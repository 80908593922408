//     							                          //
//  Program: booktourform.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import queryString from "query-string";
import MetaSEO from "../../../MetaSEO";
const queryParams = queryString.parse(location.search);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      date: new Date('2014-08-18T21:11:54'),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      input: {},
      errors: {},
      first_name: "",
      // property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
      last_name: "",
      email: "",
      phone_number: "",
      applicant_photo: "",
      price_range: "",
      desired_move_in_date: "",
      comment: "",
      status_id: true,
      helperText: "",
      bedroom: [],
      bedroomArray: [],
      priceRangeArray: [],
      // meta tags start
      title: "",
      favicon: "",
      keywords:global.keywords,
      description:global.description,
      url:window.location.origin,
      image: "",
      fb_app_id:"",
      // meta tags end
      view: queryParams.view === "mobile" ? false : ( props.view ? false : true ),
      seoLoaded: false,
    }
  }

  componentDidMount() {
    this.getcmsCommonData();
    this.getMetaData();
  }

  getcmsCommonData = async (queryString = "") => {

    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    var cacheKeyName = global.level + "_PRIVACY_POLICY_" + levelSlug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let res = await CmsDataService.getCmsData("PRIVACY_POLICY",1, 1);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false
    });
    // console.log(resData,"decd");
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);

  };

  getMetaData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_PRIVACY_POLICY_META_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    let res = await CmsDataService.getCmsMetaData("PRIVACY_POLICY", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resData.title = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.title : "" ) : "";
      resData.favicon = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "" ) : "";
      resData.keywords = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.keywords : "" ) : "";
      resData.description = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.description : "" ) : "";
      resData.url = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "" ) : "";
      resData.image = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "" ) : "";
      resData.fb_app_id = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.fb_app_id : "" ) : "";
    }
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true
    });
  }

  render() {
    // const [value, setValue] = React.useState(options[0]);
    // const [inputValue, setInputValue] = React.useState('');

    return (
      <main className="oh-template Brandmainpage">
        {/* Meta Tag Start */}
        {
          this.state.seoLoaded &&
            <MetaSEO
                title = {this.state.title}
                favicon = {this.state.favicon}
                keywords = {this.state.keywords}
                description = {this.state.description}
                noIndex={"true"}
                url = {this.state.url}
                image = {this.state.image}
                fb_app_id = {this.state.fb_app_id}
            />
         }
        {/* Meta Tag End */}
        {/* Header */}
        {this.state.view &&
          <CommonHeader propertySlug={this.state.property_slug} />
        }
        {/* middle */}
        <section className="middle">
          <div className="container disclaim_sec privacy_sec">
            {/* new logic */}
            {this.state.view &&
              <section className="reservationBook ">
                <b className="pry_mob_head">{(this.state.PRIVACY_POLICY_SECTION_1 && this.state.PRIVACY_POLICY_SECTION_1[0]) ?  this.state.PRIVACY_POLICY_SECTION_1[0].text  : "Privacy Policy"}</b>
              </section>
            }
            <div className="newcontentPrivacy">
            { (this.state.PRIVACY_POLICY_SECTION_1 && this.state.PRIVACY_POLICY_SECTION_1[1])
              ?
              <div
              dangerouslySetInnerHTML={{__html: this.state.PRIVACY_POLICY_SECTION_1[1].text}}
              />
            :
              <>
              <ol>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>1. In General</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>OHAI (“we” or “us”) values our customers and respects their privacy. This privacy policy <strong><i>(“Privacy Policy”)</i></strong> governs our collection and use of data in connection with our Services (as such term is defined in our Terms and Conditions of Service), or otherwise. Terms capitalized but not defined in this Privacy Policy shall have the meaning ascribed to them in our Terms and Conditions of Service (“Terms”) located at  <a href="/terms-condition"><strong>www.orionhaus.com/terms</strong></a>  </p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>This Privacy Policy sets forth the nature, purpose, use and sharing of any Personal Information. <strong><i> “Personal Information”</i></strong> or <strong><i>“Personal Data” </i></strong>as used in this Privacy Policy, means information that can be used on its own or with other information to identify, contact, or locate you, or to identify you in context.  This includes your name, physical address, email address, telephone number, payment card information, account username, and other identifying information.  It also includes other information that may be associated with your Personal Information, such as your location, IP address, preferences, or interests.  </p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Importantly, we may collect Personal Information directly from you when you visit our Site or access and use our Services. </p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>This Privacy Policy does not govern privacy practices associated websites other than ours, or practices of third parties that we do not own or control, such as our partners, third-party service providers featured on the Services. We are not responsible for the content or privacy practices of third-party websites or integrated products to which the Services may link. You should review the privacy policies of such sites before using the associated sites. </p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Please read this Privacy Policy carefully so that you have a clear understanding of how we collect, use, otherwise handle and protect your Personal Information in connection with your use of the Services.   IF YOU HAVE OBJECTIONS TO THE PRIVACY POLICY, YOU SHOULD IMMEDIATELY DISCONTINUE USE OF THE APPLICABLE SERVICES AND FOLLOW THE PROCEDURE DESCRIBED IN THE SECTION ENTITLED “YOUR PRIVACY RIGHTS.”  </p>

                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>2. Changes in Privacy Policy</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Any Personal Information that we collect and maintain will be subject to this Privacy Policy, as amended from time to time. We may change the Privacy Policy at our sole discretion. However, if at any time we make material changes to the Privacy Policy, we will notify you of those changes on the Site or through the Services and through the publishing of an updated Privacy Policy. If as the result of such changes you want to alter the ways in which we can use your Personal Information, you can do so by following the procedure described in the section entitled “Your Privacy Rights.” </p>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>3. Collection of Information</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We may collect certain information from you when you use our Services.  We collect and obtain your information in a few ways:  there is information that you choose to give to us, information we obtain through your use of our Services and information we obtain from third parties.  Each of these is detailed below:</p>
                  <ol>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We collect the Personal Information you choose to give us when you use the Services, including your name, e-mail address, physical address, telephone number, and the like. </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We automatically collect certain kinds of non-personal information from you when you use the Services, including device, browser type, operating system, CPU speed, referring or exit webpages, click patterns, session ID, and your computer’s IP address.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We may on occasion supplement or correct the Personal Information you submit to us and your IP address with information in our other databases or information from third-party sources in order to reduce errors in our database, authenticate our users, prevent fraud and prevent abuse of our Services, as well as to provide more consistent, relevant experiences to our users. </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We may collect general information about use of the Services, such as what pages visitors access, the number of visits, average time spent on the Services, and other similar factors. This information is generally collected in aggregate form, without identifying any user individually, although IP addresses and Session ID in relation to downloads may be tracked as part of our fraud prevention efforts. </li>
                  </ol>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>4. Location-Based Services</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Some Services may contain functionality that can enable us to access your location and tailor your experience based on your location <strong><i> (“Location-based Services”)</i></strong>. To use Location-based Services, you must enable certain features of your mobile device (e.g., GPS, WiFi, or Bluetooth) to allow us to identify your location. To the extent your location is collected through WiFi or Bluetooth sensors, such sensors, and the associated data services, may be provided by a third party that may access such information for the purpose of providing such data services to us.  You agree to such access by such third parties. You may enable or disable such Location-based Services at any time via a menu within the Services. If you choose to disable any Location-based Services on your device and/or opt out of any Location-based Services through the menu, you will not be able to utilize certain features of the Services. By enabling Location-based Services on your device, you agree and acknowledge that (i) device data we collect from you is directly relevant to your use of the Services; (ii) we may provide Location-based Services related to and based on your then-current location; and (iii) we may use any such information collected in connection with the provision of Location-based Services in providing the Services. PLEASE NOTE THAT LOCATION DATA MAY NOT ALWAYS BE ACCURATE, AND WE DISCLAIM ANY AND ALL WARRANTIES RELATED TO LOCATION-BASED SERVICES.</p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}><span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}><i>We may use your location to provide you with advertisements, offers, and other promotional content as part of the Services.</i></span></p>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>5. How Your Information is Used</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Except as provided herein, we collect and use your personal and non-personal information for internal purposes only or for gathering aggregate and anonymized data. We may use, store, and process your information in the following ways:</p>
                  <ul>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To enable you to access and use the Services.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To conduct routine business operations such as billing, identification, authentication, contact purposes and general research. As part of our routine business operations, we may transfer your Personal Information to certain third parties with whom we contract for the limited purpose of providing services such as web hosting, email, and credit card processing. These service providers are obligated to protect your information.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To employ internal marketing techniques such as tracking customer preferences to provide a customized experience and communicating with you about Services, special offers, and other services.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To employ affiliate marketing – please see our Affiliate Marketing Disclaimer set forth in our Terms.  </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To enhance the Services.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To gather product, location and other contributed information to make reports based on aggregate anonymized data, which we may use internally, or which we may sell to others.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To comply with court and governmental orders. </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>To fulfill another legitimate interest, when not overridden by your interests or, where applicable, your fundamental rights and freedoms which require the protection of Personal Information.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>When you otherwise consent for us to do so.  We may ask for your consent to process your Personal Information for a specific purpose that we communicate to you, for instance, to receive our newsletter and other such mailings you may be interested in. When you consent to our processing your Personal Information for a specified purpose, you may withdraw your consent at any time and we will stop processing of your data for that purpose.</li>
                  </ul>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>6. Tracking, Use of Cookies, Web Beacons and Similar Devices</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>In order to improve our Services and provide more convenient, relevant experiences to our customers, we and our agents may use “cookies,” “web beacons,” and similar devices to track your activities. A cookie is a small amount of data that is transferred to your browser by a web server and can only be read by the server that gave it to you. It functions as your identification card and enables us to record your passwords and preferences. It cannot be executed as code or deliver viruses. A web beacon is a small transparent .gif image that is embedded in an HTML page or email used to track when the page or email has been viewed. Most browsers are initially set to accept cookies, and most services that include similar devices are typically initially activated to collect data. You can set your browser to notify you when you receive a cookie, giving you the chance to decide whether to accept it. We do not support browsers’ Do Not Track features. You can enable or disable Do Not Track by visiting the preferences or settings page of your browser.  If you disable cookies, you may lose some of the features and functionality of using our Site, as cookies may be necessary to track and enhance your use and access.</p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We may, either directly or indirectly, track your behavior on our Services for purposes of our own customer support, analytics, research, product development, fraud prevention, risk assessment, regulatory compliance, investigation, as well as to enable you to use and access the Services and pay for your activities on the Services. We may also, either directly or through third parties we engage to provide services to us, track your behavior on our Services.</p>

                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>7. Protecting Your Information</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We use a variety of security measures, including SSL, encryption, and authentication tools, to help protect your information. We do not retain your Personal Information for longer than is necessary for our business purposes. When we no longer need your Personal Information, we dispose of it safely. </p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Our security measures include standard international data protection measures as well as those described in the EU GDPR implementing regulations. These measures may also include periodic system-wide password resets with high password strength requirements. We strongly discourage password sharing. Due to the nature of the information and related technology, we cannot be held responsible if you share your own login credentials with others. If we learn of a security breach, then we will immediately reset your account password and attempt to notify you as per GDPR guidelines to take appropriate steps. </p>

                </li>

                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>8. Data Processing</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>In submitting any third-party Personal Data within the context of the Services <strong><i>(“Third-Party Data”)</i></strong>, the parties agree that you shall be the Data Controller and you appoint us as a Data Processor of such data, for the purpose of providing the Services. To the extent that we are a Data Processor for you in respect of such Third-Party Data, we will:</p>
                  <ul>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>Implement appropriate technical and organizational measures to safeguard the Third-Party Data against any unauthorized or unlawful access, loss, destruction, theft, use or disclosure. We implement basic level security in accordance with United States law. If higher levels of security are required, please contact us. </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>Limit access to the Third-Party Data only to those employees who need to know it to enable the Data Processor to perform the Services and shall take appropriate steps to ensure the reliability of those of our employees or subcontractors who have access to such Third-Party Data.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>Only process the Personal Data as specified by this Privacy Policy and in accordance with your instructions and will not use the Third-Party Data for any purposes other than those related to the performance of the Services or pursuant to your written instructions.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>Upon the expiry or termination of this Privacy Policy, or upon your request, cease any and all use of the Third-Party Data and destroy or return it to you.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>Not disclose the Third-Party Data to any third-party without your prior written consent. Such consent is granted on, for example, your instruction to share a dashboard with third parties (public or within a restricted group).</li>
                  </ul>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We as Data Processor may provide access to a subcontractor processor to any such Third-Party Data if we reasonably consider such access and processing necessary to the performance of the Services. In the event of such access and before the access takes place, we will ensure that an agreement with the third-party is in place which is sufficient to require it to treat Personal Data in accordance with the applicable provisions of this Privacy Policy and applicable law. You authorize us to subcontract the provision of technology and commercial services to members of our corporate group.</p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>You represent and warrant that you have all the appropriate consents from data subjects whose Personal Data are submitted to us in the course of the provision of the Services.</p>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>9. Disclosure to Third Parties</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We will not share your Personal Information with third parties without your express consent, except in the limited circumstances described below:</p>
                  <ul>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We may share your Personal Information third-party service providers for the limited purposes described in the section entitled “How Your Information is Used”. </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We will share your Personal Information when required by law, regulation, or litigation, and as necessary for purposes of national security, law enforcement, or other issues of public importance. </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We may also engage third parties, including but not limited to technology services and services to help verify your identification, to conduct checks against databases such as, but not limited to, public government databases (where legally allowed), to otherwise assist us with fraud prevention and risk assessment, to assist us with customer service, to serve targeted ads, and to facilitate the payments or refunds. We may provide Personal Information about you to these third-parties, or give them access to this Personal Information, for the limited purpose of allowing them to provide these services. We will ensure that such third parties have contractual obligations to protect this Personal Information and not to use it for unrelated purposes.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>If we enter bankruptcy, undergo a change of control, seek financing or otherwise sell assets or are acquired by or merged with another entity, we may provide such entity customer information without obtaining your further consent. We may provide to you notice of such asset sales, acquisitions, or mergers.  We may also share customer information in contemplation of the foregoing transactions but will do so pursuant to commercially reasonable confidentiality and non-disclosure agreements. </li>
                  </ul>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>10. International Transfers</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>If you are visiting this Site or utilizing Services from a country other than the United States, the various communications you send and receive may result in the transfer of information across international boundaries.  If you are located in the European Union (“EU”) you should be aware that information you provide, or which is otherwise collected on this Site or through any Services rendered may be transferred to countries (including the United States) which may not have data protection laws deemed as adequate as those in the EU.</p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Notwithstanding the foregoing, the Services are intended for users in the United States and those not governed by privacy policies of other countries. Users outside the United States are advised not to disclose Personal Information to us.  </p>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>11. Your Privacy Rights</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We will ensure that you can exercise your rights pertaining to your Personal Information, including rights as provided by various data protection laws.  We therefore inform you that, where applicable under relevant law, you are entitled:</p>
                  <ul>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to have access to your Personal Information upon simple request – that is, you may receive a copy of such data (if requested), unless such data is made directly available to you, for instance within your personal account; </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to have access upon simple request, and free of charge, the categories and names of addresses of third parties that have received Personal Information for direct marketing purposes;</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to obtain a rectification of your Personal Information should it be inaccurate, incomplete or obsolete;</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to obtain the deletion of your Personal Information in the situations set forth by applicable data protection law (the “right to be forgotten”). </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to withdraw your consent to the data processing without affecting the lawfulness of processing, where your Personal Information has been collected and processed based on and your consent.  If at any time you wish to withdraw your consent to our processing of your data or opt out of our use of your information for internal marketing purposes or for compiling aggregate risk reports, you may do so by sending a written notice to: privacy@orionhaus.com. Note that your withdrawal of consent may prevent us from providing the Services to you;</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to object to the processing of your Personal Information (where it has been collected and processed based on our legitimate interests) in which case we may ask you to justify your request by explaining to us your situation;</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to request a limitation of the data processing in the situations set forth by applicable law;</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>to have your Personal Information directly transferred by us to a third-party processor of your choice (where technically feasible and when processing is based on your consent); and </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>for non-discrimination, as defined under relevant law, in the Services or quality of Services you receive from us for exercising your rights. </li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>We do not knowingly collect or solicit Personal Information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any Personal Information about yourself to us. If we learn that we have collected Personal Information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us their Personal Information, please contact us at privacy@orionhaus.com</li>
                  </ul>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Under certain laws, you may also have the right to “opt-out” of having your Personal Information sold to a third-party.  However, we do not sell your Personal Information to any other party.  Only in the case of a merger, sale, financing, acquisition, or other transaction involving all or part of us may your information be involved in a transaction.</p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>For deletion/“right to be forgotten” requests, we will undertake a good faith effort to accommodate requests for access to or deletion of personal information.  However, these requests may be subject to the evaluation of additional factors, including disproportionate burden or expense, or the risk to the rights, including the privacy rights, of others.</p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We will strive and work to resolve any inquiry you may have regarding our privacy practices.  EU residents may also file a complaint before a competent data protection authority regarding the processing of your Personal Data. While we suggest that you contact us beforehand, if you wish to exercise this right, you should contact the competent data protection authority directly.</p>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>If you have any questions, would like to exercise any of these rights, or if you need any assistance with privacy related matters, please contact us as described in the “How to Contact Us” section below. We will take the necessary steps to respond to you as soon as possible.</p>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>12. Your Choices</span>
                  <ul>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>If at any time you are uncomfortable with our use of your Personal Information for internal marketing purposes and for the purpose of creating aggregate reports, you may opt-out by email as described in “Your Privacy Rights.”  Note that if you opt not to provide us with certain mandatory information, then our products and services may be unavailable to you.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>You may similarly opt out of receiving communications from us, although we may find it necessary to continue to communicate with you regarding your use of the Services.</li>
                    <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>For our websites, you may set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this Site may then be inaccessible or not function properly. </li>
                  </ul>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>13. Governing Law</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>The terms of this Privacy Policy are governed by and in accordance with the laws of the state of Florida, and, where applicable, the laws of the countries where our servers are located. You should not provide us with any Personal Information unless you consent to the application of United States law and, where applicable, Florida law, and to the use and disclosure of your information in accordance with the terms of this Privacy Policy. </p>
                </li>
                <li className={!this.state.view ? "text-justify f-sz-12 p-bottom-5" : "text-justify"}>
                  <span className={!this.state.view ? "text-justify f-sz-12 termsandpolicy-m-b-5" : "text-justify"}>14. How to Contact Us</span>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>If you have any questions regarding this Policy or our privacy practices, you can contact us at:</p>
                  <div className="howtocontactus">
                    <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>OHAI Homes and Hotels, Inc. </p>
                    <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>2801 South Federal Highway, #21770</p>
                    <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Fort Lauderdale, FL 33335</p>
                    <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Attn: Legal Department</p>
                    <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Toll-free:  516-636-7466</p>
                    <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>Or by email at: privacy@orionhaus.com</p>
                  </div>
                  <p className={!this.state.view ? "text-justify f-sz-12 " : "text-justify"}>We take our user’s privacy concerns seriously. If you believe that we have not complied with this Privacy Policy with respect to your Personal Information, you may write to us at the address noted above.</p>
                </li>
              </ol>
              </>
            }
            </div>


            {/* <section> */}
            {/* <p className="text-center">OHAI is an independently owned and operated multifamily and short term rental management company, and is not affiliated with Airbnb, Vrbo, HomeAway, Niido, Natiivo, NGD Homesharing,  Hostiing, or CORT Furniture.</p>
                    </section>
                    <section>
                    <p className="text-center">Fair Housing & Equal Housing — OHAI is committed to compliance with all federal, state, and local fair housing laws. OHAI will not discriminate against any person because of race, color, religion, national origin, sex, familial status, disability, sexual orientation, gender, or any other specific classes protected by applicable laws. OHAI will allow any reasonable accommodation or reasonable modification based upon a disability-related need. The person requesting any reasonable modification maybe responsible for the related expenses.</p>
                    </section>
                    <section>
                    <p className="text-center">This website is for informational purposes only and does not constitute an offer, solicitation, or recommendation to sell or an offer to purchase any securities, investment products, or investment advisory services. This website and the information set forth herein are not intended to provide investment recommendations or advice.</p>
                    </section>
                    <section>
                    <p className="text-center">As our first properties are being developed, images shown are inspirational and representative of the look and feel of future OHAI properties.</p>
                    </section>
                    <section>
                    <p className="text-center">Website accessibility — OHAI strives to improve the accessibility of its website and other tools for all people, without excluding those with unique needs for accessibility. OHAI uses the relevant portions of the Web Content Accessibility Guidelines 2.1, Level A and AA, and other recommendations.</p>
                    </section>
                    <section>
                    <p className="text-center">OHAI tests its website regularly for usability on various forms of assistive technology. To make our website easier to use, we also respond to feedback from audits.</p>
                    </section>
                    <section>
                    <p className="text-center">If you are a user with needs for our website or tools that are not being met, please contact us at corporate@orionhaus.com In your message, please let us know the problems you’re encountering and the type of technology you were using when you experienced difficulty.</p>
                    </section> */}
          </div>
        </section >
        {/* Footer */}
        {
          this.state.view &&
          <CommonFooter propertySlug={this.state.property_slug} />
        }
      </main >
    );
  }
}
export default Privacy