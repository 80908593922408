////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AdAgencyService.js                        //
//  Application: Third party                           //
//  Option: Used for manage add agency                 //
//  Developer: NP           						               //
//  Date: 2021-01-11                                   //
//                                                        //
////////////////////////////////////////////////////////////

import CmsService from "./CmsService";
import ApiService from "./ApiService";

class CmsApi {

  getCmsStyleSetting(slug,level) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-cms-property-style/" + slug + "/" + level;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  getCmsSetting() {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-cms-settings";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  getCmsPropertyData(slug) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-cms-property-data/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  getcmsCommonData(slug) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "cms-common-pages?property_slug=" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }
  getFurnishedData() {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-master-data?is_dropdown=1&title=furnished";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCall(inputData);
    return response;
  }

  //for bedroom and price range
  getSettingData(slug) {
    let endPoint = "get-tour-setting/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

}

export default new CmsApi();
