////////////////////////////////////////////////////////////
//     							                          //
//  Program: Timer.jsx                                    //
//  Application: commpon                                  //
//  Option: For countdown timer into project              //
//  Developer: NP  						                   //
//  Date: 2021-03-09                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, FacebookMessengerShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon, FacebookMessengerIcon } from "react-share";
import MetaSEO from "../../../MetaSEO";

class SocialShareLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: Date.now(),
      url: "",
      display: false,
      unitName: this.props.unitName,
      unitImage: this.props.unitImage,
      urlName: this.props.urlName,
      property_slug: this.props.propertySlug ? this.props.propertySlug : global.property_slug,
      unit_slug:  localStorage.getItem("listing_slug")
      ? localStorage.getItem("listing_slug")
      : "",
    };
  }

  componentDidMount() {
    window.clearInterval(this.timerCount);
    // this.timerCount = setInterval(() => {
    //   this.timer();
    // }, 1000);
  }
  componentWillUnmount() {
    window.clearInterval(this.timerCount);
  }
  render() {
    // const url = global.themeUrl + this.state.property_slug + "/rooms/" + this.state.unit_slug;
    return (
      <>
      <MetaSEO
              title={this.props.title}
              favicon={this.props.favicon}
              keywords={this.props.keywords}
              description={this.props.description}
              noIndex={"true"}
              url={this.props.url}
              image={this.props.image}
              fb_app_id={this.props.fb_app_id}
              og_type="article"
            />
      <div className="mt-0">
        <div className="social-link-div-1">
          <figure>
            <img src={this.state.unitImage} alt={this.state.unitName} className="social-link-img" title={this.state.unitName}/>
          </figure>
          <div className="social-link-heading">{this.state.unitName}</div>
        </div>
        <section className="row">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <EmailShareButton
              title={this.state.unitName}
              url={"Please Visit " +   this.state.urlName}
              hashtags={["hashtag1", "hashtag2"]}
              className="social-link-buttons"            >
              <EmailIcon size={32} round className="w-100" />
              Email
            </EmailShareButton>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <WhatsappShareButton
              title={this.state.unitName}
              // url={this.state.urlName}
              url={"Please Visit " +   this.state.urlName}
              hashtags={["hashtag1", "hashtag2"]}
              className="social-link-buttons"
            >
              <WhatsappIcon size={32} round className="w-100" />
              What's App
            </WhatsappShareButton>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <FacebookMessengerShareButton
              url={this.state.urlName}
              // quote={"hello this is facebook quote"}
              // hashtag={"#hashtag"}
              // description={"lorem ipsom, eed adsgujbv"}
              className="social-link-buttons"
            >
              <FacebookMessengerIcon size={32} round className="w-100" />
              Messenger
            </FacebookMessengerShareButton>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <FacebookShareButton
               url={this.state.urlName}
              // quote={"hello this is facebook quote"}
              // hashtag={"#hashtag"}
              // description={"lorem ipsom, eed adsgujbv"}
              className="social-link-buttons"
            >
              <FacebookIcon size={32} round className="w-100" />
              Facebook
            </FacebookShareButton>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <TwitterShareButton
              //title={"twitter icon"}
              url={this.state.urlName}
              hashtags={[this.state.unitName]}
              className="social-link-buttons"
            >
              <TwitterIcon size={32} round className="w-100" />
              Twitter
            </TwitterShareButton>
          </div>

        </section>
      </div>
      </>
    );
  }
}

export default SocialShareLink;
