////////////////////////////////////////////////////////////
//     							                                      //
//  Program: LtrlistingApi.js                        //
//  Application: Third party                           //
//  Option: Used for manage add agency                 //
//  Developer: NP           						               //
//  Date: 2021-01-11                                   //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";
import CmsService from "./CmsService";

class LtrlistingApi {

  getUnitlisting(slug) {
    let endPoint = "get-ltr-list-detail/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getFloorplandetail(slug) {
    let endPoint = "floor-plan/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

}

export default new LtrlistingApi();
