
import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";

class PageNotFound extends Component {
    render() {
        return (
            <div>
                {/* 404page */}
                <main className="oh-template Brandmainpage header_hosting">
                    <CommonHeader propertySlug={this.state.property_slug} />
                    <section className="error_page">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 ">
                                    <h2>404</h2>
                                </div>
                                <div className="col-md-6 ">
                                    <h5>Page not found</h5>
                                    <p>This requested URL was not found on this server.</p>
                                    <button className="btn ShowmoreLink">back to homepage</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <CommonFooter propertySlug={this.state.property_slug} />
                </main>
            </div>
        )
    }
}

export default PageNotFound;