//     							                          //
//  Program: support.jsx                             //
//  Application: KINI HTML                                //
//  Option: Tech Support                                //
//  Developer: JJ		                                  //
//  Date: 2023-04-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
class SupportConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      phone_number: localStorage.getItem("phone_number")
        ? localStorage.getItem("phone_number")
        : "",
      confirmation_message: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).supportmessage
        : "",
      inputValue: undefined,
      input: {},
      errors: {},
      slug_help:
        props.match && props.match.params
          ? props.match.params.slug
            ? props.match.params.slug
            : ""
          : "",
    };
  }

  componentDidMount() {
    this.phoneNumberAutoFormat(this.state.phone_number);
    window.addEventListener("popstate", this.onBackButtonEvent);
  }
  phoneNumberAutoFormat = async (val) => {
    var number = val.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "($1)-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "($1)-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "($1)-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  onBackButtonEvent = () => {
    this.props.history.push("/access-support");
  };

  render() {
    let counter = 0;
    let support_message = this.state.confirmation_message.replace(
      "[PHONE_NUMBER]",
      this.state.phone_number ? this.state.phone_number : ""
    );
    return (
      <main className="oh-template Brandmainpage wizard_header wzard_main_bg">
        <CommonHeader propertySlug={this.state.property_slug} />
        <section className="wizard_sec">
          <div className="row- wizard_row m-0 mobView">
            <div className="wizard_box">
              <div className="wizard-data req_book_secw-zaed">
                <div className="container-fluid p-0">
                  <section className="wzrd_main_section">
                    <div className="container">
                      <div className="content">
                        <div className="row mobView wizard_new_heading">
                          <div className="wzrd_middle_desk faq_support_sec">
                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                              <div className="wzrd_heading_box col-12 m-0">
                                <h4>Access Support</h4>
                              </div>
                            </div>

                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                              <div className="requestSection mg-t-30 doitlocal">
                                <div className="cancellations reservation_faq border-0">
                                  <div className="col-sm-12 col-lg-12 p-0 headingreserv tripsdetailsmob d-block">
                                    <div className="requestSection confirm_booking pd-b-0">
                                      <div className="row book_app_info confirm-symbl mg-l-0 mg-r-0 pd-t-5 pd-b-5 tech_spt_check">
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "animateCheck.svg"
                                          )}
                                        />
                                        <div className="confirm_text">
                                          <h4> Thanks! </h4>
                                          {/* <p>
                                            Someone will text you at{" "}
                                            {this.state.phone_number} in a few
                                            minutes
                                          </p> */}
                                          <p>
                                            {support_message
                                              ? support_message
                                              : " Someone will text you at" +
                                                this.state.phone_number +
                                                "in a few minutes"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.totalRecordsfaq > 0 ? (
                              <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                                <div className="requestSection mg-t-30 doitlocal">
                                  <div className="row book_app_info ">
                                    <div className="col-12">
                                      <div className="cancellations reservation_faq border-0">
                                        <h3 className="tech-spt-title">
                                          Frequently asked questions
                                        </h3>
                                        {this.state.listfaq.map(
                                          (value, index) => {
                                            if (counter <= 2) {
                                              return (
                                                <>
                                                  <div className="reser_faq_box">
                                                    {" "}
                                                    <span className="d-none">
                                                      {counter++}
                                                    </span>
                                                    <h5>{value.title}</h5>
                                                    {/* <p>{value.description}</p> */}
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          value.description,
                                                      }}
                                                    ></p>
                                                  </div>
                                                </>
                                              );
                                            }
                                          }
                                        )}
                                        {this.state.totalRecordsfaq > 2 ? (
                                          <div className="d-flex">
                                            <b
                                              className="getdirection showmore mg-t-30"
                                              onClick={() =>
                                                this.openResFaqModal(true)
                                              }
                                            >
                                              Show more
                                            </b>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <CommonFooter propertySlug={this.state.property_slug} />  */}
      </main>
    );
  }
}
export default SupportConfirm;
