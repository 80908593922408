import React, { Component } from "react";
import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { Spinner } from "react-bootstrap";
import CmsDataService from "../../services/CmsDataService";
import Moment from "moment";
import ReservationService from "../../services/ReservationService";
import SpinnerLoader from "../common/SpinnerLoader";

class CheckoutFormParking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      errors: {},
    };
     this.removeMessage = this.removeMessage.bind(this);
     this.setPrevious = this.setPrevious.bind(this);
  }
  clearLocalStorage = () => {
    localStorage.removeItem("pricing");
    localStorage.removeItem("checkin");
    localStorage.removeItem("checkout");
    localStorage.removeItem("guest_email");
    localStorage.removeItem("userToken");
    localStorage.removeItem("reservationDetails");
    localStorage.removeItem("listing_slug");
    localStorage.removeItem("listing_name");
    localStorage.removeItem("queryParams");
  };
  Parkingpayment = async (event) => {
    var message = "";
    event.preventDefault();
    this.setState({ showSpinner: true, loading: true, loader: true});
     
      const stripe = this.props.stripe;
      const elements = this.props.elements;

      if (!stripe || !elements) {
        this.setState({loader: false});
        return;
      }
      const result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });
      if (result.error) {
        var message = result.error.message;
        var decline_message = message;
        this.setState({failedmessage: decline_message });
        this.setState({loader: false});
      }
      this.setState({ showSpinner: true, loading: true });
      var resDataArr = [];
      if (result.paymentIntent.status === "succeeded") {

        var ParkingData = {
        confirmation_code: this.props.confirmation_code,
        stripe_transaction_id:this.props.stripe_id,
        amount:this.props.amount
        };
        localStorage.setItem("parking_payment", 1);
        let res =  await ReservationService.AddParkingData(ParkingData);
        await this.clearLocalStorage();
        resDataArr = res.data ? res.data : [];
        await this.setState({
          transactionData: resDataArr,
        });
        this.setState({loader: false});
        var unit = document.getElementById("booking-success");
        unit.click();
      } else {
        this.setState({ failedmessage: "Payment Failed."});
        this.setState({loader: false});
      }
  };
 removeMessage = async() => {
  await this.setState({failedmessage:""})
 }
 
setPrevious = async() =>{
  window.location.href = "/reservation-details";
}
 


  render() {
    return (
      <>
          <div>
          <span>
            <h5 className="text-danger decline">{this.state.failedmessage}</h5>
          </span>
        </div>
        
        <div>
          <PaymentElement onChange={this.removeMessage.bind(this)}/>
          <a href={global.themeUrl +'reservation-details'}
            hidden
            className="w-100 text-decoration-none hidden"
            rel="noopener noreferrer"
          >
            <button className="mg-t-30" id="booking-success">
             Pay
            </button>
          </a>
          <div className="step_btns">
                    <button id="book-tour-page" className="w-100 btn_pre_wzd" onClick={this.setPrevious}>
                  Previous
                </button>
          
                    <button id="book-tour-page" className="w-100 btn_next_wzd" disabled={this.state.loader ? true : false}  onClick={(e) => this.Parkingpayment(e)}>
                  Pay
                  {this.state.loader ? (
              <>
                {" "}
                <SpinnerLoader />{" "}
              </>
            ) : (
              ""
            )}
                </button>
                </div>
        </div>
      
      </>
    );
  }
}

export default function InjectedCheckoutFormParking(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements, slug }) => (
        <CheckoutFormParking
          stripe={stripe}
          elements={elements}
          stripe_id={props.stripe_id}
          amount={props.amount}
          confirmation_code={props.confirmation_code}
        />
      )}
    </ElementsConsumer>
  );
}
