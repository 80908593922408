//     							                          //
//  Program: support.jsx                             //
//  Application: KINI HTML                                //
//  Option: Tech Support                                //
//  Developer: JJ		                                  //
//  Date: 2023-04-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import CommonHeader from "../common/CommonHeader";
import queryString from "query-string";
import PhoneInput from "react-phone-input-2";
import TextField from "@mui/material/TextField";
import SpinnerLoader from "../common/SpinnerLoader";
import ReservationService from "../../services/ReservationService";
const queryParams = queryString.parse(location.search);
class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      currentData: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details"))
        : [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      country_code: "+1",
      phone_number: "",
      loader: false,
      notes: queryParams.description ? queryParams.description : "",
      latch_description: queryParams.description ? queryParams.description : "",
      first_name: queryParams.username ? queryParams.username : "",
      // last_name:  localStorage.getItem("trip_details") ? JSON.parse(localStorage.getItem("trip_details")).guest_user.last_name:"",
      // confirmation_code:localStorage.getItem("trip_details") ? JSON.parse(localStorage.getItem("trip_details")).confirmation_code:queryParams.confirmation_code ? queryParams.confirmation_code:"",
      confirmation_code: queryParams.confirmation_code
        ? queryParams.confirmation_code
        : "",
      input: {
        phone_number: "",
        notes: "",
        first_name: "",
        confirmation_code: "",
      },
      errors: {
        phone_number: "",
        notes: "",
        first_name: "",
        confirmation_code: "",
      },
      aka_name:
        props.match && props.match.params
          ? props.match.params.slug
            ? props.match.params.slug
            : ""
          : "",
      propertyname:
        queryParams && queryParams.propertyname
          ? queryParams.propertyname
          : props.propertyname,
      unitname:
        queryParams && queryParams.unitname
          ? queryParams.unitname
          : props.unitname,
      params:
        queryParams && queryParams.locktype
          ? queryParams.locktype.toLowerCase()
          : props.locktype,
      user_slug:
        queryParams && queryParams.userslug
          ? queryParams.userslug
          : props.userslug,
      residentname:
        queryParams && queryParams.username
          ? queryParams.username
          : props.username,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerPhone = this.changeHandlerPhone.bind(this);
  }
  async componentDidMount() {
    if (this.state.confirmation_code) {
      this.getReservationDetails();
    }
  }
  getReservationDetails = async () => {
    var list = [];
    var record = [];
    var totalRecords = 0;
    var lockType = "";
    var params = "";
    var first_name = "";
    var last_name = "";
    var phone_number = "";
    var Fullname = "";
    this.setState({ loader: true });
    if (this.state.confirmation_code) {
      var data = {
        confirmation_code: this.state.confirmation_code,
        last_name: this.state.last_name,
        email: this.state.email,
        property_seo_slug: this.state.property_slug,
        user_slug: "",
        filter_by: 4,
      };
      let res = await ReservationService.getMyBookReservation(data);
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {
        list = res.data ? res.data : [];
        record = res.data ? res.data : [];
        first_name = res.data ? res.data.guest_user?.first_name : "";
        last_name = res.data ? res.data.guest_user?.last_name : "";
        Fullname = first_name + " " + last_name;
        phone_number = res.data ? res.data.phone_number : "";
        this.setState({ loader: false });
        totalRecords = res.data ? res.data.total : 0;
        lockType = res.data ? res.data?.property?.lock_type : "";
      }
      await localStorage.setItem("trip_details", JSON.stringify(list));
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {
        await this.setState({
          total_record: totalRecords,
          reservationList: list,
          first_name: Fullname,
          phone_number: phone_number,
          loader: false,
          params: params,
        });
        this.setState({ reservationDetail: true, errorMsg: "" });
      } else {
        this.setState({
          errorMsg:
            "Details entered by you are incorrect. Please verify your confirmation code you have used during booking.",
        });
      }
    } else {
      var errorCount = this.state.errorCount;
      if (!this.state.confirmation_code) {
        document.getElementById("confirmation_code").focus();
        this.setState({
          confirmation_code_error: "Please enter confirmation code",
        });
      }
    }
    this.setState({ loader: false, errorCount: errorCount + 1 });
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    if (event.target.name === "first_name") {
      if (event.target.value.length >= 2) {
        const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      }
    }
    if (event.target.name === "notes") {
      if (event.target.value.length >= 2) {
        var errors = this.state.errors;
        errors.notes = "";
        this.setState({ errors: errors });
      }
    }
    // if (event.target.name === "confirmation_code") {
    //   if (event.target.value.length >= 4) {
    //     var errors = this.state.errors;
    //     errors.confirmation_code = "";
    //     this.setState({ errors: errors });
    //   }
    // }
    this.setState({ [event.target.name]: value });
  };

  changeHandlerPhone = (phoneNumber) => {
    this.setState({ phone_number: phoneNumber });
    if (phoneNumber.length > 8) {
      var errors = this.state.errors;
      errors.phone_number = "";
      this.setState({ errors: errors });
    }
    if (phoneNumber.length > 15 || phoneNumber.length < 8) {
      var errors = this.state.errors;
      errors.phone_number = "The phone number must be a valid phone number";
      this.setState({ errors: errors });
    }
  };
  saveSupportData = async () => {
    var lockType = this.state.params === "latch" ? 2 : 1;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      phone_number: this.state.phone_number,
      aka_name: this.state.aka_name,
      confirmation_code: this.state.confirmation_code,
      name: this.state.first_name,
      notes: this.state.notes,
      latch_log: this.state.latch_description,
      unitname: this.state.unitname,
      propertyname: this.state.propertyname,
      user_slug: this.state.user_slug,
      lockType: lockType,
    };
    if (
      this.state.phone_number !== "" &&
      this.state.notes !== "" &&
      this.state.first_name !== ""
    ) {
      this.setState({ loader: true });
      let res = await CmsDataService.saveSupportData(inputData);
      if (global.successStatus.includes(res.status)) {
        localStorage.setItem("phone_number", this.state.phone_number);
        window.location.href = "/support-confirmation";
      } else {
        window.location.href = "/access-support";
      }
    } else {
      if (this.state.phone_number == "") {
        var errors = this.state.errors;
        errors.phone_number = "Please enter valid phone number";
        this.setState({ errors: errors });
      }
      if (this.state.first_name == "") {
        var errors = this.state.errors;
        errors.first_name = "Please enter valid name";
        this.setState({ errors: errors });
      }
      // if (this.state.confirmation_code == "") {
      //   var errors = this.state.errors;
      //   errors.confirmation_code = "Please enter valid Confirmation code";
      //   this.setState({ errors: errors });
      // }
      if (this.state.notes == "") {
        var errors = this.state.errors;
        errors.notes = "Please enter valid detail";
        this.setState({ errors: errors });
        return false;
      }
    }
  };

  render() {
    return (
      <main className="oh-template Brandmainpage wizard_header wzard_main_bg ">
        <CommonHeader propertySlug={this.state.property_slug} />
        <section className="wizard_sec tech_supp_sec">
          <div className="row- wizard_row m-0 mobView">
            <div className="wizard_box tech_sec_box">
              <div className="wizard-data req_book_secw-zaed">
                <div className="container-fluid p-0">
                  <section className="wzrd_main_section">
                    <div className="container">
                      <div className="content">
                        <div className="row mobView wizard_new_heading">
                          <div className="wzrd_middle_desk m-0">
                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                              <div className="wzrd_heading_box col-12 m-0 tech_heading">
                                <a href="/access-support">
                                  <img
                                    src={global.OnKeyPressEvent.getS3ImageUrl(
                                      "back-arrow-icon.svg"
                                    )}
                                    alt="arrow"
                                  />
                                </a>
                                <h4>Access Support</h4>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                              <div className="wzrd_heading_box col-12 mb-0 tech-spt_sec scroll_phn_safari ">
                                <div className="col-xs-12 col-sm-12 col-md-12 p-0">
                                  <div className="wzrd_mid_heading border-0 m-0 pt-0">
                                    <div className="col-12 ">
                                      {/* <p className="mg-b-15">
                                        Please provide us these details so we
                                        may assist you better.
                                      </p> */}
                                      <p className="mg-b-10">
                                        Enter your information so we can contact
                                        you.
                                      </p>
                                    </div>
                                    <div className="col-12 pl-cust-0 wzrd_phone tech_form_mg_b">
                                      <PhoneInput
                                        className="textfield phoneInput"
                                        variant="outlined"
                                        onlyCountries={["us"]}
                                        country={global.country}
                                        disableCountryCode={true}
                                        disableDropdown={true}
                                        // onKeyPress={global.OnKeyPressEvent.phoneValidation}
                                        autoFormat="true"
                                        enableSearch="true"
                                        // countryCodeEditable="true"
                                        id="phone_number"
                                        name="phone_number"
                                        value={
                                          this.state.phone_number
                                            ? this.state.phone_number
                                            : ""
                                        }
                                        onChange={(e) =>
                                          this.changeHandlerPhone(e)
                                        }
                                        error={
                                          this.state.errors.phone_number
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.errors.phone_number
                                            ? this.state.errors.phone_number
                                            : ""
                                        }
                                        placeholder="Phone number"
                                        fullWidth
                                      />
                                      {this.state.errors.phone_number ? (
                                        <p className="error-message">
                                          {this.state.errors.phone_number}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-12 pl-cust-0 wzrd_phone tech_form_mg_b">
                                      <TextField
                                        id="first_name"
                                        name="first_name"
                                        value={this.state.first_name}
                                        onChange={this.changeHandler}
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                      />
                                      {this.state.errors.first_name ? (
                                        <p className="error-message">
                                          {this.state.errors.first_name}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div className="col-12 pl-cust-0 wzrd_phone tech_form_mg_b">
                                      <TextField
                                        id="confirmation_code"
                                        name="confirmation_code"
                                        value={this.state.confirmation_code}
                                        onChange={this.changeHandler}
                                        label="Confirmation code"
                                        variant="outlined"
                                        fullWidth
                                      />
                                      {this.state.errors.confirmation_code ? (
                                        <p className="error-message">
                                          {this.state.errors.confirmation_code}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div> */}
                                    <div className="col-12 pl-cust-0 issue_text_box">
                                      <textarea
                                        placeholder="Enter your an issue here"
                                        className=""
                                        id="notes"
                                        label="Message"
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        fullWidth
                                        name="notes"
                                        value={this.state.notes}
                                        onChange={this.changeHandler}
                                      ></textarea>
                                      {this.state.errors.notes ? (
                                        <p className="error-message">
                                          {this.state.errors.notes}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="step_btns justify-content-center">
                <button
                  class=" btn_next_wzd tect_btn_sub btn_faq_issue"
                  onClick={this.saveSupportData}
                >
                  Send
                </button>
                {this.state.loader ? (
                  <div className="mob_fullscreen">
                    <SpinnerLoader />{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
        {/* <CommonFooter propertySlug={this.state.property_slug} />  */}
      </main>
    );
  }
}
export default Support;
