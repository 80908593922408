import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import FormWizards from "./FormWizards";
import Moment from "moment";
import PhoneInput from "react-phone-input-2";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Modal, Spinner } from "react-bootstrap";
import Timer from "../common/Timer";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AuthenticationService from "../../services/AuthenticationService";
import ReservationService from "../../services/ReservationService";
import SpinnerLoader from "../common/SpinnerLoader";
import CardForm from "./CardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import openeye from "./../../../assets/images/openeye.png";
import closeeye from "./../../../assets/images/closeeye.png";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CmsDataService from "../../services/CmsDataService";

class WizardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentData: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details"))
        : [],
      input: {},
      pagestate: 1,
      id_type: 1,
      loader: false,
      passwordType: "password",
      clientToken:"",
      password: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).password
        : "",
      passwordflag: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).passwordflag
        : "",
      submitloader: false,
      editablePhone: false,
      verifyOtpModal: false,
      forntphoto_loader: false,
      backphtoto_loader: false,
      selfiephoto_loader: false,
      classbox: "",
      is_notification_1: "",
      is_notification_2: "",
      is_notification_3: "",
      is_notification_4: "",
      is_notification_5: "",
      is_notification_6: "",
      is_notification_7: "",
      is_notification_8: "",
      is_notification_9: "",
      is_notification_10: "",
      is_notification_11: "",
      is_notification_12: "",
      is_notification_13: "",
      is_notification_14: "",
      is_notification_15: false,
      card_agree_type: 2,
      guestFrontimageUrl: "",
      guestbackimageUrl: "",
      guestselfieimageUrl: "",
      guestFront: "",
      back_image: "",
      selfie_image: "",
      is_notification_16: "",
      set_channel: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).set_channel
        : "",
      signature: "",
      last_name: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).guest_user.last_name
        : "",
      first_name: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).guest_user.first_name
        : "",
      email: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).email
        : "",
      phone_number: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).phone_number
        : "",
      old_email: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).guest_user.email
        : "",
      guest_slug: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).guest_user.slug
        : "",
      confirmation_code: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).confirmation_code
        : "",
      lock_type: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).property.lock_type
        : "",
      user_token: Math.floor(Math.random(12) * 1000000000000 + 1),
      is_latch_enable: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details")).is_latch_enable
        : "",
      file: "https://d1xsfycladxeij.cloudfront.net/user/profile/thumb/oh_636490df77cd2636490df77cd3.jpg",
      file_input: null,
      is_uploaded: false,
      country_code: "+1",
      otp_type: 1,
      input: {},
      email_error: "",
      signature_error: "",
      deposite_amount: "$500",
      is_email_verified: localStorage.getItem("is_email_verified")
        ? localStorage.getItem("is_email_verified")
        : "",
        is_form_submited: this.props.isformsubmited ? this.props.isformsubmited:localStorage.getItem("is_form_submited")
        ? localStorage.getItem("is_form_submited")
        : "",
      card_tokenise: localStorage.getItem("card_tokenise")
        ? localStorage.getItem("card_tokenise")
        : "",
      otp_verified_submit: false,
      error: {
        email: "",
        phone_number: "",
        password: "",
        is_notification_1: "",
        is_notification_2: "",
        is_notification_3: "",
        is_notification_4: "",
        is_notification_5: "",
        is_notification_6: "",
        is_notification_7: "",
        is_notification_8: "",
        is_notification_9: "",
        is_notification_10: "",
        is_notification_11: "",
        is_notification_12: "",
        is_notification_13: "",
        is_notification_14: "",
        is_notification_15: "",
        is_notification_16: "",
      },
      exist_img: "",
      isLoaded: false,
      editablePhone: false,
      isCardEnable:this.props.isCardEnable,
      Datecheck:this.props.Datecheck,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.setNext = this.setNext.bind(this);
    this.setPrevious = this.setPrevious.bind(this);
    this.ClickFileUploadFront = this.ClickFileUploadFront.bind(this);
    this.ClickFileUploadBack = this.ClickFileUploadBack.bind(this);
    this.ClickFileUploadSelfie = this.ClickFileUploadSelfie.bind(this);
    this.FrontFileUpload = this.FrontFileUpload.bind(this);
    this.BackFileUpload = this.BackFileUpload.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.SelfieFileUpload = this.SelfieFileUpload.bind(this);
    this.RemoveFrontImage = this.RemoveFrontImage.bind(this);
    this.RemoveBackImage = this.RemoveBackImage.bind(this);
    this.RemoveSelfieImage = this.RemoveSelfieImage.bind(this);
    this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
    this.editablePhone = this.editablePhone.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.changeHandlerPhone = this.changeHandlerPhone.bind(this);
    this.resetPasswordemail = this.resetPasswordemail.bind(this);
    this.getAgreementdata = this.getAgreementdata.bind(this);
    this.getclientToken = this.getclientToken.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    // await this.getAgreementdata();
  if (this.state.is_form_submited == 2 && this.state.card_tokenise == 0 && this.state.isCardEnable == 1 && this.state.Datecheck == false) {
      this.setState({ pagestate: 5 });
      this.getclientToken("", this.state.card_agree_type);
    } 
  }

  getclientToken = async (queryString = "", value) => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var stripe_id = [];
    var customer_id = [];
    var depositeType = [];
    var card_agree_type = 0;
    let inputData = {
      customer_id: this.state.currentData?.guest_user?.stripe_customer_id,
      guest_slug: this.state.currentData?.guest_user?.slug,
      reservation_slug: this.state.currentData?.slug,
      card_agree_type: value,
    };
    if (value == 1 || value == 2) {
      let res = await CmsDataService.getCardclientToken(inputData);
      if (global.successStatus.includes(res.status)) {
        resDataArr = res.data ? res.data[0] : [];
        stripe_id = res.data ? res.data[1] : [];
        customer_id = res.data ? res.data[2] : [];
        depositeType = res.data ? res.data[3] : [];
      }
    }
    if(depositeType == 50000) {
      card_agree_type = 1;
    } else{
      card_agree_type = 2;
    }
    await this.setState({
      clientToken: resDataArr,
      stripe_id: stripe_id,
      customer_id: customer_id,
      card_agree_type:card_agree_type,
    });
    this.getReservationDetailsForLocalStorage();
  };

  getReservationDetailsForLocalStorage = async () => {
    var list = [];
    this.setState({ loader: true });
    var data = {
      confirmation_code: this.state.confirmation_code,
      last_name: this.state.last_name,
      email: this.state.email,
      property_seo_slug: this.state.property_slug,
      user_slug: "",
      filter_by: 4,
    };
    let res = await ReservationService.getMyBookReservation(data);
    if (global.successStatus.includes(res.status) && res.data.length !== 0) {
      list = res.data ? res.data : [];
      await localStorage.setItem("trip_details", JSON.stringify(list));
    }
  };

  manageVerifyModal = (value = false) => {
    this.setState({ verifyOtpModal: value });
  };

  editablePhone = () => {
    this.setState({ editablePhone: true });
  };

  scrollTop = () => {
    const section = document.querySelector("#PrivateSwitchBase-input");
    section.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  scrollTopemail = () => {
    const section = document.querySelector("#PrivateSwitchBase-input-email");
    section.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  togglePassword = () => {
    this.setState({ passwordType: "text" });
  };
  
  togglePasswordHide = () => {
    this.setState({ passwordType: "password" });
  };

  passwordCheckFun = () => {
    let errors = this.state.error;
    let passwordCheck = global.OnKeyPressEvent.validatePassword(
      this.state.password
    );
    if (passwordCheck !== "") {
      errors.password = passwordCheck;
    } else {
      errors.password = "";
    }
    this.setState({ error: errors });
  };

  resendOtp = async () => {
    this.setState({ loader: true });
    if (!this.state.email) {
      this.setState({
        email_error: "Please enter valid email.",
      });
    } else {
      var inputData = {
        user_token: this.state.user_token,
      };
      let res = await ReservationService.resendOtp(inputData);
      var phone_number = "";
      var otp = "";
      if (global.successStatus.includes(res.status)) {
        otp = res.data && res.data.otp ? res.data.otp : "";
        this.setState({
          otp: otp,
          verifyOtpModal: true,
          phone_number_error: "",
        });
      }
    }
    this.setState({ loader: false });
  };

  getAgreementdata = async () => {
    let res = await ReservationService.getGuestformcheckboxdata();
    if (global.successStatus.includes(res.status)) {
      this.setState({
        guest_checkbox_data: res,
      });
    }
    this.setState({ loader: false });
  };

  verifyOtp = async () => {
    this.setState({ loader: true });
    if (!this.state.verify_otp || this.state.verify_otp.length !== 6) {
      this.setState({
        verify_otp_error: "Please enter valid code to continue.",
      });
    } else {
      this.setState({ verify_otp_error: "" });
      var inputData = {
        user_token: this.state.user_token,
        otp: this.state.verify_otp,
      };
      let res = await ReservationService.verifyOtp(inputData);
      if (global.successStatus.includes(res.status)) {
        let inputDatanew = {
          guest_slug: this.state.guest_slug,
          confirmation_code: this.state.confirmation_code,
          sort_order: this.state.sort_order,
          email_verified: 1,
          is_form_submited: 0,
          status_id: 1,
        };

        let res = await ReservationService.Guestform(inputDatanew);
        if (global.successStatus.includes(res.status)) {
          localStorage.setItem("is_form_submited", 0);
          localStorage.setItem("is_email_verified", 1);
          this.setState({ is_email_verified: 1 });
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
        await this.setState({
          otp_verified_submit: true,
          verifyOtpModal: false,
          verify_otp: "",
          otp: "",
          pagestate: this.state.pagestate + 1,
        });
        // this.props.history.push(global.themeUrl + this.state.property_slug + "/book-tour-basic-info"+this.state.queryString);
      } else {
        this.setState({
          verify_otp_error:
            "Your OTP token has been expired or invalid. Please try to resent it.",
          resendOtp: true,
        });
      }
    }
    this.setState({ loader: false });
  };

  sendOtp = async () => {
    var inputData = {
      country_code: this.state.country_code,
      email: this.state.email,
      user_token: this.state.user_token,
      otp_type: this.state.otp_type,
    };
    let res = await ReservationService.sendOtp(inputData);
    var phone_number = "";
    var otp = "";
    if (global.successStatus.includes(res.status)) {
      phone_number =
        res.data && res.data.mobilePhone ? res.data.mobilePhone : "";
      otp = res.data && res.data.otp ? res.data.otp : "";
      this.setState({
        display_email: phone_number,
        otp: otp,
        verifyOtpModal: true,
        email_error: "",
      });
    }
    this.setState({ loader: false });
  };

  getReservationDetails = async () => {
    var list = [];
    var record = [];
    var totalRecords = 0;
    var password = "";
    if (this.state.passwordflag == 0) {
      var password = this.state.password;
    }
    this.setState({ loader: true });
    var captcha = true;
    var data = {
      confirmation_code: this.state.confirmation_code,
      last_name: this.state.last_name,
      password: password,
      email: this.state.email,
      phone_number: this.state.phone_number,
      property_seo_slug: this.state.property_slug,
      user_slug: "",
      filter_by: 4,
    };
    let res = await ReservationService.getMyBookReservation(data);
    if (global.successStatus.includes(res.status) && res.data.length !== 0) {
      list = res.data ? res.data : [];
      record = res.data ? res.data : [];
      this.setState({ loader: false });
      totalRecords = res.data ? res.data.total : 0;
    }
    if (this.state.errorCount >= 3) {
      recaptchaRef.current.reset();
    }
    if (global.successStatus.includes(res.status) && res.data.length !== 0) {
      let statusText = "pending";

      const currentDate = new Date();
      var dateFrom = Moment(record.checkin_date).format("MM/DD/YYYY");
      var dateTo = Moment(record.checkout_date).format("MM/DD/YYYY");
      var dateCheck = Moment(currentDate).format("MM/DD/YYYY");
      var d1 = dateFrom.split("/");
      var d2 = dateTo.split("/");
      var c = dateCheck.split("/");
      if (record.status_id === 1 && c >= d1 && c <= d2) {
        statusText = "Confirmed";
      } else if (
        record.status_id !== 2 &&
        record.set_channel === 1 &&
        record.payment_status === 2 &&
        dateCheck > dateTo
      ) {
        statusText = "Confirmed";
      } else if (
        record.status_id !== 2 &&
        record.set_channel === 2 &&
        dateCheck > dateTo
      ) {
        statusText = "Completed";
      } else {
        if (record.set_channel === 1) {
          statusText =
            record.cancel_status === 0 && record.payment_status === 2
              ? "Confirmed"
              : record.cancel_status === 0 && record.status_id === 1
              ? "Payment Pending"
              : record.cancel_status === 1
              ? "Cancelled"
              : "Failed";
        }
        if (record.set_channel === 2) {
          statusText =
            record.status_id === 1 || record.cancel_status === 0
              ? "Confirmed"
              : "Failed";
        }
      }
      record.status_text = statusText;
      record.baged_class =
        statusText === "Confirmed"
          ? "baged-confirm"
          : statusText === "Confirmed"
          ? "baged-pending"
          : statusText === "Cancelled"
          ? "baged-cancel"
          : statusText === "Completed"
          ? "baged-completed"
          : statusText === "Failed"
          ? "baged-cancel"
          : "baged-cancel";
      record.total_night_text =
        "$" +
        parseFloat(record.nightly_rate) +
        " x " +
        (record.total_stay_nights
          ? record.total_stay_nights > 1
            ? record.total_stay_nights + " nights"
            : "1 night"
          : "1 night");
      record.total_nightly_rate_disp =
        "$" +
        parseFloat(record.nightly_rate) * parseInt(record.total_stay_nights);
      await this.setState({
        total_record: totalRecords,
        reservationList: list,
        loader: false,
      });
      await localStorage.setItem("trip_details", JSON.stringify(list));
      this.setState({ reservationDetail: true, errorMsg: "" });
    }
  };

  changeHandlerSelect = async (event) => {
    let input = this.state.input;
    input.id_type = event.target.value;
    await this.setState({
      id_type: event.target.value,
      input: input,
      id_error: "",
    });
  };

  ClickFileUploadFront(el) {
    document.getElementById("img_eventTarget").click();
  }

  ClickFileUploadBack(el) {
    document.getElementById("img_eventTarget_back").click();
  }

  ClickFileUploadSelfie(el) {
    document.getElementById("img_eventTarget_selfie").click();
  }

  RemoveFrontImage = () => {
    this.setState({
      guestFrontimageUrl: "",
    });
  };

  RemoveBackImage = () => {
    this.setState({
      guestbackimageUrl: "",
    });
  };

  RemoveSelfieImage = () => {
    this.setState({
      guestselfieimageUrl: "",
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.id_type === "" || this.state.id_type === undefined) {
      this.setState({ id_error: "Please select ID type" });
      return false;
    }
    // if(this.state.set_channel !== 1 && this.state.id_type !== "" &&  this.state.guestFrontimageUrl !== "" && this.state.guestbackimageUrl!=="" && this.state.guestselfieimageUrl!== ""){
    //  await this.resetPasswordemail();
    // }
    if (
      this.state.error.email === "" &&
      this.state.email !== "" &&
      this.state.guestFrontimageUrl !== "" &&
      this.state.guestbackimageUrl !== "" &&
      this.state.guestselfieimageUrl !== "" &&
      this.state.id_type !== ""
    ) {
      this.setState({ submitloader: true });
      let inputData = {
        guest_slug: this.state.guest_slug,
        signature_name: this.state.signature,
        confirmation_code: this.state.confirmation_code,
        front_photo_name: this.state.guestFront,
        back_photo_name: this.state.guestBack,
        selfie_photo_name: this.state.guestSelfie,
        id_type: this.state.id_type,
        sort_order: this.state.sort_order,
        email_verified: 1,
        is_form_submited: this.state.Datecheck === true ? 1:2,
        card_tokenise:this.state.Datecheck === true ? 1:0,
        status_id: 1,
      };

      let res = await ReservationService.Guestform(inputData);
      if (global.successStatus.includes(res.status)) {
        localStorage.setItem("is_form_submited", 2);
        if (this.state.card_tokenise == 0 && this.state.isCardEnable == 1 &&  this.state.clientToken == '' && this.state.Datecheck == false) {
          this.getclientToken("", this.state.card_agree_type);
          this.setState({ pagestate: 5 });
        } else if (this.state.card_tokenise == 0 && this.state.isCardEnable == 1 && this.state.clientToken != '') {
          this.setState({ pagestate: 5 }); 
        }
       else {
          window.location.href = "/reservation-details";
        }
        // this.setState({pagestate:5});
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ submitloader: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({ errors: "Please upload required images" });
    }
  };

  FrontFileUpload = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ forntphoto_loader: true });
      const fileData = new FormData();
      fileData.append(
        "front_guest",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      fileData.append("orgFileDestinationFolder", "guest_user_front");
      fileData.append("fieldName", "front_guest");
      fileData.append("uploadOn", "presigned_url");
      fileData.append("slug", this.state.guest_slug);
      fileData.append("refTableId", "18");
      fileData.append("displayFileName", "guestFront");
      fileData.append("documentTypeSlug", this.state.guest_slug);
      fileData.append("description", "");
      fileData.append("documentTypeSlug", this.state.guest_slug);
      fileData.append("description", "");
      fileData.append("propertySlug", "");
      fileData.append("h1", "");
      fileData.append("h2", "");
      fileData.append("sortOrder", "");
      fileData.append("statusId", 1);
      let res = await AuthenticationService.uploadFrontImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          guestFront: res.data.newName,
          guestFrontimageUrl: res.data.url,
          errors: "",
          imgError: "",
        });
        this.setState({ updateDisableButton: false });
        this.setState({ forntphoto_loader: false });
      } else {
        this.setState({ updateDisableButton: false });
        this.setState({ imgError: res.data.message });
        this.setState({ forntphoto_loader: false });
      }
    }
  };

  BackFileUpload = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ backphtoto_loader: true });
      const fileData = new FormData();
      fileData.append(
        "back_guest",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      fileData.append("orgFileDestinationFolder", "guest_user_back");
      fileData.append("fieldName", "back_guest");
      fileData.append("uploadOn", "presigned_url");
      fileData.append("slug", this.state.guest_slug);
      fileData.append("refTableId", "18");
      fileData.append("displayFileName", "guestback");
      fileData.append("documentTypeSlug", this.state.guest_slug);
      fileData.append("description", "");
      fileData.append("documentTypeSlug", this.state.guest_slug);
      fileData.append("description", "");
      fileData.append("propertySlug", "");
      fileData.append("h1", "");
      fileData.append("h2", "");
      fileData.append("sortOrder", "");
      fileData.append("statusId", 1);
      let res = await AuthenticationService.uploadFrontImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          guestBack: res.data.newName,
          guestbackimageUrl: res.data.url,
          errors: "",
          imgError: "",
        });
        this.setState({ updateDisableButton: false });
        this.setState({ backphtoto_loader: false });
      } else {
        this.setState({ updateDisableButton: false });
        this.setState({ imgError: res.data.message });
        this.setState({ backphtoto_loader: false });
      }
    }
  };

  SelfieFileUpload = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ selfiephoto_loader: true });
      const fileData = new FormData();
      fileData.append(
        "selfie_guest",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      fileData.append("orgFileDestinationFolder", "guest_user_selfie");
      fileData.append("fieldName", "selfie_guest");
      fileData.append("uploadOn", "presigned_url");
      fileData.append("slug", this.state.guest_slug);
      fileData.append("refTableId", "18");
      fileData.append("displayFileName", "guestSelfie");
      fileData.append("documentTypeSlug", this.state.guest_slug);
      fileData.append("description", "");
      fileData.append("documentTypeSlug", this.state.guest_slug);
      fileData.append("description", "");
      fileData.append("propertySlug", "");
      fileData.append("h1", "");
      fileData.append("h2", "");
      fileData.append("sortOrder", "");
      fileData.append("statusId", 1);
      let res = await AuthenticationService.uploadFrontImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          guestSelfie: res.data.newName,
          guestselfieimageUrl: res.data.url,
          errors: "",
          imgError: "",
        });
        this.setState({ updateDisableButton: false });
        this.setState({ selfiephoto_loader: false });
      } else {
        this.setState({ updateDisableButton: false });
        this.setState({ imgError: res.data.message });
        this.setState({ selfiephoto_loader: false });
      }
    }
  };

  setNext = async (e) => {
    if (this.state.pagestate === 1 && this.state.email === "") {
      this.scrollTopemail();
      this.setState({ email_error: "Please enter valid email" });
      return false;
    }
    if (
      this.state.pagestate === 1 &&
      this.state.set_channel !== 1 &&
      this.state.lock_type === 2 &&
      this.state.is_latch_enable == 1 &&
      (this.state.password === "" || this.state.password === null)
    ) {
      let errors = this.state.error;
      let passwordCheck = global.OnKeyPressEvent.validatePassword(
        this.state.password
      );
      if (passwordCheck !== "") {
        errors.password = "please enter valid password";
      } else {
        errors.password = "";
      }
      this.setState({ error: errors });
      return false;
    }
    if (
      this.state.pagestate === 3 &&
      this.state.signature === "" &&
      this.state.is_notification_16 === ""
    ) {
      this.setState({
        signature_error: "Please enter valid signature",
        is_notification_16: false,
      });
      this.scrollTop();
      var pagestate = this.state.pagestate;
      await this.setState({ nextpagestate: pagestate });
      return false;
    }
    if (this.state.pagestate === 3 && this.state.is_notification_16 === false) {
      this.scrollTop();
      return false;
    }

    if (
      this.state.pagestate === 1 &&
      this.state.set_channel !== 1 &&
      this.state.otp_verified_submit === false &&
      this.state.is_email_verified == 0 &&
      !this.state.error.password
    ) {
      e.preventDefault();
      await this.getReservationDetails();
      await this.sendOtp();
      var pagestate = this.state.pagestate;
      await this.setState({ nextpagestate: pagestate, passwordflag: 1 });
      return false;
    }
    if (
      this.state.pagestate === 1 &&
      this.state.set_channel !== 1 &&
      this.state.email !== "" &&
      this.state.email_error === "" &&
      !this.state.error.password
    ) {
      await this.getReservationDetails();
      var pagestate = this.state.pagestate;
      await this.setState({ pagestate: pagestate + 1, passwordflag: 1 });
      window.scrollTo(0, 0);
    }
    if (
      this.state.pagestate === 1 &&
      this.state.set_channel === 1 &&
      this.state.email !== "" &&
      this.state.email_error === ""
    ) {
      await this.getReservationDetails();
      var pagestate = this.state.pagestate;
      await this.setState({ pagestate: pagestate + 1 });
    } else {
      if (this.state.is_notification_1 === "") {
        await this.setState({
          is_notification_1: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_2 === "") {
        await this.setState({
          is_notification_2: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_3 === "") {
        await this.setState({
          is_notification_3: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_4 === "") {
        await this.setState({
          is_notification_4: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_5 === "") {
        await this.setState({
          is_notification_5: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_6 === "") {
        await this.setState({
          is_notification_6: false,
          classbox: "checkbox_valid",
        });
      }
      // if (this.state.is_notification_7 === "") {
      //   await this.setState({
      //     is_notification_7: false,
      //     classbox: "checkbox_valid",
      //   });
      // }
      if (this.state.is_notification_8 === "") {
        await this.setState({
          is_notification_8: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_9 === "") {
        await this.setState({
          is_notification_9: false,
          classbox: "checkbox_valid",
        });
      }
      // if (this.state.is_notification_10 === "") {
      //   await this.setState({
      //     is_notification_10: false,
      //     classbox: "checkbox_valid",
      //   });
      // }
      if (this.state.is_notification_11 === "") {
        await this.setState({
          is_notification_11: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_12 === "") {
        await this.setState({
          is_notification_12: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_13 === "") {
        await this.setState({
          is_notification_13: false,
          classbox: "checkbox_valid",
        });
      }
      if (this.state.is_notification_14 === "") {
        await this.setState({
          is_notification_14: false,
          classbox: "checkbox_valid",
        });
      }
    }
    if (this.state.pagestate === 2) {
      await this.setState({ is_submit: true });
      if (
        this.state.is_notification_1 === true &&
        this.state.is_notification_2 === true &&
        this.state.is_notification_3 === true &&
        this.state.is_notification_4 === true &&
        this.state.is_notification_5 === true &&
        this.state.is_notification_6 === true &&
        // this.state.is_notification_7 === true &&
        this.state.is_notification_8 === true &&
        this.state.is_notification_9 === true &&
        // this.state.is_notification_10 === true &&
        this.state.is_notification_11 === true &&
        this.state.is_notification_12 === true &&
        this.state.is_notification_13 === true &&
        this.state.is_notification_14 === true
      ) {
        var pagestate = this.state.pagestate;
        await this.setState({ pagestate: pagestate + 1 });
        window.scrollTo(0, 0);
      } else {
        await this.setState({ is_submit: false });
      }
    } else {
      if (this.state.pagestate === 3 && this.state.is_notification_16 === "") {
        await this.setState({ is_notification_16: false });
        this.scrollTop();
      }
      if (
        this.state.pagestate === 3 &&
        this.state.is_notification_16 &&
        this.state.signature !== ""
      ) {
        var pagestate = this.state.pagestate;
        await this.setState({ pagestate: pagestate + 1 });
        window.scrollTo(0, 0);
      }
      if (
        this.state.pagestate === 3 &&
        this.state.otp_verified_submit === true
      ) {
        var pagestate = this.state.pagestate;
        await this.setState({ pagestate: pagestate + 1 });
        window.scrollTo(0, 0);
      }
    }
  };

  setPrevious = async () => {
    var pagestate = this.state.pagestate;
    await this.setState({ pagestate: pagestate - 1, passwordflag: 1 });
    window.scrollTo(0, 0);
  };

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    // if(event.target.type=== "checkbox" && event.target.name === "is_notification"){
    //   this.setState({ property_check: true });
    // }
    // if(this.state.pagestate=== 2 && event.target.name !== "is_notification_15" && event.target.checked === false){
    //   this.setState({is_notification_15:false});
    // }
    // if(this.state.pagestate=== 2 && event.target.type === "checkbox" && event.target.checked===true && event.target.name === "is_notification_15"){
    //   this.setState({is_notification_1:true,
    //     is_notification_2:true,
    //     is_notification_3:true,
    //     is_notification_4:true,
    //     is_notification_5:true,
    //     is_notification_6:true,
    //     is_notification_7:true,
    //     is_notification_8:true,
    //     is_notification_9:true,
    //     is_notification_10:true,
    //     is_notification_11:true,
    //     is_notification_12:true,
    //     is_notification_13:true,
    //     is_notification_14:true})
    // }
    // if(this.state.pagestate=== 2 && event.target.type === "checkbox" && event.target.checked === false && event.target.name === "is_notification_15"){
    //   this.setState({is_notification_1:false,
    //     is_notification_2:false,
    //     is_notification_3:false,
    //     is_notification_4:false,
    //     is_notification_5:false,
    //     is_notification_6:false,
    //     is_notification_7:false,
    //     is_notification_8:false,
    //     is_notification_9:false,
    //     is_notification_10:false,
    //     is_notification_11:false,
    //     is_notification_12:false,
    //     is_notification_13:false,
    //     is_notification_14:false})
    // }
    // if (event.target.name === "email") {
    //   if (event.target.value.length > 8) {
    //     this.setState({ email_error: "" });
    //   }
    // }
    // if (event.target.name === "signature") {
    //   if (event.target.value ===  "") {
    //     this.setState({ signature_error: "Please enter valid signature" });
    //   } else{
    //     this.setState({ signature_error: "" });
    //   }
    // }

    // if (event.target.name === "email") {
    //   if (event.target.value.length > 7) {
    //     const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //     if (regex.test(event.target.value) === false ) {
    //       var email_error = "Please enter valid email.";
    //       this.setState({ email_error: email_error });
    //     } else {
    //       var email_error = "";
    //       this.setState({ email_error: email_error });
    //     }
    //   }
    // }
    //input[event.target.name] = value;
    if (event.target.name === "password") {
      if (event.target.value.length > 1) {
        var errors = this.state.error;
        errors.password = "";
        this.setState({ error: errors });
      }
    }
    this.setState({ [event.target.name]: value, input: input });
  };

  changeHandlerDeposite = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      [event.target.name]: value,
      input: input,
      card_agree_type: value,
    });
    if (value == 2 || value == 1) {
      this.updateStripeIntent(value);
    }
  };

  updateStripeIntent = async (value) => {
    let inputData = {
      stripe_id: this.state.stripe_id,
      card_agree_type: value,
      confirmation_code: this.state.confirmation_code,
    };
    let res = await CmsDataService.updateIntentStripeCard(inputData);
  };

  resetPasswordemail = async () => {
    var inputData = {
      email: this.state.email,
    };
    let res = await ReservationService.SendpasswordLink(inputData);
    if (global.successStatus.includes(res.status)) {
    } else {
      this.setState({ pagestate: this.state.pagestate });
    }
    this.setState({ loader: false });
  };

  changeHandlerPhone = (phoneNumber) => {
    this.setState({ phone_number: phoneNumber });
    if (phoneNumber.length > 8) {
      var errors = this.state.error;
      errors.phone_number = "";
      this.setState({ error: errors });
    }

    if (phoneNumber.length > 1) {
      var errors = this.state.error;
      errors.phone_number = "";
      this.setState({ error: errors });
    }
    if (phoneNumber.length > 15 || phoneNumber.length < 8) {
      var errors = this.state.error;
      errors.phone_number = "The phone number must be a valid phone number";
      this.setState({ error: errors });
    }
  };

  changeHandlerSignature = (event) => {
    let value = event.target.value;
    let input = this.state.input;
    if (event.target.name === "signature") {
      if (event.target.value === "") {
        this.setState({ signature_error: "Please enter valid signature" });
      } else {
        this.setState({ signature_error: "" });
      }
    }
    input[event.target.name] = value;
    this.setState({
      [event.target.name]: value,
      input: input,
      signature: value,
    });
  };

  changeHandlerEmail = (event) => {
    let value = event.target.value;
    let input = this.state.input;
    if (event.target.name === "email") {
      if (event.target.value.length > 8) {
        this.setState({ email_error: "" });
      }
    }
    if (event.target.name === "signature") {
      if (event.target.value === "") {
        this.setState({ signature_error: "Please enter valid signature" });
      } else {
        this.setState({ signature_error: "" });
      }
    }

    if (event.target.name === "email") {
      if (event.target.value.length > 7) {
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(event.target.value) === false) {
          var email_error = "Please enter valid email.";
          this.setState({ email_error: email_error });
        } else {
          var email_error = "";
          this.setState({ email_error: email_error });
        }
      }
    }
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input, email: value });
  };

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      this.setState({ email_error: "Please enter valid email." });
    } else {
      this.setState({ email_error: "" });
    }
    console.log("validate");
  };
  
  checkValidation = () => {
    let validation = true;
    let error = this.state.error;

    if (this.state.password === "") {
      error.password = "Please enter valid password.";
      validation = false;
    } else {
      let passwordCheck = global.OnKeyPressEvent.validatePassword(
        this.state.password
      );
      if (passwordCheck !== "") {
        error.password = passwordCheck;
        validation = false;
      }
    }

    this.setState({ error: error });
    return validation;
  };

  render() {
    const options = {
      clientSecret: this.state.clientToken,
      appearance: {
        /*...*/
      },
    };
    const days = this.state.currentData
      ? this.state.currentData.total_stay_nights
      : "";
    const depositeTitle =
      this.state.currentData?.deposite_checkbox_title.replace(
        "[amount]",
        this.state.deposite_amount ? this.state.deposite_amount : ""
      );
    // const daysamount = days > 32 ? "Purchase a $60 damage waiver":"Purchase a $30 damage waiver";
    const daysamount =
      days > 32
        ? this.state.currentData?.waiver_checkbox_title.replace(
            "[amount]",
            "$60" ? "$60" : ""
          )
        : this.state.currentData?.waiver_checkbox_title.replace(
            "[amount]",
            "$30" ? "$30" : ""
          );
    const step1Content = (
      <>
        <div className="container-fluid p-0">
          <section className="wzrd_main_section">
            <div className="container">
              <div className="content">
                <div className="row mobView wizard_new_heading">
                  <div className="wzrd_middle_desk">
                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
                      <div className="wzrd_heading_box col-12 ">
                        <p className="welcom_text_wzrd">
                          <strong>
                            Hi{" "}
                            {this.state.currentData.guest_user
                              ? this.state.currentData.guest_user.first_name
                              : "-"}
                            ,
                          </strong>{" "}
                          Please complete the Guest Agreement to access your
                          reservation and check-in details. You must complete
                          all sections or you won't be granted entry into the
                          apartment.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
                      <div className="wzrd_heading_box col-12 m-0 scroll_phn_safari">
                        <div className="wizardInfo pt-0">
                          <h5>
                            {this.state.currentData.listing_name
                              ? this.state.currentData.listing_name
                              : ""}
                          </h5>
                          <p className="prop_wzd_loc">
                            <img src="https://d1xsfycladxeij.cloudfront.net/website/images/pin.svg" />{" "}
                            {this.state.currentData &&
                            this.state.currentData.property &&
                            this.state.currentData.property.state
                              ? this.state.currentData.property.state
                                  .state_name + ", "
                              : ""}
                            {this.state.currentData &&
                            this.state.currentData.property &&
                            this.state.currentData.property.country
                              ? this.state.currentData.property.country
                                  .country_name + "."
                              : ""}
                          </p>
                        </div>
                        <div class="wizard_timing mg-t-20">
                          <ul className="fst_wzd_list">
                            <li>
                              <strong>Check-in</strong>
                              <span class="checkdate-  d-block">
                                {" "}
                                {this.state.currentData.checkin_date
                                  ? Moment(
                                      this.state.currentData.checkin_date
                                    ).format("MM/DD/YY")
                                  : "-"}{" "}
                                |{" "}
                                {this.state.currentData
                                  ? this.state.currentData.property
                                      ?.check_in_time
                                  : ""}
                              </span>
                            </li>
                            <li>
                              <strong>Check-out</strong>
                              <span class="checkdate-  d-block">
                                {" "}
                                {this.state.currentData.checkout_date
                                  ? Moment(
                                      this.state.currentData.checkout_date
                                    ).format("MM/DD/YY")
                                  : "-"}{" "}
                                |{" "}
                                {this.state.currentData.property
                                  ? this.state.currentData.property
                                      .check_out_time
                                  : ""}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="wzrd_mid_heading">
                          <h5>Reservation details</h5>
                          <div class="wizard_timing">
                            <ul className="resr_detl_list">
                              <li>
                                <strong>Confirmation code</strong>
                                <span class="checkdate-  d-block">
                                  {" "}
                                  {this.state.currentData.confirmation_code
                                    ? this.state.currentData.confirmation_code
                                    : "-"}
                                </span>
                              </li>
                              <li>
                                <strong>No. of guests</strong>
                                <span class="checkdate-  d-block">
                                  {this.state.currentData.number_of_guest &&
                                  this.state.currentData.number_of_guest !==
                                    null
                                    ? this.state.currentData.number_of_guest
                                    : "-"}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="wzrd_mid_heading">
                          <h5>Your contact info</h5>
                          <div class="wizard_timing ">
                            <ul className="full_len pc_view_wzd">
                              <li className="">
                                <strong>Name</strong>
                                <span class="checkdate-  d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user
                                        .first_name +
                                      " " +
                                      this.state.last_name
                                    : "-"}
                                </span>
                              </li>
                              <li className="position-relative">
                                <strong>Mobile</strong>
                                <span class="checkdate-  d-block">
                                  {" "}
                                  <PhoneNumberFormat
                                    phone_number={this.state.phone_number}
                                  />
                                  <span class="edit_phone">
                                    <img
                                      src="https://did1d8jg9ar6w.cloudfront.net/website/images/edit-icon.svg"
                                      alt=""
                                      onClick={this.editablePhone}
                                    />
                                  </span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="wzrd_mid_heading border-0 wzrd_100b row mob_row_reserv pt-0">
                          <div className="col-xs-6 col-sm-6 col-md-6 pl-cust-two-0 wzrd_phone mg-b-15">
                            <div className="countryphone">
                              <TextField
                                margin="normal"
                                className=" textfield w-100 m-0"
                                required
                                fullWidth
                                value={this.state.email ? this.state.email : ""}
                                onBlur={() => this.validateEmail()}
                                onChange={this.changeHandlerEmail}
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="off"
                              />
                              <p className="error-message">
                                {this.state.email_error
                                  ? this.state.email_error
                                  : ""}
                              </p>
                            </div>
                          </div>

                          {this.state.editablePhone === true ? (
                            <div className="col-xs-6 col-sm-6 col-md-6 pl-cust-0 wzrd_phone mg-b-15">
                              <PhoneInput
                                country={global.country}
                                onlyCountries={["us"]}
                                className=" textfield phoneInput"
                                variant="outlined"
                                autoFormat="true"
                                enableSearch="true"
                                countryCodeEditable="true"
                                id="phone_number"
                                name="phone_number"
                                value={
                                  this.state.phone_number
                                    ? this.state.phone_number
                                    : ""
                                }
                                disableCountryCode={true}
                                disableDropdown={true}
                                onChange={(e) => this.changeHandlerPhone(e)}
                                error={
                                  this.state.error.phone_number ? true : false
                                }
                                helperText={
                                  this.state.error.phone_number
                                    ? this.state.error.phone_number
                                    : ""
                                }
                                placeholder="Phone number"
                                fullWidth
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="wzrd_mid_heading border-0 wzrd_100b row pt-0">
                          {this.state.passwordflag == 0 &&
                          this.state.set_channel !== 1 &&
                          this.state.lock_type == 2 &&
                          this.state.is_latch_enable == 1 ? (
                            <div className="col-xs-6 col-sm-6 col-md-6 pl-cust-two-0 wzrd_phone position-relative">
                              <div className="countryphone">
                                <TextField
                                  margin="normal"
                                  className="textfield phoneInput m-0"
                                  required
                                  fullWidth
                                  id="password"
                                  label="Password"
                                  name="password"
                                  type={this.state.passwordType}
                                  onBlur={() => this.passwordCheckFun()}
                                  onChange={this.changeHandler}
                                  error={
                                    this.state.error.password ? true : false
                                  }
                                  value={this.state.password}
                                  autocomplete="off"
                                  // autoComplete={false}
                                  // autoFocus
                                />
                                {this.state.passwordType === "password" ? (
                                  <span
                                    className="eye-icon"
                                    onClick={this.togglePassword}
                                  >
                                    {" "}
                                    <img src={openeye} alt="" />
                                  </span>
                                ) : (
                                  <span
                                    className="eye-icon"
                                    onClick={this.togglePasswordHide}
                                  >
                                    <img src={closeeye} alt="" />
                                  </span>
                                )}

                                {/* <span className="eye-icon">
                                  <img src={openeye} alt="" />
                                  <img src={closeeye} alt="" />
                                </span> */}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <p className="error-message p-0">
                            {this.state.error.password
                              ? this.state.error.password
                              : ""}
                          </p>

                          {this.state.passwordflag == 0 &&
                          this.state.set_channel !== 1 &&
                          this.state.lock_type == 2 &&
                          this.state.is_latch_enable == 1 ? (
                            <div className="row align-items-start flex-nowrap pd-r-10 pd-l-10 mg-b-15 mg-t-15 text-align-left">
                              <div className="flex-wrap service_terms p-0">
                                <span className="agree">
                                  By selecting Next, I agree to OHAI's{" "}
                                  <a
                                    href={"/terms-condition"}
                                    title="Terms of Service"
                                    target="_blank"
                                  >
                                    {" "}
                                    Terms of Service{" "}
                                  </a>{" "}
                                  and{" "}
                                  <a
                                    href={"/privacy"}
                                    title="Privacy Policy"
                                    target="_blank"
                                  >
                                    {" "}
                                    Privacy Policy{" "}
                                  </a>
                                  .
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <p
                        className="guest_last_checkin m-0"
                        id="PrivateSwitchBase-input-email"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );

    const step2Content = (
      <>
        <div className="container-fluid p-0">
          <section className="wzrd_main_section">
            <div className="container">
              <div className="content">
                <div className="row mobView wizard_new_heading">
                  <div className="wzrd_middle_desk">
                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                      <div className="wzrd_heading_box col-12 m-0">
                        <h4>House rules</h4>
                      </div>
                    </div>

                    <div className="pc_middle_wzd">
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard ">
                            <span className="checkbox_valid_star">*</span>
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_1}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I understand that this reservation is in a community and I will abide by  all building, platform, management, and local rules. I will be a great guest."
                                  required={true}
                                  name="is_notification_1"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_1
                                      ? this.state.is_notification_1
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_1}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree to be a great guest"
                                required={true}
                                name="is_notification_1"
                                onChange={(e) => this.changeHandler(e)}
                                className="wizard_check"
                                helperText={
                                  this.state.is_notification_1
                                    ? this.state.is_notification_1
                                    : ""
                                }
                              />
                              <p className="error-message">
                                {this.state.is_notification_1 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree to NO SMOKING and to bring No Weapons of any kind.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_2}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to NO SMOKING and to bring No Weapons of any kind."
                                  required={true}
                                  name="is_notification_2"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_2
                                      ? this.state.is_notification_2
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_2}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_2"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_2 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_2
                                    ? this.state.is_notification_2
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_2 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree to NO PARTIES or events</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_3}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to NO PARTIES or events."
                                  required={true}
                                  name="is_notification_3"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_3
                                      ? this.state.is_notification_3
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_3}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_3"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_3 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_3
                                    ? this.state.is_notification_3
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_3 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree to NO TAMPERING of smoke detector. I acknowledge that this is illegal and will result in a $1,500 fine.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_4}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to NO TAMPERING of smoke detector. I acknowledge that this is illegal and will result in a $1,500 fine."
                                  required={true}
                                  name="is_notification_4"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_4
                                      ? this.state.is_notification_4
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_4}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_4"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_4 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_4
                                    ? this.state.is_notification_4
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_4 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree to NO OUTSIDE GUESTS. Only guests added to my reservation will be on the property.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_5}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to NO OUTSIDE GUESTS. Only guests added to my reservation will be on the property."
                                  required={true}
                                  name="is_notification_5"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_5
                                      ? this.state.is_notification_5
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_5}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_5"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_5 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_5
                                    ? this.state.is_notification_5
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_5 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree that the minimum guest age is 22, unless children are accompanied by parents.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_6}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree that the minimum guest age is 22, unless children are accompanied by parents."
                                  required={true}
                                  name="is_notification_6"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_6
                                      ? this.state.is_notification_6
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_6}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_6"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_6 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_6
                                    ? this.state.is_notification_6
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_6 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading d-none">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I acknowledge that only TWO bath towels per bed, per stay is provided.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_7}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I acknowledge that only TWO bath towels per bed, per stay is provided."
                                  required={true}
                                  name="is_notification_7"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_7
                                      ? this.state.is_notification_7
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_7}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_7"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_7 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_7
                                    ? this.state.is_notification_7
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_7 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree to close all windows, AC, and doors (and make sure locked and returned) at the end of my stay</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_8}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to close all windows and doors (and make sure they are locked) when leaving."
                                  required={true}
                                  name="is_notification_8"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_8
                                      ? this.state.is_notification_8
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_8}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_8"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_8 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_8
                                    ? this.state.is_notification_8
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_8 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree and acknowledge that the LOST KEY or misplacement of the apartment key, key fobs, and any other item on the key chain will result in a $500 fine.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_9}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree and acknowledge that the LOST KEY or misplacement of the apartment key, key fobs, and any other item on the key chain will result in a $500 fine."
                                  required={true}
                                  name="is_notification_9"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_9
                                      ? this.state.is_notification_9
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_9}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_9"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_9 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_9
                                    ? this.state.is_notification_9
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_9 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading d-none">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree to tidy up after myself and leave the apartment in a clean condition, and that is why the cleaning fee is low.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_10}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to tidy up after myself and leave the apartment in a clean condition, and that is why the cleaning fee is low."
                                  required={true}
                                  name="is_notification_10"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_10
                                      ? this.state.is_notification_10
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_10}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_10"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_10 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_10
                                    ? this.state.is_notification_10
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_10 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-10 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I agree to be a clean guest, and I understand that I am responsible for damages to the property inside of the apartment. I understand that the cleaner before my arrival has checked all of the items in the room, and so damages inside of the room during my stay are my responsibility. I agree that there is a cost associated with damaging items, and that damaged items need to be replaced. I understand that many items come in a set (for example, sheets and towels), and so if there are stains or damages to one part of a set, the whole sheet set needs to be replaced and paid for. All replacement items will have a $20.00 service charge to order and replace the items.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_11}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to be a clean guest, and I understand that I am responsible for damages to the property inside of the apartment. I understand that the cleaner before my arrival has checked all of the items in the room, and so damages inside of the room during my stay are my responsibility. I agree that there is a cost associated with damaging items, and that damaged items need to be replaced. I understand that many items come in a set (for example, sheets and towels), and so if there are stains or damages to one part of a set, the whole sheet set needs to be replaced and paid for. All replacement items will have a $20.00 service charge to order and replace the items."
                                  required={true}
                                  name="is_notification_11"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_11
                                      ? this.state.is_notification_11
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_11}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_11"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_11 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_11
                                    ? this.state.is_notification_11
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_11 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>I acknowledge that if the apartment is left is a condition that will cause the cleaning team to spend extra time cleaning, that I am responsible for paying for the extra cleaning time.</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_12}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I acknowledge that if the apartment is left is a condition that will cause the cleaning team to spend extra time cleaning, that I am responsible for paying for the extra cleaning time."
                                  required={true}
                                  name="is_notification_12"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_12
                                      ? this.state.is_notification_12
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_12}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_12"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_12 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_12
                                    ? this.state.is_notification_12
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_12 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>House Rules
                            I agree to NO PETS and that unapproved and/or unregistered guests will result in a $500 additional cleaning fee.</p>
                             */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_13}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="I agree to NO PETS and that unapproved and/or unregistered guests will result in a $500 additional cleaning fee."
                                  required={true}
                                  name="is_notification_13"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_13
                                      ? this.state.is_notification_13
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_13}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_13"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_13 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_13
                                    ? this.state.is_notification_13
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_13 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-5">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard scroll_phn_safari">
                            <span className="checkbox_valid_star">*</span>
                            {/* <p>Additional House Rules</p> */}
                            <p>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.is_notification_14}
                                      className="d-none"
                                    />
                                  }
                                  labelPlacement="end"
                                  label="Additional House Rules"
                                  required={true}
                                  name="is_notification_14"
                                  onChange={(e) => this.changeHandler(e)}
                                  className="wizard_check"
                                  helperText={
                                    this.state.is_notification_14
                                      ? this.state.is_notification_14
                                      : ""
                                  }
                                />
                              </FormGroup>
                            </p>
                            <ul>
                              {/* <li > Smoking is not allowed in the unit and/or the building.</li> */}
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.is_notification_14}
                                        className="d-none"
                                      />
                                    }
                                    labelPlacement="end"
                                    label=" Smoking is not allowed in the unit and/or the building."
                                    required={true}
                                    name="is_notification_14"
                                    onChange={(e) => this.changeHandler(e)}
                                    className="wizard_check"
                                    helperText={
                                      this.state.is_notification_14
                                        ? this.state.is_notification_14
                                        : ""
                                    }
                                  />
                                </FormGroup>
                              </li>

                              {/* <li>Smoking is not allowed on any balconies.</li> */}
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.is_notification_14}
                                        className="d-none"
                                      />
                                    }
                                    labelPlacement="end"
                                    label="Smoking is not allowed on any balconies."
                                    required={true}
                                    name="is_notification_14"
                                    onChange={(e) => this.changeHandler(e)}
                                    className="wizard_check"
                                    helperText={
                                      this.state.is_notification_14
                                        ? this.state.is_notification_14
                                        : ""
                                    }
                                  />
                                </FormGroup>
                              </li>
                              {/* <li>Agree to check-in and check-out times.</li> */}
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.is_notification_14}
                                        className="d-none"
                                      />
                                    }
                                    labelPlacement="end"
                                    label="Agree to check-in and check-out times."
                                    required={true}
                                    name="is_notification_14"
                                    onChange={(e) => this.changeHandler(e)}
                                    className="wizard_check"
                                    helperText={
                                      this.state.is_notification_14
                                        ? this.state.is_notification_14
                                        : ""
                                    }
                                  />
                                </FormGroup>
                              </li>
                              {/* <li>Agree to leave the apartment in a clean condition in order to not incur higher cleaning fees.</li> */}
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.is_notification_14}
                                        className="d-none"
                                      />
                                    }
                                    labelPlacement="end"
                                    label="Agree to leave the apartment in a clean condition in order to not incur higher cleaning fees."
                                    required={true}
                                    name="is_notification_14"
                                    onChange={(e) => this.changeHandler(e)}
                                    className="wizard_check"
                                    helperText={
                                      this.state.is_notification_14
                                        ? this.state.is_notification_14
                                        : ""
                                    }
                                  />
                                </FormGroup>
                              </li>
                              {/* <li>Agree that there are charges for replacement of any damages to the property, decor, or furnishes.</li> */}
                              <li>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.is_notification_14}
                                        className="d-none"
                                      />
                                    }
                                    labelPlacement="end"
                                    label="Agree that there are charges for replacement of any damages to the property, decor, or furnishes."
                                    required={true}
                                    name="is_notification_14"
                                    onChange={(e) => this.changeHandler(e)}
                                    className="wizard_check"
                                    helperText={
                                      this.state.is_notification_14
                                        ? this.state.is_notification_14
                                        : ""
                                    }
                                  />
                                </FormGroup>
                              </li>
                              {/* <li> Agree that there are are charges for replacement of any damages to the property, decor, or furnishes.</li> */}
                              <li className="d-none">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.is_notification_14}
                                        className="d-none"
                                      />
                                    }
                                    labelPlacement="end"
                                    label="Agree that there are are charges for replacement of any damages to the property, decor, or furnishes."
                                    required={true}
                                    name="is_notification_14"
                                    onChange={(e) => this.changeHandler(e)}
                                    className="wizard_check"
                                    helperText={
                                      this.state.is_notification_14
                                        ? this.state.is_notification_14
                                        : ""
                                    }
                                  />
                                </FormGroup>
                              </li>
                            </ul>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_14}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree"
                                required={true}
                                name="is_notification_14"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_14 ? true : false
                                }
                                helperText={
                                  this.state.is_notification_14
                                    ? this.state.is_notification_14
                                    : ""
                                }
                                className="wizard_check"
                              />
                              <p className="error-message">
                                {this.state.is_notification_14 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
    const step3Content = (
      <>
        <div className="container-fluid p-0">
          <section className="wzrd_main_section">
            <div className="container">
              <div className="content">
                <div className="row mobView wizard_new_heading ">
                  <div className="wzrd_middle_desk wzrd_middle_desk_new ">
                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                      <div className="wzrd_heading_box col-12 m-0">
                        <h4>Guest accommodations agreement</h4>
                      </div>
                    </div>
                    <div className="pc_middle_wzd">
                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                        <div className="wzrd_heading_box col-12 mb-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 p-0 check_wizard scroll_phn_safari">
                            <p>Last updated on November 11, 2022</p>
                            <p>
                              Rental Period: The rental period begins at
                              “Check-in time” on the "Check-in Date" and ends at
                              “Check-out Time” on "Check-out Date",
                              collectively, the "Rental Period".
                            </p>
                            <p>
                              OHAI (the "Company", "we" or "us") is committed to
                              being a great neighbor in our communities. That’s
                              why all OHAI guests (the "Guest" or "you") must
                              agree to abide by this Guest Accommodation
                              Agreement (the "Agreement), OHAI’s Website Terms
                              of Use, found at{" "}
                              <a
                                href="orionhaus.com"
                                className=""
                                target="_blank"
                              >
                                https://www.orionhaus.com
                              </a>
                              , and OHAI's Privacy Policy, found at{" "}
                              <a
                                href="orionhaus.com"
                                className=""
                                target="_blank"
                              >
                                https://www.orionhaus.com
                              </a>{" "}
                              incorporated by reference herein and referred to
                              collectively as the “OHAI House Rules”) in
                              consideration for their accommodation in a rental
                              unit (the "OHAI") located in an OHAI property (the
                              "Property").
                            </p>
                            <p>
                              Capitalized terms used but not otherwise defined
                              herein shall have the meanings provided to such
                              terms in the Guest Accommodation Agreement signed
                              by the Guest in consideration for their
                              accommodation in OHAI.
                            </p>
                            <p>
                              {" "}
                              <b>ABSOLUTELY NO SMOKING</b>{" "}
                            </p>
                            <p>
                              We prohibit smoking of any kind. This includes but
                              is not limited to tobacco, marijuana,
                              e-cigarettes, vape pens, and hookahs. This applies
                              to any part of the Property (including hallways,
                              lounges, rooftops, fitness centers, and all
                              amenity areas), any part of your reserved OHAI
                              apartment (including the balcony or patio), and
                              within 30 feet of any public entrance. The Company
                              may operate marijuana and tobacco sensors in order
                              to help enforce this rule.
                            </p>
                            <p>
                              {" "}
                              <b>MINIMUM AGE</b>{" "}
                            </p>
                            <p>
                              The registered Guest must be 22 years of age or
                              older, unless otherwise permitted by the booking
                              channel through which the reservation is made.
                              Proof of identity and age is required for
                              check-in.
                            </p>
                            <p>
                              {" "}
                              <b>ARRIVAL / EARLY ARRIVAL</b>{" "}
                            </p>
                            <p>
                              The arrival time is 3:00 p.m. on the first day of
                              your reservation. An earlier arrival may be
                              allowed subject to availability and early check in
                              fee.
                            </p>
                            <p>
                              {" "}
                              <b>DEPARTURE / LATE DEPARTURE</b>{" "}
                            </p>
                            <p>
                              The departure time is 11:00 a.m. on the last day
                              of your reservation. We reserve the right to
                              charge late fees for departures after 11:00 a.m. A
                              later departure may be allowed subject to
                              availability and late check out fee.
                            </p>
                            <p>
                              {" "}
                              <b>IDENTITY VERIFICATION</b>{" "}
                            </p>
                            <p>
                              To protect against identity theft and fraud, and
                              for the safety of other Guests and neighbors in
                              the community, we ask that the Guest completing
                              the OHAI reservation provide a scanned or
                              photographed copy of their valid,
                              government-issued photo ID (passport or state
                              driver’s license), with the Guest’s face, address,
                              date of birth and photo clearly visible and in
                              focus. The reservation billing information must
                              match the information on the government-issued ID
                              of the Guest making the reservation. We reserve
                              the right to refuse service to individuals who do
                              not furnish this information. In the event that a
                              Guest refuses to provide their valid,
                              government-issued ID after making a reservation,
                              the Guest’s reservation will be cancelled in
                              accordance with the section titled “Cancellation”
                              below.
                            </p>
                            <p>
                              {" "}
                              We expect that you, the Guest identified above,
                              will stay at the OHAI during the entire
                              reservation. Anyone else who stays at or visits
                              the OHAI during your reservation shall be deemed a
                              “Visitor” hereunder.{" "}
                            </p>
                            <p>
                              {" "}
                              <b>BACKGROUND SCREENING</b>{" "}
                            </p>
                            <p>
                              We may, but are not obligated to, obtain
                              background reports from consumer reporting
                              agencies or otherwise review publicly available
                              information about you, including sexual offender
                              registration lists or databases compiling
                              information about criminal convictions. By making
                              a reservation with us, you authorize us to use
                              your personal information (including name,
                              address, date of birth, or other identifying
                              information) to obtain these reports, and agree
                              that we may, in our sole discretion, suspend,
                              cancel, block, restrict or terminate your access
                              to our services or reservations made or
                              contemplated, based on our evaluation of such
                              reports.
                            </p>
                            <p>
                              Please see our Privacy Policy, found at
                              https://www.orionhaus.com, for further information
                              regarding our background screening process.
                            </p>
                            <p>
                              {" "}
                              <b>RIGHT TO REFUSE SERVICE</b>{" "}
                            </p>
                            <p>
                              The Company may refuse service to, or remove from
                              OHAI, any individual for any reason(s) that does
                              not violate federal or state laws. Reasons for
                              refusing service to, or removing, a Guest or any
                              other individual include, but are not limited to:
                              refusal or failure to pay for accommodations;
                              being under the influence of alcohol, drugs, or
                              any other intoxicating substance such that the
                              individual is disturbing the peace; seeking to use
                              the OHAI for an unlawful purpose; seeking to bring
                              into the OHAI an unlawfully-possessed firearm or
                              any item, including an explosive or hazardous or
                              toxic substance, that is unlawful to possess and
                              that may be dangerous to other persons;
                              destroying, damaging, or defacing the Property or
                              threatening harm to the Property, other guests, or
                              residents of the Property; causing or permitting
                              persons to exceed the maximum allowable occupancy
                              of the OHAI; or refusing to abide by the
                              reasonable standards or policies established by
                              the Company, including, without limitation, the
                              OHAI House Rules and any published rules and
                              regulations imposed by the apartment community.
                            </p>
                            <p>
                              {" "}
                              <b>NOISE POLICY</b>{" "}
                            </p>
                            <p>
                              The Guest may not disturb the quiet enjoyment of
                              any other Guest of the Company, resident of the
                              Property, or surrounding neighbors. At all times,
                              the Guest shall refrain from making loud noises
                              and excessive television and/or radio volume that
                              may disturb others. The Guest shall observe quiet
                              hours from 9:00 p.m. to 9:00 a.m. Parties and
                              gatherings are prohibited.
                            </p>
                            <p>
                              OHAI apartments are proactively monitored for
                              compliance with this noise policy. Decibel sensors
                              notify the Company of sounds in the OHAI that
                              exceed 75 decibels (dB) or other thresholds that
                              may vary depending on location. You hereby consent
                              to the use of sound level monitoring. In the event
                              that the Company receives a noise complaint from a
                              community member and/or receives a notification
                              that the noise level in the OHAI has exceeded 75dB
                              or other thresholds that may vary depending on
                              location, the Company will contact the Guest and
                              issue a first warning to reduce the noise level.
                              If, after the first warning, Company subsequently
                              receives an additional noise complaint and/or
                              notification that the noise level in the OHAI has
                              exceeded 75dB or other thresholds that may vary
                              depending on location, the Company reserves the
                              right, in its sole discretion, to charge the Guest
                              a penalty of $1,000 and/or remove the Guest from
                              the Property without a refund.
                            </p>
                            <p>
                              {" "}
                              <b>VISITORS</b>{" "}
                            </p>
                            <p>
                              Visitors must be accompanied by the Guest at all
                              times. The Guest must obtain the Company’s express
                              permission for Visitor use of Property amenities
                              (including the pool, gym, and business center).
                              The Guest is responsible and liable for the
                              actions of all Visitors.
                            </p>
                            <p>
                              {" "}
                              <b>NO SHOW CHARGES</b>{" "}
                            </p>
                            <p>
                              Your reserved OHAI will be held for you until 4:00
                              pm the day following the Arrival Date. If you do
                              not check in by that time and have not otherwise
                              notified the Company that your arrival will be
                              delayed, your reservation will be considered a “No
                              Show” and will be cancelled. You will forfeit all
                              payments made to the Company related to the
                              reservation and will not be entitled to a refund.
                            </p>
                            <p>
                              If you are aware that your arrival will be delayed
                              past 4:00 pm the day following the Arrival Date,
                              please contact us as soon as possible to avoid
                              your reservation being deemed a No Show.
                            </p>
                            <p>
                              {" "}
                              <b>ACCESS TO APARTMENTS</b>{" "}
                            </p>
                            <p>
                              You acknowledge that your reservation of the OHAI
                              does not confer exclusive possession or exclusive
                              access to the OHAI. We retain the right to access
                              and enter the OHAI at any time as needed, however,
                              we will attempt to notify you before entering the
                              OHAI as a courtesy to you except in the event of
                              an emergency. If called upon, law enforcement will
                              be granted immediate access.
                            </p>
                            <p>
                              {" "}
                              <b>LOST & FOUND POLICY</b>{" "}
                            </p>
                            <p>
                              The Company assumes no liability for lost,
                              misplaced, stolen, or damaged belongings,
                              including any belongings which are left in the
                              OHAI after your departure time has passed. If you
                              discover that you have left something, please
                              contact our team immediately and we will do our
                              best to retrieve it for you. However, you will be
                              responsible for any shipping and personnel charges
                              associated with the return of your belongings.
                              Searching or finding any left items is not
                              guaranteed. Make sure to take all of your
                              belongings with you upon departure.
                            </p>
                            <p>
                              {" "}
                              <b>PET POLICY</b>{" "}
                            </p>
                            <p>No pets are allowed.</p>
                            <p>
                              {" "}
                              <b>SERVICE ANIMALS</b>{" "}
                            </p>
                            <p>
                              Service animals may accompany our guests to any
                              OHAI property, under the following conditions:
                            </p>
                            <ul>
                              <li>
                                <p>
                                  The Company is unable to accommodate any
                                  service animals who have ever behaved
                                  violently or aggressively towards a person or
                                  another pet.
                                </p>
                              </li>
                              <li>
                                <p>
                                  Your service animal must be up to date with
                                  all licensing* and vaccination requirements of
                                  the state and local jurisdiction where the
                                  guest permanently resides.
                                </p>
                              </li>
                              <li>
                                <p>
                                  You must furnish copies of your service
                                  animal’s current licensing* and vaccination
                                  records should the Company request
                                  documentation thereof.
                                </p>
                              </li>
                              <li>
                                <p>
                                  You must never leave your service animal
                                  unattended in the rental unit.
                                </p>
                              </li>
                              <li>
                                <p>
                                  You must in all cases notify the Company that
                                  you will be bringing a service animal as part
                                  of your reservation, and confirm that you are
                                  following all service animal related rules as
                                  described on the Booking Channel and the
                                  Company website.
                                </p>
                              </li>
                            </ul>
                            <p>
                              *Please note that OHAI does NOT request
                              information or documentation concerning the
                              licensing and/or certification of your animals as
                              qualified service animals. The terms “licensed”
                              and “licensing” as used above refer only to the
                              basic licensing and registration of an animal’s
                              ownership that is typically required in most
                              states and local jurisdictions.
                            </p>
                            <p>
                              {" "}
                              <b>CHILDREN</b>{" "}
                            </p>
                            <p>
                              Children of all ages are welcome. Guests are
                              responsible for the actions of all minors who
                              accompany them in the OHAI and on the premises.
                              Children are prohibited from entering the amenity
                              areas (including the pool, gym, and rooftops)
                              unless under immediate adult supervision.
                            </p>
                            <p>
                              {" "}
                              <b>MAXIMUM OCCUPANCY</b>{" "}
                            </p>
                            <p>
                              Room occupancy limits are determined by the fire
                              code and fire safety restrictions, as well as any
                              local statutes or ordinances. Guests are required
                              to comply with maximum occupancy limits. Please
                              refer to your Booking Channel or the Company
                              website for the maximum occupancy of the OHAI you
                              have reserved. All guests must be registered.
                            </p>
                            <p>
                              {" "}
                              <b>APARTMENT KEYS / ACCESS CODES</b>{" "}
                            </p>
                            <p>
                              Access codes are issued to the registered Guest on
                              the day of arrival and work at the Check-in time.
                              No access codes will be issued to anyone under 18
                              years of age at any time.
                            </p>
                            <p>
                              {" "}
                              <b>RECEIVING MAIL AND PACKAGES</b>{" "}
                            </p>
                            <p>
                              The Company cannot accept any package or mail
                              deliveries sent to the OHAI. Instead, we recommend
                              that you have your mail or package shipped to a
                              nearby UPS store, FedEx store or Amazon Lockers.
                            </p>
                            <p>
                              {" "}
                              <b>
                                PARKING PASSES / AMENITY KEYS / POOL WRISTBANDS
                              </b>{" "}
                            </p>
                            <p>
                              The OHAI you have reserved may include parking
                              passes, amenity keys, and/or pool wristbands,
                              among other items that are provided to ensure full
                              access to the Property and its facilities. Failure
                              to return these items at the time of your
                              departure will result in a fee of $250 for each
                              unreturned item.
                            </p>
                            <p>
                              {" "}
                              <b>CLEANING</b>{" "}
                            </p>
                            <p>
                              All OHAI apartments are cleaned prior to each new
                              Guest’s arrival. The Company does not provide
                              nightly housekeeping.
                            </p>
                            <p>
                              {" "}
                              <b>INFESTATION</b>{" "}
                            </p>
                            <p>
                              If the Company determines that you have brought
                              any infestation into the OHAI or onto the
                              Property, the Company reserves the right to charge
                              you for any and all costs and expenses, including
                              immediate or urgent response requirements and loss
                              of room revenue, that the Company deems necessary
                              to address the infestation.
                            </p>
                            <p>
                              {" "}
                              <b>TRASH DISPOSAL</b>{" "}
                            </p>
                            <p>
                              Trash bins and trash bags are provided in each
                              OHAI. Trash must be discarded by The Guest prior
                              to departure from the OHAI. Directions to the
                              nearest trash area are provided in the Virtual
                              Front Desk. Failure to discard all trash may
                              result in a fine of $25 per occurrence.
                            </p>
                            <p>
                              {" "}
                              <b>POOL AND AMENITY AREAS</b>{" "}
                            </p>
                            <p>
                              No lifeguard is on duty in the pool areas. Guest
                              use of all amenities, including the pool and hot
                              tub areas, is at their own risk. The Company is
                              not responsible for any amenities or services that
                              are closed, out of service, or cannot be used for
                              any reason, and no refunds will be made.
                            </p>
                            <p>
                              {" "}
                              <b>ALCOHOL</b>{" "}
                            </p>
                            <p>
                              Guests of legal drinking age who bring alcoholic
                              beverages onto the Property must not consume such
                              beverages outside of their reserved OHAI unit.
                              Alcoholic beverages are prohibited in amenity
                              areas, including, but not limited to, the pool,
                              fitness center, rooftop, and lobby.
                            </p>
                            <p>
                              {" "}
                              <b>FIREARMS AND WEAPONS</b>{" "}
                            </p>
                            <p>
                              For the safety of our Guests and the community,
                              Guests are not permitted to carry firearms or
                              weapons in the OHAI or on the Property.
                            </p>
                            <p>
                              {" "}
                              <b>EMERGENCIES</b>{" "}
                            </p>
                            <p>
                              In the case of an emergency, call 911 immediately.
                              Please notify our team so we are aware and can be
                              of assistance.
                            </p>
                            <p>
                              {" "}
                              <b>WIFI ACCESS</b>{" "}
                            </p>
                            <p>
                              While the Company makes its best effort to provide
                              interruption-free, high-quality Internet access,
                              the Company assumes no liability in the event that
                              Internet access becomes unavailable. Guests are
                              prohibited from using WiFi for any illegal
                              activity, including, but not limited to, violating
                              the Digital Millennium Copyright Act. The Company
                              assumes no liability for Guest use of the
                              Internet.
                            </p>
                            <p>
                              {" "}
                              <b>PARKING AT OWN RISK</b>{" "}
                            </p>
                            <p>
                              All vehicles parked on the premises must be
                              registered with the Company prior to the Guest’s
                              arrival. Parking may be subject to a nightly fee.
                              Vehicles are parked at the risk of the owner. The
                              Company shall not assume liability or
                              responsibility for any vehicle, occupants, or
                              contents while operated or parked on the property.
                              If the Guest leaves a vehicle in the parking area
                              after departure without obtaining the written
                              consent of the Company, the Company reserves the
                              right to have the vehicle towed at the owner’s
                              expense.
                            </p>
                            <p>
                              {" "}
                              <b>WIFI ACCESS</b>
                            </p>
                            <p>
                              Guests are liable for any damage and/or theft at
                              the OHAI or Property caused by the Guest, their
                              Visitors, or other persons present in the OHAI
                              during the Guest’s stay. This includes damage to
                              rooms, fixtures, furnishings and equipment (such
                              as towels, artwork, electronics, and kitchen
                              equipment). The Company reserves the right to
                              retain the Guest’s payment method details and
                              charge the payment method the full amount that it
                              shall, in its sole discretion, deem necessary to
                              compensate or make good the cost or expenses
                              incurred or suffered by the Company as a result of
                              the damage and/or theft. Charges may include, but
                              are not limited to: repair costs for all property
                              damage; replacement costs for missing or damaged
                              items; smoking fees; and cleaning fees. The
                              Company will make reasonable efforts to mitigate
                              the costs of repairing the damage. In the event
                              that the OHAI or Property becomes un-rentable
                              through any action of the Guest, the Company
                              reserves the right to charge the Guest the nightly
                              rate as advertised on the Booking Channel where
                              the reservation was made until the OHAI may be
                              returned to a rentable condition, in addition to
                              charges for any damage caused by the Guest and/or
                              their Visitors.
                            </p>
                            <p>
                              {" "}
                              <b>
                                CHANGES OR MODIFICATIONS TO OHAI HOUSE RULES
                              </b>
                            </p>
                            <p>
                              The Company may modify or amend any of the OHAI
                              House Rules in writing at any time without prior
                              notice. Any written modification or amendment of
                              the OHAI Stay Rules that occurs prior to your
                              departure is hereby deemed incorporated into the
                              Agreement governing the reservation. Guests should
                              check our website regularly for updates to the
                              OHAI House Rules.
                            </p>
                            <p>
                              {" "}
                              <b>USE OF PREMISES</b>
                            </p>
                            <p>
                              The OHAI and Property amenity areas shall not be
                              used for any unlawful or illegal purpose. No
                              nails, screws, or other items may be driven into
                              any portion of the OHAI. The Guest shall use the
                              OHAI for residential purposes only. The Guest
                              shall not conduct any commercial, industrial, or
                              retail activity in the OHAI.
                            </p>
                            <p>
                              {" "}
                              <b>REMOVAL FROM THE PREMISES</b>
                            </p>
                            <p>
                              THE GUEST AGREES THAT THE COMPANY OR OTHER LAWFUL
                              AUTHORITY MAY REMOVE THE GUEST OR ANY VISITORS,
                              WITHOUT NOTICE, FOR ANY REASONS SUCH AS, BUT NOT
                              LIMITED TO: FAILURE TO LEAVE THE OHAI AT THE
                              DESIGNATED DEPARTURE DATE AND TIME (UNLESS
                              EXTENDED ACCORDING TO THE TERMS OF THE OHAI STAY
                              RULES AND AUTHORIZED IN ADVANCE BY THE COMPANY);
                              NONPAYMENT OF RESERVATION FEES OR OTHER CHARGES;
                              VIOLATION OF ANY RULE IN THE OHAI STAY RULES;
                              VIOLATION OF ANY RULES OF THE HOUSING DEVELOPMENT,
                              CONDOMINIUM ASSOCIATION, OR APARTMENT COMPLEX IN
                              WHICH THE OHAI IS LOCATED; ANY UNLAWFUL ACT OF THE
                              GUEST OR VISITORS. THE GUEST AGREES TO HOLD THE
                              COMPANY HARMLESS FOR ANY INCIDENT NECESSITATING
                              THE REMOVAL OF GUEST OR VISITORS FROM THE
                              PROPERTY.
                            </p>
                            <p>
                              {" "}
                              <b>NO TENANCY</b>
                            </p>
                            <p>
                              THIS AGREEMENT, WHETHER IN WHOLE OR PART, IS NOT A
                              LEASE OR RESIDENTIAL TENANCY AGREEMENT, AND DOES
                              NOT CONVEY OR OTHERWISE TRANSFER OR PROVIDE ANY
                              PROPERTY, LEGAL, OR BENEFICIAL RIGHTS OR INTEREST
                              IN REAL ESTATE TO YOU. YOU ARE NOT A TENANT OF,
                              AND HAVE NOT BEEN GRANTED EXCLUSIVE POSSESSION OF,
                              THE OHAI OR PROPERTY. YOU AGREE THAT THE OHAI IS
                              NOT YOUR PERMANENT RESIDENCE.
                            </p>
                            <p>
                              {" "}
                              <b>ARBITRATION</b>
                            </p>
                            <p>
                              Any dispute arising out of or related to this
                              Agreement, including, but not limited to, disputes
                              arising out of your stay at the OHAI, will be
                              submitted for and resolved by binding arbitration
                              before the American Arbitration Association (AAA).
                              The Company shall have the right in a proper case
                              to obtain temporary restraining orders, temporary
                              or preliminary injunctive relief and/or
                              declaratory relief (other than declarations with
                              respect to the amount of money damages) from a
                              court of competent jurisdiction. This agreement to
                              arbitrate disputes includes all claims arising out
                              of or relating to any aspect of this Agreement,
                              whether based in contract, tort, statute, fraud,
                              misrepresentation, or any other legal theory, and
                              regardless of whether a claim arises during or
                              after the termination of this Agreement. YOU
                              UNDERSTAND AND AGREE THAT, BY ENTERING INTO THIS
                              AGREEMENT, YOU AND THE COMPANY ARE EACH WAIVING
                              THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN
                              A CLASS ACTION.
                            </p>
                            <p>
                              The arbitration proceedings shall be heard by one
                              independent arbitrator who shall be an attorney or
                              retired judge. The arbitration shall be held in
                              Delaware and in accordance with the then-existing
                              Commercial Arbitration Rules of the AAA. All
                              matters within the scope of the Federal
                              Arbitration Act (9 U.S.C. 1, et seq.) will be
                              governed by it and not by any state arbitration
                              law. You and the Company waive any rights to
                              maintain other available resolution processes for
                              such disputes, such as a court action or
                              administrative proceeding, to settle disputes. You
                              and the Company waive any right to a jury trial
                              for such disputes. The rules in arbitration are
                              different from the rules that apply in court.
                              There is no judge or jury, and review is limited,
                              but an arbitrator can award the same damages and
                              relief, and must honor the same limitations stated
                              in this Agreement, as a court would.
                            </p>
                            <p>
                              The arbitration proceedings shall be heard by one
                              independent arbitrator who shall be an attorney or
                              retired judge. The arbitration shall be held in
                              Delaware and in accordance with the then-existing
                              Commercial Arbitration Rules of the AAA. All
                              matters within the scope of the Federal
                              Arbitration Act (9 U.S.C. 1, et seq.) will be
                              governed by it and not by any state arbitration
                              law. You and the Company waive any rights to
                              maintain other available resolution processes for
                              such disputes, such as a court action or
                              administrative proceeding, to settle disputes. You
                              and the Company waive any right to a jury trial
                              for such disputes. The rules in arbitration are
                              different from the rules that apply in court.
                              There is no judge or jury, and review is limited,
                              but an arbitrator can award the same damages and
                              relief, and must honor the same limitations stated
                              in this Agreement, as a court would.
                            </p>
                            <p>
                              Confidential. Other than as may be required by
                              law, the entire arbitration proceedings
                              (including, but not limited to, any rulings,
                              decisions or orders of the arbitrator), shall
                              remain confidential and not be disclosed to anyone
                              other than the parties to this Agreement.
                            </p>
                            <p>
                              Limitations Period. Any and all claims and actions
                              arising out of or relating to the Agreement shall
                              be commenced within one (1) year from the
                              occurrence of the facts giving rise to such claim
                              or action, or such claim or action shall be
                              barred.
                            </p>
                            <p>
                              Class Action Waiver. You agree that you will not
                              file a class action against the Company or
                              participate in a class action against the Company.
                              You agree that you will not file or seek a class
                              arbitration, or participate in a class
                              arbitration, against the Company.
                            </p>
                            <p>
                              {" "}
                              <b>GOVERNING LAW AND VENUE</b>
                            </p>
                            <p>
                              This Agreement shall be governed by, construed and
                              enforced in accordance with the laws of the State
                              of Delaware, United States, without regard to its
                              conflicts of law rules. The exclusive jurisdiction
                              for any dispute not covered by the terms of the
                              Arbitration provision set forth in this Agreement
                              may be filed only in the state or federal courts
                              located in the State of Delaware, United States.
                              YOU HEREBY WAIVE ANY RIGHT YOU MAY NOW HAVE OR
                              HEREAFTER POSSESS TO A TRIAL BY JURY. The
                              foregoing shall not apply to the extent that
                              applicable law in your country of residence
                              requires application of another law and/or
                              jurisdiction and this cannot be excluded by
                              contract.
                            </p>
                            <p>
                              {" "}
                              <b>DISCLAIMER OF WARRANTIES</b>
                            </p>
                            <p>
                              TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY
                              HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                              WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
                              OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
                              WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
                              AND FITNESS FOR PARTICULAR PURPOSE.
                            </p>
                            <p>
                              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
                              CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                              LAW.
                            </p>
                            <p>
                              {" "}
                              <b>LIMITATION OF LIABILITY</b>
                            </p>
                            <p>
                              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT
                              WILL THE COMPANY, THE PROPERTY OWNER AND ITS
                              MANAGING AGENTS, ANY OF THEIR AFFILIATES, OR THEIR
                              LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                              OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF
                              ANY KIND, INCLUDING BUT NOT LIMITED TO INDIRECT,
                              SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
                              DAMAGES, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                              LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
                              OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                              GOODWILL, OR LOSS OF DATA, UNDER ANY LEGAL THEORY,
                              INCLUDING BUT NOT LIMITED TO, TORT (INCLUDING
                              CLAIMS OF NEGLIGENCE, PERSONAL OR BODILY INJURY),
                              BREACH OF CONTRACT, OR OTHERWISE, EVEN IF
                              FORESEEABLE, ARISING OUT OF OR IN CONNECTION WITH
                              ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS
                              OR USE, THE OHAI, THE COMPANY’S SERVICES,
                              PROPERTIES, OR ANY MATERIALS OR CONTENT AVAILABLE
                              THROUGH THE OHAI, THE COMPANY’S SERVICES, OR
                              PROPERTIES.
                            </p>
                            <p>
                              IN NO EVENT SHALL COMPANY’S TOTAL LIABILITY TO YOU
                              FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
                              EXCEED THE AMOUNTS YOU HAVE PAID FOR BOOKING OHAI
                              IN THE TWELVE (12) MONTH PERIOD PRIOR TO THE EVENT
                              GIVING RISE TO THE LIABILITY, OR ONE HUNDRED
                              DOLLARS ($100), IF NO SUCH PAYMENTS HAVE BEEN
                              MADE, AS APPLICABLE. THE FOREGOING DOES NOT AFFECT
                              ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED
                              UNDER APPLICABLE LAW.
                            </p>
                            <p>
                              {" "}
                              <b>WAIVER AND SEVERABILITY</b>
                            </p>
                            <p>
                              It is the intent of the parties that the
                              provisions of this Agreement shall be enforced to
                              the fullest extent permissible under applicable
                              law. All provisions of this Agreement are intended
                              to be severable. In the event any provision
                              contained herein is held to be invalid or
                              unenforceable in any respect, in whole or in part,
                              such finding shall in no way affect <br/>(i) the
                              application of such provision to other persons or
                              circumstances, <br/>(ii) the validity or enforceability
                              of such provision, or the application of such
                              provision, in any other jurisdiction, or <br/>(iii) the
                              validity or enforceability of any other provision
                              of this Agreement.
                            </p>
                            <p>
                              The parties further agree that any such invalid or
                              unenforceable provision shall be deemed modified
                              so that it shall be enforced to the greatest
                              extent permissible under applicable law, and to
                              the extent that any court of competent
                              jurisdiction determines any provisions herein to
                              be unenforceable in any respect, such court may
                              limit this Agreement to render it enforceable in
                              the light of the circumstances in which it was
                              entered into and specifically enforce this
                              Agreement to the fullest extent permissible.
                            </p>
                            <p>
                              {" "}
                              <b>MISCELLANEOUS</b>
                            </p>
                            <p>
                              The Company reserves the right to move you to a
                              comparable property should circumstances beyond
                              the control of the Company arise.
                            </p>
                            <p>
                              It is understood and agreed that by signing this
                              Agreement and/or making payment as requested, you
                              shall have acknowledged and consented to all terms
                              and conditions of the OHAI House Rules, on behalf
                              of yourself and those in your party.
                            </p>

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.is_notification_16}
                                  />
                                }
                                labelPlacement="end"
                                label="Yes, I agree to be a great guest"
                                required={true}
                                name="is_notification_16"
                                onChange={(e) => this.changeHandler(e)}
                                error={
                                  this.state.is_notification_16 ? true : false
                                }
                                className="wizard_check"
                                helperText={
                                  this.state.is_notification_16
                                    ? this.state.is_notification_16
                                    : ""
                                }
                              />
                              <p className="error-message gust_lst_check">
                                {this.state.is_notification_16 === false
                                  ? "Please agree to continue"
                                  : ""}
                              </p>
                            </FormGroup>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-lg-12 bg_wzrd_heading sign_wzrd">
                        <div className="wzrd_heading_box m-0 pd-b-0-mob">
                          <div className="wzrd_mid_heading border-0 m-0 pb-0 pt-0">
                            <div className="col-xs-6 col-sm-6 col-md-6 ">
                              <div className="countryphone signamture_btm ">
                                <TextField
                                  margin="normal"
                                  className=" textfield w-100 m-0"
                                  required
                                  fullWidth
                                  value={
                                    this.state.signature
                                      ? this.state.signature
                                      : ""
                                  }
                                  onChange={this.changeHandlerSignature}
                                  id="signature"
                                  label="Signature"
                                  name="signature"
                                  autoComplete="off"
                                  error={
                                    this.state.signature_error ? true : false
                                  }
                                  helperText={
                                    this.state.signature_error
                                      ? this.state.signature_error
                                      : ""
                                  }
                                  // autoFocus
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="guest_last_checkin"
                        id="PrivateSwitchBase-input"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
    const step4Content = (
      <>
        <div className="container-fluid p-0">
          <section className="wzrd_main_section">
            <div className="container">
              <div className="content">
                <div className="row mobView wizard_new_heading">
                  <div className="wzrd_middle_desk">
                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                      <div className="wzrd_heading_box col-12 m-0">
                        <h4>Identity verification</h4>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                      <div className="wzrd_heading_box col-12 mb-0">
                        <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard upload_info_detail">
                          <div className="wzrd_mid_heading border-0 m-0 pt-0">
                            <div className="col-xs-6 col-sm-6 col-md-6 ">
                              <div className="countryphone">
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Select ID type
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Age"
                                    value={this.state.id_type}
                                    //disabled
                                    name="add"
                                    onChange={(event) =>
                                      this.changeHandlerSelect(event)
                                    }
                                  >
                                    <MenuItem value={1}>
                                      Driver's License
                                    </MenuItem>
                                    <MenuItem value={2}>Passport</MenuItem>
                                    <MenuItem value={3}>Other</MenuItem>
                                  </Select>
                                </FormControl>
                                <p className="error-message">
                                  {this.state.id_error
                                    ? this.state.id_error
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p>
                            {" "}
                            <b className="doc_upload_heading ">
                              Upload images of your identification
                            </b>{" "}
                          </p>
                          <ul>
                            <li>
                              <p>
                                Make sure your photos aren't blurry and the
                                front of your driver's license clearly shows
                                your face.
                              </p>
                            </li>
                            <li>
                              <p>
                                Please provide a selfie in which you hold your
                                government ID net to your face.
                              </p>
                            </li>
                            <li>
                              <p>
                                The purpose of this requirement is so that we
                                can confirm the person who booked our apartment
                                is in fact you.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading doc_upload_sec">
                      <div className="row wzrd_heading_box m-0 scroll_phn_safari">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-4 col-sm-6 col-12 id_verf_mob">
                          <div className="id_screen_upd">
                            <div
                              className="upd_frnt_img"
                              onClick={this.ClickFileUploadFront}
                            >
                              <input
                                onChange={this.FrontFileUpload}
                                type="file"
                                name="front_image"
                                accept="image/png, image/jpg, image/jpeg,image/heic"
                                disabled={this.state.forntphoto_loader}
                                id="img_eventTarget"
                                capture="camera"
                              />
                              {this.state.forntphoto_loader === true ? (
                                <div className="mob_fullscreen">
                                  <SpinnerLoader />
                                </div>
                              ) : (
                                <>
                                  <img
                                    src={global.OnKeyPressEvent.getS3ImageUrl(
                                      "front-icon.svg"
                                    )}
                                    alt=""
                                  />
                                  <p>Upload front</p>
                                </>
                              )}
                            </div>
                            {this.state.guestFrontimageUrl ? (
                              <div className="upd_frnt_img_preview">
                                <span
                                  className="exp_img_close"
                                  onClick={this.RemoveFrontImage}
                                >
                                  {global.closee}
                                </span>
                                <img
                                  src={
                                    this.state.guestFrontimageUrl
                                      ? this.state.guestFrontimageUrl
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="id_screen_upd">
                            <div
                              className="upd_frnt_img"
                              onClick={this.ClickFileUploadBack}
                            >
                              <input
                                onChange={this.BackFileUpload}
                                type="file"
                                name="back_image"
                                accept="image/png, image/jpg, image/jpeg,image/heic"
                                disabled={this.state.backphtoto_loader}
                                id="img_eventTarget_back"
                                capture="camera"
                              />
                              {this.state.backphtoto_loader === true ? (
                                <div className="mob_fullscreen">
                                  <SpinnerLoader />
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  <img
                                    src={global.OnKeyPressEvent.getS3ImageUrl(
                                      "back-icon.svg"
                                    )}
                                    alt=""
                                  />
                                  <p>Upload back</p>
                                </>
                              )}
                            </div>
                            {this.state.guestbackimageUrl ? (
                              <div className="upd_frnt_img_preview">
                                <span
                                  className="exp_img_close"
                                  onClick={this.RemoveBackImage}
                                >
                                  {global.closee}
                                </span>
                                <img
                                  src={
                                    this.state.guestbackimageUrl
                                      ? this.state.guestbackimageUrl
                                      : ""
                                  }
                                  alt=""
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6  col-xs-6 offset-xs-3 position-relative p-0">
                          <div
                            className="upd_frnt_img upd_frnt_img_sec"
                            onClick={this.ClickFileUploadSelfie}
                          >
                            <input
                              onChange={this.SelfieFileUpload}
                              type="file"
                              name="selfie_image"
                              accept="image/png, image/jpg, image/jpeg,image/heic"
                              disabled={this.state.selfiephoto_loader}
                              id="img_eventTarget_selfie"
                              capture="environment"
                            />
                            {this.state.selfiephoto_loader === true ? (
                              <div className="mob_fullscreen">
                                <SpinnerLoader />
                              </div>
                            ) : (
                              <>
                                {" "}
                                <img
                                  src={global.OnKeyPressEvent.getS3ImageUrl(
                                    "selfie.svg"
                                  )}
                                  alt=""
                                />
                                <p>Upload selfie holding ID</p>
                              </>
                            )}
                          </div>
                          {this.state.guestselfieimageUrl ? (
                            <div className="upd_frnt_img_preview_1">
                              <span
                                className="exp_img_close"
                                onClick={this.RemoveSelfieImage}
                              >
                                {global.closee}
                              </span>
                              <img
                                src={
                                  this.state.guestselfieimageUrl
                                    ? this.state.guestselfieimageUrl
                                    : ""
                                }
                                alt=""
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-12 upload_test_box">
                          <p className="error-message text-center">
                            {this.state.guestFrontimageUrl === "" &&
                            this.state.guestbackimageUrl === "" &&
                            this.guestselfieimageUrl === ""
                              ? "Images are required"
                              : this.state.errors}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
    const step5Content = (
      <>
        <div className="container-fluid p-0">
          <section className="wzrd_main_section">
            <div className="container">
              <div className="content">
                <div className="row mobView wizard_new_heading">
                  <div className="wzrd_middle_desk mb-0">
                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                      <div className="wzrd_heading_box col-12 m-0 ">
                        <h4>
                          {this.state.currentData?.card_screen_tokenise_titles
                            ? this.state.currentData
                                ?.card_screen_tokenise_titles
                            : "Credit card for incidentals"}
                        </h4>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                      <div className="wzrd_heading_box col-12 mb-0">
                        <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard upload_info_detail credit_card_inst">
                          <FormGroup className="mg-b-15">
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                onChange={(e) => this.changeHandlerDeposite(e)}
                                value={
                                  this.state.card_agree_type
                                    ? this.state.card_agree_type
                                    : 2
                                }
                              >
                                <FormControlLabel
                                  value={2}
                                  control={<Radio />}
                                  label={daysamount}
                                />
                                <FormControlLabel
                                  value={1}
                                  control={<Radio />}
                                  label={depositeTitle}
                                />
                              </RadioGroup>
                              <p className="error-message">
                                {this.state.card_agree_type === ""
                                  ? "Please Select at least one option to continue"
                                  : ""}
                              </p>
                            </FormControl>
                          </FormGroup>
                          {/* <li> Smoking is not allowed in the unit and/or the building.</li> */}
                          {this.state.card_agree_type == 1 ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.currentData
                                  ? this.state.currentData.property
                                      .credit_card_instructions
                                  : "",
                              }}
                            ></p>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.currentData
                                  ? this.state.currentData.waivermessage
                                  : "",
                              }}
                            ></p>
                          )}

                          {!this.state.clientToken ? (
                            <div>{/* <h3>Loading...</h3> */}</div>
                          ) : (
                            <Elements stripe={stripePromise} options={options}>
                              <CardForm
                                confirmation_code={
                                  this.state.currentData?.confirmation_code
                                }
                                signature={this.state.signature}
                                pagestate={this.state.pagestate}
                                setPrevious={this.setPrevious.bind(this)}
                                clientSecret={this.state.clientToken}
                                stripe_id={this.state.stripe_id}
                                card_agree_type={this.state.card_agree_type}
                                customer_id={this.state.customer_id}
                                is_form_submited = {this.state.is_form_submited}
                                guest_slug= {this.state.guest_slug}

                              />
                            </Elements>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );

    return (
      <main className="oh-template Brandmainpage wizard_header wzard_main_bg ">
        <CommonHeader propertySlug={this.state.property_slug} />
        <section className="wizard_sec">
          <div className="row- wizard_row m-0 mobView">
            <div className="wizard_box ">
              <div className="wizard-data req_book_secw-zaed">
                {this.state.pagestate === 1 ? step1Content : ""}
                {this.state.pagestate === 2 ? step2Content : ""}
                {this.state.pagestate === 3 ? step3Content : ""}
                {this.state.pagestate === 4 ? step4Content : ""}
                {this.state.pagestate === 5 ? step5Content : ""}
              </div>

              <p className="error-message">
                {this.state.imgError ? this.state.imgError : ""}
              </p>
              <div
                className={
                  this.state.pagestate == 5 ? "step_btns d-none" : "step_btns"
                }
              >
                {this.state.pagestate !== 1 && this.state.pagestate !== 5 ? (
                  <button
                    id="book-tour-page"
                    className="w-100 btn_pre_wzd"
                    onClick={this.setPrevious}
                  >
                    Previous
                  </button>
                ) : (
                  ""
                )}
                {!this.state.clientToken && this.state.pagestate === 5 ? (
                  <button
                    id="book-tour-page"
                    className="w-100 btn_pre_wzd"
                    onClick={this.setPrevious}
                  >
                    Previous
                  </button>
                ) : (
                  ""
                )}
                {!this.state.clientToken && this.state.pagestate === 5 ? (
                  <button
                    id="book-tour-page"
                    className="w-100 btn_next_wzd"
                    disabled={this.state.loader ? true : false}
                    onClick={(e) => this.setNext(e)}
                  >
                    Next
                  </button>
                ) : (
                  ""
                )}
                {this.state.pagestate !== 4 && this.state.pagestate !== 5 ? (
                  <button
                    id="book-tour-page"
                    className="w-100 btn_next_wzd"
                    disabled={this.state.loader ? true : false}
                    onClick={(e) => this.setNext(e)}
                  >
                    Next
                  </button>
                ) : (
                  ""
                )}

                {this.state.pagestate === 4 ? (
                  <button
                    id="book-tour-page"
                    className="w-100 btn_next_wzd"
                    disabled={this.state.submitloader ? true : false}
                    onClick={(e) => this.onSubmit(e)}
                  >
                    Next
                    {this.state.submitloader ? (
                      <>
                        {" "}
                        <div className="mob_fullscreen">
                          <SpinnerLoader />
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
        <Modal show={this.state.verifyOtpModal} centered className="otpModal">
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose float-end"
              onClick={() => this.manageVerifyModal(false)}
            >
              {global.closee}
            </span>
            <div>
              <figure className="mg-auto m-auto text-center">
                {this.state.isVerify ? (
                  <>
                    <h2 className="text-success">Code Verified Successfully</h2>
                    <div className="d-flex  mg-t-30 reservationBook bg-white">
                      <a className="text-decoration-none w-100">
                        <button id="book-tour-page" className="w-100">
                          continue{" "}
                          {this.state.submitloader ? (
                            <>
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </button>
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="text-center">
                      <b>Please enter code to continue</b>
                    </h4>
                    <h6 className="mg-b-30">
                      <b>Code sent to :</b> {this.state.display_email}
                    </h6>
                    <TextField
                      type="number"
                      id="OTP"
                      label="Enter Code"
                      variant="outlined"
                      fullWidth
                      name="verify_otp"
                      onChange={this.changeHandler}
                      required
                      error={this.state.verify_otp_error ? true : false}
                      value={this.state.verify_otp}
                    />
                    {this.state.verify_otp_error && (
                      <>
                        <p className="text-danger">
                          {" "}
                          {this.state.verify_otp_error}
                        </p>
                      </>
                    )}
                    <div className="d-flex  mg-t-30 reservationBook bg-white">
                      <button
                        className="w-100"
                        onClick={() => this.verifyOtp()}
                      >
                        Verify{" "}
                        {this.state.loader ? (
                          <>
                            <Spinner
                              as="span"
                              animation="grow"
                              role="status"
                              aria-hidden="true"
                            />
                            <Spinner
                              as="span"
                              animation="grow"
                              role="status"
                              aria-hidden="true"
                            />
                            <Spinner
                              as="span"
                              animation="grow"
                              role="status"
                              aria-hidden="true"
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                    <Timer
                      minutes={global.countdown_time} // env data ( Pass the no of minutes:seconds that you want count )
                      resendEvent={this.resendOtp.bind(this)} //  Resend button event you can pass your function name here
                    />
                  </>
                )}
              </figure>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default WizardForm;
