//     							                                      //
//  Program: Header.jsx                                   //
//  Application: KINI Brand Header                        //
//  Option: Header                                        //
//  Developer: PS                                         //
//  Date: 2022-08-23                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import Login from "./Login";
import SignUp from "./SignUp";
import CmsDataService from "../../services/CmsDataService";
import CmsApi from "../../services/CmsApi";

class CommonHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModel: localStorage.getItem("loginModel") === "open" ? true : false,
      signupModel: false,
      CheckIn:props.CheckIn ?props.CheckIn :"", 
      headerToken: localStorage.getItem("headerToken") ? localStorage.getItem("headerToken") : "",
      userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {},
      brandLogo: localStorage.getItem("brand_logo_url") ? localStorage.getItem("brand_logo_url") : global.OnKeyPressEvent.getS3ImageUrl('oh-logo.png'),
      notificationList: [],
      notificationCount: 0,
      totalNotificationCount: 0,
      propertySlug: props.propertySlug ? props.propertySlug : global.propertySlug,
      property_slug: props.propertySlug ? props.propertySlug : global.propertySlug
    }
    this.loginModel = this.loginModel.bind(this);
    this.signupModel = this.signupModel.bind(this);
    this.getCmsHeaderData = this.getCmsHeaderData.bind(this);
  }

  componentDidMount() {
    if (this.state.headerToken !== "") {
    }
    this.getCmsStyleSetting();
    this.getCmsHeaderData();
  }

  /* CMS Functions start here */

  getCmsStyleSetting = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.propertySlug : 1)
    var cacheKeyName = global.level + "_CMS_STYLE_DATA_" + levelSlug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem('KINI_PROPERTY_STYLE_DATA');
      this.setState(data);
    }
    let res = await CmsApi.getCmsStyleSetting(levelSlug, global.level);
    if (global.successStatus.includes(res.status)) {
      resData.property_style = res.data.property_style;
      resData.property_style_text = res.data.property_style_text;
    }
    this.setState(resData);
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState({
      showSpinner: false,
      loading: false,
    });
  };

  getCmsHeaderData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var section_header_data = [];
    var section_top_header_data = [];
    var header_color = "";
    var banner_color = "";
    var cacheKeyName = global.level + "_HEADER_DATA_" + (global.level === 3 ? this.state.property_slug : "");
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem('HEADER_DATA');
      section_header_data = data[0] && data[0].section_data ? data[0].section_data : [];
      header_color = data && data[0] ? data[0].backgroundColor : '';
      await this.setState({
        section_header_data: section_header_data,
        header_color: header_color
      });
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.propertySlug : 1)
    let res = await CmsDataService.getCmsData("HEADER", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(res.data));
      section_header_data = res.data[0] && res.data[0].section_data ? res.data[0].section_data : [];
      header_color = res.data && res.data[0] ? res.data[0].backgroundColor : '';
    }

    await this.setState({
      showSpinner: false,
      loading: false,
      section_header_data: section_header_data,
      header_color: header_color,
    });
  }
  /* CMS Functions end here */

  loginModel = (value,signup=false) => {
    this.setState({ loginModel: value });
    this.setState({ signupModel: signup });
    // console.log(this.state, value);
  }

  signupModel = (value) => {
    this.setState({ signupModel: value });
    this.setState({ loginModel: false });
  }
  render() {


    return (
      <div className="Brandheader">
        <header>
          <div className="container">
            <div className="row m-0 d-block">
              <div className="navigationMenu">
                <figure>
                  <a href="/">
                    <img src={this.state.section_header_data && this.state.section_header_data[0] && this.state.section_header_data[0].url ? this.state.section_header_data[0].url : global.OnKeyPressEvent.getS3ImageUrl('oh-logo.png')} alt={this.state.section_header_data && this.state.section_header_data[0] && this.state.section_header_data[0].alt ? this.state.section_header_data[0].alt : "Brand Logo"} title="Brand Logo" />
                  </a>
                </figure>
                {[false].map((expand) => (
                  <div className="d-flex align-items-center" key="expand">
                    <Navbar key={expand} expand={expand} >
                      <Container fluid className="p-0">
                        <ul>
                        {(!this.props.CheckIn) &&
                            <>
                              <li><a href="/reservation" alt="Check in">Check in</a></li>
                           
                            </>
                          }
                        </ul>

                        {(!this.state.headerToken && !this.props.CheckIn) &&
                            <>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <span className="nav_icon_user">
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('menu_new.svg')} alt="Navigation" title="Navigation" />
                          </span>
                          <figure className="user">
                            <img src={this.props.profile_img ? this.props.profile_img : (this.state.userData && this.state.userData.profile_thumb_url) ? this.state.userData.profile_thumb_url : global.OnKeyPressEvent.getS3ImageUrl('user-login.png')} alt="Profile image" title="Profile image" />
                            {/* <span className="msg">2</span> */}
                          </figure>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          align="end"
                        >
                          {(!this.props.CheckIn) &&
                            <>
                              <ul>
                                <li><a href="javascript:void(0)" style={{ cursor: "pointer" }} className={this.state.loginModel ? "active" : ""} onClick={() => this.loginModel(true)}>{this.state.section_header_data && this.state.section_header_data[3] && this.state.section_header_data[3].text ? this.state.section_header_data[3].text : 'Sign in'}</a></li>
                              </ul>
                              <hr />
                              <ul>
                                <li><a href="/live-here" alt="Live here">Live here</a></li>
                                <li><a href="/fort-lauderdale-fl/live-here" alt="Live in Fort Lauderdale, FL">Live in Fort Lauderdale, FL</a></li>
                                {/* <li><a href="/tempe-az/live-here" alt="Live in Tempe, AZ">Live in Tempe, AZ</a></li> */}
                                <li><a href="/scottsdale-az/live-here" alt="Live in Scottsdale, AZ">Live in Scottsdale, AZ</a></li>
                                <li><a href="/Lafayette/live-here" alt="Live in Lafayette, LAF">Live in Lafayette, LAF</a></li>
                                <li><a href="/Austin/live-here" alt="Live in Austin, AUS">Live in Highgrove Austin, AUS</a></li>
                                <li><a href="/Camdenton/live-here" alt="Live in Camdenton, EMR">Live in Ember Ridge Camdenton, EMR</a></li>
                              </ul>
                              <hr />
                              <ul>
                                <li><a href="/stay-here" alt="Stay here">Stay here</a></li>
                                <li><a href="/fort-lauderdale-fl/stay-here" alt="Stay in Fort Lauderdale, FL">Stay in Fort Lauderdale, FL</a></li>
                                {/* <li><a href="/tempe-az/stay-here" alt="Stay in Tempe, AZ">Stay in Tempe, AZ</a></li> */}
                                <li><a href="/scottsdale-az/stay-here" alt="Stay in Scottsdale, AZ">Stay in Scottsdale, AZ</a></li>
                                <li><a href="/Lafayette/stay-here" alt="Stay in Lafayette, LAF">Stay in Lafayette, LAF</a></li>
                                <li><a href="/Austin/stay-here" alt="Stay in Austin, AUS">Stay in Highgrove Austin, AUS</a></li>
                                <li><a href="/Camdenton/stay-here" alt="Stay in Camdenton, EMR">Stay in Ember Ridge Camdenton, EMR</a></li>
                              </ul>
                              <hr />
                              <ul>
                              <li><a href="/partnerships" alt="Apartment building owners">Apartment building owners </a></li>
                              </ul>
                              <ul>
                                <li><a href="/contact" alt="Contact">{this.state.section_header_data && this.state.section_header_data[2] && this.state.section_header_data[2].text ? this.state.section_header_data[2].text : 'Contact'} </a></li>
                              </ul>
                            </>
                          }
                        
                        </Dropdown.Menu>
                      </Dropdown>
                      </>
  }
                        {(this.state.headerToken && !this.props.CheckIn)&&
                          <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand2-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand2-${expand}`}
                            placement="end"
                            className="notificaitonModal"
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title id={`offcanvasNavbarLabel2-expand-${expand}`}>
                                <span>Notifications</span>
                                {this.state.notificationCount !== 0 &&
                                  <div className="notification-header">
                                    <ul>
                                      <li onClick={() => this.notificationReadData()}>Mark All</li>
                                      <li onClick={() => this.notificationClearData()}>Clear All</li>
                                    </ul>
                                  </div>
                                }
                                <div className="notificationlisting">
                                  <ul>
                                    {
                                      this.state.notificationList.length > 0 &&
                                      this.state.notificationList.map((item, index) => {
                                        return (
                                          <li key={index} className={item.is_read === 0 ? "unread" : ""} title={item.title ? item.title : ""} onClick={() => this.notificationReadData(item.slug)}>
                                            <a href="#">
                                              <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('user-login.png')} alt="" title="" />
                                              </figure>
                                              <div className="content">
                                                <div className="d-flex flex-column">
                                                  <b>{item.sender ? item.sender.first_name? item.sender.first_name : "" : ""}</b>
                                                  <p>{item.message ? item.message : ""}</p>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                  <i>{item.created_at ? Moment(item.created_at).format("MM/DD/YY") : ""}</i>
                                                </div>

                                              </div>
                                            </a>
                                          </li>)
                                      })
                                    }
                                  </ul>
                                </div>
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <ul>

                                <li><a href="/reservation-list">My trips</a></li>
                                <li><a href="/profile">Account</a></li>
                              </ul>



                            </Offcanvas.Body>
                          </Navbar.Offcanvas>
                        }
                      </Container>
                    </Navbar>

                    {(this.state.headerToken && !this.props.CheckIn)&&
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <span className="nav_icon_user">
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('menu_new.svg')} alt="Navigation" title="Navigation" />
                          </span>
                          <figure className="user">
                            <img src={this.props.profile_img ? this.props.profile_img : (this.state.userData && this.state.userData.profile_thumb_url) ? this.state.userData.profile_thumb_url : global.OnKeyPressEvent.getS3ImageUrl('user-login.png')} alt="Profile image" title="Profile image" />
                            {/* <span className="msg">2</span> */}
                          </figure>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          align="end"
                        >

                             <ul>
                                <li><a href="/reservation-list" alt="Trips">Trips </a></li>
                             
                            </ul>
                            <ul>
                                  <li><a href="/profile" alt="Account">Account </a></li>
                            </ul> 
                          {(!this.props.CheckIn) &&
                            <>
                              <hr />
                              <ul>
                                <li><a href="/live-here" alt="Live here">Live here</a></li>
                                <li><a href="/fort-lauderdale-fl/live-here" alt="Live in Fort Lauderdale, FL">Live in Fort Lauderdale, FL</a></li>
                                {/* <li><a href="/tempe-az/live-here" alt="Live in Tempe, AZ">Live in Tempe, AZ</a></li> */}
                                <li><a href="/scottsdale-az/live-here" alt="Live in Scottsdale, AZ">Live in Scottsdale, AZ</a></li>
                                <li><a href="/Lafayette/live-here" alt="Live in Lafayette, LAF">Live in Lafayette, LAF</a></li>
                                <li><a href="/Austin/live-here" alt="Live in Austin, AUS">Live in Highgrove Austin, AUS</a></li>
                                <li><a href="/Camdenton/live-here" alt="Live in Camdenton, EMR">Live in Ember Ridge Camdenton, EMR</a></li>
                              </ul>
                              <hr />
                              <ul>
                                <li><a href="/stay-here" alt="Stay here">Stay here</a></li>
                                <li><a href="/fort-lauderdale-fl/stay-here" alt="Stay in Fort Lauderdale, FL">Stay in Fort Lauderdale, FL</a></li>
                                {/* <li><a href="/tempe-az/stay-here" alt="Stay in Tempe, AZ">Stay in Tempe, AZ</a></li> */}
                                <li><a href="/scottsdale-az/stay-here" alt="Stay in Scottsdale, AZ">Stay in Scottsdale, AZ</a></li>
                                <li><a href="/Lafayette/stay-here" alt="Stay in Lafayette, LAF">Stay in Lafayette, LAF</a></li>
                                <li><a href="/Austin/stay-here" alt="Stay in Austin, AUS">Stay in Highgrove Austin, AUS</a></li>
                                <li><a href="/Camdenton/stay-here" alt="Stay in Camdenton, EMR">Stay in Ember Ridge Camdenton, EMR</a></li>
                              </ul>
                              <hr />
                              <ul>
                              <li><a href="/partnerships" alt="Apartment building owners">Apartment building owners </a></li>
                              </ul>
                              <ul>
                                <li><a href="/contact" alt="Contact">{this.state.section_header_data && this.state.section_header_data[2] && this.state.section_header_data[2].text ? this.state.section_header_data[2].text : 'Contact'} </a></li>
                              </ul>
                            </>
                          }
                              <ul>
                                <li><a href="/logout" alt="Contact">Logout </a></li>
                              </ul>

                        </Dropdown.Menu>
                      </Dropdown>
                    }
                  </div>
                ))}
              </div>


            </div>

          </div>
        </header>

        {/* Mobile view header */}
        
        <div className="mobileHeader">

          <div className="navigationMenu">
            <figure>
              <a href="/">
                <img src={global.OnKeyPressEvent.getS3ImageUrl('oh-logo.png')} alt="Brand logo" title="Brand logo" />
              </a>
            </figure>
            <div className="headerRight">
              {[false].map((expand) => (
                <Navbar key={expand} expand={expand} >
                  <Container fluid className="p-0">
                    <ul>

                    {(!this.props.CheckIn) &&
                            <li><a href="/reservation" > Check in</a></li>
                       }
                    </ul>
                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand3-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand3-${expand}`}
                      placement="end"
                      className="notificaitonModal"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand3-${expand}`}>
                          <span>Notifications</span>
                          {this.state.notificationCount !== 0 &&
                            <div className="notification-header">
                              <ul>
                                <li onClick={() => this.notificationReadData()}>Mark All</li>
                                <li onClick={() => this.notificationClearData()}>Clear All</li>
                              </ul>
                            </div>
                          }
                          <div className="notificationlisting">
                            <ul>
                              {
                                this.state.notificationList.length > 0 &&
                                this.state.notificationList.map((item, index) => {
                                  return (
                                    <li key={index} className={item.is_read === 0 ? "unread" : ""} title={item.title ? item.title : ""} onClick={() => this.notificationReadData(item.slug)}>
                                      <a href="#">
                                        <figure>
                                          <img src={global.OnKeyPressEvent.getS3ImageUrl('user-login.png')} alt="Sender image" title="Sender image" />
                                        </figure>
                                        <div className="content">
                                          <div className="d-flex flex-column">
                                            <b>{item.sender ? item.sender.first_name ? item.sender.first_name : "" : ""}</b>
                                            <p>{item.message ? item.message : ""}</p>
                                          </div>
                                          <div className="d-flex justify-content-end">
                                            <i>{item.created_at ? Moment(item.created_at).format("MM/DD/YY") : ""}</i>
                                          </div>

                                        </div>
                                      </a>
                                    </li>)
                                })
                              }
                            </ul>
                          </div>
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              ))}
              {[false].map((expand) => (
                <Navbar key={expand} expand={expand} >
                  <Container fluid className="p-0">
                  {
                        (!this.props.CheckIn)&&

                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} >
                      <span className="nav_icon_user">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('menu_new.svg')} alt="Navigation" title="Navigation" />
                      </span>
                      {/* Add new code user icon */}
                      {
                        (!this.state.headerToken && !this.props.CheckIn)&&
                      <figure className="user">
                          <img src={ this.props.profile_img ? this.props.profile_img : (this.state.userData && this.state.userData.profile_thumb_url) ? this.state.userData.profile_thumb_url : global.OnKeyPressEvent.getS3ImageUrl('user-login.png')} alt="Profile image" title="Profile image" />
                       </figure>}
                       {/* End code Add new code user icon */}
                      {
                        (this.state.headerToken && !this.props.CheckIn)&&
                        <figure className="user">
                          <img src={ this.props.profile_img ? this.props.profile_img : (this.state.userData && this.state.userData.profile_thumb_url) ? this.state.userData.profile_thumb_url : global.OnKeyPressEvent.getS3ImageUrl('user-login.png')} alt="Profile image" title="Profile image" />
                          {/* <span className="msg">2</span> */}
                        </figure>
                      }
                    </Navbar.Toggle>
                }
                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end"
                      className="notificaitonModal"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className="text-center w-100">
                          Menu
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                     
                     
                        {(!this.state.headerToken && !this.props.CheckIn) &&
                           <>
                            <ul>
                              <li><a href="javascript:void(0)" style={{ cursor: "pointer" }} className={this.state.loginModel ? "active" : ""} onClick={() => this.loginModel(true)}>{this.state.section_header_data && this.state.section_header_data[3] && this.state.section_header_data[3].text ? this.state.section_header_data[3].text : 'Sign in'}</a></li>
                             </ul>
                              {/* <hr /> */}
                            </>
                          }
                        <hr />
                      
                        {(this.state.headerToken && !this.props.CheckIn) &&
                           <>
                            <ul className="mg-b-0">
                              <li><a href="/reservation-list" alt="Trips" className="pd-b-0">Trips</a></li>
                            </ul>
                            <ul className="mg-b-0">
                              <li><a href="/profile" alt="Account" >Account</a></li>
                            </ul>
                            <hr />
                            </>
                          }
                           {(!this.props.CheckIn) &&
                            <>
                               <ul className="mg-b-25">
                                <li><a href="/live-here" alt="Live here">Live here</a></li>
                                <li><a href="/fort-lauderdale-fl/live-here" alt="Live in Fort Lauderdale, FL">Live in Fort Lauderdale, FL</a></li>
                                {/* <li><a href="/tempe-az/live-here" alt="Live in Tempe, AZ">Live in Tempe, AZ</a></li> */}
                                <li><a href="/scottsdale-az/live-here" alt="Live in Scottsdale, AZ" >Live in Scottsdale, AZ</a></li>
                                <li><a href="/Lafayette/live-here" alt="Live in Lafayette, LAF">Live in Lafayette, LAF</a></li>
                                <li><a href="/Austin/live-here" alt="Live in Austin, LAF">Live in Highgrove Austin, AUS</a></li>
                                <li><a href="/Camdenton/live-here" alt="Live in Camdenton, EMR">Live in Ember Ridge Camdenton, EMR</a></li>
                              </ul>
                              <hr />
                              <ul className="mg-b-25">
                                <li><a href="/stay-here" alt="Stay here">Stay here</a></li>
                                <li><a href="/fort-lauderdale-fl/stay-here" alt="Stay in Fort Lauderdale, FL">Stay in Fort Lauderdale, FL</a></li>
                                {/* <li><a href="/tempe-az/stay-here" alt="Stay in Tempe, AZ">Stay in Tempe, AZ</a></li> */}
                                <li><a href="/scottsdale-az/stay-here" alt="Stay in Scottsdale, AZ">Stay in Scottsdale, AZ</a></li>
                                <li><a href="/Lafayette/stay-here" alt="Stay in Lafayette, LAF">Stay in Lafayette, LAF</a></li>
                                <li><a href="/Austin/stay-here" alt="Stay in Austin, LAF">Stay in Highgrove Austin, AUS</a></li>
                                <li><a href="/Camdenton/stay-here" alt="Stay in Camdenton, EMR">Stay in Ember Ridge Camdenton, EMR</a></li>
                              </ul>
                             <hr />
                             <ul>
                                <li><a href="/partnerships" alt="Apartment building owners" className="pd-b-5">{this.state.section_header_data && this.state.section_header_data[1] && this.state.section_header_data[1].text ? this.state.section_header_data[1].text : 'Apartment building owners'}</a></li>
                              </ul>
                              <ul>
                                  <li><a href="/contact" className="pd-b-0" alt="Contact">{this.state.section_header_data && this.state.section_header_data[2] && this.state.section_header_data[2].text ? this.state.section_header_data[2].text : 'Contact'}</a></li>
                              </ul>
                            </>
                          }
                          {(this.state.headerToken && !this.props.CheckIn) &&
                             <ul>
                               <li><a href="/logout" alt="Logout">Logout</a></li>
                             </ul>
                          }
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              ))}
            </div>

          </div>
          {/* Login section */}
          <section>
            <Login redirectTo={"/"} propertySlug={this.state.property_slug} isLogin={this.state.loginModel} manageLogin={this.loginModel.bind(this)} manageSignUp={this.signupModel.bind(this)} />
          </section>
          {/* Signup section */}
          <section>
            <SignUp redirectTo={"/"} propertySlug={this.state.property_slug} isSignUp={this.state.signupModel} manageLogin={this.loginModel.bind(this)} manageSignUp={this.signupModel.bind(this)} />
          </section>
        </div>
      </div>

    );
  }
}


CommonHeader.defaultProps = {
  propertySlug: undefined
};


export default CommonHeader;