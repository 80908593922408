
//     							                          //
//  Program: strDetail.jsx                                //
//  Application: OH HTML                                  //
//  Option: STR Detail                                    //
//  Developer: PS		                                  //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import { Dropdown, Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import Moment from "moment";
import StrlistingApi from "../../services/StrlistingApi";
import ReservationService from "../../services/ReservationService";
import SocialShareLink from "../common/SocialShareLink";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  Datepicker,
  setOptions,
} from "@mobiscroll/react";
import Gallery from "../common/Gallery";
import DatePickerFile from "../common/DatePickerFile";
import GoogleMap from "../common/GoogleMap";
import MetaSEO from "../../../MetaSEO";
import KingBed from "./../../../assets/images/king.png"
import Toast from 'react-bootstrap/Toast';
import SpinnerLoader from "../common/SpinnerLoader";
const queryParams = queryString.parse(location.search);

setOptions({
  theme: "ios",
  themeVariant: "light",
});


class StrDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: window.location.href,
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      input: {},
      UnitListing: {
        mediaFiles: [],
      },
      UnitBedroom: [],
      UnitBathroom: [],
      errors: {},
      guest: 1,
      total_selected_guest: 1,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      unit_slug: this.props.match.params.unit_slug,
      slug: "",
      status_id: true,
      pathname: window.location.pathname,
      urlname: window.location.href,
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
      petsCount: 0,
      bedroom: "",
      bathroom: "",
      propertyAmenityTotal: 0,
      propertyAmenityList: {},
      unit_name: queryParams.unit ? decodeURIComponent(queryParams.unit) : "",
      headerToken: localStorage.getItem("headerToken") ? localStorage.getItem("headerToken") : "",
      media_files: [],
      all_images: [],
      mainImage: "",
      availablity_modal_box: false,
      unitImagemodal: [],
      openImagepopup: false,
      openGalaryModal: false,
      openAllAmenitiesModal: false,
      openHealthAndSafetyModal: false,
      openPropertyrulesModal: false,
      openDescriptionModal:false,
      openRefundablepolicyModal: false,
      openGuestmanualModal: false,
      openGuestSize: false,
      pricebreakdownModal: false,
      openGettingAroundModal: false,
      property_name: localStorage.getItem("property_name"),
      // meta tags start
      title: global.level === 2 ? localStorage.getItem("brand_name") : (global.level === 3 ? localStorage.getItem("property_name") : ""),
      favicon: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : ""),
      keywords: global.keywords,
      description: global.description,
      url: window.location.href,
      image: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : ""),
      fb_app_id: "",
      // meta tags end
      startDate: queryParams.check_in ? Moment(queryParams.check_in).local().format('MM/DD/YYYY') : localStorage.getItem("checkin") ? localStorage.getItem("checkin") : "",
      endDate: queryParams.check_out ? Moment(queryParams.check_out).local().format('MM/DD/YYYY') : localStorage.getItem("checkout") ? localStorage.getItem("checkout") : "",
      message: "",
      pricing: [],
      userToken: localStorage.getItem("userToken")
        ? localStorage.getItem("userToken")
        : undefined,
      user_token: localStorage.getItem("userToken")
        ? localStorage.getItem("userToken")
        : undefined,
      availableDate: "",
      bookedDate: "",
      enableDatePicker: true,
      redirectToNextPage: false,
      queryString: "",
      guestValue: "1 Guest",
      guest_slug: localStorage.getItem("guest_slug")
        ? localStorage.getItem("guest_slug")
        : "",
      property: "",
      reservation_slug: localStorage.getItem("reservation_slug")
        ? localStorage.getItem("reservation_slug")
        : "",
      property: "",
      airBnbQueryString: "",
      minDate: new Date(),
      health_safety: [],
      guest_manual: [],
      pricebreakdownModal: false,
      refundable_policy: [],
      health_safety1: [
        "Airbnb's COVID-19 safety practices apply",
        "Security camera/recording device",
        "Carbon monoxide alarm",
        "Smoke alarm",
      ],
      booktourpage: localStorage.getItem("booktourpage") ? localStorage.getItem("booktourpage") : "",
      openPicker: false,
      datePickerOpen: false,
      headerToken: localStorage.getItem("headerToken") ? localStorage.getItem("headerToken") : "",
      userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {},
      isNeedtoStrDetails: false,
      seoLoaded: false,
      showA: false,
      showB: false
    };
    this.getUnitData = this.getUnitData.bind(this);
    this.getBeddata = this.getBeddata.bind(this);
    this.neighborhoodDetail = this.neighborhoodDetail.bind(this);
    this.getAvailibityDate = this.getAvailibityDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    this.incrementpets = this.incrementpets.bind(this);
    this.decrementpets = this.decrementpets.bind(this);
    this.Setclose = this.Setclose.bind(this);
    this.Setclose2 = this.Setclose2.bind(this);
    this.openGalaryModal = this.openGalaryModal.bind(this);
    this.closeGalaryModal = this.closeGalaryModal.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.checkAvailabilityDate = this.checkAvailabilityDate.bind(this);
    this.changeDateHandlerNew = this.changeDateHandlerNew.bind(this);
    // this.openAllAmenitiesModal = this.openAllAmenitiesModal(this);
    // this.createQueryString = this.createQueryString.bind(this);

    localStorage.removeItem('checkin');
    localStorage.removeItem('checkout');
    localStorage.removeItem('pricing');
    localStorage.removeItem('trip_details');
    // localStorage.removeItem('addressDetails');
    // console.log(queryParams,this.state.startDate,this.state.endDate);
  }

  toggleShowA = () => {
    this.setState({ showA: !this.state.showA })
  };

  toggleShowB = () => {
    this.setState({ showB: !this.state.showB })
  };

  validateDateChange = async () => {
    if (this.state.startDate === "") {
      var unit = document.getElementById("calender-start-open");
      if (unit) {
        unit.click();
      }
    } else {
      await this.checkAvailabilityDate();
      //   this.setState({redirectToNextPage:true});
    }
    if (this.state.startDate !== "" && (this.state.endDate === "" || this.state.endDate === null)) {
      this.setState({ endDate: Moment(this.state.startDate).add(1, "days") })
    }
  }
  backToPage = async () => {
    history.back();
  }
  validateDateChangeMobile = async () => {
    if (this.state.startDate === "") {
      this.setState({ openPicker: true });
    } else {
      await this.checkAvailabilityDate();
      //   this.setState({redirectToNextPage:true});
    }
    if (this.state.startDate !== "" && (this.state.endDate === "" || this.state.endDate === null)) {
      this.setState({ endDate: Moment(this.state.startDate).add(1, "days") })
    }
  }

  changeDateHandlerNewMobile = async (date) => {
    var startDate = date.value[0] ? Moment(date.value[0]).local().format('YYYY-MM-DD') : "";
    var endDate = date.value[1] ? Moment(date.value[1]).local().format('YYYY-MM-DD') : "";
    if (startDate && endDate) {
      await this.setState({ resrved_date_message: "" });
    }
    // if(startDate !== "" && (endDate === "" || endDate === null )){
    //   await this.setState({endDate:Moment(startDate).add(1, "days").format("YYYY-MM-DD")})
    //   endDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
    // }
    if(startDate && endDate){
      await this.setState({ date_message:"",date_message_reserved:""});
    }
    await this.setState({ startDate: startDate, endDate: endDate});
    await this.createQueryString();
    await this.creatAirBnbQueryString();
    await this.checkAvailabilityDate();
  };

  changeDateHandlerNew = async (date) => {
    var startDate = date.value[0] ? Moment(date.value[0]).local().format('YYYY-MM-DD') : "";
    var endDate = date.value[1] ? Moment(date.value[1]).local().format('YYYY-MM-DD') : "";

    if (startDate && endDate) {
      await this.setState({ resrved_date_message: "" });
    }
    // if(startDate !== "" && (endDate === "" || endDate === null )){
    //   await this.setState({endDate:Moment(startDate).add(1, "days").format("YYYY-MM-DD")})
    //   endDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
    // }
    await this.setState({ startDate: startDate, endDate: endDate });
    // await this.getnexedBookdate(startDate);
    await this.checkAvailabilityDate();
    await this.createQueryString();
    await this.creatAirBnbQueryString();
    // await this.checkAvailabilityDate();
  };

  async componentDidMount() {
    localStorage.removeItem("reservation_confirm");
    this.neighborhoodDetail();
    this.getBeddata();
    await this.getUnitData();
    this.getAvailibityDate();
    if (this.state.booktourpage) {
      //this.setState({ redirectToNextPage: false });
      this.setState({ enableDatePicker: false });

      /* for redirect date change */
    }
    localStorage.removeItem('checkin');
    localStorage.removeItem('checkout');
    localStorage.removeItem('pricing');
    localStorage.removeItem('trip_details');

    localStorage.setItem("listing_slug", this.state.unit_slug);
    var prevQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : "";
    if (prevQueryParams !== "") {
      await this.setState({
        adultCount: parseInt(prevQueryParams.numberOfAdults),
        childCount: parseInt(prevQueryParams.numberOfChildren),
        infantCount: parseInt(prevQueryParams.numberOfInfants),
        petsCount: parseInt(prevQueryParams.numberOfPets),
      });
    }
   
    if (this.state.guest_slug !== "" && this.state.reservation_slug !== "") {
      localStorage.removeItem("guest_slug");
      localStorage.removeItem("reservation_slug");
      localStorage.removeItem("addressDetails");
      localStorage.removeItem("reservation_confirm");
    }

    if (!this.state.booktourpage) {
      await this.getAvailibityDate();
    }
    await this.guestDetail();
    // setTimeout(() => console.clear(), 5000);
  }

  placeReservation = async (dynamicData = "") => {
    if (this.state.startDate === "" && this.state.endDate === "") {
      this.setState({ date_selection_message: "Please select the date" });

    } else {
      let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : "";
      var inputData = {
        property_slug: this.state.property_slug,
        listing_slug: this.state.unit_slug,
        set_channel: 1,
        checkin_date: Moment(this.state.startDate).format("YYYY-MM-DD"),
        checkout_date: Moment(this.state.endDate).format("YYYY-MM-DD"),
        phone_number: userData.phone_number ? userData.phone_number : "",
        first_name: userData.first_name ? userData.first_name : "",
        last_name: userData.last_name ? userData.last_name : "",
        email: userData.email ? userData.email : "",
        adults: this.state.adultCount ? this.state.adultCount : 1,
        children: this.state.childCount ? this.state.childCount : 0,
        infants: this.state.infantCount ? this.state.infantCount : 0,
        pets: this.state.petsCount ? this.state.petsCount : 0,
        user_token: this.state.user_token ? this.state.user_token : "",
        level: 5,
      };
      let res = await ReservationService.storeReservation(inputData);
      var reservation_slug = "";
      var guest_slug = "";
      var listing_name = "";
      var confirmation_code= "";
      await this.createQueryString();
      if (global.successStatus.includes(res.status)) {
        reservation_slug = res.data ? res.data.reservation_slug : "";
        guest_slug = res.data ? res.data.guest_slug : "";
        confirmation_code=res.data ? res.data.confirmation_code:"";
        listing_name = res.data ? res.data.listing_name : "";
        localStorage.setItem("reservation_slug", reservation_slug);
        localStorage.setItem("confirmation_code", confirmation_code);
        localStorage.setItem("guest_slug", guest_slug);
        localStorage.setItem("listing_name", listing_name);
        localStorage.setItem("reservationDetails", JSON.stringify(inputData));
        window.location.href = global.themeUrl + "" + this.state.property_slug + "/book-tour-payment" + this.state.queryString;
      } else {
        this.setState({ place_reservation_error: res.data.message });
      }
    }
  };

  
  openImageModal = (mainImage) => {
    mainImage = mainImage ? mainImage : global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg');
    this.setState({ openImagepopup: true, unitImagemodal: mainImage });
  };

  openAllAmenitiesModal = (val = false) => {
    this.setState({ openAllAmenitiesModal: val });
  };

  openHealthAndSafetyModal = (val = false) => {
    this.setState({ openHealthAndSafetyModal: val });
  };
  openPropertyrulesModal = (val = false) => {
    this.setState({ openPropertyrulesModal: val });
  };
  openDescriptionModal = (val = false) => {
    this.setState({ openDescriptionModal: val });
  };

  openRefundablepolicyModal = (val = false) => {
    this.setState({ openRefundablepolicyModal: val });
  };

  openGuestmanualModal = (val = false) => {
    this.setState({ openGuestmanualModal: val });
  };

  openGuestSize = (val = false) => {
    this.setState({ openGuestSize: val });
  };

  pricebreakdownModal = (val = false) => {
    this.setState({ pricebreakdownModal: val });
  };


  openGettingAroundModal = (val = false) => {
    this.setState({ openGettingAroundModal: val });
  };

  showSocialLinks = (val = false) => {
    this.setState({ showSocialLinks: val });
  };

  closeImageModal() {
    this.setState({ openImagepopup: false });
  }

  createQueryString = () => {
    var queryString = "";
    queryString = `?checkin=${this.state.startDate}&checkout=${this.state.endDate}&numberOfAdults=${this.state.adultCount}&numberOfChildren=${this.state.childCount}&numberOfInfants=${this.state.infantCount}&numberOfPets=${this.state.petsCount}&listing=${this.state.unit_slug}`;
    this.setState({ queryString: queryString });
    this.creatAirBnbQueryString();
  };

  creatAirBnbQueryString = () => {
    var queryString = "";
    var guest =
      parseInt(this.state.childCount) + parseInt(this.state.adultCount);
    guest = guest === 0 ? 1 : guest;
    queryString = `?check_in=${this.state.startDate}&guests=${guest}&adults=${this.state.adultCount}&check_out=${this.state.endDate}&children=${this.state.childCount}&infants=${this.state.infantCount}&pets=${this.state.petsCount}`;
    this.setState({ airBnbQueryString: queryString });
  };

  checkAvailabilityDate = async () => {
    if (this.state.startDate && this.state.endDate) {
      var inputData = {
        listing_slug: this.state.unit_slug,
        checkin_date: this.state.startDate,
        checkout_date: this.state.endDate,
        user_token: this.state.userToken,
        level: 5
      };
      await this.getAvailibityDate(inputData);
      await this.createQueryString();
    }
  };

  guestDetail = () => {
    var message = "";
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Children"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    this.setState({ guestValue: message });
    // return message;
  };

  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var slug = this.state.unit_slug;
    var totalRecords = 0;
    var cacheKeyName =
      global.theme +
      "_UNIT_DETAILS_DATA_" +
      this.state.property_slug +
      this.state.unit_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data =
        global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
          ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
          : localStorage.removeItem(cacheKeyName);
      localStorage.setItem("maximum_number_of_guests", data.unit.maximum_number_of_guests ? data.unit.maximum_number_of_guests : 1);
      await this.setState({
        total_record: data.list,
        UnitListing: data,
        bedroom: data.unit.floor_plan.bedroom,
        bathroom: data.unit.floor_plan.bath,
        guest: data.unit.maximum_number_of_guests !== "" ? parseInt(data.unit.maximum_number_of_guests) : 1,
        media_files: data.mediaFiles,
        all_images: data.all_images,
        health_safety: data ? data.property ? data.property.health_safety : "" : "",
        refundable_policy: data ? data.property ? data.property.refundable_policy : "" : "",
        guest_manual: data ? data.property ? data.property.guest_manual : "" : "",
        pet_allowed: data ? data.property ? data.property.pet_allowed === 1 ? "Pets are allowed" : "Pets are not allowed" : "" : ""
      });
    }
    let res = await StrlistingApi.getUnitlisting(slug, this.state.property_slug);
    //let res = await UnitlistingApi.getUnitlisting();
    if (global.successStatus.includes(res.status)) {
      var resData = {};
      var propertyAmenityList = [];
      var propertyAmenityTotal = 0;
      list = res.data ? res.data : [];
      resData.property_name = res.data ? res.data.property ? res.data.property.property_name : "" : "";
      resData.check_in_time = res.data ? res.data.property ? res.data.property.check_in_time : "" : "";
      resData.check_out_time = res.data ? res.data.property ? res.data.property.check_out_time : "" : "";
      resData.smoking_type = res.data ? res.data.property ? res.data.property.smoking_type ? res.data.property.smoking_type.smoking_type_name : "" : "" : "";
      resData.health_safety = res.data ? res.data.property ? res.data.property.health_safety : "" : "";
      resData.refundable_policy = res.data ? res.data.property ? res.data.property.refundable_policy : "" : "";
      resData.guest_manual = res.data ? res.data.property ? res.data.property.guest_manual : "" : "";
      resData.pet_allowed = res.data ? res.data.property ? res.data.property.pet_allowed === 1 ? "Pets are allowed" : "Pets are not allowed" : "" : "";
      resData.city_name = res.data ? res.data.property ? res.data.property.city ? res.data.property.city.city_name : "" : "" : "";
      resData.latitude = res.data ? res.data.property ? res.data.property.latitude : "" : "";
      resData.longitude = res.data ? res.data.property ? res.data.property.longitude : "" : "";
      resData.state_name = res.data ? res.data.property ? res.data.property.state ? res.data.property.state.state_name : "" : "" : "";
      resData.country_name = res.data ? res.data.property ? res.data.property.country ? res.data.property.country.country_name : "" : "" : "";
      resData.getting_around = res.data ? res.data.property ? res.data.property.getting_around : "" : "";
      resData.property_rules = res.data ? res.data.property ? res.data.property.property_rules : "" : "";
      resData.property_slug_main = res.data ? res.data.property ? res.data.property.slug : "" : "";
      resData.unit_slug_main = res.data ? res.data.unit ? res.data.unit.slug : "" : "";
      this.setState({
        property_slug_main: resData.property_slug_main,
        unit_slug_main:resData.unit_slug_main
      });
      totalRecords = list ? list.length : 0;
      resData.title = res.data ? res.data.listing_name ? res.data.listing_name : "" : "";
      // resData.favicon = res.data ? res.data.property ? res.data.property.property_logo_thumb_url : "" : "";
      // resData.keywords = res.data ? res.data.listing_description ? res.data.listing_description : "" : "";
      resData.description = res.data ? res.data.listing_description ? res.data.listing_description.substring(0, 300) : "" : "";
      resData.property_main_image = res.data.property ? res.data.property.property_main_image_url : "";

      //propertyAmenityList = res.data ? res.data.propertyAmenity : [];
      propertyAmenityList = {};
      res.data.propertyAmenity.map((index, value) => {
        if (propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']] !== undefined) {
          propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']].push(
            {'name':res.data.propertyAmenity[value]['amenity_name'],"icon":res.data.propertyAmenity[value]['amenity_icon']}
          );
        } else {
          propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']] = [
            {'name':res.data.propertyAmenity[value]['amenity_name'],"icon":res.data.propertyAmenity[value]['amenity_icon']}
          ];
        }
      })
      propertyAmenityTotal = res.data.propertyAmenity ? res.data.propertyAmenity.length : 0;
      this.setState({
        propertyAmenityList: propertyAmenityList,
        propertyAmenityTotal: propertyAmenityTotal,
      });
      await this.setState(resData);
      var count = 0;
      list.mediaFiles.length > 0
        ? list.mediaFiles.map((item, i) => {
          if (i === 0) {
            var a = item.large_img_w_720;
            item.is_main_img = 1;
            this.setState({ mainImage: a });
            this.setState({ image: item.medium_img });
          }


          var width = parseInt(this.randomNumber(800, 1000));
          var height = parseInt(this.randomNumber(500, width));
          var orgFile = item.large_img_w_1200;
          var mediumImg = item.large_img_w_720;
          item.file_name = orgFile;
          item.medium_img = mediumImg;
          list.mediaFiles[i]["caption"] = item.description
            ? item.description
            : "";
          list.mediaFiles[i]["src"] = orgFile; //orgFile;
          list.mediaFiles[i]["thumbnail"] = i === count ? mediumImg : mediumImg;
          list.mediaFiles[i]["thumbnailWidth"] = width;
          list.mediaFiles[i]["thumbnailHeight"] = height;
          list.mediaFiles[i]["vwidth"] = 1;
          list.mediaFiles[i]["vheight"] = 1;
          list.mediaFiles[i]["main_image"] = orgFile;
          count = i === count ? count + 2 : count;
        })
        : this.setState({ mainImage: global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg') });
    }
    // if (this.state.mainImage === "") {
    //   list.mediaFiles.length > 0
    //     ? list.mediaFiles.map((item, i) => {
    //       if (i === 0) {
    //         item.is_main_img = 1;
    //         var a = item.large_img_w_720;
    //         this.setState({ mainImage: a });
    //         this.setState({ image: item.medium_img });
    //       }
    //     })
    //     : "";
    // }

    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(list));
    localStorage.setItem("maximum_number_of_guests", res.data.unit ? res.data.unit.maximum_number_of_guests : 1);

    await this.setState({
      showSpinner: true,
      loading: true,
      seoLoaded: true,
      total_record: totalRecords,
      UnitListing: list,
      bedroom: list.unit.floor_plan.bedroom,
      bathroom: list.unit.floor_plan.bath,
      square_foot: list.unit.floor_plan.square_foot,
      guest: list.unit.maximum_number_of_guests,
      property: list.property,
      media_files: list.mediaFiles,
      all_images: list.all_images,
    });
  }
  async neighborhoodDetail() {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var slug = this.state.unit_slug;
    var property_slug = this.state.property_slug;
    var totalRecordsneighborhood = 0;
    let res = await StrlistingApi.getNeighbourhoodlisting(property_slug);

    if (global.successStatus.includes(res.status)) {
      var resData = {};
      list = res.data ? res.data : [];
      totalRecordsneighborhood = list ? list.length : 0;
    }
     await this.setState({
      neighborhoodlist: list,
      totalRecordsneighborhood:totalRecordsneighborhood,
     });
  }
  async getBeddata() {
    this.setState({ showSpinner: true, loading: true });
    var listBed = [];
    var listing_slug = this.state.unit_slug;
    var totalRecordsBed = 0;
    let res = await StrlistingApi.getBedlisting(listing_slug);

    if (global.successStatus.includes(res.status)) {
      listBed = res.data ? res.data : [];
      totalRecordsBed = listBed ? listBed.length : 0;
    }
     await this.setState({
      listBed: listBed,
      totalRecordsBed:totalRecordsBed,
     });
  }

  randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  async getAvailibityDate(dynamicData = "") {
    this.setState({ showSpinner: true, loading: true });
    var inputData =
      dynamicData !== ""
        ? dynamicData
        : {
          listing_slug: this.state.unit_slug,
          checkin_date: this.state.startDate,
          checkout_date: this.state.endDate,
          user_token: this.state.userToken,
          level: 5
        };
    let res = await ReservationService.getAvailibityDate(inputData);
    var availableDate = [];
    var bookedDate = [];
    var pricing = [];
    var userToken = "";
    var message = "";
    var date_message = "";
    var redirectToNextPage = false;
    var minimumDate = [];
    var date_message = "";
    //let res = await UnitlistingApi.getUnitlisting();
    if (global.successStatus.includes(res.status)) {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      availableDate =
        res.data && res.data.available_dates ? res.data.available_dates : [];
      bookedDate =
        res.data && res.data.reserved_dates ? res.data.reserved_dates : [];
      pricing = res.data && res.data.pricing ? res.data.pricing : {};
      redirectToNextPage = res.data && res.data.pricing ? true : false;
      (res.data && res.data.pricing) ? localStorage.setItem("booktourpage", true) : "";

      if (pricing) {

        let listing_price = pricing.listing_price ? pricing.listing_price : "";
        let convenienceFee = pricing.convenienceFee ? parseFloat(pricing.convenienceFee) : "";

        let cleaning_fee = pricing.cleaning_fee ? parseFloat(pricing.cleaning_fee) : "";
        let fee_total = convenienceFee + cleaning_fee;
        let nights = pricing.nights ? pricing.nights : "";
        if (listing_price && nights) {
          let nightly_rate = listing_price / nights;
          let nightly_price_rate = listing_price;
          let total_without_taxes = pricing.listing_price + parseFloat(fee_total);
          // let total_round_off =total_without_taxes ?  global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(total_without_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)
          this.setState({ date_wise_nightly_rate: "$" + parseInt(nightly_rate), total_without_taxes: total_without_taxes, nightly_price_rate: nightly_price_rate })
        }
        localStorage.setItem("pricing", JSON.stringify(pricing));
      }
      if (this.state.startDate && this.state.endDate) {
        localStorage.setItem("checkin", this.state.startDate);
        localStorage.setItem("checkout", this.state.endDate);
      }
    } else if (res.status === 422) {
      date_message = "This date has already been reserved";
      availableDate = res.data && res.data.result ? res.data.result.available_dates : [];
      this.setState({ resrved_date_message: res.data && res.data.result ? res.data.result.message : "", startDate: "", endDate: ""});
    } else {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      message = res.data && res.data.message ? res.data.message : "";
      res.data && res.data.result ? res.data.result.available_dates : [];
      bookedDate =
        res.data && res.data.result ? res.data.result.reserved_dates : [];
      redirectToNextPage = false;
    }
    if (
      this.state.userToken === undefined ||
      this.state.userToken === "" ||
      !this.state.userToken
    ) {
      localStorage.setItem("userToken", userToken);
    }
    this.createQueryString();
    await this.setState({
      availableDate: availableDate,
      bookedDate: bookedDate,
      enableDatePicker: false,
      message: message,
      pricing: pricing,
      redirectToNextPage: redirectToNextPage,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  openModal = () => {
    this.setState({ availablity_modal_box: false });
  };

  openGalaryModal = () => {
    this.setState({ openGalaryModal: true });
  };

  closeGalaryModal = () => {
    this.setState({ openGalaryModal: false });
  };

  Setclose(e) {
    var unit = document.getElementById("dropdown-basic-str-detail-page");
    unit.click();
  }

  Setclose2(e) {
    var unit = document.getElementById("dropdown-basic-str-detail-page2");
    unit.click();
  }

  handleChange = (event) => {
    this.setState({ personName: event.target.value });
  };

  handleDateChange(event) {
    this.setState({ selectedDate: event.target.value });
  }

  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }

  resetCount = async () => {
    await this.setState({ adultCount: 1, childCount: 0, infantCount: 0, petsCount: 0, total_selected_guest: 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementAdult() {
    await this.setState({ adultCount: this.state.adultCount + 1, total_selected_guest: this.state.total_selected_guest + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({ adultCount: this.state.adultCount - 1, total_selected_guest: this.state.total_selected_guest - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({ childCount: this.state.childCount + 1, total_selected_guest: this.state.total_selected_guest + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({ childCount: this.state.childCount - 1, total_selected_guest: this.state.total_selected_guest - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementpets() {
    await this.setState({ petsCount: this.state.petsCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementpets() {
    if (this.state.petsCount === 0) {
      await this.setState({ petsCount: 0 });
    } else {
      await this.setState({ petsCount: this.state.petsCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  closeAvalModal() {
    this.setState({ availablity_modal_box: false });
  }

  onDateChange = async (startDate, endDate) => {
    var previousStartDate = this.state.startDate;
    var previousEndDate = this.state.endDate;
    var varStartDate = "";
    var varEndDate = "";
    if (startDate === null && endDate === null) {
      this.setState({ startDate: "", endDate: "", minDate: new Date() });
    } else if (
      Moment(startDate).format("YYYY-MM-DD") ===
      Moment(endDate).format("YYYY-MM-DD")
    ) {
      this.setState({
        startDate: Moment(startDate).format("YYYY-MM-DD"),
        endDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
        minDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
      });
    } else if (
      previousStartDate === Moment(startDate).format("YYYY-MM-DD") &&
      previousEndDate === Moment(endDate).format("YYYY-MM-DD")
    ) {
      this.setState({
        startDate: Moment(startDate).format("YYYY-MM-DD"),
        endDate: Moment(endDate).format("YYYY-MM-DD"),
        minDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
      });
    } else if (
      previousStartDate !== Moment(startDate).format("YYYY-MM-DD") &&
      previousEndDate === Moment(endDate).format("YYYY-MM-DD")
    ) {
      this.setState({
        startDate: Moment(startDate).format("YYYY-MM-DD"),
        endDate: Moment(endDate).format("YYYY-MM-DD"),
        minDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
      });
    } else {
      if (startDate !== null) {
        varStartDate = Moment(startDate).format("YYYY-MM-DD");
        this.setState({ startDate: varStartDate, minDate: varStartDate });
      }
      if (endDate !== null) {
        varEndDate = Moment(endDate).format("YYYY-MM-DD");
        this.setState({ endDate: varEndDate, minDate: new Date() });
      }
      if (endDate === null) {
        varStartDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
        this.setState({ endDate: varStartDate, minDate: new Date() });
      }
      if (previousStartDate !== varStartDate) {
        this.setState({ minDate: varStartDate });
      }
    }
    this.setState({ message: "" });
    this.creatAirBnbQueryString();
    await this.createQueryString();
  };

  render() {
    const [start, startRef] = "";
    const [end, endRef] = "";
    {
      /* meta data for index page for seo friendly url */
    }
    if (this.state.openGalaryModal) {
      return (
        <Gallery
          media_files={this.state.all_images}
          closeFun={this.closeGalaryModal.bind(this)}
        />
        // <GallaryView mediaList={this.state.media_files} closeFun={this.closeGalaryModal.bind(this)} />
      );
    } else {
      return (
        <React.Suspense fallback={<p>Loading list...</p>}>
          {/* Meta Tag Start */}
          {
            this.state.seoLoaded &&
            <MetaSEO
              title={this.state.title}
              favicon={this.state.favicon}
              keywords={this.state.keywords}
              description={this.state.description}
              noIndex={"true"}
              url={this.state.url}
              image={this.state.image}
              fb_app_id={this.state.fb_app_id}
              author={"OHAI"}
            />
          }
          {/* Meta Tag End */}
          <React.Fragment>
            <main className="oh-template Brandmainpage detail_page detail_page_str">
              {/* Header */}
              <CommonHeader propertySlug={this.state.property_slug} />
              {/* middle */}
              {this.state.UnitListing ? (
                // this.state.UnitListing.map((item, i) => {
                // return (
                <>
                  <section className="middle">
                    {/* <Datepicker selectMultiple={true} value={myCleaningDays} onChange={pickerChange} /> */}

                    <div className="mainstrdetailContainer p-0">

                      <div className="col-12  str_heading_top strtitledeskview">
                        <div className="property_detail d-flex justify-content-between align-items-end">
                          <div className="property_heading p-0">
                            <h4 className="listing_title p-0">{`${this.state.property ? this.state.property.property_name : ""}`}</h4>

                            <h5 className="m-0">
                              <a
                                href={
                                  this.state.property
                                    ? this.state.property
                                      .google_map_link
                                    : ""
                                }
                                target="_blank"
                              >
                                {this.state.property.city
                                  ? this.state.property.city.city_name +
                                  ", "
                                  : ""}{" "}
                                {this.state.property.state
                                  ? this.state.property.state
                                    .state_name + ", "
                                  : ""}
                                {this.state.property.country
                                  ? this.state.property.country
                                    .country_name
                                  : ""}
                              </a> </h5>
                          </div>
                          <span className="share">
                            <Link
                              to={"#"}
                              onClick={() =>
                                this.showSocialLinks(true)
                              }
                            >
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('share-icon.svg')} alt="Share" title="Share" />
                              <b>Share</b>
                            </Link>
                          </span>
                        </div>
                      </div>



                      <div className="mainstrDetail">


                        {this.state.media_files &&
                          this.state.media_files.length > 0 ? (
                          <section className="strImgLising deskview">
                            <div className="col-m-12 pd-l-30 pd-r-30">
                              <div className="row m-0 strSection">
                                <div className="col-lg-6 pd-t-0 pd-b-0 pd-l-6 pd-r-0 mainstrviewimag">
                                  {/* {this.state.media_files.map(
                                      (value, index) => {
                                         if (value.is_main_img === 1) {
                                          return ( */}
                                  <figure
                                    className="mainImg"
                                    key={"mainImg"}
                                  >
                                    {
                                      !this.state.mainImage ?
                                        <Skeleton variant="rectangular" width="100%" height={412} />
                                        :
                                        <img
                                          onClick={this.openGalaryModal}
                                          src={
                                            this.state.mainImage
                                              ? this.state.mainImage
                                              : this.state.property_main_image
                                          }
                                          // alt="Main Image"
                                          // title="Main Image"
                                        />
                                    }
                                  </figure>
                                  {/* );
                                         } */}
                                  {/* }
                                    )} */}
                                  {/* // <img src={global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg')} alt="" /> */}
                                </div>
                                <div className="col-lg-6  pd-t-0 pd-b-3 pd-l-0 pd-r-0 strviewimag">
                                  <ul>
                                    {this.state.media_files.map(
                                      (value, index) => {
                                        if (
                                          value.is_main_img !== 1 &&
                                          index <= 4
                                        ) {
                                          return (
                                            <li key={index}>
                                              <figure>
                                                <img
                                                  onClick={
                                                    this.openGalaryModal
                                                  }
                                                  src={
                                                    value.medium_img
                                                      ? value.medium_img
                                                      : this.state.property_main_image
                                                  }
                                                  // alt={
                                                  //   "Other Image " + index
                                                  // }
                                                  // title={
                                                  //   "Other Image " + index
                                                  // }
                                                />
                                              </figure>
                                            </li>
                                          );
                                        }
                                      }
                                    )}
                                  </ul>
                                  {this.state.media_files.map(
                                    (value, index) => {
                                      if (index >= 5) {
                                        return (
                                          <p
                                            key={index}
                                            className="Showmore mt-2 btn btn-link"
                                            onClick={this.openGalaryModal}
                                          >
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('showmore-gallary.png')} alt="Show More" title="Show More" />
                                            Show More
                                          </p>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              </div>
                              {/*  <div className="row m-0">
                            <div className="col-lg-6 pd-t-5 pd-b-5 pd-l-0 pd-r-5">
                              <figure className="mainImg">
                                <img
                                  src={
                                    this.state.mainImage
                                      ? this.state.mainImage
                                      : global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg')
                                  }
                                  alt={
                                    this.state.mainImage
                                      ? this.state.mainImage
                                      : global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg')
                                  }
                                  onClick={() =>
                                    this.openImageModal(this.state.mainImage)
                                  }
                                />
                              </figure>
                            </div>

                            <div className="col-lg-6  pd-t-0 pd-b-0 pd-l-0 pd-r-0">
                              <figure>
                                <Gallery
                                  images={
                                    this.state.media_files
                                      ? this.state.media_files
                                      : strlistimg2
                                  }
                                  enableImageSelection={false}
                                />
                              </figure>
                            </div>
                          </div> */}
                            </div>
                          </section>
                        )
                          :
                          <div className="container d-flex flex-wrap w-100 pd-l-35 pd-r-35 skeletonGallerymob">
                            <div className="col-md-6">
                              <Skeleton variant="rectangular" width="100%" height={412} />
                            </div>
                            <div className="col-md-6 justify-content-center row m-0 skeletonGallerythumbmob">
                              <div className="col-md-12- row m-0 pd-l-3 pd-r-0">
                                <div className="col-md-6 d-flex flex-wrap pd-l-3 pd-r-3 justify-content-between flex-column">
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                </div>
                                <div className="col-md-6 d-flex flex-wrap pd-l-3 pd-r-3 justify-content-between flex-column">
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        {this.state.media_files &&
                          this.state.media_files.length > 0 && (
                            <section className="strImgLising mobview">
                              <>
                                <div className="col-m-12 ">
                                  <div className="row m-0 strSection">
                                    <div className="col-lg-6 mg-t-5 pd-b-3 pd-l-6 pd-r-0 mainstrviewimag">
                                      <div className="d-flex align-items-center justify-content-between shareMobileview">
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} onClick={this.backToPage} alt="Back" title="Back" />
                                        {/* <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')}} onClick={history.back()} alt="Back" title="Back" /> */}
                                        <span className="share">
                                          <Link
                                            to={"#"}
                                            onClick={() =>
                                              this.showSocialLinks(true)
                                            }
                                          >
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('share-icon.svg')} alt="Share" title="Share" />
                                            <b>Share</b>
                                          </Link>
                                        </span>
                                      </div>
                                      <div className="col-12  str_heading_top strtitlemobview">
                                      <div className="property_detail d-flex justify-content-between align-items-end">
                                        <div className="property_heading p-0">
                                          <h4 className="listing_title p-0">{`${this.state.property ? this.state.property.property_name : ""}`}</h4>

                                          <h5 className="m-0">
                                            <a
                                              href={
                                                this.state.property
                                                  ? this.state.property
                                                    .google_map_link
                                                  : ""
                                              }
                                              target="_blank"
                                            >
                                              {this.state.property.city
                                                ? this.state.property.city.city_name +
                                                ", "
                                                : ""}{" "}
                                              {this.state.property.state
                                                ? this.state.property.state
                                                  .state_name + ", "
                                                : ""}
                                              {this.state.property.country
                                                ? this.state.property.country
                                                  .country_name
                                                : ""}
                                            </a> </h5>
                                        </div>
                                        <span className="share d-none">
                                          <Link
                                            to={"#"}
                                            onClick={() =>
                                              this.showSocialLinks(true)
                                            }
                                          >
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('share-icon.svg')} alt="Share" title="Share" />
                                            <b>Share</b>
                                          </Link>
                                        </span>
                                      </div>
                                    </div>
                                      {/* {this.state.media_files.map(
                                        (value, index) => {
                                          if (value.is_main_img !== 1) {
                                            return ( */}
                                              <figure
                                                className="mainImg"
                                                key={"mainImg"}
                                              >
                                                <img
                                                  src={
                                                    this.state.mainImage
                                                    ? this.state.mainImage
                                                    : this.state.property_main_image
                                                  }
                                                  // alt="Main Image"
                                                  // title="Main Image"
                                                />
                                              </figure>
                                            {/* );
                                          }
                                        }
                                      )} */}
                                      {/* // <img src={global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg')} alt="" /> */}
                                    </div>
                                    <div className="col-lg-6  pd-t-0 pd-b-3 pd-l-0 pd-r-0 strviewimag">
                                      <ul>
                                        {this.state.media_files.map(
                                          (value, index) => {
                                            if (
                                              value.is_main_img !== 1 &&
                                              index <= 0
                                            ) {
                                              return (
                                                <li key={index}>
                                                  <figure>
                                                    <img
                                                      src={
                                                        value.medium_img
                                                          ? value.medium_img
                                                          : this.state.property_main_image
                                                      }
                                                      // alt={
                                                      //   "Other Image " + index
                                                      // }
                                                      // title={
                                                      //   "Other Image " + index
                                                      // }
                                                    />
                                                  </figure>
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                      {this.state.media_files.map(
                                        (value, index) => {
                                          if (index >= 1) {
                                            return (
                                              <p
                                                key={index}
                                                className="Showmore mt-2 btn btn-link"
                                                onClick={this.openGalaryModal}
                                              >
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('showmore-gallary.png')} alt="Show More" title="Show More" />
                                                Show More
                                              </p>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                  {/*  <div className="row m-0">
                            <div className="col-lg-6 pd-t-5 pd-b-5 pd-l-0 pd-r-5">
                              <figure className="mainImg">
                                <img
                                  src={
                                    this.state.mainImage
                                      ? this.state.mainImage
                                      : global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg')
                                  }
                                  alt={
                                    this.state.mainImage
                                      ? this.state.mainImage
                                      : global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg')
                                  }
                                  onClick={() =>
                                    this.openImageModal(this.state.mainImage)
                                  }
                                />
                              </figure>
                            </div>

                            <div className="col-lg-6  pd-t-0 pd-b-0 pd-l-0 pd-r-0">
                              <figure>
                                <Gallery
                                  images={
                                    this.state.media_files
                                      ? this.state.media_files
                                      : strlistimg2
                                  }
                                  enableImageSelection={false}
                                />
                              </figure>
                            </div>
                          </div> */}
                                </div>
                              </>
                            </section>
                          )}

                        <section className="strDetail str_property_info">
                          <div className="col-m-12 pd-l-35 pd-r-35">
                            <div className="row">
                              <div className="col-md-6 ">
                                <h4 className="listing_title">
                                  {this.state.UnitListing.listing_name ? (
                                    this.state.UnitListing.listing_name
                                  ) : (
                                    <Skeleton
                                      variant="rectangular"
                                      width="100%"
                                      height={50}
                                    />
                                  )}
                                </h4>

                                <div className="property_detail">
                                  <div className="Property_info">
                                    <div className="property_size">
                                      <ul className="property_size_brd">
                                        <li>{`${this.state.bedroom > 1 ? this.state.bedroom + " Bedrooms" : this.state.bedroom + " Bedroom"} / ${this.state.bathroom > 1 ? this.state.bathroom + " Bathrooms" : this.state.bathroom + " Bathroom"}`}</li>
                                        <li>{"Sleeps " + (this.state.guest > 1
                                          ? this.state.guest : "1 ") + " People"}</li>
                                        <li>{`${this.state.square_foot
                                          ? parseInt(this.state.square_foot)
                                          : ""
                                          } Sq ft`}</li>
                                      </ul>
                                    </div>
                                    <div className="property_aminties">
                                      <ul>
                                        <li> Full kitchen  </li>
                                        <li> Living room </li>
                                        <li>Pool</li>
                                        <li>Gym</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>


                                <div className="guest-availabity-review pd-l-0 mg-b-15 ">
                                  <ul className="d-none">
                                    <>
                                      {this.state.property ?
                                        (
                                          <>
                                            <li>
                                              <img
                                                src={
                                                  global.theme === "kini"
                                                    ? global.OnKeyPressEvent.getS3ImageUrl('r-star.png')
                                                    : global.OnKeyPressEvent.getS3ImageUrl('r-star.png')
                                                }
                                                className="mg-r-5"
                                              />{" "}
                                              5.0
                                            </li>
                                            <li>
                                              <Link to={"#"}>323 reviews</Link>
                                            </li>
                                            <li>
                                              <a
                                                href={
                                                  this.state.property
                                                    ? this.state.property
                                                      .google_map_link
                                                    : ""
                                                }
                                                target="_blank"
                                                className="text-decoration-none "
                                              >
                                                {this.state.property.city
                                                  ? this.state.property.city.city_name +
                                                  ", "
                                                  : ""}{" "}
                                                {this.state.property.state
                                                  ? this.state.property.state
                                                    .state_name + ", "
                                                  : ""}
                                                {this.state.property.country
                                                  ? this.state.property.country
                                                    .country_name
                                                  : ""}
                                              </a>
                                            </li>
                                          </>
                                        )
                                        :
                                        (
                                          <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height={50}
                                          />
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>

                                <div className="str_discp_heading border-bottom text-left">
                                  <div className="miscSection pd-l-0 pd-t-15">
                                    <h5 className="text-left-cust-str">Description</h5>
                                  </div>
                                  {this.state.UnitListing.listing_description ?
                                (
                                  <>
                                 
                                  {/* <p> */}
                                  {/* <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.UnitListing ? 
                                      this.state.UnitListing.listing_description:""
                                    }
                                    maxLine="6"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className="span_text ltr_pro_desp"
                                  /> */}
                                  <p
                                   dangerouslySetInnerHTML={{
                                        __html:
                                          this.state.UnitListing
                                            .listing_description.substring(0,300) + "...",
                                      }}>
                                      </p>
                                  {/* </p> */}
                                  {this.state.UnitListing.listing_description ? (
                                    <button
                                      className="showmoreLink-desc"
                                      onClick={() =>
                                        this.openDescriptionModal(true)
                                      }
                                    >
                                      Show more{" "}
                                    </button>
                                  ) : (
                                    <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={150}
                                  />
                                  )}
                                {/* <p
                                      style={{ whiteSpace: "pre-wrap" }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          this.state.UnitListing
                                            .listing_description,
                                      }}
                                    ></p> */}
                                    {/* <p
                                      style={{ whiteSpace: "pre-wrap" }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          this.state.UnitListing
                                            .listing_description,
                                      }}
                                    ></p>
                                  </>
                                  :
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      width="100%"
                                      height={150}
                                    /> */}
                                  </>
                                ) : (
                                  ""
                                )}
                                  {/* <div className="d-flex justify-content-between pd-r-0 mg-t-30 guestBlock">
                                  <span>
                                    <h5>
                                      {this.state.UnitListing.host_name ?
                                        <>
                                          Entire guest house hosted by Kini{" "}
                                          {this.state.UnitListing.host_name}
                                        </>
                                        :
                                        <Skeleton
                                          variant="rectangular"
                                          width="100%"
                                          height={30}
                                        />
                                      }

                                    </h5>
                                    <div className="floorplan-basic">
                                      <ul>
                                        <li>
                                          {this.state.guest > 1
                                            ? this.state.guest + " guests"
                                            : "1 guest"}{" "}
                                        </li>

                                        {this.state.bedroom !== null && (
                                          <li>
                                            {this.state.bedroom > 1
                                              ? this.state.bedroom + " bedrooms"
                                              : this.state.bedroom + " bedroom"}
                                          </li>
                                        )}
                                        {this.state.guest !== null && (
                                          <li>
                                            {this.state.guest > 1
                                              ? this.state.guest + " beds"
                                              : this.state.guest + " bed"}
                                          </li>
                                        )}
                                        {this.state.bathroom !== null && (
                                          <li>
                                            {this.state.bathroom > 1
                                              ? this.state.bathroom + " bathrooms"
                                              : this.state.bathroom +
                                              " bathroom"}{" "}
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </span>
                                  {this.state.UnitListing
                                    .host_photo_thumb_url ? (
                                    <figure>
                                      <img
                                        src={
                                          this.state.UnitListing
                                            .host_photo_thumb_url
                                            ? this.state.UnitListing
                                              .host_photo_thumb_url
                                            : global.OnKeyPressEvent.getS3ImageUrl('dummy-profile.jpeg')
                                        }
                                      />
                                    </figure>
                                  ) : (
                                    <Skeleton variant="circular" height={60} width={60} className='gustthumb'>
                                      <Avatar />
                                    </Skeleton>
                                  )}
                                </div> */}
                                </div>
                                <div className="miscSection p-0">
                                    <div className="commanBlockdetail p-0 col-lg-12 border-none">
                              
                                        {this.state.totalRecordsBed >0 ? (
                                            <div className="row">
                                              <div className="col-lg-12 pd-l-10 pd-r-10">
                                                <div className="col-12 border-bottom str_bed_details" >

                                                  
                                                  <h5 className=" m-0">Where you’ll sleep</h5>
                                                  
                                                  <div className="row">
                                                  {this.state.listBed&&
                                              this.state.listBed.map(
                                              (value, index) => {
                                                return(
                                                  <>
                                                    <div className="col-md-4 col-6">
                                                      <div className="str_bed_box">
                                                        <figure>
                                                          <img src={KingBed} alt="" />
                                                        </figure>
                                                        <p>Bedroom {value.bedroom_no}</p>
                                                        <span>{value.amenity_name}</span>
                                                      </div>
                                                    </div></>)})}
                                                  </div> 
                                                </div>
                                              </div>
                                            </div>  ):""}  

                                            <div className="row">
                                              {/* <div className="col-lg-6 pd-l-12 pd-r-12 mg-t-30 guestBlock ">
                                                <h5>Where you'll sleep </h5>
                                                <div className="leftBlock">
                                                  <figure className="Bedtpye">
                                                    <img src={this.state.mainImage ? this.state.mainImage : bedImage} />
                                                  </figure>
                                                  <div className="d-flex flex-wrap align-items-baseline bedtypeText mg-t-15 mg-b-15">
                                                    <b>Bedroom</b>
                                                    <img src={arrowlong} />
                                                    <b>1 King bed</b>
                                                  </div>
                                                </div>
                                              </div> */}





                                              <div className="col-lg-12 pd-l-10 pd-r-10 mg-t-35 mg-b-45 mg-sm-b-3 guestBlock what-place-sec-">

                                                <h5 className="text-left-cust-str">What this place offers </h5>
                                                <div className="aminitiesDeskview">
                                                  <div className="amenitiesBlock">

                                                    {/* {console.log(Object.keys(this.state.propertyAmenityList))} */}
                                                    {this.state.propertyAmenityTotal > 0
                                                      ? Object.keys(this.state.propertyAmenityList).map(
                                                        (opt, i) => {
                                                          if (i < 4) {
                                                            return (
                                                              <div className="aminty_box_break">
                                                                {/* <h4 className="text-left">{opt}</h4> */}
                                                                <ul>
                                                                  {this.state.propertyAmenityList[opt].map((k, v) => {
                                                                    // {console.log(k)}
                                                                    return (
                                                                      // <li key={v}>{k.name}</li>
                                                                      <li key={v}><img src={k.icon}></img>{k.name}</li>
                                                                      // <li key={i}>{opt.amenity_name}</li>
                                                                    );

                                                                  }
                                                                  )}
                                                                </ul>
                                                              </div>
                                                            )
                                                          }
                                                        }
                                                      )
                                                      : ""}

                                                  </div>
                                                  {this.state.propertyAmenityTotal > 10 ? (
                                                    <button
                                                      className="btn btn-link Showmore "
                                                      onClick={() =>
                                                        this.openAllAmenitiesModal(true)
                                                      }
                                                    >
                                                      Show all amenities
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                                <div className="aminitiesMobview">
                                                  <div className="amenitiesBlock">
                                                    {this.state.propertyAmenityTotal > 0
                                                      ? Object.keys(this.state.propertyAmenityList).map(
                                                        (opt, i) => {
                                                          if (i < 4) {
                                                            return (
                                                              <div className="aminty_box_break">
                                                                {/* <h4 className="text-left">{opt}</h4> */}
                                                                <ul>
                                                                  {this.state.propertyAmenityList[opt].map((k, v) => {
                                                                    // {console.log(k)}
                                                                    return (
                                                                      // <li key={v}>{k.name}</li>
                                                                      <li key={v}><img src={k.icon}></img>{k.name}</li>
                                                                      // <li key={i}>{opt.amenity_name}</li>
                                                                    );

                                                                  }
                                                                  )}
                                                                </ul>
                                                              </div>
                                                            )
                                                          }
                                                        }
                                                      )
                                                      : ""}




                                                  </div>
                                                  {this.state.propertyAmenityTotal > 5 ? (
                                                    <button
                                                      className="btn btn-link Showmore"
                                                      onClick={() =>
                                                        this.openAllAmenitiesModal(true)
                                                      }
                                                    >
                                                      Show all amenities
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                              {/* <h5>Do it like to a local</h5>
                                              {this.state.neighborhoodlist?.type_list &&
                                              this.state.neighborhoodlist.type_list.map(
                                              (value, index) => {
                                                return(
                                                  <>
                                              <div className="commanBlockdetail pd-l-2 pd-r-2 mg-lg-t-30 mg-b-30 border-bottom">
                                                <p>{value.image}</p>
                                                <p>{value.tag_line}</p>
                                                <p>{value.description}</p>
                                                <p>{value.map}</p>
                                                <p>{value.attraction_type_name}</p>
                                          </div></>)})} */}
                                            </div>
                                    </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="guest-availabity guest-availabity-deskview ">
                                  <div className="d-flex justify-content-between align-items-center mg-b-30 mobigustavi">
                                    <h4 className="listing_title">
                                      {this.state.date_wise_nightly_rate ? this.state.date_wise_nightly_rate : (this.state.UnitListing.nightly_rate ? "$" + Math.round(this.state.UnitListing.nightly_rate) : "")}
                                      <b>{Math.round(this.state.UnitListing.nightly_rate) ? "/night" : ""} </b>
                                    </h4>


                                    {/* <span className="share">
                                      <Link
                                        to={"#"}
                                        onClick={() =>
                                          this.showSocialLinks(true)
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('share-icon.svg')} alt="Share" title="Share" />
                                        <b>Share</b>
                                      </Link>
                                    </span> */}
                                  </div>
                                  {/* <Datepicker
                                    controls={['calendar']}
                                    select="range"
                                    touchUi={true}
                                    inputComponent="input"
                                  // inputProps={props}
                                  /> */}
                                  {/* <Datepicker
                                    cssClass="md-book-rental"
                                    controls={['calendar']}
                                    select="range"
                                    display="inline"
                                    calendarType="month"
                                    //calendarSize={6}
                                    min="2022-07-18T00:00"
                                    showRangeLabels={false}
                                    inRangeInvalid={false}
                                    rangeEndInvalid={true}
                                  //renderCalendarHeader={calendarHeader}
                                  // labels={labels}
                                  // invalid={invalid}
                                  //colors={colors}
                                  /> */}
                                  {/* <Datepicker
                                    controls={['calendar']}
                                    select="range"
                                  //ref={setInst}
                                  //buttons={buttons}
                                  //value={val}
                                  //onChange={valChange}
                                  //onTempChange={onTempChange}
                                  /> */}

                                  {/* <Datepicker
                                    controls={['calendar']}
                                    select="range"
                                    calendarType="month"
                                    pages={2}
                                    display="inline"
                                    touchUi={true}
                                  /> */}

                                  {/* <Datepicker
                                    controls={['calendar']}
                                    select="range"
                                    display=""
                                    pages={2}
                                    startInput={this.state.start}
                                    endInput={this.state.end}
                                    min={new Date()}
                                    max={Moment(new Date()).add(1, "M")}
                                  /> */}
                                  {/* <Datepicker
                                    controls={['calendar']}
                                    select="range"
                                    pages={2}
                                    display="bubble"
                                    rangeHighlight={true}
                                    showRangeLabels={false}
                                    rangeStartHelp="CHECK-IN"
                                    rangeEndHelp="CHECKOUT"
                                  />

                              <Datepicker
                                  controls={['calendar']}
                                  select="range"
                                  calendarType="month"
                                  pages={2}
                                  display="inline"
                                  touchUi={true}
                              /> */}

                                  {/* <Input ref={this.state.setStart} placeholder="Please Select...">Outbound</Input>
                                  <Input ref={this.state.setEnd} placeholder="Please Select...">Return</Input> */}
                                  {/* <Input
                                    //ref={this.state.startDate}
                                    placeholder="Please Select...">Start
                                  </Input>
                                  <Input
                                    // ref={this.state.endDate}
                                    placeholder="Please Select...">End
                                  </Input> */}

                                  {/* New Input Fields */}

                                  <div className="guestfilter guestfilterNew mg-b-30">
                                    {/* <RangePicker
                                  renderExtraFooter={() => 'extra footer'}
                                  /> */}

                                    {/* <RangeDatePicker
                                      startDate={this.state.startDate}
                                      endDate={this.state.endDate}
                                      minDate={this.state.minDate}
                                      startDatePlaceholder="Add date"
                                      endDatePlaceholder="Add date"
                                      monthFormat="MMMM"
                                      onChange={(startDate, endDate) =>
                                        this.onDateChange(startDate, endDate)
                                      }
                                      disabled={this.state.enableDatePicker}
                                    /> */}
                                    {/* changeDateHandlerNew={this.changeDateHandlerNew.bind(this)} */}
                                    <DatePickerFile
                                      datePickerOpen={this.state.datePickerOpen}
                                      changeDateHandlerNew={this.changeDateHandlerNew.bind(this)}
                                      bookedDate={this.state.bookedDate}
                                      availableDate={this.state.availableDate}
                                      startDate={this.state.startDate ? Moment(this.state.startDate).local() : null}
                                      endDate={this.state.endDate ? Moment(this.state.endDate).local() : null}
                                    // date_selection_message={this.state.date_selection_message ? this.state.date_selection_message:""}
                                    />

                                    <div className="dropmenuInputbox">
                                      <Dropdown autoClose={false}>
                                        <Dropdown.Toggle id="dropdown-basic-str-detail-page">
                                          <TextField
                                            id="outlined-read-only-input"
                                            label="Details"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            className="inputBox"
                                            placeholder="Add guests"
                                            value={this.state.guestValue}
                                            sx={{ width: 220 }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>Adults</h4>
                                                  <h5>Age 13+</h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={
                                                      this.decrementAdult
                                                    }
                                                    disabled={this.state.adultCount === 1 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.adultCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementAdult}
                                                    disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>

                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>{this.state.childCount > 1 ? "Children":"Child"}</h4>
                                                  <h5>Ages 2–12</h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={this.decrementchild}
                                                    disabled={this.state.childCount === 0 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.childCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementchild}
                                                    disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>

                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>Infants</h4>
                                                  <h5>Under 2</h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={this.decrementinfant}
                                                    disabled={this.state.infantCount === 0 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.infantCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementinfant}
                                                    disabled={this.state.infantCount === 5 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>

                                              </div>
                                            </div>
                                            {/* <div className="d-flex justify-content-between mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>Pets</h4>
                                                  <h5><Link to={"/"}>Bringing a service animal?</Link></h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={this.decrementpets}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.petsCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementpets}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} />
                                                  </button>
                                                </span>

                                              </div>
                                            </div> */}
                                            {/*  <p>
                                                This place has a maximum of 6
                                                guests, not including infants. Pets
                                                aren't allowed.
                                              </p> */}
                                            <div className="d-flex justify-content-end">
                                              <button
                                                className="done"
                                                id="cnt-close"
                                                onClick={(e) =>
                                                  this.Setclose(e)
                                                }
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <>
                                      <p>{this.state.resrved_date_message ? this.state.resrved_date_message : ""}</p>
                                      <p>{this.state.date_selection_message ? this.state.date_selection_message : ""}</p>
                                    </>
                                    {this.state.message && (
                                      <>
                                        <p className="text-danger">
                                          {" "}
                                          {/* {this.state.message} */}
                                        </p>
                                      </>
                                    )}
                                  </div>

                                  {/* End */}

                                  {/* <a
                                    href={
                                      this.state.UnitListing.book_redirect_to
                                        ? this.state.UnitListing
                                          .book_redirect_to +
                                        this.state.airBnbQueryString
                                        : ""
                                    }
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <button className="guest-avaialbility-btn m-auto">
                                      Book
                                    </button>
                                  </a> */}
                                  {this.state.redirectToNextPage && this.state.startDate && this.state.endDate? (
                                    <>
                                      {/* <a
                                        href={this.state.UnitListing.book_redirect_to
                                          ? this.state.UnitListing
                                            .book_redirect_to +
                                          this.state.airBnbQueryString
                                          : ""}
                                        target="_blank"
                                        className="text-decoration-none"
                                      >
                                        <button className="guest-avaialbility-btn m-auto">
                                          Reserve
                                        </button>
                                      </a> */}
                                      {
                                        this.state.headerToken ?
                                          <button className="guest-avaialbility-btn m-auto" disabled={this.state.loader ? true : false} onClick={() => this.placeReservation()}>
                                            Reserve {this.state.loader ?
                                              <>
                                                {/* <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                                <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                                <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  role="status"
                                                  aria-hidden="true"
                                                /> */}

                                                <SpinnerLoader />
                                              </>
                                              : ""
                                            }

                                          </button>
                                          :
                                          <a
                                            href={
                                              global.themeUrl +
                                              this.state.property_slug + "/book-tour"
                                              + this.state.queryString
                                            }
                                            className="text-decoration-none"
                                          >
                                            <button className="guest-avaialbility-btn m-auto">
                                              Reserve
                                            </button>
                                          </a>
                                      }
                                    </>
                                  ) : (
                                    <button
                                      className="guest-avaialbility-btn m-auto"
                                      onClick={() =>
                                        this.validateDateChange()
                                      }
                                    >
                                      Check availability
                                    </button>
                                  )}
                                  {this.state.redirectToNextPage && this.state.startDate && this.state.endDate ? (
                                    <>
                                      <div className="pricebreakdownsection">
                                        <span>You won't be charged yet</span>
                                        <ul>
                                          <Toast className="d-none" show={this.state.showA} onClose={this.toggleShowA}>
                                            <Toast.Header>
                                            </Toast.Header>
                                            <Toast.Body>
                                              <h5 className="tost_heading">Price breakdown</h5>
                                              <p>03/06/2023 <span>$112</span></p>
                                              <div className="final_price_tost"></div>
                                              <b><p>Total base price <span>$112</span></p></b>

                                            </Toast.Body>

                                          </Toast>


                                          <Toast show={this.state.showB} onClose={this.toggleShowB} className="price_under_fee d-none">
                                            <Toast.Header>
                                              <p>One-time fee charged by host to cover the cost of cleaning their space.</p>
                                            </Toast.Header>
                                            <Toast.Body>

                                            </Toast.Body>
                                          </Toast>


                                          <li>
                                            <span className="price_under" onClick={this.toggleShowA}>${this.state.nightly_price_rate
                                  ? (global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.nightly_price_rate/ this.state.pricing.nights
                                    ).toFixed(2).replace(/\.00$/, "")))
                                  )
                                  : parseFloat(1).toFixed(2)} x {this.state.pricing ? this.state.pricing.nights > 1 ? this.state.pricing.nights + " nights" : this.state.pricing.nights + " night" : ""}</span><span>{this.state.nightly_price_rate ? "$"+this.state.nightly_price_rate : ""}</span>


                                          </li>
                                          {this.state.pricing && this.state.pricing.cleaning_fee ? (

                                            <li>
                                              <span className="price_under" onClick={this.toggleShowB}>Cleaning fee</span>
                                              <span>${this.state.pricing ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}</span>
                                            </li>

                                          ) : ""}
                                          {this.state.pricing && this.state.pricing.convenienceFee ? (
                                            <li ><span>Service fee</span> 
                                            <span>${this.state.pricing ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.convenienceFee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}</span>

                                            </li>
                                          ) : ""}
                                          <li><span>Total before taxes</span><span>${this.state.total_without_taxes?global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.total_without_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}</span> </li>
                                        </ul>
                                      </div>
                                    </>
                                  ) : ""}
                                </div>
                                <div className="guest-availabity guest-availabity-mobileview ">
                                  <div className="d-flex justify-content-between align-items-center mg-b-30 mobigustavi">
                                    <div className="d-flex flex-column w-75">
                                      <h3 className="text-left-cust">
                                        {this.state.date_wise_nightly_rate ? this.state.date_wise_nightly_rate : (this.state.UnitListing.nightly_rate ? "$" + Math.round(this.state.UnitListing.nightly_rate) : "")}
                                        <b>{Math.round(this.state.UnitListing.nightly_rate) ? "/night" : ""}</b>
                                      </h3>
                                      <ul className="guestavail">
                                        <li className="mg-b-3">

                                          <a href="javascript:void(0)" onClick={() =>
                                            this.openGuestSize(true)
                                          }>
                                            {this.state.guestValue}
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0)" onClick={() => this.pricebreakdownModal(true)}>
                                            {(this.state.startDate ?  Moment(this.state.startDate).format("MM-DD-YY")+ " - " :"") + (this.state.endDate ?  Moment(this.state.endDate).format("MM-DD-YY"):"")}
                                          </a>
                                        </li>
                                      </ul>


                                      {/* <Datepicker
                                        controls={['calendar']}
                                        buttons={[, 'cancel']}
                                        pages={2}
                                        buttons={[{
                                          // text: "X",
                                          icon: "material-close",
                                          handler: 'cancel'
                                        }]}
                                        calendarType="month"
                                        className="mbdate"
                                        valid={this.state.availableDate}
                                        select="range"
                                        inputComponent="input"
                                        cssClass="dual_calendar"
                                      
                                        isOpen={this.state.openPicker}
                                        openPicker={() => this.setState({ openPicker: true })}
                                        onLabelClick={() => this.setState({ openPicker: true })}
                                        onInputClick={() => this.setState({ openPicker: true })}
                                        onFocus={() => this.setState({ openPicker: true })}
                                        onClick={() => this.setState({ openPicker: true })}
                                        onClose={() => this.setState({ openPicker: false })}
                                        closeOnEsc={true}
                                       value={[this.state.startDate , this.state.endDate]}
                                        // display="bottom"
                                        onChange={(date) =>
                                          this.changeDateHandlerNewMobile(date, "dateFour")
                                        }
                                        //rangeSelectMode="wizard"
                                        showRangeLabels={true}
                                        touchUi={false}
                                        theme="ios"
                                        themeVariant="light"
                                        headerText=""
                                        max={Moment(new Date()).add(1, "year")}
                                        rangeStartLabel="Check-in"
                                        rangeEndLabel="Checkout"
                                        calendarSize={12}
                                        responsive={{
                                          xsmall: {
                                           
                                            pages: 2,
                                            touchUi: false,
                                            calendarScroll: "horizontal",
                                            calendarSize: "2",
                                            //buttons: ['Cancel'],
                                            max: Moment(new Date()).add(5, "year"),
                                            min: new Date(),
                                            
                                            refDate: Moment(new Date()).add(1, "year"),
                                          },
                                          medium: {
                                           
                                            pages: 2,
                                            
                                          }
                                         
                                        }}
                                      /> */}
                                    </div>



                                    {/* <a
                                      href=""
                                      target="_blank"
                                      className="text-decoration-none"
                                    > */}
                                    {this.state.redirectToNextPage  && this.state.startDate && this.state.endDate? (
                                      <>
                                        {/* <a
                                          href={this.state.UnitListing.book_redirect_to
                                            ? this.state.UnitListing
                                              .book_redirect_to +
                                            this.state.airBnbQueryString
                                            : ""}
                                          target="_blank"
                                          className="text-decoration-none"
                                        >
                                          <button className="guest-avaialbility-btn m-auto">
                                            Reserve
                                          </button>
                                        </a> */}
                                        {
                                          this.state.headerToken ?
                                            <button className="guest-avaialbility-btn m-auto" disabled={this.state.loader ? true : false} onClick={() => this.placeReservation()}>
                                              Reserve {this.state.loader ?
                                                <>
                                                  {/* <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    role="status"
                                                    aria-hidden="true"
                                                  />
                                                  <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    role="status"
                                                    aria-hidden="true"
                                                  />
                                                  <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    role="status"
                                                    aria-hidden="true"
                                                  /> */}

                                                  <SpinnerLoader />
                                                </>
                                                : ""
                                              }

                                            </button>
                                            :
                                            <a
                                              href={
                                                global.themeUrl +
                                                this.state.property_slug + "/book-tour"
                                                + this.state.queryString
                                              }
                                              className="text-decoration-none"
                                            >
                                              <button className="guest-avaialbility-btn m-auto">
                                                Reserve
                                              </button>
                                            </a>
                                        }
                                      </>
                                    ) : (
                                      // <button
                                      //   className="guest-avaialbility-btn m-auto"
                                      //   onClick={() =>
                                      //     this.setState({ openPicker: true })
                                      //   }
                                      // >
                                      //   Check availability
                                      // </button>
                                      <button onClick={() => this.pricebreakdownModal(true)} className="guest-avaialbility-btn m-auto d-none-">
                                        Check availability
                                      </button>
                                    )}



                                    {/* <a
                                      href={
                                        this.state.UnitListing.book_redirect_to
                                          ? this.state.UnitListing
                                            .book_redirect_to +
                                          this.state.airBnbQueryString
                                          : ""
                                      }
                                      target="_blank"
                                      className="text-decoration-none"
                                    >
                                      <button className="guest-avaialbility-btn m-auto">
                                        Reserve
                                      </button>
                                    </a> */}
                                  </div>
                                  <span><p className="text-danger">{this.state.date_message ? this.state.date_message:""}</p></span>
                                  <span><p className="text-danger">{this.state.date_message_reserved ? this.state.date_message_reserved:""}</p></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className="miscSection">
                        <div className="commanBlockdetail p-0 col-lg-12 border-bottom">
{/*                            
                                {this.state.totalRecordsBed >0 ? (
                          <div className="row">
                            <div className="col-lg-6 pd-l-10 pd-r-10 mg-lg-t-30 mg-b-30 mg-sm-b-3">
                              <div className="col-12 border-bottom str_bed_details " >

                                
                                <h5 className=" m-0">Where you’ll sleep</h5>
                                
                                <div className="row">
                                {this.state.listBed&&
                           this.state.listBed.map(
                            (value, index) => {
                              return(
                                <>
                                  <div className="col-md-4 col-6">
                                    <div className="str_bed_box">
                                      <figure>
                                        <img src={KingBed} alt="" />
                                      </figure>
                                      <p>Bedroom {value.bedroom_no}</p>
                                      <span>{value.amenity_name}</span>
                                    </div>
                                  </div></>)})}
                                </div> 
                              </div>
                            </div>
                          </div>  ):""}   */}

                          <div className="row">
                            {/* <div className="col-lg-6 pd-l-12 pd-r-12 mg-t-30 guestBlock ">
                              <h5>Where you'll sleep </h5>
                              <div className="leftBlock">
                                <figure className="Bedtpye">
                                  <img src={this.state.mainImage ? this.state.mainImage : bedImage} />
                                </figure>
                                <div className="d-flex flex-wrap align-items-baseline bedtypeText mg-t-15 mg-b-15">
                                  <b>Bedroom</b>
                                  <img src={arrowlong} />
                                  <b>1 King bed</b>
                                </div>
                              </div>
                            </div> */}

                          
                            {/* <h5>Do it like to a local</h5>
                            {this.state.neighborhoodlist?.type_list &&
                           this.state.neighborhoodlist.type_list.map(
                            (value, index) => {
                              return(
                                <>
                            <div className="commanBlockdetail pd-l-2 pd-r-2 mg-lg-t-30 mg-b-30 border-bottom">
                              <p>{value.image}</p>
                              <p>{value.tag_line}</p>
                              <p>{value.description}</p>
                              <p>{value.map}</p>
                              <p>{value.attraction_type_name}</p>
                        </div></>)})} */}
                          </div>
                        </div>

                        <div className=" pd-l-2 pd-r-2 mg-t-35 mg-b-0 pt-0 border-bottom">
                          <h5 className="text-left-cust-str">Do it like a local</h5>
                          <div className="row">
                         {this.state.neighborhoodlist?.type_list &&
                           this.state.neighborhoodlist.type_list.map(
                            (value, index) => {
                              return(
                                <>

                            <div className="col-md-4 col-12">
                              <div className="do_like_local_box">
                                <a href={value.map ? value.map :""} className="local_box">
                                  <div className="local_box_new">
                                    <div className="local_box_img">
                                      <figure>
                                      <img src={value.image ? value.image:""} alt="" title="" />
                                      </figure>
                                    </div>
                                    <h5 className="loacl_one">{value.attraction_type_name ? value.attraction_type_name:""} </h5>
                                    <h4 className="loacl_two">{value.tag_line ? value.tag_line:""} </h4>
                                    <p>{value.description ? value.description:""}</p>
                                    <div className="do_live_footer">
                                      <a href ={value.map ? value.map:""}className="local_box">
                                      <p>Open collections on the map</p></a>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div></>)})}                           
                          </div>
                        </div>



                   

                        <div className="commanBlockdetail pd-l-2 pd-r-2 mg-t-35 mg-b-0 pt-0 border-bottom">
                          <h5 className="text-left-cust-str">House Manual </h5>
                          <div className="d-flex flex-wrap">
                            <div className="thingsBlock">
                              <span>House rules</span>
                              {/* <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.property_rules
                                        ? this.state.property_rules
                                        : ""
                                    }
                                    maxLine="6"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className=" ltr_pro_desp"
                                  /> */}
                                   <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.property_rules
                                      ? this.state.property_rules.substring(0,100)+".."
                                      : "",
                                  }}
                                ></p>
                                   {this.state.property_rules ? (
                                    <button
                                      className="btn btn-link ShowmoreLink "
                                      onClick={() =>
                                        this.openPropertyrulesModal(true)
                                      }
                                    >
                                      Show more{" "}
                                    </button>
                                  ) : (
                                    ""
                                  )}
                              {/* <ul>
                                <li>
                                  Check-in after:{" "}
                                  {!this.state.check_in_time
                                    ? "3:00 pm"
                                    : this.state.check_in_time}
                                </li>
                                <li>
                                  Check-out before:{" "}
                                  {!this.state.check_out_time
                                    ? "11:00 am"
                                    : this.state.check_out_time}
                                </li>
                                <li>
                                  {!this.state.smoking_type
                                    ? "No smoking"
                                    : this.state.smoking_type}
                                </li>
                                {/* <li>
                                  {!this.state.pet_allowed
                                    ? "Pets are allowed"
                                    : this.state.pet_allowed}
                                </li>
                              </ul> */}
                              {/* <button className="btn btn-link ShowmoreLink">Show more </button> */}
                            </div>
                            {/* {this.state.health_safety !== null && this.state.health_safety !== "" ? (
                              <>
                                <div className="thingsBlock">
                                  <span>Health & Safety</span> */}
                                  {/* <p> */}
                                  {/* <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.health_safety
                                        ? this.state.health_safety
                                        : ""
                                    }
                                    maxLine="4"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className=" ltr_pro_desp"
                                  /> */}
                                  
                                  {/* </p> */}
                                  {/* {this.state.health_safety ? (
                                    <button
                                      className="btn btn-link ShowmoreLink "
                                      onClick={() =>
                                        this.openHealthAndSafetyModal(true)
                                      }
                                    >
                                      Show more{" "}
                                    </button>
                                  ) : (
                                    ""
                                  )} */}
                                {/* </div>
                              </>
                            ) : (
                              ""
                            )} */}
                            {/* {this.state.headerToken && this.state.guest_manual !== null ? (
                              <>
                                <div className="thingsBlock">
                                  <span>Guest manual</span>
                                  {/* <p> */}
                            {/* <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.guest_manual
                                        ? this.state.guest_manual
                                        : ""
                                    }
                                    maxLine="6"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className="span_text"
                                  />
                                  {/* </p> */}
                            {/* {this.state.guest_manual ? (
                                    <button
                                      className="btn btn-link ShowmoreLink"
                                      onClick={() =>
                                        this.openGuestmanualModal(true)
                                      }
                                    >
                                      Show more{" "}
                                    </button>
                                  ) : (
                                    ""
                                  )} */}
                            {/* </div> */}
                            {/* </> */}
                            {/* ) : (
                              ""
                            )}} */}

                            {this.state.refundable_policy !== null && this.state.refundable_policy !== "" ? (
                              <>
                                <div className="thingsBlock">
                                  <span>Cancellation policy</span>
                                  {/* <p>{this.state.UnitListing.property ? this.state.UnitListing.property.refundable_policy:""}</p> */}
                                  {/* <p> */}
                                  <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.refundable_policy ? this.state.refundable_policy : ""
                                    }
                                    maxLine="4"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className=" ltr_pro_desp"
                                  />

                                  {/* </p> */}
                                  <button className="btn btn-link ShowmoreLink "
                                    onClick={() =>
                                      this.openRefundablepolicyModal(true)
                                    }>
                                    Show more{" "}
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <section className="strReviews border-bottom pd-b-45">
                          <div className="col-m-12 pd-l-0 pd-r-0 mobviewreview">
                            <div className="mg-b-30">
                              <span className="reviewCount">
                                <img
                                  src={
                                    global.theme === "kini"
                                      ? global.OnKeyPressEvent.getS3ImageUrl('r-star.png')
                                      : global.OnKeyPressEvent.getS3ImageUrl('r-star.png')
                                  }
                                  className="mg-r-15"
                                />{" "}
                                <b className="mg-r-3"> 5.0</b> (100 reviews)
                              </span>
                            </div>
                            <div className="reviewCountprogress">
                              <ul>
                                <li>
                                  <h5>Cleanliness</h5>
                                  <div className="progres">
                                    <span className="filled"></span>
                                    <h6>5.0</h6>
                                  </div>
                                </li>
                                <li>
                                  <h5>Communication</h5>
                                  <div className="progres">
                                    <span className="filled"></span>
                                    <h6>5.0</h6>
                                  </div>
                                </li>
                                <li>
                                  <h5>Check-in</h5>
                                  <div className="progres">
                                    <span className="filled"></span>
                                    <h6>5.0</h6>
                                  </div>
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <h5>Accuracy</h5>
                                  <div className="progres">
                                    <span className="filled"></span>
                                    <h6>5.0</h6>
                                  </div>
                                </li>
                                <li>
                                  <h5>Location</h5>
                                  <div className="progres">
                                    <span className="filled"></span>
                                    <h6>5.0</h6>
                                  </div>
                                </li>
                                <li>
                                  <h5>Value</h5>
                                  <div className="progres">
                                    <span className="filled"></span>
                                    <h6>5.0</h6>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="reviewUsers d-flex flex-row flex-wrap justify-conten-start">
                              <div className="row">
                                <div className="col-lg-6 pd-l-15 pd-r-15 pd-t-20 pd-b-20">
                                  <div className="profile align-items-center justify-conten-center">
                                    <figure>
                                      <img src={reviewpic} />
                                    </figure>
                                    <div className="d-flex flex-column">
                                      <h5>Dani</h5>
                                      <h6>January 2022</h6>
                                    </div>
                                  </div>
                                  <div className="decription">
                                    <p>
                                      If you have to wait to get this sweet
                                      little treehouse, it's worth it! We just
                                      spent three glorious days at this peaceful
                                      and beautiful get-away! If a treehouse can
                                      be elegant, it was that...
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 pd-l-15 pd-r-15 pd-t-20 pd-b-20">
                                  <div className="profile align-items-center justify-conten-center">
                                    <figure>
                                      <img src={reviewpic2} />
                                    </figure>
                                    <div className="d-flex flex-column">
                                      <h5>Sophia</h5>
                                      <h6>February 2022</h6>
                                    </div>
                                  </div>
                                  <div className="decription">
                                    <p>
                                      Believe the hype. This was a uniquely
                                      wonderful experience. The lighting-indoor
                                      and out, the well-appointed everything,
                                      the amazing smell of the lines, and the
                                      multiple thoughtful...
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 pd-l-15 pd-r-15 pd-t-20 pd-b-20">
                                  <div className="profile align-items-center justify-conten-center">
                                    <figure>
                                      <img src={reviewpic3} />
                                    </figure>
                                    <div className="d-flex flex-column">
                                      <h5>Olivia</h5>
                                      <h6>March 2022</h6>
                                    </div>
                                  </div>
                                  <div className="decription">
                                    <p>
                                      My husband and i spent our honeymoon here,
                                      and it was amazing! We will definitely be
                                      recommending this and hopefully comin...
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6 pd-l-15 pd-r-15 pd-t-20 pd-b-20">
                                  <div className="profile align-items-center justify-conten-center">
                                    <figure>
                                      <img src={reviewpic4} />
                                    </figure>
                                    <div className="d-flex flex-column">
                                      <h5>Isabella</h5>
                                      <h6>April 2022</h6>
                                    </div>
                                  </div>
                                  <div className="decription">
                                    <p>
                                      This is a beautiful tree house. it is very
                                      romantic and we had a wonderful time. It
                                      was definitely worth it for us. I
                                      recommend bringing food that you can
                                      easily heat up. The treehouse is o...
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section> */}
                        <div className="mapSection col-lg-12 border-bottom pd-t-35 pd-b-45">
                          <h5 className="m-0 p-0 text-left-cust-str">Where you’ll be</h5>
                          <span className="map_location">
                            {this.state.city_name
                              ? this.state.city_name + ", "
                              : ""}
                            {this.state.state_name
                              ? this.state.state_name + ","
                              : ""}{" "}
                            {this.state.country_name
                              ? this.state.country_name
                              : ""}
                          </span>
                          <div className="w-100 ">
                            <div className="w-100 mapblock">
                              <GoogleMap lat={this.state.latitude} lng={this.state.longitude} propertyName={this.state.property_name} />
                              {/* <img src={locationMap} /> */}
                            </div>
                          </div>


                          {/* <HTMLEllipsis
                            unsafeHTML={
                              this.state.getting_around
                                ? this.state.getting_around
                                : ""
                            }
                            maxLine="4"
                            ellipsis=""
                            trimright="true"
                            basedOn="letters"
                            className="span_text"
                          />
                          {this.state.getting_around ? (
                            <button
                              onClick={() => this.openGettingAroundModal(true)}
                              className="btn btn-link ShowmoreLink "
                            >
                              Show more
                            </button>
                          ) : (
                            ""
                          )} */}
                        </div>
                        {this.state.getting_around ? (
                        <div className="hostSection col-lg-12 border-bottom pd-t-35 pd-b-45">
                          <div className="host">
                            <div className="d-flex flex-wrapjustify-content-between align-items-center guestBlock">
                              <div className="hostContent p-0">
                              <h5 className="text-left-cust-str">Getting around</h5>
                               <HTMLEllipsis
                            unsafeHTML={
                              this.state.getting_around
                                ? this.state.getting_around
                                : ""
                            }
                            maxLine="4"
                            ellipsis=""
                            trimright="true"
                            basedOn="letters"
                            className=" ltr_pro_desp"
                          />
                          {this.state.getting_around ? (
                            <button
                              onClick={() => this.openGettingAroundModal(true)}
                              className="btn btn-link Showmore"
                            >
                              Show more
                            </button>
                          ) : (
                            ""
                          )}
                              </div>
                            </div>
                          </div>
                          {/* <button className="btn btn-link Showmore">
                            Show more
                          </button> */}
                        </div>):""}

                        <div className="hostSection col-lg-12  pd-t-45 pd-b-45">
                          <div className="host">
                            <div className="d-flex flex-wrapjustify-content-between align-items-center guestBlock">
                              {this.state.UnitListing.host_photo_thumb_url && (
                                <figure>
                                  <img
                                    src={
                                      this.state.UnitListing
                                        .host_photo_thumb_url
                                        ? this.state.UnitListing
                                          .host_photo_thumb_url
                                        : global.OnKeyPressEvent.getS3ImageUrl('dummy-profile.jpeg')
                                    }
                                    alt="Host image" title="Host image"
                                  />
                                </figure>
                              )}
                              <div className="hostContent pl-0">
                                <span>
                                  {`Hosted by ${this.state.UnitListing.host_name ? this.state.UnitListing.host_name : ""}`}
                                </span>
                                {/* <b>
                                  Joined in{" "}
                                  {Moment(
                                    this.state.UnitListing.created_at
                                  ).format("MMM Do, YY")}
                                </b> */}
                                {/* <p>
                                  {this.state.UnitListing.host_bio
                                    ? this.state.UnitListing.host_bio
                                    : ""}
                                </p> */}
                                {/* <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.UnitListing.host_bio
                                      ? this.state.UnitListing.host_bio
                                      : "",
                                  }}
                                ></p> */}
                              </div>
                            </div>
                          </div>
                          <div className="cohost mg-t-0">
                            <h5 className="d-none">Co-hosts</h5>
                            <div className="d-flex flex-wrap align-items-center guestBlock d-none">
                              {this.state.UnitListing.host_photo_thumb_url && (
                                <figure>
                                  <img
                                    src={
                                      this.state.UnitListing
                                        .host_photo_thumb_url
                                        ? this.state.UnitListing
                                          .host_photo_thumb_url
                                        : global.OnKeyPressEvent.getS3ImageUrl('dummy-profile.jpeg')
                                    }
                                    alt="Host image" title="Host image"
                                  />
                                </figure>
                              )}
                              <p>
                                {this.state.property_name
                                  ? this.state.property_name
                                  : this.state.UnitListing.property
                                    ? this.state.UnitListing.property
                                      .property_name
                                    : "Kini Beach Haus/Las Olas(DEV-DEMO)"}
                              </p>
                            </div>
                          </div>
                          {/* <button className="btn btn-link Showmore">
                            Contact host
                          </button> */}
                        </div>

                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <Skeleton
                  sx={{ height: 190 }}
                  animation="wave"
                  variant="rectangular"
                />
              )}

              <section>
                {/* <Modal
            show={this.state.availablity_modal_box}
            centered
            className="modal-md"
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span
                className="welcomeClose float-end"
                onClick={() => this.closeAvalModal()}
              >
                {global.closee}
              </span>
              <section className="middle">
                <div className="container-fluid p-0">
                  <section className="ltrunitListing mg-b-10">

                  </section>
                </div>
              </section>
            </Modal.Body>
          </Modal> */}
                <Modal
                  show={this.state.openImagepopup}
                  centered
                  scrollable
                  className="unitmodal model-scroll"
                  onHide={() => this.closeImageModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14" >
                    <span
                      className="welcomeClose float-end"
                      onClick={() => this.closeImageModal()}
                    >
                      <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Unit image" title="Unit image" />
                    </span>
                    <div>
                      <figure className="mg-auto m-auto text-center">
                        <img
                          src={this.state.unitImagemodal}
                          alt={this.state.unitImagemodal}
                          title={this.state.unitImagemodal}
                          className="w-100"
                        />
                      </figure>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.showSocialLinks}
                  centered
                  className="ShareModal"
                  onHide={() => this.showSocialLinks(false)}
                >
                  <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                    {/* <span
                      className="mobilewelcomeClose float-end"
                      onClick={() => this.showSocialLinks(false)}
                    >
                      <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')}} />
                    </span> */}
                    <h5 className="social-link-main-heding-div">
                      <div className="title">
                        {" "}
                        Share this place with friends and family
                      </div>
                    </h5>
                    <span
                      className="welcomeClose float-end mg-t-15"
                      onClick={() => this.showSocialLinks(false)}
                    >
                      {global.closee}
                    </span>
                  </div>
                  <Modal.Body className="width_402  p-4 border_radius_14">
                    <div>
                      <figure className="mg-auto m-auto text-center col-md-12 col-ld-12 col-sm-12">
                        <SocialShareLink
                          unitName={this.state.UnitListing.listing_name}
                          unitImage={this.state.UnitListing.host_photo_thumb_url}
                          urlName={this.state.urlname}
                          title={this.state.title}
                          favicon={this.state.favicon}
                          keywords={this.state.keywords}
                          description={this.state.description}
                          noIndex={"true"}
                          url={this.state.url}
                          image={this.state.image}
                          fb_app_id={this.state.fb_app_id}
                          author={"OHAI"}
                        />
                      </figure>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openAllAmenitiesModal}
                  centered
                  scrollable={true}
                  className="unitmodal large_model amenities_modal"
                  onHide={() => this.openAllAmenitiesModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14 pt-0">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile model_top_fix">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openAllAmenitiesModal()}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Amenities" title="Amenities" />
                      </span>
                      <div className="title"> What this place offers</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openAllAmenitiesModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="place-offers pd-l-20 pd-r-20 mg-t-10">
                      {this.state.propertyAmenityTotal > 0
                        ? Object.keys(this.state.propertyAmenityList).map(
                          (opt, i) => {
                            return (
                              <div className="amt_box" >

                                <ul>
                                  <h4 className="text-left amenities_pop_head">{opt}</h4>
                                  {this.state.propertyAmenityList[opt].map((k, v) => {
                                    // {console.log(k)}
                                    return (
                                      // <li key={v}>{k.name}</li>
                                      <li key={v}><img src={k.icon}></img>{k.name}</li>
                                      // <li key={i}>{opt.amenity_name}</li>
                                    );

                                  }
                                  )}
                                </ul>
                              </div>
                            )

                          }
                        )
                        : ""}
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openHealthAndSafetyModal}
                  centered
                  dialogClassName="modal-lg"
                  className="ShareModal estimatePotential"
                  onHide={() => this.openHealthAndSafetyModal()}
                >
                   <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
                      {/* <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openHealthAndSafetyModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Health & safety" title="Health & safety" />
                      </span> */}
                      <h5 className="social-link-main-heding-div">
                        <div className="title">Health & safety</div>
                      </h5>
                      <span
                        className="welcomeClose float-end mg-t-15"
                        onClick={() => this.openHealthAndSafetyModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                  <Modal.Body className="width_402 p-4 border_radius_14">
                   
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.health_safety
                            ? this.state.health_safety
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openPropertyrulesModal}
                  centered
                  dialogClassName="modal-lg"
                  className="ShareModal estimatePotential"
                  onHide={() => this.openPropertyrulesModal()}
                >
                  <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
                      {/* <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openPropertyrulesModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Health & safety" title="Health & safety" />
                      </span> */}
                      <h5 className="social-link-main-heding-div">
                        <div className="title">House rules</div>
                      </h5>
                      <span
                        className="welcomeClose float-end mg-t-15"
                        onClick={() => this.openPropertyrulesModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.property_rules
                                        ? this.state.property_rules
                                        : ""
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openDescriptionModal}
                  centered
                  dialogClassName="modal-lg"
                  className="ShareModal estimatePotential"
                  onHide={() => this.openDescriptionModal()}
                >
                   <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
                      {/* <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openDescriptionModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Health & safety" title="Health & safety" />
                      </span> */}
                      <h5 className="social-link-main-heding-div">
                        <div className="title">Description</div>
                      </h5>
                      <span
                        className="welcomeClose float-end mg-t-15"
                        onClick={() => this.openDescriptionModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                  <Modal.Body className="width_402 p-4 border_radius_14">
                   
                    <div>
                 
                      <p
                          style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html:  this.state.UnitListing ? 
                                      this.state.UnitListing.listing_description:"",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openRefundablepolicyModal}
                  centered
                  dialogClassName="modal-lg"
                  className="ShareModal estimatePotential"
                  onHide={() => this.openRefundablepolicyModal()}
                >
                   <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
                      <h5 className="social-link-main-heding-div">
                        <div className="title">Cancellation policy</div>
                      </h5>
                      <span
                        className="welcomeClose float-end mg-t-15"
                        onClick={() => this.openRefundablepolicyModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                  <Modal.Body className="width_402 p-4 border_radius_14">
                   
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.refundable_policy
                            ? this.state.refundable_policy
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openGuestmanualModal}
                  centered
                  dialogClassName="modal-lg"
                  className="ShareModal estimatePotential"
                  onHide={() => this.openGuestmanualModal()}
                >
                  <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
                  <h5 className="social-link-main-heding-div">
                    <div className="title">Guest manual</div>
                  </h5>
                    <span
                      className="welcomeClose float-end mg-t-15"
                      onClick={() => this.openGuestmanualModal(false)}
                    >
                      {global.closee}
                    </span>
                  </div>
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.guest_manual
                            ? this.state.guest_manual
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openGettingAroundModal}
                  centered
                  dialogClassName="modal-lg"
                  className="ShareModal estimatePotential"
                  onHide={() => this.openGettingAroundModal()}
                >
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
                    <h5 className="social-link-main-heding-div">
                      <div className="title">Getting around</div>
                      </h5>
                      <span
                        className="welcomeClose float-end mg-t-15"
                        onClick={() => this.openGettingAroundModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                  <Modal.Body className="width_402 p-4 border_radius_14">
                  
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.getting_around
                            ? this.state.getting_around
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={this.state.openGuestSize}
                  centered
                  className="unitmodal"
                  onHide={() => this.openGuestSize()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openGuestSize(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Where you’ll be" title="Where you’ll be" />
                      </span>
                      <div className="title">Guests</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openGuestSize(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="pd-l-20 pd-r-20 mg-t-10">
                      <div className="guest-type-box">
                        <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                          <ul>
                            <li>
                              <h4>Adults</h4>
                              <h5>Age 13+</h5>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button
                                onClick={
                                  this.decrementAdult
                                }
                                disabled={this.state.adultCount === 1 ? true : false}
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                              </button>
                            </span>
                            <span>
                              {this.state.adultCount}
                            </span>
                            <span>
                              <button
                                onClick={this.incrementAdult}
                                disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                              </button>
                            </span>

                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                          <ul>
                            <li>
                              <h4>{this.state.childCount > 1 ? "Children":"Child"}</h4>
                              <h5>Ages 2–12</h5>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button
                                onClick={this.decrementchild}
                                disabled={this.state.childCount === 0 ? true : false}
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                              </button>
                            </span>
                            <span>
                              {this.state.childCount}
                            </span>
                            <span>
                              <button
                                onClick={this.incrementchild}
                                disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                              </button>
                            </span>

                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                          <ul>
                            <li>
                              <h4>Infants</h4>
                              <h5>Under 2</h5>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button
                                onClick={this.decrementinfant}
                                disabled={this.state.infantCount === 0 ? true : false}
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                              </button>
                            </span>
                            <span>
                              {this.state.infantCount}
                            </span>
                            <span>
                              <button
                                onClick={this.incrementinfant}
                                disabled={this.state.infantCount === 5 ? true : false}
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                              </button>
                            </span>

                          </div>
                        </div>
                        <div className="d-flex  justify-content-end mg-t-20 mg-t-20">
                          <button className="reset" onClick={() => this.resetCount()}> Reset </button>
                          <button className="done" onClick={() =>
                            this.openGuestSize(false)
                          }> Save </button>
                        </div>

                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.pricebreakdownModal}
                  centered
                  className="unitmodal guestviewmobile"
                  onHide={() => this.pricebreakdownModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-0 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.pricebreakdownModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Where you’ll be" title="Where you’ll be" />
                      </span>
                      <div className="title">Your trip</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.pricebreakdownModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="mainstrdetailContainer p-0">
                      <div className="mainstrDetail">

                        <section className="strDetail str_property_info">
                          <div className="col-m-12 pd-l-0 pd-r-0">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="guest-availabity guest-availabity-deskview d-block mg-l-0 ">

                                  {/* New Input Fields */}

                                  <div className="guestfilter guestfilterNew mg-b-30">

                                    <DatePickerFile
                                      datePickerOpen={this.state.datePickerOpen}
                                      changeDateHandlerNew={this.changeDateHandlerNew.bind(this)}
                                      bookedDate={this.state.bookedDate}
                                      availableDate={this.state.availableDate}
                                      touchUi={true}
                                      startDate={this.state.startDate ? Moment(this.state.startDate).local() : null}
                                      endDate={this.state.endDate ? Moment(this.state.endDate).local() : null}
                                      buttons={[{
                                        // text: "X",
                                        icon: "material-close",
                                        handler: 'cancel'
                                      }]}
                                    />


                                    <div className="dropmenuInputbox">
                                      <Dropdown autoClose={false}>
                                        <Dropdown.Toggle id="dropdown-basic-str-detail-page2">
                                          <TextField
                                            id="outlined-read-only-input"
                                            label="Details"
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                            className="inputBox"
                                            placeholder="Add guests"
                                            value={this.state.guestValue}
                                            sx={{ width: 220 }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>Adults</h4>
                                                  <h5>Age 13+</h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={
                                                      this.decrementAdult
                                                    }
                                                    disabled={this.state.adultCount === 1 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.adultCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementAdult}
                                                    disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>

                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>{this.state.childCount > 1 ? "Children":"Child"}</h4>
                                                  <h5>Ages 2–12</h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={this.decrementchild}
                                                    disabled={this.state.childCount === 0 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.childCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementchild}
                                                    disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>

                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>Infants</h4>
                                                  <h5>Under 2</h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={this.decrementinfant}
                                                    disabled={this.state.infantCount === 0 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.infantCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementinfant}
                                                    disabled={this.state.infantCount === 5 ? true : false}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                                                  </button>
                                                </span>

                                              </div>
                                            </div>
                                            {/* <div className="d-flex justify-content-between mg-t-20 mg-t-20">
                                              <ul>
                                                <li>
                                                  <h4>Pets</h4>
                                                  <h5><Link to={"/"}>Bringing a service animal?</Link></h5>
                                                </li>
                                              </ul>
                                              <div className="d-flex align-items-center countBtn">
                                                <span>
                                                  <button
                                                    onClick={this.decrementpets}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} />
                                                  </button>
                                                </span>
                                                <span>
                                                  {this.state.petsCount}
                                                </span>
                                                <span>
                                                  <button
                                                    onClick={this.incrementpets}
                                                  >
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} />
                                                  </button>
                                                </span>

                                              </div>
                                            </div> */}
                                            {/*  <p>
                                                This place has a maximum of 6
                                                guests, not including infants. Pets
                                                aren't allowed.
                                              </p> */}
                                            <div className="d-flex justify-content-end">
                                              <button
                                                className="done"
                                                id="cnt-close"
                                                onClick={(e) =>
                                                  this.Setclose2(e)
                                                }
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    {this.state.message && (
                                      <>
                                        <p className="text-danger text-center">
                                          {" "}
                                          {/* {this.state.message} */}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  {this.state.redirectToNextPage && this.state.startDate && this.state.endDate ? (
                                    <>
                                      <div className="pricebreakdownsection">
                                        <span>You won't be charged yet</span>
                                        <ul>
                                          <Toast className="d-none" show={this.state.showA} onClose={this.toggleShowA}>
                                            <Toast.Header>
                                            </Toast.Header>
                                            <Toast.Body>
                                              <h5 className="tost_heading">Price breakdown</h5>
                                              <p>03/06/2023 <span>$112</span></p>
                                              <div className="final_price_tost"></div>
                                              <b><p>Total base price <span>$112</span></p></b>

                                            </Toast.Body>

                                          </Toast>


                                          <Toast show={this.state.showB} onClose={this.toggleShowB} className="price_under_fee d-none">
                                            <Toast.Header>
                                              <p>One-time fee charged by host to cover the cost of cleaning their space.</p>
                                            </Toast.Header>
                                            <Toast.Body>

                                            </Toast.Body>
                                          </Toast>
                                          
                                          <li><span className="price_under" onClick={this.toggleShowA}>{this.state.date_wise_nightly_rate ? this.state.date_wise_nightly_rate : ""} x {this.state.pricing ? this.state.pricing.nights > 1 ? this.state.pricing.nights + " nights" : this.state.pricing.nights + " night" : ""}</span><span>${this.state.nightly_price_rate ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.nightly_price_rate).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}</span></li>
                                          {this.state.pricing && this.state.pricing.cleaning_fee ? (
                                            <li><span className="price_under" onClick={this.toggleShowB}>Cleaning fee</span> <span>${this.state.pricing ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}</span> </li>

                                          ) : ""}
                                          {this.state.pricing && this.state.pricing.convenienceFee ? (
                                            <li><span >Service fee</span> <span>${this.state.pricing ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.convenienceFee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}</span> </li>
                                          ) : ""}
                                          <li><span>Total before taxes</span><span>${this.state.total_without_taxes?global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.total_without_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}</span> </li>
                                        </ul>
                                      </div>
                                    </>
                                  ) : ""}
                                  {this.state.redirectToNextPage  && this.state.startDate && this.state.endDate ? (
                                    <>

                                      {
                                        this.state.headerToken ?
                                          <button className="guest-avaialbility-btn m-auto" disabled={this.state.loader ? true : false} onClick={() => this.placeReservation()}>
                                            Reserve {this.state.loader ?
                                              <>
                                                {/* <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                                <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                                <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  role="status"
                                                  aria-hidden="true"
                                                /> */}

                                                <SpinnerLoader />
                                                
                                              </>
                                              : ""
                                            }

                                          </button>
                                          :
                                          <a
                                            href={
                                              global.themeUrl +
                                              this.state.property_slug + "/book-tour"
                                              + this.state.queryString
                                            }
                                            className="text-decoration-none"
                                          >
                                            <button className="guest-avaialbility-btn m-auto">
                                              Reserve
                                            </button>
                                          </a>
                                      }
                                    </>
                                  ) : (
                                    <button
                                      className="guest-avaialbility-btn m-auto"
                                      onClick={() =>
                                        this.validateDateChange()
                                      }
                                    >
                                      Check availability
                                    </button>
                                  )}

                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>

                    </div>
                  </Modal.Body>
                </Modal>



              </section>
              {/* Footer */}
              <CommonFooter propertySlug={this.state.property_slug} />
            </main>
          </React.Fragment>
        </React.Suspense>
      );
    }
  }
}
export default StrDetails;
