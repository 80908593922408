import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import BrandIndex from "./component/template/pages/BrandIndex";
import BrandIndexStay from "./component/template/pages/BrandIndexStay";
import BrandIndexLive from "./component/template/pages/BrandIndexLive";
import PropertyIndex from "./component/template/pages/PropertyIndex";
import PropertyIndexStay from "./component/template/pages/PropertyIndexStay";
import PropertyIndexLive from "./component/template/pages/PropertyIndexLive";
import CorporateIndex from "./component/template/pages/CorporateIndex";
import Profile from "./component/template/pages/Profile";
import ThankYou from "./component/template/pages/ThankYou";
import ThankYouResident from "./component/template/pages/ThankYouResident";
import GuestMessages from "./component/template/pages/GuestMessages";
import Partnership from "./component/template/pages/Partnership";
import Disclaimers from "./component/template/pages/Disclaimers";
import Privacy from "./component/template/pages/Privacy";
import Terms from "./component/template/pages/Terms";
import Contact from "./component/template/pages/Contact";
import NewResident from "./component/template/pages/NewResident";
import Hosting from "./component/template/pages/Hosting";
import Leasing from "./component/template/pages/Leasing";
import WizardForm from "./component/template/pages/WizardForm";
import ReservationDetails from "./component/template/pages/ReservationDetails";
import ResetPassword from "./component/template/common/ResetPassword";
import InvaildUrl from "./component/template/common/InvaildUrl";
import SetPassword from "./component/template/common/SetPassword";
import SetPasswordResident from "./component/template/common/SetPasswordResident";
import FloorplanListing from "./component/template/pages/FloorplanListing";
import LtrDetails from "./component/template/pages/LtrDetails";
import StrListing from "./component/template/pages/StrListing";
import Logout from "./component/template/common/Logout";
import ReservationList from "./component/template/pages/ReservationList";
import StrDetails from "./component/template/pages/StrDetails";
import Aminity from "./component/template/pages/Aminity";
import Neighborhoods from "./component/template/pages/Neighborhoods";
import HowItWorks from "./component/template/pages/HowItWorks";
import BookTourForm from "./component/template/pages/BookTourForm";
import BookTourBasicInfo from "./component/template/pages/BookTourBasicInfo";
import BookTourPayment from "./component/template/pages/BookTourPayment";
import BookingConfirmation from "./component/template/pages/BookingConfirmation";
import VirtualTourConfirmation from "./component/template/pages/VirtualTourConfirmation";
import CorporateIndexLive from "./component/template/pages/CorporateIndexLive";
import CorporateIndexStay from "./component/template/pages/CorporateIndexStay";
import PageNotFound from "./component/template/pages/PageNotFound";

import PropertyIndexNew from "./component/template/pages/PropertyIndexNew";
import MultifamilyPartnershipsSecond from "./component/template/pages/MultifamilyPartnershipsSecond";
import MultifamilyPartnershipsFirst from "./component/template/pages/MultifamilyPartnershipsFirst";
import MultifamilyPartnershipsThird from "./component/template/pages/MultifamilyPartnershipsThird";
import CheckIn from "./component/template/pages/CheckIn";
import Parking from "./component/template/pages/Parking";
import Getreceipt from "./component/template/pages/Getreceipt";
import HelpPage from "./component/template/pages/Help";
import SupportPage from "./component/template/pages/Support";
import SupportConfirm from "./component/template/pages/SupportConfirm";
import SupportFaqs from "./component/template/pages/SupportFaqs";
import HelpData from "./component/template/pages/blog/HelpData";
import HelpCategory from "./component/template/pages/blog/HelpCategory";
import HelpBlog from "./component/template/pages/blog/HelpBlog";
import FaqMobile from "./component/template/pages/FaqMobile";
import Unsubscribe from "./component/template/pages/Unsubscribe";
import ParkingMobile from "./component/template/pages/ParkingMobile";
import SupportConfirmationCode from "./component/template/pages/SupportConfirmationCode";
import HomesharingInquiry from "./component/template/pages/HomesharingInquiry";
import ResourceDetail from "./component/misc/ResourceDetail";
import InsightsDetail from "./component/misc/InsightsDetail";
export class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        {/* kini and oh dynamic routes */}
        <Redirect from="/en/*" to="/" />
        <Route
          path="/hosting"
          component={() => {
            window.location.href = "https://www.residentonboarding.com/";
            return null;
          }}
        />
        <Route
          path="/:propertySlug/hosting/"
          component={() => {
            window.location.href = "https://www.residentonboarding.com/";
            return null;
          }}
        />
        {/* <Redirect from="/hosting/" to="www.residentonboarding.com"/>
        <Redirect from="/:propertySlug/hosting/" to="www.residentonboarding.com"/> */}
        <Route path="/page-not-found" component={PageNotFound} />
        <Route exact path="/">
          {process.env.REACT_APP_BRAND_URL.includes(global.host) ? (
            <BrandIndex />
          ) : process.env.REACT_APP_PROPERTY_URL.includes(global.host) ? (
            <PropertyIndex />
          ) : (
            <CorporateIndex />
          )}
        </Route>
        {/* <Route exact path="*">
  {process.env.REACT_APP_BRAND_URL.includes(global.host) ? <BrandIndex /> : (process.env.REACT_APP_PROPERTY_URL.includes(global.host) ? <PropertyIndex /> : <CorporateIndex />)}
</Route> */}
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/live-here">
          {process.env.REACT_APP_BRAND_URL.includes(global.host) ? (
            <BrandIndexLive title={"Live at OHAI"} page={"live-here"} />
          ) : process.env.REACT_APP_PROPERTY_URL.includes(global.host) ? (
            <PropertyIndexLive title={"Live at OHAI"} page={"live-here"} />
          ) : (
            <CorporateIndexLive title={"Live at OHAI"} page={"live-here"} />
          )}
          {/* <CorporateIndexlive title={"Live at OHAI"} page={"live-here"} /> */}
        </Route>
        <Route exact path="/stay-here">
          {process.env.REACT_APP_BRAND_URL.includes(global.host) ? (
            <BrandIndexStay title={"Stay at OHAI"} page={"stay-here"} />
          ) : process.env.REACT_APP_PROPERTY_URL.includes(global.host) ? (
            <PropertyIndexStay title={"Stay at OHAI"} page={"stay-here"} />
          ) : (
            <CorporateIndexStay title={"Stay at OHAI"} page={"stay-here"} />
          )}
        </Route>
        <Route
          exact
          path="/:propertySlug/live-here"
          component={PropertyIndexLive}
        />
        <Route
          exact
          path="/:propertySlug/stay-here"
          component={PropertyIndexStay}
        />

        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/thank-you-resident" component={ThankYouResident} />
        <Route exact path="/guest-messages" component={GuestMessages} />
        <Route exact path="/partnerships" component={Partnership} />
        <Route exact path="/disclaimers" component={Disclaimers} />

        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms-condition" component={Terms} />
        <Route exact path="/contact" component={Contact} />
        <Route
          exact
          path="/resourcecenter/multifamily/how-to-minimize-risk-in-your-airbnb-friendly-apartment-building"
          component={MultifamilyPartnershipsFirst}
        />
        <Route
          exact
          path="/resourcecenter/multifamily/how-to-stabilize-rent-in-a-down-market"
          component={MultifamilyPartnershipsSecond}
        />
        <Route
          exact
          path="/resourcecenter/multifamily/Is-my-apartment-building-a-good-fit-for-airbnb-and-vrbo-activity"
          component={MultifamilyPartnershipsThird}
        />
        <Route
          exact
          path="/homesharing-inquiry"
          component={HomesharingInquiry}
        />
        <Route exact path="/checkin" component={CheckIn} />
        <Route exact path="/checkin/:confirmationCode" component={CheckIn} />
        <Route exact path="/parking/:confirmationCode" component={Parking} />
        <Route exact path="/newresident" component={NewResident} />
        {/* <Route exact path="/:propertySlug/hosting" component={Hosting} />
        <Route exact path="/hosting" component={Hosting} /> */}
        <Route exact path="/leasing" component={Leasing} />
        <Route exact path="/:propertySlug/leasing" component={Leasing} />
        {/* <Route exact path="/wizard-form" component={WizardForm} /> */}
        <Route
          exact
          path="/reservation-details"
          component={ReservationDetails}
        />
        <Route
          exact
          path="/reset-password/:resetPasswordToken"
          component={ResetPassword}
        />
        <Route exact path="/reset-token" component={InvaildUrl} />
        <Route
          exact
          path="/set-password/:setPasswordToken"
          component={SetPassword}
        />
        <Route
          exact
          path="/set-password-resident/:setPasswordToken"
          component={SetPasswordResident}
        />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/reservation-list" component={ReservationList} />

        <Route exact path="/floorplan-listing" component={FloorplanListing} />
        <Route
          exact
          path="/:propertySlug/floorplan-listing"
          component={FloorplanListing}
        />
        <Route exact path="/str-listing" component={StrListing} />
        <Route exact path="/:propertySlug/str-listing" component={StrListing} />
        <Route
          exact
          path="/:propertySlug/rooms/:unit_slug"
          component={StrDetails}
        />
        <Route exact path="/rooms/:unit_slug" component={StrDetails} />
        <Route
          exact
          path="/:propertySlug/floorplan/:unitSlug"
          component={LtrDetails}
        />
        <Route exact path="/floorplan/:unitSlug" component={LtrDetails} />
        <Route exact path="/:propertySlug/amenity" component={Aminity} />
        <Route
          exact
          path="/:propertySlug/neighborhoods"
          component={Neighborhoods}
        />
        <Route exact path="/:propertySlug/howitworks" component={HowItWorks} />
        <Route exact path="/:propertySlug/book-tour" component={BookTourForm} />
        <Route
          exact
          path="/:propertySlug/book-tour-basic-info"
          component={BookTourBasicInfo}
        />
        <Route
          exact
          path="/:propertySlug/book-tour-payment"
          component={BookTourPayment}
        />
        <Route
          exact
          path="/:propertySlug/booking-confirmation"
          component={BookingConfirmation}
        />
        <Route
          exact
          path="/:propertySlug/virtual-tour-confirmation"
          component={VirtualTourConfirmation}
        />
        <Route
          exact
          path="/:propertySlug/disclaimers"
          component={Disclaimers}
        />
        <Route exact path="/:propertySlug/privacy-policy" component={Privacy} />
        <Route
          exact
          path="/:propertySlug/reservation-list"
          component={ReservationList}
        />
        <Route exact path="/:propertySlug/profile" component={Profile} />
        <Route
          exact
          path="/:propertySlug/guest-messages"
          component={GuestMessages}
        />
        <Route
          exact
          path="/:propertySlug/partnerships"
          component={Partnership}
        />
        <Route exact path="/:propertySlug/thank-you" component={ThankYou} />
        <Route path="/property-new" component={PropertyIndexNew} />
        <Route exact path="/get-receipt" component={Getreceipt} />
        <Route
          exact
          path="/:confirmationCode/get-receipt"
          component={Getreceipt}
        />
        <Route exact path="/help/:slug" component={HelpPage} />
        <Route exact path="/support" component={SupportConfirmationCode} />
        <Route
          exact
          path="/access-support"
          component={SupportConfirmationCode}
        />
        <Route exact path="/support-faq" component={SupportFaqs} />
        <Route exact path="/support-form" component={SupportPage} />
        <Route exact path="/support-confirmation" component={SupportConfirm} />
        {/* <Route exact path="/support/:slug" component={SupportPage} /> */}
        <Route exact path="/access-support/:slug" component={SupportPage} />
        <Route exact path="/supportConfirm/:slug" component={SupportConfirm} />
        <Route exact path="/blog" component={HelpData} />
        <Route exact path="/blog-category/:category" component={HelpCategory} />
        <Route exact path="/blog-data/:category/:blog" component={HelpBlog} />
        <Route exact path="/reservation-faq/:slug" component={FaqMobile} />
        <Route exact path="/unsubscribe/feedback" component={Unsubscribe} />
        <Route exact path="/unsubscribes/:slug" component={Unsubscribe} />
        <Route
          exact
          path="/parking-pay-mobile/:confirmationcode"
          component={ParkingMobile}
        />
        <Route exact path="/reservation" component={CheckIn} />
        <Route
          exact
          path="/reservation/:confirmationCode"
          component={CheckIn}
        />
        <Route exact path="/resource-detail/:slug" component={ResourceDetail} />
        <Route exact path="/insights-detail/:slug" component={InsightsDetail} />
        <Route path="/:propertySlug" component={PropertyIndex} />
      
      </Switch>
    );
  }
}

export default Routes;
