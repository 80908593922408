////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AdAgencyService.js                        //
//  Application: Third party                           //
//  Option: Used for manage add agency                 //
//  Developer: NP           						               //
//  Date: 2021-01-11                                   //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class UnitlistingApi {

  getUnitlisting(data) {
    // let endPoint = "get-availability-of-units/" + slug;
    let endPoint = "get-availability-of-units";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new UnitlistingApi();
