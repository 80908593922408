//     							                          //
//  Program: HelpCategory.jsx                             //
//  Application: CMS                                      //
//  Option:help blog page                                 //
//  Developer: NP		                                  //
//  Date: 2023-04-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import BlogService from "../../../services/BlogService";
import { Container } from "react-bootstrap";
import CommonHeader from "../../common/CommonHeader";
import CommonFooter from "../../common/CommonFooter";
import queryString from "query-string";
const queryParams = queryString.parse(location.search);

class HelpCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.match.params.category ? this.props.match.params.category : "",
      data: [],
      dataTotal: 0,
      category_name: "",
      view: queryParams.view === "mobile" ? false : (props.view ? false : true),
    }
  }

  componentDidMount() {
    this.getHelpByCategory();
  }

  // get all blogs with category
  getHelpByCategory = async () => {
    this.setState({ showSpinner: true, loading: true });
    var dataTotal = 0;
    var data = [];
    var formattedData = [];

    let res = await BlogService.getBlogData("category=" + this.state.category);
    if (global.successStatus.includes(res.status)) {
      data = res.data ? res.data : []
      dataTotal = data ? data.length : 0
    }

    res.data.map((index, value) => {
      if (formattedData[res.data[value]['article_category']] !== undefined) {
        formattedData[res.data[value]['article_category']].push({ title: res.data[value]['title'], slug_title: res.data[value]['slug_title'], slug: res.data[value]['corporate_slug'], content_catgory: res.data[value]['content_catgory'] });
        this.setState({ kb_category: res.data[value]['kb_category_name'] });
      }
      else {
        formattedData[res.data[value]['article_category']] = [{ title: res.data[value]['title'], slug_title: res.data[value]['slug_title'], slug: res.data[value]['corporate_slug'], content_catgory: res.data[value]['content_catgory'] }];
        this.setState({ kb_category: res.data[value]['kb_category_name'] });
      }
    })

    this.setState({ data: data, dataTotal: dataTotal });
    this.setState({ showSpinner: false, loading: false, seoLoaded: true });
  }

  openBlog(categorySlug, blogSlug) {
    this.props.history.push("/blog-data/" + categorySlug + "/" + blogSlug + "?view=mobile");
    window.location.reload();
  }

  render() {
    return (
      <main className="oh-template Brandmainpage Helpblogdetail">
        {/* Header */}
        {this.state.view &&
          <CommonHeader propertySlug={this.state.property_slug} profile_img={this.state.imageUrl} />
        }

        <Container>
          <div className="row">
            <div className="blogView">
              <div className="d-flex justify-content-start align-items-center flex-wrap">
                {/* <a href="/blog?view=mobile" className="mg-r-10">
                  <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} className="backarrow" />
                </a> */}
                <h1 className="m-0"> {this.state.kb_category}</h1>
                {/* <div className="filter">
                     <input type="search" id="gsearch" name="gsearch" placeholder="Search"/>
                     <span className="searchicon">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} alt="" />
                     </span>
                  </div> */}
              </div>
              <section className="tabsNav">
                <div className="col-12 blogsection">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <div className="blogleft">
                        {/* <h2 className="blogtitle">{this.state.category}</h2> */}
                        <ul>
                          {this.state.data.map((k, v) => {
                            return (
                              <li key={v}><a onClick={() => this.openBlog(k.article_category, k.corporate_slug)} className="hand-cursor">{k.title}</a></li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-4">
                      <div className="blogright">
                          <h4 className="blogtitle">Recent topics</h4>
                          <ul>
                            <li><a className="hand-cursor">Creating an account</a></li>
                          </ul>
                       </div>
                      </div> */}
                  </div>
                </div>
              </section>


            </div>
          </div>
        </Container>
        {/* Footer */}
        {this.state.view &&
          <CommonFooter propertySlug={this.state.property_slug} />
        }
      </main>
    );
  }
}
export default HelpCategory;