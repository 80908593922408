//     							                          //
//  Program: HelpCategory.jsx                             //
//  Application: CMS                                      //
//  Option:help blog page                                 //
//  Developer: NP		                                  //
//  Date: 2023-04-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import BlogService from "../../../services/BlogService";
import { Container } from "react-bootstrap";
import CommonHeader from "../../common/CommonHeader";
import CommonFooter from "../../common/CommonFooter";
import queryString from "query-string";
const queryParams = queryString.parse(location.search);

class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotal: 0,
      showMore: 0,
      showLine: [],
      defaultContentLength: 5,
      view: queryParams.view === "mobile" ? false : (props.view ? false : true),
    }
  }

  componentDidMount() {
    this.getAllHelp();
  }

  // get all blogs with category
  getAllHelp = async () => {
    this.setState({ showSpinner: true, loading: true });
    var data = [];
    var dataTotal = 0;
    var formattedData = [];

    let res = await BlogService.getBlogData();
    if (global.successStatus.includes(res.status)) {
      data = res.data ? res.data : []
      dataTotal = data ? data.length : 0

      res.data.map((index, value) => {
        if (formattedData[res.data[value]['kb_category_name']] !== undefined) {
          formattedData[res.data[value]['kb_category_name']].push({ title: res.data[value]['title'], slug_title: res.data[value]['slug_title'], slug: res.data[value]['corporate_slug'], content_catgory: res.data[value]['content_catgory'], kb_category_id: res.data[value]['article_category'] });
        }
        else {
          formattedData[res.data[value]['kb_category_name']] = [{ title: res.data[value]['title'], slug_title: res.data[value]['slug_title'], slug: res.data[value]['corporate_slug'], content_catgory: res.data[value]['content_catgory'], kb_category_id: res.data[value]['article_category'] }];
        }

        let prevShowLine = [...this.state.showLine];
        prevShowLine.push(this.state.defaultContentLength)
        this.setState({ showLine: prevShowLine })
      })
    }

    this.setState({ data: data, dataTotal: dataTotal, formattedData: formattedData });
    this.setState({ showSpinner: false, loading: false, seoLoaded: true });
  }

  openCategory(categorySlug) {
    this.props.history.push("/blog-category/" + categorySlug + "?view=mobile");
    // window.location.reload();
  }

  openBlog(categorySlug, blogSlug, redirectToMain = '') {
    this.props.history.push("/blog-data/" + categorySlug + "/" + blogSlug + "?redirectToMain=" + redirectToMain + "&view=mobile");
    // window.location.reload();
  }

  render() {
    return (
      <main className="oh-template Brandmainpage Helpblogdetail">
        {/* Header */}
        {this.state.view &&
          <CommonHeader propertySlug={this.state.property_slug} profile_img={this.state.imageUrl} />
        }

        <Container>
          <div className="row">
            <div className="blogView">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h1> All topics</h1>
                {/* <div className="filter">
                  <input type="search" id="gsearch" name="gsearch" placeholder="Search" />
                  <span className="searchicon">
                    <img src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} alt="" />
                  </span>
                </div> */}
              </div>
              <section className="tabsNav">
                <div className="col-12 blogsection">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="blogleft d-flex flex-wrap">
                        {this.state.dataTotal > 0
                          ? Object.keys(this.state.formattedData).map(
                            (opt, i) => {
                              var kb_id = this.state.formattedData[opt][0].kb_category_id;
                              var dataLength = this.state.formattedData[opt].length;
                              return (
                                <div className="col-12 col-md-4">
                                  <h4 className="blogtitle cursor-pointer" key={i} onClick={() => {
                                    var kb_category_id = this.state.formattedData[opt][0].kb_category_id ?? null;
                                    this.openCategory(kb_category_id)
                                  }} >{opt}</h4>
                                  <ul className="Helpdata">
                                    {this.state.formattedData[opt].slice(0, this.state.showLine[i] ?? this.state.defaultContentLength).map((k, v) => {

                                      return (
                                        <li key={v}><a onClick={() => this.openBlog(kb_id, k.slug, true)} className="hand-cursor">{k.title}</a></li>

                                      );
                                    })}
                                  </ul>
                                  {dataLength > 5 ?
                                    <span className="d-flex mg-t-30">
                                      <a
                                        className="showmore"
                                        href={"/blog-category/" + kb_id + "?view=mobile"}
                                      >Show More</a>
                                    </span>
                                    :
                                    ""
                                  }

                                </div>
                              )
                            }) : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </section>


            </div>
          </div>
        </Container>
        {/* Footer */}
        {
          this.state.view &&
          <CommonFooter propertySlug={this.state.property_slug} />
        }
      </main>
    );
  }
}
export default HelpPage;