import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

class SpinnerLoader extends Component {  
  render() {
    return (
      <>
        <Spinner
          as="span"
          animation="grow"
          role="status"
          aria-hidden="true"
          className="spinner_loader"
          variant="light"
          size="sm"
        />
        <Spinner
          as="span"
          animation="grow"
          role="status"
          aria-hidden="true"
          className="spinner_loader"
          variant="light"
          size="sm"
        />
        <Spinner
          as="span"
          animation="grow"
          role="status"
          aria-hidden="true"
          className="spinner_loader"
          variant="light"
          size="sm"
        />
      </>
    );
  }
}

export default SpinnerLoader;
