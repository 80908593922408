import { Component } from "react";
import Moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import { Button, Modal } from "react-bootstrap";
import ReservationService from "../../services/ReservationService";
import PhoneNumberFormat from "./PhoneNumberFormat";
class TripsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationList: props.reservationList,
      loader: props.loader,
      reservationModal: false,
      today: Moment(new Date()).format("YYYY-MM-DD H:m:s"),
      cancelModal: false,
      currentData: [],
      input: {},
    };
  }

  // reservationModal = async (value, item = []) => {
  //   this.setState({ reservationModal: value });
  //   localStorage.setItem("trip_details", value);
  //   await this.setState({
  //     currentData: item,
  //   });
  // };

  reservationModal = async (value, item = []) => {
    await localStorage.setItem("trip_details", JSON.stringify(item));
    window.location.href = "/reservation-details";
  };
  cancelModal = async (value, item = []) => {
    this.setState({ reservationModal: !value });
    await this.setState({
      currentData: item,
    });
    this.setState({ cancelModal: value });
  };

  cancelReservation = async (value, item = []) => {
    let input = {
      user_slug: item.confirmation_code ? item.confirmation_code : "",
      date: this.state.today,
      transaction_id: item.stripe_transaction_id
        ? item.stripe_transaction_id
        : "",
      // total_payment:item.toa
    };

    let res = await ReservationService.cancelReservationData(input);
    if (global.successStatus.includes(res.status)) {
      this.setState({ cancelModal: false });
    } else {
      alert("something went wrong try after sometime");
      this.setState({ cancelModal: false });
    }
    await this.props.getReservationData("", 2);
    //this.setState({ cancelModal: value });
  };

  render() {
    return (
      <>
        <>
          <section className="trip_sec">
            <div className="container">
              <div className="row">
                {this.props.loader ? (
                  <>
                    <tr>
                      <td colSpan={9}>
                        <Skeleton
                          variant="rectangular"
                          width={"100%"}
                          height={30}
                        />
                      </td>
                    </tr>
                  </>
                ) : this.props.reservationList &&
                  this.props.reservationList.length > 0 ? (
                  this.props.reservationList.map((item, index) => {
                    return (
                      <div className="col-lg-3 col-md-4 col-12 tripColum">
                        <div className="trip_box">
                          <div className="card" key={index}>
                            <figure key={index}>
                              <img
                                className="card-img-top"
                                src={
                                  item.file_name_medium
                                    ? item.file_name_medium
                                    : item.property.property_main_image_url
                                }
                                alt={item.listing_name}
                                title={item.listing_name}
                              />
                            </figure>
                            <div className="card-block">
                              <div className="card-heading">
                                <span className={"baged " + item.baged_class}>
                                  {item.status_text}
                                </span>
                                <h3>{item.listing_name}</h3>
                                <p>
                                  Confirmation Code{" "}
                                  <span>{item.reservation_code}</span>
                                </p>
                              </div>
                              <p>
                                Check-In{" "}
                                <span>
                                  {" "}
                                  {Moment(item.checkin_date).format(
                                    "MM/DD/YY"
                                  )}{" "}
                                  | {item.property.check_in_time}
                                </span>
                              </p>
                              <p>
                                Check-Out{" "}
                                <span>
                                  {Moment(item.checkout_date).format(
                                    "MM/DD/YY"
                                  )}{" "}
                                  | {item.property.check_out_time}
                                </span>
                              </p>
                              <p>
                                Booked{" "}
                                <span>
                                  {Moment(item.booked_at).format(
                                    global.dateTimeFormat
                                  )}
                                </span>
                              </p>
                              <div className="btn-card">
                                <h5>
                                  $
                                  {item.total_payment
                                    ? item.total_payment.replace(/\.00$/, "")
                                    : 0}
                                </h5>
                                <button
                                  onClick={() =>
                                    this.reservationModal(true, item)
                                  }
                                >
                                  Details
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-lg-12">
                    {/* <p>Sorry, No trips found.</p> */}

                    <a href="/str-listing" className="booknow">
                      <button disabled=""> Book Now</button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>

          {/* End deskview */}
          {/* Mobilekview */}
          <div className="reservationListMobview">
            <ul>
              {this.props.loader ? (
                <>
                  <li>
                    <button>
                      <figure>
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={60}
                        />
                      </figure>
                      <div className="content">
                        <h3>
                          <Skeleton
                            variant="rectangular"
                            width={210}
                            height={20}
                          />
                        </h3>
                        <h4>
                          <Skeleton
                            variant="rectangular"
                            width={210}
                            height={15}
                          />
                        </h4>
                        <h6>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={10}
                          />
                        </h6>
                      </div>
                    </button>
                  </li>
                </>
              ) : this.props.reservationList &&
                this.props.reservationList.length > 0 ? (
                this.props.reservationList.map((item, index) => {
                  return (
                    <li>
                      <button
                        className="w-100"
                        onClick={() => this.reservationModal(true, item.slug)}
                      >
                        <figure>
                          <img
                            src={
                              item.file_name_medium
                                ? item.file_name_medium
                                : item.property.property_main_image_url
                            }
                            alt={item.listing_name}
                            title={item.listing_name}
                          />
                        </figure>
                        <div className="content">
                          <h3>{item.listing_name}</h3>
                          <h4>{item.host_name}</h4>
                          <h6>
                            {Moment(item.created_at).format(global.dateFormat)}
                          </h6>
                        </div>
                      </button>
                    </li>
                  );
                })
              ) : (
                <li>Sorry, No trips found.</li>
              )}
            </ul>
          </div>
        </>
        <section className="width_402 p-4 border_radius_14">
          <Modal
            show={this.state.reservationModal}
            centered
            onHide={() => this.setState({ reservationModal: false })}
            className="reservationDetailmodalbox"
          >
            <span
              className="welcomeClose"
              onClick={() => this.setState({ reservationModal: false })}
            >
              {global.closee}
            </span>
            <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="reservationDetails">
                <div className="header">
                  <figure>
                    {!this.state.currentData ? (
                      <Skeleton
                        sx={{ height: 250 }}
                        animation="wave"
                        variant="rectangular"
                      />
                    ) : (
                      <img
                        src={
                          this.state.currentData.file_name_medium
                            ? this.state.currentData.file_name_medium
                            : global.OnKeyPressEvent.getS3ImageUrl(
                                "bedImage.jpg"
                              )
                        }
                        alt={this.state.currentData.listing_name}
                      />
                    )}
                  </figure>
                </div>
                <div className="contents">
                  <h2>
                    {this.state.currentData.listing_name
                      ? this.state.currentData.listing_name
                      : ""}
                  </h2>
                  <ul>
                    <li>
                      <img
                        src={global.OnKeyPressEvent.getS3ImageUrl(
                          "clock-icon.svg"
                        )}
                        className="clockIcon"
                        alt="Check-In"
                        title="Check-In"
                      />{" "}
                      Check-In -
                      {this.state.currentData.checkin_date
                        ? Moment(this.state.currentData.checkin_date).format(
                            global.dateTimeFormat
                          )
                        : "-"}
                    </li>
                    <li>
                      <img
                        src={global.OnKeyPressEvent.getS3ImageUrl(
                          "clock-icon.svg"
                        )}
                        className="clockIcon"
                        alt="Check-Out"
                        title="Check-Out"
                      />{" "}
                      Check-Out -
                      {this.state.currentData.checkout_date
                        ? Moment(this.state.currentData.checkout_date).format(
                            global.dateTimeFormat
                          )
                        : "-"}
                    </li>
                  </ul>
                  <hr />
                  <h3>Basic</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <label>First name</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.first_name
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Last name</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.last_name
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Unit</label>
                      <p>
                        {this.state.currentData.unit_number
                          ? this.state.currentData.unit_number
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Confirmation code</label>
                      <p>
                        {this.state.currentData.confirmation_code
                          ? this.state.currentData.confirmation_code
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Booked</label>
                      <p>
                        {this.state.currentData.created_at
                          ? Moment(this.state.currentData.created_at).format(
                              global.dateFormat
                            )
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Adults</label>
                      <p>
                        {this.state.currentData.adults &&
                        this.state.currentData.adults !== null
                          ? this.state.currentData.adults
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Children</label>
                      <p>
                        {this.state.currentData.children &&
                        this.state.currentData.children !== null
                          ? this.state.currentData.children
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Infants</label>
                      <p>
                        {this.state.currentData.infants &&
                        this.state.currentData.infants !== null
                          ? this.state.currentData.infants
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Pets</label>
                      <p>
                        {this.state.currentData.pets &&
                        this.state.currentData.pets !== null
                          ? this.state.currentData.pets
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>No. of guest</label>
                      <p>
                        {this.state.currentData.number_of_guest &&
                        this.state.currentData.number_of_guest !== null
                          ? this.state.currentData.number_of_guest
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Status</label>
                      <p>
                        {this.state.currentData.status_text
                          ? this.state.currentData.status_text
                          : "Pending"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Channel</label>
                      <p>
                        {this.state.currentData.set_channel === 1
                          ? "Website"
                          : "Airbnb"}
                      </p>
                    </div>
                    <div className="col-md-12">
                      <label>Phone number</label>
                      <p>
                        {this.state.currentData.guest_user ? (
                          <PhoneNumberFormat
                            phone_number={
                              this.state.currentData.guest_user.phone_number
                            }
                          />
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>City</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.city_id
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>State</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.state_id
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Zipcode</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.zip_code
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>County</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.county_id
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Country</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.country_id
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label>Passport</label>
                      <p>
                        {this.state.currentData.guest_user
                          ? this.state.currentData.guest_user.passport
                            ? this.state.currentData.guest_user.passport
                            : "-"
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <h3>Guest paid</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <label>{this.state.currentData.total_night_text}</label>
                    </div>
                    <div className="col-md-6">
                      <p>{`${
                        this.state.currentData.total_nightly_rate
                          ? this.state.currentData.total_nightly_rate
                          : this.state.currentData.total_nightly_rate_disp
                      }`}</p>
                    </div>
                    {this.state.currentData.reservation_taxes &&
                      this.state.currentData.reservation_taxes.length > 1 &&
                      this.state.currentData.reservation_taxes.map(
                        (tax, taxid) => {
                          return (
                            <>
                              <div className="col-md-6">
                                <label>{tax.tax_name}</label>
                              </div>
                              <div className="col-md-6">
                                <p>
                                  {tax.tax_amount ? "$" + tax.tax_amount : "-"}
                                </p>
                              </div>
                            </>
                          );
                        }
                      )}
                    {/* {this.state.currentData.total_fees !== "0.00" && this.state.currentData.total_fees > 0 ?
                      <>
                        <div className="col-md-6">
                          <label>Service fees</label>
                        </div>
                        <div className="col-md-6">
                          <p>
                            {this.state.currentData.total_fees
                              ? this.state.currentData.total_fees
                              : "-"}
                          </p>
                        </div>
                      </>
                      : ""} */}
                    {this.state.currentData.cleaning_fee !== "0.00" &&
                    this.state.currentData.cleaning_fee > 0 ? (
                      <>
                        <div className="col-md-6">
                          <label>Cleaning fee</label>
                        </div>
                        <div className="col-md-6">
                          <p>
                            {this.state.currentData.cleaning_fee
                              ? "$" + this.state.currentData.cleaning_fee
                              : "-"}
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.currentData.total_taxes !== "0.00" &&
                    this.state.currentData.total_taxes > 0 ? (
                      <>
                        <div className="col-md-6">
                          <label>Occupancy taxes</label>
                        </div>
                        <div className="col-md-6">
                          <p>
                            {this.state.currentData.total_taxes
                              ? "$" + this.state.currentData.total_taxes
                              : "-"}
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="col-md-6">
                      <label>Total(USD)</label>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <b>
                          {this.state.currentData.total_payment
                            ? "$" + this.state.currentData.total_payment
                            : "-"}
                        </b>
                      </p>
                    </div>
                  </div>
                  <hr />
                  {this.state.currentData.cancel_status === 0 &&
                  (this.props.tab === 1 || this.props.tab === 2) &&
                  this.state.currentData.set_channel === 1 ? (
                    <div className="col-lg-12 text-center m-auto pd-t-30">
                      <button
                        onClick={() =>
                          this.cancelModal(true, this.state.currentData)
                        }
                      >
                        Cancel reservation
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.cancelModal}
            centered
            onHide={() => this.setState({ cancelModal: false })}
            className="reservationDetailmodalbox"
          >
            <span
              className="welcomeClose"
              onClick={() => this.setState({ cancelModal: false })}
            >
              {global.closee}
            </span>
            <Modal.Body className="width_402 p-4 border_radius_14">
              <div className="reservationDetails">
                <h5>Are you sure want to cancel?</h5>
                <button
                  onClick={() =>
                    this.cancelReservation(true, this.state.currentData)
                  }
                  className="mg-r-15"
                >
                  Yes
                </button>
                <button
                  onClick={() =>
                    this.cancelModal(false, this.state.currentData)
                  }
                >
                  No
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </section>
      </>
    );
  }
}

// export default TripsTable;
export default TripsTable;
