import { Component } from "react";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentDidMount() {
        localStorage.removeItem("headerToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("permissions");
        localStorage.removeItem("user_type");
        window.location.href = "/"
    }
    render() {
        return ( "" );
    }
}

export default Logout;