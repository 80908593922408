//     							                          //
//  Program: HelpBlog.jsx                             //
//  Application: CMS                                      //
//  Option:help blog page                                 //
//  Developer: NP		                                  //
//  Date: 2023-04-25                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import BlogService from "../../../services/BlogService";
import { Container } from "react-bootstrap";
import CommonHeader from "../../common/CommonHeader";
import CommonFooter from "../../common/CommonFooter";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import queryString from "query-string";
const queryParams = queryString.parse(location.search);

class HelpBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.match.params.category ? this.props.match.params.category : "",
      blog: this.props.match.params.blog ? this.props.match.params.blog : "",
      data: [],
      dataTotal: 0,
      view: queryParams.view === "mobile" ? false : (props.view ? false : true),
    }
  }

  componentDidMount() {
    this.getHelpData();
  }

  // get all blogs with category
  getHelpData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var title = "";
    var description = "";
    var kb_category_id = "";
    let res = await BlogService.getBlogData("category=" + this.state.category + "&blog=" + this.state.blog);
    if (global.successStatus.includes(res.status)) {
      title = res.data ? res.data[0].title : ""
      description = res.data ? res.data[0].description : ""
      kb_category_id = res.data ? res.data[0].kb_category_id : ""
    }

    this.setState({ title: title, description: description, kb_category_id: kb_category_id });
    this.setState({ showSpinner: false, loading: false, seoLoaded: true });
  }

  render() {
    return (
      <main className="oh-template Brandmainpage Helpblogdetail">
        {/* Header */}
        {this.state.view &&
          <CommonHeader propertySlug={this.state.property_slug} profile_img={this.state.imageUrl} />
        }
        <Container>
          <div className="blogView">
            <div className="d-flex justify-content-start align-items-center flex-wrap">
              {/* <a href={(queryParams.redirectToMain) ? "/blog?view=mobile" : "/blog-category/"+this.state.kb_category_id+"?view=mobile"} className="mg-r-10">
                <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} className="backarrow" />
              </a> */}
              <h1 className="m-0"> {this.state.title}</h1>
              {/* <div className="filter">
                     <input type="search" id="gsearch" name="gsearch" placeholder="Search"/>
                     <span className="searchicon">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} alt="" />
                     </span>
                  </div> */}
            </div>
            <section className="tabsNav">
              <div className="col-12 blogsection">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="blogdetails">
                      {/* <h2 ></h2> */}
                      <HTMLEllipsis
                        unsafeHTML={
                          this.state.description
                            ? this.state.description
                            : ""
                        }
                        maxLine="6"
                        ellipsis=""
                        trimright="true"
                        basedOn="letters"
                      />
                      {/* <p>{this.state.description}</p> */}
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-1"></div> */}
                  {/* <div className="col-12 col-md-4">
                    <div className="blogright">
                      <h4 className="blogtitle">Recent topics</h4>
                      <ul>
                        <li><a className="hand-cursor">Creating an account</a></li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        </Container>
        {/* Footer */}
        {this.state.view &&
          <CommonFooter propertySlug={this.state.property_slug} />
        }
      </main>
    );
  }
}
export default HelpBlog;