import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import Moment from "moment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutFormParking from "./CheckoutFormParking";
import CmsDataService from "../../services/CmsDataService";
import ReservationService from "../../services/ReservationService";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class ParkingMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentData: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details"))
        : [],
      confirmation_code:
        props.match && props.match.params
          ? props.match.params.confirmationcode
          : "",
      today: Moment(new Date()).format("YYYY-MM-DD H:m:s"),
    };
    this.getReservationDetails = this.getReservationDetails.bind(this);
    this.getclientToken = this.getclientToken.bind(this);
  }

  async componentDidMount() {
    // if(global.isMobile){
    await this.getReservationDetails();
    // }
  }
  onBackButtonEvent = () => {
    this.props.history.push("/reservation-list");
  };
  getReservationDetails = async () => {
    var list = [];
    var record = [];
    var totalRecords = 0;
    var parking_fee_stripe = "";
    var property_parking_fee = "";
    var total_stay_nights = "";
    var parking_instruction = "";
    var reservation_slug = "";
    var guest_slug = "";
    var customer_id = "";
    this.setState({ loader: true });
    var data = {
      confirmation_code: this.state.confirmation_code,
      last_name: "",
      email: "",
      property_seo_slug: "",
      user_slug: "",
      filter_by: 4,
    };
    let res = await ReservationService.getMyBookReservation(data);
    if (global.successStatus.includes(res.status) && res.data.length !== 0) {
      list = res.data ? res.data : [];
      parking_fee_stripe = res.data ? res.data.parking_fee_stripe : "";
      reservation_slug = res.data ? res.data.slug : "";
      guest_slug =
        res.data && res.data.guest_user ? res.data.guest_user?.slug : "";
      customer_id =
        res.data && res.data.guest_user
          ? res.data.guest_user?.stripe_customer_id
          : "";
      property_parking_fee = res.data ? res.data.property_parking_fee : "";
      total_stay_nights = res.data ? res.data.total_stay_nights : "";
      parking_instruction = res.data
        ? res.data?.property?.parking_payment_instructions
        : "";
      record = res.data ? res.data : [];
      this.setState({ loader: false });
      totalRecords = res.data ? res.data.total : 0;
    }
    if (global.successStatus.includes(res.status) && res.data.length !== 0) {
      await this.setState({
        total_record: totalRecords,
        reservationList: list,
        parking_fee_stripe: parking_fee_stripe,
        property_parking_fee: property_parking_fee,
        total_stay_nights: total_stay_nights,
        parking_instruction: parking_instruction,
        reservation_slug: reservation_slug,
        guest_slug: guest_slug,
        customer_id: customer_id,
        loader: false,
      });
      await localStorage.setItem("trip_details", JSON.stringify(list));
      this.getclientToken();
      this.setState({ reservationDetail: true, errorMsg: "" });
    } else {
      this.setState({
        errorMsg:
          "Details entered by you are incorrect. Please verify your confirmation code and last name you have used during booking.",
      });
    }

    this.setState({ loader: false });
  };
  getclientToken = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var stripe_id = [];
    var parkingAmount = this.state.parking_fee_stripe
      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
          parseFloat(this.state.parking_fee_stripe)
            .toFixed(2)
            .replace(/\.00$/, "")
        )
      : parseInt(0).toFixed(2);
    let inputData = {
      customer_id: this.state.customer_id,
      payment: parkingAmount,
      guest_slug: this.state.guest_slug,
      reservation_slug: this.state.reservation_slug,
    };
    let res = await CmsDataService.getParkingclientToken(inputData);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data[0] : [];
      stripe_id = res.data ? res.data[1] : [];
    }
    await this.setState({
      clientToken: resDataArr,
      stripe_id: stripe_id,
      parkingAmount: parkingAmount,
    });
  };
  render() {
    const options = {
      clientSecret: this.state.clientToken,
      appearance: {
        /*...*/
      },
    };

    const parkingFeedisplay = this.state.property_parking_fee
      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
          parseFloat(this.state.property_parking_fee)
            .toFixed(2)
            .replace(/\.00$/, "")
        )
      : parseInt(0).toFixed(2);
    const numberOfdays = this.state.total_stay_nights;
    const parkingAmount = this.state.parking_fee_stripe
      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
          parseFloat(this.state.parking_fee_stripe)
            .toFixed(2)
            .replace(/\.00$/, "")
        )
      : parseInt(0).toFixed(2);
    return (
      <main className="oh-template Brandmainpage wizard_header wzard_main_bg parking_via_app">
        {/* <CommonHeader /> */}
        {global.isMobile ? (
          <section className="wizard_sec">
            <div className="row- wizard_row m-0 mobView">
              <div className="wizard_box ">
                <div className="wizard-data req_book_secw-zaed">
                  <div className="container-fluid p-0 parking_page">
                    <section className="wzrd_main_section">
                      <div className="container">
                        <div className="content">
                          <div className="row mobView wizard_new_heading">
                            <div className="wzrd_middle_desk mb-0">
                              <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                                <div className="wzrd_heading_box col-12 m-0 ">
                                  <h4>
                                    {this.state.currentData
                                      ?.parking_screen_stripe_titles
                                      ? this.state.currentData
                                          ?.parking_screen_stripe_titles
                                      : "Parking Payment"}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                                <div className="wzrd_heading_box col-12 mb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard upload_info_detail ">
                                    <p>
                                      {" "}
                                      <b className="doc_upload_heading pt-0 parking-head-text">
                                        Would you like to access parking
                                        instructions for $
                                        {parkingFeedisplay
                                          ? parkingFeedisplay
                                          : ""}
                                        /night?
                                      </b>{" "}
                                    </p>
                                    <ul className="parking_list_sty">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.parking_instruction
                                            ? this.state.parking_instruction
                                            : "",
                                        }}
                                      ></p>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
                                <div className="parking_price_box">
                                  <div className="wzrd_heading_box col-12 ">
                                    <div className="wzrd_mid_heading pt-0 border-0">
                                      <h5 className="pt-0">
                                        Parking price details
                                      </h5>
                                      <div className="mt-3">
                                        <ul className="parking_pay_detail">
                                          <li>
                                            <span>
                                              ${parkingFeedisplay} x{" "}
                                              {numberOfdays > 1
                                                ? numberOfdays + " nights"
                                                : numberOfdays + " night"}
                                            </span>
                                            <span>${parkingAmount}</span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="wzrd_mid_heading pt-0 ">
                                      <div className="parking_total">
                                        <ul className="parking_pay_detail">
                                          <li className="parking_total_price">
                                            <span>Total (USD)</span>
                                            <span>${parkingAmount}</span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                                <div className="wzrd_heading_box col-12 mb-0">
                                  <div className="col-xs-12 col-sm-12 col-md-12 mg-b-0 strip_parking">
                                    {!this.state.clientToken ? (
                                      <div>
                                        <h3>Loading...</h3>
                                      </div>
                                    ) : (
                                      <div className="col-xs-12 col-sm-12 col-md-12 pd-l- pd-r-">
                                        <Elements
                                          stripe={stripePromise}
                                          options={options}
                                        >
                                          <CheckoutFormParking
                                            guest_slug={
                                              this.state.guest_slug
                                                ? this.state.guest_slug
                                                : ""
                                            }
                                            reservation_slug={
                                              this.state.reservation_slug
                                                ? this.state.reservation_slug
                                                : ""
                                            }
                                            amount={
                                              this.state.parkingAmount
                                                ? this.state.parkingAmount
                                                : ""
                                            }
                                            stripe_id={this.state.stripe_id}
                                            confirmation_code={
                                              this.state.confirmation_code
                                                ? this.state.confirmation_code
                                                : this.state.currentData
                                                    ?.confirmation_code
                                            }
                                          />
                                        </Elements>
                                      </div>
                                    )}
                                    <p className="error-message"></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </main>
    );
  }
}
export default ParkingMobile;
