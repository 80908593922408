
import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";



class MultifamilyPartnershipsThird extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };
    render() {
        return (
            <div>
                <main className="oh-template Brandmainpage ">
                    <CommonHeader />

                    {/* <section className="multifamily-banner">
                        <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-50 pd-r-50">
                            <h1>How to Stabilize Rent in a Down Market</h1>
                            <h4 className="m-0">Allowing residents to short-term rent their apartment, increases NOI and rent stabilization.</h4>
                        </div>
                    </section> */}

                    <section className="multifamily-sec">
                        <div className="container">
                            <div className="breadcumbs">
                                <ul>
                                    <li> <a href="/">Home</a> </li>
                                    <li> / <a href="/partnerships">Multifamily partnerships</a></li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-md-8 col-xs-12 pad-ct-r-0">
                                    <div className="multifamily-left">
                                        <div className="multi-head mg-b-30">
                                            <h2>Is my apartment building a good fit for Airbnb and Vrbo activity?</h2>
                                            {/* <span>Allowing residents to short-term rent their apartment, increases NOI and rent stabilization.</span> */}
                                        </div>
                                        <div className="multi-cnt">
                                            <p>As most of you know by now, apartment buildings have finally begun letting renters monetize their apartments on Airbnb and other short term rental platforms. Via many case studies, homesharing as an amenity can lead to market outperformance in rental rates, occupancy and turnover. As an apartment owner, you may be wondering if your building is a good fit to allow your tenants to “Airbnb” their apartments. You should consider the following criteria:</p>

                                           <ol>
                                                <li>Amenities – similar to how hotels with nice pools and spas command comparably higher nightly rates, apartment buildings with strong amenities outperform on Airbnb.</li>
                                                <li>Weather – warm weather cities generally outperform cold weather cities, as year-round outdoor activities and amenities are highly attractive (unless of course your apartment building is near cold-weather activities).</li>
                                                <li>Cultural Events – cities rich in cultural experiences tend to rake in high-priced hospitality demand around these events. Miami, for example, which boasts Art Basel, Winter Music Conference, the Orange Bowl, and many other large events, seems to always have an event that is driving tourism.</li>
                                                <li>Restaurants / Entertainment / Nightlife – lively and interesting cities are the most attractive tourist destinations</li>
                                                <li>Proximity to other Airbnb friendly apartment buildings — if your apartment building is in the same market as other buildings that offer homesharing as an amenity, it’s a good idea to incorporate that amenity into your offering as well, so you don’t lose out on those prospects.</li>
                                            </ol>
                                            <p>If your rental building is in fact a good fit for homesharing, it’s crucial to consider the best method to minimize the risk factors that come with hosting short term residents. Partnering with a technology platform like OHAI provides control over check-ins/check outs, digital access points, key exchanges, guest customer service, room cleanings, and many other behaviors such as smoking and loud noise versus simply just becoming Airbnb-friendly.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-12 position-relative">
                                    <div className="multifamily-right">
                                        <div className="multi-need">
                                            <h4>Want to get in touch ?</h4>
                                            {/* <p>We'll start with some questions get you to the right place.</p> */}
                                            <a href="mailto:sales@orionhaus.com" className=""><button  >Contact sales</button></a>
                                            <div className="multi-address">
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('person.svg')} alt="" />  Rob Landers</p>
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('phone.svg')} alt="" /> <a href="tel:404-271-7680"> 404-271-7680</a></p>
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('email.svg')} alt="" /> <a href="mailto:rob@orionhaus.com">rob@orionhaus.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="expl_artls_sec expl_inner_detail_sec">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="artls_head">
                                            <h2 className="art_heading">
                                                Explore resource center articles
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/how-to-minimize-risk-in-your-airbnb-friendly-apartment-building"  className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('how-to-minimize-risk.webp')} alt="" title="How to minimize risk in your Airbnb-friendly apartment building?" />
                                                    </figure>
                                                </div>
                                                <p>How to minimize risk in your Airbnb-friendly apartment building?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/how-to-stabilize-rent-in-a-down-market"  className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('how-to-stabilize-rent.webp')} alt="" title="How to stabilize rent in a down market?" />
                                                    </figure>
                                                </div>
                                                <p>How to stabilize rent in a down market?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="artls_more">
                                            <button>More articles</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    <CommonFooter />
                </main>
            </div>
        )
    }
}

export default MultifamilyPartnershipsThird;