import React from "react";
import {
  ElementsConsumer,
  CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import ReservationService from "../../services/ReservationService";
import SpinnerLoader from "../common/SpinnerLoader";
class CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      errors: {},
    };
    this.removeMessage = this.removeMessage.bind(this);
  }
  handleSubmitCard = async (event) => {
    event.preventDefault();
    const stripe = this.props.stripe;
    const elements = this.props.elements;
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });
    var cardNumber = result.paymentIntent?.payment_method;
    if (result.error) {
      var message = result.error.message;
      var decline_message = message;
      this.setState({ failedmessage: decline_message });
    } else {
        /** code for update data in table flag 2  in guest form*/
        let inputDatacard = {
          guest_slug: this.props.guest_slug,
          confirmation_code:this.props.confirmation_code,
          is_form_submited: 1,
          status_id: 1,
        };
  
        let rescard =  await ReservationService.Guestform(inputDatacard);
        if (global.successStatus.includes(rescard.status)) {
          localStorage.setItem("is_form_submited", 1);
        } 
        /** end code for update data in table flag 2  in guest form*/
      /**code for save Payment intent  data in table */
      this.setState({ loader: true });
      var tokenNumber = this.props.stripe_id;
      let inputData = {
        confirmation_code: this.props.confirmation_code,
        payment_method_id: cardNumber,
        card_tokenise_number: tokenNumber,
        card_agree_type: this.props.card_agree_type,
        customer_id: this.props.customer_id,
        status_id: 1,
      };
      let res = await ReservationService.CardTokenise(inputData);
      if (global.successStatus.includes(res.status)) {
        localStorage.setItem("card_tokenise", 1);
        window.location.href = "/reservation-details";
      } else {
        this.setState({ failedmessage: res.message });
      }
      this.setState({ loader: false });
      /**end code for save tokenise data in table */
    }
  };
  removeMessage = async () => {
    await this.setState({ failedmessage: "" });
  };
  setPrevious = async () => {
    this.props.setPrevious(this.props.pagestate);
  };
  render() {
    return (
      <>
        <div className="">
          <PaymentElement onChange={this.removeMessage.bind(this)} />
          <p className="mg-b-15 text-danger decline">
            {this.state.failedmessage}
          </p>

          <div className="step_btns step_card_deatil">
          {this.state.pagestate !== 1 && this.props.is_form_submited !== 2 ? (
              <button
                id="book-tour-page"
                className="w-100 btn_pre_wzd"
                onClick={this.setPrevious}
              >
                Previous
              </button>
            ) : (
              ""
            )}
            {this.props.pagestate === 5 ? (
              <button
                id="book-tour-page"
                className="w-100 btn_next_wzd"
                disabled={this.state.loader ? true : false}
                onClick={(e) => this.handleSubmitCard(e)}
              >
                Next
                {this.state.loader ? (
                  <>
                    {" "}
                    <div className="mob_fullscreen">
                      <SpinnerLoader />
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

export default function InjectedCardForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardForm
          stripe={stripe}
          elements={elements}
          confirmation_code={props.confirmation_code}
          signature={props.signature}
          pagestate={props.pagestate}
          stripe_id={props.stripe_id}
          clientSecret={props.clientSecret}
          setPrevious={props.setPrevious}
          card_agree_type={props.card_agree_type}
          customer_id={props.customer_id}
          is_form_submited = {props.is_form_submited}
          guest_slug = {props.guest_slug}
        />
      )}
    </ElementsConsumer>
  );
}
