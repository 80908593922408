import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
class MetaSEO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : "OHAI",
      faviconUrl: props.favicon ? props.favicon :"",
      apple_touch_icon: props.favicon ? props.favicon :"",
      og_title: props.title ? props.title : "OHAI",
      author: props.author ? props.author : (props.title ? props.title : "OHAI"),
      canonical: props.url ? props.url : window.location.href,
      og_url: props.url ? props.url : window.location.href,
      og_type: props.og_type ? props.og_type : "website",
      og_site_name: props.title ? props.title : "OHAI",
      fb_app_id: props.fb_app_id ? props.fb_app_id : "5yfjp6k05bhad8motb903dgrena4d4",
      twitter_card: props.twitter_card ? props.twitter_card : "summary_large_image",
      twitter_title: props.title ? props.title : "OHAI",
      twitter_description: props.description ? props.description : global.description,
      twitter_image: props.image ? props.image : "",
      description: props.description ? props.description : global.description,
      keywords: props.keywords ? props.keywords : global.keywords,
      og_image: props.image ? props.image : "",
      og_image_width: props.og_image_width ? props.og_image_width : "320",
      og_image_height: props.og_image_height ? props.og_image_height : "240",
      og_description: props.description ? props.description : global.description,
      image: props.image ? props.image : "",
      noIndex: props.noIndex === "true" ? true : false,
    };
  }
  componentDidMount() {
    //  var ele =  document.getElementById('theme');
    //  var favicon = document.getElementById("favicon");
    //  var metaName = document.getElementById("meta_name");
    //  var siteTitle = document.getElementById("site_title");
    //  var appleTouchIcon = document.getElementById("apple_touch_icon");
    //  var canonical = document.getElementById("canonical");
    //  var description = document.getElementById("description");
    //  var keywords = document.getElementById("keywords");
    //  var author = document.getElementById("author");
    //  var ogTitle = document.getElementById("og_title");
    //  var ogType = document.getElementById("og_type");
    //  var ogUrl = document.getElementById("og_url");
    //  var ogImage = document.getElementById("og_image");
    //  var ogImageWidth = document.getElementById("og_image_width");
    //  var ogImageHeight = document.getElementById("og_image_height");
    //  var ogSiteName = document.getElementById("og_site_name");
    //  var ogDescription = document.getElementById("og_description");
    //  var image = document.getElementById("image");
    //  var twitterTitle = document.getElementById("twitter_title");
    //  var twitterCreator = document.getElementById("twitter_creator");
    //  var twitterDescription = document.getElementById("twitter_description");
    //  var twitterImage = document.getElementById("twitter_image");

    // favicon.href = this.state.faviconUrl;
    // appleTouchIcon.href = this.state.faviconUrl;
    // metaName.name = this.state.title;
    // metaName.content = this.state.title;
    // siteTitle.innerText = this.state.title;
    // canonical.href = this.state.canonical;
    // description.content = this.state.description;
    // keywords.content = this.state.keywords;
    // author.content = this.state.author;
    // ogTitle.content = this.state.og_title;
    // ogType.content = this.state.og_type;
    // ogUrl.content = this.state.og_url;
    // ogImage.content = this.state.og_image;
    // ogImageWidth.content = this.state.og_image_width;
    // ogImageHeight.content = this.state.og_image_height;
    // ogSiteName.content = this.state.og_site_name;
    // ogDescription.content = this.state.og_description;
    // image.content = this.state.image;
    // twitterTitle.content = this.state.twitter_title;
    // twitterCreator.content = this.state.author;
    // twitterDescription.content = this.state.twitter_description;
    // twitterImage.content = this.state.twitter_image;


    // if (process.env.REACT_APP_BRAND_URL.includes(global.host)) {
    //   favicon.href = this.state.faviconUrl ? this.state.faviconUrl : "/oh-favicon.svg";
    //   appleTouchIcon.href = this.state.faviconUrl ? this.state.faviconUrl : "/oh-favicon.svg";
    //   metaName.name = this.state.brand_name ? this.state.brand_name : "Kini Beach Haus";
    //   metaName.content = this.state.brand_name ? this.state.brand_name : "Kini Beach Haus";
    //   siteTitle.innerText = this.state.brand_name ? this.state.brand_name : "Kini Beach Haus";
    // } else if (process.env.REACT_APP_PROPERTY_URL.includes(global.host)) {
    //   favicon.href = this.state.faviconUrl ? this.state.faviconUrl : "/oh-favicon.svg";
    //   appleTouchIcon.href = this.state.faviconUrl ? this.state.faviconUrl         : "/oh-favicon.svg";
    //   metaName.name = this.state.property_name ? this.state.property_name : "Kini Beach Haus / Las Olas";
    //   metaName.content = this.state.property_name ? this.state.property_name : "Kini Beach Haus / Las Olas";
    //   siteTitle.innerText = this.state.property_name ? this.state.property_name : "Kini Beach Haus / Las Olas";
    // } else {
    //   favicon.href = this.state.faviconUrl ? this.state.faviconUrl : "/oh-favicon.svg";
    //   appleTouchIcon.href = this.state.faviconUrl ? this.state.faviconUrl : "/oh-favicon.svg";
    //   metaName.name = "OHAI";
    //   metaName.content = "OHAI";
    //   siteTitle.innerText = "OHAI";
    // }
  }

  render() {
    return (
      <Helmet prioritizeSeoTags>
        {/* <title>{this.state.title}</title> */}
        {/* <meta charset="utf-8" /> */}
        {
          this.state.noIndex && (
            <meta name="robots" content="noindex,nofollow" />
          )
        }
        {/* <link rel="icon" id="favicon" href={this.state.faviconUrl} />
        <meta id="meta_name" name={this.state.title} content={this.state.title} />
        <link rel="apple-touch-icon" id="apple_touch_icon" href={this.state.apple_touch_icon} />
        <meta rel="canonical" href={this.state.canonical} />
        <meta name="keywords" content={this.state.keywords}></meta> */}
        {/* <meta name="author" content={this.state.author} />
        <meta property="og:site_name" content={this.state.og_site_name} />
        <meta property="og:site" content={this.state.og_url} />
        <meta property="og:url" content={this.state.og_url} />
        <meta property="og:title" content={this.state.og_title} />
        <meta property="og:description" content={this.state.og_description} />
        <meta property="og:image" content={this.state.og_image} />
        <meta property="og:image:width" content={this.state.og_image_width} />
        <meta property="og:image:height" content={this.state.og_image_height} />
        <meta property="og:type" content={this.state.og_type} />
        <meta itemprop="twitter:image" content={this.state.image} />
        <meta name="twitter:title" content={this.state.twitter_title} />
        <meta name="twitter:description" content={this.state.twitter_description} />
        <meta name="twitter:card" content={this.state.twitter_card} />
        <meta name="twitter:image" content={this.state.twitter_image} />
        <meta name="description" content={this.state.description} />
        <meta property="fb:app_id" content={this.state.fb_app_id} /> */}
      </Helmet>
    );
  }
}

export default MetaSEO;
