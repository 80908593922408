import { Map, Polyline,GoogleApiWrapper,Marker } from "google-maps-react";
import { Component } from "react";
class GoogleMap  extends Component {
    constructor(props) {
        super(props);
        this.state = {
          lat:this.props.lat ? this.props.lat:37.759703,
          lng:this.props.lng ? this.props.lng:-122.428093,
          propertyName:this.props.propertyName,
          title:this.props.title ? this.props.title : "DESIGNER STUDIO IN THE HEART OF LAS OLAS |KING BED",
        }
        // console.log(this.props,"props",this.state.lat,"lat",this.state.lng,"lng");
    }
    render() {
        return (
            // <img src={locationMap} />

            <Map google={this.props.google}
            style={{width: '100%', height: '100%', position: 'relative'}}
            className={'map'}
            // center= {{lat:this.state.lat,lng: this.state.lng}}
            initialCenter={{lat:this.state.lat,lng: this.state.lng}}
            zoom={14}
            >
          <Marker
            title={this.state.title}
            name={this.state.propertyName ? this.state.propertyName: ""}
            />
        </Map>

        );
    }
}

// export default GoogleMap;
export default GoogleApiWrapper(
    (props) => ({
      apiKey: "AIzaSyA23meeOaFs2E4KuQk4CDB8TbOfHKWemPs",
      language: props.language,
      lat:props.lat,
      lng:props.lng
    }
  ))(GoogleMap);
