///////////////////////////////////////////////
//     							             //
//  Program: BlogService.js                  //
//  Application: Services                    //
//  Option: Used for BLog CMS                //
//  Developer: NP                            //
//  Date: 2023-04-25                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class BlogService {
  getBlogData(queryString) {
    let endPoint = "get-corporate-app-data" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getBlogDataByCategory(categorySlug) {
    let endPoint = "corporate-app/" + categorySlug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getBlogDataBySlug(categorySlug,blogSlug) {
    let endPoint = "corporate-app/" + categorySlug + blogSlug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}
export default new BlogService();