//     							                          //
//  Program: howitworks.jsx                               //
//  Application: KINI HTML                                //
//  Option: How it works                                  //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";


import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import CmsDataService from "../../services/CmsDataService";
import { Helmet } from "react-helmet-async";

class HowItWorks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            personName:[],
            date:new Date('2014-08-18T21:11:54'),
            selectedDate:new Date(),
            type:false,
            property_slug:  props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
            property_name:localStorage.getItem("property_name"),
            metaKeywords:"home,realtor,houses for rent,homes for sale,property,property for sale,homes for rent,apartments for rent near me",
            metaDescription:" Traditional real estate advertising focuses on outbound leads, which involves targeted prospecting. This method allows you to hone in probable leads, which increases the likelihood of success."
        }
      }

      componentDidMount() {
        this.getcmsCommonData();
      }

      getcmsCommonData = async (queryString = "") => {
        this.setState({ showSpinner: true, loading: true });
        var resData = {};
        var resDataArr = [];
          var cacheKeyName = global.theme + "_HOWITWORKS_DATA_" + this.state.property_slug;
        if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
          var data =  global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
          this.setState(data);
        }
          let res = await CmsDataService.getCmsData(
            "HOWITWORKS",
            "property_seo_slug=" + this.state.property_slug
          );
          if (global.successStatus.includes(res.status)) {
            resDataArr = res.data ? res.data : [];
            resDataArr.map((item, key) => {
              resData[item.section_name_slug] = item;
            });
          }
          await this.setState({
            showSpinner: false,
            loading: false
          });
          global.LocalStorageManage.setWithExpiry(cacheKeyName,JSON.stringify(resData));
          this.setState(resData);
      };

      render() {
        return (
            <main className="oh-template">
                {/* Header */}
                <CommonHeader propertySlug={this.state.property_slug} />
                 {/* middle */}
                 <Helmet>
            <title>{this.state.property_name ? this.state.property_name : global.propertyName} - HOW IT WORKS</title>
            <meta name="robots" content="noindex,nofollow" />
            <meta name="description" content={this.state.property_name ? this.state.property_name + " - A CITY REIMAGINED  " +this.state.metaDescription  : this.state.metaDescription }></meta>
            <meta name="keywords" content={this.state.metaKeywords}></meta>
            <meta name="author" content={this.state.property_name ? this.state.property_name : global.propertyName} />
          </Helmet>
                <section className="middle">
                        <div className="container-fluid p-0">
                             <section className="howitWorks pd-t-60 pd-b-20" name="SECTION_1">
                                    <div className="container pd-b-0">
                                        <div className="row mg-xs-0 align-items-center">
                                            <div className="col-lg-4 pd-r-30" name="SECTION_1_LEFT">
                                                <h3>
                                                    {this.state.SECTION_1_LEFT && this.state.SECTION_1_LEFT.section_data && this.state.SECTION_1_LEFT.section_data[0] && this.state.SECTION_1_LEFT.section_data[0].text ? this.state.SECTION_1_LEFT.section_data[0].text :"How it works"}
                                                </h3>
                                                <p className="para">
                                                    {this.state.SECTION_1_LEFT && this.state.SECTION_1_LEFT.section_data && this.state.SECTION_1_LEFT.section_data[1] && this.state.SECTION_1_LEFT.section_data[1].text ? this.state.SECTION_1_LEFT.section_data[1].text : "Kini Beach Haus gives you the opportunity to do what you love most — hosting and earning money — and handles all of the annoying parts: Cleanings, maintenance, door codes, automatic messaging, dynamic pricing."}
                                                </p>

                                            </div>
                                            <div className="col-lg-8" name="SECTION_1_RIGHT">
                                                <figure className="pd-l-70 m-0 mob">
                                                    <img src={this.state.SECTION_1_RIGHT && this.state.SECTION_1_RIGHT.section_data && this.state.SECTION_1_RIGHT.section_data[0] && this.state.SECTION_1_RIGHT.section_data[0].url ? this.state.SECTION_1_RIGHT.section_data[0].url : global.OnKeyPressEvent.getS3ImageUrl('howitworkimg-1.png')} className="img-fluid w-100" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container" name="SECTION_2">
                                        <div className="content">
                                            <div className="block-md">
                                                <div className="row mg-xs-0">
                                                    <div className="col-lg-6" name="SECTION_2_LEFT">
                                                        <figure className="bgOne" style={{
                                                        backgroundImage:"url("+`${(this.state.SECTION_2_LEFT && this.state.SECTION_2_LEFT.section_data && this.state.SECTION_2_LEFT.section_data[0] && this.state.SECTION_2_LEFT.section_data[0].url) ? this.state.SECTION_2_LEFT.section_data[0].url : ""}`+")"
                                                        }}>
                                                            <h2>{this.state.SECTION_2_LEFT && this.state.SECTION_2_LEFT.section_data && this.state.SECTION_2_LEFT.section_data[1] && this.state.SECTION_2_LEFT.section_data[1].text ? this.state.SECTION_2_LEFT.section_data[1].text : "01"}</h2>
                                                        </figure>

                                                    </div>
                                                    <div className="col-lg-6" name="SECTION_2_RIGHT">
                                                        <div className="d-flex align-items-center h-100">
                                                            <div className="blockcontent pd-l-35 blockcontentone">
                                                                <h3>
                                                                {this.state.SECTION_2_RIGHT && this.state.SECTION_2_RIGHT.section_data && this.state.SECTION_2_RIGHT.section_data[0] && this.state.SECTION_2_RIGHT.section_data[0].text ? this.state.SECTION_2_RIGHT.section_data[0].text : "Rent your apartment."}
                                                                </h3>
                                                                <strong>{this.state.SECTION_2_RIGHT && this.state.SECTION_2_RIGHT.section_data && this.state.SECTION_2_RIGHT.section_data[1] && this.state.SECTION_2_RIGHT.section_data[1].text ? this.state.SECTION_2_RIGHT.section_data[1].text : "Do you travel often and want to rent your entire place when you’re out?"} </strong>
                                                                <p> {this.state.SECTION_2_RIGHT && this.state.SECTION_2_RIGHT.section_data && this.state.SECTION_2_RIGHT.section_data[2] && this.state.SECTION_2_RIGHT.section_data[2].text ? this.state.SECTION_2_RIGHT.section_data[2].text : "A Studio or a One Bedroom is a great option."}"
                                                                </p>
                                                                <strong>{this.state.SECTION_2_RIGHT && this.state.SECTION_2_RIGHT.section_data && this.state.SECTION_2_RIGHT.section_data[3] && this.state.SECTION_2_RIGHT.section_data[3].text ? this.state.SECTION_2_RIGHT.section_data[3].text : "Do you only travel sometimes, but always want added STR income?"}</strong>
                                                                 <p>{this.state.SECTION_2_RIGHT && this.state.SECTION_2_RIGHT.section_data && this.state.SECTION_2_RIGHT.section_data[4] && this.state.SECTION_2_RIGHT.section_data[4].text ? this.state.SECTION_2_RIGHT.section_data[4].text : "If you want to Host while you’re home, a Two Bedroom is a great option, plus you can use the extra room as an office space when it’s not filled. Even better, a Three Bedroom gives you lots of options."}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mg-xs-0 mg-t-30 mg-b-30" name="SECTION_3">
                                                    <div className="col-lg-6" name="SECTION_3_LEFT">
                                                        <figure className="bgTwo" style={{
                                                        backgroundImage:"url("+`${(this.state.SECTION_3_LEFT && this.state.SECTION_3_LEFT.section_data && this.state.SECTION_3_LEFT.section_data[0] && this.state.SECTION_3_LEFT.section_data[0].url) ? this.state.SECTION_3_LEFT.section_data[0].url : ""}`+")"
                                                        }}>
                                                        <h2>{this.state.SECTION_3_LEFT && this.state.SECTION_3_LEFT.section_data && this.state.SECTION_3_LEFT.section_data[1] && this.state.SECTION_3_LEFT.section_data[1].text ? this.state.SECTION_3_LEFT.section_data[1].text : "02"}</h2>
                                                        </figure>

                                                    </div>
                                                    <div className="col-lg-6" name="SECTION_3_RIGHT">
                                                        <div className="d-flex align-items-center h-100">
                                                            <div className="blockcontent pd-l-35 blockcontenttwo">
                                                                <h3 className="md">
                                                                {this.state.SECTION_3_RIGHT && this.state.SECTION_3_RIGHT.section_data && this.state.SECTION_3_RIGHT.section_data[0] && this.state.SECTION_3_RIGHT.section_data[0].text ? this.state.SECTION_3_RIGHT.section_data[0].text : "Decorate your space."}
                                                                </h3>
                                                                <p>{this.state.SECTION_3_RIGHT && this.state.SECTION_3_RIGHT.section_data && this.state.SECTION_3_RIGHT.section_data[1] && this.state.SECTION_3_RIGHT.section_data[1].text ? this.state.SECTION_3_RIGHT.section_data[1].text : "Getting your apartment (and listing) up and running is super easy. The Kini Beach Haus Essentials Kit includes over 128 items to get your place stocked and ready to go. From sheets on the bed to silverware in the drawers, we’ve got you covered."}</p>
                                                                <p>{this.state.SECTION_3_RIGHT && this.state.SECTION_3_RIGHT.section_data && this.state.SECTION_3_RIGHT.section_data[2] && this.state.SECTION_3_RIGHT.section_data[2].text ? this.state.SECTION_3_RIGHT.section_data[2].text : "You can also go fully furnished with packages unique to each location, all hand-selected by local designers to make your place look and feel great, and give an authentic experience to your Guests."}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mg-xs-0" name="SECTION_4">
                                                    <div className="col-lg-6" name="SECTION_4_LEFT">
                                                        <figure className="bgThree" style={{
                                                        backgroundImage:"url("+`${(this.state.SECTION_4_LEFT && this.state.SECTION_4_LEFT.section_data && this.state.SECTION_4_LEFT.section_data[0] && this.state.SECTION_4_LEFT.section_data[0].url) ? this.state.SECTION_4_LEFT.section_data[0].url : ""}`+")"
                                                        }}>
                                                        <h2>{this.state.SECTION_4_LEFT && this.state.SECTION_4_LEFT.section_data && this.state.SECTION_4_LEFT.section_data[1] && this.state.SECTION_4_LEFT.section_data[1].text ? this.state.SECTION_4_LEFT.section_data[1].text : "03"}</h2>
                                                        </figure>

                                                    </div>
                                                    <div className="col-lg-6" name="SECTION_4_RIGHT">
                                                        <div className="d-flex align-items-center h-100">
                                                            <div className="blockcontent pd-l-35 blockcontentthree">
                                                                <h3>
                                                                {this.state.SECTION_4_RIGHT && this.state.SECTION_4_RIGHT.section_data && this.state.SECTION_4_RIGHT.section_data[0] && this.state.SECTION_4_RIGHT.section_data[0].text ? this.state.SECTION_4_RIGHT.section_data[0].text : "Create your listing."}
                                                                </h3>
                                                                <strong>{this.state.SECTION_4_RIGHT && this.state.SECTION_4_RIGHT.section_data && this.state.SECTION_4_RIGHT.section_data[1] && this.state.SECTION_4_RIGHT.section_data[1].text ? this.state.SECTION_4_RIGHT.section_data[1].text : "Digital key."}</strong>
                                                                <p> {this.state.SECTION_4_RIGHT && this.state.SECTION_4_RIGHT.section_data && this.state.SECTION_4_RIGHT.section_data[2] && this.state.SECTION_4_RIGHT.section_data[2].text ? this.state.SECTION_4_RIGHT.section_data[2].text : "Starbaux automatically creates a digital access key for your Guests that is activated only at Check In and deactivates automatically at Check Out."}</p>

                                                                <strong>{this.state.SECTION_4_RIGHT && this.state.SECTION_4_RIGHT.section_data && this.state.SECTION_4_RIGHT.section_data[3] && this.state.SECTION_4_RIGHT.section_data[3].text ? this.state.SECTION_4_RIGHT.section_data[3].text : "Housekeeping."}</strong>
                                                                 <p>{this.state.SECTION_4_RIGHT && this.state.SECTION_4_RIGHT.section_data && this.state.SECTION_4_RIGHT.section_data[4] && this.state.SECTION_4_RIGHT.section_data[4].text ? this.state.SECTION_4_RIGHT.section_data[4].text : "Starbaux alerts the Housekeeping staff when your Guest checks out and automatically cleans and sanitizes your apartment."}
                                                                </p>
                                                                <b>{this.state.SECTION_4_RIGHT && this.state.SECTION_4_RIGHT.section_data && this.state.SECTION_4_RIGHT.section_data[5] && this.state.SECTION_4_RIGHT.section_data[5].text ? this.state.SECTION_4_RIGHT.section_data[5].text : "Data & insights."}</b>
                                                                  <p>{this.state.SECTION_4_RIGHT && this.state.SECTION_4_RIGHT.section_data && this.state.SECTION_4_RIGHT.section_data[6] && this.state.SECTION_4_RIGHT.section_data[6].text ? this.state.SECTION_4_RIGHT.section_data[6].text : "Starbaux keeps track of your performance and shows you tons of data to help you optimize your success: from Average Daily Rates to keeping you in the loop on what days are earning the highest revenue."}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                             </section>
                        </div>
                </section>

                {/* Footer */}
                <CommonFooter propertySlug={this.state.property_slug} />
            </main>
        );
      }
  }
  export default HowItWorks