//     							                          //
//  Program: Inventory.jsx                                //
//  Application: KINI HTML                                //
//  Option: Home Sharing Listing                          //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Moment from "moment";
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import queryString from "query-string";
import MetaSEO from "../../../MetaSEO";
import ModalVideo from 'react-modal-videojs'
const queryParams = queryString.parse(location.search);
class Partnership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            add: 10,
            viewMode: 0,
            is_inactive: false,
            tableQueryString: "",
            customQueryString: "",
            showFilter: global.showFilter,
            input: {},
            UnitListing: [],
            errors: {},
            showAlertModal: false,
            selectedDate: Moment(new Date()).format("MM-DD-YYYY"),
            // Moment(new Date(date.value[0])).format("MM-DD-YYYY")
            moveoutDate: Moment(new Date()).format("MM-DD-YYYY"),
            alertModalType: "",
            alertModalTitle: "",
            alertModalMessage: "",
            filter_value: "",
            loading: false,
            showSpinner: false,
            guest: "",
            total_record: 0,
            confirm_back: 0,
            adultCount: 1,
            childCount: 0,
            bedroomcount: 1,
            bathCount: 0,
            infantCount: 0,
            petsCount: 0,
            bedroom: "",
            location: [],
            location_name: [],
            guestValue: "1 Guest",
            bedbathValue: "1 Bedroom + 1 Bathroom",
            bathroom: "",
            queryString: "",
            queryStringltr: "",
            property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
            slug: "",
            status_id: true,
            pathname: window.location.pathname,
            sec: "http://localhost:3000/kini/static/media/logo.8d8e6eeb6e92504d4242.png",
            property_name: localStorage.getItem("property_name"),
            brand_name: localStorage.getItem("brand_name"),
            metaKeywords: "rent your apartment, decorate your space, create your listing, home, realtor, houses for rent, homes for sale, property, property for sale, homes for rent, apartments for rent near me",
            metaDescription: "If your travel schedule means you won’t ever be at home with your guests, you might like a Studio or a One Bedroom. If you are home sometimes, but always want added STR income, a Two Bedroom or Three Bedroom would be perfect for you.OHAI makes it easy. Pick up our starter kit and you’ll be stocked with over 128 essentials — from sheets on the bed to silverware in the drawers.And, we offer fully furnished options with high end furnishings in a handful of local styles, created by local designers.Your app helps create and sync your listing across 45+ online travel agencies. The app gives you analytics on your listing, and automatically creates digital keys for guest reservations and schedules turnovers and sanitization when they check out.Pro-Host views available too.",
            locationSelected: false,
            // meta tags start
            title: "",
            favicon: "",
            keywords: global.keywords,
            description: global.description,
            url: window.location.href,
            image: "",
            fb_app_id: "",
            // meta tags end
            seoLoaded: false,

            // source: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerQA.mp4","website/video") + "?autoplay=1",
            // source1: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerOverview.mp4","website/video") + "?autoplay=1",
            source2: global.OnKeyPressEvent.getS3ImageUrl("OrionHausHomesharingProcess.mp4","website/video") + "?autoplay=1",
            source3: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyTourVideo.mp4","website/video") + "?autoplay=1",
            // source4: global.OnKeyPressEvent.getS3ImageUrl("OrionHausKeyAccess.mp4","website/video") + "?autoplay=1",
            // source5: global.OnKeyPressEvent.getS3ImageUrl("OrionHausResidentHomesharingKit.mp4","website/video") + "?autoplay=1",
            // show: false,
            // show1: false,
            show2: false,
            show3: false,
            // show4: false,
            show5: false,
        };
        this.openModal = this.openModal.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleChangelocation = this.handleChangelocation.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateChangeRange = this.handleDateChangeRange.bind(this);
        this.incrementAdult = this.incrementAdult.bind(this);
        this.decrementAdult = this.decrementAdult.bind(this);
        this.incrementbath = this.incrementbath.bind(this);
        this.decrementbath = this.decrementbath.bind(this);
        this.incrementBed = this.incrementBed.bind(this);
        this.decrementBed = this.decrementBed.bind(this);
        this.incrementchild = this.incrementchild.bind(this);
        this.decrementchild = this.decrementchild.bind(this);
        this.incrementinfant = this.incrementinfant.bind(this);
        this.decrementinfant = this.decrementinfant.bind(this);
        // this.incrementpets = this.incrementpets.bind(this);
        // this.decrementpets = this.decrementpets.bind(this);
        this.guestDetail = this.guestDetail.bind(this);
        this.bedbathDetail = this.bedbathDetail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.Setclosebath = this.Setclosebath.bind(this);
        this.Setclose = this.Setclose.bind(this);
    }
    componentDidMount() {
        this.guestDetail();
        this.getMetaData();
        this.getPartnershipCmsData();
        this.getBranddata();
        this.bedbathDetail();
        this.createQueryString();
        this.createQueryStringLtr();

    }

    /* CMS data getcode start */

    getMetaData = async () => {
        this.setState({ showSpinner: true, loading: true });
        var resData = {};
        var resDataArr = [];
        var cacheKeyName = global.level + "_PARTNERSHIP_META_DATA_";
        if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
            var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
            this.setState(data);
        }
        let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
        let res = await CmsDataService.getCmsMetaData("PARTNERSHIP", global.level, levelSlug);
        if (global.successStatus.includes(res.status)) {
            resData.title = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.title : "") : "";
            resData.favicon = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
            resData.keywords = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.keywords : "") : "";
            resData.description = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.description : "") : "";
            resData.url = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
            resData.image = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
            resData.fb_app_id = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.fb_app_id : "") : "";
        }
        global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
        this.setState(resData);
        await this.setState({
            showSpinner: false,
            loading: false,
            seoLoaded: true
        });
    }

    getPartnershipCmsData = async (queryString = "") => {
        this.setState({ showSpinner: true, loading: true });
        var resData = {};
        var resDataArr = [];
        let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
        var cacheKeyName = global.level + "_PARTNERSHIP_" + levelSlug;
        if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
            var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
            this.setState(data);
        }
        let res = await CmsDataService.getCmsData("PARTNERSHIP", global.level, levelSlug);
        if (global.successStatus.includes(res.status)) {
            resDataArr = res.data ? res.data : [];
            resDataArr.map((item, key) => {
                resData[item.section_name_slug] = item.section_data;
            });
        }
        await this.setState({
            showSpinner: false,
            loading: false
        });
        // console.log(resData, "part");
        global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
        this.setState(resData);
    }
    /* CMS data getcode end */

    handleChangelocation = async (event) => {
        let input = this.state.input;
        input[event.label] = event.value;
        await this.setState({ location: event.value, location_name: event.label, input: input, locationSelected: true });
        await this.createQueryString();
        await this.createQueryStringLtr();

    };

    handleDateChange = async (date, name) => {
        let input = this.state.input;
        let a = new Date(date.valueText);
        input.move_in_date = Moment(new Date(date.value)).format("MM-DD-YYYY");
        await this.setState({ selectedDate: input.move_in_date, input: input });
        await this.createQueryString();
    }

    handleDateChangeRange = async (date, name) => {
        let input = this.state.input;
        let a = new Date(date.valueText);
        input.move_in_date = Moment(new Date(date.value[0])).format("MM-DD-YYYY");
        input.move_out_date = Moment(new Date(date.value[1])).format("MM-DD-YYYY");
        await this.setState({ selectedDate: input.move_in_date, moveoutDate: input.move_out_date, input: input });
        await this.createQueryStringLtr();
    }

    guestDetail = () => {
        var message = "";
        message +=
            this.state.adultCount > 1
                ? this.state.adultCount + " Guests"
                : this.state.adultCount + " Guest";
        message += this.state.childCount
            ? this.state.childCount > 1
                ? ", " + this.state.childCount + " Childs"
                : ", " + this.state.childCount + " Child"
            : "";
        message += this.state.infantCount
            ? this.state.infantCount > 1
                ? ", " + this.state.infantCount + " Infants"
                : ", " + this.state.infantCount + " Infant"
            : "";
        message += this.state.petsCount
            ? this.state.petsCount > 1
                ? ", " + this.state.petsCount + " Pets"
                : ", " + this.state.petsCount + " Pet"
            : "";
        this.setState({ guestValue: message });
        // return message;
    }

    handleChange = (event, value) => {
        this.setState({ newcityList: value });
    }

    bedbathDetail = () => {
        var message = "";
        message +=
            this.state.bedroomcount > 1
                ? this.state.bedroomcount + " Bedrooms"
                : this.state.bedroomcount + " Bedroom";
        message += this.state.bathCount
            ? this.state.bathCount > 1
                ? ", " + this.state.bathCount + "Bathrooms"
                : ", " + this.state.bathCount + "Bathroom"
            : "";
        this.setState({ bedbathValue: message });
        // return message;
    }

    Setclosebath() {
        var unit = document.getElementById("dropdown-basic-bed");
        if (unit) {
            unit.click();
        }
    }

    createQueryString = () => {
        var queryString = "";
        queryString = `?city_name=${this.state.location_name}&city=${this.state.location}&checkin=${this.state.selectedDate}&furnished=${"unfurnished"}&numberOfbed=${this.state.bedroomcount}&numberOfbathroom=${this.state.bathCount}`;
        this.setState({ queryString: queryString });
    }

    createQueryStringLtr = () => {
        var queryStringltr = "";
        queryStringltr = `?city_name=${this.state.location_name}&city=${this.state.location}&checkin=${this.state.selectedDate}&checkout=${this.state.moveoutDate}&furnished=${"furnished"}&numberOfAdults=${this.state.adultCount}&numberOfChildren=${this.state.childCount}&numberOfInfants=${this.state.infantCount}`;
        this.setState({ queryStringltr: queryStringltr });
    }

    Setclose() {
        var unit = document.getElementById("dropdown-basic-bath");
        if (unit) {
            unit.click();
        }
    }

    async incrementbath() {
        await this.setState({ bathCount: this.state.bathCount + 1 });
        await this.createQueryString();
        await this.bedbathDetail();
    }

    async decrementbath() {
        if (this.state.bathCount === 0 || this.state.bathCount === 1) {
            await this.setState({ bathCount: 1 });
        } else {
            await this.setState({ bathCount: this.state.bathCount - 1 });
        }
        await this.createQueryString();
        await this.bedbathDetail();
    }

    async incrementBed() {
        await this.setState({ bedroomcount: this.state.bedroomcount + 1 });
        await this.createQueryString();
        await this.bedbathDetail();
    }

    async decrementBed() {
        if (this.state.bedroomcount === 0) {
            await this.setState({ bedroomcount: 0 });
        } else {
            await this.setState({ bedroomcount: this.state.bedroomcount - 1 });
        }
        await this.createQueryString();
        await this.bedbathDetail();
    }

    async incrementAdult() {
        await this.setState({ adultCount: this.state.adultCount + 1 });
        await this.createQueryStringLtr();
        await this.guestDetail();
    }

    async decrementAdult() {
        if (this.state.adultCount === 0 || this.state.adultCount === 1) {
            await this.setState({ adultCount: 1 });
        } else {
            await this.setState({ adultCount: this.state.adultCount - 1 });
        }
        await this.createQueryStringLtr();
        await this.guestDetail();
    }

    async incrementchild() {
        await this.setState({ childCount: this.state.childCount + 1 });
        await this.createQueryStringLtr();
        await this.guestDetail();
    }

    async decrementchild() {
        if (this.state.childCount === 0) {
            await this.setState({ childCount: 0 });
        } else {
            await this.setState({ childCount: this.state.childCount - 1 });
        }
        await this.createQueryStringLtr();
        await this.guestDetail();
    }

    async incrementinfant() {
        await this.setState({ infantCount: this.state.infantCount + 1 });
        await this.createQueryStringLtr();
        await this.guestDetail();
    }

    async decrementinfant() {
        if (this.state.infantCount === 0) {
            await this.setState({ infantCount: 0 });
        } else {
            await this.setState({ infantCount: this.state.infantCount - 1 });
        }
        await this.createQueryStringLtr();
        await this.guestDetail();
    }

    searchRedirect = (path) => {
        if (this.state.locationSelected) {
            window.location.href = "/" + path + (path === "floorplan-listing" ? this.state.queryString : this.state.queryStringltr)
        } else {
            var unit = document.getElementById("autocomplete-" + path);
            if (unit) {
                unit.click();
            }
        }
    }

    // async incrementpets() {
    //   await this.setState({ petsCount: this.state.petsCount + 1 });
    //   // await this.createQueryString();
    //   await this.guestDetail();
    // }

    // async decrementpets() {
    //   if (this.state.petsCount === 0) {
    //     await this.setState({ petsCount: 0 });
    //   } else {
    //     await this.setState({ petsCount: this.state.petsCount - 1 });
    //   }
    //   // await this.createQueryString();
    //   await this.guestDetail();
    // }

    getBranddata = async (queryString = "") => {

        this.setState({ showSpinner: true, loading: true });
        var resData = [];
        var city = [];
        let inputData = {
            brand_seo_slug: ""
        }
        let res = await CmsDataService.getBrandData(inputData);
        if (global.successStatus.includes(res.status)) {
            resData = res.data ? res.data : [];
            city = res.other_data ? res.other_data : [];
        }
        city.map((item, index) => {
            item.label = city[index].city.city_name;
            item.value = city[index].city.city_id;
        });
        this.setState({ newcityList: city });
        this.setState({ resData: resData });
    }

    changeHandler = (event) => {
        let input = this.state.input;
        let value = event.target.value;
        input[event.target.name] = value;
        this.setState({ [event.target.name]: value, input: input });
    }

    scrollTop = () => {
        const section = document.querySelector('.Brandmainpage');
        section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }

    openModal(src) {
        this.setState({ isOpen: true })
        this.setState({ currentVideoUrl: src })
    }

    showModal = (val = false, param = 'show') => {
        this.setState({ [param]: val });
    };

    render() {

        const inputProps = {
            className: 'md-mobile-picker-input',
            placeholder: 'Add dates'
        };

        let counter = 0;
        console.log("this.state.description :", this.state.description);
        return (


            <main className="oh-template Brandmainpage leasing">
                {/* Meta tag start */}
                {
                    this.state.seoLoaded &&
                    <MetaSEO
                        title={this.state.title}
                        favicon={this.state.favicon}
                        keywords={this.state.keywords}
                        description={this.state.description}
                        url={this.state.url}
                        image={this.state.image}
                        fb_app_id={this.state.fb_app_id}
                        noIndex="false"
                        author={"OHAI"}
                    />
                }
                {/* Meta tag end */}
                {/* Header */}
                <CommonHeader propertySlug={this.state.property_slug} />

                {/* middle */}
                <section className="middle" name="SECTION_1">
                    <div className="container-fluid p-0">
                        <section className="partner_banner"
                            style={{ backgroundImage: `url(${this.state.PARTNERSHIP_SECTION_1 ? ((this.state.PARTNERSHIP_SECTION_1[1]) ? this.state.PARTNERSHIP_SECTION_1[1].url : "") : ""})` }}
                        >
                            <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-25 pd-r-25">
                                <h1 className="text-center pd-l-50 pd-r-50"> {this.state.PARTNERSHIP_SECTION_1 ? ((this.state.PARTNERSHIP_SECTION_1[0]) ? this.state.PARTNERSHIP_SECTION_1[0].text : "OHAI is the best partner for multifamily flexible living") : "OHAI is the best partner for multifamily flexible living"}  </h1>
                                <a href="mailto: sales@orionhaus.com" target="_blank" className="becomebtn"><button>Become a partner</button></a>
                            </div>
                        </section>

                        {/* new add section */}
                        <div className="newpartnercontent">
                            {/* One section */}
                                <section className="mainListing partnerships_heading ">
                                        <div className="container">
                                            <div className="col-12 col-md-12">
                                                {/* <h2> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[0]) ? this.state.PARTNERSHIP_SECTION_2[0].text : "Homesharing is a must-have amenity.") : "Homesharing is a must-have amenity."}  </h2> */}
                                                <h2>OHAI partners with leading multifamily owners to deploy technology that operationalizes resident homesharing within their portfolios of multifamily assets.</h2>
                                                {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration.") : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration."} </span> */}
                                                {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "OHAI partners with leading multifamily owners to deploy technology that operationalizes resident homesharing within their portfolios of multifamily assets.") : "OHAI partners with leading multifamily owners to deploy technology that operationalizes resident homesharing within their portfolios of multifamily assets."} </span> */}
                                            </div>
                                        </div>
                                </section>
                                <section className="howdoesitworkSection partnerships-platform pb-0">
                                    <div className="container">
                                        <div className="row m-0">
                                            <div className="col-md-12">
                                                <div className="row blockone justify-content-center">
                                                    <div className="col-lg-6 col-sm-12 bd-r">
                                                        <div className="howwork">
                                                            <div className="mobviewhowwork partnerships-cnt">
                                                                <div className="partner_cnt_box">
                                                                    {/* <h3>{this.state.PARTNERSHIP_SECTION_3 ? ((this.state.PARTNERSHIP_SECTION_3[2]) ? this.state.PARTNERSHIP_SECTION_3[2].text : "Traditional Leases") : "Traditional Leases"}</h3> */}
                                                                    {/* <h3>Traditional Leases</h3> */}
                                                                    {/* <p>{this.state.PARTNERSHIP_SECTION_3 ? ((this.state.PARTNERSHIP_SECTION_3[3]) ? this.state.PARTNERSHIP_SECTION_3[3].text : "Building-wide residential homesharing allows all residents with traditional 12-month leases to live and travel on their own terms.") : "Building-wide residential homesharing allows all residents with traditional 12-month leases to live and travel on their own terms."} </p> */}
                                                                    <p>Rooted in the traditional 12-month lease, building-wide residential homesharing, allows residents to host a guest when they are not at home. As a result, you can anticipate an increase in NOI through additional income from the homesharing revenue split, a potential reduction in new resident concessions, and possible rent increases. Additionally, homesharing is a competitive differentiator that will drive new resident leasing and help retain existing residents.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-12">
                                                        <div className="howwork partnerships-img">
                                                            <figure>
                                                                {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('traditional_leases.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('traditional_leases.jpg')} /> */}
                                                                {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('traditional_leases.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('traditional_leases.jpg')} /> */}
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('traditional_leasesnew.jpg')} alt="Traditional Leases" />
                                                           
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            {/* End One section */}
                            {/* Two section */}
                                <section className="mainListing partnerships_heading partnerships_heading1">
                                    <div className="container">
                                        <div className="col-12 col-md-12">
                                            {/* <h2> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[0]) ? this.state.PARTNERSHIP_SECTION_2[0].text : "Homesharing is a must-have amenity.") : "Homesharing is a must-have amenity."}  </h2> */}
                                            {/* <h2>Homesharing is a must-have amenity. </h2> */}
                                            {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration.") : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration."} </span> */}
                                            {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : " OHAI is an app that apartment community residents can use to homeshare. The OHAI app makes it easy to host, to stay, and to always feel at home.") : " OHAI is an app that apartment community residents can use to homeshare. The OHAI app makes it easy to host, to stay, and to always feel at home."} </span> */}
                                            <h2>OHAI is an app that apartment community residents can use to homeshare. The OHAI app makes it easy to host, to stay, and to always feel at home.</h2>
                                        </div>
                                    </div>
                                </section>
                                <section className="howdoesitworkSection partnerships-platform">
                                        <div className="container">
                                            <div className="row m-0">
                                                <div className="col-md-12">
                                                    <div className="row blocktwo justify-content-center">
                                                        <div className="col-lg-4 col-sm-12">
                                                            <div className="howwork partnerships-img2">
                                                                {/* <figure>
                                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('furnished-unit-strs.png')} alt="Furnished Unit STRs" title="Furnished Unit STRs" />
                                                                </figure> */}

                                                                <figure>
                                                                    {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('traditional-leases.png')) : global.OnKeyPressEvent.getS3ImageUrl('traditional-leases.png')} /> */}
                                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('traditional-leases.png')} alt="What is homesharing?" />
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-sm-12 bd-r">
                                                            <div className="howwork">
                                                                <div className="mobviewhowwork partnerships-cnt">
                                                                    <div className="partner_cnt_box">
                                                                        <h3>What is homesharing?</h3>
                                                                        <p>Think of it like Airbnb'ing. It's when your residents are allowed to sublease on a short-term basis to travelers. Residents love homesharing for many reasons, but they especially love being able to earn additional income on their place when they're not using it.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </section>
                            {/* End Two section */}
                            {/* Three section */}
                            <section className="mainListing partnerships_heading partnerships_heading2">
                                        <div className="container">
                                            <div className="col-12 col-md-12">
                                                <h2> THE BEST PART? </h2>
                                                {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration.") : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration."} </span> */}
                                                {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "Your property receives a share from every night booked. Generally earning you an extra $100 per unit per months.") : "Your property receives a share from every night booked. Generally earning you an extra $100 per unit per months."} </span> */}
                                                <p className="text-center color-white">YOUR PROPERTY RECEIVES A SHARE FROM EVERY NIGHT BOOKED, GENERALLY EARNING YOU AN EXTRA $100 PER UNIT PER MONTH.</p>
                                            </div>
                                        </div>
                            </section>
                            {/* End Three section */}
                            {/* Four section */}
                            <section className="ltr_video_sec partnership_ltr_video_sec">
                            <div className="container-fluid ">
                                <div className="row text-center d-flex justify-content-center">
                                    <h2>The benefits of homesharing are endless</h2>
                                    <p>OHAI is CONTROLLED homesharing, it’s not a total free-for-all.</p>
                                    <a href="mailto: sales@orionhaus.com" className="becomebtn"><button>Become a partner</button></a>
                                </div>
                            </div>
                            <div className="container-fluid pd-t-80 px-0">
                                <div className="row m-0">
                                    <div className="col-lg-6 col-sm-12 p-0">
                                        <div className="video_step">
                                            <h3 className="video_main_head">Controlled homesharing with OHAI means</h3>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex video_earn_img">
                                                        <figure>
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('concierge.png')} alt="" />
                                                        </figure>
                                                        <div className="video_earn">
                                                            <h5>Concierge support 24/7</h5>
                                                            <p>Residents, guests, and property managers have access to OHAI’s 24/7 Concierge support.</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex video_earn_img">
                                                        <figure>
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('autokey.png')} alt="" />
                                                        </figure>
                                                        <div className="video_earn">
                                                            <h5>Automated key access</h5>
                                                            <p>The OHAI app automates access so residents, guests, and housekeeping all have automated key access at the right times.</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex video_earn_img">
                                                        <figure>
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('autohouse.png')} alt="" />
                                                        </figure>
                                                        <div className="video_earn">
                                                            <h5>Automated housekeeping</h5>
                                                            <p>The OHAI app automatically schedules the cleaners, provides their checklists, and checks their completion status. Any complications are managed by your OHAI concierge.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 position-relative video_ltr_position">
                                        <div className="video_earn_gallery">
                                            <div className="video_pop_img">
                                                <ModalVideo
                                                    id={(new Date() * 1).toString()}
                                                    source={this.state.source2}
                                                    poster={global.OnKeyPressEvent.getS3ImageUrl('OrionHausHomesharingProcessvideo.JPG',"website/video")}
                                                    show={this.state.show2}
                                                    showModal={() => this.showModal(true, 'show2')}
                                                    handleClose={() => this.showModal(false, 'show2')}
                                                />
                                            </div>
                                        </div>

                                        {/* New video gallery design */}
                                        {/* <div className="videoSection">
                                            <div className="video_earn_gallery"> */}
                                        {/* <div className="video_pop_img1">
                                <ModalVideo
                                  id={(new Date() * 1).toString()}
                                  source={this.state.source1}
                                  poster={talkimg}
                                  show={this.state.show1}
                                  showModal={() => this.showModal(true, 'show1')}
                                  handleClose={() => this.showModal(false, 'show1')}
                                />
                              </div> */}
                                        {/* <div className="video_pop_img2">
                                <ModalVideo
                                  id={(new Date() * 1).toString()}
                                  source={this.state.source5}
                                  poster={kitvideo}
                                  show={this.state.show5}
                                  showModal={() => this.showModal(true, 'show5')}
                                  handleClose={() => this.showModal(false, 'show5')}
                                />
                              </div> */}
                                        {/* <div className="video_pop_img3">
                                                    <ModalVideo
                                                        id={(new Date() * 1).toString()}
                                                        source={this.state.source2}
                                                        poster={global.OnKeyPressEvent.getS3ImageUrl('OrionHausHomesharingProcessvideo.JPG',"website/video")}
                                                        show={this.state.show2}
                                                        showModal={() => this.showModal(true, 'show2')}
                                                        handleClose={() => this.showModal(false, 'show2')}
                                                    />
                                                </div>
                                                {/* <div className="video_pop_img4">
                                                    <ModalVideo
                                                        id={(new Date() * 1).toString()}
                                                        source={this.state.source3}
                                                        poster={global.OnKeyPressEvent.getS3ImageUrl('TheOrionHausHomesharingProgramOverviewvideo.JPG',"website/video")}
                                                        show={this.state.show3}
                                                        showModal={() => this.showModal(true, 'show3')}
                                                        handleClose={() => this.showModal(false, 'show3')}
                                                    />
                                                </div> */}
                                        {/* <div className="video_pop_img5">
                                <ModalVideo
                                  id={(new Date() * 1).toString()}
                                  source={this.state.source}
                                  poster={propmanager}
                                  show={this.state.show}
                                  showModal={() => this.showModal(true, 'show')}
                                  handleClose={() => this.showModal(false, 'show')}
                                />
                              </div> */}
                                        {/* </div>
                                        </div> */}
                                        {/* End New video gallery design */}
                                    </div>
                                </div>
                            </div>
                            </section>
                            {/* End Four section */}

                             {/* Five section */}
                             <section className="mainListing partnerships_heading ">
                                        <div className="container">
                                            <div className="col-12 col-md-12">
                                                {/* <h2> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[0]) ? this.state.PARTNERSHIP_SECTION_2[0].text : "Our app controls access for any building") : "Our app controls access for any building"}  </h2> */}
                                                <h2>Our app controls access for any building </h2>
                                                {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration.") : "OHAI provides a solution to add homesharing into multifamily buildings  —through a controlled and automated environment — and powered by an STR PMS purpose-built for multifamily integration."} </span> */}
                                                {/* <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "The OHAI app controls access with direct integrations with Latch digital locks, Keycafé for metal keys, key fobs, or key cards, and code storage for key pad locks.") : "The OHAI app controls access with direct integrations with Latch digital locks, Keycafé for metal keys, key fobs, or key cards, and code storage for key pad locks."} </span> */}
                                                <p>The OHAI app controls access with direct integrations with Latch digital locks, Keycafé for metal keys, key fobs, or key cards, and code storage for key pad locks.</p>
                                            </div>
                                        </div>
                             </section>
                            <section className="howdoesitworkSection partnerships-platform appcontrols">
                                <div className="container">
                                        <div className="col-md-12">
                                            <div className="row m-0">
                                                <div className="col-12 col-sm-6  col-sm-4 col-lg-2">
                                                    <div className="appcontrolsimg">
                                                        <figure>
                                                            {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('app_1.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('app_1.jpg')} /> */}
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('app_1new.jpg')} alt="UNLOCK WITH THE IOS APP" />
                                                        </figure>
                                                        <span>UNLOCK WITH THE IOS APP</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6  col-sm-4 col-lg-2">
                                                    <div className="appcontrolsimg">
                                                        <figure>
                                                            {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('app_2.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('app_2.jpg')} /> */}
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('app_2new.jpg')} alt="UNLOCK WITH THE ANDROID APP" />
                                                        </figure>
                                                        <span>UNLOCK WITH THE ANDROID APP</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6  col-sm-4 col-lg-2">
                                                    <div className="appcontrolsimg">
                                                        <figure>
                                                            {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('app_3.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('app_3.jpg')} /> */}
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('app_3new.jpg')} alt="UNLOCK WITH A METAL KEY" />
                                                        </figure>
                                                        <span>UNLOCK WITH A METAL KEY</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6  col-sm-4 col-lg-2">
                                                    <div className="appcontrolsimg">
                                                        <figure>
                                                            {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('app_4.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('app_4.jpg')} /> */}
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('app_4new.jpg')} alt="UNLOCK WITH A KEY FOB" />
                                                        </figure>
                                                        <span>UNLOCK WITH A KEY FOB</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6  col-sm-4 col-lg-2">
                                                    <div className="appcontrolsimg">
                                                        <figure>
                                                            {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('app_5.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('app_5.jpg')} /> */}
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('app_5new.jpg')} alt="UNLOCK WITH A KEY CARD" />
                                                        </figure>
                                                        <span>UNLOCK WITH A KEY CARD</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6  col-sm-4 col-lg-2">
                                                    <div className="appcontrolsimg">
                                                        <figure>
                                                            {/* <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('app_6.jpg')) : global.OnKeyPressEvent.getS3ImageUrl('app_6.jpg')} /> */}
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('app_6new.jpg')} alt="UNLOCK WITH A DOOR CODE" />
                                                        </figure>
                                                        <span>UNLOCK WITH A DOOR CODE</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </section>
                            {/* End Five section */}

                             {/* Six section */}
                                {/* <section className="table_compare_sec">
                                
                                <div className="container">
                                <div className="row">
                                    <div className="tbl_cmp_heading">
                                        <h3 className="oh_tbl_heading">Add homeshare with peace of mind</h3>
                                        <p className="oh_tbl_para">OHAI is a superior homesharing program.</p>
                                    </div>
                                    <div className="homesharingmadeeasy">
                                    <div className="row m-0">
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('calendar_management.svg')} alt="" />
                                                    </figure>
                                                    <span> Calendar management</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('automated_key_access.svg')} alt="" />
                                                    </figure>
                                                    <span> Automated key access</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('automated_housekeeper_scheduling.svg')} alt="" />
                                                    </figure>
                                                    <span> Automated housekeeper scheduling</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('automated_messages.svg')} alt="" />
                                                    </figure>
                                                    <span> Automated messages</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('automated_guest_screening.svg')} alt="" />
                                                    </figure>
                                                    <span> Automated guest screening</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('automated_house_manual.svg')} alt="" />
                                                    </figure>
                                                    <span> Automated house manual</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('automated_property_photos.svg')} alt="" />
                                                    </figure>
                                                    <span> Automated property photos</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <div className="block">
                                                <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('direct_booking_site.svg')} alt="" />
                                                    </figure>
                                                    <span> Direct Booking site</span>
                                                </div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                </section> */}
                             {/* End Six section */}
                            {/* Sevan section */}
                             <section className="table_compare_sec">
                            <div className="container">
                                <div className="row m-0">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-10">
                                        <div className="tbl_cmp_heading">
                                            <h2 className="oh_tbl_heading">Add homeshare with peace of mind</h2>
                                            <p className="oh_tbl_para text-center pd-b-50">OHAI is a superior homesharing program.</p>
                                        </div>
                                        {/* <div className="table_oh">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>
                                                            <p>Other programs</p>
                                                        </th>
                                                        <th>
                                                            <p >OHAI</p>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            24/7 Concierge support
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Automated key access
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Housekeeping management
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Listing oversight
                                                        </th>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Resident resources
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Limitations on homesharing
                                                        </th>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}

                                        <div className="homesharingmadeeasy">
                                            <div className="row m-0 justify-content-center">
                                                    <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                                    <div className="block">
                                                        <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-13.png')} alt="" />
                                                            </figure>
                                                            <span>24/7 Concierge support</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                                    <div className="block">
                                                        <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('homes_2.png')} alt="" />
                                                            </figure>
                                                            <span> Automated key access</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                                    <div className="block">
                                                        <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-9.png')} alt="" />
                                                            </figure>
                                                            <span> Housekeeping management</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                                    <div className="block">
                                                        <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-10.png')} alt="" />
                                                            </figure>
                                                            <span> Listing oversight</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                                    <div className="block">
                                                        <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-11.png')} alt="" />
                                                            </figure>
                                                            <span> Resident resources</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                                    <div className="block">
                                                        <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-12.png')} alt="" />
                                                            </figure>
                                                            <span> Limitations on homesharing</span>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                            </section>
                            {/* End Sevan section */} 
                             {/* Eight section */}
                             <section className="brandParent ltr_live_trav casestudies">
                            <div className="container">
                                <h2>OHAI case studies deliver positive NOI time and again</h2>
                            </div>
                            <section className="brandBlockfour">
                                <div className="container position-relative">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="content">
                                                <h3>OHAI rapidly turned around a historically under performing asset.</h3>
                                                <p>ADDED +$8M TO EXIT VALUE IN 7 WEEKS</p>
                                                <p>ADDED +$200/AVG PER UNIT PER MONTH IN HOMESHARING INCOME</p>
                                                <p>ADDED +10%/AVG PER UNIT TO RENT VALUE</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl("orion_haus_rapidly_turned_around.jpg")} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="brandBlockthree">
                                <div className="container position-relative">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl("orion_haus_fillednew.jpg")} />
                                            </figure>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="content">
                                                <h3>OHAI filled 100% of available occupancy with homesharers.</h3>
                                                <p>FILLED 100% OF AVAILABLE OCCUPANCY WITH HOMESHARING TENANTS IN 7 DAYS.</p>
                                                <p>SCOTTSDALE IS AN EXTREMELY COMPETITIVE MARKET WITH ANOTHER AIRBNB-FRIENDLY BUILDING LESS THAN A MILE AWAY.</p>
                                                <p>ADDED +1.8M IN INCREMENTAL REVENUE IN THE FIRST WEEK.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="brandBlockfour">
                                <div className="container position-relative">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="content">
                                                <h3>OHAI increased NOI $10,000/ per unit annually</h3>
                                                <p>79% INCREASE IN EXIT VALUE IN 7 MONTHS</p>
                                                <p>RENTS ARE UP 54%</p>
                                                <p>RENTS ARE +39% HIGH THAN UNDERWRITING </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl("orion_haus_increased.jpg")} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </section>
                          </section>

                             {/* End Eight section */} 
                        </div>

                        {/* end new add section */}



                        



                        {/* <section className="mainListing partnerships_heading">
                            <div className="container">
                                <div className="col-12 col-md-12">
                                    <h2> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[0]) ? this.state.PARTNERSHIP_SECTION_2[0].text : "Homesharing is a must-have amenity.") : "Homesharing is a must-have amenity."}  </h2>
                                    <span> {this.state.PARTNERSHIP_SECTION_2 ? ((this.state.PARTNERSHIP_SECTION_2[1]) ? this.state.PARTNERSHIP_SECTION_2[1].text : "OHAI partners with leading multifamily owners to deploy technology that operationalizes resident homesharing within their portfolios of multifamily assets.") : "OHAI partners with leading multifamily owners to deploy technology that operationalizes resident homesharing within their portfolios of multifamily assets."} </span>
                                </div>
                            </div>
                        </section> 

                        <section className="howdoesitworkSection partnerships-platform">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row blockone">
                                            <div className="col-lg-6 col-sm-12 bd-r">
                                                <div className="howwork">
                                                    <div className="mobviewhowwork partnerships-cnt">
                                                        <div className="partner_cnt_box">
                                                            <h3>{this.state.PARTNERSHIP_SECTION_3 ? ((this.state.PARTNERSHIP_SECTION_3[2]) ? this.state.PARTNERSHIP_SECTION_3[2].text : "Traditional Leases") : "Traditional Leases"}</h3>
                                                           
                                                                <p>{this.state.PARTNERSHIP_SECTION_3 ? ((this.state.PARTNERSHIP_SECTION_3[3]) ? this.state.PARTNERSHIP_SECTION_3[3].text : "Rooted in the traditional 12-month lease, buildingwide residential homesharing, allows residents to host a guest when they are not at home. As a result, you can anticipate an increase in NOI through additional income from the homesharing revenue split, a potential reduction in new resident concessions, and possible rent increases. Additionally, homesharing is a competitive differentiator that will drive new resident leasing and help retain existing residents.") : "Rooted in the traditional 12-month lease, buildingwide residential homesharing, allows residents to host a guest when they are not at home. As a result, you can anticipate an increase in NOI through additional income from the homesharing revenue split, a potential reduction in new resident concessions, and possible rent increases. Additionally, homesharing is a competitive differentiator that will drive new resident leasing and help retain existing residents."} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="howwork partnerships-img">
                                                    <figure>
                                                        <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('traditional-leases.png')) : global.OnKeyPressEvent.getS3ImageUrl('traditional-leases.png')} />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row blocktwo">
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="howwork partnerships-img2">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('furnished-unit-strs.png')} alt="Furnished Unit STRs" title="Furnished Unit STRs" />
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-12 bd-r">
                                                <div className="howwork">
                                                    <div className="mobviewhowwork partnerships-cnt">
                                                        <div className="partner_cnt_box">
                                                            <h3>Furnished Unit STRs</h3>
                                                            <p>Large increases to the bottom line, consistent brand standards, and the ability to host flexible living residents.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row blockthree">
                                            <div className="col-lg-6 col-sm-12 bd-r">
                                                <div className="howwork">
                                                    <div className="mobviewhowwork partnerships-cnt">
                                                        
                                                        <div className="partner_cnt_box">
                                                            <h3>Multifamily STR PMS</h3>
                                                            <p>OHAI AI is the only short-term rental PMS purpose built for multifamily PMS integration.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="howwork partnerships-img3">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('multifamily-strpms.png')} alt="Multifamily STR PMS" title="Multifamily STR PMS" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}










                        {/* <section className="ltr_video_sec partnership_ltr_video_sec">
                            <div className="container-fluid ">
                                <div className="row text-center d-flex justify-content-center">
                                    <h2>The benefits of homesharing are endless</h2>
                                    <p>OHAI is CONTROLLED homesharing, it’s not a total free-for-all.</p>
                                    <a href="mailto: sales@orionhaus.com" className="becomebtn"><button>Become a partner</button></a>
                                </div>
                            </div>
                            <div className="container-fluid pd-t-80">
                                <div className="row">
                                    <div className="col-md-6 p-0">
                                        <div className="video_step">
                                            <h3 className="video_main_head">Controlled homesharing with OHAI means</h3>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex video_earn_img">
                                                        <figure>
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('concierge.png')} alt="" />
                                                        </figure>
                                                        <div className="video_earn">
                                                            <h5>Concierge support 24/7</h5>
                                                            <p>Residents, guests, and property managers have access to OHAI’s 24/7 Concierge support.</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex video_earn_img">
                                                        <figure>
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('autokey.png')} alt="" />
                                                        </figure>
                                                        <div className="video_earn">
                                                            <h5>Automated key access</h5>
                                                            <p>The OHAI app automates access so residents, guests, and housekeeping all have automated key access at the right times.</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex video_earn_img">
                                                        <figure>
                                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('autohouse.png')} alt="" />
                                                        </figure>
                                                        <div className="video_earn">
                                                            <h5>Automated housekeeping</h5>
                                                            <p>The OHAI app automatically schedules the cleaners, provides their checklists, and checks their completion status. Any complications are managed by your OHAI concierge.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 position-relative video_ltr_position">
                                        <div className="video_earn_gallery">
                                            <div className="video_pop_img">
                                                <ModalVideo
                                                    id={(new Date() * 1).toString()}
                                                    source={this.state.source2}
                                                    poster={global.OnKeyPressEvent.getS3ImageUrl('OrionHausHomesharingProcessvideo.JPG',"website/video")}
                                                    show={this.state.show2}
                                                    showModal={() => this.showModal(true, 'show2')}
                                                    handleClose={() => this.showModal(false, 'show2')}
                                                />
                                            </div>
                                        </div>

                                       
                                    </div>
                                </div>
                            </div>
                        </section> */}




                        {/* <section className="table_compare_sec">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-10">
                                        <div className="tbl_cmp_heading">
                                            <h3 className="oh_tbl_heading">Add homeshare with peace of mind</h3>
                                            <p className="oh_tbl_para">OHAI is a superior homesharing program.</p>
                                        </div>
                                        <div className="table_oh">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>
                                                            <p>Other programs</p>
                                                        </th>
                                                        <th>
                                                            <p >OHAI</p>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            24/7 Concierge support
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Automated key access
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Housekeeping management
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Listing oversight
                                                        </th>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Resident resources
                                                        </th>
                                                        <td>

                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th >
                                                            Limitations on homesharing
                                                        </th>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                        <td>
                                                            <figure>
                                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                                                            </figure>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                        </section> */}


                        {/* <section className="brandParent ltr_live_trav">
                            <div className="container">
                                <h2>Your residents want to live and travel on their own terms. Flexibility is key, and
                                    OHAI gives residents the ability to monetize their apartments while
                                    traveling for work or heading out for the weekend.</h2>
                            </div>
                            <section className="brandBlockthree">
                                <div className="container position-relative">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl("brandblock-1.png")} />
                                            </figure>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="content">
                                                <h3>Flexible living at its best</h3>
                                                <p>Flexible living at an OHAI-partnered community is super easy. Experience
                                                    what it’s like to let your residents make money when they’re away traveling for
                                                    work, heading out for vacation, or just spending time at a friend’s place over the
                                                    weekend. We make it easy for them to live better.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="brandBlockfour">
                                <div className="container position-relative">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="content">
                                                <h3>Professionally managed.</h3>
                                                <p>OHAI-partnered buildings actually provide residents service. The Orion
                                                    Haus app will automatically provide secure access to guests and housekeepers,
                                                    will schedule turnovers with the cleaning crew, and provide property managers,
                                                    residents, and guests 24/7 concierge support —so you’re always taken care of.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl("brandblock-2.png")} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="brandBlockthree">
                                <div className="container position-relative">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl("brandblock-3.png")} />
                                            </figure>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="content">
                                                <h3>Homesharing kits.</h3>
                                                <p>Homesharing is hard, but not with OHAI. OHAI provides easy ways
                                                    for residents to get started with simple suggestions on everything they need to get
                                                    started. Residents not sure what towels to get? OHAI has them covered.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section> */}
                        <section className="ltr_partnered_sec">
                            <div className="container-fluid- container">
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <div className="partnered_img">
                                            <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('homesharingprogram.jpg')} alt="" />
                                                {/* <img src={this.state.property_slug === "merino-scottsdale" ? global.OnKeyPressEvent.getS3ImageUrl('homesharingprogram.jpg') : this.state.property_main_image} alt="" /> */}
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="partnered_cnt">
                                            <h3 className="part_head">
                                                Ready to add controlled homesharing to your community?
                                            </h3>
                                            <p className="part_ltr_para">Let’s get connected.</p>
                                            <a href="mailto: sales@orionhaus.com" className="becomebtn"><button>Become a partner</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>



                        {/* explor articles section */}

                        <section className="expl_artls_sec">
                            <div className="container">
                                <div className="row m-0">
                                    <div className="col-12">
                                        <div className="artls_head">
                                            <h2 className="art_heading">
                                                Explore resource center articles
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/how-to-minimize-risk-in-your-airbnb-friendly-apartment-building" target="_blank" className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('how-to-minimize-risk.webp')} alt="" title="How to minimize risk in your Airbnb-friendly apartment building?" />
                                                    </figure>
                                                </div>
                                                <p>How to minimize risk in your Airbnb-friendly apartment building?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/how-to-stabilize-rent-in-a-down-market" target="_blank" className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('how-to-stabilize-rent.webp')} alt="" title="How to stabilize rent in a down market?" />
                                                    </figure>
                                                </div>
                                                <p>How to stabilize rent in a down market?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/Is-my-apartment-building-a-good-fit-for-airbnb-and-vrbo-activity" target="_blank" className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('is-my-apartment-building.webp')} alt="" title="Is my apartment building a good fit for Airbnb and Vrbo activity?" />
                                                    </figure>
                                                </div>
                                                <p>Is my apartment building a good fit for Airbnb and Vrbo activity?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="artls_more">
                                            <button>More articles</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* explor articles section end */}



                        <section className="hostingFeatures">
                            <div className="container">
                                <h2>Hosting features</h2>
                                <p>OHAI amenities for you and your guests.</p>
                            </div>
                            <div className="container-fluid p-0">
                                <Swiper
                                    slidesPerView={1}
                                    // spaceBetween={0}
                                    slidesPerGroup={1}
                                    loop={true}
                                    loopFillGroupWithBlank={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Navigation]}
                                    className="mySwiper"
                                    breakpoints={{
                                        "@0.00": {
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        "@0.75": {
                                            slidesPerView: 2,
                                            spaceBetween: 0,
                                        },
                                        "@1.00": {
                                            slidesPerView: 3,
                                            spaceBetween: 0,
                                        },
                                        "@1.50": {
                                            slidesPerView: 4,
                                            spaceBetween: 0,
                                        },
                                    }}
                                >
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-1.webp")} alt="Full Time Hosts" title="Full Time Hosts" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>Full-time hosts.</span>
                                                        <b>Think of them as your OHAI concierge team. They’re here to make your listing sparkle, and make sure your guests have a seamless experience. </b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-2.webp")} alt="Digital Locks" title="Digital Locks" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>Digital locks.</span>
                                                        <b>Contactless. Bluetooth. Digital. These locks work with your and your guests’ phones. Access and entry is super easy through your OHAI app.</b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-3.webp")} alt="App With Insights" title="App With Insights" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>App with insights.</span>
                                                        <b>Earnings viewer. Integrated listing and calendar management. Automatically scheduled cleanings. Notifications when your guests arrive and leave. Business analytics dashboard to check your performance. On-site staff messaging. Events calendar. Tax payment reminders.</b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-4.webp")} alt="Ultimate Experiences" title="Ultimate Experiences" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>Ultimate experiences.</span>
                                                        <b>Bike-share. Scooter-share. Moke rentals. Yacht charters. Coffee carts in the morning …and gelato carts at night (seriously).</b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-5.webp")} alt="Design Packages" title="Design Packages" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>Design packages.</span>
                                                        <b>Hand-selected unique design packages for rent. Great style, and guess what? If anything gets damaged (by you or your guests) you can get a replacement to your unit in 24 hours.</b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-6.webp")} alt="On Site Host Supply Shop" title="On Site Host Supply Shop" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>On-site host supply shop.</span>
                                                        <b>With anything you or your guests could need (or want…). Bed and bath linens. Scented candles and room sprays. Shampoo, conditioner, bodywash, lotion. Coffee mugs. Bottled water. Notebooks and stationery.</b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-7.webp")} alt="Resort Pool & Sun Deck" title="Resort Pool & Sun Deck" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>Resort pool & sun deck.</span>
                                                        <b>The ultimate resort-style pool deck. Spa. Cabanas. Lounge chairs. Don’t forget the sunscreen. </b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-8.webp")} alt="Fitness Center" title="Fitness Center" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>Fitness Center.</span>
                                                        <b>Two full sets of weights. Cables. Cardio room. Full schedule of fitness class — which can be taken in person or streamed online in your home. Free for residents and guests.</b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="featuresitems">
                                            <div className="listImg">
                                                <figure>
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-9.webp")} alt="Lush Landscaping" title="Lush Landscaping" />
                                                </figure>
                                                <div className="content">
                                                    <div className="content-center">
                                                        <span>Lush landscaping.</span>
                                                        <b>And outdoor kitchens. Walk the grounds to the fire pits. We’ll be at the grill. You bring the fixin’s.</b>
                                                    </div>
                                                </div>
                                                <div className="overlap"></div>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </section>
                        {/* <section className="subscribeForm partner_info ">
                            <div className="container">
                                <div className="col-12">
                                    <h2>
                                        {this.state.PARTNERSHIP_SECTION_4 ? ((this.state.PARTNERSHIP_SECTION_4[0]) ? this.state.PARTNERSHIP_SECTION_4[0].text : "Who loves homesharing? Remote workers. Consultants. Flight attendants. People who do a lot of work travel. People who stay at their girlfriends' on the weekend. People whose parents live nearby. People who are saving money for a down payment. People who take extended vacations. People who take weekend trips. Anyone who wants a little more cash in their pocket (so, everyone).") : "Who loves Homesharing? Remote workers. Consultants. Flight attendants. People who do a lot of work travel. People who stay at their girlfriends' on the weekend. People whose parents live nearby. People who are saving money for a down payment. People who take extended vacations. People who take weekend trips. Anyone who wants a little more cash in their pocket (so, everyone)."}
                                    </h2>
                                </div>
                            </div>
                        </section> */}

                        {/* <section className="howdoesitworkSection partnerships-platform global.OnKeyPressEvent.getS3ImageUrl('multifamily-strpms.png')">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="partent_head">{this.state.PARTNERSHIP_SECTION_5 ? ((this.state.PARTNERSHIP_SECTION_5[0]) ? this.state.PARTNERSHIP_SECTION_5[0].text : "Case study") : "Case study"}</h2>
                                        <div className="row">
                                            <div className="col-lg-7 col-sm-12 bd-r">
                                                <div className="howwork border-0">
                                                    <div className="mobviewhowwork partnerships-cnt partner_padd">

                                                        <div className="partner_cnt_box">
                                                            <h2>{this.state.PARTNERSHIP_SECTION_5 ? ((this.state.PARTNERSHIP_SECTION_5[1]) ? this.state.PARTNERSHIP_SECTION_5[1].text : "Fort Lauderdale, FL") : "Fort Lauderdale, FL"}</h2>
                                                        </div>
                                                        <div className="partner_cnt_box  p-0">
                                                            <h4>{this.state.PARTNERSHIP_SECTION_5 ? ((this.state.PARTNERSHIP_SECTION_5[2]) ? this.state.PARTNERSHIP_SECTION_5[2].text : "Kini Beach Haus / Las Olas") : "Kini Beach Haus / Las Olas"} </h4>
                                                            <div className="reviewCountprogress">
                                                                <ul>
                                                                    <li>
                                                                        <div>{this.state.PARTNERSHIP_SECTION_5 ? ((this.state.PARTNERSHIP_SECTION_5[4]) ? this.state.PARTNERSHIP_SECTION_5[4].text : "<span>81%</span> Revenue Increase") : "<span>81%</span> Revenue Increase"}</div>
                                                                    </li>
                                                                    <li>
                                                                        <div>{this.state.PARTNERSHIP_SECTION_5 ? ((this.state.PARTNERSHIP_SECTION_5[5]) ? this.state.PARTNERSHIP_SECTION_5[5].text : "<span>56%</span> Rent Increase") : "<span>56%</span> Rent Increase"}</div>
                                                                    </li>
                                                                    <li>
                                                                        <div>{this.state.PARTNERSHIP_SECTION_5 ? ((this.state.PARTNERSHIP_SECTION_5[6]) ? this.state.PARTNERSHIP_SECTION_5[6].text : "<span>25%</span> Homesharing Income") : "<span>25%</span> Homesharing Income"}</div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-sm-12">
                                                <div className="howwork partnerships-img">
                                                    <figure>
                                                        <img src={this.state.PARTNERSHIP_SECTION_5 ? ((this.state.PARTNERSHIP_SECTION_5[3]) ? this.state.PARTNERSHIP_SECTION_5[3].url : global.OnKeyPressEvent.getS3ImageUrl('multifamily-strpms.png')) : global.OnKeyPressEvent.getS3ImageUrl('multifamily-strpms.png')} />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        {/* <section className="howdoesitworkSection partnerships-platform bg-light">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="partent_head pb-0"> {this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[0]) ? this.state.PARTNERSHIP_SECTION_6[0].text : "Partner with OHAI") : "Partner with OHAI"}  </h2>
                                        <div className="mobviewhowwork partnerships-cnt">
                                            <div className="partner_cnt_box pt-0">
                                                <p className="text-center mail-para"><a href="mailto:sales@orionhaus.com" target="_blank"> <img src={mailicon} /> {this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[1]) ? this.state.PARTNERSHIP_SECTION_6[1].text : "sales@orionhaus.com") : "sales@orionhaus.com"}</a> </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12">
                                                <div className="howwork partnerships-img ">
                                                    <figure>
                                                        <img src={this.state.PARTNERSHIP_SECTION_6 ? ((this.state.PARTNERSHIP_SECTION_6[2]) ? this.state.PARTNERSHIP_SECTION_6[2].url : global.OnKeyPressEvent.getS3ImageUrl('traditional-leases.png')) : global.OnKeyPressEvent.getS3ImageUrl('traditional-leases.png')} />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                    </div>
                </section>
                {/* Footer */}
                <CommonFooter propertySlug={this.state.property_slug} />
            </main>
        );
    }
}
export default Partnership;
