////////////////////////////////////////////////////////////
//     							                          //
//  Program: GooglePlaces.jsx                             //
//  Application: Common                                   //
//  Option: address autocomplete                          //
//  Developer: Jigar Soni , NP  						  //
//  Date: 2022-08-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import TextField from "@mui/material/TextField";

function GooglePlaces(props) {
  const [address, setAddress] = useState("");
  const [street_address_2, setStreetAddress2] = useState(
    props.street_address_2
  );
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  // const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [street_address_1, setStreetAddress1] = useState("");

  let componentType = "";
  useEffect(() => {
    props.getChildData(
      street_address_1,
      street_address_2,
      city,
      state,
      // county,
      country,
      zipcode
    );
  }, [
    street_address_1,
    street_address_2,
    city,
    state,
    // county,
    country,
    zipcode,
    street_address_1
  ]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);

    results[0].address_components.map((item) => {
      componentType = item.types[0];
      switch (componentType) {
        case "route":
          setStreetAddress1(item.short_name);
          break;
        case "neighborhood":
          setStreetAddress2(item.short_name);
          break;
        case "locality":
          setCity(item.short_name);
          break;
        // case "administrative_area_level_2":
        //   setCounty(item.short_name);
        //   break;
        case "administrative_area_level_1":
          setState(item.short_name);
          break;
        case "country":
          setCountry(item.short_name);
          break;
        case "postal_code":
          setZipCode(item.short_name);
          break;
        default:
          return "";
      }
    });
  };

  const searchOptions = {
    // componentRestrictions: { country: ["us"] },
    fields: ["address_components", "geometry", "postal_code"],
    types: ["address"],
  };

  // const renderFooter = () => (
  //   <div className="dropdown-footer">
  //     <div>
  //       <img src={GoogleLogo} alt="Powered by Google" />
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className="requestSection">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
              searchOptions={searchOptions}
              // renderFooter={renderFooter}
              className="borderInput textfield"
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <>
                  <input {...getInputProps({ placeholder: "Address" })} />

                  {loading ? <div>...loading</div> : null}
                  {suggestions.map((suggestion, i) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#e1ccbe" : "#fff",
                    };
                    return (
                      <div className="place-dropdown"
                        key={i}
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </>
              )}
            </PlacesAutocomplete>
          </div>


          {/* <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
            <TextField
              id="outlined-multiline-static"
              label="Address"
              className="w-100 borderInput"
              multiline
              rows={1}
            />
          </div> */}


          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
            <TextField
              id="street_address_1"
              label="Street 1"
              variant="outlined"
              fullWidth
              name="street_address_1"
              value={
                street_address_1
                  ? street_address_1
                  : props.street_address_1
                    ? props.street_address_1
                    : ""
              }
              onChange={(event) => setStreetAddress1(event.target.value)}
              // required
              // error={this.state.errors.street_address_1 ? true : false}
              className="borderInput textfield"
              />
               <p className="error-message">
              {street_address_1 == '' && props.errors.street_address_1 !== '' ? props.errors.street_address_1:""}
                              </p>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
            <TextField
              id="street_address_2"
              label="Street 2"
              // multiline
              // rows={0}
              name="street_address_2"
              value={
                street_address_2
                  ? street_address_2
                  : props.street_address_2
                    ? props.street_address_2
                    : ""
              }
              onChange={(event) => setStreetAddress2(event.target.value)}
              variant="outlined"
              fullWidth
              className="borderInput textfield"
            />
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
            <TextField
              type="text"
              id="city"
              label="City"
              variant="outlined"
              fullWidth
              className="borderInput textfield"
              name="city"
              onChange={(event) => setCity(event.target.value)}
              value={city ? city : props.city ? props.city : ""}
              //required
            // error={this.state.errors.city ? true : false}
            // helperText={this.state.errors.city ? this.state.errors.city : ""}
            />
              <p className="error-message">
              {city == '' && props.errors.city !== '' ? props.errors.city:""}
                              </p>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
            <TextField
              type="text"
              id="state_name"
              label="State"
              variant="outlined"
              fullWidth
              className="borderInput textfield"
              name="state_name"
              onChange={(event) => setState(event.target.value)}
              value={state ? state : props.state ? props.state : ""}
              required
            // error={this.state.errors.state_name ? true : false}
            // helperText={
            //   this.state.errors.state_name ? this.state.errors.state_name : ""
            // }
            />
             <p className="error-message">
             {state == '' && props.errors.state !== '' ? props.errors.state:""}
                              </p>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
            <TextField
              type="text"
              id="country"
              label="Country"
              variant="outlined"
              fullWidth
              className="borderInput textfield"
              name="country"
              onChange={(event) => setCountry(event.target.value)}
              value={country ? country : props.country ? props.country : ""}
              required
            // error={this.state.errors.country ? true : false}
            // helperText={
            //   this.state.errors.country ? this.state.errors.country : ""
            // }
            />
              <p className="error-message">
              {country == '' && props.errors.country !== '' ? props.errors.country:""}
                              </p>
          </div>

          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
            <TextField
              type="text"
              id="zipcode"
              label="Zip Code"
              variant="outlined"
              fullWidth
              className="borderInput textfield"
              name="zipcode"
              value={zipcode ? zipcode : props.zipcode ? props.zipcode : ""}
              onChange={(event) => setZipCode(event.target.value)}
              required
            // error={this.state.errors.zipcode ? true : false}
            // helperText={
            //   this.state.errors.zipcode ? this.state.errors.zipcode : ""
            // }
            />
            <p className="error-message">
              {zipcode == '' && props.errors.zipcode !== '' ? props.errors.zipcode:""}
                              </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default GooglePlaces;
