//     							                                      //
//  Program: KINIGuestmessages.jsx                              //
//  Application: KINI HTML                                //
//  Option: KINIGuestmessages.jsx                                  //
//  Developer: PS		                                      //
//  Date: 2022-09-07                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import { Form } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import TextareaAutosize from 'react-autosize-textarea';
import { Helmet } from "react-helmet-async";
class Guestmessages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
      showaddContactModal: false,
      showquickreplyModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(type) {
    this.setState({ showaddContactModal: false });
    this.setState({ showquickreplyModal: false });
  }
  render() {

    return (
      <main className="oh-template Brandmainpage">
        {/* Header */}
        <Helmet>
        <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <section className="middle-section">
          <div className="container">
            <div className="message pd-t-20  pd-l-0 pd-r-0 ">
              <div className="col-md-12 pd-l-0 pd-r-0">
                <div className="d-flex justify-content-between align-items-center flex-wrap mg-t-10 mg-b-20">
                  <h3>All messages</h3>
                  {/* <button className="btn-success-outline mr-3" onClick={() => this.setState({ showaddContactModal: true })}>
                    <img src={global.OnKeyPressEvent.getS3ImageUrl('plus.svg')} /> Add
                  </button> */}
                </div>
              </div>
              <div className="col-lg-12 pd-l-0 pd-r-0">
                <div className="row m-0 w-100">
                  {[false].map((expand) => (
                    <Navbar key={expand} expand={expand} >
                      <Container fluid className="p-0">
                        <div className="col-lg-3 col-xxl-3 pd-l-5  pd-r-5">
                          <div className="leftSection">
                            <div className="header">
                              <div className="searchSection">
                                <Form.Control type="text" placeholder="Search here" />
                                <span><img src={global.OnKeyPressEvent.getS3ImageUrl('Search.svg')} alt="Messages" title="Messages"/> </span>
                              </div>
                              {/* dropdown search user */}
                              {/* <div className="listSetction">
                          <ul>
                            <li>
                              <a href="#">
                                <figure>
                                  <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} />
                                </figure>
                                <div className="content">
                                  <div className="d-flex flex-column">
                                    <b>Holly Ferguson</b>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <figure>
                                  <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} />
                                </figure>
                                <div className="content">
                                  <div className="d-flex flex-column">
                                    <b>Holly Ferguson</b>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <figure>
                                  <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} />
                                </figure>
                                <div className="content">
                                  <div className="d-flex flex-column">
                                    <b>Holly Ferguson</b>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div> */}
                              {/* End dropdown search user */}
                            </div>
                            <div className="listItem">
                              <ul>
                                <li>
                                  <a href="#" className="chatDeskview">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>


                                  {/* mobile view chat */}

                                  <Navbar.Toggle aria-controls={`offcanvasNavbar-expand3-${expand}`} className="chatMobileview">
                                    <a href="#" >
                                      <figure>
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                      </figure>
                                      <div className="content">
                                        <div className="d-flex align-items-start text-left flex-column w-75">
                                          <b>Holly Ferguson</b>
                                          <span><b>You - </b>Hi, Holly </span>
                                          <p>3 Sep - 10 Sep, Unit - 002 </p>
                                        </div>
                                        <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                          <i>02:15 PM</i>
                                          <b className="aleart">2</b>
                                        </div>

                                      </div>
                                    </a>
                                  </Navbar.Toggle>

                                  {/* mobile view chat */}
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <figure>
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                    </figure>
                                    <div className="content">
                                      <div className="d-flex align-items-start text-left  flex-column w-75">
                                        <b>Holly Ferguson</b>
                                        <span><b>You - </b>Hi, Holly </span>
                                        <p>3 Sep - 10 Sep, Unit - 002 </p>
                                      </div>
                                      <div className="d-flex justify-content-end flex-column align-items-end w-25">
                                        <i>02:15 PM</i>
                                        <b className="aleart">2</b>
                                      </div>

                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xxl-6 pd-l-15 pd-r-15 chatmiddleMobileview">
                          <div className="middleSection">
                            <div className="header">
                              <h6>Holly Ferguson</h6>
                            </div>
                            <div className="chatSection">
                              <div className="chatList">
                                <div className="listItem">
                                  <span className="status">06/09/22</span>
                                  <ul>
                                    <li>
                                      <div className="chatContent">
                                        <figure>
                                          <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                        </figure>
                                        <div className="content">
                                          <div className="d-flex flex-column w-100">
                                            <div className="top">
                                              <b>Holly Ferguson <i>11:52 AM</i></b>
                                              <div className="chatNav">
                                                <Dropdown>
                                                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu className="tx-13">
                                                    <Dropdown.Item href="#/action-1">Delete</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-1">Select All</Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                            <div className="chatmessage">
                                              <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                              <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div className="listItem">
                                  <ul>
                                    <li>
                                      <div className="chatContent">
                                        <figure>
                                          <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                        </figure>
                                        <div className="content">
                                          <div className="d-flex flex-column w-100">
                                            <div className="top">
                                              <b>Holly Ferguson <i>11:52 AM</i></b>
                                              <div className="chatNav">
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                              </div>
                                            </div>
                                            <div className="chatmessage">
                                              <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                              <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div className="listItem">
                                  <span className="status">06/09/22</span>
                                  <ul>
                                    <li>
                                      <div className="chatContent">
                                        <figure>
                                          <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                        </figure>
                                        <div className="content">
                                          <div className="d-flex flex-column w-100">
                                            <div className="top">
                                              <b>Holly Ferguson <i>11:52 AM</i></b>
                                              <div className="chatNav">
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                              </div>
                                            </div>
                                            <div className="chatmessage">
                                              <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                              <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div className="listItem">
                                  <span className="status">06/09/22</span>
                                  <ul>
                                    <li>
                                      <div className="chatContent">
                                        <figure>
                                          <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                        </figure>
                                        <div className="content">
                                          <div className="d-flex flex-column w-100">
                                            <div className="top">
                                              <b>Holly Ferguson <i>11:52 AM</i></b>
                                              <div className="chatNav">
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                              </div>
                                            </div>
                                            <div className="chatmessage">
                                              <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                              <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="Chatbox">
                                <span>
                                  <div className="fileUpload">
                                    <label className="hand-cursor">
                                      <input type="file" name="property_logo" />
                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Logo" />
                                    </label>
                                  </div>
                                  {/* <button onClick={() => this.setState({ showquickreplyModal: true })}><img src={global.OnKeyPressEvent.getS3ImageUrl('quick-reply.svg')} /></button> */}
                                  <button className="emoji"><img src={global.OnKeyPressEvent.getS3ImageUrl('emoji.svg')} alt="Messages" title="Messages"/></button>

                                </span>
                                {/* <Form.Control type="text" placeholder="Type your message" /> */}
                                <TextareaAutosize onResize={(e) => { }} placeholder="Type your message" />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* mobile view chat */}
                        <Navbar.Offcanvas
                          id={`offcanvasNavbar-expand3-${expand}`}
                          aria-labelledby={`offcanvasNavbarLabel-expand3-${expand}`}
                          placement="end"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand3-${expand}`}>
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <div className="message">
                              <div className="col-lg-6 col-xxl-6 pd-l-5  pd-r-0">
                                <div className="middleSection">
                                  <div className="header">
                                    <h6>Holly Ferguson</h6>
                                  </div>
                                  <div className="chatSection">
                                    <div className="chatList">
                                      <div className="listItem">
                                        <span className="status">06/09/22</span>
                                        <ul>
                                          <li>
                                            <div className="chatContent">
                                              <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                              </figure>
                                              <div className="content">
                                                <div className="d-flex flex-column w-100">
                                                  <div className="top">
                                                    <b>Holly Ferguson <i>11:52 AM</i></b>
                                                    <div className="chatNav">
                                                      <Dropdown>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                          <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="tx-13">
                                                          <Dropdown.Item href="#/action-1">Delete</Dropdown.Item>
                                                          <Dropdown.Item href="#/action-1">Select All</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    </div>
                                                  </div>
                                                  <div className="chatmessage">
                                                    <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                                    <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="listItem">
                                        <ul>
                                          <li>
                                            <div className="chatContent">
                                              <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                              </figure>
                                              <div className="content">
                                                <div className="d-flex flex-column w-100">
                                                  <div className="top">
                                                    <b>Holly Ferguson <i>11:52 AM</i></b>
                                                    <div className="chatNav">
                                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                                    </div>
                                                  </div>
                                                  <div className="chatmessage">
                                                    <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                                    <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="listItem">
                                        <span className="status">06/09/22</span>
                                        <ul>
                                          <li>
                                            <div className="chatContent">
                                              <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                                              </figure>
                                              <div className="content">
                                                <div className="d-flex flex-column w-100">
                                                  <div className="top">
                                                    <b>Holly Ferguson <i>11:52 AM</i></b>
                                                    <div className="chatNav">
                                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                                    </div>
                                                  </div>
                                                  <div className="chatmessage">
                                                    <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                                    <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="listItem">
                                        <span className="status">06/09/22</span>
                                        <ul>
                                          <li>
                                            <div className="chatContent">
                                              <figure>
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} />
                                              </figure>
                                              <div className="content">
                                                <div className="d-flex flex-column w-100">
                                                  <div className="top">
                                                    <b>Holly Ferguson <i>11:52 AM</i></b>
                                                    <div className="chatNav">
                                                      <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Messages" title="Messages"/>
                                                    </div>
                                                  </div>
                                                  <div className="chatmessage">
                                                    <span>Hi, Ferguson I hope Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>
                                                    <p>3 Sep - 10 Sep <i> Unit - 002 </i></p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="Chatbox">
                                      <span>
                                        <div className="fileUpload">
                                          <label className="hand-cursor">
                                            <input type="file" name="property_logo" />
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-nav.svg')} alt="Logo" />
                                          </label>
                                        </div>
                                        {/* <button onClick={() => this.setState({ showquickreplyModal: true })}><img src={global.OnKeyPressEvent.getS3ImageUrl('quick-reply.svg')} /></button> */}
                                        <button className="emoji"><img src={global.OnKeyPressEvent.getS3ImageUrl('emoji.svg')} alt="Messages" title="Messages"/></button>

                                      </span>
                                      <TextareaAutosize onResize={(e) => { }} placeholder="Type your message" />

                                      {/* <Form.Control type="text" /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Offcanvas.Body>
                        </Navbar.Offcanvas>
                        {/* end mobile view chat */}
                      </Container>
                    </Navbar>
                  ))}
                  {/* mobile view in header section msg */}

                </div>
              </div>
            </div>

          </div>
        </section>
        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />


        {/* Add new contact modalbox */}
        <Modal
          show={this.state.showaddContactModal}
          onHide={() => this.closeModal("basic")}
          className="addContacts"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="addContactsList">
              <div className="header">
                <div className="searchSection">
                  <Form.Control type="text" placeholder="Search here" />
                  <span><img src={global.OnKeyPressEvent.getS3ImageUrl('Search.svg')} alt="Messages" title="Messages"/> </span>
                </div>
              </div>
              {/* dropdown search user */}
              <div className="listSetction">
                <ul>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('guest.jpeg')} alt="Messages" title="Messages"/>
                      </figure>
                      <div className="content">
                        <div className="d-flex flex-column">
                          <b>Holly Ferguson</b>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              {/* End dropdown search user */}
            </div>
          </Modal.Body>

          {/* <Button
            variant="outline-light"
            onClick={() => this.closeModal("basic")}
          >
            Close
          </Button> */}
        </Modal>
        {/* End Add new contact modalbox */}


        {/* Add new contact modalbox */}
        <Modal
          show={this.state.showquickreplyModal}
          onHide={() => this.closeModal("showquickreplyModal")}
          className="showquickreplyModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="header">
              <h3>Quick replies</h3>

              <span
                className="welcomeClose"
                onClick={() => this.closeModal("showquickreplyModal")}
              >
                {global.closee}
              </span>
            </div>
            {/* dropdown search user */}
            <div className="listSetction">
              <ul>
                <li>
                  <div className="content">
                    <b>After verification</b>
                    <p>Thank you for verifying! Please download out app to access your apartment here: LINK and use your confirmation code</p>
                    <button className="mx-auto m-0 btn btn-outline-light mr-0">Use this reply</button>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <b>After verification</b>
                    <p>Thank you for verifying! Please download out app to access your apartment here: LINK and use your confirmation code</p>
                    <button className="mx-auto m-0 btn btn-outline-light mr-0">Use this reply</button>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <b>After verification</b>
                    <p>Thank you for verifying! Please download out app to access your apartment here: LINK and use your confirmation code</p>
                    <button className="mx-auto m-0 btn btn-outline-light mr-0">Use this reply</button>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <b>After verification</b>
                    <p>Thank you for verifying! Please download out app to access your apartment here: LINK and use your confirmation code</p>
                    <button className="mx-auto m-0 btn btn-outline-light mr-0">Use this reply</button>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <b>After verification</b>
                    <p>Thank you for verifying! Please download out app to access your apartment here: LINK and use your confirmation code</p>
                    <button className="mx-auto m-0 btn btn-outline-light mr-0">Use this reply</button>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <b>After verification</b>
                    <p>Thank you for verifying! Please download out app to access your apartment here: LINK and use your confirmation code</p>
                    <button className="mx-auto m-0 btn btn-outline-light mr-0">Use this reply</button>
                  </div>
                </li>
              </ul>
            </div>
            {/* End dropdown search user */}
          </Modal.Body>

        </Modal>
        {/* End Add new contact modalbox */}

      </main >
    );
  }
}
export default Guestmessages