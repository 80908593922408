//     							                          //
//  Program: OHListing.jsx                                //
//  Application: OH HTML                                  //
//  Option: OH Listing                                    //
//  Developer: PS		                                  //
//  Date: 2022-04-11                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FloorPlanApi from "../../services/FloorPlanApi";
import CmsDataService from "../../services/CmsDataService";
import queryString from "query-string";
const queryParams = queryString.parse(location.search);
import GlobalFilter from "../common/GlobalFilter";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import "swiper/css";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Skeleton, Typography } from "@mui/material";
import MetaSEO from "../../../MetaSEO";
class FloorplanListing extends Component {
  constructor(props) {
    super(props);
    // this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      date: new Date("2014-08-18T21:11:54"),
      FloorPlanListing: [],
      errors: {},
      showAlertModal: false,
      openImagepopup: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      NoUnitemsg: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      Bedroom_list: [],
      Bathroom_list: [],
      furnished_list: [],
      furnished_list_label: [],
      Bedroom: [],
      Bathroom: [],
      UnitListing: [],
      UnitBedroom: [],
      UnitBathroom: [],
      UnitAmenity: {},
      availablity_modal_box: false,
      add: queryParams.furnished === "Unfurnished" ? "Unfurnished" : "Furnished",
      listing_slug: this.props.match.params.slug,
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      slug: "",
      status_id: true,
      pathname: window.location.pathname,
      personName: [],
      location: queryParams.city ? queryParams.city : [],
      location_name: [],
      //   date:new Date('2014-08-18T21:11:54'),
      selectedDate: new Date(),
      today: Moment(new Date()).format("YYYY-MM-DD"),
      type: false,
      selectedbedrooms: [],
      unit_size: {},
      price: {},
      min_unit: "",
      max_unit: "",
      min_price: "",
      max_price: "",
      queryString: "",
      move_in_date: [],
      bathroom: "",
      bedroom: "",
      bedroomcount: (queryParams.numberOfbed != "" && !isNaN(queryParams.numberOfbed)) ? parseInt(queryParams.numberOfbed) : 1,
      bathCount: (queryParams.numberOfbathroom != "" && !isNaN(queryParams.numberOfbathroom)) ? parseInt(queryParams.numberOfbathroom) : 0,
      floorPlanImg: "",
      itemsToShow: 6,
      item_slug: "",
      is_showmore: false,
      showmore_text: "Show More",
      showless_text: "Show Less",
      // startDate: queryParams.checkin ? queryParams.checkin : Moment(new Date()).format("YYYY-MM-DD"),
      startDate: queryParams.checkin ? queryParams.checkin : "",
      endDate: queryParams.checkout ? queryParams.checkout : "",
      //bedbathValue: "1 Bedroom + 1 Bathroom",
      furnished: [],
      furnished_label: [],
      floorplanImagemodal: [],
      property_name: localStorage.getItem("property_name"),
      // meta tags start
      title: "",
      favicon: "",
      keywords: global.keywords,
      description: global.description,
      url: window.location.href,
      image: "",
      fb_app_id: "",
      // meta tags end
    };

    // this.changeView = this.changeView.bind(this);

    this.handleChangebedroom = this.handleChangebedroom.bind(this);
    this.handleChangebathroom = this.handleChangebathroom.bind(this);
    this.handleChangefurnished = this.handleChangefurnished.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.SetUnitData = this.SetUnitData.bind(this);
    this.redierectToLtrdetail = this.redierectToLtrdetail.bind(this);
    // this.incrementbath = this.incrementbath.bind(this);
    // this.decrementbath = this.decrementbath.bind(this);
    // this.incrementBed = this.incrementBed.bind(this);
    // this.decrementBed = this.decrementBed.bind(this);
    // this.getUnitData = this.getUnitData.bind(this);
    this.changeDateHandlerNew = this.changeDateHandlerNew.bind(this);
    this.filterData = this.filterData.bind(this);
    this.setPrice = this.setPrice.bind(this);
  }

  componentDidMount() {
    this.getFloorplanData();
    this.getMetaData();
    // this.getBedroombathroomList();
    this.getFloorplanListingCmsData();
    //this.getFurnishedData();
    document.getElementById("listing-focus").focus();
  }
 makeQueryString = () => {
    // check_in=2022-11-24&check_out=2022-11-30
    var queryString = this.state.startDate ? "?check_in=" + Moment(this.state.startDate).format("YYYY-MM-DD") : "";
    this.setState({ queryString: queryString });
  }
  /* CMS data getcode start */
  getMetaData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var cacheKeyName = global.level + "_FLOORPLAN_LISTING_META_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    let res = await CmsDataService.getCmsMetaData("FLOORPLAN_LISTING", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resData.title = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.title : "") : "";
      resData.favicon = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.keywords = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.keywords : "") : "";
      resData.description = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.description : "") : "";
      resData.url = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.image = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.fb_app_id = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.fb_app_id : "") : "";
    }
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true
    });
  }

  getFloorplanListingCmsData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    var cacheKeyName = global.level + "_FLOORPLAN_LISTING_DATA_" + levelSlug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }

    let res = await CmsDataService.getCmsData("FLOORPLAN_LISTING", global.level, levelSlug);
    // console.log(res, "flist");
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false
    });
    // console.log(resData);
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
  }
  /* CMS data getcode end */

  changeDateHandlerNew = async (date) => {
    var startDate = date.value ? date.value : "";
    var endDate = date.value[1] ? date.value[1] : "";
    await this.setState({ startDate: startDate });
    await this.makeQueryString();
  }

  handleChangelocation = async (event) => {
    let input = this.state.input;
    input[event.label] = event.value;
    await this.setState({ location: event.city_id, location_name: event.label, input: input });
  }

  openModal = (floor_plan_slug) => {
    var slug_type = {
      property_seo_slug: this.state.property_slug,
      floorplan_slug: floor_plan_slug,
    };
    this.getUnitData(slug_type);
    //this.setState({availablity_modal_box:true});
  }

  openImageModal = (item) => {
    this.setState({
      openImagepopup: true,
      floorplanImagemodal: item.floorplan_image_url,
    });
    //this.setState({availablity_modal_box:true});
  }

  redierectToLtrdetail = async (item, url) => {
    localStorage.setItem('ltrdetail', JSON.stringify(item));
    window.location.href = url;
  }

  closeImageModal() {
    this.setState({ openImagepopup: false });
  }

  async getFloorplanData(inputData1 = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var city = [];
    let customInputs = {
      property_seo_slug: this.state.property_slug
        ? this.state.property_slug
        : "",
      city_id: this.state.location ? parseInt(this.state.location) : "",
      // city_id: this.state.location ? parseInt(this.state.location) : "",
      Furnished: this.state.add === "Unfurnished" ? ["Unfurnished"] : ["Fully Furnished"],
      Move_in_date: this.state.startDate === "" || this.state.startDate === "undefined" || this.state.startDate === "Invalid date" ? Moment(new Date()).format('YYYY/MM/DD') : Moment(this.state.startDate).format("YYYY/MM/DD"),
      Bedroom: this.state.bedroomcount ? [this.state.bedroomcount] : [],
      Bathroom: this.state.bathCount ? [this.state.bathCount] : [],
      isquery: 1,
    }
    // let inputData = {

    // };
    let inputData = inputData1 ? inputData1 : customInputs;
    // let res = await FloorPlanApi.getUnitlisting(inputData);
    // console.log(res);
    this.setState({ Skeleton: true })
    let res = await FloorPlanApi.getFloorPlanlisting(inputData);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.getFloorplanData : [];
      totalRecords = list ? list.length : 0;
      city = res.other_data ? res.other_data : [];
      if(totalRecords === 0){
        this.setState({ Skeleton: false });
      }
    } else {
      this.setState({ Skeleton: false });
    }
    city.map((item, index) => {
      item.label = city[index].city.city_name;
      item.value = city[index].city.city_id;
    });
    this.setState({ newcityList: city });
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      FloorPlanListing: list,
      Skeleton: false,
      displaySearchResult: true
    });
    document.getElementById("listing-focus").focus();
  }

  // async getBedroombathroomList(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var list = [];
  //   var totalRecords = 0;
  //   var cacheKeyName =
  //     global.theme + "_FLOORPLAN_DROPDOWN_DATA_" + this.state.property_slug;
  //   if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
  //     var data =
  //       global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
  //         ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
  //         : localStorage.removeItem(cacheKeyName);
  //     this.setState({
  //       total_record: data.length,
  //       Bedroom_list: data.bedroomArray,
  //       Bathroom_list: data.bathroomArray,
  //       furnished_list: data.furnishedArray,
  //       furnished_list_label: data.temp,
  //     });
  //   }
  //   let res = await FloorPlanApi.getBedroomBathroomlisting(
  //     this.state.property_slug
  //   );
  //   // /let res = await FloorPlanApi.getFloorPlanlisting();
  //   if (global.successStatus.includes(res.status)) {
  //     list = res.data ? res.data : [];
  //     totalRecords = list ? list.length : 0;
  //   }
  //   let temp = [];
  //   list.furnishedArray && list.furnishedArray.length > -1 &&
  //     list.furnishedArray.map((value, key) => {
  //       temp[value.value] = value.label;
  //     });
  //   global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(list));
  //   this.setState({
  //     showSpinner: false,
  //     loading: false,
  //     total_record: totalRecords,
  //     Bedroom_list: list.bedroomArray,
  //     Bathroom_list: list.bathroomArray,
  //     furnished_list: list.furnishedArray,
  //     furnished_list_label: temp,
  //   });
  // }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.Filterdata();
  }

  filterData = async (inputData1 = "") => {
    await this.getFloorplanData(inputData1);
  }

  // async Filterdata(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   // queryString = "Bedroom=" + (this.state.input.bedroom ? this.state.input.bedroom :'') + "&" + "Bathroom=" + (this.state.input.bathroom ? this.state.input.bathroom : '' ) +"&" + "Min_Unit=" + (this.state.input.min_unit ? this.state.input.min_unit : '') + "&" + "Max_Unit=" + (this.state.input.max_unit ? this.state.input.max_unit :'') + "&" + "Min_Price=" + (this.state.input.min_price ? this.state.input.min_price :'' ) + "&" + "Max_Price=" + (this.state.input.max_price ? this.state.input.max_price :'') + "&" + "Furnished=" + (this.state.input.furnished ? this.state.input.furnished : '') + "&" + "Move_in_date=" + (this.state.input.move_in_date ? this.state.input.move_in_date :'');
  //   let inputData = {
  //     property_seo_slug: this.state.property_slug
  //       ? this.state.property_slug
  //       : "",
  //     Bedroom: this.state.input.bedroom ? this.state.input.bedroom : "",
  //     Bathroom: this.state.input.bathroom ? this.state.input.bathroom : "",
  //     Min_Unit: this.state.input.min_unit ? this.state.input.min_unit : "",
  //     Max_Unit: this.state.input.max_unit ? this.state.input.max_unit : "",
  //     Min_Price: this.state.input.min_price ? this.state.input.min_price : "",
  //     Max_Price: this.state.input.max_price ? this.state.input.max_price : "",
  //     Furnished: this.state.input.furnished ? this.state.input.furnished : "",
  //     Move_in_date: this.state.input.move_in_date
  //       ? this.state.input.move_in_date
  //       : "",
  //   };
  //   this.getFloorplanData(inputData);
  // }

  handleChangebedroom = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ bedroom: event.target.value, input: input });
    this.Filterdata();
  }

  handleChangebathroom = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ bathroom: event.target.value, input: input });
    this.Filterdata();
  }

  handleChangefurnished = (event) => {
    // this.setPrice(event);
    // this.SetUnitData(event);
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    let labelName = this.state.furnished_label;
    let furnished_list_label = this.state.furnished_list_label;
    let tempLabelName = furnished_list_label[event.target.value];
    labelName.push(tempLabelName);
    this.setState({
      furnished: event.target.value,
      input: input,
      furnished_label: labelName,
    });
    this.Filterdata();
  }

  // handleDateChange(event) {
  //   let input = this.state.input;
  //   input[event.target.name] = event.target.value;
  //   var a = Moment(new Date(event.target.value)).format("MM-DD-YYYY");
  //   this.setState({ selectedDate: a, input: input });
  //   this.Filterdata();
  // }

  handleDateChange(date, name) {
    let input = this.state.input;
    let a = new Date(date);
    input.move_in_date = Moment(new Date(a)).format("YYYY-MM-DD");
    // input[event.target.name] = event.target.value;
    // var a = Moment(new Date(event.target.value)).format("MM-DD-YYYY");
    //  //console.log(a);
    this.setState({ selectedDate: a, input: input });
    this.Filterdata();
  }

  closeOtherTab = () => {
    this.SetUnitData();
    this.setPrice();
  }

  SetUnitData(e) {
    var unit = document.getElementById("unit-selection");
    unit !== null ? unit.classList.remove("show") : "";
  }

  setPrice(e) {
    var price = document.getElementById("price-selection");
    price !== null ? price.classList.remove("show") : "";
  }

  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }

  // async getUnitData(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var list = [];
  //   var Bathroom = 0;
  //   var Bedroom = 0;
  //   var Amenity = [];
  //   var totalRecords = 0;
  //   let res = await UnitlistingApi.getUnitlisting(queryString);

  //   if (global.successStatus.includes(res.status)) {
  //     list = res.data.getUnitData ? res.data.getUnitData : [];
  //     totalRecords = list ? list.length : 0;
  //     Bathroom = res.data.getUnitData[0].bath
  //       ? res.data.getUnitData[0].bath
  //       : 0;
  //     Bedroom = res.data.getUnitData[0].bedroom
  //       ? res.data.getUnitData[0].bedroom
  //       : 0;
  //     Amenity = res.data.amentyArray ? res.data.amentyArray : [];
  //   }
  //   this.setState({
  //     showSpinner: false,
  //     loading: false,
  //     total_record: totalRecords,
  //     availablity_modal_box: true,
  //     UnitListing: list,
  //     UnitBedroom: Bedroom,
  //     UnitBathroom: Bathroom,
  //     UnitAmenity: Amenity,
  //   });
  // }

  closeAvalModal() {
    this.setState({ availablity_modal_box: false });
  }

  unitSizeDDValue() {
    if (this.state.min_unit && !this.state.max_unit) {
      return this.state.min_unit + " Sq.Ft or more";
    }
    if (!this.state.min_unit && this.state.max_unit) {
      return this.state.max_unit + " Sq.Ft or less";
    }
    if (this.state.min_unit && this.state.max_unit) {
      return this.state.min_unit + " to " + this.state.max_unit + " Sq.Ft";
    }
    if (!this.state.min_unit && !this.state.max_unit) {
      return "";
    }
  }

  unitClear = async (e) => {
    await this.setState({
      min_unit: "",
      max_unit: "",
      input: { min_unit: "", max_unit: "" },
    });
    await this.getFloorplanData();
  }

  showmore(slug) {
    this.setState({ item_slug: slug, is_showmore: true });
  }

  showless(slug) {
    this.setState({ item_slug: "", is_showmore: false });
  }

  priceClear = async (e) => {
    await this.setState({
      min_price: "",
      max_price: "",
      input: { min_price: "", max_price: "" },
    });
    await this.getFloorplanData();
  }

  unitPriceDDValue() {
    if (this.state.min_price && !this.state.max_price) {
      return "$" + this.state.min_price + " or more";
    }
    if (!this.state.min_price && this.state.max_price) {
      return "$" + this.state.max_price + " or less";
    }
    if (this.state.min_price && this.state.max_price) {
      return "$" + this.state.min_price + " to $" + this.state.max_price;
    }
    if (!this.state.min_price && !this.state.max_price) {
      return "";
    }
  }

  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <main className="oh-template Brandmainpage">
        {/* Meta Tag Start */}
        {
          this.state.seoLoaded &&
          <MetaSEO
            title={this.state.title}
            favicon={this.state.favicon}
            keywords={this.state.keywords}
            description={this.state.description}
            url={this.state.url}
            image={this.state.image}
            fb_app_id={this.state.fb_app_id}
            noIndex={"true"}
            author={"OHAI"}
          />
        }
        {/* Meta Tag End */}
        {/* Header */}
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <section className="middle">

          <div className="container-fluid p-0">
            <section
              className="liveHere-stay mg-b-10"
              name="FLOORPLAN_LISTING"
              style={{ backgroundImage: `url(${this.state.LTR_LISTING_SECTION_1 ? ((this.state.LTR_LISTING_SECTION_1[0]) ? this.state.LTR_LISTING_SECTION_1[0].url : "") : ""})` }}
            >
              <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-50 pd-r-50">
                <h1>{this.state.seoLoaded ? this.state.title : "Live at OHAI"}</h1>
                <span className="m-0">
                  {
                    this.state.displaySearchResult && (
                      this.state.total_record === 0 ?
                        "All units are currently leased up! Sign up for the wait list to be alerted of upcoming availability."
                        :
                        (this.state.total_record !== 0 && this.state.total_record > 1 ?
                          this.state.total_record + " floor plans match your search "
                          :
                          this.state.total_record + " floor plan matches your search")
                    )
                  }

                </span>
                <h6 className="d-none">
                  {this.state.FLOORPLAN_LISTING &&
                    this.state.FLOORPLAN_LISTING.section_data &&
                    this.state.FLOORPLAN_LISTING.section_data[1] &&
                    this.state.FLOORPLAN_LISTING.section_data[1].text
                    ? this.state.FLOORPLAN_LISTING.section_data[1].text
                    : "Lease an apartment and earn money on your own terms — or stay with us while you’re in town (or however long you want…)."}
                </h6>
                <div className="d-flex d-none">
                  <a
                    href={
                      global.themeUrl +
                      this.state.property_slug +
                      "/str-listing#strPlanList"
                    }
                    className="text-decoration-none"
                  >
                    <button className="mg-r-15">
                      {" "}
                      {this.state.FLOORPLAN_LISTING &&
                        this.state.FLOORPLAN_LISTING.section_data &&
                        this.state.FLOORPLAN_LISTING.section_data[2] &&
                        this.state.FLOORPLAN_LISTING.section_data[2].text
                        ? this.state.FLOORPLAN_LISTING.section_data[2].text
                        : "Stay awhile"}
                    </button>{" "}
                  </a>
                  <a href="#floorPlanList" className="text-decoration-none">
                    <button>
                      {this.state.FLOORPLAN_LISTING &&
                        this.state.FLOORPLAN_LISTING.section_data &&
                        this.state.FLOORPLAN_LISTING.section_data[3] &&
                        this.state.FLOORPLAN_LISTING.section_data[3].text
                        ? this.state.FLOORPLAN_LISTING.section_data[3].text
                        : "Live here"}{" "}
                    </button>
                  </a>{" "}
                </div>
              </div>
            </section>

            <div className="globalFilter">
              <section className="mainListing">
                <GlobalFilter
                  locationArr={this.state.newcityList}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  changeDateHandlerNew={this.changeDateHandlerNew.bind(this)}
                  handleChangelocation={this.handleChangelocation.bind(this)}
                  changeHandler={this.changeHandler.bind(this)}
                  queryParams={queryParams}
                  page={"ltr"}
                  propertySlug={this.state.property_slug}
                  filterData={this.filterData.bind(this)}
                />
              </section>
            </div>

            <section className="filter pd-b-20">
              <div className="container-fluid">
                <div className="property_detail" id="listing-focus" >
                  <div className="container">
                    <div className="row" >
                      {(this.state.FloorPlanListing && this.state.FloorPlanListing.length) > 0 ? (
                        this.state.FloorPlanListing.map((item, i) => {
                          return (
                            <>
                              <div className="col-md-12 pd-l-15 pd-r-30 mg-b-0" key={i}>
                                <div className="property_heading">
                                  <h4 className="listing_title">{item.property_name ? item.property_name : "Las Olas"}</h4>
                                  <h5>{item.city_name + (item.state_name ? ", " + item.state_name : "")}</h5>
                                </div>
                              </div>
                              <div className="col-lg-5 col-sm-12 pd-l-15 pd-r-30 mg-b-60">
                                <a href={"/" + (item.property_seo_slug ? item.property_seo_slug : this.state.property_slug) + "/floorplan/" + item.floorplan_slug}>
                                  <Swiper
                                     pagination={{
                                      clickable: true,
                                      dynamicBullets: true,
                                    }}
                                    lazy={true}
                                    // modules={[Pagination]}
                                    className="mySwiper m-0"
                                    navigation={false}
                                    autoplay={{
                                      delay: 2500,
                                      disableOnInteraction: false,
                                    }}
                                    modules={[Pagination, Autoplay]}
                                  >
                                    {item.floor_plan_main_image &&
                                      item.floor_plan_main_image.length > 0 &&
                                      item.floor_plan_main_image.map(
                                        (value, index) => {
                                          return (
                                            <SwiperSlide key={index}>
                                              <div className="property_img">
                                                <figure key={index}>
                                                  <img src={value.large_img_w_720 ? value.large_img_w_720 : global.OnKeyPressEvent.getS3ImageUrl('properties.png')} alt="Property Image" title="Property Image" />
                                                </figure>
                                              </div>
                                            </SwiperSlide>
                                          );
                                        }
                                      )}
                                    {item.property_main_image &&
                                      item.property_main_image.length > 0 &&
                                      item.property_main_image.map(
                                        (value, index) => {
                                          return (
                                            <SwiperSlide key={index}>
                                              <div className="property_img">
                                                <figure key={index}>
                                                  <img src={value.large_img_w_720 ? value.large_img_w_720 : global.OnKeyPressEvent.getS3ImageUrl('properties.png')} alt="Property Image" title="Property Image" />
                                                </figure>
                                              </div>
                                            </SwiperSlide>
                                          );
                                        }
                                      )}
                                  </Swiper>
                                </a>
                              </div>
                              <div className="col-lg-7 col-sm-12 pd-l-30 pd-r-30 mg-b-60">
                                <div className="Property_info">
                                  <a href={"/" + (item.property_seo_slug ? item.property_seo_slug : this.state.property_slug) + "/floorplan/" + item.floorplan_slug} className="text-decoration-none">
                                    <h4 className="listing_title">{item.floorplan_name}</h4>
                                  </a>
                                  <div className="property_size">
                                    <ul>
                                      <li>
                                        {`
                                      ${item.bedroom && item.bedroom !== null && (
                                            item.bedroom > 1
                                              ? item.bedroom + " Bedrooms"
                                              : item.bedroom + " Bedroom"
                                          )} / ${item.bath !== null && (
                                            item.bath > 1
                                              ? item.bath + " Bathrooms"
                                              : item.bath + " Bathroom"
                                          )}
                                    `}
                                      </li>
                                      <li>{`Sleeps ${item.maximum_number_of_guests ? item.maximum_number_of_guests : 1} People`}</li>
                                      <li>{`${item.square_foot
                                        ? parseInt(item.square_foot)
                                        : ""} Sq ft`}</li>
                                    </ul>
                                  </div>
                                  <div className="property_aminties">
                                    <ul>
                                      <li>  Full kitchen  </li>
                                      <li> Living room  </li>
                                      {
                                        (item.unit_amenities && item.unit_amenities.length > 0) ? (
                                          item.unit_amenities.map((itm, idx) => {
                                            if (idx <= 1) {
                                              <li key={idx}> {itm} </li>
                                            } else {
                                              return
                                            }
                                          })
                                        ) : (
                                          <>
                                            {/* <li>Pool</li>
                                            <li>Gym</li> */}
                                          </>
                                        )
                                      }

                                    </ul>
                                  </div>
                                  <div className="d-flex align-items-end mob-justify-content-between property_bottom">
                                    <div className="col-md-6">
                                      <h4 className="listing_title m-0">
                                        {item.base_price ? `$${global.OnKeyPressEvent.numberWithCommasForDecimal(parseInt(item.base_price))}/month`:"Not Available"}
                                      </h4>
                                    </div>
                                    <div className="col-md-6">
                                      <button onClick={() => this.redierectToLtrdetail(item, ("/" + (item.property_seo_slug ? item.property_seo_slug  : this.state.property_slug) + "/floorplan/" + item.floorplan_slug + this.state.queryString))}>View floorplan</button>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </>
                          )
                        }
                        )) : (
                        <div className="col-md-12 mg-b-60">
                          <>
                            {
                              this.state.Skeleton ?
                                <div className="row">
                                  <div className="col-md-12"><Typography variant="h1" width={"50%"}><Skeleton /></Typography></div>
                                  <div className="col-md-12"><Typography variant="h3" width={"50%"}><Skeleton /></Typography></div>
                                  <div className="col-md-4">
                                    <Skeleton variant="rectangular" width="100%">
                                      <div style={{ paddingTop: '57%' }} />
                                    </Skeleton>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="col-md-12"><Typography variant="h1" width={"80%"}><Skeleton /></Typography></div>
                                    <div className="col-md-12"><Typography variant="h2" width={"80%"}><Skeleton /></Typography></div>
                                    <div className="col-md-12"><Typography variant="h3" width={"80%"}><Skeleton /></Typography></div>
                                    <div className="col-md-12"><Typography variant="h3" width={"80%"}><Skeleton /></Typography></div>
                                  </div>
                                </div>
                                :
                                // <p>No units found for this search.</p>
                                <p>No units found.</p>
                            }
                            {/* <p>No units found for this search.</p> */}
                          </>

                        </div>
                      )
                      }
                    </div>{/* row */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section>
          <Modal
            show={this.state.availablity_modal_box}
            onHide={() => this.closeAvalModal()}
            animation={false}
            centered
            className="modal-xlg"
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span
                className="welcomeClose float-end"
                onClick={() => this.closeAvalModal()}
              >
                {global.closee}
              </span>
              {/* meta data for index page for seo friendly url */}
              <section className="middle">
                <div className="container-fluid p-0">
                  <section className="ltrunitListing mg-b-10">
                    <div className="container">
                      <h4>Studio</h4>
                      <ul className="subTitle">
                        <li>
                          <h6>
                            {this.state.UnitBedroom > 1
                              ? this.state.UnitBedroom + " Bedrooms"
                              : this.state.UnitBedroom + " Bedroom"}
                          </h6>
                        </li>
                        <li>
                          <h6>
                            {this.state.UnitBathroom > 1
                              ? this.state.UnitBathroom + " Bathrooms"
                              : this.state.UnitBathroom + " Bathroom"}{" "}
                          </h6>
                        </li>
                      </ul>
                      <TableContainer className="simpleTable">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell className="numeric">
                                Apartment
                              </TableCell>
                              <TableCell className="numeric" align="left">
                                Sq.Ft.
                              </TableCell>
                              <TableCell className="numeric" align="left">
                                Rent
                              </TableCell>
                              {/* <TableCell align="left">Amenities</TableCell> */}
                              <TableCell className="numeric" align="left">
                                Date Available
                              </TableCell>
                              <TableCell className="numeric" align="left">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.UnitListing.map((key, i) => (
                              <TableRow>
                                <TableCell data-title="Apartment" align="left">
                                  #{key.unit_number ? key.unit_number : "-"}{" "}
                                </TableCell>
                                <TableCell data-title="Sq.Ft." align="left">
                                  {key.square_foot && !isNaN(key.square_foot)
                                    ? parseInt(key.square_foot)
                                    : "-"}
                                </TableCell>
                                <TableCell data-title="Rent" align="left">
                                  $
                                  {parseInt(key.base_price)
                                    ? "" +
                                    global.OnKeyPressEvent.numberWithCommas(
                                      key.base_price
                                    )
                                    : "-"}
                                </TableCell>
                                {/* <TableCell align="left">
                                  <ul>
                                    {this.state.UnitAmenity.map((amenity)=> {
                                      return(
                                      <li>{amenity}</li>
                                      )
                                    })}
                                  </ul>
                                </TableCell> */}
                                <TableCell
                                  data-title="Date Available"
                                  align="left"
                                >
                                  {key.available === 1 &&
                                    key.available_from.includes(this.state.today)
                                    ? "Available now"
                                    : Moment(
                                      new Date(key.available_from)
                                    ).format("MM-DD-YYYY")}
                                </TableCell>
                                <TableCell data-title="Action" align="left">
                                  <button>Lease now</button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </section>
                </div>
              </section>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.openImagepopup}
            centered
            className="Florplan-box"
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span
                className="welcomeClose float-end"
                onClick={() => this.closeImageModal()}
              >
                {global.closee}
              </span>
              <div>
                <figure className="mg-auto m-auto text-center">
                  <img
                    src={
                      this.state.floorplanImagemodal
                        ? this.state.floorplanImagemodal
                        : global.OnKeyPressEvent.getS3ImageUrl('floor-plan.png')
                    }
                    alt="Floorplan Image"
                    className="w-100"
                    title="Floorplan Image"
                  />
                </figure>
              </div>
            </Modal.Body>
          </Modal>
        </section>
        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default FloorplanListing;