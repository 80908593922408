//     							                          //
//  Program: aminity.jsx                                  //
//  Application: KINI HTML                                //
//  Option: Book A Tour                                   //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import TextField from "@mui/material/TextField";
import { Modal, Spinner } from "react-bootstrap";
import queryString from "query-string";
import Moment from "moment";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import ReservationService from "../../services/ReservationService";
import Timer from "../common/Timer";
import Login from "../common/Login";
import AuthenticationService from "../../services/AuthenticationService";
import { Helmet } from "react-helmet-async";
import SpinnerLoader from "../common/SpinnerLoader";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Datepicker, options, Page } from '@mobiscroll/react';
import DatePickerFile from "../common/DatePickerFile";
import SignUp from "../common/SignUp";


const queryParams = queryString.parse(location.search);

class BookTourForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      listing_slug: localStorage.getItem("listing_slug") ? localStorage.getItem("listing_slug") : queryParams.listing,
      property_name: localStorage.getItem("property_name") ? localStorage.getItem("property_name") : "",
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      editDateModal: false,
      editGuestModal: false,
      oldStartDate: localStorage.getItem("checkin") ? localStorage.getItem("checkin") : queryParams.checkin,
      oldEndDate: localStorage.getItem("checkout") ? localStorage.getItem("checkout") : queryParams.checkout,
      startDate: localStorage.getItem("checkin") ? localStorage.getItem("checkin") : queryParams.checkin,
      endDate: localStorage.getItem("checkout") ? localStorage.getItem("checkout") : queryParams.checkout,
      message: "",
      pricing: localStorage.getItem("pricing") ? JSON.parse(localStorage.getItem("pricing")) : {},
      userToken: localStorage.getItem("userToken") ? localStorage.getItem("userToken") : undefined,
      availableDate: "",
      bookedDate: "",
      enableDatePicker: true,
      redirectToNextPage: false,
      queryString: "",
      guestValue: "1 Guest",
      adultCount: queryParams.numberOfAdults === undefined ? 1 : parseInt(queryParams.numberOfAdults),
      childCount: queryParams.numberOfChildren === undefined ? 0 : parseInt(queryParams.numberOfChildren),
      infantCount: queryParams.numberOfInfants === undefined ? 0 : parseInt(queryParams.numberOfInfants),
      petsCount: queryParams.numberOfPets === undefined ? 0 : parseInt(queryParams.numberOfPets),
      contryCode: {
        code: "CA",
        phone: "1",
        suggested: true
      },
      country_code: "+1",
      phone_number: "",
      user_token: localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "86YTUDINHBMX",
      otp_type: 1,
      display_email: "",
      otp: "",
      signupModel: false,
      email: localStorage.getItem("guest_email") ? localStorage.getItem("guest_email") : "",
      verifyOtpModal: false,
      email_error: "",
      verify_otp_error: "",
      verify_otp: "",
      isVerify: false,
      resendOtp: false,
      guest: 1,
      total_selected_guest: 1,
      loader: false,
      loginModel: false,
      datePickerOpen: false,
      openPicker: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.manageEditDateModal = this.manageEditDateModal.bind(this);
    this.manageGuestModal = this.manageGuestModal.bind(this);
    this.Setclose = this.Setclose.bind(this);
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    this.incrementpets = this.incrementpets.bind(this);
    this.decrementpets = this.decrementpets.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.loginModel = this.loginModel.bind(this);
    this.signupModel = this.signupModel.bind(this);
    this.placeReservation = this.placeReservation.bind(this);
     this.checkAvailabilityDate = this.checkAvailabilityDate.bind(this);
    // console.log(window.location);
    this.createQueryString();
    this.changeDateHandlerNew = this.changeDateHandlerNew.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateChangeRange = this.handleDateChangeRange.bind(this);
  }

  componentDidMount() {
    this.checkAvailabilityDate();
    localStorage.removeItem("sign_in_email");
    this.setState({ guest: parseInt(localStorage.getItem("maximum_number_of_guests")) });
    this.guestDetail();
    localStorage.setItem("queryParams", JSON.stringify(queryParams));
  }

  placeReservation = async (dynamicData = "") => {
    let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : "";
    var inputData = {
      property_slug: this.state.property_slug,
      listing_slug: this.state.listing_slug,
      set_channel: 1,
      checkin_date: Moment(this.state.startDate).format("YYYY-MM-DD"),
      checkout_date: Moment(this.state.endDate).format("YYYY-MM-DD"),
      phone_number: userData.phone_number ? userData.phone_number : "",
      first_name: userData.first_name ? userData.first_name : "",
      last_name: userData.last_name ? userData.last_name : "",
      email: userData.email ? userData.email : "",
      adults: this.state.adultCount ? this.state.adultCount : 1,
      children: this.state.childCount ? this.state.childCount : 0,
      infants: this.state.infantCount ? this.state.infantCount : 0,
      pets: this.state.petsCount ? this.state.petsCount : 0,
      user_token: this.state.userToken ? this.state.userToken : "",
      level: 5,
    };
    let res = await ReservationService.storeReservation(inputData);
    var reservation_slug = "";
    var guest_slug = "";
    var listing_name = "";
    var confirmation_code= "";
    await this.createQueryString();
    if (global.successStatus.includes(res.status)) {
      reservation_slug = res.data ? res.data.reservation_slug : "";
      guest_slug = res.data ? res.data.guest_slug : "";
      confirmation_code= res.data ? res.data.confirmation_code:"";
      listing_name = res.data ? res.data.listing_name : "";
      localStorage.setItem("reservation_slug", reservation_slug);
      localStorage.setItem("confirmation_code", confirmation_code);
      localStorage.setItem("guest_slug", guest_slug);
      localStorage.setItem("listing_name", listing_name);
      localStorage.setItem("reservationDetails", JSON.stringify(inputData));
      window.location.href = global.themeUrl + "" + this.state.property_slug + "/book-tour-payment" + this.state.queryString;
    } else {
      this.setState({ place_reservation_error: res.data.message });
    }
  };

  loginModel = (value,signup=false) => {
    this.setState({ loginModel: value });
    this.setState({ signupModel: signup });
    // console.log(this.state, value);
  }
  signupModel = (value) => {
    this.setState({ signupModel: value });
    this.setState({ loginModel: false });
  }

  verifyEmail = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    if (
      this.state.email !== "" &&
      this.state.email_error === ""
    ) {
      let input = {
        email: this.state.email
      };
      let res = await AuthenticationService.validateEmail(input);
      if (global.successStatus.includes(res.status)) {
        await localStorage.setItem("sign_in_email", this.state.email);
        this.loginModel(true);
        // console.log(window.location);
      } else {
        this.sendOtp();
      }
    } else {
      if (!this.state.email) {
        this.setState({ email_error: "Please enter valid email." });
      }
    }
    this.setState({ loader: false });
  };

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      this.setState({ email_error: "Please enter valid email." });
    } else {
      this.setState({ email_error: "" });
    }
    console.log("validate");
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    if (event.target.name === "email") {
      if (event.target.value.length > 8) {
        this.setState({ email_error: "" });
      }
    }

    if (event.target.name === "email") {
      if (event.target.value.length > 7) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(event.target.value) === false) {
          var email_error = "Please enter valid email.";
          this.setState({ email_error: email_error });
        } else {
          var email_error = "";
          this.setState({ email_error: email_error });
        }
      }
    }


    if (event.target.name === "verify_otp") {
      if (event.target.value.length > 5) {
        this.setState({ verify_otp_error: "" });
      }
    }
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  manageVerifyModal = (value = false) => {
    this.setState({ verifyOtpModal: value });
  };

  setCountryCode = (newValue) => {
    var contryCode = "+" + newValue.phone;
    localStorage.setItem("country_code_value", JSON.stringify(newValue));
    this.setState({ contryCode: newValue, country_code: contryCode });
  };

  resendOtp = async () => {
    this.setState({ loader: true });
    // if (!this.state.phone_number) {
    //   this.setState({
    //     phone_number_error: "Please enter phone number for sign in.",
    //   });
    // } else {
    //   var inputData = {
    //     user_token: this.state.user_token,
    //   };
    if (!this.state.email) {
      this.setState({
        email_error: "Please enter valid email.",
      });
    } else {
      var inputData = {
        user_token: this.state.user_token,
      };
      let res = await ReservationService.resendOtp(inputData);
      var phone_number = "";
      var otp = "";
      if (global.successStatus.includes(res.status)) {
        otp = res.data && res.data.otp ? res.data.otp : "";
        this.setState({
          otp: otp,
          verifyOtpModal: true,
          phone_number_error: "",
        });
      }
    }
    this.setState({ loader: false });
  };

  sendOtp = async () => {
    this.setState({ loader: true });
    if (!this.state.email) {
      this.setState({
        email_error: "Please enter valid email.",
      });
    } else {
      var inputData = {
        country_code: this.state.country_code,
        email: this.state.email,
        user_token: this.state.user_token,
        otp_type: this.state.otp_type,
      };
      let res = await ReservationService.sendOtp(inputData);
      var phone_number = "";
      var otp = "";
      if (global.successStatus.includes(res.status)) {
        phone_number = res.data && res.data.mobilePhone ? res.data.mobilePhone : "";
        otp = res.data && res.data.otp ? res.data.otp : "";
        this.setState({
          display_email: phone_number,
          otp: otp,
          verifyOtpModal: true,
          email_error: "",
        });
      }
    }
    this.setState({ loader: false });
  };

  verifyOtp = async () => {
    this.setState({ loader: true });
    if (!this.state.verify_otp || this.state.verify_otp.length !== 6) {
      this.setState({
        verify_otp_error: "Please enter valid code for sign in.",
      });
    } else {
      this.setState({ verify_otp_error: "" });
      var inputData = {
        user_token: this.state.user_token,
        otp: this.state.verify_otp,
      };
      let res = await ReservationService.verifyOtp(inputData);
      await this.createQueryString();
      if (global.successStatus.includes(res.status)) {
        localStorage.setItem("checkin", this.state.startDate);
        localStorage.setItem("checkout", this.state.endDate);
        localStorage.setItem("guest_email", this.state.email);
        await this.setState({ verifyOtpModal: false, verify_otp: "", otp: "" });
        var unit = document.getElementById("book-tour-page");
        unit.click();
        // this.props.history.push(global.themeUrl + this.state.property_slug + "/book-tour-basic-info"+this.state.queryString);
      } else {
        this.setState({
          verify_otp_error: "Your OTP token has been expired or invalid. Please try to resent it.",
          resendOtp: true,
        });
      }
    }
    this.setState({ loader: false });
  };

  createQueryString = () => {
    var queryString = "";
    queryString = `?checkin=${this.state.startDate}&checkout=${this.state.endDate}&numberOfAdults=${this.state.adultCount}&numberOfChildren=${this.state.childCount}&numberOfInfants=${this.state.infantCount}&numberOfPets=${this.state.petsCount}&listing=${this.state.listing_slug}`;
    this.setState({ queryString: queryString });
  };

  checkAvailabilityDate = () => {
    var inputData = {
      listing_slug: this.state.listing_slug,
      checkin_date: this.state.startDate,
      checkout_date: this.state.endDate,
      user_token: this.state.userToken,
      level: 5
    };
    this.getAvailibityDate(inputData);
    this.createQueryString();
  };

  guestDetail = () => {
    var message = "";
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Children"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    this.setState({ guestValue: message });
    // return message;
  };

  changeDateHandlerNewMobile = async (date) => {
    var startDate = date.value[0] ? Moment(date.value[0]).format("YYYY-MM-DD") : "";
    var endDate = date.value[1] ? Moment(date.value[1]).format("YYYY-MM-DD") : "";
    // if(startDate !== "" && (endDate === "" || endDate === null )){
    //   await this.setState({endDate:Moment(startDate).add(1, "days").format("YYYY-MM-DD")})
    //   endDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
    // }
    await this.setState({ startDate: startDate, endDate: endDate, redirectToNextPage: true });
    await this.createQueryString();
    // await this.creatAirBnbQueryString();
    await this.checkAvailabilityDate();
  };

  changeDateHandlerNew = async (date) => {
    var startDate = date.value[0] ? Moment(date.value[0]).format("YYYY-MM-DD") : "";
    var endDate = date.value[1] ? Moment(date.value[1]).format("YYYY-MM-DD") : "";
    // if(startDate !== "" && (endDate === "" || endDate === null )){
    //   await this.setState({endDate:Moment(startDate).add(1, "days").format("YYYY-MM-DD")})
    //   endDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
    // }
    await this.setState({ startDate: startDate, endDate: endDate });
    await this.checkAvailabilityDate();
    await this.createQueryString();
    // await this.creatAirBnbQueryString();
  };

  handleDateChange = async (date, name) => {
    let input = this.state.input;
    let a = new Date(date.valueText);
    input.move_in_date = Moment(new Date(date.value)).format("MM/DD/YYYY");
    await this.setState({ selectedDate: input.move_in_date, input: input });
  }

  handleDateChangeRange = async (date, name) => {
    let input = this.state.input;
    input.move_in_date = Moment(new Date(date.value[0])).format("MM/DD/YYYY");
    input.move_out_date = Moment(new Date(date.value[1])).format("MM/DD/YYYY");
    await this.setState({ startDate: input.move_in_date, endDate: input.move_out_date, input: input });
  }

  validateDateChangeMobile = async () => {
    if (this.state.startDate === "") {
      this.setState({ openPicker: true });
    } else {
      await this.checkAvailabilityDate();
      //   this.setState({redirectToNextPage:true});
    }
    if (this.state.startDate !== "" && (this.state.endDate === "" || this.state.endDate === null)) {
      this.setState({ endDate: Moment(this.state.startDate).add(1, "days") })
    }
  }

  async getAvailibityDate(dynamicData = "") {
    this.setState({ showSpinner: true, loading: true });
    var inputData = dynamicData !== ""
      ? dynamicData
      : {
        listing_slug: this.state.listing_slug,
        checkin_date: this.state.startDate,
        checkout_date: this.state.endDate,
        // user_token: this.state.userToken,
      };
      
    let res = await ReservationService.getAvailibityDate(inputData);
    var availableDate = [];
    var bookedDate = [];
    var pricing = [];
    var display_main_image_property = "";
    var userToken = "";
    var message = "";
    var redirectToNextPage = false;

    //let res = await UnitlistingApi.getUnitlisting();
    if (global.successStatus.includes(res.status)) {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      availableDate = res.data && res.data.available_dates ? res.data.available_dates : [];
      bookedDate = res.data && res.data.reserved_dates ? res.data.reserved_dates : [];
      pricing = res.data && res.data.pricing ? res.data.pricing : {};
      display_main_image_property =  res.data && res.data.property ? res.data.property.property_main_image_url : "";

      redirectToNextPage = res.data && res.data.pricing ? true : false;
      if (pricing) {
        localStorage.setItem('pricing', JSON.stringify(pricing));
      }
      if (this.state.startDate && this.state.endDate) {
        localStorage.setItem("checkin", this.state.startDate);
        localStorage.setItem("checkout", this.state.endDate);
      }
      
    } else {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      message = res.data && res.data.message ? res.data.message : "";
      availableDate = res.data && res.data.available_dates ? res.data.available_dates : [];
      bookedDate = res.data && res.data.reserved_dates ? res.data.reserved_dates : [];
      redirectToNextPage = false;
    }
    if (this.state.userToken === undefined || this.state.userToken === "" || !this.state.userToken) {
      localStorage.setItem("userToken", userToken);
    }

    this.setState({
      availableDate: availableDate,
      bookedDate: bookedDate,
      enableDatePicker: false,
      message: message,
      pricing: pricing,
      display_main_image_property:display_main_image_property,
      redirectToNextPage: redirectToNextPage
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async incrementAdult() {
    await this.setState({ adultCount: this.state.adultCount + 1, total_selected_guest: this.state.total_selected_guest + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({ adultCount: this.state.adultCount - 1, total_selected_guest: this.state.total_selected_guest - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({ childCount: this.state.childCount + 1, total_selected_guest: this.state.total_selected_guest + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({ childCount: this.state.childCount - 1, total_selected_guest: this.state.total_selected_guest - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementpets() {
    await this.setState({ petsCount: this.state.petsCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementpets() {
    if (this.state.petsCount === 0) {
      await this.setState({ petsCount: 0 });
    } else {
      await this.setState({ petsCount: this.state.petsCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  onDateChange(startDate, endDate) {
    // console.log(this.state.availableDate,Moment(startDate).format("YYYY-MM-DD"))
    // var valid = this.state.availableDate.includes(Moment(startDate).format("YYYY-MM-DD"));
    // console.log(valid);
    this.setState({
      startDate: startDate
        ? Moment(startDate).format("YYYY-MM-DD")
        : new Date(),
      endDate: endDate
        ? Moment(endDate).format("YYYY-MM-DD")
        : Moment(startDate).format("YYYY-MM-DD"),
    });
    // this.getAvailibityDate();
  }

  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }

  manageEditDateModal(modal = false) {
    this.setState({ editDateModal: modal, enableDatePicker: false });
  }
  manageGuestModal(modal = false) {
    this.setState({ editGuestModal: modal });
  }
  Setclose(e) {
    var unit = document.getElementById("dropdown-basic");
    unit.click();
  }

  render() {
    const min =  Moment(new Date()).format("YYYY-MM-DD");
    return (
      
      <main className="oh-template Brandmainpage">
        {/* Header */}
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <section className="middle">
          <div className="container-fluid p-0">
            <section className="reservationBook req_book_sec bg-white bgwhitecolor">
              <div className="container">
                <div className="content">

                  <div className="row mobView m-0-">
                    <div className="col-sm-12 col-lg-12 headingpage">
                      <div className="d-flex ResivationTitle">
                        <a className="m-0" href={global.themeUrl + this.state.property_slug + "/rooms/" + this.state.listing_slug + "?unit=" + this.state.pricing.listing_name}><img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Book now" title="Book now" /></a>
                        {/* <h4> Request to book </h4> */}
                        <h4> Book now </h4>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 yourtrip">

                      <div className="requestSection">
                        <div className="row book_app_info m-0">
                          <div className="col-md-12 pd-l-0 pd-r-0 mg-b-0 d-flex- flex-column align-items-start justify-content-start first">
                            <span className="pd-t-10 pd-l-0 pd-r-0 pd-b-20 labelTitle">Your trip </span>

                            {this.state.message && (
                              <>
                                <p className="text-danger">
                                  {" "}
                                  {this.state.message}
                                </p>
                              </>
                            )}
                            <div className="col-md-12 pd-l-0 pd-r-0 mg-b-30 d-flex flex-row align-items-start justify-content-between">
                              <div className="w-75">
                                <strong className="d-block">Dates</strong>
                                
                                <span className="d-block dateinputmobile">
                                  
                                  <Datepicker
                                    controls={['calendar']}
                                    buttons={[{
                                      // text: "X",
                                      icon: "material-close",
                                      handler: 'cancel'
                                    }]}

                                    pages={2}
                                    calendarType="month"
                                    // invalid={[]}
                                    select="range"
                                    inputComponent="input"
                                    // inputProps={inputProps}
                                    // selected={this.state.selectedDate}
                                    value={[this.state.startDate, this.state.endDate]}
                                    onChange={(date) =>
                                      this.changeDateHandlerNew(date, "dateFour")
                                    }
                                    // invalid={this.state.bookedDate}
                                    valid={this.state.availableDate}
                                    cssClass="dual_calendar"
                                    inputProps={{
                                      placeholder: 'Add date'
                                    }}
                                    isOpen={this.state.openPicker}
                                    openPicker={() => this.setState({ openPicker: true })}
                                    onLabelClick={() => this.setState({ openPicker: true })}
                                    onInputClick={() => this.setState({ openPicker: true })}
                                    onFocus={() => this.setState({ openPicker: true })}
                                    onClick={() => this.setState({ openPicker: true })}
                                    onClose={() => this.setState({ openPicker: false })}
                                    closeOnEsc={true}
                                    defaultValue={undefined}
                                    display="center"
                                    rangeSelectMode="wizard"
                                    showRangeLabels={true}
                                    touchUi={false}
                                    theme="ios"
                                    themeVariant="light"
                                    headerText=""
                                    minRange={2}
                                    max=""
                                    min= {min}
                                    rangeStartLabel="Check-in"
                                    rangeEndLabel="Check-out"
                                    inRangeInvalid={false}
                                    rangeEndInvalid={true}
                                    responsive={{
                                      xsmall: {
                                        pages: 2,
                                        touchUi: false,
                                        calendarSize: "2",
                                        calendarScroll: "horizontal",
                                        display: 'anchored',
                                        refDate: Moment(new Date()).add(1, "year"),
                                      },
                                      medium: {
                                        // display: 'center',
                                        // display: 'anchored',
                                        pages: 2,
                                        touchUi: false,
                                      }
                                      // custom: {
                                      //   breakpoint: 850,
                                      //   display: 'center',
                                      // pages: 2,
                                      //   touchUi: false
                                      //}
                                    }}
                                  /> 


                                </span>
                              </div>
                              <p
                                className="Link"
                                onClick={() =>
                                  this.setState({ openPicker: true })
                                }
                              >
                                Edit
                              </p>



                            </div>

                            <div className="col-md-12 pd-l-0 pd-r-0 d-flex flex-row align-items-start justify-content-between guestedit">
                              <div>
                                <strong className="d-block">Guests</strong>
                                <span>{this.state.guestValue}</span>
                              </div>
                              <p
                                className="Link"
                                onClick={() => this.manageGuestModal(true)}
                              >
                                Edit
                              </p>



                            </div>

                          </div>
                          {/* <div className="col-md-6 pd-l-10 pd-r-10 mg-b-30 d-flex flex-column align-items-end justify-content-start last">
                            <p
                                className="Link"
                                onClick={() => this.manageGuestModal(true)}
                              >
                                Edit
                              </p>


                          </div> */}



                        </div>



                      </div>
                      <div className="requestSection">
                        <div className="row book_pay_info m-0">
                          <div className="col-xs-12 col-sm-12 col-md-12 pd-l-0 pd-r-0 mg-b-10">
                            <span className="pd-t-10 pd-b-20 text-left d-block signsection">
                              Sign in or sign up to book
                            </span>
                            <div className="countryphone">
                              <TextField
                                type="text"
                                id="Email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                name="email"
                                onBlur={() => this.validateEmail()}
                                onChange={this.changeHandler}
                                required
                                value={this.state.email}
                                error={
                                  this.state.email_error
                                    ? true
                                    : false
                                }
                              />

                            </div>
                            {/* <p className="mg-t-15">
                                  We’ll  text you to confirm your email.{" "}
                                </p> */}

                            {this.state.email_error && (
                              <>
                                <p className="text-danger css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input">
                                  {" "}
                                  {this.state.email_error}
                                </p>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mg-b-0 paybtn">
                          {/* <a
                              href={
                                global.themeUrl +
                                this.state.property_slug +
                                "/book-tour-basic-info"
                              }
                              className="w-100 text-decoration-none"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              <button className="w-100" onClick={() => this.sendOtp}>Continue</button>
                            </a> */}
                          <button
                            className=""
                            // onClick={() => this.sendOtp()}
                            onClick={(e) => this.verifyEmail(e)}
                            disabled={this.state.loader ? true : false}
                          >
                            Continue {this.state.loader ?
                              <>
                                <SpinnerLoader />
                              </>
                              : ""
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-xl-1"></div>
                    {this.state.redirectToNextPage === true ? (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 position-relative priceDetail">
                      <div className="requestaddedSection">
                        <figure>
                          <img src={this.state.pricing ? this.state.pricing.listing_image ? this.state.pricing.listing_image.large_img_w_720 : this.state.display_main_image_property : this.state.display_main_image_property} alt="No image" className="img-fluid" title="No image" />
                        </figure>
                        <div className="priceInfo">
                          <div className="priceTop">
                            <h5>
                              {this.state.pricing.listing_name ? this.state.pricing.listing_name : (this.state.property_name ?
                                this.state.property_name : "")}
                            </h5>
                            {/* <h6>
                              {`${this.state.pricing.checkin_date
                                ? Moment(this.state.pricing.checkin_date).format('ddd, MMM DD')
                                : Moment(this.state.startDate).format('ddd, MMM DD')
                                } - ${this.state.pricing.checkout_date
                                  ? Moment(this.state.pricing.checkout_date).format('ddd, MMM DD')
                                  : Moment(this.state.endDate).format('ddd, MMM DD')
                                }`}{" "}
                              <b>
                                {" "}
                                {`${this.state.pricing.nights
                                  ? this.state.pricing.nights
                                  : 1
                                  }`}{this.state.pricing.nights > 1 ? " Nights" : " Night"}
                              </b>
                            </h6> */}
                          </div>
                          <div className="priceMiddle">
                            <span className="mg-b-20 d-block">Price details</span>
                            <ul>
                              <li>
                                <span>{`$${this.state.pricing.listing_price
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.listing_price / this.state.pricing.nights
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseFloat(1).toFixed(2)
                                  }  × ${this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                  }`}{this.state.pricing.nights > 1 ? " nights" : " night"}</span>
                                <span>{`$${this.state.pricing.listing_price ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.listing_price).toFixed(2)).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}`}</span>
                              </li>
                              {/* {this.state.pricing ? this.state.pricing.fees ?
                                <li>
                                  <span>Service fee</span>
                                  <span>{`$${this.state.pricing.fees ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.fees).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>
                                : "" : ""} */}
                              {this.state.pricing ? this.state.pricing.cleaning_fee ?
                                <li>
                                  <span>Cleaning fee</span>
                                  <span>{`$${this.state.pricing.cleaning_fee ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>
                                : "" : ""}
                              <li>
                                <span>Service fees</span>

                                {/* <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                {/* <span>${this.state.pricing.nightly_rate * 8 /100}</span> */}
                                <span>{`$${this.state.pricing.convenienceFee
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.convenienceFee
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseInt(0).toFixed(2)
                                  }`}</span>
                              </li>
                              {this.state.pricing ? this.state.pricing.total_of_dollar_taxes ?
                                <li>
                                  <span>Occupancy taxes</span>
                                  {/* <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                  <span>{`$${this.state.pricing.total_of_dollar_taxes ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>
                                : "" : ""}

                            </ul>
                          </div>
                          <div className="priceBottom">
                            <ul>
                              <li>
                                <span>Total (USD)</span>
                                {/* <span>{` US$${
                                  this.state.pricing
                                    .total_listing_price_with_taxes_fees
                                    ? parseFloat(
                                        this.state.pricing
                                          .total_listing_price_with_taxes_fees
                                      ).toFixed(2)
                                    : parseInt(0).toFixed(2)
                                }`}</span> */}
                                <b>
                                  <span>{`$${this.state.pricing
                                    .total_listing_price_with_taxes_fees
                                    ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                      (parseFloat(this.state.pricing
                                        .total_listing_price_with_taxes_fees).toFixed(2).replace(/\.00$/, ""))
                                    )
                                    : parseInt(0).toFixed(2)
                                    }`}</span>
                                </b>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>):""}
                  </div>
                </div>
              </div>
              {/* <div className="container pd-l-35 pd-r-35 mg-t-35 mg-b-35 mobAminity"> */}
              {/* <figure>
                                            <img src={aminityListimg1} className="img-fluid" />
                                        </figure> */}
              {/* </div> */}
              <a
                hidden
                className="text-decoration-none w-100 hidden"
                href={
                  global.themeUrl +
                  this.state.property_slug +
                  "/book-tour-basic-info" +
                  this.state.queryString
                }
              >
                <button id="book-tour-page" className="w-100">
                  continue
                </button>
              </a>
            </section>
          </div>
        </section>

        {/* modal for date and guest is here */}

        <section>
          <Modal
            show={this.state.editDateModal}
            centered
            onHide={() => this.manageEditDateModal(false)}
            className="DateModalbox"
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span
                className="welcomeClose float-end"
                onClick={() => this.manageEditDateModal(false)}
              >
                {global.closee}
              </span>
              <div>
                <RangeDatePicker
                  startDate={
                    this.state.startDate ? this.state.startDate : new Date()
                  }
                  endDate={this.state.endDate ? this.state.endDate : new Date()}
                  minDate={new Date()}
                  startDatePlaceholder="Add Date"
                  endDatePlaceholder="Add Date"
                  monthFormat="MM-DD-YYYY"
                  onChange={(startDate, endDate) =>
                    this.onDateChange(startDate, endDate)
                  }
                  disabled={this.state.enableDatePicker}
                />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.editGuestModal}
            centered
            onHide={() => this.manageGuestModal(false)}
            className="guestModalbox"
          >
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span
                className="welcomeClose float-end"
                onClick={() => this.manageGuestModal(false)}
              >
                {global.closee}
              </span>
              <div className="dropmenuInputbox">
                <h4>Guests</h4>
                {/* <p>
                  This place has a maximum of 6 guests, not including infants.
                  Pets aren't allowed.
                </p> */}

                <div className="dropdown-item">
                  <div className="d-flex justify-content-between align-items-start mg-t-15 mg-t-15">
                    <ul>
                      <li>
                        <strong>Adults</strong>
                        <span>Age 13+</span>
                      </li>
                    </ul>
                    <div className="d-flex align-items-center countBtn">
                      <span>
                        <button onClick={this.decrementAdult}
                          disabled={this.state.adultCount === 1 ? true : false}>
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                        </button>
                      </span>
                      <span> {this.state.adultCount}</span>
                      <span>
                        <button onClick={this.incrementAdult} disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}>
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                        </button>
                      </span>

                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start mg-t-15 mg-t-15">
                    <ul>
                      <li>
                        <strong>{this.state.childCount > 1 ? "Children":"Child"}</strong>
                        <span>Ages 2–12</span>
                      </li>
                    </ul>
                    <div className="d-flex align-items-center countBtn">
                      <span>
                        <button onClick={this.decrementchild} disabled={this.state.childCount === 0 ? true : false}>
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                        </button>
                      </span>
                      <span>{this.state.childCount}</span>
                      <span>
                        <button onClick={this.incrementchild} disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}>
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start mg-t-15 mg-t-15">
                    <ul>
                      <li>
                        <strong>Infants</strong>
                        <span>Under 2</span>
                      </li>
                    </ul>
                    <div className="d-flex align-items-center countBtn">
                      <span>
                        <button onClick={this.decrementinfant} disabled={this.state.infantCount === 0 ? true : false}>
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                        </button>
                      </span>
                      <span> {this.state.infantCount}</span>
                      <span>
                        <button onClick={this.incrementinfant} disabled={this.state.infantCount === 5 ? true : false}>
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                        </button>
                      </span>

                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                      <ul>
                        <li>
                          <strong>Pets</strong>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center countBtn">
                        <span>
                          <button onClick={this.decrementpets} disabled={this.state.decrementpets === 0 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} />
                          </button>
                        </span>
                      <span> {this.state.petsCount}</span>
                        <span>
                          <button onClick={this.incrementpets} disabled={this.state.incrementpets === 5 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} />
                          </button>
                        </span>
                      </div>
                    </div> */}

                  <div className="d-flex justify-content-end">
                    <button
                      className="done"
                      id="cnt-close"
                      onClick={() => this.manageGuestModal(false)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.verifyOtpModal} centered className="otpModal">
            <Modal.Body className="width_402 p-4 border_radius_14">
              <span
                className="welcomeClose float-end"
                onClick={() => this.manageVerifyModal(false)}
              >
                {global.closee}
              </span>
              <div>
                <figure className="mg-auto m-auto text-center">
                  {this.state.isVerify ? (
                    <>
                      <h2 className="text-success">
                        Code Verified Successfully
                      </h2>
                      <div className="d-flex  mg-t-30 reservationBook bg-white">
                        <a
                          className="text-decoration-none w-100"
                          href={
                            global.themeUrl +
                            this.state.property_slug +
                            "/book-tour-basic-info"
                          }
                        >
                          <button id="book-tour-page" className="w-100">
                            continue {this.state.loader ?
                              <>
                                {/* <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                /> */}

                                <SpinnerLoader />
                                
                              </>
                              : ""
                            }
                          </button>
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <h4 className="text-center">
                        <b>Please enter code to sign in</b>
                      </h4>
                      <h6 className="mg-b-30">
                        <b>Code sent to :</b> {this.state.display_email}
                      </h6>
                      {/* <h6>
                        <b>Code :</b> {this.state.otp}
                      </h6> */}

                      <TextField
                        type="number"
                        id="OTP"
                        label="Enter Code"
                        variant="outlined"
                        fullWidth
                        name="verify_otp"
                        onChange={this.changeHandler}
                        required
                        error={this.state.verify_otp_error ? true : false}
                        value={this.state.verify_otp}
                      />
                      {this.state.verify_otp_error && (
                        <>
                          <p className="text-danger">
                            {" "}
                            {this.state.verify_otp_error}
                          </p>
                        </>
                      )}
                      <div className="d-flex  mg-t-30 reservationBook bg-white">
                        <button
                          className="w-100"
                          onClick={() => this.verifyOtp()}
                        >
                        
                          Verify {this.state.loader ?
                            <>
                              {/* <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                              /> */}

                               <SpinnerLoader />
                            </>
                            : ""
                          }
                        </button>
                      </div>
                      <Timer
                        minutes={global.countdown_time} // env data ( Pass the no of minutes:seconds that you want count )
                        resendEvent={this.resendOtp.bind(this)} //  Resend button event you can pass your function name here
                      />
                    </>
                  )}
                </figure>
              </div>
            </Modal.Body>
          </Modal>
        </section>
        {/* {
          this.state.loginModel && ( */}
            <section>
              <Login email={this.state.email} placeReservation={this.placeReservation.bind(this)} isPlaceReservation={true} redirectTo={`${global.themeUrl}${this.state.property_slug}/book-tour-payment${this.state.queryString}`} propertySlug={this.state.property_slug} isLogin={this.state.loginModel} manageLogin={this.loginModel.bind(this)} manageSignUp={this.signupModel.bind(this)}/>
            </section>
          {/* )
        } */}
          <section>
            <SignUp redirectTo={"/"} propertySlug={this.state.property_slug} isSignUp={this.state.signupModel} manageLogin={this.loginModel.bind(this)} manageSignUp={this.signupModel.bind(this)} />
          </section>
        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default BookTourForm;
