import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Component } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import AuthenticationService from "../../services/AuthenticationService";
import ReservationService from "../../services/ReservationService";
import Timer from "./Timer";
import Login from "./Login";
import SpinnerLoader from "../common/SpinnerLoader";
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      email: "",
      confirm_email: "",
      first_name: "",
      last_name: "",
      password: "",
      isAdult: false,
      emailError: "",
      passwordError: "",
      loginError: "",
      forgetPasswordError: "",
      ForgetPassword: false,
      redirectTo: this.props.redirectTo ? this.props.redirectTo : "/",
      property_slug: this.props.propertySlug ? this.props.propertySlug : global.property_slug,
      loginModel: false,
      nextScreen: false,
      verify_otp_screen: false,
      otp_type: 1,
      user_token: "",
      errors: {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        confirm_email: "",
        isAdult: ""
      },
      websiteUrl: window.location.href
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.loginModel = this.loginModel.bind(this);
  }


  componentDidMount() {
  }

  loginModel = async (value, signup) => {
    this.props.manageSignUp(false);
    await this.setState({ loginModel: value });
    await this.setState({ signupModel: signup });
    await this.setState({ isSignUp: signup });
  }

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      let errors = this.state.errors;
      errors.email = "Please enter valid email."
      this.setState({ errors: errors });
    } else {
      let errors = this.state.errors;
      errors.email = ""
      this.setState({ errors: errors });
    }
  }

  passwordCheckFun = () => {
    let errors = this.state.errors;
    let passwordCheck = global.OnKeyPressEvent.validatePassword(this.state.password);
    if (passwordCheck !== "") {
      errors.password = passwordCheck
    } else {
      errors.password = "";
    }
    this.setState({ errors: errors });
  }

  ForgetPassword = async (e) => {
    e.preventDefault();
    if (
      this.state.email !== "" &&
      this.state.emailError === ""
    ) { // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        email: this.state.email,
      }
      let res = await AuthenticationService.forgotPass(input);
      if ((global.successStatus).includes(res.status)) {
        // localStorage.clear();
        // setTimeout(() => this.props.history.push('/signin'), global.redirect_time);
        setTimeout(() => this.setState({ ForgetPassword: false }), global.redirect_time);
      } else {
        this.setState({ forgetPasswordError: (res.data.message) ? res.data.message : '' })
        setTimeout(() => this.setState({ ForgetPassword: false }), global.redirect_time);
      }
    } else {
      if (!this.state.email) {
        this.setState({ emailError: "Please enter valid email." });
      }
    }
  }

  resendOtp = async () => {
    this.setState({ loader: true });
    var inputData = {
      user_token: this.state.user_token,
    };
    let res = await ReservationService.resendOtp(inputData);
    var otp = "";
    var user_token = "";
    if (global.successStatus.includes(res.status)) {
      otp = res.data && res.data.otp ? res.data.otp : "";
      user_token = res.data && res.data.user_token ? res.data.user_token : "";
      this.setState({
        otp: otp,
        user_token: user_token,
        phone_number_error: "",
      });
    }
    this.setState({ loader: false });
  };

  verifyOtp = async () => {
    this.setState({ loader: true });
    if (!this.state.verify_otp || this.state.verify_otp.length !== 6) {
      this.setState({
        verify_otp_error: "Please enter valid code for sign up.",
      });
    } else {
      this.setState({ verify_otp_error: "" });
      var inputData = {
        user_token: this.state.user_token,
        otp: this.state.verify_otp,
      };
      let res = await ReservationService.verifyOtp(inputData);
      if (global.successStatus.includes(res.status)) {
        await this.setState({ nextScreen: true, verify_otp_screen: false, verify_otp: "", otp: "" });
      } else {
        this.setState({
          verify_otp_error: "Your code has been expired or invalid. Please try to resent it.",
          resendOtp: true,
        });
      }
    }
    this.setState({ loader: false });
  };

  validateUserEmail = async () => {
    // e.preventDefault();
    let validation = true;
    let errors = this.state.errors;
    if (this.state.email === "") {
      errors.email = "Please enter valid email."
      validation = false;
    }
    this.setState({ loader: true });
    if (
      this.state.email !== "" &&
      this.state.errors.email === ""
    ) {
      let input = {
        email: this.state.email
      };
      // let res = await AuthenticationService.validateEmail(input);
      let res = await AuthenticationService.validateGuestEmail(input); //stg
      if (global.successStatus.includes(res.status)) {
        await localStorage.setItem("sign_in_email", this.state.email);
        this.setState({ emailPresent: res.message });
        setTimeout(() => {
          this.props.manageSignUp(false);
          this.loginModel(true);
        }, 4000);
      } else {
        var phone_number = "";
        var otp = "";
        var user_token = "";
        phone_number = res.data && res.data.mobilePhone ? res.data.mobilePhone : "";
        otp = res.data && res.data.otp ? res.data.otp : "";
        user_token = (res.data && res.data.user_token) ? res.data.user_token : "";
        this.setState({
          display_email: phone_number,
          otp: otp,
          verifyOtpModal: true,
          user_token: user_token,
          email_error: "",
          verify_otp_screen: true
        });
        // this.setState({nextScreen: true});
      }
    } else {
      // let errors = this.state.errors;
      if (!this.state.email) {
        this.setState({ emailError: "Please enter valid email." });
      }
    }
    this.setState({ loader: false });
  };

  checkValidation = () => {
    let validation = true;
    let errors = this.state.errors;
    if (this.state.first_name === "") {
      errors.first_name = "Please enter first name."
      validation = false;
    }

    if (this.state.last_name === "") {
      errors.last_name = "Please enter last name."
      validation = false;
    }

    if (this.state.password === "") {
      errors.password = "Please enter valid password."
      validation = false;
    } else {
      let passwordCheck = global.OnKeyPressEvent.validatePassword(this.state.password);
      if (passwordCheck !== "") {
        errors.password = passwordCheck
        validation = false;
      }
    }
    // if (!this.state.isAdult) {
    //   errors.isAdult = "Please agree the terms of service and privacy policy and continue.";
    //   validation = false;
    // }

    // if(this.state.confirm_email === ""){
    //   errors.confirm_email = "Confirm email is required."
    //   validation = false;
    // }

    if (this.state.email === "") {
      errors.email = "Please enter valid email."
      validation = false;
    }

    // if((this.state.email !== "" && this.state.confirm_email !== "") && (this.state.email !== this.state.confirm_email)){
    //   errors.email = "Email and confirm email must be same!"
    //   validation = false;
    // }
    this.setState({ errors: errors });
    return validation;
  }

  createAccount = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    let isValid = await this.checkValidation();
    if (isValid) {
      let input = {
        email: this.state.email,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        password: this.state.password,
        fallback_url: this.state.websiteUrl
      };
      let res = await AuthenticationService.signup(input);
      if (global.successStatus.includes(res.status)) {
        let res = await AuthenticationService.signin(input);
        if (global.successStatus.includes(res.status)) {
          await localStorage.setItem("headerToken", res.data.headerToken ? res.data.headerToken : "");
          global.headerToken = res.data.headerToken ? res.data.headerToken : "";
          // localStorage.setItem('isLogin', 1);
          await localStorage.setItem("isPopupShow", 1);
          await localStorage.setItem("userData", res.data.user_data ? JSON.stringify(res.data.user_data) : {});
          await localStorage.setItem("permissions", res.data.permissions ? JSON.stringify(res.data.permissions) : {});
          await localStorage.setItem("user_type", res.data.user_type);
          global.currerntRoleSlug = res.data.currerntRoleSlug ? res.data.currerntRoleSlug : "";
          await localStorage.setItem('currerntRoleSlug', res.data.currerntRoleSlug);
          this.setState({
            user_slug: res.data.user_data ? res.data.user_data.slug : "",
          });
          await this.saveTermscondition();
          this.props.manageSignUp(false);
          window.location.href = this.state.redirectTo;
        } else {
          this.setState({
            loginError: res.data.message ? res.data.message : "Error",
          });
        }
      } else {
        this.setState({
          loginError: res.data.message ? res.data.message : "Error",
        });
      }
      this.setState({ loader: false });
    } else {
      this.setState({ loader: false });
      return false;
    }

  };

  saveTermscondition = async (e) => {
    var user_slug = this.state.user_slug ? this.state.user_slug : "";
    let input = {
      is_term_condition: 1,
      slug: user_slug
    };
    let res = await AuthenticationService.UpdateTerms(input);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        loginError: "",
      });
    }
    else {
      this.setState({
        loginError: res.data.message ? res.data.message : "Error",
      });
    }
  }

  changeHandler = (event) => {
    let value = (event.target.type === "checkbox") ? event.target.checked : event.target.value;
    let errors = this.state.errors;
    if (event.target.name === "email") {
      if (event.target.value.length > 8) {
        errors.email = "";
        this.setState({ errors: errors });
      }
    }
    // if (event.target.name === "isAdult") {
    //   if (value === true) {
    //     errors.isAdult = "";
    //     this.setState({ errors: errors });
    //   }
    // }

    // if (event.target.name === "confirm_email") {
    //   if (event.target.value.length > 8) {
    //     errors.confirm_email = "";
    //     if(this.state.email === event.target.value){
    //       errors.email = "";
    //     }else{
    //       errors.email = "Email and confirm email must be same!";
    //     }
    //     this.setState({ errors: errors });
    //   }
    // }

    if (event.target.name === "password") {
      if (event.target.value.length > 4) {
        errors.password = "";
        this.setState({ errors: errors });
      }
    }

    if (event.target.name === "first_name") {
      if (event.target.value.length > 1) {
        const regex = /^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/i;
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      }
    }

    if (event.target.name === "last_name") {
      if (event.target.value.length > 1) {
        const regex = /^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/i;
        if (regex.test(event.target.value) === false) {
          errors.last_name = "Please enter valid last name.";
          this.setState({ errors: errors });
        } else {
          errors.last_name = "";
          this.setState({ errors: errors });
        }
      }
    }

    if (event.target.name === "email") {
      if (event.target.value.length > 7) {
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(event.target.value) === false) {
          errors.email = "Please enter valid email.";
          this.setState({ errors: errors });
        } else {
          errors.email = "";
          this.setState({ errors: errors });
        }
      }
    }
    if (event.target.name === "verify_otp") {
      if (event.target.value.length > 5) {
        this.setState({ verify_otp_error: "" });
      }
    }
    this.setState({ [event.target.name]: value });
  };
  manageSignUp = (event) => {
    this.setState({ isSignUp: false });
    this.props.manageSignUp(false);
  }
  openLoginModal = () => {
    this.props.manageSignUp(false);
    this.loginModel(true)
  }

  render() {

    return (
      <>
        <div>
          <Modal show={this.props.isSignUp || this.state.isSignUp} centered className="reservationBook model_bg_cust">
            <span
              className="welcomeClose float-end"
              onClick={() => this.manageSignUp(false)}
            >
              {global.closee}
            </span>
            <ModalBody className="width_402 border_radius_14">

              {
                this.state.verify_otp_screen ?
                  <>
                    <Container component="main" maxWidth="xs">
                      <CssBaseline />
                      <Box
                        sx={{
                          // marginTop: 5,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <figure>
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('logo-footer.png')} alt="Property Logo" title="Property Logo" />
                        </figure>
                        <Typography component="h1" variant="h5">
                          Please enter the code to sign up.
                        </Typography>
                        <Typography component="h6" variant="h6">
                          <b>the code sent to :</b> {this.state.display_email}
                        </Typography>
                        {/* <Typography component="h6" variant="h6">
                          <b>Code :</b> {this.state.otp}
                        </Typography> */}
                        <Box
                          // component="form"
                          // onSubmit={this.loginUser}
                          // noValidate
                          className="w-100 otp_box"
                          sx={{ mt: 1 }}
                        >
                          {this.state.loginError && (
                            <>
                              <p className="text-danger text-center m-auto font-14-cust">
                                {" "}
                                {this.state.loginError}
                              </p>
                            </>
                          )}
                          <TextField
                            margin="normal"
                            type="number"
                            className="borderInput textfield w-100"
                            required
                            fullWidth
                            id="OTP"
                            label="Enter code"
                            name="verify_otp"
                            onChange={(e) => this.changeHandler(e)}
                            error={this.state.verify_otp_error ? true : false}
                            value={this.state.verify_otp}
                            // autoComplete={false}
                            helperText={
                              this.state.verify_otp_error ? this.state.verify_otp_error : ""
                            }
                            autoFocus
                          />

                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => this.verifyOtp()}
                          >
                            Verify
                            {this.state.loader ?
                              <>
                                {/* <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                  className="spinner_loader"
                                />
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                  className="spinner_loader"
                                />
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                  className="spinner_loader"
                                /> */}

                                <SpinnerLoader />
                              </>
                              : ""
                            }
                          </Button>
                          <Grid container className="w-100">
                            <Timer
                              minutes={global.countdown_time} // env data ( Pass the no of minutes:seconds that you want count )
                              resendEvent={this.resendOtp.bind(this)} //  Resend button event you can pass your function name here
                            />
                          </Grid>
                        </Box>
                      </Box>
                    </Container>
                  </>
                  :
                  <div>
                    {!this.state.nextScreen ?
                      <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                          sx={{
                            // marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <figure>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('logo-footer.png')} alt="Property Logo" title="Property Logo" />
                          </figure>
                          <Typography component="h1" variant="h5">
                            Please create an account.
                          </Typography>
                          <Box
                            // component="form"
                            // onSubmit={this.loginUser}
                            // noValidate
                            className="w-100"
                            sx={{ mt: 1 }}
                          >
                            {this.state.loginError && (
                              <>
                                <p className="text-danger text-center m-auto font-14-cust">
                                  {" "}
                                  {this.state.loginError}
                                </p>
                              </>
                            )}
                            {this.state.emailPresent && (
                              <>
                                <p className="text-danger text-center m-auto font-14-cust">
                                  {" "}
                                  {this.state.emailPresent}
                                </p>
                              </>
                            )}
                            <div className="countryphone">
                              <TextField
                                margin="normal"
                                className=" textfield w-100"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                onBlur={() => this.validateEmail()}
                                onChange={(e) => this.changeHandler(e)}
                                error={this.state.errors.email ? true : false}
                                value={this.state.email}
                                autoComplete="off"
                                helperText={
                                  this.state.errors.email ? this.state.errors.email : ""
                                }
                              // autoFocus
                              />
                            </div>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              onClick={() => this.validateUserEmail()}
                            >
                              Continue
                              {this.state.loader ?
                                <>
                                  {/* <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  /> */}

                                  <SpinnerLoader />
                                </>
                                : ""
                              }
                            </Button>
                            <Grid container className="forget_text">
                              <Grid item s>
                                <Link
                                  href="#"
                                  onClick={() =>
                                    this.openLoginModal()
                                  }
                                  variant="body2"
                                >
                                  Already a member? Sign in
                                </Link>
                              </Grid>
                              {/* <Grid item>
                            <Link href="#" variant="body2">
                              {"Don't have an account? Sign Up"}
                            </Link>
                          </Grid> */}
                            </Grid>
                          </Box>
                        </Box>
                      </Container>
                      :
                      <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                          sx={{
                            // marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <figure>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('logo-footer.png')} alt="Property Logo" title="Property Logo" />
                          </figure>
                          <Typography component="h1" variant="h5">
                            Please create an account.
                          </Typography>
                          <Box
                            // component="form"
                            // onSubmit={this.loginUser}
                            // noValidate
                            className="w-100"
                            sx={{ mt: 1 }}
                          >
                            {this.state.loginError && (
                              <>
                                <p className="text-danger text-center m-auto font-14-cust">
                                  {" "}
                                  {this.state.loginError}
                                </p>
                              </>
                            )}

                            <div className="row">
                              <div className="countryphone">
                                <TextField
                                  margin="normal"
                                  className="textfield w-100"
                                  // required
                                  fullWidth
                                  id="email"
                                  label="Email"
                                  name="email"
                                  disabled={true}
                                  onBlur={() => this.validateEmail()}
                                  onChange={(e) => this.changeHandler(e)}
                                  error={this.state.errors.email ? true : false}
                                  value={this.state.email}
                                  // autoComplete={false}
                                  helperText={
                                    this.state.errors.email ? this.state.errors.email : ""
                                  }
                                />
                              </div>
                              {/* <TextField
                        margin="normal"
                        className="borderInput textfield w-100"
                        required
                        fullWidth
                        id="confirm_email"
                        label="Confirm email"
                        name="confirm_email"
                        onChange={(e) => this.changeHandler(e)}
                        error={this.state.errors.confirm_email ? true : false}
                        value={this.state.confirm_email}
                        // autoComplete={false}
                        helperText={
                          this.state.errors.confirm_email ? this.state.errors.confirm_email : ""
                        }
                        autoFocus
                      /> */}
                              <div className="countryphone">
                                <TextField
                                  margin="normal"
                                  className="textfield w-100"
                                  required
                                  fullWidth
                                  id="password"
                                  label="Password"
                                  name="password"
                                  type="password"
                                  onBlur={() => this.passwordCheckFun()}
                                  onChange={(e) => this.changeHandler(e)}
                                  error={this.state.errors.password ? true : false}
                                  value={this.state.password}
                                  // autoComplete={false}
                                  helperText={
                                    this.state.errors.password ? this.state.errors.password : ""
                                  }
                                // autoFocus
                                />
                              </div>
                              <div className="countryphone">
                                <TextField
                                  margin="normal"
                                  className="textfield w-100"
                                  required
                                  fullWidth
                                  id="first_name"
                                  label="First name"
                                  name="first_name"
                                  onChange={(e) => this.changeHandler(e)}
                                  error={this.state.errors.first_name ? true : false}
                                  value={this.state.first_name}
                                  // autoComplete={false}
                                  helperText={
                                    this.state.errors.first_name ? this.state.errors.first_name : ""
                                  }
                                // autoFocus
                                />
                              </div>
                              <div className="countryphone">
                                <TextField
                                  margin="normal"
                                  className=" textfield w-100"
                                  required
                                  fullWidth
                                  id="last_name"
                                  label="Last name"
                                  name="last_name"
                                  onChange={(e) => this.changeHandler(e)}
                                  error={this.state.errors.last_name ? true : false}
                                  value={this.state.last_name}
                                  // autoComplete={false}
                                  helperText={
                                    this.state.errors.last_name ? this.state.errors.last_name : ""
                                  }
                                // autoFocus
                                />
                              </div>
                            </div>
                            <div className="row align-items-start flex-nowrap pd-r-15 pd-l-15 mg-b-0 text-align-left confirm-policy">
                              {/* <FormGroup className="d-flex">
                                <FormControlLabel
                                  control={<Checkbox checked={this.state.isAdult} />}
                                  labelPlacement="end"
                                  label=""
                                  required={true}
                                  name="isAdult"
                                  onChange={(e) => this.changeHandler(e)}
                                  error={this.state.errors.isAdult ? true : false}
                                  helperText={this.state.errors.isAdult ? this.state.errors.isAdult : ""}
                                  className="w-10 d-content labeltext"
                                />
                                <span className="agree">I agree to the  &nbsp; <a href={"/terms-condition"} title="Terms Of Service" target="_blank"> Terms Of Service</a> &nbsp; and &nbsp; <a href={"/privacy"} title="Privacy Policy" target="_blank"> Privacy Policy </a>.&nbsp;</span>
                                <div className="d-flex flex-wrap">
                                <p className="mg-t-8 mg-b-0" style={{ textAlign: "left !important" }} >{`To sign up, you need to be at least 18. Your birthday won't be shared with other people who use OHAI.`}   <a href={global.themeUrl + this.state.property_slug + "/privacy-policy"} title="Privacy" target="_blank"> Privacy Policy </a>{" "}</p>


                                </div>
                              </FormGroup> */}

                              <div className="d-flex flex-wrap service_terms">
                                <span className="agree">By selecting Agree and continue, I agree to OHAI's  &nbsp; <a href={"/terms-condition"} title="Terms of Service" target="_blank"> Terms of Service </a> &nbsp; and acknowledge the &nbsp; <a href={"/privacy"} title="Privacy Policy" target="_blank"> Privacy Policy </a>.&nbsp;</span>
                              </div>

                            </div>
                            {
                              this.state.errors.isAdult ?
                                <p className="text-danger pd-l-3 pd-t-15">
                                  {this.state.errors.isAdult}
                                </p>
                                : ""
                            }
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              onClick={(e) => this.createAccount(e)}
                            >
                              Agree and continue

                              {this.state.loader ?
                                <>
                                  {/* <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  /> */}

                                  <SpinnerLoader />
                                </>
                                : ""
                              }
                            </Button>
                            <Grid container>
                              <Grid item s>
                                <Link
                                  href="#"
                                  onClick={() =>
                                    this.openLoginModal()
                                  }
                                  variant="body2"
                                >
                                  Already a member? Sign in
                                </Link>
                              </Grid>
                              {/* <Grid item>
                          <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid> */}
                            </Grid>
                          </Box>
                        </Box>
                      </Container>
                    }
                  </div>
              }
            </ModalBody>
          </Modal>
          <section>
            <Login email={this.state.email} redirectTo={"/"} propertySlug={this.state.property_slug} isLogin={this.state.loginModel} manageLogin={this.loginModel.bind(this)} />
          </section>
        </div>
      </>
    );
  }
}

export default SignUp;
