import React, { Component } from "react";
import SpinnerLoader from "../common/SpinnerLoader";
import ReservationService from "../../services/ReservationService";
import CommonHeader from "../common/CommonHeader";
class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_slug: "",
      confirmation_code: props.match.params ? props.match.params.confirmationCode : "",
      unsubscribeReasonList: global.unsubscribeReasonList,
      showSpinner: false,
      loading: false,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      slug: this.props.match.params.slug,
      resSlug: "",
      isSubmit: false,
      pathname: window.location.pathname,
      confirm_back: 0,
      feedback: "",
      description: "",
      feedbackError: null,
    };
    this.changeHandler = this.changeHandler.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    if (this.state.slug !== undefined) {
      var resSlug = {};
      var resMessage = {};
      var resubscribe = {};
      var resubscribeMsg = [];
      let res = await ReservationService.addUnsubscribedEmail(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resSlug = res.data ? res.data.slug : '';
        resubscribe = res.data ? res.data.type:'';
        resubscribeMsg = "Kindly click on the unsubscribe button below to confirm your unsubscription.";
        if(resubscribe === "subscribe"){

          let resdataSubscribe =  await ReservationService.getreSubscribedmessage();
          if (global.successStatus.includes(resdataSubscribe.status)) {
            resubscribeMsg = resdataSubscribe.data?resdataSubscribe.data.description:"Kindly click on the unsubscribe button below to confirm your unsubscription.";
          }
        }
      }
     
      this.setState({
        resSlug: resSlug,
        resubscribe:resubscribe,
        resMessage:resubscribeMsg,
      });
  
      this.props.history.push({
        pathname: '/unsubscribes/'+ this.state.resSlug,
        state: this.state.resSlug
      });


    }
  }
  validate() {
    let feedbackError = '';
    if (!this.state.feedback) {

      feedbackError = "Please select at least one option";
    }
    if(this.state.feedback === "Other" && this.state.description === ""){
      feedbackError = "This field is required";
    }
    if (feedbackError) {
      this.setState({ feedbackError });
      return false;
    }
    return true;
  }
  updateUnsubscribeFeedback = async (e) => {
    e.preventDefault();
    var resSlug = {};
    var resMessage = {};
    var resubscribe = {};
    var resubscribeMsg = [];
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        subscribe:this.state.resubscribe === "subscribe" ? 2:1,
        slug: this.state.resSlug ? this.state.resSlug : '',
      };
      if (this.state.resSlug !== undefined) {
        this.setState({ loading: true, isSubmit: true });
        let res = await ReservationService.updateUnsubscribedEmailFeedback(inputData);
        if (global.successStatus.includes(res.status)) {
          resSlug = res.data ? res.data.slug : '';
          resMessage = res.message ? res.message : '';
          resubscribe = res.data ? res.data.type:'';
          if(resubscribe === "subscribe"){
            let resdataSubscribe =  await ReservationService.getreSubscribedmessage();
            if (global.successStatus.includes(resdataSubscribe.status)) {
              resMessage = resdataSubscribe.data?resdataSubscribe.data.description:"";
            }
            
          }
        }
       
          this.setState({
            resSlug: resSlug,
            resMessage:resMessage,
            resubscribe:resubscribe,
          });
          this.props.history.push({
            pathname: '/unsubscribes/'+ this.state.resSlug,
            state: this.state.resSlug
          });
        
      }
      this.setState({ loading: false, isSubmit: false });
      
      setTimeout(
        () => this.setState({ alertModalMessage:"",showAlertModal: false,loading: false,description:"" }),
       2000
      );
  }
  changeHandlerRadio = (event) => {
    let input = this.state.input;
    let feedback = this.state.input;
    let description = this.state.input;
    let value = event.target.type === "checkbox"
      ? event.target.checked
      : event.target.value;
    value = event.target.type === "radio" ? value : value;
    if (value === "Other") {
      // input[event.target.name] = value;
      // feedback[event.target.name] = value;
      description[event.target.name] = "";
      this.setState({
        [event.target.name]: value,
        input: input,
        feedback: value,
        description: "",
        feedbackError:"",
      });
    } else {
      input[event.target.name] = value;
      feedback[event.target.name] = value;
      description[event.target.name] = value;
      this.setState({
        [event.target.name]: value,
        input: input,
        feedback: value,
        description: value,
        feedbackError:"",
      });
    }
    //console.log("hh",feedback)
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let description = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    description[event.target.name] = value;
    this.setState({
      [event.target.name]: value,
      input: input,
      description: value,
      feedbackError:"",
    });
  };
  render() {
    return (
      <>
        <main className="oh-template Brandmainpage accessyourreservation unsubscribrl_main_sec">
          {/* Header */}
          <CommonHeader CheckIn={true} />
          {/* middle */}
          <section className="ConfirmationBooking reservationBook middle-section">
            <div className="container">
              <div className="row- mobView">
                {this.state.resSlug || this.state.slug ?
                  (<>
                    <div className="col-lg-6 col-md-6 col-sm-8 d-block m-auto requestSection contact_form_box unsub_box">
                      <p className="p-dark text-center">
                      {this.state.resMessage}
                      </p>
                      {this.state.resMessage ?
                      '' 
                      :(<>
                        <p className="p-dark text-center"  dangerouslySetInnerHTML={{
                                    __html: this.state.resMessage ? this.state.resMessage:"To confirm your unsubscription, please click the Unsubscribe button below.",
                                  }}></p>
                        {/* <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.resMessage ? this.state.resMessage:"Kindly click on the unsubscribe button below to confirm your unsubscription.",
                                  }}
                                ></p> */}
                      </>) 
                      }
                      
                      <div className="pd-l-15 pd-r-15 mg-b-30 pd-l-15 pd-r-15 mg-b-30 d-flex justify-content-center m-auto">
                        <button
                          className="btn-checkin mg-t-25 btn-unsub-load"
                          type="button"
                          onClick={this.updateUnsubscribeFeedback}
                          //disabled={this.state.isSubmit ? true : false}
                        >
                          {this.state.slug !== undefined && this.state.resubscribe === "subscribe"
                              ? "Re-Subscribe"
                              : "Unsubscribe"}
                        </button>
                        {this.state.isSubmit ? (
                  <div className="mob_fullscreen">
                    <SpinnerLoader />{" "}
                  </div>
                ) : (
                  ""
                )}
                      </div>

                    </div>
                    
                    </>)
                  :
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-8 d-block m-auto requestSection contact_form_box unsub_box">
                      <h1 className="pd-l-15 pd-r-15 mg-b-15">
                      Oops.
                      </h1>
                      <p className="text-center p-dark pd-b-20">Looks like the content you were looking for is no longer here.</p>
                      <div className="pd-l-15 pd-r-15 mg-b-30 pd-l-15 pd-r-15 mg-b-30 d-flex justify-content-center m-auto">
                        <a href="/" className="text-decoration-none">
                        <button
                        
                          className="btn-checkin mt-2 btn-unsub-load"
                          type="button"
                        >Back to Home
                        </button></a>
                      </div>
                      </div>
                      </>
                }


              </div>
            </div>
          </section>
        </main>

      </>
    );
  }
}

export default Unsubscribe;