//     							                          //
//  Program: booktourform.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import CommonHeader from "../common/CommonHeader";
import { Helmet } from "react-helmet-async";

class Hosting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personName: [],
            date: new Date('2014-08-18T21:11:54'),
            selectedDate: new Date(),
            type: false,
            value: undefined,
            inputValue: undefined,
            input: {},
            errors: {},
            first_name: "",
            // property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
            last_name: "",
            email: "",
            phone_number: "",
            applicant_photo: "",
            price_range: "",
            desired_move_in_date: "",
            comment: "",
            status_id: true,
            helperText: "",
            bedroom: [],
            bedroomArray: [],
            priceRangeArray: []
        }
    }

    componentDidMount() {
        this.getStrListingCmsData();
    }

    /* CMS data getcode start */
    getStrListingCmsData = async (queryString = "") => {
        this.setState({ showSpinner: true, loading: true });
        var resData = {};
        var resDataArr = [];
        let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
        var cacheKeyName = global.level + "_DISCLAIMERS_" + levelSlug;
        if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
            var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
            this.setState(data);
        }
        let res = await CmsDataService.getCmsData("DISCLAIMERS", global.level, levelSlug);
        if (global.successStatus.includes(res.status)) {
            resDataArr = res.data ? res.data : [];
            resDataArr.map((item, key) => {
                resData[item.section_name_slug] = item.section_data;
            });
        }
        await this.setState({
            showSpinner: false,
            loading: false
        });
        global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
        this.setState(resData);
    }
    /* CMS data getcode end */

    render() {
        // const [value, setValue] = React.useState(options[0]);
        // const [inputValue, setInputValue] = React.useState('');

        return (
            <main className="oh-template Brandmainpage header_hosting">
                 <Helmet>
                <meta name="robots" content="noindex,nofollow" />
                </Helmet>
                {/* Header */}
                <CommonHeader propertySlug={this.state.property_slug} />
                {/* middle */}
                <section className="middle hosting_sec">
                    <section className="partner_banner home_share_banner">
                        <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-25 pd-r-25">
                            <h1> Resident Homesharing Program</h1>
                        </div>
                    </section>
                    <section className="homesharing_sec">
                        <div className="container">
                            <div className="row m-0 ">
                                <div className="col-12">
                                    <div className="home_heading_main">
                                        <h2>Overview</h2>
                                        <h4 className="homeshare_head_2">Welcome to Homesharing at The Fredrick / Tempe! Once your set up is complete, you’ll be on your way to earning additional money on your apartment when you’re not using it! </h4>
                                        <h4 className="homeshare_head_2">OHAI makes Homesharing easy. We take care of guest communication, cleaning schedules, and key handoff so you can focus on the greater aspects of hosting: designing your apartment, managing your calendar availability for guests, and setting your nightly rates.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="get_started">
                        <div className="container">
                            <div className="row m-0">
                                <div className="col-12">
                                    <div className="home_heading_main">
                                        <h2>Getting started </h2>
                                        <h4 className="homeshare_head_2">After you sign your lease and Homesharing lease addendum with your property manager, you’ll receive your Homesharing kit.</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12" >
                                    <div className="get_flow">
                                        <h4 className="pt-h4-new">What to do with your Homesharing kit?</h4>
                                        <ul>
                                        <li>
                                                <div className="flow_first">
                                                    <div className="flow_icon">
                                                       <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('Furnish.svg')} alt="" />
                                                       </figure>
                                                    </div>
                                                </div>
                                                <div className="flow_second">
                                                    <div className="flow_cnt">
                                                        <h4 className="flow_heading">Furnish your apartment</h4>
                                                        <p>Furnish your apartment in the style that reflects you and what you love! While the style is totally up to you, we prepared a helpful checklist and recommendations (and some must haves). Check it out using this <a class="btn_link_guest" href="https://docs.google.com/spreadsheets/d/1nwI1QzW5eTXmD8qPqBZX68KUMnRpog0efsgrYhul0t0/edit" target="/blank">link</a>.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="flow_first">
                                                    <div className="flow_icon">
                                                       <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('pay_click.svg')} alt="" />
                                                       </figure>
                                                    </div>
                                                </div>
                                                <div className="flow_second">
                                                    <div className="flow_cnt">
                                                        <h4 className="flow_heading">Pay for Homesharing kit</h4>
                                                        <p>Your Homesharing kit includes 2 cleaner cabinet locks, a key fob for the key locker, and 6 no-iron quilts to make the bed set up easy breezy. If you didn’t pay for your kit when you picked it up, you can pay using this <a class="btn_link_guest" href="https://checkout.square.site/buy/MCW5YJYLI4GAKGBAKQBD6M2T" target="/blank">link</a>.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="flow_first">
                                                    <div className="flow_icon">
                                                        <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('adduser.svg')} alt="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="flow_second">
                                                    <div className="flow_cnt">
                                                        <h4 className="flow_heading">Sign up</h4>
                                                        <p>If you didn’t submit your resident details when you picked up your Homesharing kit, do so now so we can create your account for the app. After you submit, you’ll receive an email to download the app and <a class="btn_link_guest" href="https://www.orionhaus.com/newresident" target="/blank">create your password</a>.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="flob_keys">
                                                <div className="flow_first">
                                                    <div className="flow_icon">
                                                        <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('key.svg')} alt="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="flow_second">
                                                    <div className="flow_cnt">
                                                        <h4 className="flow_heading">2 Key fobs</h4>
                                                        <p>You need to make 1 main key set and 1 back up key set for a total of 2 key sets. Both sets must include: your guest keys, your amenities fob, your parking remote (if you purchased a parking space for guests) to the Key Cafe fob. Later on, after you're all set. You'll drop both of these key sets off in individual Key Cafe Lockers.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="flow_first">
                                                    <div className="flow_icon">
                                                        <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('house.svg')} alt="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="flow_second">
                                                    <div className="flow_cnt">
                                                        <h4 className="flow_heading">Housekeeping locks and cabinets</h4>
                                                        <p>In your apartment, there are 2 large cabinets — this will become your housekeeper cabinets, and these cabinets need the housekeeper locks, and they will hold your extra sheets and cleaner supplies. Follow the instructions on how to set up your locks and set pin code to 726.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* <li>
                                                <div className="flow_first">
                                                    <div className="flow_icon">
                                                        <img src={wifi_icon} alt="" />
                                                    </div>
                                                </div>
                                                <div className="flow_second">
                                                    <div className="flow_cnt">
                                                        <h4>Wifi</h4>
                                                        <p>SSet up your guest WiFi. All Guest WiFi networks must have a naming and password convention which must follow this format: FRD-TMP-### where ### is your apartment number. For example:  </p>

                                                        <p className="m-0"><span>Apartment unit:</span> 101</p>
                                                        <p className="m-0"><span>Network name:</span> FRD-TMP-101</p>
                                                        <p className="m-0"><span>Network password:</span> FRD-TMP-101</p>


                                                    </div>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12" >
                                    <div className="get_started_img">
                                        <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('getstarted.png')} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="get_listed">
                        <div className="container ">
                            <div className="row m-0">
                                <div className="col-12">
                                    <div className="home_heading_main">
                                        <h2>Get listed </h2>
                                        <h4 className="homeshare_head_2">Once you have completed the welcome form, you will receive an email from OHAI to create a password and download the OHAI app.</h4>
                                    </div>
                                </div>
                                <div className="position-relative">
                                <div className="col-lg-8">
                                    <div className="get_listed_img">
                                        <figure>
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('mobile.png')} alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="get_listed_cnt">
                                        <h3>What to do in the OHAI App?</h3>
                                        <h5>Follow the prompts to create your login credentials</h5>
                                        <p>On the app you will be able to provide your personal listing details, the app has already pulled the building information and photos so your listing is just missing your unit details, such as the size of your beds, your unit photos, etc.</p>
                                        <p>Manage calendar availability — tell the app when you’ll be home and your place is available for guests.</p>
                                        <p>Set your prices.</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="get_hosted">
                        <div className="container">
                            <div className="row m-0">
                                <div className="col-12">
                                    <div className="home_heading_main">
                                        <h2>Get ready for guests</h2>
                                        <h4 className="homeshare_head_2">When you leave your place, you must “check-out” and a traditional check-out process will be triggered:
                                            a cleaning needs to take place, which will get your apartment ready for your guests. This is great because your cleaner will
                                            clean your place after you go, and again after your guests leave, so your place will be perfect before you come home.</h4>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="guest_cnt_box">
                                        <div className="guest_img">
                                            {/* <img src={gg1} alt="" /> */}
                                            <div className="resdent_ready resdent_img"></div>
                                        </div>
                                        <div className="guest_cnt">
                                            <h3>Resident check-out</h3>
                                            <p>You must check out by 11am</p>
                                            <p>Strip your bed linen and towels and put in your closet. If you have personal belongings that you want to be out of reach of guests, you’ll need to order the same locks as the housekeeping closets and install them on your bedroom closets:  <a class="btn_link_guest" href="https://www.amazon.com/gp/product/B0B6H74444/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1" target="/blank">link</a> </p>
                                            <p>Lock any belongings you want out of reach in your lockable bedroom closet</p>
                                            <p> Remove perishable items from the refrigerator if they will expire during your reservation date</p>
                                            <p> You must need a total of 3 sets of sheets and towels at all times, and they must be left in the locked cleaners closet which has the locks and code set to 726. You need 3 sets because: 1 will be out, 1 will be in laundry, and 1 will be used for backup. They will always be in rotation.</p>
                                            <h4>1 full set includes</h4>
                                            <ul>
                                                <li>Bed sheet sets with 4 pillow cases</li>
                                                <li>2 bath towels</li>
                                                <li>2 face wash cloths</li>
                                            </ul>
                                            <p>Your mattress must have a mattress protector and each of your pillows must have pillow protectors. This is so if a guest damages your sheets, it will help prevent further damage to your mattress and pillows.</p>
                                            <p>We require that residents provide guests with basic consumable items such as:</p>
                                            <ul>
                                                <li>Hand soap in each bathroom</li>
                                                <li>2 rolls of toilet paper</li>
                                                <li>1 paper towel roll</li>
                                                <li>2 trash bags per trash can</li>
                                                <li>A clean dish sponge</li>
                                                <li>Dish soap</li>
                                            </ul>
                                            <p>Drop off keys at the Key Cafe Box, follow the prompts on the screen: (select drop off key, scan the fob, place keys in the bin that opens and lastly close the bin)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="guest_cnt_box">
                                        <div className="guest_img">
                                            <div className="resdent_ready clear_clear_img"></div>
                                        </div>
                                        <div className="guest_cnt">
                                            <h3>Cleaners & cleaning fee</h3>
                                            <p>The “Cleaning Fee” is paid by the guest and covers the cleaning after the guest leaves. If you are at home and are leaving before guests arrive, then your “resident check-out” will trigger a check out cleaning, and that will be taken out before payouts.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="guest_cnt_box">
                                        <div className="guest_img">
                                            {/* <img src={gg3} alt="" /> */}
                                            <div className="resdent_ready check_in_img"></div>
                                        </div>
                                        <div className="guest_cnt">
                                            <h3>Check-in</h3>
                                            <p>OHAI Concierge team handles all guest communication and provides access to the guests </p>
                                            <p>Guest check-in time is 3pm</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="guest_cnt_box">
                                        <div className="guest_img">
                                            {/* <img src={gg4} alt="" /> */}
                                            <div className="resdent_ready check_out_img"></div>
                                        </div>
                                        <div className="guest_cnt">
                                            <h3>Check-out</h3>
                                            <p>After the guest checks out, housekeeping will clean your apartment. When you arrive home, you’ll be welcomed to a clean apartment. Your check-in is available after check-in time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="guest_cnt_box">
                                        <div className="guest_img">
                                        <div className="resdent_ready concierge_img"></div>
                                        </div>
                                        <div className="guest_cnt">
                                            <h3>Your OHAI Concierge</h3>
                                            <p>Your OHAI Concierge team works 24/7 to support you and your guests. You can contact us at any time via the chat!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="get_paid">
                        <div className="container">
                            <div className="row m-0">
                                <div className="col-md-6">
                                    <div className="get_paid_img">
                                        <figure>
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('getpaid.jpg')} alt="" />
                                        </figure>
                                    </div>

                                </div>
                                  <div className="col-md-6">
                                  <div className="get_pain_cnt">
                                        <h3>Get paid </h3>
                                        <p>You will receive your payout report and check on the 15th of every month for the previous month’s earnings, as long as your rent is paid and set to auto-pay.</p>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </section>
                    <section className="get_paid pd-t-0">
                        <div className="container">
                            <div className="row m-0">
                                <div className="col-md-6 paid_mobile">
                                    <div className="get_paid_img">
                                        <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('getconnected.jpg')} alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                <div className="get_pain_cnt p-0">
                                        <h3>Get connected </h3>
                                        <p>You can chat with the OHAI Concierge team 24/7 with any questions, suggestions or concerns.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 paid_desk">
                                    <div className="get_paid_img">
                                        <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('getconnected.jpg')} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                {/* Footer */}
                {/* <CommonFooter propertySlug={this.state.property_slug} /> */}
            </main >
        );
    }
}
export default Hosting