///////////////////////////////////////////////
//     							             //
//  Program: ReservationService.js                 //
//  Application: Services                    //
//  Option: Used for Applicants data              //
//  Developer: GP                  //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";
import CmsService from "./CmsService";

class ReservationService {

    getAvailibityDate(data) {
        let endPoint = "get-unit-listing-available-date";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
      }


      sendOtp(data) {
        let endPoint = "user-otp";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
      }


      resendOtp(data) {
        let endPoint = "resend-user-otp";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
      }


      verifyOtp(data) {
        let endPoint = "verify-user-otp";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
      }

      storeReservation(data) {
        let endPoint = "store-reservation-details-for-website";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
      }
      Guestform(data) {
        let endPoint = "store-guest-form";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      updateReservationData(data) {
        let endPoint = "update-reservation-details-for-website";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.putCMSCall(inputData);
        return response;
      }

      getDataByCitySlug(slug) {
        let endPoint = "location/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.getCMSCall(inputData);
        return response;
      }
      getCity(queryString) {
        let endPoint = "get-city" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = CmsService.getCMSCall(inputData);
        return response;
      }

      getReservationList(data) {
        let endPoint = "get-my-trip";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }

      getReservation(slug) {
        let endPoint = "get-reservation-details/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      getGuestformdata(slug) {
        let endPoint = "get-guest-form-info/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      getFaqdata(slug) {
        let endPoint = "faq-knowledge-base?category_type=Reservation&property_slug="+ slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      getGuestformcheckboxdata() {
        let endPoint = "get-guest-form-data-checkbox";
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      cancelReservationData(data) {
        let endPoint = "refund-stripe-transaction";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      SendpasswordLink(data) {
        let endPoint = "send-set-password-guest-form";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      getMyBookReservation(data) {
        let endPoint = "get-my-book-reservation";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      CardTokenise(data) {
        let endPoint = "store-card-detail";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      AddParkingData(data) {
        let endPoint = "store-parking-payment";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      UpdateCarcolor(data) {
        let endPoint = "update-parking-car-color-type";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      getFaqdataSupport(queryString) {
        let endPoint = "faq-knowledge-base?category_type="+queryString;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      addUnsubscribedEmail(slug) {
        let endPoint = "subscribe-email/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      updateUnsubscribedEmailFeedback(data) {
        let endPoint = "unsubscribe-email";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }
      getreSubscribedmessage() {
        let slug = "4H6I2F3WMNJ7";
        let endPoint = "get-Resubscribe-message/" + slug;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      getMessageDetail(queryString) {
        let endPoint = "cms-support-messages";
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      getcleanerDetail(confirmationcode) {
        let endPoint = "get-cleaner-support/"+confirmationcode;
        let addditionalHeaderData = {};
        let postData = {};

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: confirmation_code,
        };
        let response = ApiService.getCall(inputData);
        return response;
      }
      getkeycode(data) {
        let endPoint = "check-bin";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.postCall(inputData);
        return response;
      }

}

export default new ReservationService();
