//     							                          //
//  Program: support.jsx                             //
//  Application: KINI HTML                                //
//  Option: Tech Support                                //
//  Developer: JJ		                                  //
//  Date: 2023-04-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReservationService from "../../services/ReservationService";

class FaqMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      country_code: "+1",
      phone_number: "",
      loader: false,
      notes: "",
      first_name: "",
      property_slug:
        props.match && props.match.params ? props.match.params.slug : "",
    };
  }
  componentDidMount() {
    this.getFaqDetail();
  }

  getFaqDetail = async () => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecordsfaq = 0;
    var listfaq = [];
    var property_slug = this.state.property_slug
      ? this.state.property_slug
      : "";
    let res = await ReservationService.getFaqdata(property_slug);
    if (global.successStatus.includes(res.status)) {
      listfaq = res.data ? res.data?.data : [];
      totalRecordsfaq = listfaq ? listfaq.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      listfaq: listfaq,
      totalRecordsfaq: totalRecordsfaq,
    });
  };
  render() {
    return (
      <main className="oh-template Brandmainpage mobile_faq_sec">
        <section className="fqa_sec_box">
          <div className="container">
            <div className="row-">
              {this.state.totalRecordsfaq > 0 ? (
                <div className="col-12">
                  <h3 className="main_title_faqa">
                    Frequently asked questions
                  </h3>
                  {this.state.listfaq.map((value, index) => {
                    return (
                      <>
                        <div className="mobile_faq">
                            <h5>{value.title}</h5>
                             <p dangerouslySetInnerHTML={{
                           __html: value.description,
                        }}></p>
                          </div>
                        
                      </>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </main>
    );
  }
}
export default FaqMobile;
