//     							                          //
//  Program: HomesharingInquiry.jsx                             //
//  Application: Homesharinh Inquiry                                //
//  Developer: Raj Kumar                                //
//  Date: 2023-09-19                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import HomesharingInquiryService from "../../services/HomesharingInquiryService";
import "react-datepicker/dist/react-datepicker.css";
import CommonHeader from "../common/CommonHeader";
import MetaSEO from "../../../MetaSEO";
import SpinnerLoader from "../common/SpinnerLoader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class HomesharingInquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      input: {},
      errors: {
        property_name: "",
        unit_name: "",
        person_name: "",
        recaptcha: "",
      },
      recaptcha: "",
      headerToken: localStorage.getItem("headerToken")
        ? localStorage.getItem("headerToken")
        : "",
      userData: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {},
      first_name: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).first_name
        : "",
      last_name: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).last_name
        : "",
      email: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).email
        : "",
      phone_number: "",
      applicant_photo: "",
      price_range: "",
      desired_move_in_date: null,
      comment: "",
      status_id: true,
      helperText: "",
      bedroom: [],
      bedroomArray: [],
      priceRangeArray: [],
      loader: false,
      isValidRecatcha: false,
      errorCount: 0,
      residentData: [],
      property_slug:
        props.match && props.match.params
          ? props.match.params.propertySlug
            ? props.match.params.propertySlug
            : global.property_slug
          : global.property_slug,
      // meta tags start
      title:
        global.level === 2
          ? localStorage.getItem("brand_name")
          : global.level === 3
          ? localStorage.getItem("property_name")
          : "OHAI",
      favicon:
        global.level === 2
          ? localStorage.getItem("brand_logo_url")
          : global.level === 3
          ? localStorage.getItem("property_logo_url")
          : "OHAI",
      keywords: global.keywords,
      description: global.description,
      url: window.location.href,
      image:
        global.level === 2
          ? localStorage.getItem("brand_logo_url")
          : global.level === 3
          ? localStorage.getItem("property_logo_url")
          : "",
      fb_app_id: "",
      // meta tags end
    };
  }

  async componentDidMount() {
    localStorage.setItem("propertySlug", this.state.property_slug);
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const recipient_slug = params.get("slug");
    const resident_slug = params.get("r_slug");
    const unit_slug = params.get("u_slug");
    if (
      recipient_slug !== undefined &&
      resident_slug !== undefined &&
      unit_slug !== undefined
    ) {
      this.setState({ showSpinner: true, loading: true });
      var resData = {};
      var totalRecords = 0;

      this.setState({ Skeleton: true });
      let res = await HomesharingInquiryService.getResidentUnitData(
        "slug=" +
          (recipient_slug ? recipient_slug : "") +
          "&r_slug=" +
          (resident_slug ? resident_slug : "") +
          "&u_slug=" +
          (unit_slug ? unit_slug : "")
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        totalRecords = res.data ? res.data.length : 0;
        if (totalRecords === 0) {
          this.setState({ Skeleton: false });
        }
        this.setState(resData);
        this.setState({
          showSpinner: false,
          loading: false,
          total_record: totalRecords,
          Skeleton: false,
          displaySearchResult: true,
          showAlertModal: true,
          successContact: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      } else {
        this.setState({ Skeleton: false });
      }
    }
  }

  render() {
    return (
      <main className="oh-template Brandmainpage accessyourreservation homeshare_enquiry">
        <CommonHeader CheckIn={true} />

        <MetaSEO
          title={this.state.title}
          favicon={this.state.favicon}
          keywords={this.state.keywords}
          description={this.state.description}
          url={this.state.url}
          image={this.state.image}
          fb_app_id={this.state.fb_app_id}
          noIndex="true"
          author={"OHAI"}
        />
        <section className="ConfirmationBooking reservationBook middle-section">
          <div className="container">
            <div className="row-">
              {this.state.successContact ? (
                <>
                  <div className="col-lg-6 col-md-6 col-sm-8 d-block m-auto requestSection contact_form_box">
                    <div className="container">
                      <div className="content p-0">
                        <img
                          src={global.OnKeyPressEvent.getS3ImageUrl(
                            "animateCheck.svg"
                          )}
                          className="mg-b-20"
                        />
                        <h4 className="m-0">
                          {" "}
                          {this.state.successMsg ? this.state.successMsg : ""}
                        </h4>
                        <div className="d-flex justify-content-center flex-column align-items-center mg-t-30"></div>
                        {/* <h4 className="m-0">
                          {" Please wait"} <SpinnerLoader />
                        </h4> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>

        {/* Footer */}
        {/* <CommonFooter propertySlug={this.state.property_slug} /> */}
      </main>
    );
  }
}
export default HomesharingInquiry;
