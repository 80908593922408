import React from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Datepicker, Input, Page } from '@mobiscroll/react';
import { useEffect } from 'react';
import Moment from 'moment';

function DatePickerFile(props) {
    const now = new Date();
    const today =  Moment(new Date()).format("YYYY-MM-DD");
    // const min = now;
    // const max = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
    const [start, setStart] = React.useState(props.startDate);
    const [end, setEnd] = React.useState(props.endDate);
    const setDates = (date) => {
        // console.log(date,"date",props);
        var startDate = date.value[0] ? date.value[0] : "";
        var endDate = date.value[1] ? date.value[1] : "";
        // setStart(startDate);
        // setEnd(endDate);
        props.changeDateHandlerNew(date);
        // await this.setState({ startDate: startDate, endDate: endDate });
    }
    localStorage.removeItem('checkin');
    localStorage.removeItem('checkout');
    //  console.log(props.nextDateOpen);
    // console.log("startaa:",start,"Endaa:",end);
    // useEffect(()=>{
    //     console.log("start:",start,"End:",end);
    // },[start,end]);


    return (
        <Page>
            <div className="mbsc-grid mbsc-no-padding">
                <div className="mbsc-row mobiscrollCalendar ">
                    <div className="mbsc-col-6 ">
                        <Datepicker
                            controls={['calendar']}
                            select="range"
                            startInput={start}
                            endInput={end}
                            //disabled={props.bookedDate}
                            //    value={[props.startDate ? Moment(props.startDate).format("MM/DD/YYYY"):"",Moment(props.startDate).format("MM/DD/YYYY")]}
                            defaultValue={[props.startDate, props.endDate]}
                            //defaultValue={[props.startDate , props.endDate]}
                            // invalid={props.bookedDate}
                            valid={props.availableDate}
                             min={today}
                            // max={max}
                            pages={2}
                            onChange={setDates}
                            id={"calender-end-open"}
                            touchUi={props.touchUi ? true : false}
                            theme="ios"
                            buttons={props.buttons ? props.buttons : []}
                            inputTyping={false}
                            themeVariant="light"
                            cssClass="str_dual_calendar"
                            minRange={2}
                            // maxRange={props.nextDateOpen}
                            inRangeInvalid={false}
                            rangeEndInvalid={true}
                            responsive={{
                                xsmall: {
                                    calendarScroll: "vertical",
                                    calendarSize: "2"
                                },
                                medium: {

                                }
                            }}
                        />
                        <Input ref={setStart} value={props.startDate ? Moment(props.startDate).local().format("MM/DD/YYYY") : ""} id={"calender-start-open"} label="Check-in" inputStyle="" labelStyle="stacked" placeholder="Add date" className="str_detail_cal">Check-in </Input>

                    </div>
                    <div className="mbsc-col-6">
                        <Input ref={setEnd} value={props.endDate ? Moment(props.endDate).local().format("MM/DD/YYYY") : ""} id={"calender-end-open1"} label="Check-out" inputStyle="" labelStyle="stacked" placeholder="Add date">Check-out</Input>
                    </div>
                </div>
            </div>
        </Page>
    );
}


export default DatePickerFile;