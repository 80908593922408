import { Component } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import queryString from "query-string";
import Dropdown from "react-bootstrap/Dropdown";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, Page } from "@mobiscroll/react";
import Moment from "moment";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Menu } from "antd";
import { Spinner } from "react-bootstrap";
const queryParams = queryString.parse(location.search);

class GlobalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      city_name: props.queryParams ? props.queryParams.city_name : (props.queryParams ? props.queryParams.city : ""),
      selectedDate: "",
      // input: [],
      // Moment(new Date(date.value[0])).format("MM-DD-YYYY")
      // moveoutDate: Moment(new Date()).format("MM-DD-YYYY"),
      adultCount: 1,
      childCount: 0,
      bedroomcount: 1,
      bathCount: 1,
      infantCount: 0,
      petsCount: 0,
      bedroom: "",
      location: queryParams.city ? queryParams.city : [],
      location_name: [],
      guestValue: "1 Guest",
      bedbathValue: "1 Bedroom, 1 Bathroom",
      bathroom: "",
      page: props.page,
      // add:queryParams.furnished ===  "Unfurnished" ? "Unfurnished" : "Furnished",
      add: "",
      property_slug: props.match ? props.match.params.propertySlug : "",
      move_in_date: props.queryParams.checkin ? props.queryParams.checkin : Moment(new Date()).format("YYYY/MM/DD"),
      move_out_date: props.queryParams.checkout ? props.queryParams.checkout : "",
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
      petsCount: 0,
      queryString: "",
      queryStringltr: "",
      locationSelected: false,
      loaderSearch: false,
    };
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementbath = this.incrementbath.bind(this);
    this.decrementbath = this.decrementbath.bind(this);
    this.incrementBed = this.incrementBed.bind(this);
    this.decrementBed = this.decrementBed.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
    // this.handleDateChange = this.handleDateChange.bind(this);
    // this.handleDateChangeRange = this.handleDateChangeRange.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.bedbathDetail = this.bedbathDetail.bind(this);
    this.Setclosebath = this.Setclosebath.bind(this);
    this.createQueryString();
    this.createQueryStringLtr();
    this.Setclose = this.Setclose.bind(this);
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  changeHandlerSelect = async (event) => {
    let input = this.state.input;
    input.add = event.target.value;
    await this.setState({ add: event.target.value, input: input });
    await this.createQueryString();
    await this.createQueryStringLtr();
    if (event.target.value == "Unfurnished") {
      var unit = document.getElementById("uncontrolled-tab-example-tab-live-here");
      if (unit) {
        unit.click();
      }
    } else {
      var unit = document.getElementById("uncontrolled-tab-example-tab-stay-here");
      if (unit) {
        unit.click();
      }
    }
  }

  componentDidMount = async () => {
    if (this.props.page === "str") {

      await this.setState({
        adultCount: (this.props.queryParams.numberOfAdults != "" && !isNaN(this.props.queryParams.numberOfAdults)) ? parseInt(this.props.queryParams.numberOfAdults) : 1,
        childCount: (this.props.queryParams.numberOfChildren != "" && !isNaN(this.props.queryParams.numberOfChildren)) ? parseInt(this.props.queryParams.numberOfChildren) : 0,
        infantCount: (this.props.queryParams.numberOfInfants != "" && !isNaN(this.props.queryParams.numberOfInfants)) ? parseInt(this.props.queryParams.numberOfInfants) : 0,
        petsCount: (this.props.queryParams.numberOfPets != "" && !isNaN(this.props.queryParams.numberOfPets)) ? parseInt(this.props.queryParams.numberOfPets) : 0,
        city_id: (this.props.queryParams.city != "" && !isNaN(this.props.queryParams.city)) ? parseInt(this.props.queryParams.city) : 0,
      });
      await this.guestDetail();
      await this.createQueryStringLtr();
      await this.createQueryString();
    } else {
      await this.setState({
        bedroomcount: (this.props.queryParams.numberOfbed != "" && !isNaN(this.props.queryParams.numberOfbed)) ? parseInt(this.props.queryParams.numberOfbed) : 1,
        bathCount: (this.props.queryParams.numberOfbathroom != "" && !isNaN(this.props.queryParams.numberOfbathroom)) ? parseInt(this.props.queryParams.numberOfbathroom) : 0,
        city_id: (this.props.queryParams.city != "" && !isNaN(this.props.queryParams.city)) ? parseInt(this.props.queryParams.city) : 0,
      });
      await this.bedbathDetail();
      await this.createQueryStringLtr();
      await this.createQueryString();
    }
  }
  createQueryString = () => {
    var queryString = "";
    queryString = `?city_name=${this.state.city_name ? this.state.city_name : this.state.location_name}&city=${this.state.city_id ? this.state.city_id : this.state.location}&checkin=${this.state.selectedDate}&furnished=${this.state.add}&numberOfbed=${this.state.bedroomcount}&numberOfbathroom=${this.state.bathCount}`;
    this.setState({ queryString: queryString });
  }
  searchRedirect = (path) => {
    this.setState({ loaderSearch: true });
    if (this.state.locationSelected) {
      this.setState({ loaderSearch: true });
      window.location.href = "/" + path + (path === "floorplan-listing" ? this.state.queryString : this.state.queryStringltr)
    } else {
      var unit = document.getElementById("autocomplete-" + path);
      this.setState({ loaderSearch: false });
      if (unit) {
        unit.click();
      }
    }
  }
  // handleDateChange = async (date, name) => {
  //   let input = this.state.input;
  //   let a = new Date(date.valueText);
  //   input.move_in_date = Moment(new Date(date.value)).format("MM-DD-YYYY");
  //   await this.setState({ selectedDate: input.move_in_date, input: input });
  //   await this.createQueryString();
  // }

  // handleDateChangeRange = async (date, name) => {
  //   let input = this.state.input;
  //   let a = new Date(date.valueText);
  //   input.move_in_date = Moment(new Date(date.value[0])).format("MM-DD-YYYY");
  //   input.move_out_date = Moment(new Date(date.value[1])).format("MM-DD-YYYY");
  //   await this.setState({ selectedDate: input.move_in_date, moveoutDate: input.move_out_date, input: input });
  //   await this.createQueryStringLtr();
  // }
  createQueryStringLtr = () => {
    var queryStringltr = "";
    queryStringltr = `?city_name=${this.state.city_name ? this.state.city_name : this.state.location_name}&city=${this.state.city_id ? this.state.city_id : this.state.location}&checkin=${this.state.selectedDate}&checkout=${this.state.move_out_date ? this.state.move_out_date : ""}&furnished=${this.state.add}&numberOfAdults=${this.state.adultCount}&numberOfChildren=${this.state.childCount}&numberOfInfants=${this.state.infantCount}`;
    this.setState({ queryStringltr: queryStringltr });
  }
  Setclose() {
    var unit = document.getElementById("dropdown-basic-bath");
    if (unit) {
      unit.click();
    }
  }
  bedbathDetail = () => {
    var message = "";
    message +=
      this.state.bedroomcount > 1
        ? this.state.bedroomcount + " Bedrooms"
        : this.state.bedroomcount + " Bedroom";
    message += this.state.bathCount
      ? this.state.bathCount > 1
        ? ", " + this.state.bathCount + " Bathrooms"
        : ", " + this.state.bathCount + " Bathroom"
      : "";
    this.setState({ bedbathValue: message });
    // return message;
  };
  Setclosebath() {
    var unit = document.getElementById("dropdown-basic-bed");
    if (unit) {
      unit.click();
    }
  }
  async incrementbath() {
    await this.setState({ bathCount: this.state.bathCount + 1 });
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async decrementbath() {
    if (this.state.bathCount === 0 || this.state.bathCount === 1) {
      await this.setState({ bathCount: 1 });
    } else {
      await this.setState({ bathCount: this.state.bathCount - 1 });
    }
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async incrementBed() {
    await this.setState({ bedroomcount: this.state.bedroomcount + 1 });
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async decrementBed() {
    if (this.state.bedroomcount === 0) {
      await this.setState({ bedroomcount: 0 });
    } else {
      await this.setState({ bedroomcount: this.state.bedroomcount - 1 });
    }
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async incrementAdult() {
    await this.setState({ adultCount: this.state.adultCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({ adultCount: this.state.adultCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({ childCount: this.state.childCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({ childCount: this.state.childCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  guestDetail = () => {
    var message = "";
    //  if (this.props.page === "str") {
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Children"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    //}
    this.setState({ guestValue: message });
    // return message;
  };

  changeDateHandlerNew = (date) => {
    this.props.changeDateHandlerNew(date);
    this.createQueryStringLtr();
    this.createQueryString();
  }

  handleChangelocation = async (event, v) => {
    await this.setState({ city_id: event.city_id, location_name: event.label, city_name: event.label });
    this.props.handleChangelocation(event);
    await this.createQueryStringLtr();
    await this.createQueryString();
  };

  filterData = async () => {
    this.setState({ loaderSearch: true });
    if (this.props.page === "str") {
      let inputData2 = {
        property_seo_slug: this.props.propertySlug ? this.props.propertySlug : "",
        city_id: this.state.city_id ? this.state.city_id : "",
        Furnished: this.state.add === "Unfurnished" ? ['Unfurnished'] : ['Fully Furnished'],
        Move_in_date: this.props.startDate ? Moment(new Date(this.props.startDate)).format("YYYY-MM-DD") : Moment(new Date()).format("YYYY-MM-DD"),
        Move_out_date: this.props.endDate ? Moment(new Date(this.props.endDate)).format("YYYY-MM-DD") : "",
        guestValue: this.state.adultCount + this.state.childCount + this.state.infantCount,
      }
      await this.props.filterData(inputData2);
    } else {
      let inputData2 = {
        property_seo_slug: this.props.propertySlug ? this.props.propertySlug : "",
        city_id: this.state.city_id ? this.state.city_id : "",
        Furnished: this.state.add === "Unfurnished" ? ['Unfurnished'] : ['Fully Furnished'],
        Move_in_date: (this.props.startDate !== "") ? Moment(this.props.startDate).format("YYYY-MM-DD") : Moment(new Date()).format('YYYY-MM-DD'),
        Bedroom: this.state.bedroomcount ? [this.state.bedroomcount] : [],
        Bathroom: this.state.bathCount ? [this.state.bathCount] : [],
        isquery: 1,
      }
      await this.props.filterData(inputData2);
    }
    this.setState({ loaderSearch: false });
  }

  render() {
    const inputPropsLtr = {
      className: "md-mobile-picker-input",
      placeholder: "Add date",
    };
    const inputProps = {
      className: "md-mobile-picker-input",
      placeholder: "Add dates",
    };
    return (
      <div className="mainfilter">
        <div className="container">
          <div className="filtersection">
            <Tabs
              defaultActiveKey={
                this.props.page === "ltr" ? "live-here" : "stay-here"
              }
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="live-here" title="Live here">
                <div className="tab-section">
                  <div className="search d-flex flex-column justify-content-start align-items-start">
                    <label>Where</label>
                    <Stack spacing={2} md={{ width: 300 }} sx={{ width: 200 }}>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        name="location"
                        disablePortal
                        disableClearable
                        value={this.state.city_name}
                        onChange={(e, v) => {
                          this.handleChangelocation(v);
                        }}
                        options={
                          this.props.locationArr
                        }
                        // onChange={this.handleChangelocation}
                        // getOptionValue={(option) => `${option}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            hiddenLabel
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            placeholder="Search locations"
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div className="add d-flex flex-column justify-content-start align-items-start">
                    <label>Add</label>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={this.state.add ? this.state.add : "Unfurnished"}
                        //disabled
                        name="add"
                        onChange={(event) => this.changeHandlerSelect(event)}
                      >

                        <MenuItem value="Furnished">Furnished</MenuItem>
                        <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                      </Select>
                    </FormControl>


                  </div>
                  <div className="when d-flex flex-column justify-content-start align-items-start">
                    <label>When</label>
                    <Datepicker
                      controls={["calendar"]}
                      buttons={[{
                        // text: "X",
                        icon: "material-close",
                        handler: 'cancel'
                      }]}
                      // buttons={['set', 'cancel']}
                      pages={1}
                      calendarType="month"
                      invalid={[]}
                      // /select=""
                      inputComponent="input"
                      inputProps={inputPropsLtr}
                      //selected={this.props.queryParams ? this.props.queryParams.checkin :"" }
                      // startInput={this.props.startDate}
                      // endInput={this.props.endDate}
                      defaultValue={this.props.startDate}
                      //  value={this.props.startDate + " - " + this.props.endDate}
                      onChange={(date) => this.changeDateHandlerNew(date)}
                      name="dateFour"
                      // closeOnEsc={true}
                      // cssClass=""
                      // display="bottom"
                      rangeSelectMode="wizard"
                      showRangeLabels={true}
                      touchUi={false}
                      theme="ios"
                      themeVariant="light"
                      headerText=""
                      max=""
                      // rangeStartLabel="Check-in"
                      // rangeEndLabel="Check-out"
                      // rangeEndLabel="Check-out"
                      // responsive={{
                      //   xsmall: {
                      //     pages: 2,
                      //     touchUi: true,
                      //     calendarScroll: "horizontal",
                      //     calendarSize: "12",
                      //     // display: "top",
                      //     refDate: Moment(new Date()).add(1, "days"),
                      //   },
                      //   medium: {
                      //     // display: 'center',
                      //     pages: 2,
                      //     // touchUi: true
                      //   }
                      //   // custom: {
                      //   //   breakpoint: 850,
                      //   //   display: 'center',
                      //   // pages: 2,
                      //   //   touchUi: false
                      //   //}
                      // }}

                      responsive={{
                        xsmall: {
                          pages: 1,
                          touchUi: false,
                          calendarSize: "2",
                          cssClass: "single_pick_date",
                          calendarScroll: "horizontal",
                          refDate: Moment(new Date()).add(1, "year"),
                        },
                        medium: {
                          // display: 'center',
                          pages: 1,
                          // touchUi: true
                        }
                        // custom: {
                        //   breakpoint: 850,
                        //   display: 'center',
                        // pages: 2,
                        //   touchUi: false
                        //}
                      }}
                    />
                  </div>
                  <div className="details d-flex flex-column justify-content-start align-items-start">
                    <label className="vs-hide-">Details</label>
                    <Dropdown autoClose={false}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic-bed"
                      >
                        <TextField
                          id="outlined-read-only-input"
                          // label="GUESTS"
                          InputProps={{
                            readOnly: true,
                          }}
                          className="inputBox"
                          //placeholder="Add guests"
                          value={this.state.bedbathValue}
                          // sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="d-flex justify-content-between pd-15 pd-b-5">
                          <ul>
                            <li>
                              {/* <b>Bedrooms</b> */}
                              <li><b>{this.state.bedroomcount > 1 ? "Bedrooms" : "Bedroom"}</b></li>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button
                                onClick={this.decrementBed}
                                disabled={
                                  this.state.bedroomcount === 1 ? true : false
                                }
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                              </button>
                            </span>
                            <span>{this.state.bedroomcount}</span>
                            <span>
                              <button onClick={this.incrementBed}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                              </button>
                            </span>

                          </div>
                        </div>
                        <div className="d-flex justify-content-between pd-15 pd-b-5">
                          <ul>
                            <li>
                              {/* <b>Bathrooms</b> */}
                              <li><b>{this.state.bathCount > 1 ? "Bathrooms" : "Bathroom"}</b></li>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button onClick={this.decrementbath}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                              </button>
                            </span>
                            <span>{this.state.bathCount}</span>
                            <span>
                              <button onClick={this.incrementbath}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                              </button>
                            </span>

                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            className="done"
                            id="cnt-close"
                            onClick={this.Setclosebath}
                          >
                            Close
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {this.props.page === "ltr" ?
                    <>
                      <div className="search-btn">
                        <button className={this.state.loaderSearch ? "search_loader" : ""} >
                          <img alt="Live here search" title="Live here search" src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} onClick={() => this.filterData()} />
                        </button>
                      </div>
                      <div className="mobilesearch-btn">
                        <button onClick={() => this.filterData()}>
                          {this.state.loaderSearch ?
                            <>
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                            </>
                            : "Search"
                          }
                        </button>
                      </div>
                    </>
                    :
                    <>
                      <div className="search-btn">
                        {this.state.property_slug ? (
                          <button >
                            <a href={
                              global.themeUrl + this.state.property_slug +
                              "/floorplan-listing" +
                              this.state.queryStringltr

                            }><img src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} alt="Floor plan search" title="Floor plan search" /></a></button>
                        ) : (
                          <button>
                            <a href={
                              "/floorplan-listing" +
                              this.state.queryStringltr
                            }><img src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} alt="Floor plan search" title="Floor plan search" /></a></button>

                        )}
                      </div>
                      <div className="mobilesearch-btn">
                        {this.state.property_slug ? (
                          <button>
                            <a href={
                              global.themeUrl + this.state.property_slug +
                              "/floorplan-listing" +
                              this.state.queryStringltr

                            }>search</a></button>
                        ) : (
                          <button>
                            <a href={
                              "/floorplan-listing" +
                              this.state.queryStringltr
                            }>Search</a></button>

                        )}
                      </div>
                    </>
                  }
                </div>
              </Tab>
              <Tab eventKey="stay-here" title="Stay here">
                <div className="tab-section">
                  <div className="search d-flex flex-column justify-content-start align-items-start">
                    <label>Where</label>
                    <Stack spacing={2} md={{ width: 300 }} sx={{ width: 200 }}>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        disablePortal
                        // options={top100Films.map((option) => option.title)}
                        name="location"
                        // value={
                        //   this.props.queryParams
                        //     ? this.props.queryParams.city_name
                        //     : ""
                        // }
                        value={this.state.city_name}
                        //alue={option}
                        onChange={(e, v) => {
                          this.handleChangelocation(v);
                        }}
                        options={
                          this.props.locationArr ? this.props.locationArr : []
                        }
                        // onChange={this.handleChangelocation}
                        // getOptionValue={(option) => `${option}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            hiddenLabel
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            placeholder="Search locations"
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div className="add d-flex flex-column justify-content-start align-items-start">
                    <label>Add</label>
                    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={this.state.add ? this.state.add : "Furnished"}
                        // disabled
                        name="add"
                        onChange={(event) => this.changeHandlerSelect(event)}
                      >
                        {/* <MenuItem value={10} selected>
                          Furnished
                        </MenuItem> */}
                        <MenuItem value="Furnished">Furnished</MenuItem>
                        <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                        {/* <MenuItem value={10} selected>Unfurnished</MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="when d-flex flex-column justify-content-start align-items-start">
                    <label>When</label>
                    <Datepicker
                      controls={["calendar"]}
                      buttons={[{
                        // text: "X",
                        icon: "material-close",
                        handler: 'cancel'
                      }]}
                      // /buttons={["cancel"]}
                      pages={2}
                      calendarType="month"
                      invalid={[]}
                      select="range"
                      inputComponent="input"
                      inputProps={inputProps}
                      //selected={this.props.queryParams ? this.props.queryParams.checkin :"" }
                      // startInput={this.props.startDate}
                      // endInput={this.props.endDate}
                      defaultValue={this.props.startDate + " - " + this.props.endDate}
                      //  value={this.props.startDate + " - " + this.props.endDate}
                      onChange={(date) => this.changeDateHandlerNew(date)}
                      name="dateFour"
                      closeOnEsc={true}
                      cssClass="str_dual_calendar"
                      // display="bottom"
                      rangeSelectMode="wizard"
                      showRangeLabels={true}
                      touchUi={false}
                      theme="ios"
                      themeVariant="light"
                      headerText=""
                      max=""
                      rangeStartLabel="Check-in"
                      rangeEndLabel="Check-out"
                      responsive={{
                        xsmall: {
                          pages: 2,
                          touchUi: false,
                          calendarScroll: "horizontal",
                          calendarSize: "2",
                          refDate: Moment(new Date()).add(1, "year"),
                        },
                        medium: {
                          // display: 'center',
                          pages: 2,
                          // touchUi: true
                        }
                        // custom: {
                        //   breakpoint: 850,
                        //   display: 'center',
                        // pages: 2,
                        //   touchUi: false
                        //}
                      }}
                    />
                  </div>
                  <div className="details d-flex flex-column justify-content-start align-items-start">
                    <label>Details</label>
                    <Dropdown autoClose={false}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic-bath"
                      >
                        <TextField
                          id="outlined-read-only-input"
                          label=""
                          InputProps={{
                            readOnly: true,
                          }}
                          className="inputBox"
                          placeholder="Add guests"
                          value={this.state.guestValue}
                          // sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="d-flex justify-content-between align-items-start  pd-15 pd-b-5">
                          <ul>
                            <li>
                              <b>{this.state.adultCount > 1 ? "Adults" : "Adult"}</b>
                              <i>Age 13+</i>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button
                                onClick={this.decrementAdult}
                                disabled={
                                  this.state.adultCount === 1 ? true : false
                                }
                              >
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                              </button>
                            </span>
                            <span>{this.state.adultCount}</span>
                            <span>
                              <button onClick={this.incrementAdult}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                          <ul>
                            <li>
                              <b>{this.state.childCount > 1 ? "Children" : "Child"}</b>
                              <i>Ages 2–12</i>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button onClick={this.decrementchild}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                              </button>
                            </span>
                            <span>{this.state.childCount}</span>
                            <span>
                              <button onClick={this.incrementchild}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                              </button>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                          <ul>
                            <li>
                              <b>{this.state.infantCount > 1 ? "Infants" : "Infant"}</b>
                              <i>Under 2</i>
                            </li>
                          </ul>
                          <div className="d-flex align-items-center countBtn">
                            <span>
                              <button onClick={this.decrementinfant}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                              </button>
                            </span>
                            <span>{this.state.infantCount}</span>

                            <span>
                              <button onClick={this.incrementinfant}>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                              </button>
                            </span>
                          </div>
                        </div>
                        {/*  <p>
                                                This place has a maximum of 6
                                                guests, not including infants. Pets
                                                aren't allowed.
                                              </p> */}
                        <div className="d-flex justify-content-end">
                          <button
                            className="done"
                            id="cnt-close"
                            onClick={this.Setclose}
                          >
                            Close
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {this.props.page === "str" ?
                    <>
                      <div className="search-btn">
                        <button className={this.state.loaderSearch ? "search_loader" : ""} >
                          <img alt="Stay here search" title="Stay here search" src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} onClick={() => this.filterData()} />
                        </button>
                      </div>
                      <div className="mobilesearch-btn">
                        <button onClick={() => this.filterData()}>
                          {this.state.loaderSearch ?
                            <>
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                            </>
                            : "Search"
                          }
                        </button>
                      </div>
                    </>
                    :
                    <>
                      <div className="search-btn">
                        {this.state.property_slug ? (
                          <button>
                            <a href={
                              global.themeUrl + this.state.property_slug +
                              "/str-listing" +
                              this.state.queryStringltr

                            }><img alt="Stay here search" title="Stay here search" src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} /></a></button>
                        ) : (
                          <button>
                            <a href={
                              "/str-listing" +
                              this.state.queryStringltr
                            }><img alt="Stay here search" title="Stay here search" src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} /></a></button>

                        )}

                      </div>
                      <div className="mobilesearch-btn">
                        {this.state.property_slug ? (
                          <button>
                            <a href={
                              global.themeUrl + this.state.property_slug +
                              "/str-listing" +
                              this.state.queryStringltr

                            }>Search</a></button>
                        ) : (
                          <button>
                            <a href={
                              "/str-listing" +
                              this.state.queryStringltr
                            }>Search</a></button>

                        )}
                      </div>
                    </>
                  }
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

// export default TripsTable;
export default GlobalFilter;
