//     							                          //
//  Program: booktourform.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import ModalVideo from 'react-modal-videojs'
import MetaSEO from "../../../MetaSEO";

class Leasing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personName: [],
            date: new Date('2014-08-18T21:11:54'),
            selectedDate: new Date(),
            type: false,
            value: undefined,
            inputValue: undefined,
            input: {},
            errors: {},
            first_name: "",
            // property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
            last_name: "",
            email: "",
            phone_number: "",
            applicant_photo: "",
            price_range: "",
            desired_move_in_date: "",
            comment: "",
            status_id: true,
            helperText: "",
            bedroom: [],
            bedroomArray: [],
            priceRangeArray: [],
            isOpen: false,
            currentVideoUrl: "",
            autoPlay: false,
            // meta tags start
            title: global.level === 2 ? localStorage.getItem("brand_name") : (global.level === 3 ? localStorage.getItem("property_name") : ""),
            favicon: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : ""),
            keywords: global.keywords,
            description: global.description,
            url: window.location.href,
            image: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : ""),
            fb_app_id: "",
            // meta tags end
            source: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerQA.mp4","website/video") + "?autoplay=1",
            source1: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerOverview.mp4","website/video") + "?autoplay=1",
            source2: global.OnKeyPressEvent.getS3ImageUrl("OrionHausHomesharingProcess.mp4","website/video") + "?autoplay=1",
            source3: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyTourVideo.mp4","website/video") + "?autoplay=1",
            source4: global.OnKeyPressEvent.getS3ImageUrl("OrionHausKeyAccess.mp4","website/video") + "?autoplay=1",
            source5: global.OnKeyPressEvent.getS3ImageUrl("OrionHausResidentHomesharingKit.mp4","website/video") + "?autoplay=1",
            show: false,
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false
        }
        this.openModal = this.openModal.bind(this)


    }

    openModal(src) {
        this.setState({ isOpen: true })
        this.setState({ currentVideoUrl: src })
    }

    showModal = (val = false, param = 'show') => {
        this.setState({ [param]: val });
    };



    render() {
        const { poster, source, show } = this.state;
        return (
            <main className="oh-template Brandmainpage header_hosting">
                {/* Meta Tag Start */}
                <MetaSEO
                    title={this.state.title}
                    favicon={this.state.favicon}
                    keywords={this.state.keywords}
                    description={this.state.description}
                    url={this.state.url}
                    image={this.state.image}
                    fb_app_id={this.state.fb_app_id}
                    noIndex="true"
                    author={"OHAI"}
                />
                {/* Meta Tag End */}
                {/* Header */}
                <CommonHeader propertySlug={this.state.property_slug} />
                {/* middle */}
                <section className="middle hosting_sec">
                    <section className="partner_banner leasing-process_banner">
                        <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-25 pd-r-25">
                            <h1>Leasing process for property managers</h1>
                        </div>
                    </section>

                    <section className="homesharing_sec leasing_into_sec">
                        <div className="container">
                            <div className="row m-0 ">
                                <div className="col-12">
                                    <div className="home_heading_main ">
                                        <h2>What is OHAI</h2>
                                        <h4 className="homeshare_head_2">OHAI is your Homesharing management team (and there’s an app for that!). If you are reading this, it means we are now working together to bring your residents an amenity that helps them make money. This amenity increases value to your property and makes your community a more desirable place to live, because it offers people a way to make additional income from their apartment when they’re not using it. </h4>
                                        <div className="video_pop_img">
                                            <ModalVideo
                                                id={(new Date() * 1).toString()}
                                                source={this.state.source}
                                                poster={global.OnKeyPressEvent.getS3ImageUrl('IntroducingHomsharingToPropertyManagers.JPG',"website/video")}
                                                show={this.state.show}
                                                showModal={() => this.showModal(true, 'show')}
                                                handleClose={() => this.showModal(false, 'show')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="get_started Leasint_tour_sec">
                        <div className="container">
                            <div className="row m-0">
                                <div className="col-12">
                                    <div className="home_heading_main">
                                        <h2>Property Tours</h2>
                                        <h4>Property tours do not change due to the Homesharing program. Your property management team will continue to offer an extraordinary experience to prospective residents during tours, just as you have been doing it all along.</h4>
                                        <h4>When it comes to talking about the Homesharing program, think of it as an amenity, so you will talk about it the same way you talk about other amenities like the gym or the pool.</h4>
                                        <div className="video_pop_img">
                                            <ModalVideo
                                                id={(new Date() * 1).toString()}
                                                source={this.state.source1}
                                                poster={global.OnKeyPressEvent.getS3ImageUrl('TalkingAboutHomesharingDuringPropertyTours.JPG',"website/video")}
                                                show={this.state.show1}
                                                showModal={() => this.showModal(true, 'show1')}
                                                handleClose={() => this.showModal(false, 'show1')}
                                            />
                                        </div>
                                        </div>
                                </div>
                                <div className="col-sm-12 col-xs-12" >
                                    <div className="get_flow Leasing_list">
                                        <h5>Main points to mention about the Homesharing program amenity during tours:</h5>
                                        <ul>
                                            <li>“Oh! We also offer Homesharing here. We’re partnered with OHAI to provide our residents the ability to Airbnb your apartment when you’re not home, all through the OHAI app.”</li>
                                            <li>“The rent is a little high, but- do you ever spend the night away from home? If so, you can homeshare your apartment through OHAI and offset your rent”</li>
                                            <li>Show them the OHAI Homesharing Process video</li>
                                            </ul>
                                            <div className="video_pop_img">
                                                    <ModalVideo
                                                        id={(new Date() * 1).toString()}
                                                        source={this.state.source2}
                                                        poster={global.OnKeyPressEvent.getS3ImageUrl('OrionHausHomesharingProcessvideo.JPG',"website/video")}
                                                        show={this.state.show2}
                                                        showModal={() => this.showModal(true, 'show2')}
                                                        handleClose={() => this.showModal(false, 'show2')}
                                                    />
                                                </div>
                                            <ul>
                                            <li>“The OHAI team manages all the hard things like guest communication, cleaning and laundry schedule and key exchange for guest access, for only 7.5% of the nightly rate”</li>
                                            <li>OHAI put together a high quality linen kit with 3 sets of sheets and towels per room at a discounted price (if resident asks the price, you can say it is based on the property and OHAI will provide a link to see the items in the kit and the price, the kit is necessary for a consistent guest experience and for laundry management)</li>
                                            <li>You can also show residents The OHAI Homesharing Program Overview video  </li>
                                          </ul>
                                          <div className="video_pop_img">
                                                    <ModalVideo
                                                        id={(new Date() * 1).toString()}
                                                        source={this.state.source3}
                                                        poster={global.OnKeyPressEvent.getS3ImageUrl('TheOrionHausHomesharingProgramOverviewvideo.JPG',"website/video")}
                                                        show={this.state.show3}
                                                        showModal={() => this.showModal(true, 'show3')}
                                                        handleClose={() => this.showModal(false, 'show3')}
                                                    />
                                                </div>
                                          <ul>
                                            <li>At the end of the tour make sure to tell the residents how easy it is to drop off keys at the KeyCafe box which is the same process that “their guests” will use (try to use statements like “your guests” and “your apartment” to make them feel as if it is already happening). Show them the How OHAI Guests Get Their Keys video and walk with them to drop off a key if they used the self-guided tour option</li>
                                                </ul>
                                                    <div className="video_pop_img">
                                                        <ModalVideo
                                                            id={(new Date() * 1).toString()}
                                                            source={this.state.source4}
                                                            poster={global.OnKeyPressEvent.getS3ImageUrl('HowOrionHausGuestsGetTheirKeys.JPG',"website/video")}
                                                            show={this.state.show4}
                                                            showModal={() => this.showModal(true, 'show4')}
                                                            handleClose={() => this.showModal(false, 'show4')}
                                                        />
                                                    </div>
                                                <ul>
                                            <li className="m-0">After the tour make sure to follow up with OHAI and continue with the procedures that you normally follow after a tour </li>
                                        </ul>
                                        <h5>After resident signs a lease:</h5>
                                        <ul>
                                            <li>First, celebrate, you just got a lease signed!!!</li>
                                            <li>After the lease is signed, text an OH team member to help you locate an available linen kit. The OH team member will let you know which apartment and closet to go to to get their linen kit. You will present the linen kit to the resident and ask that they scan the QR codes on the postcard that comes with the kit. Show them the Resident Homesharing Kit Video and OHAI will take it from there.
                                               
                                                </li>
                                        </ul>
                                        <div className="video_pop_img">
                                                    <ModalVideo
                                                        id={(new Date() * 1).toString()}
                                                        source={this.state.source5}
                                                        poster={global.OnKeyPressEvent.getS3ImageUrl('ResidentHomesharingKit.jpg',"website/video")}
                                                        show={this.state.show5}
                                                        showModal={() => this.showModal(true, 'show5')}
                                                        handleClose={() => this.showModal(false, 'show5')}
                                                    />
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                </main >
        );
    }
}
export default Leasing