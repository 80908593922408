
import React, { Component } from "react";
import PhoneInput from 'react-phone-input-2'

class PhoneNumberFormat  extends Component {
    constructor(props) {
      super(props);
      this.state = {
        phone_number:this.props.phone_number,
        key_no:this.props.key_no ? this.props.key_no  : "",
        displayPhoneNo:""
      }
    }

    componentDidMount = async() => {
      if(!this.state.key_no) {
          var key = global.OnKeyPressEvent.generateSlug();
          await this.setState({key_no:key});
        }
        var ele = document.getElementById('phoneNumberFormat' + this.state.key_no);
        var eleValue = ele.value;
        this.setState({displayPhoneNo:eleValue});
    }

    render() {
      return (
        <>
            <span key={this.state.key_no} id={this.state.key_no}>{this.state.displayPhoneNo}</span>
                <PhoneInput
                    country={global.country}
                    disableSearchIcon
                    // autoFormat="true"
                    enableSearch="true"
                    countryCodeEditable="true"
                    value={this.state.phone_number}
                    inputProps={{
                        id:"phoneNumberFormat" + this.state.key_no
                    }}
                    containerClass="d-none"
                />
            </>
      );
    }
  }

  export default PhoneNumberFormat;