//     							                          //
//  Program: Inventory.jsx                                //
//  Application: KINI HTML                                //
//  Option: Home Sharing Listing                          //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import Moment from "moment";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { Skeleton, Typography } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import ReservationService from "../../services/ReservationService";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import StrlistingApi from "../../services/StrlistingApi";

class Getreceipt extends Component {
  constructor() {
    super();
    this.state = {
      currentData: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details"))
        : [],
      headerToken: localStorage.getItem("headerToken") ? localStorage.getItem("headerToken") : "",  
      cancelModal: false,
      openGettingAroundModal: false,
      cancel_amount: "",
      openGuestmanualModal: false,
      openArrivalinstructionModal:false,
      tab: parseInt(localStorage.getItem("tab")),
      today: Moment(new Date()).format("YYYY-MM-DD H:m:s"),
      // property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
      // property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
    };
    this.downloadPdf = this.downloadPdf.bind(this);
  }
  componentDidMount() {
    var channel = this.state.currentData.set_channel ? this.state.currentData.set_channel : 1;
    if (channel === 1) {
      this.setState({ set_channel: "Direct Booking" });
    }
    else if (channel === 2) {
      this.setState({ set_channel: "Airbnb" });
    }
    else if (channel === 3) {
      this.setState({ set_channel: "OTA" });
    }
    else if (channel === 4) {
      this.setState({ set_channel: "Expedia" });
    }
    else if (channel === 5) {
      this.setState({ set_channel: "Vrbo" });
    }

  }

  async downloadPdf(url, name) {
    let file_name = name.split(".")[0];
    var queryString = "path=" + url + "&display_file_name=" + file_name;
    this.setState({ showSpinner: true, loading: true });
    let inputData = {
      // path: url,
    };
    var resData = {};
    await StrlistingApi.downloadPdf(inputData,queryString);
    // await MultiImageService.downloadFile(inputData, queryString);
    this.setState(resData);
    this.setState({
      showSpinner: false,
      loading: false,
    });
  }
  openGuestmanualModal = (val = false) => {
    this.setState({ openGuestmanualModal: val });
  };
  openArrivalinstructionModal = (val = false) => {
    this.setState({ openArrivalinstructionModal: val });
  };
  render() {
    return (
      <main className="oh-template Brandmainpage getreceiptsection">
        <CommonHeader propertySlug={this.state.property_slug} CheckIn={this.props.CheckIn}/>
        <div className="container-fluid p-0">
          <section className="reservationBook req_book_sec bgwhitecolor">
            <div className="container">
              <div className="content">
                
                <div className="row mobView">
                {this.state.headerToken ? 
                  <div className="reservation-data-mob">
                    <div className="back_go">
                      <a href="/reservation-details">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                      </a>
                      <div className="d-flex flex-column">
                          <h4 className="back_arrow">
                            {/* My Trips */}
                            Your receipt from OHAI
                          </h4>
                          <div className="confirm_text">
                            <p>Receipt ID: {this.state.currentData.confirmation_code
                                  ? this.state.currentData.confirmation_code
                                  : "-"} <b>-</b> {this.state.currentData.booked_at
                                    ? Moment(this.state.currentData.booked_at).format(
                                      global.dateFormat
                                    )
                                    : "-"}  </p>
                          </div>
                      </div>
                    </div>
                  </div>: 
                  <div className="reservation-data-mob">
                    <div className="back_go">
                      <a href="/reservation">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                      </a>
                      <div className="d-flex flex-column pd-l-5">
                          <h4 className="back_arrow">
                            {/* My Trips */}
                            Your receipt from OHAI
                          </h4>
                          <div className="confirm_text">
                            <p>Receipt ID: {this.state.currentData.confirmation_code
                                  ? this.state.currentData.confirmation_code : "-"} <b>-</b> {this.state.currentData.booked_at
                                    ? Moment(this.state.currentData.booked_at).format(
                                      global.dateFormat
                                    )
                                    : "-"} </p>
                          </div>
                      </div>
                    </div></div>}
                    <div className="receiptmainsection">
                        <div className="col-sm-12 col-lg-6 col-xl-6 position-relative priceInfo">
                        <div className="requestaddedSection m-0 confirm_recpt">
                          <div className="priceInfo">
                            <div className="priceTop">
                              <h5>
                                {this.state.currentData.listing_name
                                  ? this.state.currentData.listing_name
                                  : ""}
                              </h5>
                            </div>
                            <div className="requestSection confirm_booking">
                              <div className="reservation-info">
                                <div className="reservSection checkdatemob">
                                  <ul>
                                    <li>
                                      <strong>Check-in</strong>
                                      <span className="checkdate-  d-block">
                                        {this.state.currentData.checkin_date
                                          ? Moment(
                                            this.state.currentData.checkin_date
                                          ).format("MM/DD/YY")
                                          : "-"} | {this.state.currentData
                                            ? this.state.currentData.property.check_in_time
                                            : ""}
                                      </span>

                                    </li>
                                    <li>
                                      <strong>Check-out</strong>
                                      <span className="checkdate-  d-block">
                                        {this.state.currentData.checkout_date
                                          ? Moment(
                                            this.state.currentData.checkout_date
                                          ).format("MM/DD/YY")
                                          : "-"} | {this.state.currentData
                                            ? this.state.currentData.property.check_out_time
                                            : ""}
                                      </span>

                                    </li>
                                  </ul>
                                </div>
                                <div className="reservSection">
                                  <div className="reserv_mid_heading">
                                    <h5>Reservation details</h5>
                                  </div>
                                  <div className="row">

                                    {/* <div className="col-6 col-md-6">
                                      <strong>Unit</strong>
                                      <span className="mg-b-10 d-block">
                                        {this.state.currentData.unit_name
                                          ? this.state.currentData.unit_name
                                          : this.state.currentData.unit_number}
                                      </span>
                                    </div> */}
                                    <div className="col-6 col-md-6">
                                      <strong>Confirmation code</strong>
                                      <span className="mg-b-10 d-block">
                                        {this.state.currentData.confirmation_code
                                          ? this.state.currentData.confirmation_code
                                          : "-"}
                                      </span>
                                    </div>
                                    {/* {this.state.currentData.booking_code ? (
                                      <div className="col-6 col-md-6">
                                        <strong className="gustaccess">Guest access code <span><img src={global.OnKeyPressEvent.getS3ImageUrl('info-b.svg')} /></span></strong>
                                        <span className="mg-b-10 d-block">
                                          {this.state.currentData.booking_code ? this.state.currentData.booking_code : "-"}
                                        </span>

                                      </div>) : ""} */}
                                    {this.state.currentData.created_at ? (
                                      <div class="col-6 col-md-6">
                                        <strong>Booking date</strong>
                                        <span className="mg-b-10 d-block">
                                        {this.state.currentData.booked_at
                                        ? Moment(this.state.currentData.booked_at).format(
                                          global.dateFormat
                                        )
                                        : "-"}
                                        </span>
                                      </div>) : ""}
                                    <div className="col-6 col-md-6">
                                      <strong>Status</strong>
                                      <span className="mg-b-10 d-block">
                                        {this.state.currentData.status_text
                                          ? this.state.currentData.status_text
                                          : "Pending"}
                                      </span>
                                    </div>
                                    {this.state.currentData.number_of_guest &&
                                      this.state.currentData.number_of_guest !== null ? (
                                      <div class="col-6 col-md-6">
                                        <strong>No. of guests</strong>
                                        <span className="mg-b-10 d-block">
                                          {this.state.currentData.number_of_guest &&
                                            this.state.currentData.number_of_guest !== null
                                            ? this.state.currentData.number_of_guest
                                            : "-"}
                                        </span>
                                      </div>) : ""}
                                    {/* {this.state.currentData.adults &&
                                      this.state.currentData.adults !== null ? (
                                      <div class="col-6 col-md-6">
                                        <strong>Adults</strong>
                                        <span className="mg-b-10 d-block">
                                          {this.state.currentData.adults &&
                                            this.state.currentData.adults !== null
                                            ? this.state.currentData.adults
                                            : "-"}
                                        </span>
                                      </div>) : ""} */}
                                    {/* {this.state.currentData.children &&
                                      this.state.currentData.children !== null ? (
                                      <div class="col-6 col-md-6">
                                        <strong>Children</strong>
                                        <span className="mg-b-10 d-block">
                                          {this.state.currentData.children &&
                                            this.state.currentData.children !== null
                                            ? this.state.currentData.children
                                            : "-"}
                                        </span>
                                      </div>) : ""} */}
                                    {/* {this.state.currentData.infants &&
                                      this.state.currentData.infants !== null ? (
                                      <div class="col-6 col-md-6">
                                        <strong>Infants</strong>
                                        <span className="mg-b-10 d-block">
                                          {this.state.currentData.infants &&
                                            this.state.currentData.infants !== null
                                            ? this.state.currentData.infants
                                            : "-"}
                                        </span>
                                      </div>) : ""} */}
                                    {/* {this.state.currentData.pets &&
                                      this.state.currentData.pets !== null ? (
                                      <div class="col-6 col-md-6">
                                        <strong>Pets</strong>
                                        <span className="mg-b-10 d-block">
                                          {this.state.currentData.pets &&
                                            this.state.currentData.pets !== null
                                            ? this.state.currentData.pets
                                            : "-"}
                                        </span>
                                      </div>) : ""} */}

                                    {/* <div className="col-6 col-md-6">
                                      <strong>Channel</strong>
                                      <span className="mg-b-10 d-block">
                                        {this.state.set_channel
                                          ? this.state.set_channel
                                          : "Website"}
                                      </span>
                                    </div> */}

                                  </div>
                                </div>
                                <div className="reservSection">
                              <div className="reserv_mid_heading">
                                <h5>Guest details</h5>
                              </div>
                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <strong>First name</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.guest_user
                                      ? this.state.currentData.guest_user.first_name
                                      : "-"}
                                  </span>
                                </div>
                                {this.state.currentData.guest_user.last_name ? (
                                  <div class="col-6 col-md-6">
                                    <strong>Last name</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.guest_user
                                        ? this.state.currentData.guest_user.last_name
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.guest_user && this.state.currentData.guest_user.phone_number !== "undefined" && this.state.currentData.guest_user.phone_number !== "" ? (
                                  <div class="col-6 col-md-6">
                                    <strong>Mobile</strong>
                                    <span className="mg-b-10 d-block">
                                      <PhoneNumberFormat
                                        phone_number={
                                          this.state.currentData.guest_user.phone_number
                                        }
                                      />
                                    </span>
                                  </div>) : ""}
                                  {this.state.currentData.guest_user && this.state.currentData.guest_user.email !== "undefined" && this.state.currentData.guest_user.email !== "" ? (
                                <div className="col-6 col-md-6">
                                  <strong>Email</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.guest_user ? this.state.currentData.guest_user.email : ""}
                                  </span>
                                </div>) : ""}
                                {/* <div className="col-6 col-md-6">
                                  <strong>Address</strong>
                                  <span className="mg-b-10 d-block">

                                  </span>
                                </div> */}
                                {/* {this.state.currentData.guest_user && this.state.currentData.guest_user.city && this.state.currentData.guest_user.city.city_name ? (
                                  <div class="col-6 col-md-6">
                                    <strong>City</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.guest_user ?
                                        this.state.currentData.guest_user.city ? this.state.currentData.guest_user.city.city_name
                                          : "-" : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.guest_user && this.state.currentData.guest_user.state && this.state.currentData.guest_user.state.state_name ? (
                                  <div class="col-6 col-md-6">
                                    <strong>State</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.guest_user ? this.state.currentData.guest_user.state
                                        ? this.state.currentData.guest_user.state.state_name
                                        : "-" : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.guest_user.county ? (
                                  <div class="col-6 col-md-6">
                                    <strong>County</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.guest_user
                                        ? this.state.currentData.guest_user.county ? this.state.currentData.guest_user.county.county_name
                                          : "-" : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.guest_user.country ? (
                                  <div class="col-6 col-md-6">
                                    <strong>Country</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.guest_user
                                        ? this.state.currentData.guest_user.country ? this.state.currentData.guest_user.country.country_name
                                          : "-" : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.guest_user && this.state.currentData.guest_user.zip_code ? (
                                  <div class="col-6 col-md-6">
                                    <strong>Zipcode</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.guest_user
                                        ? this.state.currentData.guest_user.zip_code
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.guest_user.passport ? (
                                  <div class="col-6 col-md-6">
                                    <strong>Passport</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.guest_user
                                        ? this.state.currentData.guest_user.passport
                                          ? this.state.currentData.guest_user.passport
                                          : "-"
                                        : "-"}
                                    </span>
                                  </div>) : ""} */}

                              </div>
                            </div>
                              </div>
                            
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="col-sm-12 col-lg-5 col-xl-5 position-relative priceInfo">
                          <div className="requestaddedSection m-0 confirm_recpt">
                            <div className="priceInfo pd-t-5 pd-b-0">
                              <div className="priceMiddle pd-b-15">
                                <span className="mg-b-20 d-block">Price details</span>
                                <ul>
                                  <li>
                                    <span>{this.state.currentData.total_night_text}</span>
                                    <span>{`${this.state.currentData.total_nightly_rate
                                ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_nightly_rate).toFixed(2)).replace(/\.00$/, ""))
                                :global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_nightly_rate_disp).toFixed(2)).replace(/\.00$/, ""))
                                }`}</span>
                                  </li>

                                  {this.state.currentData.cleaning_fee !== "0.00" &&
                                    this.state.currentData.cleaning_fee > 0 ? (
                                    <li>
                                      <span>Cleaning fee</span>
                                      <span>
                                        {this.state.currentData.cleaning_fee
                                          ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                      </span>
                                    </li>
                                  ) : (
                                    ""
                                  )}

                                  {this.state.currentData.convenience_fee !== "0.00" &&
                                    this.state.currentData.convenience_fee > 0 ? (
                                    <li>
                                      <span>Service fee</span>
                                      <span>
                                        {this.state.currentData.convenience_fee
                                          ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.convenience_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                      </span>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                   {/* {this.state.currentData.guest_service_fee !== "0.00" &&
                              this.state.currentData.guest_service_fee > 0 ? (
                              <li>
                                <span>Service Fee</span>
                                <span>
                                  {this.state.currentData.guest_service_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.guest_service_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )} */}
                                  {this.state.currentData.total_taxes !== "0.00" &&
                                    this.state.currentData.total_taxes > 0 ? (
                                    <li>
                                      <span>Occupancy taxes</span>
                                      <span>
                                        {this.state.currentData.total_taxes
                                          ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                      </span>
                                    </li>
                                  ) : (
                                    ""
                                  )}

                                </ul>
                              </div>
                              <div className="priceBottom">
                                <ul>
                                  <li>
                                    <span>Total (USD)</span>
                                    <b>
                                      <span>
                                        {this.state.currentData.total_payment
                                          ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_payment).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                      </span>
                                    </b>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="priceInfo pd-t-0 pd-b-0">
                              {/* <div className="priceMiddle">
                                <span className="mg-b-20 d-block">Payment</span>
                                <ul className="payu">
                                  <li className="align-items-start">
                                      <strong>PayU</strong>
                                      <span class="d-block">01/20/23</span>
                                    </li>
                                    <li className="align-items-flex-end justify-content-end">
                                      <strong className="payuprice">Cleaning fee</strong>
                                    </li>
                                </ul>
                              </div> */}
                              <div className="priceBottom">
                              <ul>
                                  <li>
                                    <span>Amount paid (USD)</span>
                                    <b>
                                      <span>
                                        {this.state.currentData.total_payment
                                          ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_payment).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                      </span>
                                    </b>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      
                    </div>
                    {this.state.currentData.set_channel === 1 ? (
                    <div className="d-flex align-items-center justify-content-center mg-t-50">
                            <button className="downloadbtn"  onClick={() => {
                                                  this.downloadPdf(
                                                   this.state.currentData.pdfPath,
                                                     this.state.currentData.receipt_pdf,
                                                  );
                                                }} download={true}>Download receipt</button>
                     </div>):""}

                  {/* <div className="col-lg-7  reserve_mobile">
                    <div className="reserv-img">
                      <figure>
                        <img
                          src={
                            this.state.currentData.file_name
                              ? this.state.currentData.file_name
                              : ""
                          }
                          alt={this.state.currentData.listing_name}
                          title={this.state.currentData.listing_name}
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="col-lg-5 ">
                    <div className="reservation-data">
                      <div className="back_go">
                        <h1 className="back_arrow">
                          <a href="/reservation-list">
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                          </a>
                          My Trips
                        </h1>
                      </div>
                      <h2>

                        {this.state.currentData.listing_name
                          ? this.state.currentData.listing_name
                          : ""}
                      </h2>
                      <div className="reservation-info">
                        <ul>
                          <li>
                            <label>

                              Check-in
                              <p>
                                {this.state.currentData.checkin_date
                                  ? Moment(
                                    this.state.currentData.checkin_date
                                  ).format("MM/DD/YY")
                                  : "-"}
                                |
                                {this.state.currentData
                                  ? this.state.currentData.property.check_in_time
                                  : ""}
                              </p>
                            </label>
                          </li>
                          <li>
                            <label>

                              Check-out
                              <p className="pl-5">
                                {this.state.currentData.checkout_date
                                  ? Moment(
                                    this.state.currentData.checkout_date
                                  ).format("MM/DD/YY")
                                  : "-"}
                                |
                                {this.state.currentData
                                  ? this.state.currentData.property.check_out_time
                                  : ""}
                              </p>
                            </label>
                          </li>
                        </ul>
                        <div className="reserv_mid_heading">
                          
                        </div>
                        <div className="row">
                          <div class="col-6 col-md-6">
                            <label>Getting there</label>
                            <p>
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_1) ? this.state.currentData.property.street_1 + ", " : ""}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_2) ? this.state.currentData.property.street_2 + ", " : ""}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.city) ? this.state.currentData.property.city.city_name + ", " : "-"}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.state) ? this.state.currentData.property.state.state_name + ", " : ""}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.country) ? this.state.currentData.property.country.country_name + "." : ""}
                            </p>
                          </div>
                          <div class="col-6 col-md-6">
                            <label>Get directions</label>
                            <p><a href=
                              {this.state.currentData
                                ? this.state.currentData.property
                                  ? this.state.currentData.property.google_map_link
                                  : "-"
                                : "-"} className="map_text" target="_blank">
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_1) ? this.state.currentData.property.street_1 + ", " : ""}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_2) ? this.state.currentData.property.street_2 + ", " : ""}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.city) ? this.state.currentData.property.city.city_name + ", " : ""}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.state) ? this.state.currentData.property.state.state_name + ", " : ""}
                              {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.country) ? this.state.currentData.property.country.country_name + "." : ""}
                            </a>
                            </p>
                          </div>
                          {this.state.currentData.parking_slot ? (
                            <div class="col-6 col-md-6">
                              <label>Parking space</label>
                              <p>
                                {this.state.currentData.parking_slot
                                  ? this.state.currentData.parking_slot
                                  : "-"}
                              </p>
                            </div>) : ""}
                        </div>
                        <div className="reserv_mid_heading">
                          <h5>Basic</h5>
                        </div>
                        <div class="row">
                          <div class="col-6 col-md-6">
                            <label>First name</label>
                            <p>
                              {this.state.currentData.guest_user
                                ? this.state.currentData.guest_user.first_name
                                : "-"}
                            </p>
                          </div>
                          {this.state.currentData.guest_user.last_name ? (
                            <div class="col-6 col-md-6">
                              <label>Last name</label>
                              <p>
                                {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.last_name
                                  : "-"}
                              </p>
                            </div>) : ""}
                          <div class="col-6 col-md-6">
                            <label>Unit</label>
                            <p>
                              {this.state.currentData.unit_name
                                ? this.state.currentData.unit_name
                                : this.state.currentData.unit_number}
                            </p>
                          </div>
                          <div class="col-6 col-md-6">
                            <label>Confirmation code</label>
                            <p>
                              {this.state.currentData.confirmation_code
                                ? this.state.currentData.confirmation_code
                                : "-"}
                            </p>
                          </div>
                          {this.state.currentData.created_at ? (
                            <div class="col-6 col-md-6">
                              <label>Booked</label>
                              <p>
                                {this.state.currentData.created_at
                                  ? Moment(this.state.currentData.created_at).format(
                                    global.dateFormat
                                  )
                                  : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.adults &&
                            this.state.currentData.adults !== null ? (
                            <div class="col-6 col-md-6">
                              <label>Adults</label>
                              <p>
                                {this.state.currentData.adults &&
                                  this.state.currentData.adults !== null
                                  ? this.state.currentData.adults
                                  : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.children &&
                            this.state.currentData.children !== null ? (
                            <div class="col-6 col-md-6">
                              <label>Children</label>
                              <p>
                                {this.state.currentData.children &&
                                  this.state.currentData.children !== null
                                  ? this.state.currentData.children
                                  : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.infants &&
                            this.state.currentData.infants !== null ? (
                            <div class="col-6 col-md-6">
                              <label>Infants</label>
                              <p>
                                {this.state.currentData.infants &&
                                  this.state.currentData.infants !== null
                                  ? this.state.currentData.infants
                                  : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.pets &&
                            this.state.currentData.pets !== null ? (
                            <div class="col-6 col-md-6">
                              <label>Pets</label>
                              <p>
                                {this.state.currentData.pets &&
                                  this.state.currentData.pets !== null
                                  ? this.state.currentData.pets
                                  : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.number_of_guest &&
                            this.state.currentData.number_of_guest !== null ? (
                            <div class="col-6 col-md-6">
                              <label>No. of guest</label>
                              <p>
                                {this.state.currentData.number_of_guest &&
                                  this.state.currentData.number_of_guest !== null
                                  ? this.state.currentData.number_of_guest
                                  : "-"}
                              </p>
                            </div>) : ""}
                          <div class="col-6 col-md-6">
                            <label>Status</label>
                            <p>
                              {this.state.currentData.status_text
                                ? this.state.currentData.status_text
                                : "Pending"}
                            </p>
                          </div>
                          <div class="col-6 col-md-6">
                            <label>Channel</label>
                            <p>
                              {this.state.currentData.set_channel === 1
                                ? "Website"
                                : "Airbnb"}
                            </p>
                          </div>
                          {this.state.currentData.guest_user && this.state.currentData.guest_user.phone_number !== "undefined" && this.state.currentData.guest_user.phone_number !== "" ? (
                            <div class="col-6 col-md-6">
                              <label>Phone</label>
                              <p>
                                {this.state.currentData.guest_user && this.state.currentData.guest_user.phone_number !== "undefined" && this.state.currentData.guest_user.phone_number !== "" ? (
                                  <PhoneNumberFormat
                                    phone_number={
                                      this.state.currentData.guest_user.phone_number
                                    }
                                  />
                                ) : (
                                  "-"
                                )}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.guest_user && this.state.currentData.guest_user.city && this.state.currentData.guest_user.city.city_name ? (
                            <div class="col-6 col-md-6">
                              <label>City</label>
                              <p>
                                {this.state.currentData.guest_user ?
                                  this.state.currentData.guest_user.city ? this.state.currentData.guest_user.city.city_name
                                    : "-" : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.guest_user && this.state.currentData.guest_user.state && this.state.currentData.guest_user.state.state_name ? (
                            <div class="col-6 col-md-6">
                              <label>State</label>
                              <p>
                                {this.state.currentData.guest_user ? this.state.currentData.guest_user.state
                                  ? this.state.currentData.guest_user.state.state_name
                                  : "-" : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.guest_user && this.state.currentData.guest_user.zip_code ? (
                            <div class="col-6 col-md-6">
                              <label>Zipcode</label>
                              <p>
                                {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.zip_code
                                  : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.guest_user.county ? (
                            <div class="col-6 col-md-6">
                              <label>County</label>
                              <p>
                                {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.county ? this.state.currentData.guest_user.county.county_name
                                    : "-" : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.guest_user.country ? (
                            <div class="col-6 col-md-6">
                              <label>Country</label>
                              <p>
                                {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.country ? this.state.currentData.guest_user.country.country_name
                                    : "-" : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.guest_user.passport ? (
                            <div class="col-6 col-md-6">
                              <label>Passport</label>
                              <p>
                                {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.passport
                                    ? this.state.currentData.guest_user.passport
                                    : "-"
                                  : "-"}
                              </p>
                            </div>) : ""}
                        </div>

                        <div className="reserv_mid_heading">
                          <h5>Guest paid</h5>
                        </div>

                        <div class="row">
                          <div class="col-6 col-md-6">
                            <label>{this.state.currentData.total_night_text}</label>
                          </div>
                          <div class="col-6 col-md-6">
                            <p>{`${this.state.currentData.total_nightly_rate
                              ? "$" + this.state.currentData.total_nightly_rate
                              : this.state.currentData.total_nightly_rate_disp
                              }`}</p>
                          </div>
                          {this.state.currentData.reservation_taxes &&
                            this.state.currentData.reservation_taxes.length > 1 &&
                            this.state.currentData.reservation_taxes.map(
                              (tax, taxid) => {
                                return (
                                  <>
                                    <div className="col-6 col-md-6">
                                      <label>{tax.tax_name}</label>
                                    </div>
                                    <div className="col-6 col-md-6">
                                      <p>
                                        {tax.tax_amount
                                          ? "$" + tax.tax_amount
                                          : "-"}
                                      </p>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          {this.state.currentData.total_fees !== "0.00" &&
                            this.state.currentData.total_fees > 0 ? (
                            <>
                              <div className="col-6 col-md-6">
                                <label>Service fees</label>
                              </div>
                              <div className="col-6 col-md-6">
                                <p>
                                  {this.state.currentData.total_fees
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_fees).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.currentData.cleaning_fee !== "0.00" &&
                            this.state.currentData.cleaning_fee > 0 ? (
                            <>
                              <div className="col-6 col-md-6">
                                <label>Cleaning fee</label>
                              </div>
                              <div className="col-6 col-md-6">
                                <p>
                                  {this.state.currentData.cleaning_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.currentData.total_taxes !== "0.00" &&
                            this.state.currentData.total_taxes > 0 ? (
                            <>
                              <div className="col-6 col-md-6">
                                <label>Occupancy taxes</label>
                              </div>
                              <div className="col-6 col-md-6">
                                <p>
                                  {this.state.currentData.total_taxes
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.currentData.convenience_fee !== "0.00" &&
                            this.state.currentData.convenience_fee > 0 ? (
                            <>
                              <div className="col-6 col-md-6">
                                <label>Service Fee</label>
                              </div>
                              <div className="col-6 col-md-6">
                                <p>
                                  {this.state.currentData.convenience_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.convenience_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div class="col-6 col-md-6">
                            <label>Total (USD)</label>
                          </div>
                          <div class="col-6 col-md-6">
                            <p>
                              <b>
                                {this.state.currentData.total_payment
                                  ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_payment).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                              </b>
                            </p>
                          </div>
                        </div>
                        {this.state.currentData
                          && this.state.currentData.property.guest_manual ? (
                          <div className="reserv_mid_heading">
                            <h5>Guest manual</h5>
                          </div>) : ""}

                        <div class="row mg-b-10">
                          <div className="thingsBlock">
                            <span>Guest manual</span>
                            <p>
                            <HTMLEllipsis
                              unsafeHTML={
                                this.state.currentData
                                  ? this.state.currentData.property.guest_manual
                                  : ""
                              }
                              maxLine="3"
                              ellipsis=""
                              trimright="true"
                              basedOn="letters"
                              className="span_text"
                            />
                            </p>
                            {this.state.currentData.property.guest_manual ? (
                              <button
                                className="btn btn-link ShowmoreLink"
                                onClick={() =>
                                  this.openGuestmanualModal(true)
                                }
                              >
                                Show more
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.currentData.wi_fi_name ? (
                            <div class="col-6 col-md-6">
                              <label>Wifi name</label>
                              <p>
                                {this.state.currentData.wi_fi_name
                                  ? this.state.currentData.wi_fi_name
                                  : "-"}
                              </p>
                            </div>) : ""}
                          {this.state.currentData.wi_fi_password ? (
                            <div class="col-6 col-md-6">
                              <label>Wifi password</label>
                              <p>
                                {this.state.currentData.wi_fi_password
                                  ? this.state.currentData.wi_fi_password
                                  : "-"}
                              </p>
                            </div>) : ""}

                        </div>
                        {(this.state.currentData.cancel_status === 0 && this.state.currentData.set_channel === 1 && this.state.currentData.status_text != "Failed" && (this.state.tab === 1 || this.state.tab === 2)) ?
                          <div className="reserv_mid_heading text-center">
                            <button
                              onClick={() =>
                                this.cancelModal(true, this.state.currentData)
                              }
                            >
                              Cancel reservation
                            </button>
                          </div>
                          : (
                            ""
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 reserve_desk">
                    <div className="reserv-img">
                      <figure>
                        <img
                          src={
                            this.state.currentData.file_name
                              ? this.state.currentData.file_name
                              : global.OnKeyPressEvent.getS3ImageUrl('bedImage.jpg')
                          }
                          alt={this.state.currentData.listing_name}
                          title={this.state.currentData.listing_name}
                        />
                      </figure>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div >
      </main >
    );
  }
}
export default Getreceipt;
