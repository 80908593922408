import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import CmsDataService from "../../services/CmsDataService";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Component } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import AuthenticationService from "../../services/AuthenticationService";
import SpinnerLoader from "../common/SpinnerLoader";
import TermsStatic from "../../template/pages/TermsStatic";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      email: props.email ? props.email : localStorage.getItem('sign_in_email') ? localStorage.getItem('sign_in_email') : "",
      isterms: false,
      password: "",
      emailError: "",
      passwordError: "",
      loginError: "",
      forgetPasswordError: "",
      ForgetPassword: false,
      signupModel: false,
      redirectTo: this.props.redirectTo ? this.props.redirectTo : "/",
      property_slug: this.props.propertySlug ? this.props.propertySlug : global.property_slug,
      loader: false,
      resetPasswordpath: window.location.origin + "/reset-password/",
      termsPolicy: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.signupModel = this.signupModel.bind(this);
  }

  componentDidMount() {
    // console.log("login", this.state);
    // console.log("props", this.props);
  }

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      var emailError = "Please enter valid email.";
      this.setState({ emailError: emailError });
    } else {
      var emailError = "";
      this.setState({ emailError: emailError });
    }
    console.log("validate");
  }

  ForgetPassword = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    if (
      this.state.email !== "" &&
      this.state.emailError === "" ||
      global.OnKeyPressEvent.validateEmail(this.state.email)

    ) { // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        email: this.state.email,
        fallback_url: this.state.resetPasswordpath
      }
      let res = await AuthenticationService.forgotPass(input);
      if ((global.successStatus).includes(res.status)) {
        // localStorage.clear();
        // setTimeout(() => this.props.history.push('/signin'), global.redirect_time);
        this.setState({ forgetPasswordSuccess: "We have e-mailed your password reset link!" });
        setTimeout(() => this.managerFogetPassword(), global.redirect_time);
      } else {
        this.setState({ forgetPasswordError: (res.data.message) ? res.data.message : '' })
        setTimeout(() => this.managerFogetPassword(), global.redirect_time);
      }
    } else {
      // if (!this.state.email) {
      this.setState({ emailError: "Please enter valid email." });
      // }
    }
    this.setState({ loader: false });
  }

  signupModel = (value) => {
    this.setState({ signupModel: value });
    this.setState({ loginModel: false });
  }

  managerFogetPassword = () => {
    this.setState({ forgetPasswordSuccess: "", forgetPasswordError: "", email: "", emailError: "", loginError: "" });
    this.setState({ ForgetPassword: false })
  }

  loginUser = async (e) => {
    e.preventDefault();

    if (
      this.state.email !== "" &&
      this.state.password !== "" &&
      this.state.emailError === "" &&
      this.state.passwordError === ""
    ) {
      this.setState({ loader: true });
      let input = {
        email: this.state.email,
        password: this.state.password,
      };
      let res = await AuthenticationService.signin(input);
      if (global.successStatus.includes(res.status)) {
        //termsPolicy = res.data?.terms_submited ? res.data?.terms_submited : 0; 

        localStorage.removeItem('sign_in_email');
        localStorage.setItem(
          "headerToken",
          res.data.headerToken ? res.data.headerToken : ""
        );
        global.headerToken = res.data.headerToken ? res.data.headerToken : "";
        // localStorage.setItem('isLogin', 1);
        localStorage.setItem("isPopupShow", 1);
        localStorage.setItem(
          "userData",
          res.data.user_data ? JSON.stringify(res.data.user_data) : {}
        );
        localStorage.setItem(
          "permissions",
          res.data.permissions ? JSON.stringify(res.data.permissions) : {}
        );
        localStorage.setItem("user_type", res.data.user_type);
        global.currerntRoleSlug = res.data.currerntRoleSlug ? res.data.currerntRoleSlug : ""
        localStorage.setItem('currerntRoleSlug', res.data.currerntRoleSlug);

        //let termsPolicy = 1;
        let termsPolicy = res.data?.user_data?.force_accept_terms_condition;

        /**show popopup **/
        if (termsPolicy == 1) {
          this.setState({
            isterms: true,
            termsPolicy: termsPolicy,
            user_slug: res.data?.user_data?.slug,
          });
          this.manageLogin(false);
          this.getcmsCommonData();
          return false;
        }
        else{
          if (this.props.isPlaceReservation) {
            this.props.placeReservation();
          } else {
              window.location.href = this.state.redirectTo;
          }
        }
        /**show popopup **/
      } else {
        this.setState({
          loginError: "Either email or password is incorrect.",
          // loginError: res.data.message ? res.data.message : "Error",
        });
      }
    } else {
      if (!this.state.email) {
        this.setState({ emailError: "Please enter valid email." });
      }
      if (!this.state.password) {
        this.setState({ passwordError: "Please enter valid password." });
      }
    }
    this.setState({ loader: false });
  };

  changeHandler = (event) => {
    let value = event.target.value;

    if (event.target.name === "email") {
      if (event.target.value.length > 8) {
        this.setState({ emailError: "" });
      }
    }

    if (event.target.name === "password") {
      if (event.target.value.length > 4) {
        this.setState({ passwordError: "" });
      }
    }
    // if (event.target.name === "email") {
    //   if (event.target.value.length > 7) {
    //     const regex =
    //       /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //     if (regex.test(event.target.value) === false) {
    //       var emailError = "Please enter valid email.";
    //       this.setState({ emailError: emailError });
    //     } else {
    //       var emailError = "";
    //       this.setState({ emailError: emailError });
    //     }
    //   }
    // }
    this.setState({ [event.target.name]: value });
  };

  getcmsCommonData = async (queryString = "") => {
    var resData = {};
    var resDataArr = [];
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    var cacheKeyName = global.level + "_TERMS_AND_CONDITIONS_" + levelSlug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let res = await CmsDataService.getCmsData("TERMS_AND_CONDITIONS", 1, 1);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false
    });
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
  }

  manageLogin = (value, signup) => {
    this.setState({
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      loginError: "",
      forgetPasswordError: "",
      ForgetPassword: false,
      loader: false
    });
    this.props.manageLogin(false, signup);
  }

  saveTermscondition = async (e) => {
    this.setState({ loader: true });
    var user_slug = this.state.user_slug ? this.state.user_slug : "";
    let input = {
      is_term_condition: 1,
      slug: user_slug
    };
    e.preventDefault();
    let res = await AuthenticationService.UpdateTerms(input);
    if (global.successStatus.includes(res.status)) {
      // this.setState({isterms:false});
      // this.loginUser();
      if (this.props.isPlaceReservation) {
        this.props.placeReservation();
      } else {
        window.location.href = this.state.redirectTo;
      }

    } else {
      this.setState({ isterms: true });
    }
    this.setState({ loader: false });
  }

  render() {
    // console.log("email",this.state.email)
    return (
      <>
        <div>
          <Modal show={this.props.isLogin} centered className="reservationBook model_bg_cust">
            <span
              className="welcomeClose float-end signmodalbox"
              onClick={() => this.manageLogin(false)}
            >
              {global.closee}
            </span>
            <ModalBody className="width_402 border_radius_14">


              {this.state.ForgetPassword ? (
                <div className="text-center">
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                      sx={{
                        // marginTop: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('logo-footer.png')} alt="Logo" title="Logo" />
                      </figure>
                      <div className="w-100">
                        <Typography component="h1" variant="h3" >
                          Forgot password?
                        </Typography>
                        <Typography component="h5" variant="h5">
                          Enter your email below and we'll send you an email to get this sorted out.
                        </Typography>
                      </div>

                      <div className="w-100">
                        <Box
                          // component="form"
                          // onSubmit={this.loginUser}
                          // noValidate
                          sx={{ mt: 1 }}
                        >
                          {this.state.forgetPasswordError && (
                            <>
                              <p className="text-danger text-center m-auto font-14-cust">
                                {" "}
                                {this.state.forgetPasswordError}
                              </p>
                            </>
                          )}
                          {this.state.forgetPasswordSuccess && (
                            <>
                              <p className="text-success text-center m-auto font-14-cust">
                                {" "}
                                {this.state.forgetPasswordSuccess}
                              </p>
                            </>
                          )}
                          <div className="countryphone">
                            <TextField
                              margin="normal"
                              className="textfield"
                              required
                              fullWidth
                              id="email"
                              label="Email"
                              name="email"
                              //onBlur={() => this.validateEmail()}
                              onChange={(e) => this.changeHandler(e)}
                              error={this.state.emailError ? true : false}
                              value={this.state.email}
                              // autoComplete="email"
                              helperText={
                                this.state.emailError ? this.state.emailError : ""
                              }
                            />
                          </div>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={(e) => this.ForgetPassword(e)}
                            disabled={this.state.loader ? true : false}
                          >
                            Submit {this.state.loader ?
                              <>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                  className="spinner_loader"
                                />
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                  className="spinner_loader"
                                />
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  role="status"
                                  aria-hidden="true"
                                  className="spinner_loader"
                                />
                              </>
                              : ""
                            }
                          </Button>
                          <Grid container className="forget_text">
                            <Grid item s>
                              <Link
                                href="#"
                                onClick={() =>
                                  this.setState({ ForgetPassword: false })
                                }
                                variant="body2"
                              >
                                Sign in?
                              </Link>
                            </Grid>
                            {/* <Grid item>
                          <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid> */}
                          </Grid>
                        </Box>
                      </div>
                    </Box>
                  </Container>
                </div>
              ) : (
                <div className="text-center">
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                      sx={{
                        // marginTop: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('logo-footer.png')} alt="Logo" title="Logo" />
                      </figure>
                      <Typography component="h1" variant="h5">
                        Please sign in to continue.
                      </Typography>
                      <Box
                        // component="form"
                        // onSubmit={this.loginUser}
                        // noValidate
                        sx={{ mt: 1 }}
                      >
                        {this.state.loginError && (
                          <>
                            <p className="text-danger text-center m-auto font-14-cust">
                              {" "}
                              {this.state.loginError}
                            </p>
                          </>
                        )}
                        <div className="row">
                          <div className="countryphone">
                            <TextField
                              margin="normal"
                              className=" textfield"
                              required
                              fullWidth
                              id="email"
                              label="Email"
                              name="email"
                              onBlur={() => this.validateEmail()}
                              onChange={(e) => this.changeHandler(e)}
                              error={this.state.emailError ? true : false}
                              value={this.state.email}
                              autoComplete="off"
                              helperText={
                                this.state.emailError ? this.state.emailError : ""
                              }
                              autoFocus={false}
                            />
                          </div>
                          <div className="countryphone">
                            <TextField
                              margin="normal"
                              className="textfield"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              onChange={this.changeHandler}
                              error={this.state.passwordError ? true : false}
                              value={this.state.password}
                              helperText={
                                this.state.passwordError
                                  ? this.state.passwordError
                                  : ""
                              }
                            // autoComplete="current-password"
                            />
                          </div>
                        </div>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={this.loginUser}
                          disabled={this.state.loader ? true : false}
                        >
                          Let's go {this.state.loader ?
                            <>
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                              <Spinner
                                as="span"
                                animation="grow"
                                role="status"
                                aria-hidden="true"
                                className="spinner_loader"
                              />
                            </>
                            : ""
                          }
                        </Button>
                        <Grid container className="forget_text">
                          <div className="signmodalfootersect">
                            <Grid item>
                              <Link
                                href="#"
                                onClick={() =>
                                  this.setState({ ForgetPassword: true })
                                }
                                variant="body2"
                                className="textColornormal"
                              >
                                Forgot password?
                              </Link>
                            </Grid>
                            <Grid item>
                              <Link
                                href="#"
                                onClick={() => this.manageLogin(false, true)}
                                variant="body2"
                                className="textColorbold"
                              >

                                {/* <Link href="javascript:void(0)" variant="body2" style={{ cursor: "pointer" }} className={this.state.signupModel ? "active" : ""} onClick={() => this.signupModel(true)}> */}
                                {'Sign up'}
                              </Link>

                              {/* <li><a href="javascript:void(0)" style={{ cursor: "pointer" }} className={this.state.signupModel ? "active" : ""} onClick={() => this.signupModel(true)}>{this.state.section_header_data && this.state.section_header_data[4] && this.state.section_header_data[4].text ? this.state.section_header_data[4].text : 'Sign up'}</a></li> */}
                            </Grid>
                          </div>
                          {/* <Grid item>
                            <Link href="#" variant="body2">
                              {"Don't have an account? Sign Up"}
                            </Link>
                          </Grid> */}
                        </Grid>
                      </Box>
                    </Box>
                  </Container>
                </div>
              )}
            </ModalBody>
          </Modal>

          {/* Terms & Condition Pop Up */}
          <Modal show={this.state.isterms}
            centered
            scrollable={true}
            className="unitmodal large_model p-0"
          >

            <ModalBody className="width_402 p-4 border_radius_14 pt-0">
              <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile model_top_fix">
                <span
                  className="mobilewelcomeClose float-end"
                  onClick={() => this.manageterms(false)}
                >
                  <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Getting around" title="Getting around" />
                </span>
                <div className="title">Privacy policy</div>
                {/* <span
                            className="welcomeClose float-end"
                            onClick={() => this.manageterms(false)}
                          >
                            {global.closee}
                          </span>  */}
              </div>

              <div className="">
                {this.state.termsPolicy == 1 ? (
                  <>
                    <div className="newcontentterms newcontentPrivacy">
                      {
                        (this.state.TERMS_AND_CONDITIONS_SECTION_1 && this.state.TERMS_AND_CONDITIONS_SECTION_1[1])
                          ?
                          <div
                            dangerouslySetInnerHTML={{ __html: this.state.TERMS_AND_CONDITIONS_SECTION_1[1].text }}
                          />
                          :
                          <TermsStatic />
                      }
                    </div>
                  </>) : ""}


                <button className="btn-agree" onClick={(e) => this.saveTermscondition(e)}>
                  {this.state.loader ?
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                        className="spinner_loader"
                      />
                      <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                        className="spinner_loader"
                      />
                      <Spinner
                        as="span"
                        animation="grow"
                        role="status"
                        aria-hidden="true"
                        className="spinner_loader"
                      />
                    </>
                    : "I agree"
                  }
                </button>

                {/* <input type="button" value="I agree" className="btn-agree" onClick={(e) => this.saveTermscondition(e)} /> */}
              </div>
            </ModalBody>
          </Modal>
          {/* Terms & Condition Pop Up */}
        </div>
      </>
    );


  }
}

export default Login;
