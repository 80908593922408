import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import TextareaAutosize from "react-autosize-textarea";
import SpinnerLoader from "./SpinnerLoader";
import ApplicantsService from "../../services/ApplicantsService";
import moment from "moment";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from "react-bootstrap";
const recaptchaRef = React.createRef();
class LiveChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      first_name: "",
      notes: "",
      email: "",
      loader: false,
      successMessage: false,
      openChatboxModal: false,
      errors: {
        first_name: "",
        email: "",
      },
      input: {
        first_name: "",
        email: "",
        phone_number: "",
        notes: ""
      },
      errorCount: 0,
      time1: moment(new Date()).format("hh:mmA"),
      time2: "",
      showDropdown: false
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerPhone = this.changeHandlerPhone.bind(this);
    this.saveOrUpdateApplicants = this.saveOrUpdateApplicants.bind(this);
    this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);
  }

  onChangeRecaptcha = (value) => {
    if (value !== "") {
      var errors = this.state.errors;
      errors.recaptcha = "";
      this.setState({ isValidRecatcha: true, errors: errors });
    }
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    if (event.target.name === "first_name") {
      if (event.target.value.length >= 2) {
        const regex = /^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      }
    }
    if (event.target.name === "phone_number") {
      if (event.target.value.length > 8) {
        var errors = this.state.errors;
        errors.phone_number = "";
        this.setState({ errors: errors });
      }
      if (event.target.value.length > 15 || event.target.value.length < 8) {
        var errors = this.state.errors;
        errors.phone_number = "The phone number must be a valid phone number";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "email") {
      if (event.target.value.length > 7) {
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.email = "Please enter email.";
          this.setState({ errors: errors });
        } else {
          errors.email = "";
          this.setState({ errors: errors });
        }
      }
    }
    this.setState({ [event.target.name]: value });
  };

  changeHandlerPhone = (phoneNumber) => {
    this.setState({ phone_number: phoneNumber });
    if (phoneNumber.length > 8) {
      var errors = this.state.errors;
      errors.phone_number = "";
      this.setState({ errors: errors });
    }
    if (phoneNumber.length > 15 || phoneNumber.length < 8) {
      var errors = this.state.errors;
      errors.phone_number = "The phone number must be a valid phone number";
      this.setState({ errors: errors });
    }
  };




  onErrorRecaptcha = (value) => {
    // var errors = this.state.errors;
    // errors.recaptcha = "Verification expired. Check the checkbox again."
    this.setState({ isValidRecatcha: false });
    recaptchaRef.current.reset();
  };

  scrollTop = () => {
    const section = document.querySelector('#successMessage');
    section.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
  }

  saveOrUpdateApplicants = async (e) => {
    e.preventDefault();
    var captcha = true;
    if (this.state.errorCount >= 3) {
      const recaptchaValue = recaptchaRef.current.getValue();
      captcha = this.state.isValidRecatcha ? true : false;
    }
    if (
      captcha &&
      this.state.errors.email === "" &&
      this.state.email !== "" &&
      this.state.errors.first_name === "" &&
      this.state.first_name !== ""
    ) {
      var origin = window.location.origin ? window.location.origin :"";
      var pathname = window.location.pathname ? window.location.pathname:"";
      var finalPath = origin + pathname; 
      this.setState({ loader: true });
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        property_slug: this.state.property_slug,
        page_from: finalPath,
        email: this.state.email,
        phone_number: this.state.phone_number,
        notes: this.state.notes,
      };
      this.setState({ input: inputData });
      let res = await ApplicantsService.createContact(inputData);
      if (global.successStatus.includes(res.status)) {
        await this.setState({
          time2: moment(new Date()).format("hh:mmA"),
          successMessage: true,
          loader: false,
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          notes: "",
          status_id: true,
          // showDropdown: false,
        });
        //  document.getElementById("successMessage").focus();
        if (this.state.errorCount >= 3) {
          recaptchaRef.current.reset();
        }
        this.scrollTop();
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          await this.setState({ errors: errors, loader: false });
        }
      }
    } else {
      //show required errors
      var errors = this.state.errors;
      var errorCount = this.state.errorCount;
      // if (!this.state.phone_number) {
      //   // document.getElementById("phone_number").focus();
      //   errors.phone_number = "Phone number is required.";
      // }
      if (!this.state.email) {
        document.getElementById("email").focus();
        errors.email = "Please enter email.";
      }
      if (!this.state.first_name) {
        document.getElementById("first_name").focus();
        errors.first_name = "Please enter name.";
      }
      await this.setState({ errors: errors, errorCount: errorCount + 1, loader: false });
    }
  };

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      let errors = this.state.errors;
      errors.email = "Please enter email.";
      this.setState({ errors: errors });
    } else {
      let errors = this.state.errors;
      errors.email = "";
      this.setState({ errors: errors });
    }
  };

  closeDropdown = (msg) => {
    this.setState({ showDropdown: msg })
    if (msg) {
      document.body.className = "removeScroll";
    } else {
      document.body.className = "";
    }
  }

  openChatboxModal = (val = false) => {
    this.setState({ openChatboxModal: val });
    if (val) {
      document.body.className = "removeScroll";
    } else {
      document.body.className = "";
    }
  };

  onFoucsFirstName = () => {
    const section = document.querySelector( '#first_name');
    section.scrollIntoView( { behavior: 'auto', block: 'start' } );
  }

  render() {
    return (
      <>
        {" "}
        {/* New chat section d-none*/}
        <section className="chatSection">
          {/* <button
            className="btn btn-chat"
            onClick={() =>
              this.openChatboxModal(true)
            }
          >
          <img src={global.OnKeyPressEvent.getS3ImageUrl('chat-box.png')} />
          </button> */}



          <Dropdown drop={"up"} show={this.state.showDropdown} onToggle={(e) => this.closeDropdown(e)}>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu className="tx-13 chat-box-toggle">
              <div className="chat-header">
                <span>Concierge</span>
                <span className="chat_close" onClick={() => this.closeDropdown(false)}><img src={global.OnKeyPressEvent.getS3ImageUrl('close-chat.png')} alt="" /></span>
              </div>
              <div className="chat-body">
                <div className="recievermsg d-flex justify-content-start mg-r-50">
                  <div className="d-block">
                    <p>
                      Hey there. I'm your automated concierge bot. I'm going to
                      pass your message to our actual human concierge team. Just
                      gimme your deets, and we'll back to you right away!{" "}
                    </p>
                    <span>{moment(new Date()).format("hh:mmA")}</span>
                  </div>
                </div>
                {/* <div className="sendermsg d-flex justify-content-end mg-l-50 d-none">
                  <div className="d-block">
                    <p>Your Details:<br/>Name:Prakash <br/> Email: p@e2logy.com <br/> message:hello </p>
                    <span>{moment(new Date()).format("hh:mmA")}</span>
                  </div>
                </div> */}
                <div className={this.state.successMessage ? "d-none" : "chatformSection requestSection reservationBook  row"}>
                  <h4>What can I do for you?</h4>
                  <div className="w-100 mg-b-10 p-0">
                    <div className="countryphone">
                      <TextField
                        id="first_name"
                        name="first_name"
                        value={this.state.first_name}
                        onFocus={() => this.onFoucsFirstName()}
                        onChange={this.changeHandler}
                        label="Name"
                        variant="outlined"
                        fullWidth
                        required
                        error={this.state.errors.first_name ? true : false}
                        helperText={
                          this.state.errors.first_name
                            ? this.state.errors.first_name
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="w-100 mg-b-10 p-0">
                    <div className="countryphone">
                      <TextField
                        type="email"
                        name="email"
                        value={this.state.email}
                        onBlur={this.validateEmail}
                        onChange={this.changeHandler}
                        id="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                        className=" textfield"
                        error={this.state.errors.email ? true : false}
                        helperText={
                          this.state.errors.email ? this.state.errors.email : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="w-100 mg-b-10 p-0">
                    <PhoneInput
                      className=" textfield phoneInput"
                      country={global.country}
                      onKeyPress={global.OnKeyPressEvent.phoneValidation}
                      value={this.state.phone_number}
                      variant="outlined"
                      autoFormat="true"
                      enableSearch="true"
                      countryCodeEditable="true"
                      id="phone_number"
                      name="phone_number"
                      placeholder="Phone number"
                      onChange={(e) => this.changeHandlerPhone(e)}
                      fullWidth
                    />
                  </div>
                  {/* {this.state.errors.phone_number ? (
                    <p className="error-message">
                      {this.state.errors.phone_number}
                    </p>
                  ) : (
                    ""
                  )} */}
                  <div className="w-100 mg-b-10 p-0">
                    <div className="countryphone text_filled_up">
                      <TextField
                        id="Comment"
                        label="Message"
                        multiline
                        rows={2}
                        variant="outlined"
                        fullWidth
                        name="notes"
                        value={this.state.notes}
                        onChange={this.changeHandler}
                        className=" textfield"
                      />
                    </div>
                  </div>
                  {this.state.errorCount >= 3 && (
                    <div
                      id="recaptch-block"
                      className="w-100 mg-b-10 p-0"
                    >
                      <ReCAPTCHA
                        // theme="dark" // light
                        // onErrored:""
                        // badge="bottomright" //bottomleft inline
                        // size="normal" //"compact"
                        ref={recaptchaRef}
                        onChange={this.onChangeRecaptcha}
                        onExpired={() => this.onErrorRecaptcha}
                        onErrored={() => this.onErrorRecaptcha}
                        sitekey={"6Lezqe8jAAAAAJXeZgVxsekhhHeTC6B8V9sbe18u"}
                      />
                      {this.state.errors.recaptcha !== "" ? (
                        <p className="error-message">
                          {this.state.errors.recaptcha}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <button
                    className="w-100"
                    onClick={this.saveOrUpdateApplicants}
                    disabled={this.state.loader ? true : false}
                  >
                    Send
                    {this.state.loader ? (
                      <>
                        <SpinnerLoader />
                      </>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
                {this.state.successMessage && (
                  <>
                    <div className="sendermsg d-flex justify-content-end mg-l-50">
                      <div className="d-block">
                      <p>Your Details<br /><br />Name: {this.state.input.first_name} <br /> Email: {this.state.input.email}<br /> Phone:   
                      <span className="phone-white">
                      <PhoneNumberFormat
                                      phone_number={
                                        this.state.input.phone_number
                                      }
                                    /></span> <br /> Message: {this.state.input.notes.length > 25 ? this.state.input.notes.substring(0, 50) + "..." : this.state.input.notes} </p>
                        <span>{moment(new Date()).format("hh:mmA")}</span>
                      </div>
                    </div>
                    <div className="recievermsg d-flex justify-content-start mg-r-50" id="successMessage">
                      <div className="d-block">
                        <p>
                          Thanks! I sent over your message and someone will get
                          back to you super soon. Promise!{" "}
                        </p>
                        <span>{moment(new Date()).format("hh:mmA")}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="chat-footer d-none">
                <TextareaAutosize
                  onResize={(e) => { }}
                  placeholder="Type your message..."
                  disabled={true}
                />
                <button>
                  <img src={global.OnKeyPressEvent.getS3ImageUrl('postarrow.svg')} />

                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Modal
            show={this.state.openChatboxModal}
            className="chat-box-pop"
            onHide={() => this.openChatboxModal()}
            backdropClassName="bg_chat_box"

          >
            <Modal.Body className="chatSection border_radius_14">
              <div className="dropdown-menu-wala ">
                <div className="chat-header">
                  <span>Concierge</span>

                  <span className="chat_close" onClick={() => this.openChatboxModal(false)}><img src={global.OnKeyPressEvent.getS3ImageUrl('close-chat.png')} alt="" /></span>

                </div>
                <div className="chat-body">
                  <div className="recievermsg d-flex justify-content-start mg-r-50">
                    <div className="d-block">
                      <p>
                        Hey there. I'm your automated concierge bot. I'm going to
                        pass your message to our actual human concierge team. Just
                        gimme your deets, and we'll back to you right away!{" "}
                      </p>
                      <span>{moment(new Date()).format("hh:mmA")}</span>
                    </div>
                  </div>
                  <div className={this.state.successMessage ? "d-none" : "chatformSection requestSection reservationBook row"}>
                    <h4>What can I do for you?</h4>
                    <div className="w-100 mg-b-10 p-0">
                      <div className="countryphone">
                        <TextField
                          id="first_name"
                          name="first_name"
                          value={this.state.first_name}
                          onChange={this.changeHandler}
                          label="Name"
                          variant="outlined"
                          fullWidth
                          required
                          error={this.state.errors.first_name ? true : false}
                          helperText={
                            this.state.errors.first_name
                              ? this.state.errors.first_name
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="w-100 mg-b-10 p-0">
                      <div className="countryphone">
                        <TextField
                          type="email"
                          name="email"
                          value={this.state.email}
                          onBlur={this.validateEmail}
                          onChange={this.changeHandler}
                          id="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          required
                          className="borderInput textfield"
                          error={this.state.errors.email ? true : false}
                          helperText={
                            this.state.errors.email ? this.state.errors.email : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="w-100 mg-b-10 p-0">
                      <PhoneInput
                        className="borderInput textfield phoneInput"
                        country={global.country}
                        onKeyPress={global.OnKeyPressEvent.phoneValidation}
                        value={this.state.phone_number}
                        variant="outlined"
                        autoFormat="true"
                        enableSearch="true"
                        countryCodeEditable="true"
                        id="phone_number"
                        name="phone_number"
                        placeholder="Phone number"
                        onChange={(e) => this.changeHandlerPhone(e)}
                        fullWidth
                      />
                    </div>

                    <div className="w-100 mg-b-10 p-0">
                      <div className="countryphone">
                        <TextField
                          id="Comment"
                          label="Message"
                          multiline
                          rows={2}
                          variant="outlined"
                          fullWidth
                          name="notes"
                          value={this.state.notes}
                          onChange={this.changeHandler}
                          className="borderInput textfield"
                        />
                      </div>
                    </div>
                    {this.state.errorCount >= 3 && (
                      <div
                        id="recaptch-block"
                        className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-10"
                      >
                        <ReCAPTCHA

                          ref={recaptchaRef}
                          onChange={this.onChangeRecaptcha}
                          onExpired={() => this.onErrorRecaptcha}
                          onErrored={() => this.onErrorRecaptcha}
                          sitekey={process.env.REACT_APP_SITE_KEY}
                        />
                        {this.state.errors.recaptcha !== "" ? (
                          <p className="error-message">
                            {this.state.errors.recaptcha}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <button
                      className="w-100"
                      onClick={this.saveOrUpdateApplicants}
                      disabled={this.state.loader ? true : false}
                    >
                      Send &nbsp;
                      {this.state.loader ? (
                        <>
                          {" "}
                          <SpinnerLoader />{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                  {this.state.successMessage && (
                    <>
                      <div className="sendermsg d-flex justify-content-end mg-l-50">
                        <div className="d-block">
                          <p>Your Details<br /><br />Name: {this.state.input.first_name} <br /> Email: {this.state.input.email}<br /> Phone: {this.state.input.phone_number} <br /> message: {this.state.input.notes ? this.state.input.notes.substring(0, 50) + "..." : ""} </p>
                          <span>{moment(new Date()).format("hh:mmA")}</span>
                        </div>
                      </div>
                      <div className="recievermsg d-flex justify-content-start mg-r-50">
                        <div className="d-block">
                          <p id="successMessage">
                            Thanks! I sent over your message and someone will get
                            back to you super soon. Promise!{" "}
                          </p>
                          <span>{moment(new Date()).format("hh:mmA")}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="chat-footer d-none">
                  <TextareaAutosize
                    onResize={(e) => { }}
                    placeholder="Type your message..."
                    disabled={true}
                  />
                  <button>
                    <img src={global.OnKeyPressEvent.getS3ImageUrl('postarrow.svg')} />
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal> */}




        </section>
        {/* End New chat section */}{" "}
      </>
    );
  }
}

export default LiveChat;
