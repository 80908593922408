// import { Helmet } from "react-helmet-async";
import 'react-phone-input-2/lib/style.css';
import { HelmetProvider } from 'react-helmet-async';
import "./App.css"
import "./App.scss"
// import AppRoutes from './AppRoutes'
import Routes from './Routes'
import LocalStorageManage from "./config/LocalStorageManage";
import OnKeyPressEvent from './config/OnKeyPressEvent'
import { BrowserRouter } from "react-router-dom";
// import Css from "./Css";
// import MetaSEO from './MetaSEO';
global.successStatus = [200,201,202];
global.host = window.location.host;
global.userPermissions = new OnKeyPressEvent();
global.OnKeyPressEvent = new OnKeyPressEvent();
global.closee = <img alt="close" title="Close Icon" className="pointer" src={global.OnKeyPressEvent.getS3ImageUrl('close-wel.svg')} />;
global.LocalStorageManage = new LocalStorageManage();
global.url = window.location.origin;
global.oh = process.env.REACT_APP_BRAND_URL.includes(global.host) ? false : true;
global.theme = "kini"; //global.oh ? "oh" : "kini";
global.propertyName = process.env.REACT_APP_BRAND_URL.includes(global.host) ? 'Kini Beach Haus'  : ( process.env.REACT_APP_PROPERTY_URL.includes(global.host) ? "Kini Beach Haus / Las Olas" :  " OHAI");
global.level = process.env.REACT_APP_BRAND_URL.includes(global.host) ? 2  : ( process.env.REACT_APP_CORPORATE_URL.includes(global.host) ? 1 :  3);
global.brandSlug =  process.env.REACT_APP_BRAND_URL.includes(global.host) ? global.OnKeyPressEvent.getBrandSlug() : "";
global.property_slug = process.env.REACT_APP_PROPERTY_URL.includes(global.host) ? global.OnKeyPressEvent.getPropertySlug() : ""
global.country = "us";
//  global.themeUrl = "/" + global.theme + "/" + global.theme + "/";
// global.themeUrl = "/" + global.theme + "/";
global.themeUrl = "/";
global.countdown_time = parseFloat(2.5);
global.redirect_time = 5000;
global.dateFormat = "MM/DD/YY";
global.dateTimeFormat = "MM/DD/YY | hh:mm A";
global.hostName = window.location.hostname;
global.isMobile = window.innerWidth <= 767;
// global.keywords = "Airbnb Friendly Apartment Buildings,Homesharing,Short Term Rental";
 global.description = "Homesharing-friendly apartments. Earn money when you're away. Flexible, furnished, and unfurnished apartments. ";
 global.unsubscribeReasonList = [
  { label: "I no longer want to receive these emails", value: "I no longer want to receive these emails" },
  { label: "I never signed up for this mailing list", value: "I never signed up for this mailing list" },
  { label: "The emails are inappropriate", value: "The emails are inappropriate" },
  { label: "The emails are spam and should be reported", value: "The emails are spam and should be reported" },
  { label: "Other (fill in reason below)", value: "Other" },
];

function App() {
  return (
    <>
    <HelmetProvider>
    <div className="App">
      {/* <Css /> */}
      {/* <MetaSEO /> */}
      <BrowserRouter basename="">
      {/* <AppRoutes /> */}
      <Routes />
      </BrowserRouter>
    </div>
    </HelmetProvider>
    </>
  );
}

export default App;
