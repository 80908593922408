//     							                                      //
//  Program: KINIReservationList.jsx                      //
//  Application: KINI HTML                                //
//  Option: Reservation List                              //
//  Developer: PS		                                      //
//  Date: 2022-08-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Moment from "moment";
import ReservationService from "../../services/ReservationService";
import TripsTable from "../common/TripsTable";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class ReservationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      reservationModal: false,
      userDetails: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : "",
      reservationList: [],
      currentData: {},
      loader: false,
    };
    this.getReservationData = this.getReservationData.bind(this);
  }
  componentDidMount() {
    localStorage.setItem("tab", 1);
    this.getReservationData();
  }

  reservationModal(value, item = []) {
    this.setState({ reservationModal: value });
    this.setState({ currentData: item });
  }

  statusText = async (record) => {
    const currentDate = new Date();
    var dateFrom = Moment(record.checkin_date).format("MM/DD/YYYY");
    var dateTo = Moment(record.checkout_date).format("MM/DD/YYYY");
    var dateCheck = Moment(currentDate).format("MM/DD/YYYY");
    var d1 = dateFrom.split("/");
    var d2 = dateTo.split("/");
    var c = dateCheck.split("/");
    if (record.status_id === 1 && c >= d1 && c <= d2) {
      return "Currently Hosting";
    } else if (record.status_id !== 2 && c > d2) {
      return "Completed";
    } else {
      return record.payment_status === 2 ? "Confirmed" : record.status_id === 1 ? "Payment Pending" : "Failed";
    }
  }

  getReservationData = async (queryString = "", filter_by = 1) => {
    var list = [];
    var totalRecords = 0;
    this.setState({ loader: true });
    // var todayDate = Moment(new Date()).format("YYYY-MM-DD");
    // var currentDateFilter = "checkin_date=" +  todayDate + "&is_custom=1";
    var data = {
      property_seo_slug: this.state.property_slug,
      filter_by: filter_by,
      user_slug: this.state.userDetails.slug ? this.state.userDetails.slug : "",
    };
    let res = await ReservationService.getReservationList(data);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      this.setState({ loader: false });
      // totalRecords = list ? list.length : 0;
      totalRecords = res.data ? res.data.total : 0;
    }
    let statusText = "pending";

    await list.map((record, index) => {
      const currentDate = new Date();
      var dateFrom = Moment(record.checkin_date).format("YYYY/MM/DD");
      var dateTo = Moment(record.checkout_date).format("YYYY/MM/DD");
      var dateCheck = Moment(currentDate).format("YYYY/MM/DD");
      var d1 = dateFrom.split("/");
      var d2 = dateTo.split("/");
      var c = dateCheck.split("/");
      if (record.status_id === 1 && dateCheck >= dateFrom && dateCheck <= dateTo) {
        statusText = "Confirmed";
        } else if (record.set_channel === 1 && record.payment_status === 2 && dateTo < dateCheck) {
          statusText = record.cancel_status === 0 && record.payment_status === 2 ? "Completed" : record.cancel_status === 0 && record.status_id === 1 ? "Payment Pending" : record.cancel_status === 1 ? "Cancelled" : "Failed";
        } else if (record.status_id !== 2 && record.set_channel !== 1  && dateTo < dateCheck) {
          statusText = "Completed";
        } else if(record.set_channel === 1 && dateCheck < dateTo) {  
      statusText = record.cancel_status === 0 && record.payment_status === 2 ? "Confirmed" : record.cancel_status === 0 && record.status_id === 1 ? "Payment Pending" : record.cancel_status === 1 ? "Cancelled" : "Failed";
        } else if (record.set_channel !== 1 && dateCheck < dateTo) {
          statusText = record.status_id === 1 ? "Confirmed" : record.status_id === 2  ? "Cancelled" : record.status_id === 3 ? "Failed" : "Failed";
        }else {
          statusText = "Completed";
        }

     // }
      record.status_text = statusText;
      record.baged_class = statusText === "Confirmed" ? "baged-confirm" : (statusText === "Payment Pending" ? "baged-pending" : (statusText === "Cancelled" ? "baged-cancel" : (statusText === "Completed" ? "baged-completed" : (statusText === "Failed" ? "baged-cancel" : "baged-cancel"))));
      // ${this.state.currentData.nightly_rate ? this.state.currentData.nightly_rate : 0 } x ${this.state.currentData.total_stay_nights ? (this.state.currentData.total_stay_nights > 1 ? this.state.currentData.total_stay_nights + "nights" : "1 night") : 0 }
      record.total_night_text = "$" + parseFloat(record.nightly_rate) + " x " + (record.total_stay_nights ? (record.total_stay_nights > 1 ? record.total_stay_nights + " nights" : "1 night") : "1 night");
      record.total_nightly_rate_disp = "$" + parseFloat(record.nightly_rate) * parseInt(record.total_stay_nights)
    });
    await this.setState({
      total_record: totalRecords,
      reservationList: list,
      loader: false
    });
  };

  tabSelect = (tab) => {
    if (tab === "upcoming") {
      localStorage.setItem("tab", 2);
      this.getReservationData("", 2);
    } else if (tab === "past") {
      localStorage.setItem("tab", 3);
      this.getReservationData("", 3);
    } else {
      localStorage.setItem("tab", 1);
      this.getReservationData();
    }
  }

  render() {
    return (
      <main className="oh-template Brandmainpage">
        {/* Header */}
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <section className="middle-section reservationListSection">
          <div className="container disclaim_sec- mytripList">
            <section className="reservationList">
              <h1 className="back_arrow">
                {/* <Link to="#">
                  <img src={backarrow} />
                </Link> */}
                Trips
              </h1>
            </section>
            <section className="tabsNav">
              <Tabs defaultActiveKey="current" id="uncontrolled-tab-example" onSelect={(e) => this.tabSelect(e)}>
                <Tab eventKey="current" title="Current">
                  {/* deskview */}

                  <TripsTable reservationList={this.state.reservationList} loader={this.state.loader} getReservationData={this.getReservationData.bind(this)} tab={1} />

                  {/* End kview */}
                </Tab>
                <Tab eventKey="upcoming" title="Upcoming">
                  <TripsTable reservationList={this.state.reservationList} getReservationData={this.getReservationData.bind(this)} loader={this.state.loader} tab={2} />
                </Tab>
                <Tab eventKey="past" title="Past">
                  <TripsTable reservationList={this.state.reservationList} getReservationData={this.getReservationData.bind(this)} loader={this.state.loader} tab={3} />
                </Tab>
              </Tabs>
            </section>
          </div>

        </section>
        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default ReservationList;
