import { TextField } from "@mui/material";
import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import SpinnerLoader from "../common/SpinnerLoader";
import ReCAPTCHA from "react-google-recaptcha";
import ReservationService from "../../services/ReservationService";
import SupportFaqs from "../pages/SupportFaqs";
import queryString from "query-string";
const queryParams = queryString.parse(location.search);
const recaptchaRef = React.createRef();
import { Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Moment from "moment";
import TimerSupport from "../common/TimerSupport";
class SupportConfirmationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_slug: "",
      user_type: 0,
      confirmation_code: props.match.params ? props.match.params.confirmationCode :queryParams.confirmation_code ? queryParams.confirmation_code:"",
      params: queryParams && queryParams.params ? queryParams.params.toLowerCase() : props.params,
      propertyname: queryParams && queryParams.propertyname ? queryParams.propertyname : props.propertyname,
      residentname: queryParams && queryParams.username ? queryParams.username : props.username,
      unitname: queryParams && queryParams.unitname ? queryParams.unitname : props.unitname,
      latch_description: queryParams.description ? queryParams.description : "",
      user_slug: queryParams && queryParams.userslug ? queryParams.userslug : props.userslug,
      confirmation_code_error: "",
      is_form_submited_popup: false,
      is_form_resident_submited_popup: false,
      is_form_resident_submited_popup_one: false,
      is_form_submited_not_submited_popup: false,
      is_form_submited_not_submited_popup_two: false,
      is_form_not_resident_submited_popup: false,
      locker_screen_poup:false,
      booking_code_invalid:false,
      booking_code_valid:false,
      screen_malfunctioning:false,
      bin_not_opeone:false,
      bin_not_opetwo:false,
      reboot_poup_one:false,
      reboot_poup_two:false,
      supportHide:"",
      recaptcha_error: "",
      loader: false,
      errorCount: 0,
      errorMsg: "",
      input: {},
      today: Moment(new Date()).format("YYYY-MM-DD H:m:s"),
    };
    this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
  }
  changeHandlerSelect = async (event) => {
    let input = this.state.input;
    input.user_type = event.target.value;
    await this.setState({ user_type: event.target.value, input: input, user_type_error: "" });
  }
  componentDidMount() {
    this.getMessageDetail();
  }
  phoneNumberAutoFormat = async (val) => {
    var number = val.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "($1)-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1 $2 $3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "($1)-$2-$3");
    }
    this.setState({ Keycode: finalnumber });
  };
  getMessageDetail = async () => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecordsfaq = 0;
    var listmessage = [];
    let res = await ReservationService.getMessageDetail(queryString);
    if (global.successStatus.includes(res.status)) {
      listmessage = res.data ? res.data : [];
      totalRecordsfaq = listmessage ? listmessage.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      listmessage: listmessage,
      totalRecordsfaq: totalRecordsfaq,
    });
  }
  onChangeRecaptcha = (value) => {
    if (value !== "") {
      this.setState({ isValidRecatcha: true, recaptcha_error: "" });
    }
  };
  isFormSubmitedPopup = (value = false) => {
    this.setState({ is_form_submited_popup: value, supportHide: "visible" });
  };
  isFormnotSubmitedResidnetPopupone = (value = false) => {
    this.setState({ is_form_not_resident_submited_popup: value, supportHide: "visible" });
  };
  isFormSubmitedResidnetPopupone = (value = false) => {
    this.setState({ is_form_resident_submited_popup_one: value, supportHide: "visible" });
  };
  isFormResidentSubmitedPopup = (value = false) => {
    this.setState({ is_form_resident_submited_popup: value, supportHide: "visible" });
  };
  isFormnotSubmitedPopupone = (value = false) => {
    this.setState({ is_form_submited_not_submited_popup: value, supportHide: "visible" });
  };
  isFormnotSubmitedPopuptwo = (value = false) => {
    this.setState({ is_form_submited_not_submited_popup_two: value, supportHide: "visible" });
  };
  changeHandler = (event) => {
    let value = event.target.value;
    if (event.target.name === "confirmation_code") {
      if (event.target.value.length >= 4) {
        this.setState({ confirmation_code_error: "" });
      }
    }
    this.setState({ [event.target.name]: value, errorMsg: "" });
  };

  onErrorRecaptcha = (value) => {
    this.setState({ isValidRecatcha: false });
    recaptchaRef.current.reset();
  };
  openResidentSubmitpopup = () => {
    this.setState({
      supportHide: "invisible",
      is_form_resident_submited_popup_one: true,
      is_form_resident_submited_popup: false,
    });
  }
  openLockerSreenpopup = () => {
    this.setState({
      supportHide: "invisible",
      locker_screen_poup: true,
      is_form_submited_popup: false,
    });
  }
  bookingCodeInvalidpopup = () => {
    this.setState({
      supportHide: "invisible",
      booking_code_invalid: true,
      locker_screen_poup: false,
    });
  }
  bookingCodeInvalidpopupclose = (value = false) => {
    this.setState({ locker_screen_poup: value, supportHide: "visible" });
  };
  bookingcodeinvalidclose = (value = false) => {
    this.setState({ booking_code_invalid: value, supportHide: "visible",locker_screen_poup:true, });
  };
  getGuestFormDetails = async (confirmationcode) => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecords = 0;
    var list = [];
    var formSubmited = "";
    var emailVerified = "";
    var card_tokenise = "";
    var parking_payment = "";
    var lockType = "";
    var supportfaqurl = "";
    confirmationcode = this.state.confirmation_code;
    if (!this.state.confirmation_code) {
      document.getElementById("confirmation_code").focus();
      this.setState({
        confirmation_code_error: "Please enter confirmation code",
      });
    }
    if (!this.state.user_type) {
      document.getElementById("demo-simple-select").focus();
      this.setState({
        user_type_error: "Please select user type",
      });
    }
    if (this.state.user_type == 3 && !this.state.confirmation_code_error && !this.state.user_type_error && !this.state.errorMsg) {
      let res = await ReservationService.getcleanerDetail(confirmationcode);
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {
        list = res.data ? res.data : [];
        lockType = res.data ? res.data[0].lock_type : "";
        this.setState({
          lockType: lockType,
        });
        if (lockType == 1) {
          supportfaqurl = "/support-faq?params=Keycafe&cleaner=cleaner";
        } else {
          supportfaqurl = "/support-faq?params=Latch&cleaner=cleaner";
        }
        window.location.href = supportfaqurl;
      } else {
        this.setState({ errorMsg: "Details entered by you are incorrect. Please enter valid project id." })
      }

    }
    if (this.state.user_type == 1 || this.state.user_type == 2) {
      let res = await ReservationService.getGuestformdata(confirmationcode);
      await this.getReservationDetails();
      if (global.successStatus.includes(res.status)) {
        list = res.data ? res.data : [];
        totalRecords = list ? list.length : 0;
        formSubmited = res.data.is_form_submited ? res.data.is_form_submited : 0;
        emailVerified = res.data.email_verified ? res.data.email_verified : 0;
        card_tokenise = res.data.card_tokenise ? res.data.card_tokenise : 0;
        parking_payment = res.data.parking_payment ? res.data.parking_payment : 0;

      }
      this.setState({
        is_form_submited: formSubmited,
        is_email_verified: emailVerified,
        card_tokenise: card_tokenise,
        parking_payment: parking_payment,
        showSpinner: false,
        loading: false,
        total_record: totalRecords,
      });
    }
    /**below is case when isform submited is 1 and user type is guest */
    if (this.state.is_form_submited == 1 && this.state.user_type == 1 && this.state.lockType == 1 && !this.state.confirmation_code_error && !this.state.user_type_error && !this.state.errorMsg) {

      this.setState({
        is_form_submited_popup: true,
      });
      await this.getReservationDetails();
    }
    else if (this.state.is_form_submited == 0 && this.state.user_type == 1 && this.state.lockType == 1 && !this.state.confirmation_code_error && !this.state.user_type_error && !this.state.errorMsg) {
      this.setState({
        is_form_submited_not_submited_popup: true,
        supportHide: "invisible",
      });
    }
    else if (this.state.is_form_submited == 1 && this.state.user_type == 2 && this.state.lockType == 1 && !this.state.confirmation_code_error && !this.state.user_type_error && !this.state.errorMsg) {
     // await this.getReservationDetails();
      this.setState({
        is_form_resident_submited_popup: true,
      });
    }
    else if (this.state.is_form_submited == 0 && this.state.user_type == 2 && this.state.lockType == 1 && !this.state.confirmation_code_error && !this.state.user_type_error && !this.state.errorMsg) {
      this.setState({
        is_form_not_resident_submited_popup: true,
        supportHide: "invisible",
      });
    }
    else if (this.state.lockType == 2 && !this.state.confirmation_code_error && !this.state.user_type_error && !this.state.errorMsg){
      supportfaqurl = "/support-faq?params=Latch&confirmation_code=" + this.state.confirmation_code;
      window.location.href = supportfaqurl;
    }
  }
  bookingCodevalidpopup = () => {
    this.setState({
      supportHide: "invisible",
      booking_code_valid: true,
      locker_screen_poup: false,
    });
  }
  bookingcodevalidclose = (value = false) => {
    this.setState({ booking_code_valid: value, supportHide: "visible",locker_screen_poup:true, });
  }
  rebootPopupOneClose = (value = false) => {
    this.setState({ reboot_poup_one: value, supportHide: "visible" });
  }
  rebootPopuptwoClose = (value = false) => {
    this.setState({ reboot_poup_two: value, supportHide: "visible" });
  }
  screenMalfunctioningone = () => {
    this.setState({
      popup:1,
      supportHide: "invisible",
      screen_malfunctioning: true,
      locker_screen_poup: false,
    });
  }
  screenMalfunctioningtwo = () => {
    this.setState({
      popup:2,
      supportHide: "invisible",
      screen_malfunctioning: true,
      locker_screen_poup: false,
    });
  }
  rebootPopupOne = () => {
    this.setState({
      supportHide: "invisible",
      reboot_poup_one: true,
      screen_malfunctioning: false,
    });
  }
  rebootPopupTwo = () => {
    this.setState({
      supportHide: "invisible",
      reboot_poup_two: true,
      reboot_poup_one: false,
    });
  }
  malfunctioningclose = (value = false) => {
    this.setState({ screen_malfunctioning: value, supportHide: "visible" ,locker_screen_poup:true});
  }
  binNotopen = () => {
    if(this.state.listkey == 1 && this.state.Keycode != null) {
    this.setState({
      supportHide: "invisible",
      bin_not_opeone: true,
      locker_screen_poup: false,
    });
  } else {
   this.setState({
    supportHide: "invisible",
      bin_not_opetwo: true,
      locker_screen_poup: false,
    });
  }
  }
  binNotopenclose = (value = false) => {
    this.setState({ bin_not_opeone: value, supportHide: "visible",locker_screen_poup:true});
  }
  binNotopentwoclose = (value = false) => {
    this.setState({ bin_not_opetwo: value, supportHide: "visible", locker_screen_poup:true});
  }
  getReservationDetails = async () => {
    var list = [];
    var record = [];
    var totalRecords = 0;
    var checkinTime = "";
    var checkoutTime = "";
    var propertyCheckinTime = "";
    var lockType = "";
    var Keycode = "";
    var params = "";
    var checkoutinstruction = "";
    var checkininstruction = "";
    var totalRecordsfaq = 0;
    var listfaq = [];
    var listkey = []; 
    var totalRecordskey = 0; 
    var queryString = "";
    this.setState({ loader: true });
    var captcha = true;
    if (this.state.errorCount >= 3) {
      const recaptchaValue = recaptchaRef.current.getValue();
      captcha = this.state.isValidRecatcha ? true : false;
    }
    if (
      captcha &&
      this.state.confirmation_code_error === "" &&
      this.state.confirmation_code
    ) {
      var data = {
        confirmation_code: this.state.confirmation_code,
        last_name: this.state.last_name,
        email: this.state.email,
        property_seo_slug: this.state.property_slug,
        user_slug: "",
        filter_by: 4,
      };
      let res = await ReservationService.getMyBookReservation(data);
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {

        list = res.data ? res.data : [];
        record = res.data ? res.data : [];
        this.setState({ loader: false });
        totalRecords = res.data ? res.data.total : 0;
        lockType = res.data ? res.data?.property?.lock_type : "";
        Keycode = res.data ? res.data.booking_code:"";
        checkinTime = res.data ? res.data.checkin_date:"";
        checkoutTime = res.data ? res.data.checkout_date:"";
        checkoutinstruction = res.data ? res.data?.property?.checkout_instructions : "";
        checkininstruction = res.data ? res.data?.property?.arrival_instruction : "";
        propertyCheckinTime = res.data ? res.data?.property?.check_in_time : "";

      }
      if (lockType == 2) {
        params = "latch";
        queryString = "Access Support-Latch";
      } else if (lockType == 1) {
        params = "keycafe";
        queryString = "Access Support-Keycafe";
      } else {
        params = "latch";
        queryString = "Access Support-Latch";
      }
      let resfaq = await ReservationService.getFaqdataSupport(queryString);
      //let res = await ReservationService.getFaqdataSupport();
      if (global.successStatus.includes(resfaq.status)) {
        listfaq = resfaq.data ? resfaq.data?.data : [];
        totalRecordsfaq = listfaq ? listfaq.length : 0;
      }
      if(lockType == 1){
        var data = {
          Keycode: Keycode,
        };
        let reskey = await ReservationService.getkeycode(data);
        if (global.successStatus.includes(resfaq.status)) {
          listkey = reskey.data ? reskey.data : [];
          totalRecordskey= listkey ? listkey.length : 0;
        } 
      }
      if (this.state.errorCount >= 3) {
        recaptchaRef.current.reset();
      }

      if (global.successStatus.includes(res.status) && res.data.length !== 0) {
        await this.setState({
          total_record: totalRecords,
          reservationList: list,
          listfaq: listfaq,
          listkey:listkey,
          totalRecordsfaq: totalRecordsfaq,
          loader: false,
          lockType: lockType,
          Keycode:Keycode,
          params: params,
          checkinTime:checkinTime,
          checkoutTime:checkoutTime,
          checkoutinstruction:checkoutinstruction,
          checkininstruction:checkininstruction,
          propertyCheckinTime:propertyCheckinTime
        });
        this.phoneNumberAutoFormat(this.state.Keycode);
        await localStorage.setItem("trip_details", JSON.stringify(list));
        this.setState({ reservationDetail: false, errorMsg: "" });
      } else {
        this.setState({ errorMsg: "Details entered by you are incorrect. Please verify your confirmation code you have used during booking." })
      }
    } else {
      var errorCount = this.state.errorCount;
      if (!this.state.confirmation_code) {
        document.getElementById("confirmation_code").focus();
        this.setState({
          confirmation_code_error: "Please enter confirmation code",
        });
      }
    }
    this.setState({ loader: false, errorCount: errorCount + 1 });
  };
  openNotsubmittedpopupTwo = () => {
    this.setState({
      is_form_submited_not_submited_popup: false,
      is_form_submited_not_submited_popup_two: true,
    });
  }
  render() {
    let supportfaqurlkeycafe = "/support-faq?params=Keycafe&confirmation_code=" + this.state.confirmation_code;
    let supportfaqurllatch = "/support-faq?params=Latch&confirmation_code=" + this.state.confirmation_code;
    let supporturl = "/support-form";
    if(this.state.confirmation_code){
      supporturl = supporturl + "?confirmation_code=" + this.state.confirmation_code;
  }
    let supportfinalurl = this.state.lockType == 1 ? supportfaqurlkeycafe : supportfaqurllatch;
    let checkinurl = "/reservation?page=support&confirmation_code=" + this.state.confirmation_code;
    return (
      <>
        {this.state.reservationDetail || this.state.residentname ? (
          <SupportFaqs confirmationCode={this.state.confirmation_code} params={this.state.params} listfaq={this.state.listfaq} totalRecordsfaq={this.state.totalRecordsfaq} username={this.state.residentname} unitname={this.state.unitname} propertyname={this.state.propertyname} userslug={this.state.user_slug} latch_description={this.state.latch_description} />
        )
          :
          <main className="oh-template Brandmainpage accessyourreservation">
            {/* Header */}
            <CommonHeader  CheckIn={true}/>
            {/* middle */}
            <section className={"ConfirmationBooking reservationBook middle-section " + this.state.supportHide} >
              <div className="container">
                <div className="row- mobView d-block">
                  <div className="col-lg-6 col-md-6 col-sm-8 d-block m-auto requestSection contact_form_box">
                    <h1 className="pd-l-15 pd-r-15 mg-b-30">
                      Access Support
                    </h1>
                    {
                      this.state.errorMsg && (
                        <p className="error-message pd-l-15 pd-r-15 mg-b-30">{this.state.errorMsg}</p>
                      )
                    }
                    <div className="pd-l-15 pd-r-15 mg-b-30">
                      <div className="countryphone">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">I'm:</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            value={this.state.user_type}
                            //disabled
                            name="add"
                            onChange={(event) => this.changeHandlerSelect(event)}
                          >
                            <MenuItem value={1}>Guest</MenuItem>
                            <MenuItem value={2}>Resident</MenuItem>
                            <MenuItem value={3}>Cleaner</MenuItem>
                          </Select>
                        </FormControl>
                        <p className="error-message">
                          {this.state.user_type_error ? this.state.user_type_error : ""}
                        </p>
                      </div></div>
                    <div className="pd-l-15 pd-r-15 mg-b-30">
                      <div className="countryphone">
                        <TextField
                          id="confirmation_code"
                          name="confirmation_code"
                          value={this.state.confirmation_code}
                          autoFocus={true}
                          onChange={this.changeHandler}
                          label={this.state.user_type == 3 ?"Project Id":"Confirmation code"}
                          variant="outlined"
                          fullWidth
                          required
                          error={this.state.confirmation_code_error ? true : false}
                          helperText={
                            this.state.confirmation_code_error
                              ? this.state.confirmation_code_error
                              : ""
                          }
                        />
                      </div>
                    </div>
                    {this.state.errorCount >= 3 && (
                      <div id="recaptch-block" className="w-100 mg-b-10 pd-l-15 pd-r-15">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          onChange={this.onChangeRecaptcha}
                          onExpired={() => this.onErrorRecaptcha}
                          onErrored={() => this.onErrorRecaptcha}
                          sitekey={"6Lezqe8jAAAAAJXeZgVxsekhhHeTC6B8V9sbe18u"}
                        />
                        {this.state.recaptcha_error !== "" ? (
                          <p className="error-message">
                            {this.state.recaptcha_error}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    <div className="pd-l-15 pd-r-15 mg-b-30 pd-l-15 pd-r-15 mg-b-30 d-flex justify-content-center m-auto">
                      <button
                        onClick={this.getGuestFormDetails}
                        disabled={this.state.loader ? true : false}
                        className="btn-checkin"
                      >
                        Submit
                        {this.state.loader ? (
                          <>
                            <SpinnerLoader />
                          </>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modal show={this.state.is_form_submited_popup} centered className="access_popup" >
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.isFormSubmitedPopup(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font cancellations p-0">
                  <p
                      dangerouslySetInnerHTML={{
                        __html:this.state.listmessage ? this.state.listmessage.support_guest_form_submited_popupone_message.replace("[arrival_instructions]", this.state.reservationList
                        ? this.state.reservationList.property.arrival_instruction : "") : "",
                      }}
                    ></p>
                  </h4>
                  {/* <div className="cancellations">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.reservationList
                          ? this.state.reservationList.property.arrival_instruction : "",
                      }}
                    ></p>
                  </div> */}

                  <div className="access_btn_box">
                    {/* <a href={supportfinalurl}> */}
                      <button id="book-tour-page" className="btn_pre_access" onClick={this.openLockerSreenpopup}>
                       {this.state.listmessage ? this.state.listmessage.arrival_code_not_work_guest_button_message : "Code didn't work"}
                      </button>
                      {/* </a> */}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.is_form_submited_not_submited_popup} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.isFormnotSubmitedPopupone(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_not_submited_popupone_message : ""}
                  </h4>
                  <div className="access_btn_box">
                    <a href={checkinurl}>
                      <button id="book-tour-page" className="btn_pre_access">
                      {this.state.listmessage ? this.state.listmessage.online_checkin_button_message :"Checkin"}
                      </button></a>
                  </div>
                  <div className="access_btn_box"> <button id="book-tour-page" className="btn_pre_access" onClick={this.openNotsubmittedpopupTwo}>
                      {this.state.listmessage ? this.state.listmessage.dont_want_button_message :"I Don't Want To"}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.is_form_submited_not_submited_popup_two} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.isFormnotSubmitedPopuptwo(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_not_submited_popuptwo_message : ""}
                  </h4>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.is_form_resident_submited_popup} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.isFormResidentSubmitedPopup(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font cancellations p-0">
                     <p
                      dangerouslySetInnerHTML={{
                        __html:this.state.listmessage ? this.state.listmessage.support_guest_form_submitted_resident_popupone_message.replace("[arrival_instructions]", this.state.reservationList
                        ? this.state.reservationList.property.arrival_instruction : "") : "",
                      }}
                    ></p>
                  </h4>

                  <div className="access_btn_box">
                    <button id="book-tour-page" className="btn_pre_access" onClick={this.openResidentSubmitpopup}>
                    {this.state.listmessage ? this.state.listmessage.arrival_code_not_work_resident_button_message : "They have their arrival instructions but code didn't work"}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.is_form_resident_submited_popup_one} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.isFormSubmitedResidnetPopupone(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_submitted_resident_popuptwo_message : ""}
                  </h4>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.is_form_not_resident_submited_popup} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.isFormnotSubmitedResidnetPopupone(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.listmessage ? this.state.listmessage.support_guest_form_not_submited_residentpopup_message : "",
                      }}
                    ></p>
                  </h4>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.locker_screen_poup} centered className="access_popup"> 
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.bookingCodeInvalidpopupclose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                  {this.state.listmessage ? this.state.listmessage.key_locker_title_message : "What does the Key Locker screen say?"}
                  </h4>
                  <div className="cancellations">
                  {this.state.today < this.state.checkoutTime ? (
                  <div className="access_btn_box">
                    <button id="book-tour-page" className="btn_pre_access" onClick={this.bookingCodeInvalidpopup}>
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_screen_booking_code_invalid_button : ""}
                    </button>
                  </div>):("")}
                  {this.state.today < this.state.checkoutTime ? (
                  <div className="access_btn_box">
                    <button id="book-tour-page" className="btn_pre_access" onClick={this.bookingCodevalidpopup}>
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_screen_booking_code_valid_button : ""}
                    </button>
                  </div>):("")}
                  <div className="access_btn_box">
                    <button id="book-tour-page" className="btn_pre_access" onClick={this.screenMalfunctioningone}>
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_screen_malfunctioning_button_message : ""}
                    </button>
                  </div>
                  <div className="access_btn_box">
                    <button id="book-tour-page" className="btn_pre_access" onClick={this.screenMalfunctioningtwo}>
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_return_key_message : ""}
                    </button>
                  </div>
                  <div className="access_btn_box">
                    <button id="book-tour-page" className="btn_pre_access" onClick={this.binNotopen}>
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_bin_not_open_message : ""}
                    </button>
                  </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.booking_code_invalid} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.bookingcodeinvalidclose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0"> 
                  <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.listmessage ? this.state.listmessage.support_booking_code_invalid_poup_message.replace(
                          "[access_code]",
                          this.state.Keycode ? `<span class="access_code_support">${this.state.Keycode}</span>` : ""
                        ) : "",
                      }}
                    ></p> 
                   
                    </h4>
                </div>
              </Modal.Body>
            </Modal>
             <Modal show={this.state.booking_code_valid} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.bookingcodevalidclose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                  <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.listmessage ? this.state.listmessage.support_booking_code_valid_poup_message.replace(
                          "[checkin_time]",
                          this.state.propertyCheckinTime ? `<span class="access_code_support">${this.state.propertyCheckinTime}</span>` : ""
                        ) : "",
                      }}
                    ></p>
                  </h4>
                  
                </div>
              </Modal.Body>
            </Modal>
             <Modal show={this.state.screen_malfunctioning} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.malfunctioningclose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  {/* <h4 className="text-center">
                    {this.state.listmessage ? this.state.listmessage.support_malfunctioning_popup_message : ""}
                  </h4> */}
                  <h4 className="text-center support_cnt_font cancellations p-0">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:this.state.listmessage ? this.state.listmessage.support_malfunctioning_popup_message : "",
                      }}
                    ></p>
                  </h4>
                   <div className="access_btn_box">
                  <button id="book-tour-page" className="btn_pre_access" onClick={this.rebootPopupOne}>
                  {this.state.listmessage ? this.state.listmessage.pressed_reset_button_message :"I pressed the reset button"}
                    </button>
                    </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.bin_not_opeone} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.binNotopenclose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.listmessage ? this.state.listmessage.support_bin_not_open_popup_one_message.replace(
                          "[access_code]",
                          this.state.Keycode ? `<span class="access_code_support">${this.state.Keycode}</span>` : ""
                        ) : "",
                      }}
                    ></p>
                  </h4>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={this.state.bin_not_opetwo} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.binNotopentwoclose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <h4 className="text-center support_cnt_font p-0">
                    {this.state.listmessage ? this.state.listmessage.support_bin_not_open_popup_two_message : ""}
                  </h4>
                  <div className="access_btn_box">
                  <a href={supporturl}>
                      <button id="book-tour-page" className="btn_pre_access">
                       {this.state.listmessage ? this.state.listmessage.still_work_not_button_message : "Still isn't working/contact?"}
                      </button></a>
                      </div>
                </div>
              </Modal.Body>
            </Modal>
             <Modal show={this.state.reboot_poup_one} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.rebootPopupOneClose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  {/* <h4 className="text-center">
                    {this.state.listmessage ? this.state.listmessage.support_guest_form_screen_malfunctioning_messageone : ""} */}
                  <TimerSupport
                        minutes={parseFloat(5.0)}
                        message={this.state.listmessage ? this.state.listmessage.support_guest_form_screen_malfunctioning_messageone:""}
                        keycodemessage={this.state.listmessage ? this.state.listmessage.try_your_code_again_message:""}
                        keycode ={this.state.Keycode}
                        checkoutinstruction={this.state.checkoutinstruction}
                        checkininstruction = {this.state.checkininstruction}
                        confirmationCode = {this.state.confirmation_code}
                        popup = {this.state.popup}
                        stillnotwork={this.state.listmessage ? this.state.listmessage.still_work_not_button_message : "Still isn't working/contact?"}
                        supporturl={supporturl}
                      />
                  {/* </h4> */}
                  {/* <div className="access_btn_box">
                      <button id="book-tour-page" className="btn_pre_access" onClick={this.rebootPopupTwo}>
                        Still not work
                      </button>
                      </div> */}
                </div>
              </Modal.Body>
            </Modal>
              <Modal show={this.state.reboot_poup_two} centered className="access_popup">
              <Modal.Body className="width_402 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.rebootPopuptwoClose(false)}
                >
                  {global.closee}
                </span>
                <div className="w-100 text-center">
                  <h1 className="pd-l-15 pd-r-15 mg-b-20">
                    Access Support
                  </h1>
                  <div className="access_btn_box">
                  <a href={supporturl}>
                      <button id="book-tour-page" className="btn_pre_access">
                        {this.state.listmessage ? this.state.listmessage.support_guest_form_screen_malfunctioning_messagetwo : ""}
                      </button></a>
                      </div>
                </div>
              </Modal.Body>
            </Modal>
          </main>
        }
      </>
    );
  }
}

export default SupportConfirmationCode;