//     							                          //
//  Program: strDetail.jsx                                //
//  Application: OH HTML                                  //
//  Option: STR Detail                                    //
//  Developer: PS		                                  //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import { Modal, ModalBody, Spinner, Dropdown } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import queryString from "query-string";
import GoogleMap from "../common/GoogleMap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Moment from "moment";
import LtrlistingApi from "../../services/LtrlistingApi";
import SocialShareLink from "../common/SocialShareLink";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import PhoneInput from "react-phone-input-2";
import Gallery from "../common/Gallery";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import UnitlistingApi from "../../services/UnitlistingApi";
import ApplicantsService from "../../services/ApplicantsService";
import Accordion from 'react-bootstrap/Accordion';
import { PopupModal } from "react-calendly";
import ModalVideo from 'react-modal-videojs'
import FloorPlanApi from "../../services/FloorPlanApi";
import MetaSEO from "../../../MetaSEO";
import SpinnerLoader from "../common/SpinnerLoader";
const recaptchaRef = React.createRef();
const queryParams = queryString.parse(location.search);
class LtrDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: window.location.href,
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: Moment(new Date()).format("YYYY-MM-DD"),
      type: false,
      input: {},
      UnitListing: {
        mediaFiles: [],
      },
      UnitBedroom: [],
      UnitBathroom: [],
      FloorPlanListing: [],
      errors: {},
      guest: "",
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      isValidRecatcha: false,
      errorCount: 0,
      total_record: 0,
      total_calculation: "",
      confirm_back: 0,
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      unit_slug: this.props.match.params.unitSlug,
      slug: "",
      status_id: true,
      pathname: window.location.pathname,
      urlname: window.location.href,
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
      petsCount: 0,
      bedroom: "",
      bathroom: "",
      property: "",
      propertyAmenityTotal: 0,
      propertyAmenityList: [],
      unit_name: queryParams.unit ? decodeURIComponent(queryParams.unit) : "",
      media_files: [],
      mainImage: "",
      availablity_modal_box: false,
      unitImagemodal: [],
      openImagepopup: false,
      openGalaryModal: false,
      openAllAmenitiesModal: false,
      openHealthAndSafetyModal: false,
      docCalcModal: false,
      openGettingAroundModal: false,
      openvirtualTourpopup: false,
      loader: false,
      source: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerQA.mp4", "website/video") + "?autoplay=1",
      source1: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerOverview.mp4", "website/video") + "?autoplay=1",
      source2: global.OnKeyPressEvent.getS3ImageUrl("OrionHausHomesharingProcess.mp4", "website/video") + "?autoplay=1",
      source3: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyTourVideo.mp4", "website/video") + "?autoplay=1",
      source4: global.OnKeyPressEvent.getS3ImageUrl("OrionHausKeyAccess.mp4", "website/video") + "?autoplay=1",
      source5: global.OnKeyPressEvent.getS3ImageUrl("OrionHausResidentHomesharingKit.mp4", "website/video") + "?autoplay=1",
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      property_name: localStorage.getItem("property_name"),
      // meta tags start
      title: global.level === 2 ? localStorage.getItem("brand_name") : (global.level === 3 ? localStorage.getItem("property_name") : ""),
      favicon: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : ""),
      keywords: global.keywords,
      description: global.description,
      url: window.location.href,
      image: global.level === 2 ? localStorage.getItem("brand_logo_url") : (global.level === 3 ? localStorage.getItem("property_logo_url") : ""),
      fb_app_id: "",
      // meta tags end
      startDate: localStorage.getItem("checkin")
        ? localStorage.getItem("checkin")
        : "",
      endDate: localStorage.getItem("checkout")
        ? localStorage.getItem("checkout")
        : "",
      message: "",
      pricing: [],
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      errors: {
        email: "",
        phone_number: "",
        first_name: "",
      },
      userToken: localStorage.getItem("userToken")
        ? localStorage.getItem("userToken")
        : undefined,
      availableDate: "",
      bookedDate: "",
      enableDatePicker: true,
      redirectToNextPage: false,
      queryString: "",
      guestValue: "1 Guest",
      guest_slug: localStorage.getItem("guest_slug")
        ? localStorage.getItem("guest_slug")
        : "",
      property: "",
      reservation_slug: localStorage.getItem("reservation_slug")
        ? localStorage.getItem("reservation_slug")
        : "",
      property: "",
      airBnbQueryString: "",
      minDate: new Date(),
      startDate: queryParams.check_in ? Moment(queryParams.check_in).local().format('MM/DD/YYYY') : "",
      health_safety: [],
      health_safety1: [
        "Airbnb's COVID-19 safety practices apply",
        "Security camera/recording device",
        "Carbon monoxide alarm",
        "Smoke alarm",
      ],
      booktourpage: localStorage.getItem("booktourpage") ? localStorage.getItem("booktourpage") : "",
      unit_number: "",
      celendaryPopUp: false,
      celendaryLink: "https://calendly.com/orion-haus/discovery",
      nightly_rate: queryParams.price ? queryParams.price : "",
      seoLoaded: false,
      currentFloorplanNight: 7,
      webview: queryParams.webview ? queryParams.webview : 1,
    };
    this.getUnitData = this.getUnitData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    this.incrementpets = this.incrementpets.bind(this);
    this.decrementpets = this.decrementpets.bind(this);
    this.Setclose = this.Setclose.bind(this);
    this.openGalaryModal = this.openGalaryModal.bind(this);
    this.closeGalaryModal = this.closeGalaryModal.bind(this);
    this.closevirtualtour = this.closevirtualtour.bind(this);
    this.openvirtualtour = this.openvirtualtour.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.changeDateHandlerNew = this.changeDateHandlerNew.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.checkAvailabilityDate = this.checkAvailabilityDate.bind(this);
    this.changeHandlerPhone = this.changeHandlerPhone.bind(this);
    this.saveOrUpdateApplicants = this.saveOrUpdateApplicants.bind(this);
    this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);
    this.getUnitDataFloorplan = this.getUnitDataFloorplan.bind(this);
    this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
    // this.openAllAmenitiesModal = this.openAllAmenitiesModal(this);
    // this.createQueryString = this.createQueryString.bind(this);
  }
  backToPage = async () => {
    history.back();
  }

  async componentDidMount() {
    await this.getUnitData();
    this.getFloorplanData();
    this.getUnitDataFloorplan();
    this.setState({ Currentdata: localStorage.getItem("ltrdetail") ? localStorage.getItem("ltrdetail") : "" });
  }

  // ;
  changeHandlerSelect = async (event) => {
    let input = this.state.input;
    input.add = event.target.value;
    let currentFloorplanPrice = "";
    let currentFloorplanupdated = "";
    this.state.FloorPlanListing.map((item, i) => {
      if (item.floorplan_slug === input.add) {
        currentFloorplanPrice = item.adr_value ? item.adr_value : 176;
        currentFloorplanupdated = item.updated_at ? item.updated_at : "";
      }
    })
    // input.adddate = event.target.value;
    let total = currentFloorplanPrice * this.state.currentFloorplanNight;

    this.setState({ currentFloorplanSlug: input.add, floorplanPrice: input.add, total_calculation: total, adr_updated_on: currentFloorplanupdated });

    //  input.add-date = event.target.value;
  }

  changeHandlerSelectmonth = async (event) => {
    let input = this.state.input;
    let currentFloorplanPrice = "";
    this.state.FloorPlanListing.map((item, i) => {
      if (item.floorplan_slug === this.state.currentFloorplanSlug) {
        currentFloorplanPrice = item.adr_value ? item.adr_value : 176;
      }
    })
    input.adddate = event.target.value;
    let total = currentFloorplanPrice * input.adddate;
    this.setState({ total_calculation: total, currentFloorplanNight: input.adddate });
    //  input.add-date = event.target.value;
  }
  onChangeRecaptcha = (value) => {
    if (value !== "") {
      var errors = this.state.errors;
      errors.recaptcha = "";
      this.setState({ isValidRecatcha: true, errors: errors });
    }
  }

  onErrorRecaptcha = (value) => {
    // var errors = this.state.errors;
    // errors.recaptcha = "Verification expired. Check the checkbox again."
    this.setState({ isValidRecatcha: false });
    recaptchaRef.current.reset();
  }

  changeDateHandlerNew = async (date) => {
    var startDate = date.value[0] ? Moment(date.value[0]).format("YYYY-MM-DD") : "";
    var endDate = date.value[1] ? Moment(date.value[1]).format("YYYY-MM-DD") : "";
    // if(startDate !== "" && (endDate === "" || endDate === null )){
    //   await this.setState({endDate:Moment(startDate).add(1, "days").format("YYYY-MM-DD")})
    //   endDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
    // }
    await this.setState({ startDate: startDate, endDate: endDate });
    await this.createQueryString();
    await this.creatAirBnbQueryString();
  }

  saveOrUpdateApplicants = async (e) => {
    e.preventDefault();
    var captcha = true;
    if (this.state.errorCount >= 3) {
      const recaptchaValue = recaptchaRef.current.getValue();
      captcha = this.state.isValidRecatcha ? true : false;
    }
    if (
      captcha &&
      this.state.errors.email === "" &&
      this.state.email !== "" &&
      this.state.errors.phone_number === "" &&
      this.state.phone_number !== "" &&
      this.state.errors.first_name === "" &&
      this.state.first_name !== ""
    ) {
      this.setState({ loader: true });
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        property_slug: this.state.property_slug === "the-fredrick-tempe" ? "the-fredrick-tempe" : this.state.property_slug,
        floorplan_slug:this.state.currentFloorplanSlug ? this.state.currentFloorplanSlug:"",
        email: this.state.email,
        phone_number: this.state.phone_number,
        formattedValue:this.state.formattedValue,
        // tour_date_time:this.state.tour_date_time ? this.state.tour_date_time : '',
        desired_move_in_date: this.state.selectedDate
          ? this.state.selectedDate
          : "",
        bedroom_number: this.state.bedroom
          ? this.state.bedroom
          : JSON.stringify(this.state.bedroomArray[0].value).replace(
            /["']/g,
            ""
          ),
        price_range_slug: this.state.price_range,
        applicant_photo: this.state.applicant_photo,
        notes: this.state.notes,
        sort_order: this.state.sort_order,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      this.setState({ qry_first_name: this.state.first_name, qry_email: this.state.email, qry_phone: this.state.phone_number });
      this.setState({ qry_string: "&applicant[name_first]=" + this.state.first_name + "&phone_numbers[0][phone_number]=" + this.state.phone_number + "&applicant[username]=" + this.state.email });
      let res = await ApplicantsService.createApplicantsFromWebsite(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          desired_move_in_date: this.state.selectedDate ? this.state.selectedDate : Moment(new Date()).format("MM-DD-YYYY"),
          bedroom_number: "",
          price_range_slug: "",
          notes: "",
          status_id: true,
        });
        if (this.state.errorCount >= 3) {
          recaptchaRef.current.reset();
        }
        // var redirectBtn = document.getElementById("virtual-tour-button");
        // redirectBtn.click();
        if(this.state.Lease_Inquiry_URL === null){
          // window.location.href = this.state.Lease_Inquiry_URL;
          this.manageCelendaryPopUp(true);
        }
        else{
          // this.manageCelendaryPopUp(true);
          window.location.href = this.state.Lease_Inquiry_URL;
        }
        // this.props.history.push(global.themeUrl + this.state.property_slug + "/virtual-tour-confirmation");
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ loader: false });

      // this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      //show required errors
      var errors = this.state.errors;
      var errorCount = this.state.errorCount;
      if (!this.state.phone_number) {
        // document.getElementById("phone_number").focus();
        errors.phone_number = "Phone number is required.";
      }
      if (!this.state.email) {
        document.getElementById("email").focus();
        errors.email = "Please enter valid email.";
      }
      if (!this.state.first_name) {
        document.getElementById("first_name").focus();
        errors.first_name = "Please enter valid name.";
      }
      this.setState({ errors: errors, errorCount: errorCount + 1 });
    }
  }

  changeHandlerPhone = (phoneNumber, country, e, formattedValue) => {
    this.setState({ phone_number: phoneNumber,formattedValue:formattedValue });
    if (phoneNumber.length > 8) {
      var errors = this.state.errors;
      errors.phone_number = "";
      this.setState({ errors: errors });
    }
    if (phoneNumber.length > 15 || phoneNumber.length < 8) {
      var errors = this.state.errors;
      errors.phone_number = "The phone number must be a valid phone number";
      this.setState({ errors: errors });
    }
  }

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      let errors = this.state.errors;
      errors.email = "Please enter valid email."
      this.setState({ errors: errors });
    } else {
      let errors = this.state.errors;
      errors.email = ""
      this.setState({ errors: errors });
    }
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    if (event.target.name === "first_name") {
      if (event.target.value.length >= 2) {
        const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      }
    }
    if (event.target.name === "phone_number") {
      if (event.target.value.length > 8) {
        var errors = this.state.errors;
        errors.phone_number = "";
        this.setState({ errors: errors });
      }
      if (event.target.value.length > 15 || event.target.value.length < 8) {
        var errors = this.state.errors;
        errors.phone_number = "The phone number must be a valid phone number";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "email") {
      if (event.target.value.length > 7) {
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.email = "Please enter valid email.";
          this.setState({ errors: errors });
        } else {
          errors.email = "";
          this.setState({ errors: errors });
        }
      }
    }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  }

  changeDateHandlerNew = async (date) => {
    var startDate = date.value[0] ? Moment(date.value[0]).format("YYYY-MM-DD") : "";
    var endDate = date.value[1] ? Moment(date.value[1]).format("YYYY-MM-DD") : "";
    await this.setState({ startDate: startDate, endDate: endDate });
    await this.createQueryString();
    await this.creatAirBnbQueryString();
  };

  openImageModal = (mainImage) => {
    mainImage = mainImage ? mainImage : global.OnKeyPressEvent.getS3ImageUrl('str-d-list-1.jpg');
    this.setState({ openImagepopup: true, unitImagemodal: mainImage });
  }

  async getFloorplanData(inputData1 = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var totalRecords = 0;
    var city = [];
    let customInputs = {
      property_seo_slug: this.state.property_slug
        ? this.state.property_slug
        : "",
      city_id: this.state.location ? parseInt(this.state.location) : "",
      // city_id: this.state.location ? parseInt(this.state.location) : "",
      Furnished: ["Unfurnished"],
      Move_in_date: this.state.startDate === "" || this.state.move_in_date === "undefined" || this.state.move_in_date === "Invalid date" ? Moment(new Date()).format('YYYY-MM-DD') : Moment(this.state.startDate).format("YYYY-MM-DD"),
      Bedroom: this.state.bedroomcount ? [this.state.bedroomcount] : [1],
      Bathroom: this.state.bathCount ? [this.state.bathCount] : [1],
      isquery: 1,
    }
    // let inputData = {

    // };
    let inputData = inputData1 ? inputData1 : customInputs;
    // let res = await FloorPlanApi.getUnitlisting(inputData);
    // console.log(res);
    this.setState({ Skeleton: true })
    let res = await FloorPlanApi.getFloorPlanlisting(inputData);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.getFloorplanData : [];

      totalRecords = list ? list.length : 0;
      city = res.other_data ? res.other_data : [];
    } else {
      this.setState({ Skeleton: false })
    }
    city.map((item, index) => {
      item.label = city[index].city.city_name;
      item.value = city[index].city.city_id;
    });
    this.setState({ newcityList: city });
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      FloorPlanListing: list,
      Skeleton: false,
      displaySearchResult: true
    });

  }

  openvirtualtour = (val = false) => {
    this.setState({ openvirtualTourpopup: true });
  }

  openAllAmenitiesModal = (val = false) => {
    this.setState({ openAllAmenitiesModal: val });
  }

  openHealthAndSafetyModal = (val = false) => {
    this.setState({ openHealthAndSafetyModal: val });
  }

  docCalcModal = (val = false) => {
    this.setState({ docCalcModal: val });
  }


  openGettingAroundModal = (val = false) => {
    this.setState({ openGettingAroundModal: val });
  }

  showSocialLinks = (val = false) => {
    this.setState({ showSocialLinks: val });
  }

  closeImageModal() {
    this.setState({ openImagepopup: false });
  }

  closevirtualtour() {
    this.setState({ openvirtualTourpopup: false });
    this.setState({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    });
    this.setState({
      errors: {
        email: "",
        phone_number: "",
        first_name: "",
      }
    });
  }

  createQueryString = () => {
    var queryString = "";
    queryString = `?checkin=${this.state.startDate}&checkout=${this.state.endDate}&numberOfAdults=${this.state.adultCount}&numberOfChildren=${this.state.childCount}&numberOfInfants=${this.state.infantCount}&numberOfPets=${this.state.petsCount}&listing=${this.state.unit_slug}`;
    this.setState({ queryString: queryString });
    this.creatAirBnbQueryString();
  }

  creatAirBnbQueryString = () => {
    var queryString = "";
    var guest =
      parseInt(this.state.childCount) + parseInt(this.state.adultCount);
    guest = guest === 0 ? 1 : guest;
    queryString = `?check_in=${this.state.startDate}&guests=${guest}&adults=${this.state.adultCount}&check_out=${this.state.endDate}&children=${this.state.childCount}&infants=${this.state.infantCount}&pets=${this.state.petsCount}`;
    this.setState({ airBnbQueryString: queryString });
  }

  checkAvailabilityDate = () => {
    if (this.state.startDate && this.state.endDate) {
      var inputData = {
        listing_slug: this.state.unit_slug,
        checkin_date: this.state.startDate,
        checkout_date: this.state.endDate,
        user_token: this.state.userToken,
      };
      this.getAvailibityDate(inputData);
      this.createQueryString();
    }
  }

  guestDetail = () => {
    var message = "";
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Childs"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    this.setState({ guestValue: message });
    // return message;
  }

  async getUnitData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var list = [];
    var slug = this.state.unit_slug;
    var totalRecords = 0;
    var propertyAmenityList = [];
    var propertyAmenityTotal = 0;
    var cacheKeyName =
      global.theme +
      "_UNIT_DETAILS_DATA_" +
      this.state.property_slug +
      this.state.unit_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data =
        global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
          ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
          : localStorage.removeItem(cacheKeyName);
      this.setState({
        total_record: data.list,
        UnitListing: data,
      });
    }
    let res = await LtrlistingApi.getFloorplandetail(slug);
    //let res = await UnitlistingApi.getUnitlisting();
    if (global.successStatus.includes(res.status)) {
      resData.unit_number = res.data ? res.data.property_floor_plan_name : "";
      resData.title = res.data ? res.data.property_floor_plan_name : "";
      resData.currentFloorplanSlug = res.data ? res.data.slug : "";
      resData.floorplan_description = res.data ? res.data.marketing_description : "This beautiful building allows you to homeshare your apartment when you're not there — whether that be for the summer, long weekends away, or just any time you want. Just check out your resident app, tell it when you're not going to be home — and make money living here!";
      // resData.description = res.data ? res.data.marketing_description.substring(0, 300) : "";
      // resData.keywords = res.data ? res.data.marketing_description : "";
      resData.nightly_rate = res.data ? res.data.base_price : "";
      // resData.total_calculation = res.data ? res.data.base_price : "";

      // resData.floorplanPrice = res.data ? res.data.base_price : "";
      resData.balcony = res.data.floor_plan ? res.data.floor_plan.balcony : "";
      resData.bathroom = res.data ? res.data.bath : "";
      resData.bedroom = res.data ? res.data.bedroom : "";
      resData.interior = res.data ? res.data.interior : "";
      resData.square_foot = res.data ? res.data.square_foot : "";
      resData.latitude = res.data
        ? res.data.property
          ? res.data.property.latitude
          : ""
        : "";
      resData.property_name = res.data.property ? res.data.property.property_name : "";
      resData.brand_name = res.data.property ? res.data.property.brand.brand_name : "";
      // resData.favicon = res.data.property ? res.data.property.brand.brand_logo_thumb_url : "";
      resData.brand_logo = res.data.property ? res.data.property.brand.brand_logo_thumb_url : "";
      resData.property_logo = res.data.property ? res.data.property.property_logo_medium_url : "";
      resData.guest = res.data.maximum_number_of_guests ? res.data.maximum_number_of_guests : "";
      resData.mainImage = res.data.is_main_image ? res.data.is_main_image.large_img_w_720 : "";
      resData.image = res.data.is_main_image ? res.data.is_main_image.medium_img : "";
      resData.property_main_image = res.data.property ? res.data.property.property_main_image_url : "";
      resData.adr_updated_on = res.data ? res.data.updated_at : "";
      resData.video_url = res.data ? res.data.video_url : "";
      resData.Lease_Inquiry_URL = res.data ? res.data?.property.lease_inquiry_url:"";
      resData.longitude = res.data
        ? res.data.property
          ? res.data.property.longitude
          : ""
        : "";
      resData.google_map_link = res.data
        ? res.data.property
          ? res.data.property.google_map_link
          : ""
        : "";
      resData.state_name = res.data
        ? res.data.property
          ? res.data.property.state
            ? res.data.property.state.state_name
            : ""
          : ""
        : "";
      resData.country_name = res.data
        ? res.data.property
          ? res.data.property.country
            ? res.data.property.country.country_name
            : ""
          : ""
        : "";
      resData.city_name = res.data
        ? res.data.property
          ? res.data.property.city
            ? res.data.property.city.city_name
            : ""
          : ""
        : "";
      resData.street_1 = res.data
        ? res.data.property
          ? res.data.property.street_1
          : ""
        : "";
      resData.street_2 = res.data
        ? res.data.property
          ? res.data.property.street_2
          : ""
        : "";
      resData.zip_code = res.data
        ? res.data.property
          ? res.data.property.zip_code
          : ""
        : "";
      resData.property_seo_slug = res.data
        ? res.data.property
          ? res.data.property.city
            ? res.data.property.city.property_seo_slug
            : ""
          : ""
        : "";

      //  resData.total_calculation = this.getAdrPrice(resData.bedroom ? resData.bedroom : 1 ,resData.bathroom ? resData.bathroom : 0,resData.property_seo_slug ?resData.property_seo_slug : this.state.property_slug)*7;
      resData.total_calculation = (res.data.adr_value ? res.data.adr_value : 176) * 7;
      // resData.total_calculation = res*7;
      // resData.media_files = res.data
      //   ? res.data.media_files
      //   : "";

      if (resData.mainImage === "") {
        var mediaFiles = res.data ? res.data.media_files : "";
        if (mediaFiles !== "" && mediaFiles.length > 0) {
          mediaFiles.map((item, index) => {
            if (index === 0) {
              resData.mainImage = item.large_img_w_720;
              item.is_main_img = 1;
            }
          });
        }
        await this.setState({ media_files: mediaFiles });
      } else {
        await this.setState({ media_files: res.data ? res.data.media_files : "" });
      }
      totalRecords = list ? list.length : 0;
      propertyAmenityList = {};
      res.data.propertyAmenity.map((index, value) => {
        if (propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']] !== undefined) {
          propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']].push(
            {'name':res.data.propertyAmenity[value]['amenity_name'],"icon":res.data.propertyAmenity[value]['amenity_icon']}
          );
        } else {
          propertyAmenityList[res.data.propertyAmenity[value]['amenity_type_name']] = [
            {'name':res.data.propertyAmenity[value]['amenity_name'],"icon":res.data.propertyAmenity[value]['amenity_icon']}
          ];
        }
      })
      propertyAmenityTotal = res.data.propertyAmenity
        ? res.data.propertyAmenity.length
        : 0;

      this.setState({
        propertyAmenityList: propertyAmenityList,
        propertyAmenityTotal: propertyAmenityTotal,
      });

    }
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    await this.setState(resData);
    this.setState({
      showSpinner: true,
      seoLoaded: true,
      loading: true,
      total_record: totalRecords,
      property: list.property,
      property_name: resData.property_name,
    });
  }

  async getUnitDataFloorplan(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var Bathroom = 0;
    var Bedroom = 0;
    var Amenity = [];
    var totalRecords = 0;
    var slug_type = {
      property_seo_slug: this.state.property_slug,
      floorplan_slug: this.state.unit_slug,
    };
    let res = await UnitlistingApi.getUnitlisting(slug_type);

    if (global.successStatus.includes(res.status)) {
      list = res.data.getUnitData ? res.data.getUnitData : [];
      totalRecords = list ? list.length : 0;
      Bathroom = res.data.getUnitData[0].bath
        ? res.data.getUnitData[0].bath
        : 0;
      Bedroom = res.data.getUnitData[0].bedroom
        ? res.data.getUnitData[0].bedroom
        : 0;
      Amenity = res.data.amentyArray ? res.data.amentyArray : [];
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      //availablity_modal_box: true,
      UnitListingfloorplan: list,
      UnitBedroom: Bedroom,
      UnitBathroom: Bathroom,
      UnitAmenity: Amenity,
    });
  }

  randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  }



  openModal = () => {
    this.setState({ availablity_modal_box: false });
  }

  openGalaryModal = () => {
    this.setState({ openGalaryModal: true });
  }

  closeGalaryModal = () => {
    this.setState({ openGalaryModal: false });
  }

  Setclose(e) {
    var unit = document.getElementById("dropdown-basic");
    unit.click();
  }

  handleChange = (event) => {
    this.setState({ personName: event.target.value });
  }

  handleDateChange = async (date, name) => {
    let input = this.state.input;
    let a = new Date(date.valueText);
    input.move_in_date = Moment(new Date(date.value)).format("MM-DD-YYYY");
    await this.setState({ selectedDate: input.move_in_date, input: input });
    await this.createQueryString();
  }


  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }

  async incrementAdult() {
    await this.setState({ adultCount: this.state.adultCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({ adultCount: this.state.adultCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({ childCount: this.state.childCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({ childCount: this.state.childCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementpets() {
    await this.setState({ petsCount: this.state.petsCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementpets() {
    if (this.state.petsCount === 0) {
      await this.setState({ petsCount: 0 });
    } else {
      await this.setState({ petsCount: this.state.petsCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  closeAvalModal() {
    this.setState({ availablity_modal_box: false });
  }

  onDateChange = async (startDate, endDate) => {
    var previousStartDate = this.state.startDate;
    var previousEndDate = this.state.endDate;
    var varStartDate = "";
    var varEndDate = "";
    if (startDate === null && endDate === null) {
      this.setState({ startDate: "", endDate: "", minDate: new Date() });
    } else if (
      Moment(startDate).format("YYYY-MM-DD") ===
      Moment(endDate).format("YYYY-MM-DD")
    ) {
      this.setState({
        startDate: Moment(startDate).format("YYYY-MM-DD"),
        endDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
        minDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
      });
    } else if (
      previousStartDate === Moment(startDate).format("YYYY-MM-DD") &&
      previousEndDate === Moment(endDate).format("YYYY-MM-DD")
    ) {
      this.setState({
        startDate: Moment(startDate).format("YYYY-MM-DD"),
        endDate: Moment(endDate).format("YYYY-MM-DD"),
        minDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
      });
    } else if (
      previousStartDate !== Moment(startDate).format("YYYY-MM-DD") &&
      previousEndDate === Moment(endDate).format("YYYY-MM-DD")
    ) {
      this.setState({
        startDate: Moment(startDate).format("YYYY-MM-DD"),
        endDate: Moment(endDate).format("YYYY-MM-DD"),
        minDate: Moment(startDate).add(1, "days").format("YYYY-MM-DD"),
      });
    } else {
      if (startDate !== null) {
        varStartDate = Moment(startDate).format("YYYY-MM-DD");
        this.setState({ startDate: varStartDate, minDate: varStartDate });
      }
      if (endDate !== null) {
        varEndDate = Moment(endDate).format("YYYY-MM-DD");
        this.setState({ endDate: varEndDate, minDate: new Date() });
      }
      if (endDate === null) {
        varStartDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
        this.setState({ endDate: varStartDate, minDate: new Date() });
      }
      if (previousStartDate !== varStartDate) {
        this.setState({ minDate: varStartDate });
      }
    }
    this.setState({ message: "" });
    this.creatAirBnbQueryString();
    await this.createQueryString();
  }

  manageCelendaryPopUp = (value = false) => {
    this.setState({ openvirtualTourpopup: false });
    this.setState({ celendaryPopUp: value });
  }


  openModal(src) {
    this.setState({ isOpen: true })
    this.setState({ currentVideoUrl: src })
  }

  showModal = (val = false, param = 'show') => {
    this.setState({ [param]: val });
  };



  render() {
    const [start, startRef] = "";
    const [end, endRef] = "";
    let counter = 0;

    {
      /* meta data for index page for seo friendly url */
    }
    if (this.state.openGalaryModal) {
      return (
        <Gallery
          media_files={this.state.media_files}
          closeFun={this.closeGalaryModal.bind(this)}
        />
        // <GallaryView mediaList={this.state.media_files} closeFun={this.closeGalaryModal.bind(this)} />
      );
    } else {
      return (
        <React.Suspense fallback={<p>Loading list...</p>}>
          <React.Fragment>
            {/* Meta Tag Start */}
            {
              this.state.seoLoaded &&
              <MetaSEO
                title={this.state.title}
                favicon={this.state.favicon}
                keywords={this.state.keywords}
                noIndex={"true"}
                description={this.state.description}
                url={this.state.url}
                image={this.state.image}
                fb_app_id={this.state.fb_app_id}
                author={"OHAI"}
              />
            }
            {/* Meta Tag End */}
            <main className="oh-template Brandmainpage detail_page_ltr ltr_overflow">
              {/* Header */}
              {/* <CommonHeader propertySlug={this.state.property_slug} /> */}
              {this.state.webview === 1 ? (
                <CommonHeader propertySlug={this.state.property_slug} />
              ) : (
                ""
              )}
              {/* middle */}
              {this.state.UnitListing ? (
                // this.state.UnitListing.map((item, i) => {
                // return (
                <>
                  <section className="middle">
                    <div className="mainstrdetailContainer p-0">
                      <div className="mainstrDetail">


                        {this.state.media_files &&
                          this.state.media_files.length > 0 ? (
                          <section className="strImgLising deskview">
                            <>
                              <div className="col-m-12 pd-l-30 pd-r-30">
                                <div className="row m-0 strSection">
                                  <div className="col-lg-6 pd-t-0 pd-b-0 pd-l-6 pd-r-0 mainstrviewimag">
                                    <figure
                                      className="mainImg"
                                    // key={index}
                                    >
                                      <img
                                        onClick={this.openGalaryModal}
                                        src={
                                          this.state.mainImage
                                            ? this.state.mainImage
                                            : this.state.property_main_image
                                        }
                                        // alt="Main Image"
                                        // title="Main Image"
                                      />
                                    </figure>
                                    {/* );
                                        }
                                      }
                                    )} */}
                                  </div>
                                  <div className="col-lg-6  pd-t-0 pd-b-3 pd-l-0 pd-r-0 strviewimag">
                                    <ul>
                                      {this.state.media_files.map(
                                        (value, index) => {
                                          if (
                                            counter <= 3 && index !== 0
                                          ) {
                                            return (
                                              <li key={index}>
                                                <figure>
                                                  <span className="d-none">{counter++}</span>
                                                  <img
                                                    onClick={
                                                      this.openGalaryModal
                                                    }
                                                    src={
                                                      value.medium_img
                                                        ? value.medium_img
                                                        : global.OnKeyPressEvent.getS3ImageUrl('str-d-list-2.jpg')
                                                    }
                                                    // alt={
                                                    //   "Other Image " + index
                                                    // }
                                                    // title={
                                                    //   "Other Image " + index
                                                    // }
                                                  />
                                                </figure>
                                              </li>
                                            );
                                          }
                                        }
                                      )}
                                    </ul>
                                    {this.state.media_files.map(
                                      (value, index) => {
                                        if (counter > 3) {
                                          return (
                                            <p
                                              key={index}
                                              className="Showmore mt-2 btn btn-link"
                                              onClick={this.openGalaryModal}
                                            >
                                              <img src={global.OnKeyPressEvent.getS3ImageUrl('showmore-gallary.png')} alt="Show More" title="Show More" />
                                              Show More
                                            </p>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          </section>
                        )
                          :
                          <div className="container d-flex flex-wrap w-100 pd-l-35 pd-r-35 skeletonGallerymob">
                            <div className="col-md-6">
                              <Skeleton variant="rectangular" width="100%" height={412} />
                            </div>
                            <div className="col-md-6 justify-content-center row m-0 skeletonGallerythumbmob">
                              <div className="col-md-12- row m-0 pd-l-3 pd-r-0">
                                <div className="col-md-6 d-flex flex-wrap pd-l-3 pd-r-3 justify-content-between flex-column">
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                </div>
                                <div className="col-md-6 d-flex flex-wrap pd-l-3 pd-r-3 justify-content-between flex-column">
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                  <Skeleton variant="rectangular" width="100%" height={203} />
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        {this.state.media_files &&
                          this.state.media_files.length > 0 && (
                            <section className="strImgLising mobview">
                              <>
                                <div className="col-m-12 ">
                                  <div className="row m-0 strSection">
                                    <div className="col-lg-6 mg-t-8 pd-b-3 pd-l-6 pd-r-0 mainstrviewimag">
                                    {this.state.webview === 1 ? (
                                      <div className=" d-flex align-items-center justify-content-between shareMobileview">
                                     
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} onClick={this.backToPage} alt="Back" title="Back" />
                                        <span className="share">
                                          <Link
                                            to={"#"}
                                            onClick={() =>
                                              this.showSocialLinks(true)
                                            }
                                          >
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('share-icon.svg')} alt="Share" title="Share" />
                                            <b>Share</b>
                                          </Link>
                                        </span>
                                      </div> ) : (
                                            ""
                                          )}

                                      <div className="str_heading_top">
                                    <div className="property_detail">
                                    <div className="property_heading p-0">
                                    <h4 className="listing_title p-0">{`${this.state.property_name ? this.state.property_name : ""}`}</h4>

                                    <h5 className="m-0">
                                      <a
                                        href={
                                          this.state.google_map_link
                                            ? this.state.google_map_link
                                            : ""
                                        }
                                        target="_blank"
                                      >
                                        {this.state.city_name
                                          ? this.state.city_name + ","
                                          : " "}{" "}
                                        {this.state.state_name
                                          ? this.state.state_name + ","
                                          : ""}{" "}
                                        {this.state.country_name
                                          ? this.state.country_name
                                          : ""}

                                      </a> </h5>
                                  </div>
                                    </div>
                                    </div>     
                                      <figure
                                        className="mainImg"
                                      >
                                        <img
                                          src={
                                            this.state.mainImage
                                              ? this.state.mainImage
                                              : this.state.property_main_image
                                          }
                                        />
                                      </figure>

                                    </div>
                                    <div className="col-lg-6  pd-t-0 pd-b-3 pd-l-0 pd-r-0 strviewimag">
                                      <ul>
                                        {this.state.media_files.map(
                                          (value, index) => {
                                            if (

                                              index <= 0 && value.is_main_img !== 1
                                            ) {
                                              return (
                                                <li key={index}>
                                                  <figure>
                                                    <img
                                                      src={
                                                        value.medium_img
                                                          ? value.medium_img
                                                          : this.state.property_main_image
                                                      }
                                                    />
                                                  </figure>
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                      </ul>
                                      {this.state.media_files.map(
                                        (value, index) => {
                                          if (index >= 1) {
                                            return (
                                              <p
                                                key={index}
                                                className="Showmore mt-2 btn btn-link"
                                                onClick={this.openGalaryModal}
                                              >
                                                <img src={global.OnKeyPressEvent.getS3ImageUrl('showmore-gallary.png')} alt="Show More" title="Show More" />
                                                Show More
                                              </p>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            </section>
                          )}

                        <section className="strDetail mg-b-10 str_property_info ltr_pro_detail">
                          <div className="col-m-12 pd-l-35 pd-r-35">
                            <div className="row">
                              <div className="col-md-6">
                               

                                  <div className="col-12  str_heading_top strnewdeskview">
                                    <div className="property_detail d-flex justify-content-start align-items-start">
                                    <div className="partnered_img mg-r-15">
                                        <figure>
                                          <img src={this.state.brand_logo ? this.state.brand_logo : this.state.property_logo} alt="" />
                                        </figure>
                                       </div>
                                        <div className="property_heading p-0">
                                           <h4 className="listing_title">
                                              {this.state.unit_number ? (
                                                this.state.unit_number
                                              ) : (
                                                <Skeleton
                                                  variant="rectangular"
                                                  width="100%"
                                                  height={50}
                                                />
                                              )}
                                           </h4>
                                          
                                           <h4 className="listing_title p-0 ltr-listing_title">{`${this.state.property_name ? this.state.property_name : ""}`}</h4>
                                            <h5 className="m-0 ltr-location">
                                              <a
                                                href={
                                                  this.state.google_map_link
                                                    ? this.state.google_map_link
                                                    : ""
                                                }
                                                target="_blank"
                                              >
                                                {this.state.city_name
                                                  ? this.state.city_name + ","
                                                  : " "}{" "}
                                                {this.state.state_name
                                                  ? this.state.state_name + ","
                                                  : ""}{" "}
                                                {this.state.country_name
                                                  ? this.state.country_name
                                                  : ""}

                                              </a> 
                                            </h5>
                                        </div>
                                    </div>
                                   </div>

                                <div className="property_detail">
                                 
                                  <div className="Property_info">
                                    <div className="property_size">
                                      <ul className="property_size_brd">
                                        <li>{`${this.state.bedroom > 1 ? this.state.bedroom + " Bedrooms" : this.state.bedroom + " Bedroom"} / ${this.state.bathroom > 1 ? this.state.bathroom + " Bathrooms" : this.state.bathroom + " Bathroom"}`}</li>
                                        <li>{"Sleeps " + (this.state.guest > 1
                                          ? this.state.guest : "1 ") + " People"}</li>
                                        <li>{`${this.state.square_foot
                                          ? parseInt(this.state.square_foot)
                                          : ""
                                          } Sq ft`}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>


                                <div className="guest-availabity-review pd-l-0 mg-b-15 ">
                                  <ul className="d-none">
                                    <>
                                      {this.state.property ?
                                        (
                                          <>
                                            <li>
                                              <img
                                                src={
                                                  global.theme === "kini"
                                                    ? global.OnKeyPressEvent.getS3ImageUrl('p-star.png')
                                                    : global.OnKeyPressEvent.getS3ImageUrl('r-star.png')
                                                }
                                                className="mg-r-5"
                                              />{" "}
                                              5.0
                                            </li>
                                            <li>
                                              <Link to={"#"}>323 reviews</Link>
                                            </li>
                                            <li>
                                              <a
                                                href={
                                                  this.state.property
                                                    ? this.state.property
                                                      .google_map_link
                                                    : ""
                                                }
                                                target="_blank"
                                                className="text-decoration-none "
                                              >
                                                {this.state.property.city
                                                  ? this.state.property.city.city_name +
                                                  ", "
                                                  : ""}{" "}
                                                {this.state.property.state
                                                  ? this.state.property.state
                                                    .state_name + ", "
                                                  : ""}
                                                {this.state.property.country
                                                  ? this.state.property.country
                                                    .country_name
                                                  : ""}
                                              </a>
                                            </li>
                                          </>
                                        )
                                        :
                                        (
                                          <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height={50}
                                          />
                                        )
                                      }
                                    </>
                                  </ul>
                                </div>
                                {this.state.floorplan_description ?
                                  <>
                                    <HTMLEllipsis
                                      unsafeHTML={
                                        this.state.floorplan_description
                                          ? this.state.floorplan_description
                                          : ""
                                      }
                                      maxLine="1000"
                                      ellipsis="..."
                                      trimright="true"
                                      className="white-space-pre-wrap ltr_pro_desp"
                                      basedOn="letters"
                                    />
                                  </>
                                  :
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      width="100%"
                                      height={150}
                                    />
                                  </>
                                }



                                <div className="miscSection left whatthisPlace p-0">
                                  <div className="commanBlockdetail col-lg-12 border-bottom-">
                                    <div className="col-lg-12 pd-l-0 pd-r-15 mg-t-30 mg-b-30 mg-sm-b-3 guestBlock">
                                      <h5 className="text-left-cust-str">What this place offers </h5>
                                      <div className="aminitiesDeskview">
                                        <div className="amenitiesBlock">
                                          {this.state.propertyAmenityTotal > 0
                                            ? Object.keys(this.state.propertyAmenityList).map(
                                              (opt, i) => {
                                                if (i < 4) {
                                                  return (
                                                    <div key={i} className="aminty_box_break">
                                                      <ul>
                                                        {this.state.propertyAmenityList[opt].map((k, v) => {
                                                          return (
                                                            <li key={v}><img src={k.icon}></img>{k.name}</li>
                                                          );

                                                        }
                                                        )}
                                                      </ul>
                                                    </div>
                                                  )
                                                }
                                              }
                                            )
                                            : ""}

                                        </div>
                                        {this.state.propertyAmenityTotal > 10 ? (
                                          <button
                                            className="btn btn-link Showmore mg-b-30"
                                            onClick={() =>
                                              this.openAllAmenitiesModal(true)
                                            }
                                          >
                                            Show all amenities
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="aminitiesMobview">
                                        <div className="amenitiesBlock">
                                          {this.state.propertyAmenityTotal > 0
                                            ? Object.keys(this.state.propertyAmenityList).map(
                                              (opt, i) => {
                                                return (
                                                  <div key={i} className="aminty_box_break">
                                                    <ul>
                                                      {this.state.propertyAmenityList[opt].map((k, v) => {
                                                        return (
                                                          <li key={v}><img src={k.icon}></img>{k.name}</li>
                                                        );

                                                      }
                                                      )}
                                                    </ul>
                                                  </div>
                                                )

                                              }
                                            )
                                            : ""}
                                        </div>
                                        {
                                          this.state.propertyAmenityTotal > 5 ? (
                                            <button
                                              className="btn btn-link Showmore"
                                              onClick={() =>
                                                this.openAllAmenitiesModal(true)
                                              }
                                            >
                                              Show all amenities
                                            </button>
                                          ) : (
                                            ""
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="guest-availabity guest-availabity-deskview position-relative leaseMobile">
                                  <div className="d-flex justify-content-between align-items-center mg-b-30 mobigustavi">
                                    <h4 className="listing_title">
                                      {this.state.nightly_rate
                                        ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal(parseInt(this.state.nightly_rate)) : ""}
                                      <b>{this.state.nightly_rate ? "/month" : ""} </b>
                                    </h4>
                                      <span className="share">
                                        <Link
                                          to={"#"}
                                          onClick={() =>
                                            this.showSocialLinks(true)
                                          }
                                        >
                                          <img src={global.OnKeyPressEvent.getS3ImageUrl('share-icon.svg')} alt="Share" title="Share" />
                                          <b>Share</b>
                                        </Link>
                                      </span>
                                  </div>
                                 

                                  {this.state.redirectToNextPage ? (
                                    <>
                                      <a
                                        href={this.state.UnitListing.book_redirect_to
                                          ? this.state.UnitListing
                                            .book_redirect_to +
                                          this.state.airBnbQueryString
                                          : ""}
                                        target="_blank"
                                        className="text-decoration-none"
                                      >
                                        <button className="guest-avaialbility-btn m-auto">
                                          Reserve
                                        </button>
                                      </a>
                                    </>
                                  ) : (
                                    <button
                                      className="guest-avaialbility-btn m-auto"
                                      onClick={() =>
                                        this.openvirtualtour()
                                      }
                                    >
                                      Contact the property
                                    </button>
                                  )}
                                  <div className="miscSection leaseAvailable p-0">
                                    <div className="sectionOne">
                                      <Accordion defaultActiveKey="0">

                                        <>
                                          <Accordion.Item eventKey="0">
                                            {this.state.UnitListingfloorplan !== "" ? (

                                              <Accordion.Header>
                                                <h5>Lease availability </h5>
                                              </Accordion.Header>

                                            ) : ""}

                                            <Accordion.Body>
                                              <section className="leaseAvailability">
                                                <div className="col-12 col-md-12 col-lg-9">
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <th>Unit</th>
                                                        <th>Rent</th>
                                                        <th>Available</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {this.state.UnitListingfloorplan ? (
                                                        this.state.UnitListingfloorplan.map((item, i) => {
                                                          return (
                                                            <tr key={i}>
                                                              <td>{item.unit_number}</td>
                                                              <td> {item.base_price ? `$${global.OnKeyPressEvent.numberWithCommasForDecimal(parseInt(item.base_price))}/month`:""}</td>
                                                              {/* //<td>${item.base_price}</td> */}
                                                              {/* <td>21 Nov</td> */}
                                                              <td>{item.available_from > this.state.selectedDate ? Moment(item.available_from).format("D MMM") : Moment(this.state.selectedDate).format("D MMM")}</td>
                                                            </tr>
                                                          )
                                                        })) : ""}
                                                    </tbody>
                                                  </table>

                                                </div>
                                              </section>
                                            </Accordion.Body>

                                          </Accordion.Item>
                                        </>
                                       
                                      </Accordion>
                                    </div>
                                  </div>
                                </div>
                                <div className="guest-availabity guest-availabity-mobileview ">
                                  <div className="d-flex justify-content-between align-items-center mg-b-30 mobigustavi">
                                    <div className="d-flex flex-column w-50">
                                      <h3 className="text-left-cust">
                                        {this.state.nightly_rate
                                          ? "$" +
                                          global.OnKeyPressEvent.numberWithCommasForDecimal(parseInt(this.state.nightly_rate))
                                          : this.state.nightly_rate
                                            ? "$" +

                                            this.state
                                              .nightly_rate

                                            : ""}
                                        <b>/month</b>
                                      </h3>
                                    </div>
                                    <button
                                      className="guest-avaialbility-btn m-auto"
                                      onClick={() =>
                                        this.openvirtualtour()
                                      }
                                    >
                                      Contact the property
                                    </button>
                                    {/* ) */}
                                    {/* } */}
                                  </div>
                                </div >
                              </div >
                            </div >
                          </div >

                        </section >
                      </div>
                    </div >
                  </section >

                  <section className="earn_aptmt_sec">
                    <div className="container position-relative">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="can_earn">
                            <h3 className="earn_heading">What can I earn homesharing my apartment?</h3>
                            <p className="earn_para">You can homeshare your place for up to <b>365 nights per year.</b></p>
                          </div>
                          <div className="can_earn_bg"></div>
                        </div>
                        <div className="col-md-8">
                          <div className="earn_acordion">
                            <div className="miscSection leaseAvailable p-0">
                              <div className="sectionOne">
                                <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                                  <Accordion.Item eventKey="0">
                                    {/* {this.state.FloorPlanListing !== "" ? ( */}

                                    <Accordion.Header>
                                      <p>What kind of apartment?</p>
                                    </Accordion.Header>

                                    {/* ) : ""} */}


                                    <Accordion.Body>

                                      <section className="earn_rate">
                                        <div className="col-12 col-md-12 col-lg-12">
                                          <FormControl>
                                            {this.state.currentFloorplanSlug &&
                                              <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={this.state.currentFloorplanSlug}
                                                name="add"
                                                onChange={(event) => this.changeHandlerSelect(event)}
                                              >
                                                {this.state.FloorPlanListing.map((item, i) => (
                                                  <MenuItem key={i} value={item.floorplan_slug}>{item.floorplan_name} &nbsp;- from ${item.base_price ? global.OnKeyPressEvent.numberWithCommasForDecimal(parseInt(item.base_price)):""}/month</MenuItem>
                                                ))}
                                              </Select>
                                            }
                                          </FormControl>
                                        </div>
                                      </section>
                                    </Accordion.Body>

                                  </Accordion.Item>

                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                      <p>How many nights booked will you have per month?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <section className="earn_rate">
                                        <div className="col-12 col-md-12 col-lg-12">
                                          <FormControl  sx={{ m: 1, minWidth: 120 }}>
                                            <Select
                                              labelId="demo-select-small"
                                              id="demo-select-small"
                                              value={this.state.currentFloorplanNight}
                                              name="adddate"
                                              onChange={(event) => this.changeHandlerSelectmonth(event)}
                                            >
                                              <MenuItem value="1">1 Night</MenuItem>
                                              <MenuItem value="2">2 Nights</MenuItem>
                                              <MenuItem value="3">3 Nights</MenuItem>
                                              <MenuItem value="4">4 Nights</MenuItem>
                                              <MenuItem value="5">5 Nights</MenuItem>
                                              <MenuItem value="6">6 Nights</MenuItem>
                                              <MenuItem value="7">7 Nights</MenuItem>
                                              <MenuItem value="8">8 Nights</MenuItem>
                                              <MenuItem value="9">9 Nights</MenuItem>
                                              <MenuItem value="10">10 Nights</MenuItem>
                                              <MenuItem value="11">11 Nights</MenuItem>
                                              <MenuItem value="12">12 Nights</MenuItem>
                                              <MenuItem value="13">13 Nights</MenuItem>
                                              <MenuItem value="14">14 Nights</MenuItem>
                                              <MenuItem value="15">15 Nights</MenuItem>
                                              <MenuItem value="16">16 Nights</MenuItem>
                                              <MenuItem value="17">17 Nights</MenuItem>
                                              <MenuItem value="18">18 Nights</MenuItem>
                                              <MenuItem value="19">19 Nights</MenuItem>
                                              <MenuItem value="20">20 Nights</MenuItem>
                                              <MenuItem value="21">21 Nights</MenuItem>
                                              <MenuItem value="22">22 Nights</MenuItem>
                                              <MenuItem value="23">23 Nights</MenuItem>
                                              <MenuItem value="24">24 Nights</MenuItem>
                                              <MenuItem value="25">25 Nights</MenuItem>
                                              <MenuItem value="26">26 Nights</MenuItem>
                                              <MenuItem value="27">27 Nights</MenuItem>
                                              <MenuItem value="28">28 Nights</MenuItem>
                                              <MenuItem value="29">29 Nights</MenuItem>
                                              <MenuItem value="30">30 Nights</MenuItem>
                                              <MenuItem value="31">31 Nights</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </section>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>

                                <div className="earn_detail">
                                  <p>Your potential revenue by AirDNA</p>
                                  <h5>Earn ${global.OnKeyPressEvent.numberWithCommasForDecimal(this.state.total_calculation)} a month</h5>
                                  <a href="javascript:void(0)" onClick={this.docCalcModal} >How are estimations calculated?</a>
                                </div>
                                <div className="earn_detail">
                                  <p><i>ADR Prices Updated on: {this.state.adr_updated_on ? Moment(this.state.adr_updated_on).format("MM/DD/YY") : ""}</i></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="ltr_video_sec ltr_detail_video">
                    <div className="container-fluid ">
                      <div className="row">
                        <div className="col-lg-6 col-sm-12 p-0">
                          <div className="video_step">
                            {/* <h3 className="video_main_head">Homesharing is easy with your OHAI Concierge</h3> */}
                            <h3 className="video_main_head">Homesharing is easy with the OHAI app</h3>
                            <div className="row">
                              <div className="col-12">
                                {/* <div className="d-flex video_earn_img">
                                  <figure>
                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('concierge.png')} alt="" />
                                  </figure>
                                  <div className="video_earn">
                                    <h5>Concierge support 24/7</h5>
                                    <p>Day or night, rain or shine, you and your guests have direct access to a concierge team.</p>
                                  </div>
                                </div> */}
                                <div className="d-flex video_earn_img">
                                  <figure>
                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('autokey.png')} alt="" />
                                  </figure>
                                  <div className="video_earn">
                                    <h5>Automated key access</h5>
                                    <p>The OHAI app automates access so residents, guests, and housekeeping all have automated key access at the right times.</p>
                                  </div>
                                </div>
                                <div className="d-flex video_earn_img">
                                  <figure>
                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('autohouse.png')} alt="" />
                                  </figure>
                                  <div className="video_earn">
                                    <h5>Automated housekeeping</h5>
                                    <p>The OHAI app automatically schedules the cleaners, provides their checklists, and checks their completion status.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 position-relative video_ltr_position">
                          <div className="video_earn_gallery">
                            <div className="video_pop_img">
                              <ModalVideo
                                id={(new Date() * 1).toString()}
                                source={this.state.source2}
                                poster={global.OnKeyPressEvent.getS3ImageUrl('OrionHausHomesharingProcessvideo.JPG', 'website/video')}
                                show={this.state.show2}
                                showModal={() => this.showModal(true, 'show2')}
                                handleClose={() => this.showModal(false, 'show2')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* new design Homesharing made easy */}

                  <section className="table_compare_sec">
                
                    <div className="container">
                      <div className="row">
                          <div className="tbl_cmp_heading">
                            <h2 className="oh_tbl_heading">Homesharing made easy</h2>
                              <p className="oh_tbl_para text-center pd-b-50">The OHAI app makes homesharing easy, by automating many operations.</p>
                          </div>
                          <div className="homesharingmadeeasy">
                          <div className="row m-0">
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-1.png')} alt="" />
                                          </figure>
                                        <span> Calendar management</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes_2.png')} alt="" />
                                          </figure>
                                        <span> Automated key access</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-3.png')} alt="" />
                                          </figure>
                                        <span> Automated housekeeper scheduling</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-4.png')} alt="" />
                                          </figure>
                                        <span> Automated messages</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-5.png')} alt="" />
                                          </figure>
                                        <span> Automated guest screening</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-6.png')} alt="" />
                                          </figure>
                                        <span> Automated house manual</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-7.png')} alt="" />
                                          </figure>
                                        <span> Automated property photos</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-8.png')} alt="" />
                                          </figure>
                                        <span> Direct Booking site</span>
                                    </div>
                                </div>
                          </div>
                        </div>
                    </div>
                    </div>
                  </section>
                  
                  {/* End new design Homesharing made easy */}

                  <div className="miscSection container">
                    {/* <div className="commanBlockdetail col-lg-12 border-bottom">
                    </div > */}
                    <div className="mapSection Ltrmapsection col-lg-12  pd-t-60 pd-b-60" >
                      <h5 className="m-0 p-0 text-left-cust-str">Where you’ll be</h5>
                      <span className="map_location">
                          {this.state.city_name
                            ? this.state.city_name + ", "
                            : ""}
                          {this.state.state_name
                            ? this.state.state_name + ","
                            : ""}{" "}
                          {this.state.country_name
                            ? this.state.country_name
                            : ""}

                        </span>
                      <div className="w-100 ">
                        <div className="w-100 mapblock">
                          <GoogleMap title={this.state.unit_number} lat={this.state.latitude} lng={this.state.longitude} propertyName={this.state.property_name} />
                          {/* <img src={locationMap} /> */}
                        </div>
                      </div>
                      <HTMLEllipsis
                        unsafeHTML={
                          this.state.getting_around
                            ? this.state.getting_around
                            : ""
                        }
                        maxLine="4"
                        ellipsis=""
                        trimright="true"
                        basedOn="letters"
                      />
                      {
                        this.state.getting_around ? (
                          <button
                            onClick={() => this.openGettingAroundModal(true)}
                            className="btn btn-link ShowmoreLink"
                          >
                            Show more
                          </button>
                        ) : (
                          ""
                        )
                      }
                    </div>
                  </div>

                  <section className="ltr_faq_sec">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="ltr_faq_heading">Frequently asked questions</h3>
                        </div>
                        {/* New faqs */}
                        <div className="col-md-6">
                          <div className="miscSection leaseAvailable p-0">
                            <div className="ltr_faq">
                              <Accordion>
                                <>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                    <h5>How do I get started homesharing? </h5>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                    <p>Once you are moved into your OHAI-partnered apartment community, you’ll be invited to the OHAI app which will guide you through creating your listing in a few steps.</p>
                                    </Accordion.Body>

                                  </Accordion.Item>
                                </>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <h5>What do I do with my personal belongings when guests are in my apartment?</h5>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                  <p>We recommend certain locks that you can use to secure your closet or cabinets.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    <h5>How will guests enter the building and my apartment?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                  <p>Key access is automatically created through the OHAI app.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <h5>How does cleaning work after guests leave?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p>Guest reservation cleaning is automatically scheduled through the OHAI app. </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                  <h5>How does pricing work?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p>You set your own pricing in the OHAI app.</p>
                                    <p>As part of this program, the building deducts a portion of your homesharing earnings. Reach out to the building manager to ask for more details.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header>
                                    <h5>Are there any other rules I should be aware of?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p>Buildings have required house rules for residents and guests including things like quiet hours. Homesharing here is exclusively through the OHAI homesharing program. All local laws apply, including all lease provisions.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="miscSection leaseAvailable p-0">
                            <div className=" sec_faq_ltr">
                              <Accordion>
                                <>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      <h5>I’m not enrolled in Airbnb because I am a renter. Can I still qualify? </h5>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                      <p>Yes, living at this property, and any OHAI-partnered property lets you homeshare.</p>
                                    </Accordion.Body>

                                  </Accordion.Item>
                                </>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <h5>What do I get to control on my listing?</h5>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                  <p>You control your pricing, your availability, your title and listing description, photography.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                    <h5>How do guests access my wifi?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                   <p>Guests can find your wifi inside of the house manual in their app or online.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <h5>When are earnings paid out?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                  <p>Payouts are sent on or before the 15th of the month for the prior month’s reservations, so long as your rent has been paid.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                    <h5>What is the application and move in process?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                  <p>On this page click on Contract the property and you’ll be connected to start the process.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="brandParent ltr_live_trav">
                    <div className="container">
                      <h2>Live and travel on your own terms. We know flexibility is key, and we’ll help you monetize your apartment while traveling for work or heading out for the weekend.</h2>
                    </div>
                    <section className="brandBlockthree">
                      <div className="container position-relative">
                        <div className="row">
                          <div className="col-lg-8">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl("brandblock-1.webp")} />
                            </figure>
                          </div>
                          <div className="col-lg-4">
                            <div className="content">
                              <h3>Flexible living at its best</h3>
                              <p>Flexible living at an OHAI community is super easy. Experience what it's like to make money when you're away traveling for work, heading out for vacation, or just spending time at a friends' place over the weekend. We make it easy to live better.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="brandBlockfour">
                      <div className="container position-relative">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="content">
                              <h3>Professionally managed.</h3>
                              <p>OHAI partnered buildings actually provide you service. Your OHAI app will automatically provide secure access to guests and housekeepers, will schedule turnovers with the cleaning crew, and provide you and your guests 24/7 concierge support — so you’re always taken care of at OHAI.</p>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl("brandblock-2.webp")} />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="brandBlockthree">
                      <div className="container position-relative">
                        <div className="row">
                          <div className="col-lg-8">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl("brandblock-3.webp")} />
                            </figure>
                          </div>
                          <div className="col-lg-4">
                            <div className="content">
                              <h3>Homesharing kits.</h3>
                              <p>Getting started is easy with our simple suggestions of everything you need to get started. Not sure what towels to get? OHAI has you covered.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>

                  <section className="ready_move_sec">
                    <div className="container">
                      <div className="row ready_mob-revert">
                        <div className="col-md-4">
                          <div className="ready_cnt">
                            <h3 className="ready_move_heading">Ready to move forward?</h3>
                            <button onClick={() => this.openvirtualtour()}>Contact the property</button>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="ready_box">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('ss1.png')} alt="" />
                            </figure>
                            <p>Use your OHAI app
                              to make your listing
                            </p>
                          </div>
                          <div className="ready_box">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('ss2.png')} alt="" />
                            </figure>
                            <p>Customize your photos
                              and pricing
                            </p>
                          </div>
                          <div className="ready_box">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('ss3.png')} alt="" />
                            </figure>
                            <p>Enjoy your days away,
                              we’ve got your back
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="ltr_partnered_sec">
                    <div className="container-fluid- container">
                      <div className="row">
                        <div className="col-md-6 p-0">
                          <div className="partnered_img">
                            <figure>
                              {/* <img src={global.OnKeyPressEvent.getS3ImageUrl('partnered_img.jpg')} alt="" /> */}
                              <img src={this.state.property_slug === "merino-scottsdale" ? global.OnKeyPressEvent.getS3ImageUrl('partnered_img.jpg') : this.state.property_main_image} alt="" />
                            </figure>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="partnered_cnt">
                            <h3 className="part_head">
                              OHAI partnered
                              apartment buildings
                            </h3>
                            <p className="part_ltr_para">OHAI partners with building owners to provide the ability to homeshare to all residents in their community. Property managers have full visibility into all hosting activity, guests, housekeeping, and maintenance.</p>
                            <button onClick={() => this.openvirtualtour()}>Contact the property</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>


                </>
              ) : (
                <Skeleton
                  sx={{ height: 190 }}
                  animation="wave"
                  variant="rectangular"
                />
              )
              }

              <section>
                <Modal
                  show={this.state.openImagepopup}
                  centered
                  className="unitmodal"
                  onHide={() => this.closeImageModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <span
                      className="welcomeClose float-end"
                      onClick={() => this.closeImageModal()}
                    >
                      <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} />
                    </span>
                    <div>
                      <figure className="mg-auto m-auto text-center">
                        <img
                          src={this.state.unitImagemodal}
                          alt={this.state.unitImagemodal}
                          className="w-100"
                        />
                      </figure>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openvirtualTourpopup}
                  centered
                  className="unitmodal-2 tellme pd-l-0"
                  onHide={() => this.closevirtualtour(true)}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <span
                      className="welcomeClose float-end"
                      onClick={() => this.closevirtualtour(true)}
                    >
                      {global.closee}
                    </span>
                    <section className="middle">
                      <div className="container-fluid p-0">
                        <section className="reservationBook pd-t-20 pd-b-20 ">
                          <div className="container pd-b-0">
                            <div className="content">
                              <div className="">
                                <div className="col-12 col-md-12 mg-b-20 col-12 col-md-12 pd-l-0 mg-b-20 d-flex justify-content-start">
                                  <h4>Tell me about yourself </h4>
                                </div>
                              </div>
                              <div className="row mobView  justify-content-center pd-l-0 pd-r-0">
                                <div className="col-12">
                                  <div className="requestSection">

                                    <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                      <div className="countryphone">
                                        <TextField
                                          id="first_name"
                                          name="first_name"
                                          value={this.state.first_name}
                                          onChange={this.changeHandler}
                                          label="Name"
                                          variant="outlined"
                                          fullWidth
                                          required
                                          error={
                                            this.state.errors.first_name ? true : false
                                          }
                                          helperText={
                                            this.state.errors.first_name
                                              ? this.state.errors.first_name
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                    <div className="countryphone">
                                      <TextField
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        onBlur={this.validateEmail}
                                        onChange={this.changeHandler}
                                        id="email"
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        className=" textfield"
                                        error={this.state.errors.email ? true : false}
                                        helperText={
                                          this.state.errors.email
                                            ? this.state.errors.email
                                            : ""
                                        }
                                      />
                                      </div>
                                    </div>
                                    <div className="col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                    <PhoneInput
                                        country={global.country}
                                        onlyCountries={["us"]}
                                        //value={this.state.phone}
                                        onKeyPress={global.OnKeyPressEvent.phoneValidation}
                                        value={this.state.phone_number}
                                        disableCountryCode={true}
                                        disableDropdown={true}
                                        variant="outlined"
                                        placeholder=""
                                        autoFormat="true"
                                        enableSearch="true"
                                        countryCodeEditable="true"
                                        className="phoneInput"
                                        id="phone_number"
                                        name="phone_number"
                                        disableSearchIcon
                                        searchClass="search-class"
                                        searchStyle={{
                                          margin: "0",
                                          width: "97%",
                                          height: "30px",
                                        }}
                                        // className="MuiOutlinedInput-input borderInput textfield w-100 react-datepicker-ignore-onclickoutside"
                                        error={this.state.errors.phone_number ? true : false}
                                        helperText={this.state.errors.phone_number ? this.state.errors.phone_number : ""}
                                        onChange={this.changeHandlerPhone}
                                      // onChange={(phone_number) => this.setState({ phone_number },)}
                                      />

                                      {this.state.errors.phone_number ? (
                                        <p className="error-message">
                                          {this.state.errors.phone_number}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {this.state.errorCount >= 3 && (
                                      <div
                                        id="recaptch-block"
                                        className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30"
                                      >
                                        <ReCAPTCHA
                                          // theme="dark" // light
                                          // onErrored:""
                                          // badge="bottomright" //bottomleft inline
                                          // size="normal" //"compact"
                                          ref={recaptchaRef}
                                          onChange={this.onChangeRecaptcha}
                                          onExpired={() => this.onErrorRecaptcha}
                                          onErrored={() => this.onErrorRecaptcha}
                                          sitekey={process.env.REACT_APP_SITE_KEY}
                                        />
                                        {this.state.errors.recaptcha !== "" ? (
                                          <p className="error-message">
                                            {this.state.errors.recaptcha}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )}

                                    <div className="col-md-12 pd-l-15 pd-r-15 mg-b-0 d-flex justify-content-center">
                                      <button
                                        onClick={this.saveOrUpdateApplicants}
                                        disabled={this.state.loader ? true : false}
                                        className="w-100"
                                      >
                                        Next &nbsp;
                                        {this.state.loader ? (
                                          <>
                                            <SpinnerLoader />
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </button>
                                      <a
                                        href={"https://calendly.com/orion-haus/discovery"}

                                      >
                                        {" "}
                                        <button
                                          hidden
                                          className="w-100"
                                          id="virtual-tour-button"
                                        >
                                          Confirm
                                        </button>
                                      </a>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </section>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.showSocialLinks}
                  centered
                  className="ShareModal"
                  onHide={() => this.showSocialLinks(false)}
                >
                  <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                    <h5 className="social-link-main-heding-div">
                      <div className="title">
                        {" "}
                        Share this place with friends and family
                      </div>
                    </h5>
                    <span
                      className="welcomeClose float-end mg-t-15"
                      onClick={() => this.showSocialLinks(false)}
                    >
                      {global.closee}
                    </span>
                  </div>
                  <Modal.Body className="width_402  p-4 border_radius_14">
                    <div>
                      <figure className="mg-auto m-auto text-center col-md-12 col-ld-12 col-sm-12">
                        <SocialShareLink
                          unitName={this.state.unit_number}
                          unitImage={this.state.brand_logo ? this.state.brand_logo:this.state.property_logo}
                          urlName={this.state.urlname}
                          title={this.state.title}
                          favicon={this.state.favicon}
                          keywords={this.state.keywords}
                          description={this.state.description}
                          noIndex={"true"}
                          url={this.state.url}
                          image={this.state.image}
                          fb_app_id={this.state.fb_app_id}
                          author={"OHAI"}
                        />
                      </figure>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={this.state.docCalcModal}
                  centered
                  dialogClassName="modal-lg"
                  className="ShareModal estimatePotential"
                  onHide={() => this.docCalcModal(false)}
                >
                  <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
                    <h5 className="social-link-main-heding-div">
                      <div className="title">
                        {" "}
                        How we estimate your earning potential
                      </div>
                    </h5>
                    <span
                      className="welcomeClose float-end mg-t-15"
                      onClick={() => this.docCalcModal(false)}
                    >
                      {global.closee}
                    </span>
                  </div>
                  <Modal.Body className="width_402  p-4 border_radius_14">
                    <div>
                      <p>Your estimated earnings are calculated by AirDNA’s Rentalizer, which estimates the average daily rate
                        for an address based on historical performance data of comparable properties near the subject property’s address.</p>

                      <p>AirDNA’s algorithm searches for up to 50 comparable properties within a 10-mile  radius of the subject property.
                        AirDNA selects these properties based on their proximity to the address geographically and how similar they are
                        in their bedroom, bathroom, and guest count configurations. A weighted average of these properties'
                        historical performance provides the ADR estimate by AirDNA used to estimate your future revenue.</p>

                      <p>Your potential revenue is calculated by taking the average daily rate and multiplying that number by the
                        number you input for how many nights you will book per month — which may or may not be restricted by the building’s
                        limits on how often residents can host. For example, to estimate revenue from booking one week, we multiply the AirDNA
                        Rentalizer ADR number by 7. While this computation will estimate the total revenue, it does not remove any revenue
                        share that the property owner may or may not collect, which typically ranges from 0 - 25%, depending on the individual
                        building.</p>

                      <p>Your actual earnings will depend on several factors, including your personal marketing, photography, availability,
                        price, and the supply and demand dynamics in the area. Your ability to short term rent may also depend on local laws
                        and building rules and restrictions, like hosting night limits, which may or may not change over time. </p>

                      <p> The AirDNA Rentalizer ADRs were last referenced on {this.state.adr_updated_on ? Moment(this.state.adr_updated_on).format("MM/DD/YY") : ""}</p>

                      <p>These earning estimates are not an appraisal or estimate of property value.</p>

                      <p> The metrics presented are as of the date indicated and generally are estimates based on what we
                        believe to be relevant sets of comparable properties generated through AirDNA https://airdna.co
                        which is a third-party provider of revenue management solutions widely used by industry analysts
                        and individual homesharing or short-term rental investors. However, the use of comparable sets is
                        only a forecasting tool and may not reflect the actual performance of a particular property located
                        in a particular geographic area. Furthermore, the information used to generate a comparable set is
                        historical and the results may vary based on, among other things, changing local and national economic
                        conditions and other factors which may vary greatly from time to time. We do not make any representation
                        that the actual performance of the property will be consistent with these metrics, and we do not assume any
                        obligation to update them.</p>

                      <p>This website does not constitute an offer, solicitation,
                        or recommendation to sell or an offer to purchase any securities, investment products, or
                        investment advisory services. This website and the information set forth herein are not
                        intended to provide investment recommendations or advice.</p>
                    </div>
                  </Modal.Body>
                </Modal>


                <Modal
                  show={this.state.openAllAmenitiesModal}
                  centered
                  scrollable={true}
                  className="unitmodal large_model amenities_modal"
                  onHide={() => this.openAllAmenitiesModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14 pt-0">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile model_top_fix">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openAllAmenitiesModal()}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Amenity" title="Amenity" />
                      </span>
                      <div className="title"> What this place offers</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openAllAmenitiesModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="place-offers pd-l-20 pd-r-20 mg-t-10">
                      {this.state.propertyAmenityTotal > 0
                        ? Object.keys(this.state.propertyAmenityList).map(
                          (opt, i) => {
                            return (
                              <div key={i} className="amt_box">
                                <h4 className="text-left amenities_pop_head">{opt}</h4>
                                <ul>
                                  {this.state.propertyAmenityList[opt].map((k, v) => {
                                    // {console.log(k)}
                                    return (
                                      <li key={v}><img src={k.icon}></img>{k.name}</li>
                                      // <li key={i}>{opt.amenity_name}</li>
                                    );

                                  }
                                  )}
                                </ul>

                              </div>
                            )

                          }
                        )
                        : ""}
                    </div >
                  </Modal.Body >
                </Modal >
                <Modal
                  show={this.state.openHealthAndSafetyModal}
                  centered
                  className="unitmodal"
                  onHide={() => this.openHealthAndSafetyModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openHealthAndSafetyModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Health & safety" title="Health & safety" />
                      </span>
                      <div className="title">Health & safety</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openHealthAndSafetyModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="pd-l-20 pd-r-20 mg-t-10">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.health_safety
                            ? this.state.health_safety
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openRefundablepolicyModal}
                  centered
                  className="unitmodal"
                  onHide={() => this.openRefundablepolicyModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openRefundablepolicyModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Refundable policy" title="Refundable Policy" />
                      </span>
                      <div className="title">Refundable policy</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openRefundablepolicyModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="pd-l-20 pd-r-20 mg-t-10">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.refundable_policy
                            ? this.state.refundable_policy
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openGuestmanualModal}
                  centered
                  className="unitmodal"
                  onHide={() => this.openGuestmanualModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openGuestmanualModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Guest manual" title="Guest Manual" />
                      </span>
                      <div className="title">Guest manual</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openGuestmanualModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="pd-l-20 pd-r-20 mg-t-10">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.guest_manual
                            ? this.state.guest_manual
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={this.state.openGettingAroundModal}
                  centered
                  className="unitmodal"
                  onHide={() => this.openGettingAroundModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openGettingAroundModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Where you’ll be" title="Where you’ll be" />
                      </span>
                      <div className="title">Where you’ll be</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openGettingAroundModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="pd-l-20 pd-r-20 mg-t-10">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.getting_around
                            ? this.state.getting_around
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>

                <PopupModal
                  url={this.state.celendaryLink}
                  pageSettings={this.props.pageSettings}
                  utm={this.props.utm}
                  prefill={{
                    email: this.state.email,
                    firstName: this.state.first_name,
                    lastName: '',
                    name: this.state.first_name
                  }}
                  onModalClose={() => this.setState({ celendaryPopUp: false })}
                  open={this.state.celendaryPopUp}
                  /*
                  * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                  * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                  */
                  rootElement={document.getElementById("root")}
                />


              </section >
              {/* Footer */}
           
              <CommonFooter propertySlug={this.state.property_slug} webview={this.state.webview}/>
            </main >
          </React.Fragment >
        </React.Suspense >
      );
    }
  }
}
export default LtrDetails;
