///////////////////////////////////////////////
//     							             //
//  Program: HomesharingInquiryService.js    //
//  Application: Services                    //
//  Option: Used for HomesharingInquiry data //
//  Developer: Raj Kumar                     //
//  Date: 2023-09-18                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class HomesharingInquiryService {
  getResidentUnitData(queryString) {
    let endPoint =
      "get-homesharing-unit-resident" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  addHomeSharingInquiryData(data) {
    let endPoint = "add-homesharing-inquiry-data";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new HomesharingInquiryService();
