
import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
class MultifamilyPartnershipsSecond extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };
    render() {
        return (
            <div>
                <main className="oh-template Brandmainpage ">
                    <CommonHeader />

                    {/* <section className="multifamily-banner">
                        <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-50 pd-r-50">
                            <h1>How to Stabilize Rent in a Down Market</h1>
                            <h4 className="m-0">Allowing residents to short-term rent their apartment, increases NOI and rent stabilization.</h4>
                        </div>
                    </section> */}

                    <section className="multifamily-sec">
                        <div className="container">
                            <div className="breadcumbs">
                                <ul>
                                    <li> <a href="/">Home</a> </li>
                                    <li> / <a href="/partnerships">Multifamily partnerships</a></li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-md-8 col-xs-12 pad-ct-r-0">
                                    <div className="multifamily-left">
                                        <div className="multi-head mg-b-30">
                                            <h2>How to stabilize rent in a down market? </h2>
                                            <span>Allowing residents to short-term rent their apartment, increases NOI and rent stabilization.</span>
                                        </div>
                                        <div className="multi-cnt">
                                            <p>Rents are on the move. Apartment rents have fallen for the third consecutive month amidst a backdrop of rising interest rates, corporate layoffs and economic turmoil. Major growth markets such as Phoenix and Las Vegas are on pace to become the first two major metro markets to experience year-over-year rent decreases as we barrel towards recession in 2023.</p>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('piprgph.png')} alt="" />
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('linegph.png')} alt="" />
                                            <p>With rents moving lower, what can landlords do to stabilize rents in a competitive market? Allow short term rentals — in a safe and controlled fashion — through a partnership with OHAI.</p>
                                            <p>Very few apartment buildings allow their tenants to take advantage of Airbnb and other short term rental platforms. Historically, there was nothing in it for the building owner. Why would a building owner want to allow transient behavior in a stable apartment building while tenants reap all of the financial benefit? But not anymore.</p>
                                            <p>Adding homesharing as an amenity with OHAI provides your building with a competitive market advantage while mitigating risk:</p>
                                            <ol>
                                                <li>Homesharing is a rare, sought-after amenity: Seasonal, flexible and WFH employees want the flexibility to be mobile. Allowing such tenants to monetize their rental apartments while they travel will keep your buildings competitive as resident short term rental revenue can partially offset rent.</li>
                                                <li>Building ownership can choose to participate in tenant short term rental income generated on Airbnb, Vrbo and other platforms, increasing revenue in today’s volatile environment.</li>
                                                <li>OHAI controls all check ins/check outs, digital access points, key exchanges, room cleaning/turnovers and guest customer service to provide a controlled environment for all hospitality activity.</li>
                                            </ol>
                                            <p>In most buildings, OHAI can increase your net operating income (NOI) by driving market outperformance in rental rates, occupancy, turnover and customer acquisition cost, while at the same time offering a slice of income generated by tenants. It’s a winning formula for tenants, owners and guests alike.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-12 position-relative">
                                    <div className="multifamily-right">
                                        <div className="multi-need">
                                            <h4>Want to get in touch ?</h4>
                                            {/* <p>We'll start with some questions get you to the right place.</p> */}
                                            <a href="mailto:sales@orionhaus.com" className=""><button  >Contact sales</button></a>
                                            <div className="multi-address">
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('person.svg')} alt="" />  Rob Landers</p>
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('phone.svg')} alt="" /> <a href="tel:404-271-7680"> 404-271-7680</a></p>
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('email.svg')} alt="" /> <a href="mailto:rob@orionhaus.com">rob@orionhaus.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="expl_artls_sec expl_inner_detail_sec">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="artls_head">
                                            <h2 className="art_heading">
                                                Explore resource center articles
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/how-to-minimize-risk-in-your-airbnb-friendly-apartment-building"  className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('how-to-minimize-risk.webp')} alt="" title="How to minimize risk in your Airbnb-friendly apartment building?" />
                                                    </figure>
                                                </div>
                                                <p>How to minimize risk in your Airbnb-friendly apartment building?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/Is-my-apartment-building-a-good-fit-for-airbnb-and-vrbo-activity" className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('is-my-apartment-building.webp')} alt="" title="Is my apartment building a good fit for Airbnb and Vrbo activity?" />
                                                    </figure>
                                                </div>
                                                <p>Is my apartment building a good fit for Airbnb and Vrbo activity?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="artls_more">
                                            <button>More articles</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    <CommonFooter />
                </main>
            </div>
        )
    }
}

export default MultifamilyPartnershipsSecond;