////////////////////////////////////////////////////////////
//     							                          //
//  Program: Timer.jsx                                    //
//  Application: commpon                                  //
//  Option: For countdown timer into project              //
//  Developer: NP  						                   //
//  Date: 2021-03-09                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from 'react';

class Timer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            start: Date.now(),
            diff: 0,
            minutes: 0,
            seconds: 0,
            duration: 60 * this.props.minutes,
            resend: false,
        }
        this.timerState = '';
        this.timerCount = '';
        this.count = 0;
        this.resendOTP = this.resendOTP.bind(this);
    }

    componentDidMount() {
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(() => {
            this.timer();
        }, 1000)
    }

    starttimer() {
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(() => {
            this.timer();
        }, 1000)
    }

    componentWillUnmount() {
        window.clearInterval(this.timerCount);
    }

    timer() {
        let diff = this.state.diff;
        let minutes = this.state.minutes;
        let seconds = this.state.seconds;

        diff = this.state.duration - (((Date.now() - this.state.start) / 1000) | 0);
        minutes = (diff / 60) | 0;
        seconds = (diff % 60) | 0;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.setState({ minutes: minutes, seconds: seconds });

        if (diff <= 0) {
            this.setState({ resend: true });
            window.clearInterval(this.timerCount);
        }
    }

    resendOTP(evt) {
        this.setState({ start: Date.now(), minutes: 0, seconds: 0, duration: 60 * this.props.minutes });
        let isSuccess = this.props.resendEvent();
        if (isSuccess) {
            this.setState({ resend: false });
        }
        this.starttimer();
        evt.preventDefault();
    }

    render() {
        return (
            <div className="az-signin-footer mg-t-25 reservationBook resend">
                {this.state.resend ? <span className="w-100" onClick={this.resendOTP}>Re-send code</span>
                    : <h6 className="timerText">You can re-send the code after {this.state.minutes}:{this.state.seconds}</h6>
                }
            </div>
        );
    }
}

export default Timer;
