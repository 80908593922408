//     							                          //
//  Program: booktourbasicinfo.jsx                        //
//  Application: KINI HTML                                //
//  Option: Book tour Basic Info                          //
//  Developer: Gauarang Patel,PS                             //
//  Date: 2022-06-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import queryString from "query-string";
import Moment from "moment";
import ReservationService from "../../services/ReservationService";
import PhoneInput from 'react-phone-input-2';
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import AuthenticationService from "../../services/AuthenticationService";
import { Helmet } from "react-helmet-async";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Login from "../common/Login";

const queryParams = queryString.parse(location.search);
class BookTourBasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginModel: localStorage.getItem("loginModel") === "open" ? true : false,
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      property_name: localStorage.getItem("property_name") ? localStorage.getItem("property_name") : "",
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      input: {},
      errors: {
        first_name: "",
        last_name: "",
        phone_number: "",
        isAdult: "",
      },
      listing_slug: localStorage.getItem("listing_slug") ? localStorage.getItem("listing_slug") : queryParams.listing,
      editDateModal: false,
      editGuestModal: false,
      oldStartDate: localStorage.getItem("checkin") ? localStorage.getItem("checkin") : queryParams.checkin,
      oldEndDate: localStorage.getItem("checkout") ? localStorage.getItem("checkout") : queryParams.checkout,
      startDate: localStorage.getItem("checkin") ? localStorage.getItem("checkin") : queryParams.checkin,
      endDate: localStorage.getItem("checkout") ? localStorage.getItem("checkout") : queryParams.checkout,
      message: "",
      userToken: localStorage.getItem("userToken") ? localStorage.getItem("userToken") : undefined,
      availableDate: "",
      bookedDate: "",
      phone_number: "",
      email: localStorage.getItem("guest_email") ? localStorage.getItem("guest_email") : sessionStorage.getItem("guest_email") ? sessionStorage.getItem("guest_email") : "",
      country_code: "",
      enableDatePicker: true,
      redirectToNextPage: false,
      passwordType:"password",
      queryString: "",
      adultCount: queryParams.numberOfAdults === undefined ? 1 : parseInt(queryParams.numberOfAdults),
      childCount: queryParams.numberOfChildren === undefined ? 0 : parseInt(queryParams.numberOfChildren),
      infantCount: queryParams.numberOfInfants === undefined ? 0 : parseInt(queryParams.numberOfInfants),
      petsCount: queryParams.numberOfPets === undefined ? 0 : parseInt(queryParams.numberOfPets),
      guestValue: "1 Guest",
      editGuestModal: false,
      birthdate: Moment(new Date()).format("YYYY-MM-DD"),
      pricing: localStorage.getItem("pricing") ? JSON.parse(localStorage.getItem("pricing")) : {},
      isAdult: localStorage.getItem("reservationDetails") ? JSON.parse(localStorage.getItem("reservationDetails")).isAdult : false,
      first_name: localStorage.getItem("reservationDetails") ? JSON.parse(localStorage.getItem("reservationDetails")).first_name : "",
      last_name: localStorage.getItem("reservationDetails") ? JSON.parse(localStorage.getItem("reservationDetails")).last_name : "",
      email: localStorage.getItem("guest_email") ? localStorage.getItem("guest_email") : "",
      guest_slug: localStorage.getItem("guest_slug") ? localStorage.getItem("guest_slug") : "",
      reservation_slug: localStorage.getItem("reservation_slug") ? localStorage.getItem("reservation_slug") : "",
      phone_number: localStorage.getItem("reservationDetails") ? JSON.parse(localStorage.getItem("reservationDetails")).phone_number : "",
      reservation_confirm: localStorage.getItem("reservation_confirm") ? JSON.parse(localStorage.getItem("reservation_confirm")) : "",
      guest: 1,
      total_selected_guest: 1,
      privacy_modal: false,
      terms_modal: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setClassTodatebirth = this.setClassTodatebirth.bind(this);
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    this.incrementpets = this.incrementpets.bind(this);
    this.decrementpets = this.decrementpets.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.changeHandlerPhone = this.changeHandlerPhone.bind(this);
    this.loginModel = this.loginModel.bind(this);
  }



  async componentDidMount() {
    /* set old date and phone number in localstorage */
    this.setState({ guest: parseInt(localStorage.getItem("maximum_number_of_guests")) });
    this.guestDetail();
    localStorage.setItem("queryParams", JSON.stringify(queryParams));

    if (this.state.reservation_confirm !== "") {
      await this.createQueryString();
      var unit = document.getElementById("reservation-payment-id");
      unit.click();
    }
  }

  changeHandlerPhone = (phoneNumber) => {
    this.setState({ phone_number: phoneNumber });
    if (phoneNumber.length > 8) {
      var errors = this.state.errors;
      errors.phone_number = "";
      this.setState({ errors: errors });
    }

    if (phoneNumber.length > 1) {
      var errors = this.state.errors;
      errors.phone_number = "";
      this.setState({ errors: errors });
    }
    if (phoneNumber.length > 15 || phoneNumber.length < 8) {
      var errors = this.state.errors;
      errors.phone_number = "The phone number must be a valid phone number";
      this.setState({ errors: errors });
    }
  }

   togglePassword = ()=>{
    this.setState({ passwordType: "text" });
  }
   togglePasswordHide = ()=>{
    this.setState({ passwordType: "password" });
  }

  passwordCheckFun = () => {
    let errors = this.state.errors;
    let passwordCheck = global.OnKeyPressEvent.validatePassword(this.state.password);
    if (passwordCheck !== "") {
      errors.password = passwordCheck
    } else {
      errors.password = "";
    }
    this.setState({ errors: errors });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = (event.target.type === "checkbox") ? event.target.checked : event.target.value;

    // if (event.target.name === "isAdult") {
    //   if (value === true) {
    //     var errors = this.state.errors;
    //     errors.isAdult = "";
    //     this.setState({ errors: errors });
    //   }
    // }
    if (event.target.name === "first_name") {
      if (event.target.value.length > 1) {
        const regex = /^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      }
    }
    if (event.target.name === "last_name") {
      if (event.target.value.length > 1) {
        const regex = /^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.last_name = "Please enter valid last name.";
          this.setState({ errors: errors });
        } else {
          errors.last_name = "";
          this.setState({ errors: errors });
        }
      }
    }
    if (event.target.name === "phone_number") {
      if (event.target.value.length > 1) {
        var errors = this.state.errors;
        errors.phone_number = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "password") {
      if (event.target.value.length > 1) {
        var errors = this.state.errors;
        errors.password = "";
        this.setState({errors: errors });
      }
    }
    if (event.target.name === "email") {
      if (event.target.value.length > 7) {
        const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.email = "Please enter valid email.";
          this.setState({ errors: errors });
        } else {
          errors.email = "";
          this.setState({ errors: errors });
        }
      }
    }
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  createQueryString = () => {
    var queryString = "";
    queryString = `?checkin=${this.state.startDate}&checkout=${this.state.endDate}&numberOfAdults=${this.state.adultCount}&numberOfChildren=${this.state.childCount}&numberOfInfants=${this.state.infantCount}&numberOfPets=${this.state.petsCount}&listing=${this.state.listing_slug}`;
    this.setState({ queryString: queryString });
  };

  checkAvailabilityDate = () => {
    var inputData = {
      listing_slug: this.state.unit_slug,
      checkin_date: this.state.startDate,
      checkout_date: this.state.endDate,
      user_token: this.state.userToken,
    };
    this.getAvailibityDate(inputData);
    this.createQueryString();
  };

  guestDetail = () => {
    var message = "";
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Childs"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    this.setState({ guestValue: message });
    // return message;
  };

  placeReservation = async (dynamicData = "") => {

    if (this.state.errors.first_name === "" &&
      this.state.first_name !== "" &&
      this.state.errors.last_name === "" &&
      this.state.last_name !== "" &&
      this.state.errors.phone_number === "" &&
      this.state.phone_number !== "" &&
      this.state.errors.password === "" &&
      this.state.password !== "") {

      var inputData = {
        property_slug: this.state.property_slug,
        listing_slug: this.state.listing_slug,
        set_channel: 1,
        checkin_date: Moment(this.state.startDate).format("YYYY-MM-DD"),
        checkout_date: Moment(this.state.endDate).format("YYYY-MM-DD"),
        phone_number: this.state.phone_number ? this.state.phone_number : "",
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        email: this.state.email ? this.state.email : "",
        adults: this.state.adultCount ? this.state.adultCount : 1,
        children: this.state.childCount ? this.state.childCount : 0,
        infants: this.state.infantCount ? this.state.infantCount : 0,
        pets: this.state.petsCount ? this.state.petsCount : 0,
        user_token: this.state.userToken ? this.state.userToken : "",
        password: this.state.password ? this.state.password : "",
        level: 5,
      };

      let res = await ReservationService.storeReservation(inputData);
      // console.log(res);
      var reservation_slug = "";
      var guest_slug = "";
      var listing_name = "";
      var confirmation_code= "";
      await this.createQueryString();
      if (global.successStatus.includes(res.status)) {
        reservation_slug = res.data ? res.data.reservation_slug : "";
        confirmation_code= res.data ? res.data.confirmation_code:"";
        guest_slug = res.data ? res.data.guest_slug : "";
        listing_name = res.data ? res.data.listing_name : "";
        let input = {
          email: this.state.email,
          password: this.state.password,
        };
        let resultLogin = await AuthenticationService.signin(input);
        if (global.successStatus.includes(resultLogin.status)) {
          localStorage.setItem("headerToken", resultLogin.data.headerToken ? resultLogin.data.headerToken : "");
          global.headerToken = resultLogin.data.headerToken ? resultLogin.data.headerToken : "";
          // localStorage.setItem('isLogin', 1);
          localStorage.setItem("isPopupShow", 1);
          localStorage.setItem("userData", resultLogin.data.user_data ? JSON.stringify(resultLogin.data.user_data) : {});
          localStorage.setItem("permissions", resultLogin.data.permissions ? JSON.stringify(resultLogin.data.permissions) : {});
          localStorage.setItem("user_type", resultLogin.data.user_type);
          global.currerntRoleSlug = resultLogin.data.currerntRoleSlug ? resultLogin.data.currerntRoleSlug : ""
          localStorage.setItem('currerntRoleSlug', resultLogin.data.currerntRoleSlug);
        }
        localStorage.setItem("reservation_slug", reservation_slug);
        localStorage.setItem("confirmation_code", confirmation_code);
        localStorage.setItem("guest_slug", guest_slug);
        localStorage.setItem("listing_name", listing_name);
        localStorage.setItem("reservationDetails", JSON.stringify(inputData));
        var unit = document.getElementById("reservation-payment-id");
        unit.click();
      } else {
        this.setState({ place_reservation_error: res.data.message });
      }
    } else {
      var errors = this.state.errors;
      if (!this.state.first_name) {
        errors.first_name = "First name is required.";
      }
      if (!this.state.last_name) {
        errors.last_name = "Last name is required.";
      }
      if (!this.state.phone_number) {
        errors.phone_number = "Phone number is required.";
      }
      if (!this.state.password) {
        errors.password = "Password is required.";
      }
      // if (!this.state.isAdult) {
      //   errors.isAdult = "Agree and continue.";
      // }
      this.setState({ errors: errors });
    }
  };

  async getAvailibityDate(dynamicData = "") {
    this.setState({ showSpinner: true, loading: true });

    var inputData = dynamicData
      ? dynamicData
      : {
        listing_slug: this.state.unit_slug,
        checkin_date: this.state.startDate,
        checkout_date: this.state.endDate,
        // user_token: this.state.userToken,
      };
    let res = await ReservationService.getAvailibityDate(inputData);
    var availableDate = [];
    var bookedDate = [];
    var pricing = [];
    var display_main_image_property = "";
    var userToken = "";
    var message = "";
    var redirectToNextPage = false;

    //let res = await UnitlistingApi.getUnitlisting();
    if (global.successStatus.includes(res.status)) {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      availableDate =
        res.data && res.data.available_dates ? res.data.available_dates : [];
      bookedDate =
        res.data && res.data.reserved_dates ? res.data.reserved_dates : [];
      pricing = res.data && res.data.pricing ? res.data.pricing : [];
      display_main_image_property =  res.data && res.data.property ? res.data.property.property_main_image_url : "";
      redirectToNextPage = res.data && res.data.pricing ? true : false;
    } else {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      message = res.data && res.data.message ? res.data.message : "";
      availableDate =
        res.data && res.data.available_dates ? res.data.available_dates : [];
      bookedDate =
        res.data && res.data.reserved_dates ? res.data.reserved_dates : [];
      redirectToNextPage = false;
    }
    if (this.state.userToken === undefined || this.state.userToken === "" || !this.state.userToken) {
      localStorage.setItem("userToken", userToken);
    }
    this.setState({
      availableDate: availableDate,
      bookedDate: bookedDate,
      enableDatePicker: false,
      message: message,
      pricing: pricing,
      display_main_image_property:display_main_image_property,
      redirectToNextPage: redirectToNextPage,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async incrementAdult() {
    await this.setState({ adultCount: this.state.adultCount + 1, total_selected_guest: this.state.total_selected_guest + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({ adultCount: this.state.adultCount - 1, total_selected_guest: this.state.total_selected_guest - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({ childCount: this.state.childCount + 1, total_selected_guest: this.state.total_selected_guest + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({ childCount: this.state.childCount - 1, total_selected_guest: this.state.total_selected_guest - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  async incrementpets() {
    await this.setState({ petsCount: this.state.petsCount + 1 });
    await this.createQueryString();
    await this.guestDetail();
  }

  async decrementpets() {
    if (this.state.petsCount === 0) {
      await this.setState({ petsCount: 0 });
    } else {
      await this.setState({ petsCount: this.state.petsCount - 1 });
    }
    await this.createQueryString();
    await this.guestDetail();
  }

  onDateChange(startDate, endDate) {
    // console.log(this.state.availableDate,Moment(startDate).format("YYYY-MM-DD"))
    // var valid = this.state.availableDate.includes(Moment(startDate).format("YYYY-MM-DD"));
    // console.log(valid);
    this.setState({
      startDate: startDate
        ? Moment(startDate).format("YYYY-MM-DD")
        : new Date(),
      endDate: endDate
        ? Moment(endDate).format("YYYY-MM-DD")
        : Moment(startDate).format("YYYY-MM-DD"),
    });
    // this.getAvailibityDate();
  }

  manageGuestModal(modal = false) {
    this.setState({ editGuestModal: modal });
  }

  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }

  setValue(event) {
    // console.log(event);
  }

  setInputValue(event) {
    // console.log(event);
  }

  setClassTodatebirth = (id, type) => {
    var progress = document.getElementById(id);
    if (type === 1) {
      progress.classList.add("Isfoucsinput");
    } else {
      progress.classList.remove("Isfoucsinput");
    }
  };
  setBirthDate = (value) => {
    var birthdate = value
      ? value.format("YYYY-MM-DD")
      : Moment(new Date()).format("YYYY-MM-DD");
    // console.log(birthdate);
    this.setState({ birthdate: birthdate });
  };

  loginModel = (value) => {
    this.setState({ loginModel: value });
    this.setState({ signupModel: false });
    // console.log(this.state, value);
  }

  signupModel = (value) => {
    this.setState({ signupModel: value });
    this.setState({ loginModel: false });
  }
  render() {
    const dateFormat = "MM/DD/YYYY";

    return (
      <main className="oh-template Brandmainpage">
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <CommonHeader propertySlug={this.state.property_slug} />
        <section className="middle">
          <div className="container-fluid p-0">
            <section className="reservationBook req_book_sec bg-white bgwhitecolor">
              <div className="container">
                <div className="content">
                  <div className="row mobView m-0">
                    <div className="col-sm-12 col-lg-12 headingpage">
                      <div className="d-flex ResivationTitle">
                        {/* <a href=""><img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} /></a> */}
                        <h4 className="mg-l-0"> Your details </h4>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 yourtrip">
                      <div className="requestSection">
                        {/* <div className="row book_app_info">
                          <div className="col-md-6 pd-l-10 pd-r-10 mg-b-30 d-flex flex-column align-items-start justify-content-start first">
                            <span className="pd-t-10 pd-b-20 labelTitle">Your trip </span>
                            {
                              this.state.place_reservation_error &&
                              <p className="text-danger text-left">{this.state.place_reservation_error}</p>
                            }
                            <strong className="d-block">Dates</strong>
                            <span>
                              {Moment(this.state.oldStartDate).format(
                                "MM/DD/YYYY"
                              ) +
                                " to " +
                                Moment(this.state.oldEndDate).format(
                                  "MM/DD/YYYY"
                                )}
                            </span>
                          </div>
                          <div className="col-md-6 pd-l-10 pd-r-10 mg-b-30 d-flex flex-column align-items-end justify-content-start last">

                          </div>
                          <div className="col-md-12 pd-l-10 pd-r-10 mg-b-30 d-flex flex-row align-items-start justify-content-between">
                            <div>
                              <strong className="d-block">Guests</strong>
                              <span>{this.state.guestValue}</span>
                            </div>
                            <p
                              className="Link"
                              onClick={() => this.manageGuestModal(true)}
                            >
                              Edit
                            </p>
                          </div>
                        </div> */}
                        <div className="row book_app_info m-0 basInfo">
                          <div className="requestSection yourInfo p-0">
                            <div className="row m-0">
                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-0 pd-r-0 mg-b-20">
                                <TextField
                                  type="email"
                                  id="email"
                                  label="Email"
                                  variant="outlined"
                                  fullWidth
                                  className="borderInput textfield"
                                  name="email"
                                  value={this.state.email}
                                  required
                                  disabled
                                  error={this.state.errors.email ? true : false}
                                  helperText={
                                    this.state.errors.email
                                      ? this.state.errors.email
                                      : ""
                                  }
                                />
                                <p className="mg-t-15">
                                  We'll email your trip confirmation and
                                  receipts.
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-0 pd-r-0 mg-b-10">

                                <p className="mg-t-0 alreadyAccount">
                                  Already have an account? <a href="javascript:void(0)" style={{ cursor: "pointer" }} className={this.state.loginModel ? "active" : ""} onClick={() => this.loginModel(true)}>{this.state.section_header_data && this.state.section_header_data[3] && this.state.section_header_data[3].text ? this.state.section_header_data[3].text : 'Sign in'}</a>
                                </p>
                              </div>

                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-0 pd-r-0 mg-b-20">
                                <div className="countryphone- booktourbasicInfo">
                                  <TextField
                                    id="Applicant Name"
                                    label="First name"
                                    variant="outlined"
                                    fullWidth
                                    name="first_name"
                                    value={this.state.first_name}
                                    onChange={this.changeHandler}
                                    required
                                    error={
                                      this.state.errors.first_name
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.errors.first_name
                                        ? this.state.errors.first_name
                                        : ""
                                    }
                                  />
                                  <TextField
                                    id="Applicant Name"
                                    label="Last name"
                                    variant="outlined"
                                    fullWidth
                                    name="last_name"
                                    value={this.state.last_name}
                                    onChange={this.changeHandler}
                                    required
                                    error={
                                      this.state.errors.last_name ? true : false
                                    }
                                    helperText={
                                      this.state.errors.last_name
                                        ? this.state.errors.last_name
                                        : ""
                                    }
                                  />
                                </div>

                                <p className="mg-t-15">
                                  Make sure it matches the name on your
                                  government ID.
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-0 pd-r-0 mg-b-30">
                                <PhoneInput
                                  country={global.country}
                                  //value={this.state.phone}
                                  onKeyPress={global.OnKeyPressEvent.phoneValidation}
                                  value={this.state.phone_number}
                                  variant="outlined"
                                  autoFormat="true"
                                  enableSearch="true"
                                  countryCodeEditable="true"
                                  className="phoneInput"
                                  id="phone_number"
                                  name="phone_number"
                                  disableSearchIcon
                                  searchClass="search-class"
                                  searchStyle={{
                                    margin: "0",
                                    width: "97%",
                                    height: "30px",
                                  }}
                                  // className="MuiOutlinedInput-input borderInput textfield w-100 react-datepicker-ignore-onclickoutside"
                                  error={this.state.errors.phone_number ? true : false}
                                  helperText={this.state.errors.phone_number ? this.state.errors.phone_number : ""}
                                  onChange={(e) => this.changeHandlerPhone(e)}
                                //  onChange={this.changeHandlerPhone}
                                // onChange={phone_number => this.setState({ phone_number })}
                                />
                                {this.state.errors.phone_number ? (
                                  <p className="error-message">
                                    {this.state.errors.phone_number}
                                  </p>
                                ) : (
                                  ""
                                )}

                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-0 pd-r-0 mg-b-20">
                                <div className="passshowhide passwordInput">
                                  <TextField
                                    type={this.state.passwordType}
                                    id="password"
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    className="borderInput textfield"
                                    name="password"
                                    value={this.state.password}
                                    required
                                    onBlur={() => this.passwordCheckFun()}
                                    onChange={this.changeHandler}
                                    error={this.state.errors.password ? true : false}
                                    helperText={
                                      this.state.errors.password
                                        ? this.state.errors.password
                                        : ""
                                    }
                                  />
                                  {this.state.passwordType === "password" ?(
                                   <span onClick={this.togglePassword}>Show</span>):
                                   
                                   <span onClick={this.togglePasswordHide}>Hide</span>}
                                </div>
                              </div>
                              <div className="row align-items-start flex-nowrap pd-r-10 pd-l-10 mg-b-30 mg-t-0 text-align-left confirm-policy">
                                {/* <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox checked={this.state.isAdult} />}
                                    labelPlacement="end"
                                    label=""
                                    required={true}
                                    name="isAdult"
                                    onChange={this.changeHandler}
                                    error={this.state.errors.isAdult ? true : false}
                                    helperText={this.state.errors.isAdult ? this.state.errors.isAdult : ""}
                                    className="w-10"
                                  />

                                </FormGroup> */}
                                <div className="d-flex flex-wrap flex-column pd-l-0">
                                  <p className="mg-t-0 mg-b-0 agree" style={{ textAlign: "left !important" }} >
                                    {/* {`To sign up, you need to be at least 18. Your birthday won't be shared with other people who use OHAI.`} */}
                                    {`By selecting Agree and continue, I agree to OHAI `}
                                    <a href={'javaScript:void(0)'} onClick={() => this.setState({ privacy_modal: true })} title="Privacy"> Privacy </a> and
                                    <a href={'javaScript:void(0)'} onClick={() => this.setState({ terms_modal: true })} title="T&C"> T&C </a>
                                    </p>
                                  {/* <a href={global.themeUrl + this.state.property_slug + "/privacy"} title="Privacy" target="_blank" > Privacy Policy </a> */}

                                  {/* {
                                    this.state.errors.isAdult ?
                                      <p className="text-danger pd-l-0">
                                        {this.state.errors.isAdult}
                                      </p>
                                      : ""
                                  } */}
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div className="col-md-12 mg-b-0 paybtn">
                          <a
                            href={
                              global.themeUrl +
                              this.state.property_slug +
                              "/book-tour-payment" +
                              this.state.queryString
                            }
                            hidden
                            className="w-100 text-decoration-none hidden"
                            rel="noopener noreferrer"
                          >
                            <button
                              className="w-100"
                              id="reservation-payment-id"
                            >
                              Agree and continue
                            </button>
                          </a>
                          <button
                            className="w-100"
                            onClick={() => this.placeReservation()}
                          >
                            Agree and continue
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-xl-1"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 position-relative priceDetail">
                      <div className="requestaddedSection">
                        <figure>
                          <img src={this.state.pricing ? this.state.pricing.listing_image ? this.state.pricing.listing_image.large_img_w_720 : this.state.display_main_image_property : this.state.display_main_image_property} className="img-fluid" alt="Property Image" title="Property Image" />
                        </figure>
                        <div className="priceInfo">
                          <div className="priceTop">
                            <h5>
                              {this.state.pricing.listing_name
                                ? this.state.pricing.listing_name
                                : this.state.property_name
                                  ? this.state.property_name
                                  : ""}
                            </h5>
                            {/* <h6>
                              {`${this.state.startDate
                                ? Moment(this.state.startDate).format('ddd, MMM DD')
                                : Moment(this.state.startDate).format('ddd, MMM DD')
                                } - ${this.state.endDate
                                  ? Moment(this.state.endDate).format('ddd, MMM DD')
                                  : Moment(this.state.endDate).format('ddd, MMM DD')
                                }`}{" "}
                              <b>
                                {" "}
                                {`${this.state.pricing.nights
                                  ? this.state.pricing.nights
                                  : 1
                                  } `} {this.state.pricing.nights > 1 ? " Nights" : " Night"}
                              </b>
                            </h6> */}
                            {/* <h6>
                              {`${this.state.pricing.checkin_date
                                ? Moment(this.state.pricing.checkin_date).format('ddd, MMM DD')
                                : Moment(this.state.startDate).format('ddd, MMM DD')
                                } - ${this.state.pricing.checkout_date
                                  ? Moment(this.state.pricing.checkout_date).format('ddd, MMM DD')
                                  : Moment(this.state.endDate).format('ddd, MMM DD')
                                }`}{" "}
                              <b>
                                {" "}
                                {`${this.state.pricing.nights
                                  ? this.state.pricing.nights
                                  : 1
                                  } `} {this.state.pricing.nights > 1 ? " Nights" : " Night"}
                              </b>
                            </h6> */}
                          </div>
                          <div className="priceMiddle">
                            <ul>
                              <li>
                                {/* <span>{`$${this.state.pricing.nightly_rate ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.nightly_rate).toFixed(2)).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}  × ${this.state.pricing.nights ? this.state.pricing.nights : 1} Nights`}</span> */}
                                <span>{`$${this.state.pricing.listing_price
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.listing_price / this.state.pricing.nights
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseFloat(1).toFixed(2)
                                  }  × ${this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                  }  nights`}</span>
                                <span>{`$${this.state.pricing.listing_price ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.listing_price).toFixed(2)).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}`}</span>
                              </li>
                              {/* <li>
                                <span>{` US$${
                                  this.state.pricing.nightly_rate
                                    ? parseFloat(
                                        this.state.pricing.nightly_rate
                                      ).toFixed(2)
                                    : parseFloat(1).toFixed(2)
                                }  × ${
                                  this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                } Nights`}</span>
                                <span>{`US$${
                                  this.state.pricing.listing_price
                                    ? parseFloat(
                                        this.state.pricing.listing_price
                                      ).toFixed(2)
                                    : parseFloat(1).toFixed(2)
                                }`}</span>
                              </li> */}
                              {this.state.pricing ? this.state.pricing.cleaning_fee ? (
                                <>
                                  <li>
                                    <span>Cleaning fee</span>
                                    {/* <span>{` US$${
                                  this.state.pricing.fees
                                    ? parseFloat(
                                        this.state.pricing.fees
                                      ).toFixed(2)
                                    : parseInt(0).toFixed(2)
                                }`}</span> */}
                                    <span>{` $${this.state.pricing.cleaning_fee ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                  </li>
                                </>
                              )
                                : "" : ""}
                              {/* {this.state.pricing ? this.state.pricing.fees ?
                                <li>
                                  <span>Service fee</span>
                                  <span>{` $${this.state.pricing.fees ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.fees).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>
                                : "" : ""} */}
                                      <li>
                                <span>Service fees</span>

                                {/* <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                {/* <span>${this.state.pricing.nightly_rate * 8 /100}</span> */}
                                <span>{`$${this.state.pricing.convenienceFee
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.convenienceFee
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseInt(0).toFixed(2)
                                  }`}</span>
                              </li>
                              {this.state.pricing ? this.state.pricing.total_of_dollar_taxes ?
                                <li>
                                  <span>Occupancy taxes</span>
                                  {/* <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                  <span>{`$${this.state.pricing.total_of_dollar_taxes ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>
                                : "" : ""}
                            </ul>
                          </div>
                          <div className="priceBottom">
                            <ul>
                              <li>
                                <span>Total (USD)</span>
                                {/* <span>{` US$${
                                  this.state.pricing
                                    .total_listing_price_with_taxes_fees
                                    ? parseFloat(
                                        this.state.pricing
                                          .total_listing_price_with_taxes_fees
                                      ).toFixed(2)
                                    : parseInt(0).toFixed(2)
                                }`}</span> */}
                                <span>{`$${this.state.pricing
                                  .total_listing_price_with_taxes_fees
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(this.state.pricing
                                      .total_listing_price_with_taxes_fees).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseInt(0).toFixed(2)
                                  }`}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="container pd-b-60">
                <div className="content">
                  <div className="d-flex ResivationTitle">
                    <a href=""><img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} /></a>
                    <h4> Request to Book </h4>
                  </div>
                  <div className="row mobView">
                    <div className="col-md-6 col-lg-6">
                      <span className="subhead">Your trip info</span>
                      {
                        this.state.place_reservation_error &&
                        <p className="text-danger text-left">{this.state.place_reservation_error}</p>
                      }
                      <div className="requestSection">
                        <div className="row">
                          <div className="col-md-6 pd-l-15 pd-r-15 mg-b-30 d-flex flex-column align-items-start justify-content-start">
                            <strong className="d-block">Dates</strong>
                            <span>
                              {" "}
                              {Moment(this.state.oldStartDate).format(
                                "MM/DD/YYYY"
                              ) +
                                " to " +
                                Moment(this.state.oldEndDate).format(
                                  "MM/DD/YYYY"
                                )}
                            </span>
                          </div>
                          <div className="col-md-6 pd-l-15 pd-r-15 mg-b-30 d-flex flex-column align-items-end justify-content-start">
                            <Link>Edit</Link>
                          </div>
                          {this.state.message && (
                            <>
                              <p className="text-danger">
                                {" "}
                                {this.state.message}
                              </p>
                            </>
                          )}
                          <div className="col-md-6 pd-l-15 pd-r-15 mg-b-30 d-flex flex-column align-items-start justify-content-start">
                            <strong className="d-block">Guests</strong>
                            <span>{this.state.guestValue}</span>
                          </div>
                          <div className="col-md-6 pd-l-15 pd-r-15 mg-b-30 d-flex flex-column align-items-end justify-content-start">
                            <p
                              className="Link"
                              onClick={() => this.manageGuestModal(true)}
                            >
                              Edit
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <span className="subhead">Your details</span>
                          <div className="requestSection">
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-20">
                                <TextField
                                  type="email"
                                  id="email"
                                  label="Email"
                                  variant="outlined"
                                  fullWidth
                                  className="borderInput textfield"
                                  name="email"
                                  value={this.state.email}
                                  required
                                  disabled
                                  error={this.state.errors.email ? true : false}
                                  helperText={
                                    this.state.errors.email
                                      ? this.state.errors.email
                                      : ""
                                  }
                                />
                                <p className="mg-t-15">
                                  We'll email your trip confirmation and
                                  receipts.{" "}
                                </p>
                              </div>

                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">
                                <PhoneInput
                                  country={global.country}
                                  value={this.state.phone}
                                  onKeyPress={global.OnKeyPressEvent.phoneValidation}
                                  value={this.state.phone_number}
                                  variant="outlined"
                                  autoFormat="true"
                                  enableSearch="true"
                                  countryCodeEditable="true"
                                  className="phoneInput"
                                  id="phone_number"
                                  name="phone_number"
                                  disableSearchIcon
                                  searchClass="search-class"
                                  searchStyle={{
                                    margin: "0",
                                    width: "97%",
                                    height: "30px",
                                  }}
                                  className="MuiOutlinedInput-input borderInput textfield w-100 react-datepicker-ignore-onclickoutside"
                                  error={this.state.errors.phone_number ? true : false}
                                  helperText={this.state.errors.phone_number ? this.state.errors.phone_number : ""}
                                  onChange={(e) => this.changeHandlerPhone(e)}
                                 onChange={this.changeHandlerPhone}
                                onChange={phone_number => this.setState({ phone_number })}
                                />
                                {this.state.errors.phone_number ? (
                                  <p className="error-message">
                                    {this.state.errors.phone_number}
                                  </p>
                                ) : (
                                  ""
                                )}

                              </div>

                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-20">
                                <div className="countryphone">
                                  <TextField
                                    id="Applicant Name"
                                    label="First name"
                                    variant="outlined"
                                    fullWidth
                                    name="first_name"
                                    value={this.state.first_name}
                                    onChange={this.changeHandler}
                                    required
                                    error={
                                      this.state.errors.first_name
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.errors.first_name
                                        ? this.state.errors.first_name
                                        : ""
                                    }
                                  />
                                  <TextField
                                    id="Applicant Name"
                                    label="Last name"
                                    variant="outlined"
                                    fullWidth
                                    name="last_name"
                                    value={this.state.last_name}
                                    onChange={this.changeHandler}
                                    required
                                    error={
                                      this.state.errors.last_name ? true : false
                                    }
                                    helperText={
                                      this.state.errors.last_name
                                        ? this.state.errors.last_name
                                        : ""
                                    }
                                  />
                                </div>

                                <p className="mg-t-15">
                                  Make sure it matches the name on your
                                  government ID.
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-20">
                                <TextField
                                  type="password"
                                  id="password"
                                  label="Password"
                                  variant="outlined"
                                  fullWidth
                                  className="borderInput textfield"
                                  name="password"
                                  value={this.state.password}
                                  required
                                  onBlur={() => this.passwordCheckFun()}
                                  onChange={this.changeHandler}
                                  error={this.state.errors.password ? true : false}
                                  helperText={
                                    this.state.errors.password
                                      ? this.state.errors.password
                                      : ""
                                  }
                                />
                              </div>
                              <div className="row align-items-start flex-nowrap pd-r-15 pd-l-15 mg-b-30 text-align-left confirm-policy">
                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox checked={this.state.isAdult} />}
                                    labelPlacement="end"
                                    label=""
                                    required={true}
                                    name="isAdult"
                                    onChange={this.changeHandler}
                                    error={this.state.errors.isAdult ? true : false}
                                    helperText={this.state.errors.isAdult ? this.state.errors.isAdult : ""}
                                    className="w-10"
                                  />
                                </FormGroup>
                                <div className="d-flex flex-wrap">
                                  <p className="mg-t-8 mg-b-0" style={{ textAlign: "left !important" }} >{`To sign up, you need to be at least 18. Your birthday won't be shared with other people who use OHAI.`}   <a href={'javaScript:void(0)'} onClick={() => this.setState({ privacy_modal: true })} title="Privacy"> Privacy Policy </a>{" "}</p>
                                  <a href={global.themeUrl + this.state.property_slug + "/privacy"} title="Privacy" target="_blank" > Privacy Policy </a>

                                  {
                                    this.state.errors.isAdult ?
                                      <p className="text-danger">
                                        {this.state.errors.isAdult}
                                      </p>
                                      : ""
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12 pd-l-15 pd-r-15 mg-b-0 d-flex justify-content-center">
                            <a
                              href={
                                global.themeUrl +
                                this.state.property_slug +
                                "/book-tour-payment" +
                                this.state.queryString
                              }
                              hidden
                              className="w-100 text-decoration-none hidden"
                              rel="noopener noreferrer"
                            >
                              <button
                                className="w-100"
                                id="reservation-payment-id"
                              >
                                Agree and continue
                              </button>
                            </a>
                            <button
                              className="w-100"
                              onClick={() => this.placeReservation()}
                            >
                              Agree and continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 offset-md-2 offset-lg-2 position-relative">
                      <div className="requestaddedSection">
                        <figure>
                          <img src={this.state.pricing ? this.state.pricing.listing_image ? this.state.pricing.listing_image.large_img_w_720 : global.OnKeyPressEvent.getS3ImageUrl('aminity-list-1.jpg') : global.OnKeyPressEvent.getS3ImageUrl('aminity-list-1.jpg')} className="img-fluid" alt="Property Image" title="Property Image" />
                        </figure>
                        <div className="priceInfo">
                          <div className="priceTop">
                            <h5>
                              {this.state.pricing.listing_name
                                ? this.state.pricing.listing_name
                                : this.state.property_name
                                  ? this.state.property_name
                                  : ""}
                            </h5>
                            <h6>
                              {`${this.state.startDate
                                ? Moment(this.state.startDate).format('ddd, MMM DD')
                                : Moment(this.state.startDate).format('ddd, MMM DD')
                                } - ${this.state.endDate
                                  ? Moment(this.state.endDate).format('ddd, MMM DD')
                                  : Moment(this.state.endDate).format('ddd, MMM DD')
                                }`}{" "}
                              <b>
                                {" "}
                                {`${this.state.pricing.nights
                                  ? this.state.pricing.nights
                                  : 1
                                  } `} {this.state.pricing.nights > 1 ? " Nights" : " Night"}
                              </b>
                            </h6>
                            <h6>
                              {`${this.state.pricing.checkin_date
                                ? Moment(this.state.pricing.checkin_date).format('ddd, MMM DD')
                                : Moment(this.state.startDate).format('ddd, MMM DD')
                                } - ${this.state.pricing.checkout_date
                                  ? Moment(this.state.pricing.checkout_date).format('ddd, MMM DD')
                                  : Moment(this.state.endDate).format('ddd, MMM DD')
                                }`}{" "}
                              <b>
                                {" "}
                                {`${this.state.pricing.nights
                                  ? this.state.pricing.nights
                                  : 1
                                  } `} {this.state.pricing.nights > 1 ? " Nights" : " Night"}
                              </b>
                            </h6>
                          </div>
                          <div className="priceMiddle">
                            <ul>
                              <li>
                                <span>{`$${this.state.pricing.nightly_rate ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.nightly_rate).toFixed(2)).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}  × ${this.state.pricing.nights ? this.state.pricing.nights : 1} Nights`}</span>
                                <span>{`$${this.state.pricing.listing_price
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.listing_price / this.state.pricing.nights
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseFloat(1).toFixed(2)
                                  }  × ${this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                  }  Nights`}</span>
                                <span>{`$${this.state.pricing.listing_price ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.listing_price).toFixed(2)).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}`}</span>
                              </li>
                              <li>
                                <span>{` US$${
                                  this.state.pricing.nightly_rate
                                    ? parseFloat(
                                        this.state.pricing.nightly_rate
                                      ).toFixed(2)
                                    : parseFloat(1).toFixed(2)
                                }  × ${
                                  this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                } Nights`}</span>
                                <span>{`US$${
                                  this.state.pricing.listing_price
                                    ? parseFloat(
                                        this.state.pricing.listing_price
                                      ).toFixed(2)
                                    : parseFloat(1).toFixed(2)
                                }`}</span>
                              </li>
                              {this.state.pricing ? this.state.pricing.fees ?
                                <li>
                                  <span>Service fee</span>
                                  <span>{` $${this.state.pricing.fees ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.fees).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>
                                : "" : ""}
                              {this.state.pricing ? this.state.pricing.cleaning_fee ? (
                                <>
                                  <li>
                                    <span>Cleaning fee</span>
                                    <span>{` US$${
                                  this.state.pricing.fees
                                    ? parseFloat(
                                        this.state.pricing.fees
                                      ).toFixed(2)
                                    : parseInt(0).toFixed(2)
                                }`}</span>
                                    <span>{` $${this.state.pricing.cleaning_fee ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                  </li>
                                </>
                              )
                                : "" : ""}
                              {this.state.pricing ? this.state.pricing.total_of_dollar_taxes ?
                                <li>
                                  <span>Occupancy taxes</span>
                                  <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span>
                                  <span>{`$${this.state.pricing.total_of_dollar_taxes ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>
                                : "" : ""}
                            </ul>
                          </div>
                          <div className="priceBottom">
                            <ul>
                              <li>
                                <span>Total</span>
                                <span>{` US$${
                                  this.state.pricing
                                    .total_listing_price_with_taxes_fees
                                    ? parseFloat(
                                        this.state.pricing
                                          .total_listing_price_with_taxes_fees
                                      ).toFixed(2)
                                    : parseInt(0).toFixed(2)
                                }`}</span>
                                <span>{`$${this.state.pricing
                                  .total_listing_price_with_taxes_fees
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(this.state.pricing
                                      .total_listing_price_with_taxes_fees).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseInt(0).toFixed(2)
                                  }`}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="container pd-l-35 pd-r-35 mg-t-35 mg-b-35 mobAminity">
                <figure>
                                                <img src={aminityListimg1} className="img-fluid" />
                                            </figure>
              </div> */}
            </section>
          </div>
          <section>
            <Modal
              show={this.state.editGuestModal}
              centered
              onHide={() => this.manageGuestModal(false)}
              className="guestModalbox"
            >
              <Modal.Body className="width_402 p-4 border_radius_14">
                <span
                  className="welcomeClose float-end"
                  onClick={() => this.manageGuestModal(false)}
                >
                  {global.closee}
                </span>
                <div className="dropmenuInputbox">
                  <h4>Guests</h4>
                  {/* <p>
                    This place has a maximum of 6 guests, not including infants.
                    Pets aren't allowed.
                  </p> */}

                  <div className="dropdown-item">
                    <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                      <ul>
                        <li>
                          <strong>Adults</strong>
                          <span>Age 13+</span>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center countBtn">
                        <span>
                          <button onClick={this.decrementAdult}
                            disabled={this.state.adultCount === 1 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                          </button>
                        </span>
                        <span> {this.state.adultCount}</span>
                        <span>
                          <button onClick={this.incrementAdult} disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                          </button>
                        </span>

                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                      <ul>
                        <li>
                          <strong>{this.state.childCount > 1 ? "Children":"Child"}</strong>
                          <span>Ages 2–12</span>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center countBtn">
                        <span>
                          <button onClick={this.decrementchild} disabled={this.state.childCount === 0 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                          </button>
                        </span>
                        <span>{this.state.childCount}</span>
                        <span>
                          <button onClick={this.incrementchild} disabled={parseInt(this.state.guest) === this.state.total_selected_guest ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                      <ul>
                        <li>
                          <strong>Infants</strong>
                          <span>Under 2</span>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center countBtn">
                        <span>
                          <button onClick={this.decrementinfant} disabled={this.state.infantCount === 0 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} alt="Guest Count" title="Guest Count" />
                          </button>
                        </span>
                        <span> {this.state.infantCount}</span>
                        <span>
                          <button onClick={this.incrementinfant} disabled={this.state.infantCount === 5 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} alt="Guest Count" title="Guest Count" />
                          </button>
                        </span>

                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                      <ul>
                        <li>
                          <strong>Pets</strong>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center countBtn">
                        <span>
                          <button onClick={this.decrementpets} disabled={this.state.decrementpets === 0 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} />
                          </button>
                        </span>
                      <span> {this.state.petsCount}</span>
                        <span>
                          <button onClick={this.incrementpets} disabled={this.state.incrementpets === 5 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} />
                          </button>
                        </span>
                      </div>
                    </div> */}

                    <div className="d-flex justify-content-end mg-t-30">
                      <button
                        className="done"
                        id="cnt-close"
                        onClick={() => this.manageGuestModal(false)}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={this.state.privacy_modal}
              centered
              onHide={() => this.setState({ privacy_modal: false })}
              className="guestModalbox privacymodal"
            >

              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="d-flex justify-content-between">
                  <h4>Privacy policy</h4>
                  <span
                    className="welcomeClose float-end"
                    onClick={() => this.setState({ privacy_modal: false })}
                  >
                    {global.closee}
                  </span>
                </div>

                <div className="">
                  <Privacy view={"mobile"} />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={this.state.terms_modal}
              centered
              onHide={() => this.setState({terms_modal: false })}
              className="guestModalbox privacymodal"
            >

              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="d-flex justify-content-between">
                  <h4>Terms and Conditions of Service</h4>
                  <span
                    className="welcomeClose float-end"
                    onClick={() => this.setState({ terms_modal: false })}
                  >
                    {global.closee}
                  </span>
                </div>

                <div className="">
                  <Terms view={"mobile"} />
                </div>
              </Modal.Body>
            </Modal>

          </section>
        </section>
        <section>
            <Login redirectTo={"/"} propertySlug={this.state.property_slug} isLogin={this.state.loginModel} manageLogin={this.loginModel.bind(this)} manageSignUp={this.signupModel.bind(this)} />
          </section>
        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default BookTourBasicInfo;
