import React, { Component } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import AuthenticationService from "../../services/AuthenticationService";

class InvaildUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      password: "",
      confirmPassword: "",
      confirmPasswordError: "",
      passwordError: "",
      property_slug: global.property_slug,
      loader: false,
      resetPasswordError: "",
      resetPasswordSuccess: "",
      resetPasswordToken: props.match.params.resetPasswordToken
        ? props.match.params.resetPasswordToken
        : "",
      resetPasswordModal: true,
    };
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    // console.log("login", this.state);
  }

  passwordCheckFun = async () => {
    let passwordError = this.state.passwordError;
    let passwordCheck = global.OnKeyPressEvent.validatePassword(this.state.password);
    if (passwordCheck !== "") {
      passwordError = passwordCheck
    } else {
      passwordError = "";
    }
    await this.setState({ passwordError: passwordError });
  }
  ForgetPassword = async (e) => {
    e.preventDefault();
    window.location.href = "/";
  //   this.setState({loader:true});
  //  // Validate all fields before data submission
  //     this.setState({ isSubmit: true });
  //     let input = {
  //       email: this.state.email,
  //       fallback_url: this.state.resetPasswordpath
  //     }
  //     let res = await AuthenticationService.forgotPass(input);
  //     if ((global.successStatus).includes(res.status)) {
  //       // localStorage.clear();
  //       // setTimeout(() => this.props.history.push('/signin'), global.redirect_time);
  //       this.setState({forgetPasswordSuccess:"We have e-mailed your password reset link!"});
  //       setTimeout(() => this.managerFogetPassword(), global.redirect_time);
  //     } else {
  //       this.setState({ forgetPasswordError: (res.data.message) ? res.data.message : '' })
  //       setTimeout(() => this.managerFogetPassword(), global.redirect_time);
  //     }
  //   // } else {
  //     // if (!this.state.email) {
  //       this.setState({ emailError: "Please enter valid email." });
  //     // }
  //   // }
  //   this.setState({loader:false});
  }
  managerFogetPassword = () => {
    this.setState({forgetPasswordSuccess:"",forgetPasswordError:"",email:"",emailError:"",loginError:""});
    this.setState({ ForgetPassword: false })
  }
  // ResetPassword = async (e) => {
  //   e.preventDefault();
  //   await this.passwordCheckFun();
  //   this.setState({ loader: true });
  //   if (
  //     this.state.password !== "" &&
  //     this.state.passwordError === "" &&
  //     this.state.confirmPassword !== "" &&
  //     this.state.confirmPasswordError === ""
  //   ) {
  //     // Validate all fields before data submission
  //     this.setState({ isSubmit: true });
  //     let input = {
  //       new_password: this.state.password,
  //       password_confirmation: this.state.confirmPassword,
  //       reset_token: this.state.resetPasswordToken,
  //     };
  //     let res = await AuthenticationService.resetPassword(input);
  //     if (global.successStatus.includes(res.status)) {
  //       this.setState({
  //         resetPasswordSuccess: "Your password has been reset.",
  //       });
  //       setTimeout(() => this.managerResetPassword(), global.redirect_time);
  //     } else {
  //       this.setState({
  //         resetPasswordError: res.data.message ? res.data.message : "",
  //       });
  //       setTimeout(() => this.managerResetPassword(), global.redirect_time);
  //     }
  //   } else {
  //     if (!this.state.password) {
  //       this.setState({ passwordError: "Password is required." });
  //     }
  //     if (!this.state.confirmPassword) {
  //       this.setState({
  //         confirmPasswordError: "Confirm password is required.",
  //       });
  //     }
  //   }
  //   this.setState({ loader: false });
  // };

  managerResetPassword = () => {
    this.setState({
      resetPasswordError: "",
      resetPasswordSuccess: "",
      password: "",
      confirmPassword: "",
    });
    this.setState({ resetPasswordModal: false });
    window.location.href = "/";
  };

  changeHandler = (event) => {
    let value = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ resetPasswordError: "", resetPasswordSuccess: "" });
    if (event.target.name === "confirmPassword") {
      if (event.target.value.length > 4) {
        this.setState({ confirmPasswordError: "" });
      }
    }

    if (event.target.name === "password") {
      if (event.target.value.length > 4) {
        this.setState({ passwordError: "" });
      }
    }

    if (
      event.target.name === "confirmPassword" &&
      event.target.value.length >= 1
    ) {
      var pw =
        event.target.name === "password"
          ? event.target.value
          : this.state.password;
      var cpw =
        event.target.name === "confirmPassword"
          ? event.target.value
          : this.state.confirmPassword;

      if (pw !== cpw) {
        this.setState({
          confirmPasswordError: "Password and confirm password must be same.",
        });
      } else {
        this.setState({ confirmPasswordError: "" });
      }
    }
  };

  render() {
    return (
      <>
        <main className="oh-template Brandmainpage detail_page">
          <div className="bgresetPassword">
            <Modal
              show={this.state.resetPasswordModal}
              centered
              className="reservationBook"
            >
              <span
                className="welcomeClose float-end"
                onClick={() => this.managerResetPassword(false)}
              >
                {global.closee}
              </span>
              <ModalBody className="width_402 pb-5 border_radius_14">


                <div className="text-center">
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                      sx={{
                        // marginTop: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('logo-footer.png')} alt="Property Logo" title="Property Logo" />
                      </figure>
                      <div className="w-100 mt-3">
                        <Typography component="h1" variant="h3">
                          URL is not valid
                        </Typography>
                      </div>

                      <div className="w-100">
                        <Box
                          // component="form"
                          // onSubmit={this.loginUser}
                          // noValidate
                          sx={{ mt: 1 }}
                        >

                          <p className="text-center ">
                          The link you are trying to access is either expired or doesn't exist. Please re-initiate the password recovery to reset your account password.
                          </p>

                          <Grid container className="forget_text justify-content-center">
                            <Grid item>
                              <Link
                                href="#"
                                variant="body2"
                                onClick={(e) => this.ForgetPassword(e)}
                              >
                                Try again
                              </Link>
                            </Grid>
                          </Grid>

                        </Box>
                      </div>
                    </Box>
                  </Container>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </main>
      </>
    );
  }
}

export default InvaildUrl;
