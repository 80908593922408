import { TextField } from "@mui/material";
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import CommonHeader from "../common/CommonHeader";
import SpinnerLoader from "../common/SpinnerLoader";
import ReCAPTCHA from "react-google-recaptcha";
import ReservationService from "../../services/ReservationService";
import Moment from "moment";
import ReservationDetails from "./ReservationDetails";
import queryString from "query-string";
const queryParams = queryString.parse(location.search);
import { red } from "@mui/material/colors";
import WizardForm from "./WizardForm";
const recaptchaRef = React.createRef();

class CheckIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_slug: "",
      confirmation_code:
        props.match.params && props.match.params.confirmationCode
          ? props.match.params.confirmationCode
          : queryParams.confirmation_code
          ? queryParams.confirmation_code
          : "",
      page: queryParams.page ? queryParams.page : "",
      confirmation_code_error: "",
      emailError: "",
      last_name: "",
      last_name_error: "",
      recaptcha_error: "",
      loader: false,
      errorCount: 0,
      errorMsg: "",
      bookedDate:"",
    };
    this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }

  onChangeRecaptcha = (value) => {
    if (value !== "") {
      this.setState({ isValidRecatcha: true, recaptcha_error: "" });
    }
  };

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      var emailError = "Please enter valid email.";
      this.setState({ emailError: emailError });
    } else {
      var emailError = "";
      this.setState({ emailError: emailError });
    }
  };
  changeHandler = (event) => {
    let value = event.target.value;
    if (event.target.name === "email") {
      if (event.target.value.length > 8) {
        this.setState({ emailError: "" });
      }
    }
    if (event.target.name === "last_name") {
      if (event.target.value.length >= 2) {
        this.setState({ last_name_error: "" });
      }
    }
    if (event.target.name === "confirmation_code") {
      event.target.value = value.trim();
      if (event.target.value.length >= 3) {
        this.setState({ confirmation_code_error: "" });
      }
      const regex = /^([a-zA-Z0-9_-]){3,10}$/i;
      // const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
      if (
        event.target.value !== "" &&
        regex.test(event.target.value) === false
      ) {
        this.setState({
          confirmation_code_error: "Please enter valid confirmation code",
        });
      } else {
        this.setState({ confirmation_code_error: "" });
      }
    }
    this.setState({ [event.target.name]: value, errorMsg: "" });
  };
  componentDidMount() {
    if (this.state.page == "support" || this.state.confirmation_code) {
      this.getReservationDetails();
    }
  }

  onErrorRecaptcha = (value) => {
    // var errors = this.state.errors;
    // errors.recaptcha = "Verification expired. Check the checkbox again."
    this.setState({ isValidRecatcha: false });
    recaptchaRef.current.reset();
  };
  getGuestFormDetails = async (confirmationcode) => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecords = 0;
    var list = [];
    var formSubmited = "";
    var emailVerified = "";
    var card_tokenise = "";
    var parking_payment = "";
    var is_card_enable = "";
    let res = await ReservationService.getGuestformdata(confirmationcode);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;
      formSubmited = res.data.is_form_submited ? res.data.is_form_submited : 0;
      emailVerified = res.data.email_verified ? res.data.email_verified : 0;
      card_tokenise = res.data.card_tokenise ? res.data.card_tokenise : 0;
      parking_payment = res.data.parking_payment ? res.data.parking_payment : 0;
      is_card_enable = res.data.is_card_enable ? res.data.is_card_enable:0;
    }
    this.setState({
      is_form_submited: formSubmited,
      is_email_verified: emailVerified,
      card_tokenise: card_tokenise,
      parking_payment: parking_payment,
      is_card_enable:is_card_enable,
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
    });
    localStorage.setItem("is_form_submited", this.state.is_form_submited);
    localStorage.setItem("is_email_verified", this.state.is_email_verified);
    localStorage.setItem("card_tokenise", this.state.card_tokenise);
    localStorage.setItem("parking_payment", this.state.parking_payment);
    localStorage.setItem("card_enable", this.state.is_card_enable);
  };
  getReservationDetails = async () => {
    var list = [];
    var record = [];
    var totalRecords = 0;
    var bookedDate = '';
    
    this.setState({ loader: true });
    var captcha = true;
    if (this.state.errorCount >= 3) {
      const recaptchaValue = recaptchaRef.current.getValue();
      captcha = this.state.isValidRecatcha ? true : false;
    }
    if (
      captcha &&
      this.state.confirmation_code_error === "" &&
      this.state.confirmation_code
      // && this.state.last_name_error === "" &&
      // this.state.last_name !== ""
    ) {
      // var data = {
      //   confirmation_code: this.state.property_slug,
      //   last_name: this.state.property_slug,
      //   property_seo_slug: this.state.property_slug,
      //   user_slug: "",
      //   filter_by: 4,
      // };
      // let res = await ReservationService.getReservationList(data);
      var data = {
        confirmation_code: this.state.confirmation_code,
        last_name: this.state.last_name,
        email: this.state.email,
        property_seo_slug: this.state.property_slug,
        user_slug: "",
        filter_by: 4,
      };
      await this.getGuestFormDetails(this.state.confirmation_code);
      let res = await ReservationService.getMyBookReservation(data);
      // let res = this.state.res;
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {
        list = res.data ? res.data : [];
        record = res.data ? res.data : [];
        bookedDate = res.data ?  Moment(res.data.booked_at).format("MM/DD/YYYY") : '';
        this.setState({ loader: false ,bookedDate:bookedDate});
        // totalRecords = list ? list.length : 0;
        totalRecords = res.data ? res.data.total : 0;
      }
      if (this.state.errorCount >= 3) {
        recaptchaRef.current.reset();
      }
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {
        let statusText = "pending";

        const currentDate = new Date();
        var dateFrom = Moment(record.checkin_date).format("MM/DD/YYYY");
        var dateTo = Moment(record.checkout_date).format("MM/DD/YYYY");
        var dateCheck = Moment(currentDate).format("MM/DD/YYYY");
        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("/");
        if (record.status_id === 1 && c >= d1 && c <= d2) {
          statusText = "Confirmed";
        } else if (
          record.status_id !== 2 &&
          record.set_channel === 1 &&
          record.payment_status === 2 &&
          dateCheck > dateTo
        ) {
          statusText = "Confirmed";
        } else if (
          record.status_id !== 2 &&
          record.set_channel === 2 &&
          dateCheck > dateTo
        ) {
          statusText = "Completed";
        } else {
          if (record.set_channel === 1) {
            statusText =
              record.cancel_status === 0 && record.payment_status === 2
                ? "Confirmed"
                : record.cancel_status === 0 && record.status_id === 1
                ? "Payment Pending"
                : record.cancel_status === 1
                ? "Cancelled"
                : "Failed";
          }
          if (record.set_channel === 2) {
            statusText =
              record.status_id === 1 || record.cancel_status === 0
                ? "Confirmed"
                : "Failed";
          }
        }
        record.status_text = statusText;
        record.baged_class =
          statusText === "Confirmed"
            ? "baged-confirm"
            : statusText === "Confirmed"
            ? "baged-pending"
            : statusText === "Cancelled"
            ? "baged-cancel"
            : statusText === "Completed"
            ? "baged-completed"
            : statusText === "Failed"
            ? "baged-cancel"
            : "baged-cancel";
        // ${this.state.currentData.nightly_rate ? this.state.currentData.nightly_rate : 0 } x ${this.state.currentData.total_stay_nights ? (this.state.currentData.total_stay_nights > 1 ? this.state.currentData.total_stay_nights + "nights" : "1 night") : 0 }
        record.total_night_text =
          "$" +
          parseFloat(record.nightly_rate) +
          " x " +
          (record.total_stay_nights
            ? record.total_stay_nights > 1
              ? record.total_stay_nights + " nights"
              : "1 night"
            : "1 night");
        record.total_nightly_rate_disp =
          "$" +
          parseFloat(record.nightly_rate) * parseInt(record.total_stay_nights);
        await this.setState({
          total_record: totalRecords,
          reservationList: list,
          loader: false,
        });
        await localStorage.setItem("trip_details", JSON.stringify(list));
        this.setState({ reservationDetail: true, errorMsg: "" });
      } else {
        this.setState({
          errorMsg:
            "Details entered by you are incorrect. Please verify your confirmation code and last name you have used during booking.",
        });
      }
      // window.location.href = "/reservation-details";
    } else {
      var errorCount = this.state.errorCount;
      if (
        this.state.confirmation_code === "" ||
        this.state.confirmation_code === undefined
      ) {
        document.getElementById("confirmation_code").focus();
        this.setState({
          confirmation_code_error: "Please enter confirmation code",
        });
      }
      // if (!this.state.last_name) {
      //   document.getElementById("last_name").focus();
      //   this.setState({ last_name_error: "Last name is required" });
      // }
    }
    this.setState({ loader: false, errorCount: errorCount + 1 });
  };

  render() {
    const oldReservationDate = new Date("11/21/2023");
    const Datecheck = Moment(this.state.bookedDate).isBefore(oldReservationDate);
    return (
      <>
        {this.state.reservationDetail ? (
          (this.state.is_form_submited === 0 || this.state.is_form_submited === 2) && this.state.is_card_enable == 1  ?  (
            <WizardForm isCardEnable = {this.state.is_card_enable}  isformsubmited= {this.state.is_form_submited} card_tokenise = {this.state.card_tokenise} Datecheck = {Datecheck}/>
          ) : (
            <ReservationDetails  Datecheck = {Datecheck} />
          )
        ) : (
          <main className="oh-template Brandmainpage accessyourreservation checkin-mobile-over">
            {/* Header */}
            <CommonHeader CheckIn={true} />
            {/* middle */}
            <section className="ConfirmationBooking reservationBook middle-section">
              <div className="container">
                <div className="row- mobView">
                  <div className="col-lg-6 col-md-6 col-sm-8 d-block m-auto requestSection contact_form_box">
                    <h1 className="pd-l-15 pd-r-15 mg-b-30">
                      Access your reservation
                    </h1>
                    {this.state.errorMsg && (
                      <p className="error-message pd-l-15 pd-r-15 mg-b-30">
                        {this.state.errorMsg}
                      </p>
                    )}
                    <div className="pd-l-15 pd-r-15 mg-b-30">
                      <div className="countryphone">
                        <TextField
                          id="confirmation_code"
                          name="confirmation_code"
                          value={this.state.confirmation_code}
                          autoFocus={true}
                          onChange={this.changeHandler}
                          label="Confirmation code"
                          variant="outlined"
                          fullWidth
                          required
                          error={
                            this.state.confirmation_code_error ? true : false
                          }
                          helperText={
                            this.state.confirmation_code_error
                              ? this.state.confirmation_code_error
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="pd-l-15 pd-r-15 mg-b-30">
                      <div className="countryphone">
                        <TextField
                          id="last_name"
                          name="last_name"
                          value={this.state.last_name}
                          autoFocus={false}
                          onChange={this.changeHandler}
                          label="Last name"
                          variant="outlined"
                          fullWidth
                          // required
                          error={this.state.last_name_error ? true : false}
                          helperText={
                            this.state.last_name_error
                              ? this.state.last_name_error
                              : ""
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="pd-l-15 pd-r-15 mg-b-30">
                  <div className="countryphone">
                    <TextField
                      id="email"
                      name="email"
                      value={this.state.email}
                      autoFocus={false}
                      onChange={(e) => this.changeHandler(e)}
                      // onBlur={() => this.validateEmail()}
                      label="Email"
                      variant="outlined"
                      fullWidth
                      error={this.state.emailError ? true : false}
                      helperText={
                        this.state.emailError ? this.state.emailError : ""
                      }
                      
                     // error={this.state.last_name_error ? true : false}
                      // helperText={
                      //   this.state.last_name_error
                      //     ? this.state.last_name_error
                      //     : ""
                      // }
                    />
                  </div>
                </div> */}

                    {this.state.errorCount >= 3 && (
                      <div
                        id="recaptch-block"
                        className="w-100 mg-b-10 pd-l-15 pd-r-15"
                      >
                        <ReCAPTCHA
                          // theme="dark" // light
                          // onErrored:""
                          // badge="bottomright" //bottomleft inline
                          // size="normal" //"compact"
                          ref={recaptchaRef}
                          onChange={this.onChangeRecaptcha}
                          onExpired={() => this.onErrorRecaptcha}
                          onErrored={() => this.onErrorRecaptcha}
                          sitekey={"6Lezqe8jAAAAAJXeZgVxsekhhHeTC6B8V9sbe18u"}
                        />
                        {this.state.recaptcha_error !== "" ? (
                          <p className="error-message">
                            {this.state.recaptcha_error}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    <div className="pd-l-15 pd-r-15 mg-b-30 pd-l-15 pd-r-15 mg-b-30 d-flex justify-content-center m-auto">
                      <button
                        onClick={this.getReservationDetails}
                        disabled={this.state.loader ? true : false}
                        className="btn-checkin"
                      >
                        Submit
                        {this.state.loader && this.state.page != "support" ? (
                          <>
                            <SpinnerLoader />
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.page == "support" ? (
                          <>
                            {" "}
                            <div className="mob_fullscreen">
                              <SpinnerLoader />
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        )}
      </>
    );
  }
}

export default CheckIn;
