//     							                          //
//  Program: booktourform.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
class TermsStaic extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main className="oh-template Brandmainpage">
        <section className="middle">
          <div className="container disclaim_sec privacy_sec">


            <p className={"text-justify"}><strong><u>IMPORTANT</u>:</strong> PLEASE READ ALL OF THE FOLLOWING TERMS AND CONDITIONS OF SERVICE (THESE “TERMS”), CAREFULLY. THESE TERMS CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THESE INCLUDE (BUT ARE NOT LIMITED TO) VARIOUS LIMITATIONS AND EXCLUSIONS, A BINDING ARBITRATION CLAUSE, A CLASS ACTION WAIVER, A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AND OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.</p>
            <p className={"text-justify"}>OHAI provides an online platform that permits Tenants (as defined below) to manage short-term rentals and other Homesharing opportunities pursuant to a valid Homesharing Agreement (as defined below) among Tenant, Owner, and OHAI (as defined below) (hereinafter the “Platform”).  The Platform may be accessible by website (including through the Site, as defined below), mobile application, or other technology as designated by OHAI from time to time.  </p>
            <p className={"text-justify"}>These Terms include not only these Terms and Conditions of Service, but also incorporate by reference all policies, guidelines, special or supplemental terms and conditions of use or service posted by OHAI on through the Platform from time to time and as such may be amended, modified, supplement or restated by OHAI from time to time.  Our Privacy Policy available at <a href="/privacy" target="_blank"><strong>https://www.orionhaus.com/privacy</strong></a> (“Privacy Policy”) informs you of OHAI’ collection and use of your information, including your Personal Information as defined in the Privacy Policy, in relation to your use of our Platform and/or other related products or services OHAI may make available to you via or in relation to your use of the Platform.</p>
            <p className={"text-justify"}>By using or accessing the Platform, you acknowledge and agree to comply with and be legally bound by the terms and conditions of these Terms, whether or not you become a registered user of the Platform.  These Terms govern your access to and use of the Platform and constitute a binding legal agreement between you and OHAI.</p>
            <p className={"text-justify"}>By accessing, browsing, submitting any information, to or through, or otherwise in any manner using the Platform, and linking to these Terms or by accessing, submitting any information to or through, or otherwise in any manner using any service offered on or though this Platform, You acknowledge that you have read and understand, and agree to be bound by and to comply with all of these Terms.  If you do not agree to all of these Terms without any modification by you, you are not authorized to and must not access or use this Platform or any feature, functionality or service offered on or though this Platform.</p>
            <p className={"text-justify"}>Any person who accesses this Platform but does not successfully register to become a User is referred to in these Terms as a “Visitor.” Persons who access this Platform and successfully register to become a User are referred to as a User, Tenant, or Homesharing Guest as the case may be. Visitors and Users are also referred to in these Terms as “You”, “you”, “Your”, or “your.”  If you accept or agree to these Terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these Terms and, in such event, “you” and “your” will refer and apply to that company or other legal entity. </p>
            <ol>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Definitions</span>
                <p className={"text-justify"}>The following terms used in these Terms with their initial letters capitalized, and those terms defined in the introductory paragraphs or elsewhere in these Terms, throughout these Terms have the meanings provided.  Defined terms may be used in the singular, the plural or possessive form as the context requires.  Additionally, these Terms incorporate by reference those terms and phrases as defined in our Privacy Policy.</p>
                <ol>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Account”</strong> means with respect to Users, the account created by OHAI or the User on or through the Platform for a User’s use in connection with the OHAI Services.</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Collective Content”</strong> means OHAI Content and User Content. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Content”</strong> means any text, graphics, images, music, software (excluding any OHAI Software), audio, video, information, or other electronically stored data.</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Governmental Authority”</strong> means any domestic or foreign, federal, state, local, provincial, county, or municipal or supra national government or political subdivision thereof, or any agency, instrumentality, subdivision, court, tribunal, commission or other authority of such government or political subdivision, or any self-regulated, private, non-governmental or quasi-governmental organization, body or authority (to the extent that the rules, regulations or orders thereof have the force of Law).  </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Homesharing Guest”</strong> means a User that accesses and uses the Platform to manage their sharing, subleasing, or short-term rental of the Premises from a Tenant pursuant to a valid and confirmed reservation on which the Homesharing Guest is named, and their identification has been verified in advance.  Access to the Premises as a Homesharing Guest will be provided to only to those Users with a confirmed, active, and paid-for reservations.  Homesharing Guest may not have any unregistered visitors to the Premises unless approved by the Owner.  </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Homesharing Agreement”</strong> means that certain written agreement between Tenant, Owner, and OHAI (or its agent, licensee, or designee) governing Tenant’s limited right to engage in Homesharing with respect to the Premises. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Homesharing”</strong> means the sharing, subleasing, or short-term rental of the Premises by Tenant to Homesharing Guests. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Intellectual Property Rights”</strong> means, with respect to any thing, material or work (hereinafter, a “Work”): (a) any and all worldwide copyrights, trademarks, trade secrets and any other intellectual property and proprietary rights and legal protections in and to such Work including but not limited to all rights under treaties and conventions and applications related to any of the foregoing; (b) all patents, patent applications, registrations and rights to make applications and registrations for the foregoing; (c) all goodwill associated with the foregoing; (d) all renewals, extensions, reversions or restorations of all such rights; (e) all works based upon, derived from, or incorporating the Work; (f) all income, royalties, claims, and payments now or hereafter due or payable with respect thereto; (g) all causes of action, either in law or in equity for past, present or future infringement based on the Work; (h) all rights corresponding to each of the foregoing throughout the world; and (i) all the rights embraced or embodied therein, including but not limited to, the right to duplicate, reproduce, copy, distribute, publicly perform, display, license, adapt, prepare derivative works from the Work, together with all physical or tangible embodiments of the Work.</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Law”</strong>or<strong> or “Laws”</strong> means, as amended from time to time, any domestic or foreign federal, state, local, provincial, county or municipal or supra national Law (including common law), rule, regulation, statute, treaty, constitution, ordinance, decree, code, proclamation, policy, procedure, guideline, standard, order, judgment, or other legally enforceable requirement enacted, issued, adopted, promulgated, enforced, ordered or applied by any governmental authority. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“OHAI Content”</strong> means all Content that OHAI makes available through the Platform including any Content licensed from a third party, but excluding User Content.</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“OHAI Software”</strong> means any software, html, or other code, at any time or from time to time, in whole or in part, comprising the Platform or utilized by OHAI through the Platform.</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“OHAI”, “we”, “us”,</strong> or <strong> “our,”</strong> shall refer to OHAI Homes and Hotels, Inc. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Owner”</strong> means the owner of the Premises, or its agent, licensee, or designee. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Premises”</strong> means the property that Tenant has lawfully leased from Owner pursuant to a written lease agreement. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Services”</strong> means collectively all services OHAI offers through the Platform to Users and visitors, including any User Feature, OHAI Content, and OHAI Software, and the Site, among others.</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Site”</strong> means the website located at <a href="https://www.orionhaus.com/"><strong>www.orionhaus.com</strong></a> or its subdomains. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“Tenant”</strong> means a User that access and uses the Platform to manage Homesharing of their Premises pursuant to a valid Homesharing Agreement and that is a party to, and not in default of, a valid lease of the Premises from Owner. </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“User Content”</strong> means all Content that a User provides, posts, uploads, publishes, submits, transmits, or makes available on the Platform, including but not limited to all such Content provided for purposes of establishing, initializing, modifying, or creating an Account.</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“User Feature”</strong> means any feature or functionality of the Platform which OHAI may designate from time to time for use by OHAI Users for or in connection with the OHAI Services.  </p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}><strong>“User”</strong> means a person (acting either on their own behalf or on behalf of a company or other legal entity) who completes the Account registration process as described under the “Account Registration” section below and whose Account remains in good standing and has not otherwise been restricted or terminated by OHAI.</p>
                  </li>
                </ol>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Acknowledgements and Disclaimers</span>
                <ol>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Explanation of Services and Acknowledgements</span>
                    <p className={"text-justify"}>OHAI MAKES AVAILABLE THE PLATFORM WHICH IS AN SOCIAL PLATFORM THAT ENABLES TENANTS TO MANAGE HOMESHARING OPPORTUNITIES PURSUANT TO A VALID HOMESHARING AGREEMENT. UNLESS EXPLICITLY SPECIFIED OTHERWISE IN THE PLATFORM AND SET FORTH IN THESE TERMS OF SERVICE, OHAI'S RESPONSIBILITIES ARE LIMITED TO FACILITATING THE AVAILABILITY OF THE PLATFORM ONLY.  </p>
                    <p className={"text-justify"}>IF YOU CHOOSE TO USE THE PLATFORM TO COMMUNICATE, CONNECT, AND/OR MEET ANY OTHER PERSON OR USER (WHETHER ANOTHER TENANT, ANY GUEST, OR OTHERWISE) YOU DO SO AT YOUR SOLE RISK. YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER VISITORS AND USERS OF THE PLATFORM AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE PLATFORM. OHAI MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE PLATFORM OR THEIR INTERACTIONS WITH ANY CURRENT OR FUTURE USERS OF THE PLATFORM. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE PLATFORM AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE PLATFORM. YOU ACKNOWLEDGE AND AGREE THAT OHAI DOES NOT HAVE AN OBLIGATION TO CONDUCT BACKGROUND OR CRIMINAL CHECKS (INCLUDING REGISTERED SEX OFFENDER CHECKS) ON ANY USER BUT MAY CONDUCT SUCH BACKGROUND OR CRIMINAL CHECKS (INCLUDING REGISTERED SEX OFFENDER CHECKS) IN ITS SOLE DISCRETION.</p>
                    <p className={"text-justify"}>OHAI HAS NO CONTROL OVER THE CONDUCT OF USERS OF THE PLATFORM AND DISCLAIMS ALL LIABILITY IN THIS REGARD TO THE MAXIMUM EXTENT PERMITTED BY LAW.</p>
                    <p className={"text-justify"}><strong>IF YOU ARE A TENANT, YOU REPRESENT AND WARRANT THAT YOU HAVE A VALID HOMESHARING AGREEMENT WITH THE APPLICABLE OWNER AND OHAI.</strong></p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Disclaimers</span>
                    <p className={"text-justify"}>NEITHER WE NOR ANY OF OUR THIRD-PARTY LICENSORS OR SUPPLIERS MAKES ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO ANY ASPECT OF THE PLATFORM TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. WE HEREBY DISCLAIM ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, QUIET ENJOYMENT, OR ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE.  WE DO NOT WARRANT THAT THE PLATFORM WILL FUNCTION AS DESCRIBED, WILL BE UNINTERRUPTED OR ERROR-FREE, FREE OF HARMFUL COMPONENTS, THAT ANY CONTENT, FILES, DATA OR OTHER MATERIALS (INCLUDING BUT NOT LIMITED TO ANY USER CONTENT) UPLOADED TO, DOWNLOADED FROM, OR STORED BY OR WITHIN THE PLATFORM WILL BE TIMELY, CURRENT OR SECURE, OR WILL NOT BE LOST, CORRUPTED OR OTHERWISE DAMAGED OR IMPAIRED, OR THAT WE WILL BE ABLE TO PREVENT DISRUPTION OF OR TO ANY OF THE PLATFORM OR THAT WE WILL CORRECT ANY DEFECTS.</p>
                    <p className={"text-transform"}>Although we take reasonable steps to prevent the introduction of viruses, worms, TROJANS, or other malicious software (“malware”) or other destructive materials to the Services, OHAI does not guarantee or warrant that the Services or materials that may be downloaded from the Services do not contain such contaminating or destructive properties.</p>
                    <p className={"text-justify"}>IN NO EVENT WILL OHAI BE LIABLE FOR DAMAGES OF ANY KIND ARISING FROM THE USE OF THE SERVICES OR ANY INFORMATION OBTAINED THROUGH THE SERVICES, INCLUDING DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES (EVEN IF OHAI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
                  </li>

                </ol>

              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Modification of Platform or Terms</span>
                <p className={"text-justify"}>OHAI reserves the right, at its sole discretion, to modify the Platform or to modify these Terms at any time and without prior notice, and without penalty. If we modify these Terms – which may include updating, revising, amending, supplementing, restating, or terminating these Terms – we will either post the modification on the Platform or otherwise provide you with notice of the modification. Please also refer to the “Last Updated” date indicated at the beginning of these Terms.  Through continued access or use of the Platform after OHAI has posted a modification thereon or has provided you with notice of a modification, you are indicating that you agree to be bound by the modified Terms. If the modified Terms are not acceptable to you, you must discontinue use of the Platform.</p>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>User Eligibility</span>
                <p className={"text-justify"}>The use of this Platform is intended for persons who are eighteen (18) years of age or older. Any access to or use of any User Features, or the Platform by anyone under eighteen (18) years of age is prohibited. By registering on the Platform as a User or accessing or utilizing any User Features on or through the Platform you represent and warrant that you are eighteen (18) years of age or older.  Persons under the age of 18 may not avail themselves to any of the OHAI Services, unless accompanied by an eligible User over the age of eighteen (18) years of age.  In any event, by becoming a User, you represent and warrant that you are at least eighteen (18) years old and that you have the right, authority, and capacity to enter into and abide by all of these Terms.  Without limiting the foregoing, the use of the Platform is not available to Users or any other person or entity who or which do not have a valid Account or who or which have had their Account temporarily or permanently deactivated.</p>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Accounts</span>
                <ol>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Registration, Profile, and User Content</span>
                    <p className={"text-justify"}>In order to become a User and to access User Features or utilize or enjoy Services via the Platform you must register to create an Account.  You may register directly via the Platform or as described in this Section.  </p>
                    <p className={"text-justify"}>As a User, your Account and your Account profile page will be created based upon the User Content you provide to us.  In some instances, OHAI will provide you, either in person or by electronic means, information necessary to establish your Account.  Any information that you provide, publish or post to or through the Platform (including any profile information you provide) or send to other Users or Visitors (including via any User Feature, feedback, text, any email feature, or through any OHAI-related Facebook, Twitter or other social media posting) will be considered User Content. You consent to us using your User Content to create an Account that will allow you to utilize features of the Platform reserved for our Users. Our collection and use of personal information in connection with the Platform is as provided in OHAI’s Privacy Policy located at <a href="/privacy"><strong>www.orionhaus.com/privacy</strong></a>.</p>
                    <p className={"text-justify"}>As a User, you are solely responsible for your User Content and your interactions with other members of the public, including other Users.  You agree to provide and maintain accurate, current, and complete information and that OHAI and visitors to the Platform may rely on your User Content as accurate, current, and complete.</p>
                    <p className={"text-justify"}>As a User, you are the sole authorized user of your Account, and are responsible for maintaining the confidentiality of any password provided by you or OHAI for accessing User Features. You are solely and fully responsible for all activities that occur under your Account (even if that content or activity occurs from other individuals who have accessed the Platforms and other Services through your account), and OHAI expressly disclaims any liability arising from the unauthorized access or use of your Account. If at any time you suspect that any unauthorized party may be using your Account or you suspect any other breach of security, you agree to notify us immediately.</p>
                    <p className={"text-justify"}>In some cases, OHAI may utilize third-party user account management providers (such as Facebook, Google, Microsoft, Apple, and the like), to establish and manage your Account. If you are directed to any such OHAI third-party account management provider, you may be subject to terms and conditions governing use of that third-party’s service and that third-party’s personal information collection practices.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Limitation, Suspension, Termination, and Cancellation of Accounts</span>
                    <p className={"text-justify"}>Unless otherwise authorized by OHAI, as a User,<strong> you may not have more than one </strong> (1) Account with OHAI at any given time.  You must (and you agree to) provide accurate, current, and complete information as requested or prompted during or in connection with the Account registration process, and to promptly update such information to keep it accurate, current, and complete. OHAI reserves the right to suspend, restrict, or terminate your Account and your access to the User Features if you provide any false or misleading information, or otherwise violate any of these Terms. You are responsible for safeguarding your password and agree not to disclose your password to any third-party.  You have sole responsibility for any activities or actions conducted under your Account, whether or not you have authorized such activities or actions. You are obligated to immediately notify OHAI of any unauthorized use of your Account.  </p>
                    <p className={"text-justify"}>OHAI may, in our sole discretion and without thereby incurring any liability to you or otherwise, with or without cause, with or without prior notice and at any time, limit, suspend, deactivate, or cancel your Account and/or deny you access to the User Features or other Services.  If we exercise our discretion under these Terms to do so, any or all of the following may occur with or without prior notice or explanation to you: (a) your Account will be deactivated or suspended, your password will be disabled, and you will not be able to access the User Features or your Account; and (b)  any pending or future orders for Services will be immediately terminated and refunded, if applicable.  Upon termination, deactivation, or suspension of your Account, you are required to immediately cease all use of the User Features and may not re-register under any other Account.  </p>
                    <p className={"text-justify"}>You may cancel your Account at any time via the “Cancel Account” or other similarly designated feature of the Platform, or by notifying us thereof by e-mail at <a href="mailto:support@orionhaus.com"><strong>support@orionhaus.com </strong></a>or by contacting us by telephone at <a href="tel:+8888888888"><strong> (888) 888-8888</strong></a>.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>OHAI Communications</span>
                    <p className={"text-justify"}>By becoming a User, you acknowledge that we may from time to time and as needed communicate with you by various means, including via e-mail, text message, telephone calls, and push notifications to the telephone number you provide to us, in relation to your use of the Platform, the status of your Account, your use of Services, among others.  Please note that this may include communications generated by automatic telephone dialing systems or other methods and systems, which will deliver prerecorded messages sent by or on behalf of OHAI, its affiliated companies, or other Users, including but not limited to operational communications concerning your Account, use of User Features or other Services, and updates concerning new and existing features on the OHAI Platform.</p>
                    <p className={"text-justify"}>In other instances, we will seek your consent to receive our communications before contacting you by asking you to “opt-in” to such communications.  This may include receiving our newsletter, promotions and promotional materials provided by OHAI or third parties, and updates and news concerning OHAI.</p>
                    <p className={"text-justify"}>Where we have already received your opt-in consent to receive certain communications, IF YOU WISH TO OPT-OUT OF PROMOTIONAL EMAILS, TEXT MESSAGES, OR OTHER SUCH “OPT-IN” COMMUNICATIONS, YOU MAY OPT-OUT BY FOLLOWING THE UNSUBSCRIBE OPTIONS THAT ARE PROVIDED TO YOU ON THE PLATFORM OR VIA THE COMMUNICATION, IF APPLICABLE (e.g., the “unsubscribe” button in our promotional emails). Standard text messaging charges applied by your telephone phone carrier will apply to text messages we send.</p>

                  </li>
                </ol>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Financial Terms</span>
                <p className={"text-justify"}>You agree to pay OHAI for all fees and charges associated with your use of the Platform (if any), pursuant to the express terms and conditions of your Homesharing Agreement.</p>
                <p className={"text-justify"}>In connection with your use of the User Features (including during your Account registration), you may be asked to provide customary billing information such as name, billing address, and credit card or other payment information either to OHAI or its third-party payment processor(s). If you are directed to any OHAI third party payment processor(s), you may be subject to terms and conditions governing use of that third-party’s service and that third-party’s personal information collection practices. </p>
                <p className={"text-justify"}>In furtherance of your use of the User Features, you understand and agree that OHAI reserves the right, in its sole discretion, to (i) obtain a pre-authorization via your credit card or other payment method for any fees or charges for or associated with Services and/or (ii) charge your credit card or other payment method a nominal amount, not to exceed One Dollar  ($1.00) per verification, or a similar sum in the currency in which you are transacting, to verify your credit card or other payment method including for processing or establishing recurring or subscription-based fee collection. OHAI does not control, and is not responsible for, any fees or charges that may be charged to you by your bank, financial institution, or payment provider, with respect to OHAI’s collection of fees or payments, and OHAI expressly disclaims all (and you agree that OHAI will have no) liability in this regard.</p>
                <p className={"text-justify"}>OHAI will take reasonable action to rectify any payment processing errors that we become aware of. These steps may include crediting or debiting (as appropriate) the same payment method used for the original payout to or payment by you.</p>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>User Conduct</span>
                <p className={"text-justify"}>You understand and agree that you alone are responsible, at your sole cost and expense, for compliance with any and all Laws that may apply to your use of the Platform or any feature thereof.  In connection with your use of the Platform, you must not, and you agree that you shall never do or attempt to do any of the following:</p>
                <ol className="userConduct">
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>violate any applicable Law;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>use manual or automated software, devices, scripts, robots, backdoors or other means or processes to access, “scrape,” “crawl” or “spider” any web pages or other features or services contained in or comprising the Platform;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>access or use the Platform or any part thereof to use, expose, or allow to be used or exposed, any OHAI Content in any way that is inconsistent with the OHAI Privacy Policy or these Terms; or which in any way otherwise violates any rights of any of OHAI’s Users or any other person or entity;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>use the Platform or any part thereof or any feature or functionality accessed on or through the Platform for any commercial or other purposes that are not expressly permitted by these Terms;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>copy, store or otherwise access or use any information contained on or accessed through the Platform for purposes not expressly permitted by these Terms;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>infringe the rights of any person or entity, including without limitation, any intellectual property, privacy, publicity, or contractual rights;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>interfere with or damage the Platform, or any part thereof or any feature or functionality accessed on or through the Platform - whether through the use of viruses, cancel bots, Trojans, harmful code, flood pings, denial-of-service attacks, backdoors, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology or otherwise;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>use the Platform, or any part thereof or any feature or functionality accessed on or through the Platform to transmit, distribute, post, or submit any information concerning any other person or entity, including without limitation, photographs of others without their permission, personal contact information or credit, debit, calling card or account numbers;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>use the Platform, or any part thereof or any feature or functionality accessed on or through the Platform for or in connection with the distribution of unsolicited commercial email (“spam”);</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>stalk, harass, or bully any other user of the Platform;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>register for more than one Account or register for an Account on behalf of an individual other than yourself;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>recruit or otherwise solicit any User to participate in a third-party services or websites that are competitive to OHAI, without OHAI’s prior written approval;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>use automated scripts to collect information from or otherwise interact with the Platform;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>post, upload, publish, submit or transmit any Content that (i) infringes, misappropriates or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other Intellectual Property Rights, or rights of publicity or privacy, (ii) violates, or encourages any conduct that would violate, any applicable Law or regulation or would give rise to civil liability, (iii) is fraudulent, false, misleading or deceptive, (iv) is defamatory, obscene, pornographic, vulgar or offensive, (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group, (vi) is violent or threatening or promotes violence or actions that are threatening to any other person, or (vii) promotes illegal or harmful activities or substances;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>systematically retrieve data or other content from the Platform to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>use, display, mirror or frame the Platform, or any individual element within or comprising any part or feature of the Platform, OHAI’s name, any OHAI trademark, service mark, trade dress, logo or other proprietary source identifiers, any materials or information, or the layout and design of any page or form contained on a page of the Platform;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>access, tamper with, or use non-public areas of the Platform, OHAI’s computer systems, or the technical delivery systems of OHAI’s providers;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>attempt to probe, scan, or test the vulnerability of any OHAI system or network or breach any security or authentication measures;</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by OHAI or any of OHAI’s providers or any other third party (including another user) to protect the Platform; and/or</p>
                  </li>
                  <li className={"text-justify"}>
                    <p className={"text-justify"}>attempt to decipher, decompile, disassemble, or reverse engineer any of the software used to provide the Platform or any feature or functionality thereof.</p>
                  </li>
                </ol>
                <p className={"text-justify"}>OHAI at all times has the right to investigate and may prosecute violations of any of the foregoing to the fullest extent of the Law.</p>
                <p className={"text-justify"}>OHAI may access, preserve and disclose any of your information if we are required to do so by Law or enforceable legal obligation, or if we believe in good faith that it is reasonably necessary to (i) respond to claims asserted against OHAI, (ii) to comply with a lawful and enforceable request by law enforcement, judicial body, or other public authorities, or in connection with a legal obligation, or other enforceable legal process (for example, court order, subpoena, warrant, etc.), (iii) enforce or administer our agreements with users (iv) for fraud prevention, risk assessment, investigation, customer support, product development and de-bugging purposes, or (v) protect the rights, property or safety of OHAI, its users, or members of the public. You acknowledge that OHAI has no obligation to monitor your access to or use of the Platform but has the right to do so for the purpose of operating and improving the Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes), to ensure your compliance with these Terms, to comply with applicable law or the lawful and enforceable order or requirement of a court, administrative agency or other governmental body, to respond to content that it determines is otherwise objectionable or as set forth in these Terms. OHAI reserves the right, at any time and without prior notice, to remove or disable access to any materials that OHAI, at its sole discretion, considers to be objectionable for any reason, in violation of these Terms or otherwise harmful to the Platform.</p>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Intellectual Property and User License Agreement</span>
                <ol>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>General Intellectual Property Provisions</span>
                    <p className={"text-justify"}>The Platform is protected by copyright, trademark, and other Laws of the United States and foreign countries. You acknowledge and agree that the Platform, Services, and OHAI Content, including all associated Intellectual Property Rights, are the exclusive property of OHAI and its licensors (hereinafter “OHAI Intellectual Property”). You will not remove, alter, or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Platform or Collective Content.  All trademarks, trade names, and source identifiers of OHAI used on or in connection with the Platform are trademarks or registered trademarks of OHAI.  Trademarks, trade names, and any other source identifiers of third parties used on or in connection with the Platform are used for identification purposes only and may be the property of their respective owners.</p>
                    <p className={"text-justify"}>Nothing in these Terms grants, transfers or conveys, nor may be construed or operate as a grant, transfer or conveyance, to any User or any other person of any right, title or interest in or to any of OHAI Intellectual Property therein, including but not limited to any trademark, trade name, service mark or other proprietary identifying symbols used by us from time to time on or in connection with the Platform, or any feature or functionality thereof.  No User or other person may use any of OHAI Intellectual Property without in each case first obtaining our prior written consent, which consent may be withheld in our sole and absolute discretion.  All goodwill arising from or incident to any use of OHAI Intellectual Property shall inure exclusively to us or our third-party licensors as applicable.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Non-Assertion</span>
                    <p className={"text-justify"}>You agree you shall not assert, nor will you authorize, assist, or encourage any third-party to assert, against us or any of our affiliates, business partners, licensors, licensees or transferees, any patent infringement or other Intellectual Property infringement claim with respect to the Platform.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Notice of Infringement</span>
                    <p className={"text-justify"}>OHAI respects applicable intellectual property Laws and expects all Users to do the same. It is OHAI’s policy to terminate in appropriate circumstances access of Users who repeatedly infringe or are believed to be repeatedly infringing the rights of Intellectual Property owners. Claims of trademark, copyright, or patent infringement or any other alleged Intellectual Property violations should be sent to OHAI’s designated agent. </p>
                    <p className={"text-justify"}>If you are an intellectual property owner, or are authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged infringements taking place on or through the Platform by submitting a complete Notice of Alleged Infringement as described below and delivering it to OHAI. Upon receipt of the Notice as described below, OHAI will take whatever action, in its sole discretion, it deems appropriate, including removal of the alleged infringing content from the Platform.</p>
                  </li>

                  <span className={"text-justify"}>Notice of Alleged Infringement </span>
                  <ol className="userConduct">
                    <li className={"text-justify"}>
                      <p className={"text-justify"}>Provide your mailing address, telephone number, and, if available, email address.</p>
                    </li>
                    <li className={"text-justify"}>
                      <p className={"text-justify"}>Identify the intellectual property that you claim has been infringed by providing a comprehensive list of the items (works, trademarks, etc.) that you claim have been infringed.  If your work is registered with the United States Patent and Trademark Office, United States Copyright Office, or other intellectual property office or jurisdiction, you must include copies of the registration certificates or information sufficient to confirm that such work is in in fact protected.</p>
                    </li>
                    <li className={"text-justify"}>
                      <p className={"text-justify"}>Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the Platform where such material may be found.</p>
                    </li>
                    <li className={"text-justify"}>
                      <p className={"text-justify"}>Provide your full legal name and electronically sign and affirm the following statement:  "I hereby affirm that I have a good faith belief that the disputed use of the material is not authorized by the owner, its agent, or the law (e.g., as a fair use).  I affirm that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the Intellectual Property or of an exclusive right under the Intellectual Property that is allegedly infringed."</p>
                    </li>
                    <li className={"text-justify"}>
                      <p className={"text-justify"}>Deliver this Notice, with all items completed, to OHAI by email to <a href="mailto:IP@orionhaus.com"><strong>IP@orionhaus.com</strong></a> </p>
                    </li>
                  </ol>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>OHAI License</span>
                    <p className={"text-justify"}>OHAI grants Users a limited, non-exclusive, non-transferable license, to (i) access and use the Platform and any OHAI Software; (ii) access and view any OHAI Content solely for your use of the Platform and (iii) access and view any User Content to which you are permitted access, solely for your use of the Platform. You have no right to sublicense the license rights granted in this section.  You agree not to use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast, or otherwise exploit the Platform or Collective Content, except as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise to OHAI Intellectual Property, except for the licenses and rights expressly granted in these Terms.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>User Content License</span>
                    <p className={"text-justify"}>OHAI may, in our sole discretion, permit you to post, upload, publish, submit, or transmit User Content. By making available any User Content on or through the Platform, you hereby grant to OHAI a worldwide, irrevocable, perpetual (or for the term of the protection), non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such User Content on, through, by means of, or to promote or market the Platform. OHAI does not claim any ownership rights in any such User Content and nothing in these Terms will be deemed to restrict any rights that you may have to use and exploit any such User Content.</p>
                    <p className={"text-justify"}>You acknowledge and agree that you are solely responsible for all User Content that you make available through the Platform. Accordingly, you represent and warrant that (i) you either are the sole and exclusive owner of all User Content that you make available through the Platform or you have all rights, licenses, consents and releases that are necessary to grant to OHAI the rights in such User Content, as contemplated under these Terms, and (ii) neither the User Content nor your posting, uploading, publication, submission or transmittal of the User Content or OHAI’s use of the User Content (or any portion thereof) on, through or by means of the Platform will infringe, misappropriate or violate a third party’s patent, copyright, trademark, trade secret, moral rights or other proprietary rights or Intellectual Property, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Suggestions</span>
                    <p className={"text-justify"}>If you provide any suggested improvement(s) to the Platform or any feature or functionality thereof (each a “Suggestion” and collectively “Suggestions”) to OHAI, we will own all right, title and interest (including any Intellectual Property Rights) in and to any Suggestion even if the person making the Suggestion has designated the Suggestion as confidential or proprietary.  OHAI shall be entitled to use and exploit any Suggestion without restriction.  You must, and hereby do, irrevocably assign to OHAI all right, title and interest (including all Intellectual Property Rights) in and to all Suggestions (if any) and agree to provide us or our designees with any assistance we may, or they may reasonably request or require from time to time to further document, perfect and maintain our rights in any Suggestion.</p>
                  </li>
                </ol>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Affiliate Marketing; Third-Party Websites, Applications, Links, and Resources</span>
                <p className={"text-justify"}>The Platform may contain links to or otherwise interface or connect with third-party websites or resources, such social media sites, and payment processors. You acknowledge and agree that OHAI is not responsible or liable for (i) the availability or accuracy of such websites or resources or (ii) the content, products, or services on or available from such websites or resources. </p>
                <p className={"text-justify"}><strong>AFFILIATE MARKETING DISCLAIMER:</strong>  In addition, in some instances and from time to time we may provide through the Platform links to certain third-party products and services and those links may be affiliate links.  If you click on those links and chose to make a purchase from such a third-party, we may in certain instances earn a small commission from that third-party.  The commission is paid to us by the third-party, not from you and you will incur no additional cost.  </p>
                <p className={"text-justify"}>Links to third-party websites or resources do not imply any endorsement by OHAI of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources or the content, products, or services on or available from such websites or resources.  </p>

              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Limitation of Liability </span>
                <p className={"text-justify"}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE PLATFORM, OR ANY FEATURE OR SERVICE ACCESSED OR OFFERED ON OR THROUGH THE PLATFORM REMAINS WITH YOU.</p>
                <p className={"text-justify"}>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER OHAI NOR ANY OF OUR AGENTS, REPRESENTATIVES, CONTRACTORS OR AFFILIATES, SHALL BE LIABLE TO ANY USER, ANY USER, ANY GUESTS OR OTHERWISE, FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, FILES, DATA, CONTENT, BUSINESS, OPPORTUNITIES, REVENUES, ANTICIPATED SAVINGS OR OTHERWISE (EVEN IF WE OR THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) OR FOR ANY DAMAGES FOR BODILY INJURY OR EMOTIONAL DISTRESS IN CONNECTION WITH THE PLATFORM, INCLUDING, WITHOUT LIMITATION, ANY SUCH DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH (I) ACCESS TO OR USE OF, OR THE INABILITY TO ACCESS OR USE, THE SYSTEM OR ANY SERVICE OR ANY PART THEREOF, (II) THE PROVISION OF OR FAILURE TO PROVIDE, OR ANY INTERRUPTION OF, ANY OHAI SERVICE OR ANY OTHER SERVICE, (III) ANY INFORMATION, SOFTWARE, PRODUCT, SERVICE, DATA, FILE, CONTENT, OR MATERIALS STORED ON, OBTAINED THROUGH, UPLOADED TO OR DOWNLOADED FROM THE SYSTEM OR ANY SERVICE, OR ANY DAMAGE TO OR CORRUPTION OR LOSS OF ANY OF THE FOREGOING, (IV) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES OR CONTENT, OR (V) ANY ACCESS TO OR USE OF, OR ANY INABILITY TO ACCESS OR USE ANY CONTENT OR MATERIALS. </p>
                <p className={"text-justify"}>IN THE EVENT THAT NOTWITHSTANDING THE PROVISIONS OF THE FOREGOING PARAGRAPHS OF THIS SECTION, WE (OR ANY OF OUR AGENTS, REPRESENTATIVES, CONTRACTORS, AFFILIATES OR THIRD PARTY LICENSORS OR SUPPLIERS) ARE FOUND LIABLE TO ANY USER, USER OR GUEST OR OTHERWISE FOR DAMAGES FROM ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF ACTION OR THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY, THE AGGREGATE AMOUNT OF ALL SUCH LIABILITY SHALL BE LIMITED TO AND SHALL NOT EXCEED THE AMOUNT ACTUALLY PAID TO US BY USER OR YOU (AS APPLICABLE) FOR AND WITH RESPECT TO THE PARTICULAR SERVICES THAT GIVE RISE TO SUCH CLAIM IN WHICH THE CAUSE OF ACTION ACCRUED. </p>
                <p className={"text-justify"}>THE FOREGOING LIMITATIONS AND EXCLUSIONS APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Indemnification </span>
                <p className={"text-justify"}>You agree and must at all times indemnify, defend and hold OHAI and its third-party suppliers or licensors, each of our and their business partners and each of our and their respective employees, officers, directors, managers and representatives (each an “Indemnitee” and collectively the “Indemnitees”), harmless from and against any and all losses, damages, liabilities, judgments, penalties, fines, costs and expenses (including attorneys’ fees and court costs) suffered or incurred by any of the Indemnitees as a result of any third-party claim, action, suit, or proceeding arising out of or incident to (i) any User or other person’s use of or access to the Platform in a manner not authorized by these Terms, or in violation of any applicable laws, (ii) Content including any claim involving infringement or misappropriation of third-party rights or the use, development, design, manufacture, production, advertising, promotion or marketing of User Content, (iii) any breach by User or any other person of any term or condition of these Terms, including without limitation, any representation and warranty hereunder, or (iv) any act, omission or misconduct of User or any other person using or accessing the Platform. </p>
                <p className={"text-justify"}>We will promptly notify you of any claim which we become aware of and which we believe to be subject to indemnification under this Section; provided that our failure to promptly notify you shall not affect your indemnification obligations hereunder except to the extent that our failure to promptly notify you materially prejudices your ability to defend the claim. At our option, you will have the right to defend against any such claim(s) with counsel of your own choosing (subject to a conflicts assessment), and to settle such claim as you deem appropriate, provided that you (nor any Person acting on your behalf) may enter into any settlement without OHAI’s prior written consent (which may be conditioned or withheld in our sole and absolute discretion) and provided that we may, at any time, elect to take over control of the defense and settlement of any claim.</p>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>Dispute Resolution </span>
                <ol>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Arbitration</span>
                    <p className={"text-justify"}>If you reside in the United States, you and OHAI agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof, or to the use of the Platform (collectively, “Disputes”) will be settled by binding arbitration, except that each party retains the right to seek injunctive or other equitable relief (and associated legal relief) in a court of competent jurisdiction for any dispute directly arising out of or related to your Homesharing Agreement or to redress or prevent the actual or threatened infringement, misappropriation or violation of a Party’s copyrights, trademarks, trade secrets, patents, or other Intellectual Property Rights. You acknowledge and agree that you and OHAI are each waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action lawsuit, class-wide arbitration, private attorney general action, or any other representative proceeding. Further, unless both you and OHAI otherwise agree in writing, the arbitrator may not consolidate more than one person’s claims and may not otherwise preside over any form of any class or representative proceeding. </p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Rules and Governing Law</span>
                    <p className={"text-justify"}>This agreement to arbitrate evidences a transaction in interstate commerce, and thus the Federal Arbitration Act governs the interpretation and enforcement of this provision. The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this “Dispute Resolution” section.  The AAA Rules are available at www.adr.org/Rules. The Federal Arbitration Act will govern the interpretation and enforcement of this section.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Process</span>
                    <p className={"text-justify"}>A Party who desires to initiate arbitration must provide the other Party with a written Demand for Arbitration as specified in the AAA Rules. (The AAA provides a form Demand for Arbitration.) The arbitrator will be either a retired judge or an attorney licensed to practice law in the state of Florida and will be selected by the Parties from the AAA’s roster of consumer dispute arbitrators. If the Parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the AAA Rules.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Location and Procedure</span>
                    <p className={"text-justify"}>Unless you and OHAI otherwise agree, the arbitration will be conducted in Broward County, Florida, United States.  If your claim does not exceed Ten Thousand Dollars ($10,000.00) then the arbitration will be conducted solely on the basis of documents you and OHAI submit to the arbitrator, unless you request a hearing, or the arbitrator determines that a hearing is necessary. If your claim exceeds Ten Thousand Dollars ($10,000.00), your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the Parties, consistent with the expedited nature of the arbitration.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Decision</span>
                    <p className={"text-justify"}>The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s award damages must be consistent with the terms of the “Limitation of Liability” section above as to the types and the amounts of damages for which a Party may be held liable. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. If you prevail in arbitration, you will be entitled to an award of attorneys’ fees and expenses, to the extent provided under applicable law. If OHAI prevails in arbitration, it will be entitled to an award of attorney’s fees and expenses, to the extent provided under applicable law. </p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Fees</span>
                    <p className={"text-justify"}>Your responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in the AAA Rules. </p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Changes</span>
                    <p className={"text-justify"}>Notwithstanding the provisions of the “Modification” section above, if OHAI changes this “Dispute Resolution” section after the date you first accepted these Terms (or accepted any subsequent changes to these Terms), you may reject any such change by sending us written notice (including by email) within thirty (30) days of the date such change became effective, as indicated in the “Last Updated” date above or in the date of OHAI’s email to you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any Dispute between you and OHAI in accordance with the provisions of this “Dispute Resolution” section as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).</p>
                  </li>

                </ol>
              </li>
              <li className={"text-justify"}>
                <span className={"text-justify"}>General Provisions</span>
                <ol>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>General Rules of Construction </span>
                    <p className={"text-justify"}>For purposes of these Terms: (i) whenever the context requires, the singular number shall include the plural, and vice versa; (ii) the masculine gender shall include the feminine and neuter genders; (iii) the feminine gender shall include the masculine and neuter genders; and (iv) the neuter gender shall include the masculine and feminine genders.  Any rule of construction to the effect that ambiguities are to be resolved against the drafting party shall not be applied in the construction or interpretation of these Terms.  As used in these Terms: (i) the words “hereof”, “hereunder”, “herein” and words of similar import refer to these Terms as a whole and not to any particular provision of these Terms; (ii) the words “include” and “including”, and variations thereof, shall not be deemed to be terms of limitation, but rather shall be deemed to be followed by the words “without limitation”; (iii) except as otherwise expressly indicated, all references in these Terms to “Sections”, “Subsections”, “Paragraphs”, “Schedules”, “Exhibits” are intended to refer to sections, subsections or paragraphs of these Terms; and (iv) the word “or” means “and/or”.  </p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Notices </span>
                    <p className={"text-justify"}>Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms, will be in writing and given by OHAI (i) via email (in each case to the address that you provide) or (ii) by posting to the Platform. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Controlling Law and Jurisdiction </span>
                    <p className={"text-justify"}>These Terms and your use of the Services will be interpreted in accordance with the laws of the State of Florida and the United States of America, without regard to its conflict-of-law provisions. You and we agree to submit to the exclusive personal jurisdiction of a state court located in Broward County, Florida or a United States District Court, Southern District of Florida located in Broward County, Florida for any actions for which the parties retain the right to seek injunctive or other equitable relief (and associated legal relief) in a court of competent jurisdiction concerning any dispute directly arising out of or related to your Homesharing Agreement or to prevent the actual or threatened infringement, misappropriation or violation of a Party’s copyrights, trademarks, trade secrets, patents, or other Intellectual Property Rights, as set forth in the Dispute Resolution provision.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Severability </span>
                    <p className={"text-justify"}>If any provision of these Terms, or the application of such provision, is held invalid by a court of competent jurisdiction, the remainder of these Terms, and the application of such provision to persons, entities, or circumstances other than those with respect to which it is held invalid, shall not be affected.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Waiver and Enforceability </span>
                    <p className={"text-justify"}>The failure of OHAI to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of OHAI. Except as expressly set forth in these Terms, the exercise by either Party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. If for any reason an arbitrator or a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible, and the other provisions of these Terms will remain in full force and effect.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Third-Party Beneficiary</span>
                    <p className={"text-justify"}>These Terms do not and are not intended to confer any rights or remedies upon any person other than the Parties. Notwithstanding the foregoing, the Parties agree that the payment card networks are third- party beneficiaries of these Terms for purposes of enforcing provisions related to payments, but that their consent or agreement is not necessary for any changes or modifications to these Terms.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Term and Termination </span>
                    <p className={"text-justify"}>These Terms are effective upon your creation of an Account, as amended by any modifications made by OHAI as outlined at the start of these Terms. You may discontinue your use of User Features or your Account at any time, for any reason. We may suspend or deactivate your Account or revoke your permission to access the Platform or any or all User Features, at any time, for any reason, with or without notice to you. We reserve the right to refuse access to the Platform or any User Feature to any User for any reason not prohibited by applicable Law. Either Party may terminate the Terms for any reason upon written notice to the other Party. </p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Survival </span>
                    <p className={"text-justify"}>Sections I (Definitions), II (Acknowledgments and Disclaimers), VI (Financial Terms), VIII (Intellectual Property and User License Agreement), X (Limitation of Liability), XI (Indemnification), XII (Dispute Resolution), and XIII (General Provisions) shall survive termination of these Terms.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Entire Agreement </span>
                    <p className={"text-justify"}>These Terms constitute the entire and exclusive understanding and agreement between OHAI and you regarding your use of the Platform and these Terms supersede and replace any and all prior oral or written understandings or agreements between OHAI and you regarding the Platform.  In the event of a conflict or inconsistency between these Terms and your Homesharing Agreement, the terms and conditions set forth in your Homesharing Agreement shall prevail.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>Assignment </span>
                    <p className={"text-justify"}>These Terms are neither assignable, transferrable nor delegable by you whether by operation of Law or otherwise. Any attempt by you to assign, transfer, or delegate any of these Terms, will be null and void and of no effect. OHAI may assign, transfer, or delegate these Terms, at its sole discretion, without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the respective Parties, their successors and permitted assigns.</p>
                  </li>
                  <li className={"text-justify"}>
                    <span className={"text-justify"}>How to Contact Us </span>
                    <p className={"text-justify"}>If you have any questions regarding these Terms or our privacy practices, you can contact us at:</p>
                    <div className="howtocontactus">
                      <p className={"text-justify"}>OHAI Homes and Hotels, Inc. </p>
                      <p className={"text-justify"}>Fort Lauderdale, FL 33335Attn: Legal Department </p>
                      <p className={"text-justify"}>Phone:  516-636-7466 </p>
                      <p className={"text-justify"}>Or by email at: <a href="mailto:privacy@orionhaus.com"><strong>privacy@orionhaus.com </strong></a></p>
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </section>
      </main >
    );
  }
}
export default TermsStaic