//     							                          //
//  Program: aminity.jsx                                  //
//  Application: KINI HTML                                //
//  Option: Aminity                                       //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import CmsDataService from "../../services/CmsDataService";
import { Helmet } from "react-helmet-async";

class Aminity extends Component {
  constructor(props) {
    super(props);
    // console.log("amenft",props);
    this.state = {
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      property_name: localStorage.getItem("property_name"),
      metaKeywords:
        "home,realtor,houses for rent,homes for sale,property,property for sale,homes for rent,apartments for rent near me",
      metaDescription:
        " Traditional real estate advertising focuses on outbound leads, which involves targeted prospecting. This method allows you to hone in probable leads, which increases the likelihood of success.",
    };
  }

  componentDidMount() {
    this.getcmsCommonData();
    this.getAmenitylist();
  }

  getAmenitylist = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    let Amenitylist = [];
    let AmenitylistTotal = 0;
    let Amenitydivide = 0;
    let res = await CmsDataService.getAmenitylist(this.state.property_slug);
    if (global.successStatus.includes(res.status)) {
      Amenitylist = res.data ? res.data : [];
      AmenitylistTotal = Amenitylist ? Amenitylist.length : 0;
      Amenitydivide =
        AmenitylistTotal !== 0 ? Math.ceil(AmenitylistTotal / 2) : 0;
    }
    await this.setState({
      showSpinner: false,
      loading: false,
    });
    this.setState({
      Amenitylist: Amenitylist,
      AmenitylistTotal: AmenitylistTotal,
      Amenitydivide: Amenitydivide,
    });
  };

  getcmsCommonData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName =
      global.theme + "_AMENITIES_DATA_" + this.state.property_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data =
        global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
          ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
          : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let res = await CmsDataService.getCmsData(
      "AMENITIES",
      "property_seo_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false,
    });
    global.LocalStorageManage.setWithExpiry(
      cacheKeyName,
      JSON.stringify(resData)
    );
    this.setState(resData);
  };

  render() {
    return (
      <main className="oh-template">
        {/* Header */}
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
          <title>
            {this.state.property_name
              ? this.state.property_name
              : global.propertyName}{" "}
            OFFERS A LUXE EXPERIENCE FOR EVEN THE BUSIEST RESIDENTS{" "}
          </title>
          {/* <meta name="robots" content="noindex" /> */}
          <meta
            name="description"
            content={
              this.state.property_name
                ? this.state.property_name +
                " - AMENITIES   " +
                this.state.metaDescription
                : this.state.metaDescription
            }
          ></meta>
          <meta name="keywords" content={this.state.metaKeywords}></meta>
          <meta
            name="author"
            content={
              this.state.property_name
                ? this.state.property_name
                : global.propertyName
            }
          />
        </Helmet>
        <section className="middle" name="SECTION_1">
          <div className="container-fluid p-0">
            <section
              className="aminintyMain mg-b-10"
              name="SECTION_1"
              style={{
                backgroundImage:
                  "url(" +
                  `${this.state.SECTION_1 && this.state.SECTION_1.backgroundImage
                    ? this.state.SECTION_1.backgroundImage
                    : global.OnKeyPressEvent.getS3ImageUrl("aminity.jpg")
                  }` +
                  ")",
              }}
            >
              <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-50 pd-r-50">
                <h2>
                  {this.state.SECTION_1 &&
                    this.state.SECTION_1.section_data &&
                    this.state.SECTION_1.section_data[0] &&
                    this.state.SECTION_1.section_data[0].text
                    ? this.state.SECTION_1.section_data[0].text
                    : "Hosting features"}
                </h2>
                <h6>
                  {this.state.SECTION_1 &&
                    this.state.SECTION_1.section_data &&
                    this.state.SECTION_1.section_data[1] &&
                    this.state.SECTION_1.section_data[1].text
                    ? this.state.SECTION_1.section_data[1].text
                    : "Kini Beach Haus amenities for you and your guests"}
                </h6>
                <div className="d-flex">
                  <button className="mg-r-15">
                    {this.state.SECTION_1 &&
                      this.state.SECTION_1.section_data &&
                      this.state.SECTION_1.section_data[2] &&
                      this.state.SECTION_1.section_data[2].text
                      ? this.state.SECTION_1.section_data[2].text
                      : "View amenities"}
                  </button>{" "}
                  <button>
                    {this.state.SECTION_1 &&
                      this.state.SECTION_1.section_data &&
                      this.state.SECTION_1.section_data[3] &&
                      this.state.SECTION_1.section_data[3].text
                      ? this.state.SECTION_1.section_data[3].text
                      : "Book a tour"}
                  </button>
                </div>
              </div>
            </section>

            <section className="aminityBlock pd-t-60 pd-b-20" name="SECTION_2">
              <div
                className="container pd-b-60"
                style={{
                  background: `${this.state.SECTION_2
                    ? this.state.SECTION_2.backgroundColor
                    : ""
                    }`,
                }}
              >
                <div className="content">
                  <div className="row">
                    <div className="col-lg-4">
                      <h3>
                        {this.state.SECTION_2 &&
                          this.state.SECTION_2.section_data &&
                          this.state.SECTION_2.section_data[0] &&
                          this.state.SECTION_2.section_data[0].text
                          ? this.state.SECTION_2.section_data[0].text
                          : "Kini offers a luxe experience for even the busiest residents"}
                      </h3>
                    </div>
                    <div className="col-lg-8">
                      {this.state.AmenitylistTotal > 0 && (
                        <>
                          <div className="aminityListitems">
                            <ul>
                              {this.state.Amenitylist.map((options, index) => {
                                if (index < this.state.Amenitydivide) {
                                  return <li>{options.amenity_name}</li>;
                                }
                              })}
                            </ul>
                            {/* <ul>

                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[1] && this.state.SECTION_2.section_data[1].text ? this.state.SECTION_2.section_data[1].text :"Lounge with Wet Bar & Shuffleboard"} </li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[2] && this.state.SECTION_2.section_data[2].text ? this.state.SECTION_2.section_data[2].text :"Fire Pit & Outdoor Grilling"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[3] && this.state.SECTION_2.section_data[3].text ? this.state.SECTION_2.section_data[3].text :"Dining Areas Studio"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[4] && this.state.SECTION_2.section_data[4].text ? this.state.SECTION_2.section_data[4].text :"State-of-the-Art Fitness"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[5] && this.state.SECTION_2.section_data[5].text ? this.state.SECTION_2.section_data[5].text :"Center Cardio and Flex Studio with Echelon Bikes and Mirror Gym Platform"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[6] && this.state.SECTION_2.section_data[6].text ? this.state.SECTION_2.section_data[6].text :"Bar and Terrace Co-Work Lounge and Conference Room for Work Meetings"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[7] && this.state.SECTION_2.section_data[7].text ? this.state.SECTION_2.section_data[7].text :"Poolside Entertainment Lounge"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[8] && this.state.SECTION_2.section_data[8].text ? this.state.SECTION_2.section_data[8].text :"Bike Workshop and Storage Dog Washing Station"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[9] && this.state.SECTION_2.section_data[9].text ? this.state.SECTION_2.section_data[9].text :"Pet-Friendly Community Stay Connected with Wi-Fi Throughout Community"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[10] && this.state.SECTION_2.section_data[10].text ? this.state.SECTION_2.section_data[10].text :"Resident Lounge and Leasing Lobby Pool with Soothing Water Loungers & Poolside Cabanas"}</li>
                                                            <li> {this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[11] && this.state.SECTION_2.section_data[11].text ? this.state.SECTION_2.section_data[11].text :"Ground Floor Art Gallery Drawbar Kitchen & Bar w/ Beverages"}</li>
                                                        </ul> */}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="container pd-l-35 pd-r-35 mg-t-35 mg-b-35 mobAminity"
                name="SECTION_3"
              >
                <figure>
                  <img
                    src={
                      this.state.SECTION_3 &&
                        this.state.SECTION_3.backgroundImage
                        ? this.state.SECTION_3.backgroundImage
                        : global.OnKeyPressEvent.getS3ImageUrl("aminity-list-1.jpg")
                    }
                    alt="amenity"
                    className="img-fluid"
                    title="amenity"
                  />
                </figure>
              </div>
              <div
                className="container pd-l-35 pd-r-35 mg-t-35 mg-b-35 mobAminity"
                name="SECTION_4"
              >
                <figure>
                  <img
                    src={
                      this.state.SECTION_4 &&
                        this.state.SECTION_4.backgroundImage
                        ? this.state.SECTION_4.backgroundImage
                        : global.OnKeyPressEvent.getS3ImageUrl("aminity-list-2.jpg")
                    }
                    alt="amenity"
                    className="img-fluid"
                    title="amenity"
                  />
                </figure>
              </div>
              <div
                className="container pd-l-35 pd-r-35 mg-t-35 mg-b-35 mobAminity"
                name="SECTION_5"
              >
                <figure>
                  <img
                    src={
                      this.state.SECTION_5 &&
                        this.state.SECTION_5.backgroundImage
                        ? this.state.SECTION_5.backgroundImage
                        : global.OnKeyPressEvent.getS3ImageUrl("aminity-list-3.jpg")
                    }
                    alt="amenity"
                    className="img-fluid"
                    title="amenity"
                  />
                </figure>
              </div>
              <div
                className="container pd-l-35 pd-r-35 mg-t-35 mg-b-35 mobAminity"
                name="SECTION_7"
              >
                <figure>
                  <img
                    src={
                      this.state.SECTION_7 &&
                        this.state.SECTION_7.backgroundImage
                        ? this.state.SECTION_7.backgroundImage
                        : global.OnKeyPressEvent.getS3ImageUrl("aminity-list-4.jpg")
                    }
                    alt="amenity"
                    className="img-fluid"
                    title="amenity"
                  />
                </figure>
              </div>
              <div
                className="container pd-t-60 pd-b-60"
                name="SECTION_6"
                style={{
                  background: `${this.state.SECTION_6
                    ? this.state.SECTION_6.backgroundColor
                    : ""
                    }`,
                }}
              >
                <div className="content">
                  <div className="row">
                    <div className="col-lg-4">
                      <h3 className="smarter">
                        {this.state.SECTION_6 &&
                          this.state.SECTION_6.section_data &&
                          this.state.SECTION_6.section_data[0] &&
                          this.state.SECTION_6.section_data[0].text
                          ? this.state.SECTION_6.section_data[0].text
                          : "A smarter way to live"}
                      </h3>
                    </div>
                    {/* <div className="col-lg-8">
                                                    <div className="Amenitylistitems">
                                                        <ul>
                                                            <li>{this.state.SECTION_6 && this.state.SECTION_6.section_data && this.state.SECTION_6.section_data[1] && this.state.SECTION_6.section_data[1].text ? this.state.SECTION_6.section_data[1].text :"Stay Connected with Wi-Fi Throughout "}</li>
                                                            <li>{this.state.SECTION_6 && this.state.SECTION_6.section_data && this.state.SECTION_6.section_data[2] && this.state.SECTION_6.section_data[2].text ? this.state.SECTION_6.section_data[2].text :"1Gbps of High-Speed Wi-Fi Pre-Installed in Residences"}</li>
                                                            <li>{this.state.SECTION_6 && this.state.SECTION_6.section_data && this.state.SECTION_6.section_data[3] && this.state.SECTION_6.section_data[3].text ? this.state.SECTION_6.section_data[3].text :"Convenient Mobile Entry to Community, Amenities & Residences"}</li>

                                                            <li>{this.state.SECTION_6 && this.state.SECTION_6.section_data && this.state.SECTION_6.section_data[4] && this.state.SECTION_6.section_data[4].text ? this.state.SECTION_6.section_data[4].text :"Smartphone-Controlled Programmable Thermostats"}</li>
                                                            <li>{this.state.SECTION_6 && this.state.SECTION_6.section_data && this.state.SECTION_6.section_data[5] && this.state.SECTION_6.section_data[5].text ? this.state.SECTION_6.section_data[5].text :"Innovative Touch Technology on Kitchen Sinks"}</li>
                                                            <li>{this.state.SECTION_6 && this.state.SECTION_6.section_data && this.state.SECTION_6.section_data[6] && this.state.SECTION_6.section_data[6].text ? this.state.SECTION_6.section_data[6].text :"Smartphone Controlled Lighting, Solar Shade Controls *"}</li>

                                                        </ul>
                                                    </div>
                                                </div> */}
                    <div className="col-lg-8">
                      {this.state.AmenitylistTotal > 0 && (
                        <>
                          <div className="aminityListitems">
                            <ul>
                              {this.state.Amenitylist.map((options, index) => {
                                if (index >= this.state.Amenitydivide) {
                                  return <li>{options.amenity_name}</li>;
                                }
                              })}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="container pd-l-35 pd-r-35 mg-t-35 mg-b-0 mobAminity"
                name="SECTION_8"
              >
                <figure>
                  <img
                    src={
                      this.state.SECTION_8 &&
                        this.state.SECTION_8.backgroundImage
                        ? this.state.SECTION_8.backgroundImage
                        : global.OnKeyPressEvent.getS3ImageUrl("aminity-list-5.jpg")
                    }
                    alt="amenity"
                    className="img-fluid"
                    title="amenity"
                  />
                </figure>
              </div>
              <div
                className="container pd-l-35 pd-r-35 mg-t-35 mg-b-0 mobAminity"
                name="SECTION_9"
              >
                <figure>
                  <img
                    src={
                      this.state.SECTION_9 &&
                        this.state.SECTION_9.backgroundImage
                        ? this.state.SECTION_9.backgroundImage
                        : global.OnKeyPressEvent.getS3ImageUrl("aminity-list-6.jpg")
                    }
                    alt="amenity"
                    className="img-fluid"
                    title="amenity"
                  />
                </figure>
              </div>
            </section>
          </div>
        </section>

        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default Aminity;
