//     							                                      //
//  Program: Thankyou..jsx                                //
//  Application: Thank you HTML                           //
//  Option: Book Tour Form                                //
//  Developer: PS		                                      //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import CmsDataService from "../../services/CmsDataService";
import { Helmet } from "react-helmet-async";
import { Dropdown } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import PhoneInput from "react-phone-input-2";
import TextareaAutosize from 'react-autosize-textarea';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class ThankYouResident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      personName: [],
      date: new Date('2014-08-18T21:11:54'),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      input: {},
      errors: {},
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      applicant_photo: "",
      price_range: "",
      desired_move_in_date: "",
      comment: "",
      status_id: true,
      helperText: "",
      bedroom: [],
      bedroomArray: [],
      priceRangeArray: [],
      playStoreLink: "https://play.google.com/store/apps/details?id=com.orionhaus.haus",
      appStoreLink: "https://apps.apple.com/us/app/orion-haus/id1627695152",
    }

    // if(!localStorage.getItem("headerToken")){
    //   window.location.href = "/";
    // }
  }

  componentDidMount() {
    this.getcmsCommonData();
    // setTimeout(() => {
    //   window.location.href = "/"
    // }, 10000);
  }
  getcmsCommonData = async (queryString = "") => {

    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.theme + "_PRIVACY_DATA_" + this.state.property_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let res = await CmsDataService.getCmsData(
      "PRIVACY_POLICY",
      "property_seo_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false,
      amenitySet: true
    });
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
  };

  render() {
    // const [value, setValue] = React.useState(options[0]);
    // const [inputValue, setInputValue] = React.useState('');

    return (
      <main className="oh-template Brandmainpage">
        {/* Header */}
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <section className="middle-section">
          <div className="container disclaim_sec">
            <section className="thankyou">

              <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} alt="Congrats You're in" title="Congrats You're in" className="thankyou" />
              <h2>Congrats! You're in!</h2>
              <p className="text-center p-0 m-0">
                {/* Your password has been set. */}
                {/* We've sent your free report to you inbox so it's easy to access.  */}
                Download the app using the links and log in with your
                new OHAI account.
              </p>
              <ul>
                <li><a href={this.state.appStoreLink} target="_blank"><img src={global.OnKeyPressEvent.getS3ImageUrl('download_apple.png')} alt="App Store Link" title="App Store Link" /></a></li>
                <li><a href={this.state.playStoreLink} target="_blank"><img src={global.OnKeyPressEvent.getS3ImageUrl('download_android.png')} alt="Play Store Link" title="Play Store Link" /></a></li>
              </ul>
              {/* <div className="d-flex justify-content-center align-items-center mg-t-50">
                <a href="/"><button>Back to home</button></a>
              </div> */}
            </section>
          </div>
        </section>


        {/* New chat section d-none*/}
        <section className="chatSection d-none">
          <Dropdown drop={'up'}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
            </Dropdown.Toggle>
            <Dropdown.Menu className="tx-13">
              <div className="chat-header">
                <span>Concierge</span>
              </div>
              <div className="chat-body">
                <div className="recievermsg d-flex justify-content-start mg-r-50">
                  <div className="d-block">
                    <p>Hey there. I'm your automated concierge bot. I'm going to pass your message to our actual human concierge team. Just gimme your deets, and we'll back to you right away! </p>
                    <span>09:15AM</span>
                  </div>
                </div>
                <div className="sendermsg d-flex justify-content-end mg-l-50">
                  <div className="d-block">
                    <p>Hi</p>
                    <span>09:20AM</span>
                  </div>
                </div>
                <div className="chatformSection">
                  <h4>What can I do for you?</h4>
                  <TextField id="name" name="name" label="Name" variant="outlined" fullWidth required />
                  <TextField type="email" name="email" id="email" label="Email" variant="outlined" fullWidth required className="borderInput textfield" />
                  <PhoneInput
                    className="borderInput textfield phoneInput"
                    variant="outlined"
                    id="phone_number"
                    name="phone_number"
                    fullWidth
                  />
                  <TextField
                    id="Comment"
                    label="Comments"
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    name="notes"
                    value={this.state.notes}
                    onChange={this.changeHandler}
                    className="borderInput textfield"
                  />

                  <button className="w-100">Send</button>
                </div>

              </div>
              <div className="chat-footer">

                <TextareaAutosize
                  onResize={(e) => { }}
                  placeholder="Type your message..."
                />
                <button><img src={global.OnKeyPressEvent.getS3ImageUrl('postarrow.svg')} /></button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </section>
        {/* End New chat section */}

        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main >
    );
  }
}
export default ThankYouResident