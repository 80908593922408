//     							                          //
//  Program: Inventory.jsx                                //
//  Application: KINI HTML                                //
//  Option: Home Sharing Listing                          //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import ApplicantsService from "../../services/ApplicantsService";
import CommonHeader from "../common/CommonHeader";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dropdown from 'react-bootstrap/Dropdown';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Datepicker, options, Page } from '@mobiscroll/react';
import StrlistingApi from "../../services/StrlistingApi";
import Moment from "moment";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import Form from 'react-bootstrap/Form';
import queryString from "query-string";
import CmsApi from "../../services/CmsApi";
import { Skeleton } from "@mui/material";
import MetaSEO from "../../../MetaSEO";
import Accordion from 'react-bootstrap/Accordion';
import ModalVideo from 'react-modal-videojs'
const queryParams = queryString.parse(location.search);

class PropertyIndex extends Component {
  constructor(props) {
    // console.log(props);
    super(props);
    // console.log(this.props, props);
    this.state = {
      add: "",
      viewMode: 0,
      media_files: [],
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      UnitListing: [],
      multiImageList: [],
      multiImageListTotal: 0,
      first_name: "",
      last_name: "",
      email: "",
      errors: {},
      errors: {
        email: "",
        first_name: "",

      },
      showAlertModal: false,
      selectedDate: "",
      // Moment(new Date(date.value[0])).format("MM-DD-YYYY")
      moveoutDate: "",
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      guest: "",
      total_record: 0,
      errorCount: 0,
      confirm_back: 0,
      adultCount: 1,
      childCount: 0,
      bedroomcount: 1,
      bathCount: 1,
      infantCount: 0,
      succes_message: "",
      petsCount: 0,
      bedroom: "",
      location: queryParams.city ? queryParams.city : [],
      location_name: [],
      city_name: queryParams.city_name ? queryParams.city_name : [],
      title1: queryParams.title ? queryParams.title : "",
      guestValue: "1 Guest",
      bedbathValue: "1 Bedroom, 1 Bathroom",
      bathroom: "",
      queryString: "",
      queryStringltr: "",
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      slug: "",
      status_id: true,
      pathname: window.location.pathname,
      sec: "http://localhost:3000/kini/static/media/logo.8d8e6eeb6e92504d4242.png",
      property_name: localStorage.getItem("property_name"),
      property_new_name: "",
      brand_name: localStorage.getItem("brand_name"),
      defaultActiveKey: "live-here",
      // meta tags start
      title: queryParams.title ? queryParams.title : localStorage.getItem("property_name"),
      favicon: localStorage.getItem("property_logo_url"),
      keywords: global.keywords,
      description: global.description,
      url: window.location.href,
      image: localStorage.getItem("property_logo_url"),
      fb_app_id: "",
      // meta tags end
      loaderSearch: false,
      seoLoaded: false,

      // source: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerQA.mp4","website/video") + "?autoplay=1",
      // source1: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyManagerOverview.mp4","website/video") + "?autoplay=1",
      source2: global.OnKeyPressEvent.getS3ImageUrl("OrionHausHomesharingProcess.mp4", "website/video") + "?autoplay=1",
      source3: global.OnKeyPressEvent.getS3ImageUrl("OrionHausPropertyTourVideo.mp4", "website/video") + "?autoplay=1",
      // source4: global.OnKeyPressEvent.getS3ImageUrl("OrionHausKeyAccess.mp4","website/video") + "?autoplay=1",
      // source5: global.OnKeyPressEvent.getS3ImageUrl("OrionHausResidentHomesharingKit.mp4","website/video") + "?autoplay=1",
      // show: false,
      // show1: false,
      show2: false,
      show3: false,
      // show4: false,
      show5: false,
    };
    this.openModal = this.openModal.bind(this);
    this.getimagesData = this.getimagesData.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangelocation = this.handleChangelocation.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateChangeRange = this.handleDateChangeRange.bind(this);
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementbath = this.incrementbath.bind(this);
    this.decrementbath = this.decrementbath.bind(this);
    this.incrementBed = this.incrementBed.bind(this);
    this.decrementBed = this.decrementBed.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    // this.incrementpets = this.incrementpets.bind(this);
    // this.decrementpets = this.decrementpets.bind(this);
    this.saveNewsletter = this.saveNewsletter.bind(this);
    this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
    this.getIp = this.getIp.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.bedbathDetail = this.bedbathDetail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Setclosebath = this.Setclosebath.bind(this);
    this.Setclose = this.Setclose.bind(this);
  }

  componentDidMount() {
    console.log(this.props, "taken");
    this.getMetaData();
    this.getimagesData();
    this.guestDetail();
    // this.getIp();
    this.getPropertyHomeCmsData();
    this.getPropertyFooterCmsData();
    this.getBranddata();
    this.bedbathDetail();
    this.createQueryString();
    this.createQueryStringLtr();
    this.getCmsPropertyData();
  }

  getIp = async (e) => {
    let response = await fetch('https://geolocation-db.com/json/');
    let data = await response.json();
    this.setState({ ip: data.IPv4 });
  }

  changeHandlerSelect = async (event) => {
    let input = this.state.input;
    input.add = event.target.value;
    await this.setState({ add: event.target.value, input: input });
    await this.createQueryString();
    await this.createQueryStringLtr();

    if (event.target.value == "Unfurnished") {
      var unit = document.getElementById("uncontrolled-tab-example-tab-live-here");
      if (unit) {
        unit.click();
      }
    } else {
      var unit = document.getElementById("uncontrolled-tab-example-tab-stay-here");
      if (unit) {
        unit.click();
      }
    }
  }

  saveNewsletter = async (e) => {
    e.preventDefault();
    if (
      this.state.errors.email === "" &&
      this.state.email !== "" &&
      this.state.errors.first_name === "" &&
      this.state.first_name !== "" &&
      this.state.errors.last_name === ""
    ) {
       this.setState({ loader: true });
      // this.setState({ showSpinner: true, isSubmit: true });
      // await this.getIp();
      var origin = window.location.origin ? window.location.origin :"";
      var pathname = window.location.pathname ? window.location.pathname:"";
      var finalPath = origin + pathname; 
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        sort_order: this.state.sort_order,
        page_from: finalPath,
        ip: this.state.ip,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      let res = await ApplicantsService.createsubscriberFromWebsite(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          first_name: "",
          last_name: "",
          email: "",
          status_id: true,
          // succes_message: "Thanks for your subscription"
          succes_message: "Thank you for your subscription."
        });
        // this.props.history.push(global.themeUrl + this.state.property_slug + "/virtual-tour-confirmation");
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ loader: false });

      // this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      //show required errors
      var errors = this.state.errors;
      var errorCount = this.state.errorCount;
      if (!this.state.email) {
        document.getElementById("email_sub").focus();
        errors.email = "Please enter email.";
      }
      if (!this.state.first_name) {
        document.getElementById("first_name_sub").focus();
        errors.first_name = "Please enter first name.";
      }
      this.setState({ errors: errors, errorCount: errorCount + 1 });
    }
  }

  /* CMS data getcode start */

  getMetaData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_PROPERTY_HOME_META_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    let res = await CmsDataService.getCmsMetaData("HOME", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resData.title = this.state.title1 ? this.state.title1 : (res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.title : "") : "");
      resData.favicon = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.keywords = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.keywords : "") : "";
      resData.description = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.description : "") : "";
      resData.url = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.image = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.fb_app_id = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.fb_app_id : "") : "";
    }
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true
    });
  }

  getPropertyHomeCmsData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_PROPERTY_HOME_DATA_" + this.state.property_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    let res = await CmsDataService.getCmsData("HOME", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false
    });
    // console.log(resData, "home");
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
  }

  getPropertyFooterCmsData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_PROPERTY_HOME_DATA_" + this.state.property_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    let res = await CmsDataService.getCmsData("FOOTER", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
      global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    }
    // console.log("footer", resData);
    await this.setState(resData);
  }
  /* CMS data getcode end */

  getCmsPropertyData = async (queryString = "") => {
    // this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var cacheKeyName = global.theme + "_PROPERTY_DATA_" + this.state.property_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem('PROPERTY_DATA');
      resData.logo = localStorage.getItem("property_logo_url") ? localStorage.getItem("property_logo_url") : global.OnKeyPressEvent.getS3ImageUrl('oh_logo.svg'),
        resData.brand_logo = localStorage.getItem("brand_logo_url") ? localStorage.getItem("brand_logo_url") : global.OnKeyPressEvent.getS3ImageUrl('oh_logo.svg'),
        // resData.phone_number = localStorage.getItem("phone_number") ? <PhoneNumberFormat phone_number={localStorage.getItem("phone_number")} /> : "(786)-465-6661";
        resData.property_logo_thumb_url = localStorage.getItem("property_logo_url") ? localStorage.getItem("property_logo_url") : global.OnKeyPressEvent.getS3ImageUrl('oh_logo.svg');
      resData.property_name = localStorage.getItem("property_name") ? localStorage.getItem("property_name") : "";
      resData.theme = data.theme ? data.theme : "kini";
      this.setState(resData);
    }
    let res = await CmsApi.getCmsPropertyData(this.state.property_slug);
    if (global.successStatus.includes(res.status)) {
      global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(res.data));
      resData.logo = res.data.property_logo_thumb_url ? res.data.property_logo_thumb_url : global.OnKeyPressEvent.getS3ImageUrl('oh_logo.svg');
      resData.brand_logo = res.data.brand ? res.data.brand.brand_logo_url : global.OnKeyPressEvent.getS3ImageUrl('oh_logo.svg');
      resData.brand_name = res.data.brand ? res.data.brand.brand_name : "KINI BEACH HAUS";
      var phone_number = res.data.property_phone_number ? res.data.property_phone_number : "(786)-465-6661";
      // resData.phone_number = res.data.property_phone_number ? <PhoneNumberFormat phone_number={res.data.property_phone_number} /> : "(786)-465-6661";
      resData.property_logo_thumb_url = res.data.property_logo_thumb_url ? res.data.property_logo_thumb_url : "";
      resData.property_name = res.data.property_name ? res.data.property_name : "";
      resData.theme = res.data.theme ? res.data.theme : "";
      localStorage.setItem("property_name", resData.property_name);
      this.setState({ property_new_name: resData.property_name });
      localStorage.setItem("phone_number", phone_number);
      localStorage.setItem("property_logo_url", resData.property_logo_thumb_url);
      localStorage.setItem("brand_logo_url", resData.brand_logo);
      localStorage.setItem("brand_name", resData.brand_name);
      localStorage.setItem("theme", resData.theme);
    } else {
      window.location.href = "/";
    }
    //  console.log(this.state.property_new_name);
    this.setState(resData);
  }

  handleChangelocation = async (event) => {
    let input = this.state.input;
    input[event.label] = event.value;
    await this.setState({ location: event.value, location_name: event.label, city_name: event.label, input: input });
    await this.createQueryString();
    await this.createQueryStringLtr();
  }

  getimagesData = async (queryString = "") => {
    let inputData = {
      path: "properties",
      refTableId: 3,
      property_seo_slug: this.state.property_slug,
      mediaType: 1,
    };
    let res = await StrlistingApi.getimagesData(inputData);
    var multiImageList = [];
    var multiImageListTotal = 0;
    if (global.successStatus.includes(res.status)) {
      multiImageList = res.data ? res.data : [];
      multiImageListTotal = multiImageList.length;
      multiImageList.length > 0
        ? multiImageList.map((item, i) => {
          if (item.is_main_img === 1) {
            var a = item.large_img_w_1200;
            this.setState({ mainImage: a });
          }
        })
        : this.setState({ mainImage: "" });
    }
    this.setState({
      multiImageList: multiImageList,
      multiImageListTotal: multiImageListTotal,
    });
  }

  handleDateChange = async (date, name) => {
    let input = this.state.input;
    let a = new Date(date.valueText);
    input.move_in_date = Moment(new Date(date.value)).format("MM-DD-YYYY");
    await this.setState({ selectedDate: input.move_in_date, input: input });
    await this.createQueryString();
  }

  handleDateChangeRange = async (date, name) => {
    let input = this.state.input;
    let a = new Date(date.valueText);
    input.move_in_date = Moment(new Date(date.value[0])).format("MM-DD-YYYY");
    input.move_out_date = Moment(new Date(date.value[1])).format("MM-DD-YYYY");
    await this.setState({ selectedDate: input.move_in_date, moveoutDate: input.move_out_date, input: input });
    await this.createQueryStringLtr();
  }

  guestDetail = () => {
    var message = "";
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Children"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    this.setState({ guestValue: message });
    // return message;
  }

  handleChange = (event, value) => {
    this.setState({ newcityList: value });
  }

  bedbathDetail = () => {
    var message = "";
    message +=
      this.state.bedroomcount > 1
        ? this.state.bedroomcount + " Bedrooms"
        : this.state.bedroomcount + " Bedroom";
    message += this.state.bathCount
      ? this.state.bathCount > 1
        ? ", " + this.state.bathCount + " Bathrooms"
        : ", " + this.state.bathCount + " Bathroom"
      : "";
    this.setState({ bedbathValue: message });
    // return message;
  }

  Setclosebath() {
    var unit = document.getElementById("dropdown-basic-bed");
    if (unit) {
      unit.click();
    }
  }

  createQueryString = () => {
    var queryString = "";
    queryString = `?city_name=${this.state.city_name ? this.state.city_name : this.state.location_name}&city=${this.state.location ? this.state.location : ""}&checkin=${this.state.selectedDate}&furnished=${this.state.add === "" ? "Unfurnished" : this.state.add}&numberOfbed=${this.state.bedroomcount}&numberOfbathroom=${this.state.bathCount}`;
    this.setState({ queryString: queryString });
  }

  createQueryStringLtr = () => {
    var queryStringltr = "";
    queryStringltr = `?city_name=${this.state.city_name ? this.state.city_name : this.state.location_name}&city=${this.state.location ? this.state.location : ""}&checkin=${this.state.selectedDate}&checkout=${this.state.moveoutDate}&furnished=${this.state.add === "" ? "Furnished" : this.state.add}&numberOfAdults=${this.state.adultCount}&numberOfChildren=${this.state.childCount}&numberOfInfants=${this.state.infantCount}`;
    this.setState({ queryStringltr: queryStringltr });
  }

  Setclose() {
    var unit = document.getElementById("dropdown-basic-bath");
    if (unit) {
      unit.click();
    }
  }

  async incrementbath() {
    await this.setState({ bathCount: this.state.bathCount + 1 });
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async decrementbath() {
    if (this.state.bathCount === 0 || this.state.bathCount === 1) {
      await this.setState({ bathCount: 1 });
    } else {
      await this.setState({ bathCount: this.state.bathCount - 1 });
    }
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async incrementBed() {
    await this.setState({ bedroomcount: this.state.bedroomcount + 1 });
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async decrementBed() {
    if (this.state.bedroomcount === 0) {
      await this.setState({ bedroomcount: 0 });
    } else {
      await this.setState({ bedroomcount: this.state.bedroomcount - 1 });
    }
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async incrementAdult() {
    await this.setState({ adultCount: this.state.adultCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({ adultCount: this.state.adultCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({ childCount: this.state.childCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({ childCount: this.state.childCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  // async incrementpets() {
  //   await this.setState({ petsCount: this.state.petsCount + 1 });
  //   // await this.createQueryString();
  //   await this.guestDetail();
  // }

  // async decrementpets() {
  //   if (this.state.petsCount === 0) {
  //     await this.setState({ petsCount: 0 });
  //   } else {
  //     await this.setState({ petsCount: this.state.petsCount - 1 });
  //   }
  //   // await this.createQueryString();
  //   await this.guestDetail();
  // }

  getBranddata = async (queryString = "") => {

    this.setState({ showSpinner: true, loading: true });
    var resData = [];
    var city = [];
    let inputData = {
      brand_seo_slug: ""
    }
    let res = await CmsDataService.getBrandData(inputData);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : [];
      city = res.other_data ? res.other_data : [];
    }
    city.map((item, index) => {
      item.label = city[index].city.city_name;
      item.value = city[index].city.city_id;
    });
    this.setState({ newcityList: city });
    this.setState({ resData: resData });
  }

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      let errors = this.state.errors;
      errors.email = "Please enter valid email."
      this.setState({ errors: errors });
    } else {
      let errors = this.state.errors;
      errors.email = ""
      this.setState({ errors: errors });
    }
  }

  changeHandler = (event) => {
    // let input = this.state.input;
    // let value = event.target.value;
    // input[event.target.name] = value;
    // this.setState({ [event.target.name]: value, input: input });
    let input = this.state.input;
    let value = event.target.value;
    if (event.target.name === "first_name") {
      const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      }
      else {
        errors.first_name = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "last_name") {
      const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.last_name = "Please enter valid last name.";
          this.setState({ errors: errors });
        } else {
          errors.last_name = "";
          this.setState({ errors: errors });
        }
      }
      else {
        errors.last_name = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "email") {
      const regex =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.email = "Please enter valid email.";
          this.setState({ errors: errors });
        } else {
          errors.email = "";
          this.setState({ errors: errors });
        }
      }
      else {
        errors.email = "";
        this.setState({ errors: errors });
      }
    }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  }


  scrollTop = () => {
    const section = document.querySelector('.Brandmainpage');
    section.scrollIntoView({ behavior: 'auto', block: 'start' });
  }

  openModal(src) {
    this.setState({ isOpen: true })
    this.setState({ currentVideoUrl: src })
  }

  showModal = (val = false, param = 'show') => {
    this.setState({ [param]: val });
  };


  render() {
    const inputProps = {
      className: 'md-mobile-picker-input',
      placeholder: 'Add dates'
    };



    return (
      <main className="Brandmainpage propertyIndex">

        {/* Meta Tag Start */}
        {
          this.state.seoLoaded &&
          <MetaSEO
            title={this.state.title}
            favicon={this.state.favicon}
            keywords={this.state.keywords}
            description={this.state.description}
            url={this.state.url}
            image={this.state.image}
            fb_app_id={this.state.fb_app_id}
            noIndex="false"
            author={"OHAI"}
          />
        }
        {/* Meta Tag End */}
        {/* Header */}
        <CommonHeader propertySlug={this.state.property_slug} />

        {/* middle PROPERTY_HOME_SECTION_1 */}
        <section className="middle" name="SECTION_1">
          <div className="container-fluid p-0">
            {(this.state.PROPERTY_HOME_SECTION_1 || this.state.mainImage) ?
              <>
                <section className="banner banner_two"
                  style={
                    {
                      backgroundImage: "url(" + `${(this.state.mainImage && this.state.mainImage) ? this.state.mainImage : (this.state.PROPERTY_HOME_SECTION_1 ? (this.state.PROPERTY_HOME_SECTION_1[2] ? this.state.PROPERTY_HOME_SECTION_1[2].url : "") : "")}` + ")"
                    }

                  }
                >
                  <div className="container d-flex align-items-start flex-column justify-content-center h-100 w-100 pd-l-25 pd-r-25">
                    <h1>{this.state.title1 ? this.state.title1 : (this.state.PROPERTY_HOME_SECTION_1 ? ((this.state.PROPERTY_HOME_SECTION_1[0]) ? this.state.PROPERTY_HOME_SECTION_1[0].text : "Live like a local") : "Live like a local")} </h1>
                    <h1>{this.state.property_new_name ? this.state.property_new_name : ""}</h1>
                    <h4>{this.state.PROPERTY_HOME_SECTION_1 ? ((this.state.PROPERTY_HOME_SECTION_1[1]) ? this.state.PROPERTY_HOME_SECTION_1[1].text : "Unique places to live or stay") : "Unique places to live or stay"}</h4>
                  </div>
                </section>
              </>
              :
              <>
                <section className="banner">
                  <Skeleton sx={{ height: "100%", bgcolor: 'black.450' }} animation="wave" variant="rectangular" />
                </section>
              </>
            }
            <section className="mainListing">
              <div className="mainfilter">
                <div className="container">
                  <div className="filtersection">
                    <Tabs
                      // defaultActiveKey={(this.state.add === "Furnished" ? "stay-here": "live-here")}
                      defaultActiveKey={"live-here"}
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >

                      <Tab eventKey="live-here" title="Live here" >
                        <div className="tab-section">
                          <div className="mobileViewbannerContent">
                            <h1>{this.state.title1 ? this.state.title1 : (this.state.PROPERTY_HOME_SECTION_1 ? ((this.state.PROPERTY_HOME_SECTION_1[0]) ? this.state.PROPERTY_HOME_SECTION_1[0].text : "Live like a local") : "Live like a local")} </h1>
                            <h1> {this.state.property_new_name ? this.state.property_new_name : ""}</h1>
                            <h4>{this.state.PROPERTY_HOME_SECTION_1 ? ((this.state.PROPERTY_HOME_SECTION_1[1]) ? this.state.PROPERTY_HOME_SECTION_1[1].text : "Unique places to live or stay") : "Unique places to live or stay"}</h4>
                          </div>
                          <div className="search d-flex flex-column justify-content-start align-items-start">
                            <label>Where</label>
                            <Stack>
                              <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                name="location"
                                disableClearable
                                disablePortal
                                value={this.state.city_name}
                                //alue={option}
                                onChange={(e, v) => {
                                  this.handleChangelocation(v);
                                }}
                                options={this.state.newcityList ? this.state.newcityList : []}
                                // onChange={this.handleChangelocation}
                                // getOptionValue={option => `${option}`}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    hiddenLabel
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                    }}
                                    placeholder="Search locations"
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          <div className="add d-flex flex-column justify-content-start align-items-start">
                            <label>Add</label>
                            <FormControl>

                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={this.state.add ? this.state.add : "Unfurnished"}
                                //disabled
                                name="add"
                                onChange={(event) => this.changeHandlerSelect(event)}
                              >
                                {/* <MenuItem value={10} selected>Furnished</MenuItem> */}
                                {/* <MenuItem value={10} selected >Unfurnished</MenuItem> */}
                                <MenuItem value="Furnished">Furnished</MenuItem>
                                <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="when d-flex flex-column justify-content-start align-items-start">
                            <label>When</label>
                            <Datepicker
                              controls={['calendar']}
                              buttons={[{
                                // text: "X",
                                icon: "material-close",
                                handler: 'cancel'
                              }]}
                              // buttons={['set', 'cancel']}
                              pages={1}
                              calendarType="month"
                              invalid={[]}
                              // /select=""
                              inputComponent="input"
                              inputProps={{
                                placeholder: 'Add date'
                              }}
                              selected={this.state.selectedDate}
                              onChange={(date) =>
                                this.handleDateChange(date, "dateFour")
                              }
                              // closeOnEsc={true}
                              // cssClass=""
                              // defaultValue={undefined}
                              // display="bottom"
                              rangeSelectMode="wizard"
                              showRangeLabels={true}
                              touchUi={false}
                              theme="ios"
                              themeVariant="light"
                              headerText=""
                              max=""
                              min=""
                              rangeStartLabel="Check-in"
                              // rangeEndLabel="Check-out"
                              responsive={{
                                xsmall: {
                                  pages: 1,
                                  touchUi: false,
                                  calendarSize: "2",
                                  cssClass: "single_pick_date",
                                  refDate: Moment(new Date()).add(1, "year"),
                                },
                                medium: {
                                  // display: 'center',
                                  pages: 1,
                                  // touchUi: true
                                }
                                // custom: {
                                //   breakpoint: 850,
                                //   display: 'center',
                                // pages: 2,
                                //   touchUi: false
                                //}
                              }}
                            />

                          </div>
                          <div className="details d-flex flex-column justify-content-start align-items-start">
                            <label className="vs-hide-">Details</label>
                            <Dropdown autoClose={false}>
                              <Dropdown.Toggle variant="success" id="dropdown-basic-bed">
                                <TextField
                                  id="outlined-read-only-input"
                                  // label="GUESTS"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  className="inputBox"
                                  //placeholder="Add guests"
                                  value={this.state.bedbathValue}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>

                                <div className="d-flex justify-content-between pd-15 pd-b-5">
                                  <ul>
                                    {/* <li><b>Bedrooms</b></li> */}
                                    <li><b>{this.state.bedroomcount > 1 ? "Bedrooms" : "Bedroom"}</b></li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button
                                        onClick={
                                          this.decrementBed
                                        }
                                        disabled={
                                          this.state.bedroomcount ===
                                            1
                                            ? true
                                            : false
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement bedroom counter" title="Decrement bedroom counter" />
                                      </button>
                                    </span>
                                    <span>
                                      {this.state.bedroomcount}
                                    </span>
                                    <span>
                                      <button
                                        onClick={
                                          this.incrementBed
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment bedroom counter" title="Increment bedroom counter" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between pd-15 pd-b-5">
                                  <ul>
                                    {/* <li><b>Bathrooms</b></li> */}
                                    <li><b>{this.state.bathCount > 1 ? "Bathrooms" : "Bathroom"}</b></li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button
                                        onClick={
                                          this.decrementbath
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement bathroom counter" title="Decrement bathroom counter" />
                                      </button>
                                    </span>
                                    <span>
                                      {this.state.bathCount}
                                    </span>
                                    <span>
                                      <button
                                        onClick={
                                          this.incrementbath
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment bathroom counter" title="Increment bathroom counter" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="done"
                                    id="cnt-close"
                                    onClick={this.Setclosebath}
                                  >
                                    Close
                                  </button>
                                </div>

                              </Dropdown.Menu>
                            </Dropdown>


                          </div>
                          <div className="search-btn">
                            {/* href={global.themeUrl + item.property_seo_slug} */}
                            <button className={this.state.loaderSearch ? "search_loader" : ""} onClick={this.openlink}>
                              <a href={
                                global.themeUrl + this.state.property_slug +
                                "/floorplan-listing" +
                                this.state.queryString
                              }>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} alt="Search  Button" title="Floorplan Search" />
                              </a>
                            </button>

                          </div>
                          <div className="mobilesearch-btn">
                            <button onClick={this.openlink}><a href={
                              global.themeUrl + this.state.property_slug +
                              "/floorplan-listing" +
                              this.state.queryString
                            }>Search</a></button>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="stay-here" title="Stay here" >
                        <div className="tab-section">

                          <div className="mobileViewbannerContent">
                            <h1>{this.state.PROPERTY_HOME_SECTION_1 ? ((this.state.PROPERTY_HOME_SECTION_1[0]) ? this.state.PROPERTY_HOME_SECTION_1[0].text : "Live like a local") : "Live like a local"} </h1>
                            <h1> {this.state.property_new_name ? this.state.property_new_name : ""}</h1>
                            <h4>{this.state.PROPERTY_HOME_SECTION_1 ? ((this.state.PROPERTY_HOME_SECTION_1[1]) ? this.state.PROPERTY_HOME_SECTION_1[1].text : "Unique places to live or stay") : "Unique places to live or stay"}</h4>
                          </div>

                          <div className="search d-flex flex-column justify-content-start align-items-start">
                            <label>Where </label>
                            <Stack>
                              <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                disablePortal
                                // options={top100Films.map((option) => option.title)}
                                name="location"
                                value={this.state.city_name}
                                //alue={option}
                                onChange={(e, v) => {
                                  this.handleChangelocation(v);
                                }}
                                options={this.state.newcityList ? this.state.newcityList : []}
                                // onChange={this.handleChangelocation}
                                // getOptionValue={option => `${option}`}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    hiddenLabel
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                    }}
                                    placeholder="Search locations"
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          <div className="add d-flex flex-column justify-content-start align-items-start">
                            <label>Add</label>
                            <FormControl>

                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={this.state.add ? this.state.add : "Furnished"}
                                // disabled
                                name="add"
                                onChange={(event) => this.changeHandlerSelect(event)}
                              >
                                <MenuItem value="Furnished">Furnished</MenuItem>
                                <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                                {/* <MenuItem value={10} selected>Unfurnished</MenuItem> */}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="when d-flex flex-column justify-content-start align-items-start">
                            <label>When</label>
                            <Datepicker
                              controls={['calendar']}
                              buttons={[{
                                // text: "X",
                                icon: "material-close",
                                handler: 'cancel'
                              }]}
                              // buttons={['set', 'cancel']}
                              pages={2}
                              calendarType="month"
                              invalid={[]}
                              select="range"
                              inputComponent="input"
                              inputProps={inputProps}
                              selected={this.state.selectedDate}
                              onChange={(date) =>
                                this.handleDateChangeRange(date, "dateFour")
                              }
                              closeOnEsc={true}
                              cssClass="str_dual_calendar"
                              defaultValue={undefined}
                              // display="bottom"
                              rangeSelectMode="wizard"
                              showRangeLabels={true}
                              touchUi={false}
                              theme="ios"
                              themeVariant="light"
                              headerText=""
                              max=""
                              // min={new Date()}
                              rangeStartLabel="Check-in"
                              rangeEndLabel="Check-out"
                              responsive={{
                                xsmall: {
                                  pages: 2,
                                  touchUi: false,
                                  calendarScroll: "horizontal",
                                  calendarSize: "2",
                                  refDate: Moment(new Date()).add(1, "year"),
                                },
                                medium: {
                                  // display: 'center',
                                  pages: 2,
                                  // touchUi: true
                                }
                                // custom: {
                                //   breakpoint: 850,
                                //   display: 'center',
                                // pages: 2,
                                //   touchUi: false
                                //}
                              }}
                            />

                          </div>
                          <div className="details d-flex flex-column justify-content-start align-items-start">
                            <label>Details</label>
                            <Dropdown autoClose={false}>
                              <Dropdown.Toggle variant="success" id="dropdown-basic-bath">
                                <TextField
                                  id="outlined-read-only-input"
                                  label=""
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  className="inputBox"
                                  placeholder="Add guests"
                                  value={this.state.guestValue}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                                  <ul>
                                    <li><b>{this.state.adultCount > 1 ? "Adults" : "Adult"}</b><i>Age 13+</i></li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button
                                        onClick={
                                          this.decrementAdult
                                        }
                                        disabled={
                                          this.state.adultCount ===
                                            1
                                            ? true
                                            : false
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                                      </button>
                                    </span>

                                    <span>
                                      {this.state.adultCount}
                                    </span>
                                    <span>
                                      <button
                                        onClick={
                                          this.incrementAdult
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                                  <ul>
                                    <li><b>{this.state.childCount > 1 ? "Children" : "Child"}</b><i>Ages 2–12</i></li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button
                                        onClick={
                                          this.decrementchild
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                                      </button>
                                    </span>
                                    <span>
                                      {this.state.childCount}
                                    </span>
                                    <span>
                                      <button
                                        onClick={
                                          this.incrementchild
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                                  <ul>
                                    <li><b>{this.state.infantCount > 1 ? "Infants" : "Infant"}</b><i>Under 2</i></li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button
                                        onClick={
                                          this.decrementinfant
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-guest.svg')} alt="Decrement guest counter" title="Decrement guest counter" />
                                      </button>
                                    </span>
                                    <span>
                                      {this.state.infantCount}
                                    </span>
                                    <span>
                                      <button
                                        onClick={
                                          this.incrementinfant
                                        }
                                      >
                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('plus-guest.svg')} alt="Increment guest counter" title="Increment guest counter" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                {/*  <p>
                                                This place has a maximum of 6
                                                guests, not including infants. Pets
                                                aren't allowed.
                                              </p> */}
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="done"
                                    id="cnt-close"
                                    onClick={this.Setclose}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>


                          </div>
                          <div className="search-btn">
                            <button><a href={
                              global.themeUrl + this.state.property_slug +
                              "/str-listing" +
                              this.state.queryStringltr
                            }><img src={global.OnKeyPressEvent.getS3ImageUrl('search-icon.svg')} alt="Search Button" title="Unit Search" /></a></button>
                          </div>

                          <div className="mobilesearch-btn">
                            <button><a href={
                              global.themeUrl + this.state.property_slug +
                              "/str-listing" +
                              this.state.queryStringltr
                            }>Search</a></button>
                          </div>
                        </div>
                      </Tab>

                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                  <h2>{this.state.PROPERTY_HOME_SECTION_2 ? ((this.state.PROPERTY_HOME_SECTION_2[0]) ? this.state.PROPERTY_HOME_SECTION_2[0].text : "Welcome home") : "Welcome home"}  </h2>
                  <h4> {this.state.PROPERTY_HOME_SECTION_2 ? ((this.state.PROPERTY_HOME_SECTION_2[1]) ? this.state.PROPERTY_HOME_SECTION_2[1].text : "Book a night for a night or stay as long as you’d like.") : "Book a night for a night or stay as long as you’d like."} </h4>
                </div>
              </div>
              <div className=" Gallery-slider p-0">
                <div className="container p-0">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={15}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    centeredSlides={false}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper m-0"

                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      "@0.75": {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      "@1.00": {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 15,
                      },
                    }}
                  >
                    {(this.state.multiImageList && this.state.multiImageList.length > 0) && this.state.multiImageList.map(
                      (value, index) => {
                        return (
                          <SwiperSlide>

                            <div className="featuresitems">
                              <a href="#">
                                <div className="">
                                  <figure key={index}>
                                    <img src={value.large_img_w_720
                                      ? value.large_img_w_720
                                      : global.OnKeyPressEvent.getS3ImageUrl('g1.jpg')} alt="Property Images" title="Property Images" />
                                  </figure>
                                  <div className="overlap"></div>

                                </div>
                              </a>
                            </div>

                          </SwiperSlide>
                        );
                      }
                    )}
                    {/* </SwiperSlide>
                  <SwiperSlide>
                    <div className="featuresitems">
                      <a href="#">
                        <div className="">
                          <figure>
                            <img src={Gallery2} />
                          </figure>
                          <div className="overlap"></div>

                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="featuresitems">
                        <a href="#">
                          <div className="">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('g1.jpg')} />
                            </figure>
                            <div className="overlap"></div>

                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="featuresitems">
                        <a href="#">
                          <div className="">
                            <figure>
                              <img src={Gallery2} />
                            </figure>
                            <div className="overlap"></div>

                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="featuresitems">
                        <a href="#">
                          <div className="">
                            <figure>
                              <img src={Gallery3} />
                            </figure>
                            <div className="overlap"></div>

                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="featuresitems">
                        <a href="#">
                          <div className="">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('g1.jpg')} />
                            </figure>
                            <div className="overlap"></div>

                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="featuresitems">
                        <a href="#">
                          <div className="">
                            <figure>
                              <img src={Gallery2} />
                            </figure>
                            <div className="overlap"></div>

                          </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="featuresitems">
                        <a href="#">
                          <div className="">
                            <figure>
                              <img src={Gallery3} />
                            </figure>
                            <div className="overlap"></div>

                          </div>
                        </a>
                      </div>
                    </SwiperSlide>

                        </div>
                      </a>
                    </div>
                  </SwiperSlide> */}

                  </Swiper>
                </div>
              </div>
            </section>



            {/* <section className="howdoesitworkSection">
              <div className="container shapeBg">
                <h3> The ultimate flexible living </h3>
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="row">
                      <div className="col-md-6 bd-r">
                        <div className="howwork">
                          <div className="mobviewhowwork">
                            <div className="d-flex align-items-baseline">
                              <h1>1</h1>
                              <h4 className="mg-b-10">Live with us</h4>
                            </div>
                            <p>When you live here, you’re free
                              to roam. Our perfect-length apartments
                              allow you to settle in, and take off for the
                              weekend.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 bd-r">
                        <div className="howwork">
                          <div className="mobviewhowwork">
                            <div className="d-flex align-items-baseline">
                              <h1>2</h1>
                              <h4 className="mg-b-10">Stay with us</h4>
                            </div>
                            <p>Stay for a few days or plant yourself
                              for the season. You’re bound to find the
                              perfect place to fit your style, plus tons of
                              amenities.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mg-t-15">
                    <button>Tell me more</button>
                  </div>
                </div>

              </div>

            </section> */}
            <section className="everthing">
              <div className="container">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="content">
                      <h2> {this.state.PROPERTY_HOME_SECTION_3 ? ((this.state.PROPERTY_HOME_SECTION_3[0]) ? this.state.PROPERTY_HOME_SECTION_3[0].text : "Live or stay — it’s just a tap away") : "Live or stay — it’s just a tap away"} </h2>
                      <h5>
                        {/* 24/7 in-app customer service
                        neighborhood guides
                        homesharing management
                        request late checkout */}
                        {this.state.PROPERTY_HOME_SECTION_3 ? ((this.state.PROPERTY_HOME_SECTION_3[1]) ? this.state.PROPERTY_HOME_SECTION_3[1].text : "24/7 in-app customer service, neighborhood guides, Homesharing management, request late check-out, and tons more...") : "24/7 in-app customer service, neighborhood guides, Homesharing management, request late check-out, and tons more..."}

                      </h5>
                      <ul>
                        <li><img src={global.OnKeyPressEvent.getS3ImageUrl("everything-1.svg")} alt="24/7 in-app customer service" title="24/7 in-app customer service" /> <b>24/7 in-app customer service</b></li>
                        <li><img src={global.OnKeyPressEvent.getS3ImageUrl("everything-2.svg")} alt="Homesharing management" title="Homesharing management" /> <b>Homesharing management</b></li>
                        <li><img src={global.OnKeyPressEvent.getS3ImageUrl("everything-3.svg")} alt="Neighborhood guides" title="Neighborhood guides" /> <b>Neighborhood guides</b></li>
                        <li><img src={global.OnKeyPressEvent.getS3ImageUrl("everything-4.svg")} alt="Request late checkout" title="Request late checkout" /> <b>Request late checkout</b></li>
                      </ul>

                      <div className="downnloadApp d-flex mg-t-0 d-none">
                        <a href="https://orionhaus.page.link/app" target="blank"><img src={global.OnKeyPressEvent.getS3ImageUrl("download_apple.png")} className="mg-r-30" alt="Ios App Image" title="Ios App Image" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.orionhaus.haus" target="blank"> <img src={global.OnKeyPressEvent.getS3ImageUrl("download_android.png")} alt="Android App Image" title="Android App Image" /></a>
                      </div>
                    </div>
                    <div className="simulatorscreen">
                      <img src={this.state.PROPERTY_HOME_SECTION_3 ? ((this.state.PROPERTY_HOME_SECTION_3[2]) ? this.state.PROPERTY_HOME_SECTION_3[2].url : global.OnKeyPressEvent.getS3ImageUrl('mobile-screen.webp')) : global.OnKeyPressEvent.getS3ImageUrl('mobile-screen.webp')} className="w-75 mobilescreen" alt="Mobile App Image" title="Mobile App Image" />
                      <img src={global.OnKeyPressEvent.getS3ImageUrl("simulator-bg-shape.svg")} alt="Mobile App Image" title="Mobile App Image" className="w-100 mobilescreenBg" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="brandBlockone">
              <div className="container position-relative">
                <div className="row">
                  <div className="col-lg-8">
                    <figure>
                      <img src={this.state.PROPERTY_HOME_SECTION_4 ? ((this.state.PROPERTY_HOME_SECTION_4[2]) ? this.state.PROPERTY_HOME_SECTION_4[2].url : global.OnKeyPressEvent.getS3ImageUrl("brandblock-1.webp")) : global.OnKeyPressEvent.getS3ImageUrl("brandblock-1.webp")} alt="Flexible living at its best" title="Flexible living at its best" />
                    </figure>
                  </div>
                  <div className="col-lg-4">
                    <div className="content">
                      <h3>
                        {/* The first building that actually supports homesharing. */}
                        {this.state.PROPERTY_HOME_SECTION_4 ? ((this.state.PROPERTY_HOME_SECTION_4[0]) ? this.state.PROPERTY_HOME_SECTION_4[0].text : "Flexible living at its best") : "Flexible living at its best"}
                      </h3>
                      <p>
                        {/* Getting started is easy with our Kini Beach Haus starter kit. Luxury linens like sheets and towels, high-end amenities like shampoo, conditioner, and body wash, and delicious candles help you take your listing to the next level right away. */}
                        {this.state.PROPERTY_HOME_SECTION_4 ? ((this.state.PROPERTY_HOME_SECTION_4[1]) ? this.state.PROPERTY_HOME_SECTION_4[1].text : "Flexible living at an OHAI community is super easy. Experience what it's like to make money when you're away traveling for work, heading out for vacation, or just spending time at a friends' place over the weekend. We make it easy to live better.") : "Flexible living at an OHAI community is super easy. Experience what it's like to make money when you're away traveling for work, heading out for vacation, or just spending time at a friends' place over the weekend. We make it easy to live better."}
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="brandBlocktwo">
              <div className="container position-relative">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="content">
                      <h3>{this.state.PROPERTY_HOME_SECTION_5 ? ((this.state.PROPERTY_HOME_SECTION_5[0]) ? this.state.PROPERTY_HOME_SECTION_5[0].text : "Professionally managed.") : "Professionally managed."}</h3>
                      <p>{this.state.PROPERTY_HOME_SECTION_5 ? ((this.state.PROPERTY_HOME_SECTION_5[1]) ? this.state.PROPERTY_HOME_SECTION_5[1].text : "Our team does the heavy lifting when it’s most needed (like keeping you in the loop when your guest arrives and leaves; and automatically scheduling turnovers and sanitization).") : "Our team does the heavy lifting when it’s most needed (like keeping you in the loop when your guest arrives and leaves; and automatically scheduling turnovers and sanitization)."}</p>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <figure>
                      <img src={this.state.PROPERTY_HOME_SECTION_5 ? ((this.state.PROPERTY_HOME_SECTION_5[2]) ? this.state.PROPERTY_HOME_SECTION_5[2].url : global.OnKeyPressEvent.getS3ImageUrl("brandblock-2.webp")) : global.OnKeyPressEvent.getS3ImageUrl("brandblock-2.webp")} alt="Professionally managed" title="Professionally managed" />
                    </figure>
                  </div>
                </div>
              </div>
            </section>

            <section className="brandParent">
              <div className="container">
                <h2>{this.state.PROPERTY_HOME_SECTION_6 ? ((this.state.PROPERTY_HOME_SECTION_6[0]) ? this.state.PROPERTY_HOME_SECTION_6[0].text : "Live and travel on your own terms. We know flexibility is key, and we’ll help you monetize your apartment while traveling for work or heading out for the weekend.") : "Live and travel on your own terms. We know flexibility is key, and we’ll help you monetize your apartment while traveling for work or heading out for the weekend."}</h2>
              </div>

              <section className="brandBlockthree">
                <div className="container position-relative">
                  <div className="row">
                    <div className="col-lg-8">
                      <figure>
                        <img src={this.state.PROPERTY_HOME_SECTION_7 ? ((this.state.PROPERTY_HOME_SECTION_7[2]) ? this.state.PROPERTY_HOME_SECTION_7[2].url : global.OnKeyPressEvent.getS3ImageUrl("brandblock-3.webp")) : global.OnKeyPressEvent.getS3ImageUrl("brandblock-3.webp")} alt="Homesharing kits" title="Homesharing kits" />
                      </figure>
                    </div>
                    <div className="col-lg-4">
                      <div className="content">
                        <h3>{this.state.PROPERTY_HOME_SECTION_7 ? ((this.state.PROPERTY_HOME_SECTION_7[0]) ? this.state.PROPERTY_HOME_SECTION_7[0].text : "Homesharing-in-a-baux™.") : "Homesharing-in-a-baux™."}</h3>
                        <p>{this.state.PROPERTY_HOME_SECTION_7 ? ((this.state.PROPERTY_HOME_SECTION_7[1]) ? this.state.PROPERTY_HOME_SECTION_7[1].text : "Getting started is easy with our OHAI starter kit. Luxury linens like sheets and towels, high-end amenities like shampoo, conditioner, and body wash, and delicious candles help you take your listing to the next level right away.") : "Getting started is easy with our OHAI starter kit. Luxury linens like sheets and towels, high-end amenities like shampoo, conditioner, and body wash, and delicious candles help you take your listing to the next level right away."}</p>

                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="brandBlockfour">
                <div className="container position-relative">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="content">
                        <h3>{this.state.PROPERTY_HOME_SECTION_8 ? ((this.state.PROPERTY_HOME_SECTION_8[0]) ? this.state.PROPERTY_HOME_SECTION_8[0].text : "Hosts with the mosts.") : "Hosts with the mosts."}</h3>
                        <p>{this.state.PROPERTY_HOME_SECTION_8 ? ((this.state.PROPERTY_HOME_SECTION_8[1]) ? this.state.PROPERTY_HOME_SECTION_8[1].text : "Our hosts welcome you and your guests every time you visit, and can enhance your or your guests’ stay with insider tips about where to go and what to do.") : "Our hosts welcome you and your guests every time you visit, and can enhance your or your guests’ stay with insider tips about where to go and what to do."}</p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <figure>
                        <img src={this.state.PROPERTY_HOME_SECTION_8 ? ((this.state.PROPERTY_HOME_SECTION_8[2]) ? this.state.PROPERTY_HOME_SECTION_8[2].url : global.OnKeyPressEvent.getS3ImageUrl("brandblock-4.webp")) : global.OnKeyPressEvent.getS3ImageUrl("brandblock-4.webp")} alt="Hosts with the mosts" title="Hosts with the mosts" />
                      </figure>
                    </div>
                  </div>
                </div>
              </section>
            </section>

            <section className="hostingFeatures">
              <div className="container">
                <h2>{this.state.PROPERTY_HOME_SECTION_9 ? ((this.state.PROPERTY_HOME_SECTION_9[0]) ? this.state.PROPERTY_HOME_SECTION_9[0].text : "Hosting features") : "Hosting features"}</h2>
                <h5>{this.state.PROPERTY_HOME_SECTION_9 ? ((this.state.PROPERTY_HOME_SECTION_9[1]) ? this.state.PROPERTY_HOME_SECTION_9[1].text : "OHAI amenities for you and your guests.") : "OHAI amenities for you and your guests."} </h5>
              </div>
              <div className="container-fluid p-0">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={0}
                  slidesPerGroup={1}
                  loop={true}
                  loopFillGroupWithBlank={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"

                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    "@0.75": {
                      slidesPerView: 2,
                      spaceBetween: 0,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 0,
                    },
                    "@1.50": {
                      slidesPerView: 4,
                      spaceBetween: 0,
                    },
                  }}
                >
                  {
                    this.state.PROPERTY_HOME_SECTION_10 ?
                      <>
                        {
                          this.state.PROPERTY_HOME_SECTION_10.map((item, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <div className="featuresitems">
                                  <div className="listImg">
                                    <figure>
                                      <img src={item[2] ? item[2].url : global.OnKeyPressEvent.getS3ImageUrl("feature-1.webp")} alt="Full Time Hosts" title="Full Time Hosts" />
                                    </figure>
                                    <div className="content">
                                      <div className="content-center">
                                        <span>{item[0] ? item[0].text : "Full-time hosts."}</span>
                                        <b>{item[1] ? item[1].text : "Think of them as your OHAI concierge team. They’re here to make your listing sparkle, and make sure your guests have a seamless experience."}</b>
                                      </div>
                                    </div>
                                    <div className="overlap"></div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        }
                      </>
                      :
                      <>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-1.webp")} alt="Full Time Hosts" title="Full Time Hosts" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>Full-time hosts.</span>
                                  <b>Think of them as your Kini Beach Haus concierge team. They’re here to make your listing sparkle, and make sure your guests have a seamless experience. </b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-2.webp")} alt="Digital Locks" title="Digital Locks" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>Digital locks.</span>
                                  <b>Contactless. Bluetooth. Digital. These locks work with your and your guests’ phones. Access and entry is super easy through your Kini Beach Haus app.</b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-3.webp")} alt="App With Insights" title="App With Insights" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>App with insights.</span>
                                  <b>Earnings viewer. Integrated listing and calendar management. Automatically scheduled cleanings. Notifications when your guests arrive and leave. Business analytics dashboard to check your performance. On-site staff messaging. Events calendar. Tax payment reminders.</b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-4.webp")} alt="Ultimate Experiences" title="Ultimate Experiences" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>Ultimate experiences.</span>
                                  <b>Bike-share. Scooter-share. Moke rentals. Yacht charters. Coffee carts in the morning …and gelato carts at night (seriously).</b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-5.webp")} alt="Design Packages" title="Design Packages" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>Design packages.</span>
                                  <b>Hand-selected unique design packages for rent. Great style, and guess what? If anything gets damaged (by you or your guests) you can get a replacement to your unit in 24 hours.</b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-6.webp")} alt="On Site Host Supply Shop" title="On Site Host Supply Shop" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>On-site host supply shop.</span>
                                  <b>With anything you or your guests could need (or want…). Bed and bath linens. Scented candles and room sprays. Shampoo, conditioner, bodywash, lotion. Coffee mugs. Bottled water. Notebooks and stationery.</b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-7.webp")} alt="Resort Pool & Sun Deck" title="Resort Pool & Sun Deck" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>Resort pool & sun deck.</span>
                                  <b>The ultimate resort-style pool deck. Spa. Cabanas. Lounge chairs. Don’t forget the sunscreen. </b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-8.webp")} alt="Fitness Center" title="Fitness Center" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>Fitness Center.</span>
                                  <b>Two full sets of weights. Cables. Cardio room. Full schedule of fitness class — which can be taken in person or streamed online in your home. Free for residents and guests.</b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="featuresitems">
                            <div className="listImg">
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl("feature-9.webp")} alt="Lush Landscaping" title="Lush Landscaping" />
                              </figure>
                              <div className="content">
                                <div className="content-center">
                                  <span>Lush landscaping.</span>
                                  <b>And outdoor kitchens. Walk the grounds to the fire pits. We’ll be at the grill. You bring the fixin’s.</b>
                                </div>
                              </div>
                              <div className="overlap"></div>

                            </div>
                          </div>
                        </SwiperSlide>
                      </>
                  }
                </Swiper>
              </div>
            </section>

            {/* New section addded */}

            <section className="earn_aptmt_sec">
              <div className="container position-relative">
                <div className="row">
                  <div className="col-md-4">
                    <div className="can_earn">
                      <h2 className="earn_heading">What can I earn homesharing my apartment?</h2>
                      <p className="earn_para">You can homeshare your place for up to <b>365 nights per year.</b></p>
                    </div>
                    <div className="can_earn_bg"></div>
                  </div>
                  <div className="col-md-8">
                    <div className="earn_acordion">
                      <div className="miscSection leaseAvailable p-0">
                        {/* <div className="sectionOne">
                          <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                            <Accordion.Item eventKey="0">

                              <Accordion.Header>
                                <p>What kind of apartment?</p>
                              </Accordion.Header>




                              <Accordion.Body>

                                <section className="earn_rate">
                                  <div className="col-12 col-md-12 col-lg-12">
                                    <FormControl>
                                      {this.state.currentFloorplanSlug &&
                                        <Select
                                          labelId="demo-select-small"
                                          id="demo-select-small"

                                          value={this.state.currentFloorplanSlug}
                                          name="add"
                                          onChange={(event) => this.changeHandlerSelect(event)}


                                        >

                                          {this.state.FloorPlanListing.map((item, i) => (
                                            <MenuItem value={item.floorplan_slug}>{item.floorplan_name} &nbsp;- from ${global.OnKeyPressEvent.numberWithCommasForDecimal(item.base_price)}/month</MenuItem>
                                          ))}

                                        </Select>
                                      }
                                    </FormControl>
                                  </div>
                                </section>
                              </Accordion.Body>

                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                <p>How many nights booked will you have per month?</p>
                              </Accordion.Header>
                              <Accordion.Body>
                                <section className="earn_rate">
                                  <div className="col-12 col-md-12 col-lg-12">
                                    <FormControl>
                                      <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={this.state.currentFloorplanNight}

                                        name="adddate"
                                        onChange={(event) => this.changeHandlerSelectmonth(event)}

                                      >

                                        <MenuItem value="1">1 Night</MenuItem>
                                        <MenuItem value="2">2 Nights</MenuItem>
                                        <MenuItem value="3">3 Nights</MenuItem>
                                        <MenuItem value="4">4 Nights</MenuItem>
                                        <MenuItem value="5">5 Nights</MenuItem>
                                        <MenuItem value="6">6 Nights</MenuItem>
                                        <MenuItem value="7">7 Nights</MenuItem>
                                        <MenuItem value="8">8 Nights</MenuItem>
                                        <MenuItem value="9">9 Nights</MenuItem>
                                        <MenuItem value="10">10 Nights</MenuItem>
                                        <MenuItem value="11">11 Nights</MenuItem>
                                        <MenuItem value="12">12 Nights</MenuItem>
                                        <MenuItem value="13">13 Nights</MenuItem>
                                        <MenuItem value="14">14 Nights</MenuItem>
                                        <MenuItem value="15">15 Nights</MenuItem>
                                        <MenuItem value="16">16 Nights</MenuItem>
                                        <MenuItem value="17">17 Nights</MenuItem>
                                        <MenuItem value="18">18 Nights</MenuItem>
                                        <MenuItem value="19">19 Nights</MenuItem>
                                        <MenuItem value="20">20 Nights</MenuItem>
                                        <MenuItem value="21">21 Nights</MenuItem>
                                        <MenuItem value="22">22 Nights</MenuItem>
                                        <MenuItem value="23">23 Nights</MenuItem>
                                        <MenuItem value="24">24 Nights</MenuItem>
                                        <MenuItem value="25">25 Nights</MenuItem>
                                        <MenuItem value="26">26 Nights</MenuItem>
                                        <MenuItem value="27">27 Nights</MenuItem>
                                        <MenuItem value="28">28 Nights</MenuItem>
                                        <MenuItem value="29">29 Nights</MenuItem>
                                        <MenuItem value="30">30 Nights</MenuItem>
                                        <MenuItem value="31">31 Nights</MenuItem>

                                      </Select>
                                    </FormControl>
                                  </div>
                                </section>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>

                          <div className="earn_detail">
                            <p>Your potential revenue by AirDNA</p>

                            <h5>Earn ${global.OnKeyPressEvent.numberWithCommasForDecimal(this.state.total_calculation)} a month</h5>
                            <a href="#" onClick={this.docCalcModal} >How are estimations calculated?</a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="ltr_video_sec">
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-md-6 p-0">
                    <div className="video_step">
                      {/* <h2 className="video_main_head">Homesharing is easy with your OHAI Concierge</h2> */}
                      <h2 className="video_main_head">Homesharing is easy with the OHAI app</h2>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex video_earn_img">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('concierge.png')} alt="" />
                            </figure>
                            <div className="video_earn">
                              <h5>Concierge support 24/7</h5>
                              <p>Day or night, rain or shine, you and your guests have direct access to a concierge team.</p>
                            </div>
                          </div>
                          <div className="d-flex video_earn_img">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('autokey.png')} alt="" />
                            </figure>
                            <div className="video_earn">
                              <h5>Automated key access</h5>
                              <p>The OHAI app automates access so residents, guests, and housekeeping all have automated key access at the right times.</p>
                            </div>
                          </div>
                          <div className="d-flex video_earn_img">
                            <figure>
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('autohouse.png')} alt="" />
                            </figure>
                            <div className="video_earn">
                              <h5>Automated housekeeping</h5>
                              <p>The OHAI app automatically schedules the cleaners, provides their checklists, and checks their completion status. Any complications are managed by your OHAI concierge.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 position-relative video_ltr_position">

                    {/* <div className="video_earn_gallery ltr_fst_vd">
                            <div className="video_pop_img">
                              <ModalVideo
                                id={(new Date() * 1).toString()}
                                source={this.state.source}
                                poster={propmanager}
                                show={this.state.show}
                                showModal={() => this.showModal(true, 'show')}
                                handleClose={() => this.showModal(false, 'show')}
                              />
                            </div>
                          </div> */}

                    {/* <div className="video_earn_gallery ltr_sec_vd">
                            <div className="video_pop_img">
                              <ModalVideo
                                id={(new Date() * 1).toString()}
                                source={this.state.source1}
                                poster={talkimg}
                                show={this.state.show1}
                                showModal={() => this.showModal(true, 'show1')}
                                handleClose={() => this.showModal(false, 'show1')}
                              />
                            </div>
                          </div> */}

                    <div className="video_earn_gallery">
                      <div className="video_pop_img">
                        <ModalVideo
                          id={(new Date() * 1).toString()}
                          source={this.state.source2}
                          poster={global.OnKeyPressEvent.getS3ImageUrl('OrionHausHomesharingProcessvideo.JPG')}
                          show={this.state.show2}
                          showModal={() => this.showModal(true, 'show2')}
                          handleClose={() => this.showModal(false, 'show2')}
                        />
                      </div>
                    </div>

                    {/* <div className="video_earn_gallery ltr_fur_vd">
                            <div className="video_pop_img">
                              <ModalVideo
                                id={(new Date() * 1).toString()}
                                source={this.state.source3}
                                poster={programvideo}
                                show={this.state.show3}
                                showModal={() => this.showModal(true, 'show3')}
                                handleClose={() => this.showModal(false, 'show3')}
                              />
                            </div>
                          </div> */}

                    {/* <div className="video_earn_gallery ltr_fiv_vd">
                            <div className="video_pop_img">
                              <ModalVideo
                                id={(new Date() * 1).toString()}
                                source={this.state.source4}
                                poster={keyvideo}
                                show={this.state.show4}
                                showModal={() => this.showModal(true, 'show4')}
                                handleClose={() => this.showModal(false, 'show4')}
                              />
                            </div>
                          </div> */}

                    {/* <div className="video_earn_gallery ltr_six_vd">
                            <div className="video_pop_img">
                              <ModalVideo
                                id={(new Date() * 1).toString()}
                                source={this.state.source5}
                                poster={kitvideo}
                                show={this.state.show5}
                                showModal={() => this.showModal(true, 'show5')}
                                handleClose={() => this.showModal(false, 'show5')}
                              />
                            </div>
                          </div> */}

                    {/* New video gallery design */}
                    {/* <div className="videoSection">
                      <div className="video_earn_gallery"> */}
                    {/* <div className="video_pop_img1">
                                <ModalVideo
                                  id={(new Date() * 1).toString()}
                                  source={this.state.source1}
                                  poster={talkimg}
                                  show={this.state.show1}
                                  showModal={() => this.showModal(true, 'show1')}
                                  handleClose={() => this.showModal(false, 'show1')}
                                />
                              </div> */}
                    {/* <div className="video_pop_img2">
                                <ModalVideo
                                  id={(new Date() * 1).toString()}
                                  source={this.state.source5}
                                  poster={kitvideo}
                                  show={this.state.show5}
                                  showModal={() => this.showModal(true, 'show5')}
                                  handleClose={() => this.showModal(false, 'show5')}
                                />
                              </div> */}
                    {/* <div className="video_pop_img3">
                          <ModalVideo
                            id={(new Date() * 1).toString()}
                            source={this.state.source2}
                            poster={global.OnKeyPressEvent.getS3ImageUrl('OrionHausHomesharingProcessvideo.JPG')}
                            show={this.state.show2}
                            showModal={() => this.showModal(true, 'show2')}
                            handleClose={() => this.showModal(false, 'show2')}
                          />
                        </div>
                        <div className="video_pop_img4">
                          <ModalVideo
                            id={(new Date() * 1).toString()}
                            source={this.state.source3}
                            poster={programvideo}
                            show={this.state.show3}
                            showModal={() => this.showModal(true, 'show3')}
                            handleClose={() => this.showModal(false, 'show3')}
                          />
                        </div> */}
                    {/* <div className="video_pop_img5">
                                <ModalVideo
                                  id={(new Date() * 1).toString()}
                                  source={this.state.source}
                                  poster={propmanager}
                                  show={this.state.show}
                                  showModal={() => this.showModal(true, 'show')}
                                  handleClose={() => this.showModal(false, 'show')}
                                />
                              </div> */}
                    {/* </div>
                    </div> */}
                    {/* End New video gallery design */}

                  </div>
                </div>
              </div>
            </section>

            {/* <section className="table_compare_sec">
              <div className="container">
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <div className="tbl_cmp_heading">
                      <h2 className="oh_tbl_heading">Homesharing made easy</h2>
                    
                        <p className="oh_tbl_para">The OHAI app makes homesharing easy, by automating many operations.</p>
                    </div>
                    <div className="table_oh">
                      <table class="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <p>OHAI does for you</p>
                            </th>
                            <th>
                              <p >Resident controls</p>
                            </th>

                          </tr>
                        </thead>
                        <tbody>
                        
                          <tr>
                            <th >
                              Automated key access
                            </th>
                            <td>
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                              </figure>
                            </td>
                          </tr>
                          <tr>
                            <th >
                             
                              Automated housekeeper scheduling
                            </th>
                            <td>
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                              </figure>
                            </td>
                          </tr>
                          <tr>
                            <th >
                             
                              Automated messages
                            </th>
                            <td>
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                              </figure>
                            </td>
                          </tr>
                          <tr>
                            <th >
                             
                              Automated guest screening
                            </th>
                            <td>
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                              </figure>
                            </td>
                          </tr>
                          <tr>
                            <th >
                             
                              Automated house manual
                            </th>
                            <td>
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                              </figure>
                            </td>
                          </tr>
                          <tr>
                            <th >
                             
                              Automated property photos
                            </th>
                            <td>
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                              </figure>
                            </td>
                          </tr>
                          <tr>
                            <th >
                             
                              Direct Booking site
                            </th>
                            <td>
                              <figure>
                                <img src={global.OnKeyPressEvent.getS3ImageUrl('right_c.png')} alt="" />
                              </figure>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </section> */}
   
            {/* new design Homesharing made easy */}

            <section className="table_compare_sec">
                
                <div className="container">
                  <div className="row">
                      <div className="tbl_cmp_heading">
                      <h2 className="oh_tbl_heading">Homesharing made easy</h2>
                      <p className="oh_tbl_para text-center pd-b-50">The OHAI app makes homesharing easy, by automating many operations.</p>
                      </div>
                      <div className="homesharingmadeeasy">
                          <div className="row m-0">
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-1.png')} alt="" />
                                          </figure>
                                        <span> Calendar management</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes_2.png')} alt="" />
                                          </figure>
                                        <span> Automated key access</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-3.png')} alt="" />
                                          </figure>
                                        <span> Automated housekeeper scheduling</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-4.png')} alt="" />
                                          </figure>
                                        <span> Automated messages</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-5.png')} alt="" />
                                          </figure>
                                        <span> Automated guest screening</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-6.png')} alt="" />
                                          </figure>
                                        <span> Automated house manual</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-7.png')} alt="" />
                                          </figure>
                                        <span> Automated property photos</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                                  <div className="block">
                                      <figure>
                                            <img src={global.OnKeyPressEvent.getS3ImageUrl('homes-8.png')} alt="" />
                                          </figure>
                                        <span> Direct Booking site</span>
                                    </div>
                                </div>
                          </div>
                        </div>
                </div>
                </div>
              </section>
              
              {/* End new design Homesharing made easy */}


            <section className="ltr_faq_sec">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="ltr_faq_heading">Frequently asked questions</h2>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="miscSection leaseAvailable p-0">
                      <div className="ltr_faq">
                        <Accordion>
                          <>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <h5>What do I do to manage my listing, versus what does OHAI do? </h5>
                              </Accordion.Header>

                              <Accordion.Body>
                                <p>You are totally in charge of your listing and your earnings. You manage your pricing, the way your listing looks, your photos, your content, and your availability. OHAI manages all of the logistics — 24/7 guest chat with our Concierge team, key changeovers and access control for you, your guests, and housekeeping, and managing the cleaners.</p>
                              </Accordion.Body>

                            </Accordion.Item>
                          </>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              <h5>I’m not enrolled in Airbnb because I am a renter, can I still qualify?</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>100%. If you live at an OHAI partnered building, like these, you can absolutely participate in homesharing.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <h5>What do I get to control on my listing?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>Your listing name, your listing description, your photos, your in-apartment amenities, your pricing, your calendar.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              <h5>Why doesn’t OHAI just rent the apartments themselves?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>OHAI works with apartment building owners to provide the homesharing amenity to their residents. </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              <h5>What happens if there is a guest emergency?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>Guests are in contact with our 24/7 Concierge team that will assist in emergencies, and work with you and the property manager to resolve the issue.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              <h5>How do guests access my wifi?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>You put your wifi info in your app for the guests to see in their reservation info.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="miscSection leaseAvailable p-0">
                      <div className=" sec_faq_ltr">
                        <Accordion>
                          <>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <h5>Can I manage my own cleanings? Communicate with my guests? Have the listings on my Airbnb profile? </h5>
                              </Accordion.Header>

                              <Accordion.Body>
                                <p>You can customize your checklist and be listed as a co-host, but you can’t communicate with guests.</p>
                              </Accordion.Body>

                            </Accordion.Item>
                          </>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              <h5>How can guests book my unit?</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>They can book through any listed OTA channel or OrionHaus.com</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <h5>What is the housekeeping process?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>OHAI has a standard cleaning process for apartments, but if needed, it can be customized to your needs.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              <h5>What happens if there is a damage in my unit?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>The OHAI Concierge team will work with housekeeping and you to resolve any issues of damage.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              <h5>When are the earnings paid out?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>Earnings are paid out on the 15th of the month, as long as your rent has been paid on-time.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              <h5>What is the application and move-in process?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                              <p>Most of our properties have a digital application and leasing process, but make sure to check with your OHAI leasing team to find out.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div> */}

                  {/* New faqs */}
                  <div className="col-md-6">
                          <div className="miscSection leaseAvailable p-0">
                            <div className="ltr_faq">
                              <Accordion>
                                <>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                    <h5>How do I get started homesharing? </h5>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                    <p>Once you are moved into your OHAI-partnered apartment community, you’ll be invited to the OHAI app which will guide you through creating your listing in a few steps.</p>
                                    </Accordion.Body>

                                  </Accordion.Item>
                                </>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                  <h5>What do I do with my personal belongings when guests are in my apartment?</h5>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                  <p>We recommend certain locks that you can use to secure your closet or cabinets.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header>
                                  <h5>How will guests enter the building and my apartment?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                  <p>Key access is automatically created through the OHAI app.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header>
                                    <h5>How does cleaning work after guests leave?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p>Guest reservation cleaning is automatically scheduled through the OHAI app. </p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>
                                  <h5>How does pricing work?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p>You set your own pricing in the OHAI app.</p>
                                    <p>As part of this program, the building deducts a portion of your homesharing earnings. Reach out to the building manager to ask for more details.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header>
                                  <h5>Are there any other rules I should be aware of?</h5>

                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <p>Buildings have required house rules for residents and guests including things like quiet hours. Homesharing here is exclusively through the OHAI homesharing program. All local laws apply, including all lease provisions.</p>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </div>
                  </div>
                  <div className="col-md-6">
                    <div className="miscSection leaseAvailable p-0">
                      <div className=" sec_faq_ltr">
                        <Accordion>
                          <>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <h5>I’m not enrolled in Airbnb because I am a renter. Can I still qualify? </h5>
                              </Accordion.Header>

                              <Accordion.Body>
                                <p>Yes, living at this property, and any OHAI-partnered property lets you homeshare.</p>
                              </Accordion.Body>

                            </Accordion.Item>
                          </>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                            <h5>What do I get to control on my listing?</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                            <p>You control your pricing, your availability, your title and listing description, photography.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                            <h5>How do guests access my wifi?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                            <p>Guests can find your wifi inside of the house manual in their app or online.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                            <h5>When are earnings paid out?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                            <p>Payouts are sent on or before the 15th of the month for the prior month’s reservations, so long as your rent has been paid.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                            <h5>What is the application and move in process?</h5>

                            </Accordion.Header>
                            <Accordion.Body>
                            <p>On this page click on Contract the property and you’ll be connected to start the process.</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="ready_move_sec">
              <div className="container">
                <div className="row ready_mob-revert">
                  <div className="col-md-4">
                    <div className="ready_cnt">
                      <h2 className="ready_move_heading">Ready to move forward?</h2>
                      <button onClick={() => this.openvirtualtour()}>Contact the property</button>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="ready_box">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('ss1.png')} alt="" />
                      </figure>
                      <p>Use your OHAI app
                        to make your listing
                      </p>
                    </div>
                    <div className="ready_box">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('ss2.png')} alt="" />
                      </figure>
                      <p>Customize your photos
                        and pricing
                      </p>
                    </div>
                    <div className="ready_box">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('ss3.png')} alt="" />
                      </figure>
                      <p>Enjoy your days away,
                        we’ve got your back
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="ltr_partnered_sec">
              <div className="container-fluid- container">
                <div className="row">
                  <div className="col-md-6 p-0">
                    <div className="partnered_img">
                      <figure>
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('homesharingprogram.jpg')} alt="" />
                        {/* <img src={this.state.property_slug === "merino-scottsdale" ? global.OnKeyPressEvent.getS3ImageUrl('homesharingprogram.jpg') : this.state.property_main_image} alt="" /> */}
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="partnered_cnt">
                      <h2 className="part_head">
                        OHAI partnered
                        apartment buildings
                      </h2>
                      <p className="part_ltr_para">OHAI partners with building owners to provide the ability to homeshare to all residents in their community. Property managers have full visibility into all hosting activity, guests, housekeeping, and maintenance.</p>
                      <button onClick={() => this.openvirtualtour()}>Contact the property</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            {/* End new section addded */}

            <section className="subscribeForm">
              <div className="container">
                <img src={global.OnKeyPressEvent.getS3ImageUrl("send-icon.svg")} alt="Sign Up" title="Sign Up" />
                <h2>{this.state.PROPERTY_HOME_SECTION_11 ? ((this.state.PROPERTY_HOME_SECTION_11[0]) ? this.state.PROPERTY_HOME_SECTION_11[0].text : "Sign me up!") : "Sign me up!"}</h2>
                <p>{this.state.PROPERTY_HOME_SECTION_11 ? ((this.state.PROPERTY_HOME_SECTION_11[1]) ? this.state.PROPERTY_HOME_SECTION_11[1].text : "Sign up with your email to receive updates from OHAI. We won’t spam you, we promise — just the good stuff.") : "Sign up with your email to receive updates from OHAI. We won’t spam you, we promise — just the good stuff."}
                </p>

                <div className="formSection">
                  <Form>
                    <Form.Group className="mb-3 d-flex flex-wrap justify-content-center group">
                      {/* <Form.Control
                        type="text" placeholder="First name" id="first_name_sub"
                        name="first_name"
                        value={this.state.first_name}
                        onChange={this.changeHandler} error={
                          this.state.errors.first_name ? true : false
                        }
                        helperText={
                          this.state.errors.first_name
                            ? this.state.errors.first_name
                            : ""
                        } />
                      <Form.Control
                        type="text" placeholder="Last name" name="last_name"
                        value={this.state.last_name}
                        onChange={this.changeHandler} />
                      <Form.Control
                        type="email" placeholder="Email" name="email"
                        value={this.state.email}
                        onBlur={this.validateEmail}
                        onChange={this.changeHandler}
                        id="email_sub" error={this.state.errors.email ? true : false}
                        helperText={
                          this.state.errors.email
                            ? this.state.errors.email
                            : ""
                        } /> */}


<div>
                        <TextField
                          type="text" placeholder="First name" id="first_name_sub"
                          name="first_name"
                          value={this.state.first_name}
                          onChange={(e) => this.changeHandler(e)}
                          error={
                            this.state.errors.first_name ? true : false
                          }
                          helperText={
                            this.state.errors.first_name
                              ? this.state.errors.first_name
                              : ""
                          } />

                        {this.state.errors.first_name !== "" ? (
                          <p className="error-message">
                            {this.state.errors.first_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <TextField
                          type="text" placeholder="Last name" name="last_name"
                          value={this.state.last_name}
                          onChange={(e) => this.changeHandler(e)} />
                        {this.state.errors.last_name !== "" ? (
                          <p className="error-message">
                            {this.state.errors.last_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <TextField
                          type="email" placeholder="Email" name="email"
                          value={this.state.email}
                          //onBlur={() => this.validateEmail()}
                          onChange={(e) => this.changeHandler(e)}
                          id="email_sub" error={this.state.errors.email ? true : false}
                          helperText={
                            this.state.errors.email
                              ? this.state.errors.email
                              : ""
                          } />
                        {this.state.errors.email !== "" ? (
                          <p className="error-message">
                            {this.state.errors.email}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <button disabled={this.state.loader ? true : false} onClick={(e) => this.saveNewsletter(e)}>{this.state.FOOTER ? ((this.state.FOOTER[2]) ? this.state.FOOTER[2].text : "I'm in!") : "I'm in!"}</button>
                      </div>
                      {/* <button
                                          hidden
                                          className="w-100"
                                          id="virtual-tour-button"
                                        >
                                          Confirm
                                        </button> */}
                      <span className="subscribemsg">
                        {this.state.succes_message ? (<img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} alt="Thank you for your subscription" title="Thank you for your subscription" />)
                          : ""}{this.state.succes_message}</span>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </section>

            <section className="FooterAbout">
              <div className="container">
                <div className="content">
                  <a href="#">
                    <figure>
                      <img src={this.state.PROPERTY_HOME_SECTION_12 ? ((this.state.PROPERTY_HOME_SECTION_12[0]) ? this.state.PROPERTY_HOME_SECTION_12[0].url : global.OnKeyPressEvent.getS3ImageUrl('footer-logo.png')) : global.OnKeyPressEvent.getS3ImageUrl('footer-logo.png')} alt="Property Logo" title="Property Logo" />
                    </figure>
                  </a>
                  <p>
                    {/* Travel, hospitality and lifestyle revolutionaries Cindy Diffenderfer and Kanan Whited proudly introduce Kini Beach Haus  — a revolutionary platform that turns everyday renters into real estate investors. */}
                    {this.state.PROPERTY_HOME_SECTION_12 ? ((this.state.PROPERTY_HOME_SECTION_12[1]) ? this.state.PROPERTY_HOME_SECTION_12[1].text : this.state.description) : this.state.description}
                  </p>
                  <a href="/partnerships"><button>Learn more</button></a>

                  {/* <ul>
                    <li><a href="https://www.instagram.com/orionhaus/" title="Instagram" target="blank"><img src={instagram} /></a></li>
                    <li><a href="https://www.facebook.com/liveorionhaus" title="Facebook" target="blank"><img src={facebook} /></a></li>
                    <li><a href="https://www.linkedin.com/company/orionhaus" title="Linkedin" target="blank"><img src={linkedin} /></a></li>
                    <li><a href="mailto:concierge@orionhaus.com" title="Mail"><img src={mail} /></a></li>
                  </ul> */}
                </div>
              </div>
            </section>
          </div>
        </section>



        {/* Footer */}
        <footer>
          <div className="container-fluid">
            <div className="footer-bottom">
              <p>Ⓒ {this.state.FOOTER_COPY_RIGHT ? ((this.state.FOOTER_COPY_RIGHT[0]) ? this.state.FOOTER_COPY_RIGHT[0].text : "OHAI. All rights reserved.") : "OHAI. All rights reserved."} </p>
              <ul>
                <li><a href="/partnerships" >{this.state.section_header_data && this.state.section_header_data[1] && this.state.section_header_data[1].text ? this.state.section_header_data[1].text : 'Partnerships'}</a></li>
                {/* <li><a href="/contact" >{this.state.section_header_data && this.state.section_header_data[2] && this.state.section_header_data[2].text ? this.state.section_header_data[2].text : 'Contact'}</a></li> */}
                <li><a href="/contact">{this.state.FOOTER_COPY_RIGHT ? ((this.state.FOOTER_COPY_RIGHT[1]) ? this.state.FOOTER_COPY_RIGHT[1].text : "Contact") : "Contact"}</a></li>
                <li><a href="/disclaimers">{this.state.FOOTER_COPY_RIGHT ? ((this.state.FOOTER_COPY_RIGHT[2]) ? this.state.FOOTER_COPY_RIGHT[2].text : "Disclaimers") : "Disclaimers"}</a></li>
                <li><a href="/privacy">Privacy</a></li>
                <li><a href="/terms-condition">Terms</a></li>
              </ul>
            </div>
          </div>
          <img alt="Top Arrow" title="Top Arrow" onClick={() => this.scrollTop()} className="top-arrow" src={global.OnKeyPressEvent.getS3ImageUrl('top-arrow.svg')} />
        </footer>

        {/* <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#EEEDEB", color: "#000", boxShadow: "0 0 4px 0px #000" }}
          expires={150}
        >
          This site uses cookies to provide you with a great user experience. By using OHAI, you accept our use of cookies.{" "}
        </CookieConsent> */}

        {/* Footer */}
      </main>
    );
  }
}
export default PropertyIndex;