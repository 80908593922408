import React, { Component } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import AuthenticationService from "../../services/AuthenticationService";
import SpinnerLoader from "../common/SpinnerLoader";
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: {},
      password: "",
      confirmPassword: "",
      confirmPasswordError: "",
      passwordError: "",
      property_slug: global.property_slug,
      loader: false,
      resetPasswordError: "",
      resetPasswordSuccess: "",
      resetPasswordToken: props.match.params.resetPasswordToken
        ? props.match.params.resetPasswordToken
        : "",
      resetPasswordModal: true,
    };
    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    // console.log("login", this.state);
    this.validateUserToken();
  }

  async validateUserToken() {
    let res = await AuthenticationService.validateToken(
      this.state.resetPasswordToken
    );

    if (res.status === 400) {
      this.props.history.push("/reset-token");
    }
  }
  
  passwordCheckFun = async() => {
    let passwordError = this.state.passwordError;
    let passwordCheck = global.OnKeyPressEvent.validatePassword(this.state.password);
      if(passwordCheck !== ""){
        passwordError = passwordCheck
      } else {
        passwordError = "";
      }
      await this.setState({passwordError:passwordError});
  }

  ResetPassword = async (e) => {
    e.preventDefault();
    await this.passwordCheckFun();
    this.setState({ loader: true });
    if (
      this.state.password !== "" &&
      this.state.passwordError === "" &&
      this.state.confirmPassword !== "" &&
      this.state.confirmPasswordError === ""
    ) {
      // Validate all fields before data submission
      this.setState({ isSubmit: true });
      let input = {
        new_password: this.state.password,
        password_confirmation: this.state.confirmPassword,
        reset_token: this.state.resetPasswordToken,
      };
      let res = await AuthenticationService.resetPassword(input);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          resetPasswordSuccess: "Your password has been reset.",
        });
        setTimeout(() => this.managerResetPassword(), global.redirect_time);
      } else {
        this.setState({
          resetPasswordError: res.data.message ? res.data.message : "",
        });
        setTimeout(() => this.managerResetPassword(), global.redirect_time);
      }
    } else {
      if (!this.state.password) {
        this.setState({ passwordError: "Password is required." });
      }
      if (!this.state.confirmPassword) {
        this.setState({
          confirmPasswordError: "Confirm password is required.",
        });
      }
    }
    this.setState({ loader: false });
  };

  managerResetPassword = () => {
    this.setState({
      resetPasswordError: "",
      resetPasswordSuccess: "",
      password: "",
      confirmPassword: "",
    });
    this.setState({ resetPasswordModal: false });
    window.location.href = "/";
  };

  changeHandler = (event) => {
    let value = event.target.value;
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ resetPasswordError: "", resetPasswordSuccess: "" });
    if (event.target.name === "confirmPassword") {
      if (event.target.value.length > 4) {
        this.setState({ confirmPasswordError: "" });
      }
    }

    if (event.target.name === "password") {
      if (event.target.value.length > 4) {
        this.setState({ passwordError: "" });
      }
    }

    if (
      event.target.name === "confirmPassword" &&
      event.target.value.length >= 1
    ) {
      var pw =
        event.target.name === "password"
          ? event.target.value
          : this.state.password;
      var cpw =
        event.target.name === "confirmPassword"
          ? event.target.value
          : this.state.confirmPassword;

      if (pw !== cpw) {
        this.setState({
          confirmPasswordError: "Password and confirm password must be same.",
        });
      } else {
        this.setState({ confirmPasswordError: "" });
      }
    }
  };

  render() {
    return (
      <>
        <main className="oh-template Brandmainpage detail_page">
          <div className="bgresetPassword">
                <Modal
                  show={this.state.resetPasswordModal}
                  centered
                  className="reservationBook"
                >
                   <span
                      className="welcomeClose float-end"
                      onClick={() => this.managerResetPassword(false)}
                    >
                      {global.closee}
                    </span>
                  <ModalBody className="width_402 pb-5 border_radius_14">


                    <div className="text-center">
                      <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                          sx={{
                            // marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <figure>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('logo-footer.png')} alt="Property Logo" title="Property Logo" />
                          </figure>
                          <div className="w-100">
                            <Typography component="h1" variant="h3">
                              Reset password
                            </Typography>
                          </div>

                          <div className="w-100">
                            <Box
                              // component="form"
                              // onSubmit={this.loginUser}
                              // noValidate
                              sx={{ mt: 1 }}
                            >
                              {this.state.resetPasswordError && (
                                <>
                                  <p className="text-danger text-center m-auto font-14-cust">
                                    {" "}
                                    {this.state.resetPasswordError}
                                  </p>
                                </>
                              )}
                              {this.state.resetPasswordSuccess && (
                                <>
                                  <p className="text-success text-center m-auto font-14-cust">
                                    {" "}
                                    {this.state.resetPasswordSuccess}
                                  </p>
                                </>
                              )}
                               <div className="countryphone">
                              <TextField
                                margin="normal"
                                className="textfield"
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                name="password"
                                onBlur={() => this.passwordCheckFun()}
                                onChange={(e) => this.changeHandler(e)}
                                error={this.state.passwordError ? true : false}
                                value={this.state.password}
                                // autoComplete="email"
                                helperText={
                                  this.state.passwordError
                                    ? this.state.passwordError
                                    : ""
                                }
                                autoFocus={false}
                              />
                              </div>
                              <div className="countryphone">
                              <TextField
                                margin="normal"
                                className="textfield"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm password"
                                type="password"
                                id="confirmPassword"
                                onChange={this.changeHandler}
                                error={
                                  this.state.confirmPasswordError ? true : false
                                }
                                value={this.state.confirmPassword}
                                helperText={
                                  this.state.confirmPasswordError
                                    ? this.state.confirmPasswordError
                                    : ""
                                }
                                // autoComplete="current-password"
                              />
                              </div>

                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={(e) => this.ResetPassword(e)}
                                disabled={this.state.loader ? true : false}
                              >
                                Submit{" "}
                                {this.state.loader ? (
                                  <>
                                    {/* <Spinner
                                      as="span"
                                      animation="grow"
                                      role="status"
                                      aria-hidden="true"
                                      className="spinner_loader"
                                    />
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      role="status"
                                      aria-hidden="true"
                                      className="spinner_loader"
                                    />
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      role="status"
                                      aria-hidden="true"
                                      className="spinner_loader"
                                    /> */}
                                    <SpinnerLoader />
                                  </>
                                ) : (
                                  ""
                                )}
                              </Button>
                            </Box>
                          </div>
                        </Box>
                      </Container>
                    </div>
                  </ModalBody>
                </Modal>
                </div>
        </main>
      </>
    );
  }
}

export default ResetPassword;
