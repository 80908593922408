//     							                          //
//  Program: Inventory.jsx                                //
//  Application: KINI HTML                                //
//  Option: Home Sharing Listing                          //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import Moment from "moment";
import StrlistingApi from "../../services/StrlistingApi";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { Skeleton, Typography } from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import ReservationService from "../../services/ReservationService";
import { Swiper, SwiperSlide } from "swiper/react";
import TextField from '@mui/material/TextField';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";

class ReservationDetails extends Component {
  constructor() {
    super();
    this.state = {
      currentData: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details"))
        : [],
      input: {},
      headerToken: localStorage.getItem("headerToken") ? localStorage.getItem("headerToken") : "",  
      is_form_submited: localStorage.getItem("is_form_submited") ? localStorage.getItem("is_form_submited") : "",  
      card_tokenise: localStorage.getItem("card_tokenise") ? localStorage.getItem("card_tokenise") : "",  
      is_card_enable:localStorage.getItem("card_enable") ? localStorage.getItem("card_enable") : "", 
      parking_payment: localStorage.getItem("parking_payment") ? localStorage.getItem("parking_payment") : "",  
      carcolor: localStorage.getItem("trip_details")
      ? JSON.parse(localStorage.getItem("trip_details")).car_type_color
      : "",
      cancelModal: false,
      openGettingAroundModal: false,
      cancel_amount: "",
      openGuestmanualModal: false,
      editableCar: false,
      openHouserulesModal: false,
      openResFaqModal: false,
      openArrivalinstructionModal:false,
      tab: parseInt(localStorage.getItem("tab")),
      today: Moment(new Date()).format("YYYY-MM-DD H:m:s"),
      carcolor_error:"",
    };
    this.neighborhoodDetail = this.neighborhoodDetail.bind(this);
    this.editableCar = this.editableCar.bind(this);
  }
  editableCar = () => {
    this.setState({ editableCar: true })
  }
  componentDidMount() {
    this.getGuestFormDetails();
    this.getFaqDetail();
    this.Cancelamount();
    this.neighborhoodDetail();
    window.addEventListener("popstate", this.onBackButtonEvent)
    var channel = this.state.currentData.set_channel ? this.state.currentData.set_channel : 1;
    if (channel === 1) {
      this.setState({ set_channel: "Direct Booking"});
    }
    else if (channel === 2) {
      this.setState({ set_channel: "Airbnb" });
    }
    else if (channel === 3) {
      this.setState({ set_channel: "OTA" });
    }
    else if (channel === 4) {
      this.setState({ set_channel: "Expedia" });
    }
    else if (channel === 5) {
      this.setState({ set_channel: "Vrbo" });
    }

  }
  onBackButtonEvent = () => {
    this.props.history.push("/reservation-list");
}
onSubmitCar = async (e) => {
  e.preventDefault();
  if (this.state.carcolor === "") {
    this.setState({ carcolor_error: "Please enter valid Type and Color" });
    return false;
  }
  var confirmationcode = this.state.currentData.confirmation_code;
    let inputData = {
      confirmation_code:confirmationcode,
      car_type_color:this.state.carcolor
    };

    let res = await ReservationService.UpdateCarcolor(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({editableCar : false});
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
    }
    this.setState({ submitloader: false });
}
changeHandlerCar = (event) => {
  let value = event.target.value;
  let input = this.state.input;
  if (event.target.name === "carcolor") {
    if (event.target.value === "") {
      this.setState({ carcolor_error: "Please enter valid Type and Color" });
    } else {
      this.setState({ carcolor_error: "" });
    }
  }
  input[event.target.name] = value;
  this.setState({ [event.target.name]: value, input: input, carcolor: value });
}
getFaqDetail = async () => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecordsfaq = 0;
    var listfaq = [];
    var property_slug = this.state.currentData?.property?.slug;
    let res = await ReservationService.getFaqdata(property_slug);
    if (global.successStatus.includes(res.status)) {
      listfaq = res.data ? res.data?.data : [];
      totalRecordsfaq = listfaq ? listfaq.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      listfaq:listfaq,
      totalRecordsfaq: totalRecordsfaq,
    });
  }
  getGuestFormDetails = async () => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecords = 0;
    var list = [];
    var formSubmited = "";
    var cardTokenise = "";
    var ParkingSubmited = "";
    var ParkingReceipt = "";
    var WavioReceipt = "";
    var is_card_enable = "";
    var confirmationcode = this.state.currentData.confirmation_code;
    let res = await ReservationService.getGuestformdata(confirmationcode);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;
      formSubmited = res.data.is_form_submited ? res.data.is_form_submited:0;
      cardTokenise = res.data.card_tokenise ? res.data.card_tokenise:0;
      ParkingSubmited = res.data.parking_payment ? res.data.parking_payment:0;
      ParkingReceipt = res.data.stripe_receipt_url ? res.data.stripe_receipt_url:"";
      WavioReceipt = res.data.wavio_stripe_receipt_url ? res.data.wavio_stripe_receipt_url:"";
      is_card_enable = res.data.is_card_enable ? res.data.is_card_enable:0;

    }
    this.setState({
      is_form_submited:formSubmited,
      parking_payment:ParkingSubmited,
      card_tokenise:cardTokenise,
      ParkingReceipt:ParkingReceipt,
      WavioReceipt:WavioReceipt,
      is_card_enable:is_card_enable,
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
    });
    localStorage.setItem("is_form_submited", this.state.is_form_submited);
    localStorage.setItem("card_tokenise", this.state.card_tokenise);
    localStorage.setItem("parking_payment", this.state.parking_payment);
    localStorage.setItem("card_enable", this.state.is_card_enable);
  }
   async neighborhoodDetail() {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var property_slug = this.state.currentData.property ? this.state.currentData.property.property_seo_slug:"";
    var totalRecordsneighborhood = 0;
    let res = await StrlistingApi.getNeighbourhoodlisting(property_slug);

    if (global.successStatus.includes(res.status)) {
      var resData = {};
      list = res.data ? res.data : [];
      totalRecordsneighborhood = list ? list.length : 0;
    }
     await this.setState({
      neighborhoodlist: list,
      totalRecordsneighborhood:totalRecordsneighborhood,
     });
  }
  openGuestmanualModal = (val = false) => {
    this.setState({ openGuestmanualModal: val });
  };
  openHouserulesModal = (val = false) => {
    this.setState({ openHouserulesModal: val });
  };

  openResFaqModal = (val = false) => {
    this.setState({ openResFaqModal: val });
  };

  openArrivalinstructionModal = (val = false) => {
    this.setState({ openArrivalinstructionModal: val });
  };
  Cancelamount = async () => {
    var today = Moment(this.state.today);
    var bookeddate = Moment(this.state.currentData.checkin_date);
    // var today = Moment("2022-09-27 17:00:37");
    // var bookeddate = Moment("2022-09-29 18:00:37");
    var price = this.state.currentData.total_payment;
    var diff = bookeddate.diff(today);
    var diff_days = Moment.duration(diff)._data.days;
    var diff_hours = bookeddate.diff(today, 'hours');
    if (diff_hours > 48 && diff_days >= 14) {
      this.setState({ cancel_amount: price });
    } else if (diff_days < 14 && diff_days >= 7) {
      var price_half = price / 2;
      this.setState({ cancel_amount: price_half });
    } else {
      this.setState({ cancel_amount: 0 });
    }

  }
  cancelModal = async (value, item = []) => {
    this.setState({ reservationModal: !value });
    await this.setState({
      currentData: item,
    });
    this.setState({ cancelModal: value });
  };
  cancelReservation = async (value, item = []) => {
    let input = {
      user_slug: item.confirmation_code ? item.confirmation_code : "",
      date: this.state.today,
      transaction_id: item.stripe_transaction_id
        ? item.stripe_transaction_id
        : "",
      // total_payment:this.state.cancel_amount,
      total_payment: this.state.cancel_amount
        ? (parseFloat(this.state.cancel_amount).toFixed(2)) : parseInt(0).toFixed(2)
    };

    let res = await ReservationService.cancelReservationData(input);
    if (global.successStatus.includes(res.status)) {
      this.setState({ cancelModal: false });
      window.location.href = "/reservation-list";
    } else {
      alert("something went wrong try after sometime");
      this.setState({ cancelModal: false });
    }
    await this.props.getReservationData("", 2);
    //this.setState({ cancelModal: value });
  };
  
  openGettingAroundModal = (val = false) => {
    this.setState({ openGettingAroundModal: val });
  };
  

  render() {
    const listing_url =  "/"+ (this.state.currentData.property ?this.state.currentData.property.property_seo_slug :"") +  '/rooms/' + (this.state.currentData ?this.state.currentData.listing_slug :"");
    const checkin_url =  "/reservation/"+ (this.state.currentData.confirmation_code ?this.state.currentData.confirmation_code :"");
    const parking_url =  "/parking/"+ (this.state.currentData.confirmation_code ?this.state.currentData.confirmation_code :"");
    const parkingAmount =  this.state.currentData?global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData?.parking_fee_stripe).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2);
    const parkingFeedisplay =  this.state.currentData?global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData?.property_parking_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2);
    const oldReservationDate = new Date("11/20/2023");
    const Datecheck = Moment(this.state.currentData?.bookedDate).isBefore(oldReservationDate);
     let counter = 0;
     let countermobile = 0;
    return (
      <main className="oh-template Brandmainpage">
        <CommonHeader propertySlug={this.state.property_slug} CheckIn={this.props.CheckIn}/>
        <div className="container-fluid p-0">
          <section className="reservationBook req_book_sec bgwhitecolor trips_main_sec">
            <div className="container">
              <div className="content">
                
                <div className="row mobView">
                {this.state.headerToken ? 
                  <div className="reservation-data-mob">
                    <div className="back_go">
                      <a href="/reservation-list">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                      </a>
                      <h4 className="back_arrow">
                        {/* My Trips */}
                        Trips
                      </h4>
                    </div>
                  </div>: 
                   <div className="reservation-data-mob">
                  <div className="back_go">
                      <a href="/reservation">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                      </a>
                      <h4 className="back_arrow">
                        {/* My Trips */}
                        Trips
                      </h4>
                    </div></div>}
                    {this.state.currentData.guest_user && this.state.currentData.guest_user.email
                                  ?  (
                    <div className="col-sm-12 col-lg-12 headingreserv tripsdetailsmob">
                      <div className="requestSection confirm_booking pd-b-0">
                        <div className="row book_app_info confirm-symbl mg-l-0 mg-r-0 pd-t-5 pd-b-5 align-items-center">
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} />
                          <div className="confirm_text">
                            <h4>Your arrival info is below!</h4>
                            {/* <h4>Your reservation is confirmed! </h4>
                            <p>We emailed the details to {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.email:""} </p> */}

                            {/* <h4> Reservation Confirmed </h4>
                          <p>Thank You! Your reservation has been successfully created. </p> */}
                          </div>
                        </div>
                      </div>
                    </div> ):""}
                  <div className="col-sm-12 col-lg-6 reservdetail deskviewresver">
                    <div className="requestSection confirm_booking">
                    {this.state.headerToken ? 
                      <div className="reservation-data-desk">
                        <div className="back_go">
                          <h4 className="back_arrow">
                            <a href="/reservation-list">
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                            </a>
                            {/* My Trips */}
                            Trips
                          </h4>
                        </div>
                      </div>:
                       <div className="reservation-data-desk">
                      <div className="back_go">
                          <h4 className="back_arrow">
                          <a href="/reservation">
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                            </a>
                            {/* My Trips */}
                            Trips
                          </h4>
                        </div></div>}

                     <div className="col-sm-12 col-lg-12 headingreserv tripsdetailsdesk">
                     {this.state.currentData.guest_user && this.state.currentData.guest_user.email
                                  ?  (
                      <div className="requestSection confirm_booking">
                        <div className="row book_app_info confirm-symbl mg-l-0 mg-r-0 mg-b-0 align-items-center">
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} />
                          <div className="confirm_text">
                            <h4>Your arrival info is below!</h4>
                            {/* <h4>Your reservation is confirmed! </h4>
                            <p>We emailed the details to {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.email:""} </p> */}
                            {/* <h4> Reservation Confirmed </h4>
                          <p>Thank You! Your reservation has been successfully created. </p> */}
                          </div>
                        </div>
                      </div>):""}
                      {/* {this.state.currentData.parking_slot ? (
                        <div class="col-6">
                          <label>Parking space</label>
                          <p>
                            {this.state.currentData.parking_slot
                              ? this.state.currentData.parking_slot
                              : "-"}
                          </p>
                        </div>) : ""} */}
                    </div>
                      <div className="reservation-info">
                        <div className="reservSection addressdeskview">
                          <div className="reserv_mid_heading">
                            <h5>Address</h5>
                          </div>
                          <div className="row">

                            <div className="col-6 col-md-6">
                              <strong>Getting there</strong>
                              <span className="mg-b-20 pd-b-5 d-block">
                                {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_1) ? this.state.currentData.property.street_1 + ", " : ""}
                                {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_2) ? this.state.currentData.property.street_2 + ", " : ""}
                                {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.city) ? this.state.currentData.property.city.city_name + ", " : "-"}
                                {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.state) ? this.state.currentData.property.state.state_name + ", " : ""}
                                {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.country) ? this.state.currentData.property.country.country_name + "." : ""}
                              </span>
                            <div className="unitmobileview2">
                              <strong>Unit</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.unit_number
                                      ? this.state.currentData.unit_number
                                      : this.state.currentData.unit_name}
                                  </span>
                              </div>
                              <a href=
                                  {this.state.currentData
                                    ? this.state.currentData.property
                                      ? this.state.currentData.property.google_map_link
                                      : "-"
                                    : "-"} className="map_text getdirection" target="_blank">
                                  {/* {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_1) ? this.state.currentData.property.street_1 + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_2) ? this.state.currentData.property.street_2 + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.city) ? this.state.currentData.property.city.city_name + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.state) ? this.state.currentData.property.state.state_name + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.country) ? this.state.currentData.property.country.country_name + "." : ""} */}
                                  <img src={global.OnKeyPressEvent.getS3ImageUrl('pin.svg')} /> Get directions
                                </a>
                            </div>
                            <div className="col-6 col-md-6 unitsectionmob">
                                  <strong>Unit</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.unit_number
                                      ? this.state.currentData.unit_number
                                      : this.state.currentData.unit_name}
                                  </span>
                                </div>
                          </div>
                        </div>
                      {this.state.is_form_submited == 1 ? (
                        <>
                        <div className="requestSection reqestmobview arrivalinst">
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                            <div className="cancellations">
                              <span className="pd-t-20 pd-b-20 labelTitle">Arrival instructions</span>
                                              <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.currentData
                                    ? this.state.currentData.property.arrival_instruction:"",
                                  }}
                                ></p>
                            </div>
                            {this.state.parking_payment == 1 ? (
                            <div className="cancellations">
                              <span className="pd-t-20 pd-b-20 labelTitle">Parking instructions</span>
                                              <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.currentData
                                    ? this.state.currentData.property.parking_instructions:"",
                                  }}
                                ></p>

                            <div className="cancellations car_color_type border-0">
                              <span className="pd-t-20 pd-b-0 labelTitle position-relative">Car type and color <span class="edit_phone">
                                <img src="https://did1d8jg9ar6w.cloudfront.net/website/images/edit-icon.svg" alt=""  onClick={this.editableCar} />
                              </span>  
                              </span>
                                  <p>{this.state.carcolor ? this.state.carcolor:""}</p>
                                  <div className="col-xs-12 col-sm-12 col-md-12 pl-cust-two-0 mg-b-15 wzrd_phone">
                                  {this.state.editableCar === true ? (
                                    <>
                                  <div class="form-group">
                                    <input 
                                    type="text" 
                                    class="form-control" 
                                    value={this.state.carcolor ? this.state.carcolor : ""}
                                    onChange={this.changeHandlerCar}
                                    id="carcolor"
                                    name="carcolor"
                                    autoComplete="off"
                                    />
                                  </div>
                                  <a href="" className="map_text mg-t-15 mg-b-15 getdirection d-inline-block" onClick={(e) => this.onSubmitCar(e)}>Save</a>
                                  </>) : ""}
                                  </div>
                                  
                            </div>
                             
                            </div>):
                            <>
                            {this.state.currentData.property_parking_fee ?(
                              <div className="cancellations">
                              <span className="pd-t-20 labelTitle">Parking instructions</span>
                                              <span className="pd-t-20 pd-b-20 labelTitle">Would you like to access parking instructions for  ${parkingFeedisplay}/night?</span>
                                              <a href=
                                    {parking_url} className="map_text mg-b-30 getdirection d-inline-block">
                                   Pay to park
                                  </a>
                            </div>):""}
                            </>
                            }

                            

                            <div className="cancellations-checkoutinstruction cancellations">
                              <span className="pd-t-20 pd-b-20 labelTitle">Check-out instructions</span>
                                              <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.currentData
                                    ? this.state.currentData.property.checkout_instructions:"",
                                  }}
                                ></p>
                            </div>
                            </div>
                          </div>
                        </div>
                        <div className="reservSection thingsknowmobview">
                            <div className="row book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                              <span className="pd-t-20 pd-b-15 labelTitle">House Manual</span>
                              <div className="d-flex flex-wrap">
                          {this.state.currentData.wi_fi_name ? (
                              <div className="col-6 col-md-6">
                                <strong>Wifi name</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.wi_fi_name
                                    ? this.state.currentData.wi_fi_name
                                    : "-"}
                                </span>
                              </div>) : ""}

                            {this.state.currentData.wi_fi_password ? (
                              <div className="col-6 col-md-6">
                                <strong>Wifi password</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.wi_fi_password
                                    ? this.state.currentData.wi_fi_password
                                    : "-"}
                                </span>
                              </div>) : ""} 
                              </div>
                            <div className="housemanulsection">
                              <div className="col-md-12 sectionone">
                                {this.state.currentData
                                  && this.state.currentData.property?.guest_manual ? (
                                  <strong>House manual</strong>) : ""}

                                  <span className="mg-b-10 d-block">
                                  <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.currentData
                                        ? this.state.currentData.property?.guest_manual
                                        : ""
                                    }
                                    maxLine="3"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className="span_text mg-b-20"
                                  />

                                  {this.state.currentData.property && this.state.currentData.property.guest_manual ? (
                                  <b
                                    className="getdirection showmore mg-t-30"
                                    onClick={() =>
                                      this.openGuestmanualModal(true)
                                    }
                                  >
                                    Show more
                                  </b>
                                ) : (
                                  ""
                                )}
                                </span>
                              

                              </div>
                              <div className="col-md-12 sectionone">
                                {this.state.currentData
                                  && this.state.currentData.property?.property_rules_new ? (
                                  <strong>House rules</strong>) : ""}

                                  <span className="mg-b-10 d-block">
                                  <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.currentData
                                        ? this.state.currentData.property?.property_rules_new
                                        : ""
                                    }
                                    maxLine="3"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className="span_text mg-b-20 formatter"
                                  />
                                  
                                  {this.state.currentData.property && this.state.currentData.property.property_rules_new ? (
                                  <b
                                    className="getdirection showmore mg-t-30"
                                    onClick={() =>
                                      this.openHouserulesModal(true)
                                    }
                                  >
                                    Show more
                                  </b>
                                ) : (
                                  ""
                                )}
                                </span>
                              

                              </div>
                            </div>  
                            </div>
                            </div>
                           
                          </div>
                        </div>

                          {/* Faqs-section-desktop         */}
                                    {this.state.totalRecordsfaq > 0 ? (
                          <div className="requestSection mg-t-30 pb-0 doitlocal">
                              <div className="row book_app_info cancellations-">
                                <div className="col-12">
                                  <div className="cancellations reservation_faq">
                                    <span className="pd-t-20 pd-b-0 labelTitle">Frequently asked questions</span>
                            {
                           this.state.listfaq.map(
                             (value, index) => {
                              if (
                                counter <= 2
                              ) {
                              return(<>
                                          <div className="reser_faq_box"> <span className="d-none">{counter++}</span>
                                      {/* <h5>{value.title}</h5> */}
                                      {/* <p>{value.description}</p> */}
                                      <div className="reser_faq_edit"
                                  dangerouslySetInnerHTML={{
                                    __html: value.description,
                                  }}
                                ></div>
                                    </div></>)}})}
                                  {this.state.totalRecordsfaq > 2 ?(
                                    <div className="d-flex">
                                      <b
                                        className="getdirection showmore mg-t-30"
                                        onClick={() =>
                                          this.openResFaqModal(true)
                                        }
                                      >
                                        Show more
                                      </b>
                                    </div>):""}

                                  </div>
                                </div>
                              </div>
                            </div> ):""}




                        <div className="requestSection mg-t-20 doitlocal">
                            <div className="row book_app_info cancellations-">
                              <div className="col-12">
                              <div className="cancellations">
                                <span className="pd-t-20 pd-b-30 labelTitle">Do it like a local</span>
                                  <Swiper
                                      slidesPerView={1}
                                      spaceBetween={15}
                                      slidesPerGroup={1}
                                      loop={true}
                                      loopFillGroupWithBlank={true}
                                      centeredSlides={false}
                                      pagination={{
                                        clickable: true,
                                      }}
                                      autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                      // navigation={true}
                                      modules={[Navigation, Pagination, Autoplay]}
                                      className="mySwiper m-0"

                                      breakpoints={{
                                        "@0.00": {
                                          slidesPerView: 1,
                                          spaceBetween: 10,
                                        },
                                        "@0.75": {
                                          slidesPerView: 1,
                                          spaceBetween: 10,
                                        },
                                        "@1.00": {
                                          slidesPerView: 2,
                                          spaceBetween: 10,
                                        },
                                        "@1.50": {
                                          slidesPerView: 2,
                                          spaceBetween: 15,
                                        },
                                      }}
                                    >
                            {this.state.neighborhoodlist?.type_list &&
                           this.state.neighborhoodlist.type_list.map(
                            (value, index) => {
                              return(
                                <>
                              <SwiperSlide>
                                <>
                            <div className="col-md-12 col-12">
                                  <div className="do_like_local_box">
                                    <a href={value.map ? value.map :""} className="local_box" target="_blank">
                                      <div className="local_box_new">
                                        <div className="local_box_img">
                                          <figure>
                                           <img src={value.image ? value.image:""} alt="" title="" />
                                          </figure>
                                        </div>
                                        <h5 className="loacl_one">{value.attraction_type_name ? value.attraction_type_name:""}</h5>
                                        <h4 className="loacl_two">{value.tag_line ? value.tag_line:""}</h4>
                                        <p>{value.description ? value.description:""}</p>
                                          <div className="do_live_footer">
                                      <a href ={value.map ? value.map:""}className="local_box" target="_blank">
                                      <p>Open collections on the map</p></a>
                                    </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                </>
                                </SwiperSlide></>)})}
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                         </div>
                         {this.state.getting_around ? (
                        <div className="requestSection gettingaroundmobview mg-t-5">
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                              <span className="pd-t-20 pd-b-5 labelTitle">Getting around</span>
                              <p className="mg-b-30">
                              <HTMLEllipsis
                            unsafeHTML={
                              this.state.getting_around
                                ? this.state.getting_around
                                : ""
                            }
                            maxLine="4"
                            ellipsis=""
                            trimright="true"
                            basedOn="letters"
                            className="span_text"
                          />
                          {this.state.getting_around ? (
                            <b
                              onClick={() => this.openGettingAroundModal(true)}
                              className="getdirection showmore "
                            >
                              Show more
                            </b>
                          ) : (
                            ""
                          )}
                              </p>
                                
                            </div>
                            </div>
                          </div>
                        </div>):""}
                        </>):(
                          <>
                          <div className="requestSection reqestmobview arrivalinst pb-0">
                            {this.state.currentData.status_id == 1?(
                              <>
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                            <div className="reserv_mid_heading confirm-symbl d-block">
                            <h5 className="mg-b-20 mt-0">Access your reservation</h5>
                            <a href=
                                    {checkin_url} className="map_text getdirection">
                                    Access arrival information
                                  </a>
                              </div></div></div></>):""}</div>
                              </>
                        )}
                       
                        <div className="requestSection host mg-t-25">
                          <div className="reserv_mid_heading mg-t-0 pd-t-25">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                              <div className="d-flex flex-wrap flex-column mg-lg-t-15 guestBlock">
                                <h5>Hosted by {this.state.currentData && this.state.currentData.host_name ? this.state.currentData.host_name: this.state.currentData.host_name_property}</h5>
                                {/* <div className="hostContent">
                                  <b>Joined in Nov 16th, 22</b>
                                </div> */}
                              </div>
                              <figure><img src={this.state.currentData && this.state.currentData.host_photo_medium  ? this.state.currentData.host_photo_medium:this.state.currentData?.host_photo_property_medium} alt="Host image" title="Host image" />
                              </figure>
                            </div>
                            <p></p>
                          </div>
                        </div>
                        <div className="requestSection getrecieptmobview mg-t-5">
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                                <ul className="receipt">
                                {this.state.ParkingReceipt ? (
                                <li>
                                    <a href={this.state.ParkingReceipt} target="_blank"><div className="icon1"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Print details" /> Get parking receipt </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>):""}
                                  {this.state.WavioReceipt ? (
                                <li>
                                    <a href={this.state.WavioReceipt} target="_blank"><div className="icon1"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Print details" /> Get waiver receipt </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>):""}
                                {this.state.currentData.set_channel === 1 ?(
                                  <li>
                                    <a href={'/get-receipt'} target="_blank"><div className="icon1"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Print details" /> Get receipt </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>):""}
                                  <li>
                                    <a href={listing_url} target="_blank"><div className="icon2"><img src={global.OnKeyPressEvent.getS3ImageUrl('gotolisting.svg')}  className="viewlist" alt="Get receipt" /> Go to listing </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>
                                  {/* <li>
                                    <a href="/support-form" target="_blank"><div className="icon2"><img src={global.OnKeyPressEvent.getS3ImageUrl('need-help.svg')}  className="viewlist" alt="Get receipt" /> Need help</div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                     
                       

                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-1 col-xl-1"></div>
                  <div className="col-sm-12 col-lg-5 col-xl-5 position-relative priceInfo deskviewresver">
                    <div className="requestaddedSection m-0 confirm_recpt">
                      <figure>
                        <img src={this.state.currentData.file_name ? this.state.currentData.file_name : this.state.currentData.property?.property_main_image_url} alt={this.state.currentData.listing_name} title={this.state.currentData.listing_name} className="img-fluid" />
                      </figure>

                      <div className="priceInfo">
                        <div className="priceTop">
                          <h5>
                            {this.state.currentData.listing_name
                              ? this.state.currentData.listing_name
                              : ""}
                          </h5>
                        </div>
                        <div className="requestSection confirm_booking">
                          <div className="reservation-info">
                            <div className="reservSection checkdatemob">
                              <ul>
                                <li>
                                  <strong>Check-in</strong>
                                  <span className="checkdate-  d-block">
                                    {this.state.currentData.checkin_date
                                      ? Moment(
                                        this.state.currentData.checkin_date
                                      ).format("MM/DD/YY")
                                      : "-"} | {this.state.currentData
                                        ? this.state.currentData.property?.check_in_time
                                        : ""}
                                  </span>

                                </li>
                                <li>
                                  <strong>Check-out</strong>
                                  <span className="checkdate-  d-block">
                                    {this.state.currentData.checkout_date
                                      ? Moment(
                                        this.state.currentData.checkout_date
                                      ).format("MM/DD/YY")
                                      : "-"} | {this.state.currentData
                                        ? this.state.currentData.property?.check_out_time
                                        : ""}
                                  </span>

                                </li>
                              </ul>
                            </div>
                            <div className="reservSection addressmobilview">
                            <div className="reserv_mid_heading">
                              <h5>Address</h5>
                            </div>
                            <div className="row">

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <strong>Getting there</strong>
                                <span className="mg-b-10 pd-b-10 d-block gettingmobileview">
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_1) ? this.state.currentData.property.street_1 + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_2) ? this.state.currentData.property.street_2 + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.city) ? this.state.currentData.property.city.city_name + ", " : "-"}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.state) ? this.state.currentData.property.state.state_name + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.country) ? this.state.currentData.property.country.country_name + "." : ""}
                                </span>
                              <div className="unitmobileview2">
                                      <strong>Unit</strong>
                                      <span className="mg-b-10 d-block">
                                        {this.state.currentData.unit_number
                                          ? this.state.currentData.unit_number
                                          : this.state.currentData.unit_name}
                                      </span>
                              </div>
                                <a href=
                                    {this.state.currentData
                                      ? this.state.currentData.property
                                        ? this.state.currentData.property.google_map_link
                                        : "-"
                                      : "-"} className="map_text getdirection" target="_blank">
                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('pin.svg')} /> Get direction
                                  </a>
                              </div>
                              <div className="col-md-6 unitsectionmob">
                                    <strong>Unit</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.unit_number
                                        ? this.state.currentData.unit_number
                                        : this.state.currentData.unit_name}
                                    </span>
                                  </div>
                            </div>
                        </div>
                            <div className="reservSection">
                              <div className="reserv_mid_heading">
                                <h5>Reservation details</h5>
                              </div>
                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <strong>Confirmation code</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.confirmation_code
                                      ? this.state.currentData.confirmation_code
                                      : "-"}
                                  </span>
                                </div>
                                {this.state.currentData.booked_at ? (
                                  <div className="col-6">
                                    <strong>Booking date</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.booked_at
                                        ? Moment(this.state.currentData.booked_at).format(
                                          global.dateFormat
                                        )
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                <div className="col-6 col-md-6">
                                  <strong>Status</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.status_text
                                      ? this.state.currentData.status_text
                                      : "Pending"}
                                  </span>
                                </div>
                                {this.state.currentData.number_of_guest &&
                                  this.state.currentData.number_of_guest !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>No. of guests</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.number_of_guest &&
                                        this.state.currentData.number_of_guest !== null
                                        ? this.state.currentData.number_of_guest
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.adults &&
                                  this.state.currentData.adults !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Adults</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.adults &&
                                        this.state.currentData.adults !== null
                                        ? this.state.currentData.adults
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.children &&
                                  this.state.currentData.children !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Children</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.children &&
                                        this.state.currentData.children !== null
                                        ? this.state.currentData.children
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.infants &&
                                  this.state.currentData.infants !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Infants</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.infants &&
                                        this.state.currentData.infants !== null
                                        ? this.state.currentData.infants
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.pets &&
                                  this.state.currentData.pets !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Pets</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.pets &&
                                        this.state.currentData.pets !== null
                                        ? this.state.currentData.pets
                                        : "-"}
                                    </span>
                                  </div>) : ""}

                                <div className="col-6 col-md-6">
                                  <strong>Channel</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.set_channel
                                      ? this.state.set_channel
                                      : "Website"}
                                  </span>
                                </div>

                              </div>
                            </div>
                            <div className="reservSection">
                          <div className="reserv_mid_heading">
                            <h5>Guest details</h5>
                          </div>
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <strong>First name</strong>
                              <span className="mg-b-10 d-block">
                                {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.first_name
                                  : "-"}
                              </span>
                            </div>
                            {this.state.currentData.guest_user?.last_name ? (
                              <div className="col-6">
                                <strong>Last name</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user?.last_name
                                    : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.phone_number !== "undefined" && this.state.currentData.guest_user.phone_number !== "" ? (
                              <div className="col-6 col-md-6">
                                <strong>Mobile</strong>
                                <span className="mg-b-10 d-block">
                                  <PhoneNumberFormat
                                    phone_number={
                                      this.state.currentData.guest_user.phone_number
                                    }
                                  />
                                </span>
                              </div>) : ""}
                              {this.state.currentData.guest_user && this.state.currentData.guest_user.email !== "undefined" && this.state.currentData.guest_user.email !== "" ? (
                            <div className="col-6 col-md-6">
                              <strong>Email</strong>
                              <span className="mg-b-10 d-block">
                                {this.state.currentData.guest_user ? this.state.currentData.guest_user.email : ""}
                              </span>
                            </div>) :<div className="col-6 col-md-6">
                              <strong>Email</strong>
                              <span className="mg-b-10 d-block">
                                {this.state.currentData.email ? this.state.currentData.email : ""}
                              </span>
                            </div>}
                            {/* <div className="col-6 col-md-6">
                              <strong>Address</strong>
                              <span className="mg-b-10 d-block">

                              </span>
                            </div> */}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.city && this.state.currentData.guest_user.city.city_name ? (
                              <div className="col-6 col-md-6">
                                <strong>City</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user ?
                                    this.state.currentData.guest_user.city ? this.state.currentData.guest_user.city.city_name
                                      : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.state && this.state.currentData.guest_user.state.state_name ? (
                              <div className="col-6 col-md-6">
                                <strong>State</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user ? this.state.currentData.guest_user.state
                                    ? this.state.currentData.guest_user.state.state_name
                                    : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user?.county ? (
                              <div className="col-6 col-md-6">
                                <strong>County</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.county ? this.state.currentData.guest_user.county.county_name
                                      : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user?.country ? (
                              <div className="col-6 col-md-6">
                                <strong>Country</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.country ? this.state.currentData.guest_user.country.country_name
                                      : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.zip_code ? (
                              <div className="col-6 col-md-6">
                                <strong>Zipcode</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.zip_code
                                    : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user?.passport ? (
                              <div className="col-6">
                                <strong>Passport</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.passport
                                      ? this.state.currentData.guest_user.passport
                                      : "-"
                                    : "-"}
                                </span>
                              </div>) : ""}

                          </div>
                        </div>
                          </div>
                         
                        </div>

                        <div className="priceMiddle">
                          <span className="mg-b-20 d-block">Price details</span>
                          <ul>
                            <li>
                              <span>{this.state.currentData.total_night_text}</span>
                              <span>{`${this.state.currentData.total_nightly_rate
                                ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_nightly_rate).toFixed(2)).replace(/\.00$/, ""))
                                :global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_nightly_rate_disp).toFixed(2)).replace(/\.00$/, ""))
                                }`}</span>
                            </li>

                            {this.state.currentData.cleaning_fee !== "0.00" &&
                              this.state.currentData.cleaning_fee > 0 ? (
                              <li>
                                <span>Cleaning fee</span>
                                <span>
                                  {this.state.currentData.cleaning_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}
                            

                            {/* {this.state.currentData.convenience_fee !== "0.00" &&
                              this.state.currentData.convenience_fee > 0 ? (
                              <li>
                                <span>Service fee</span>
                                <span>
                                  {this.state.currentData.convenience_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.convenience_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )} */}
                            {this.state.currentData.guest_service_fee !== "0.00" &&
                              this.state.currentData.guest_service_fee > 0 ? (
                              <li>
                                <span>Service fee</span>
                                <span>
                                  {this.state.currentData.guest_service_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.guest_service_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}
                            {this.state.currentData.total_taxes !== "0.00" &&
                              this.state.currentData.total_taxes > 0 ? (
                              <li>
                                <span>Occupancy taxes</span>
                                <span>
                                  {this.state.currentData.total_taxes
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}

                          </ul>
                        </div>
                        <div className="priceBottom">
                          <ul>
                            <li>
                              <span>Total (USD)</span>
                              <b>
                                <span>
                                  {this.state.currentData.total_payment
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_payment).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </b>
                            </li>
                          </ul>
                        </div>
                      </div>

                      
                    </div>
                  </div>

        {/* mobile view */}
            <div className="newsectionchangemobilereservdetail">
                  <div className="col-sm-12 col-lg-5 col-xl-5 position-relative priceInfomainone">
                    <div className="requestaddedSection m-0 confirm_recpt">
                      <figure>
                        <img src={this.state.currentData.file_name ? this.state.currentData.file_name :  this.state.currentData.property?.property_main_image_url} alt={this.state.currentData.listing_name} title={this.state.currentData.listing_name} className="img-fluid" />
                      </figure>

                      <div className="priceInfo">
                        <div className="priceTop">
                          <h5>
                            {this.state.currentData.listing_name
                              ? this.state.currentData.listing_name
                              : ""}
                          </h5>
                        </div>
                        <div className="requestSection confirm_booking">
                          <div className="reservation-info">
                            <div className="reservSection checkdatemob">
                              <ul>
                                <li>
                                  <strong>Check-in</strong>
                                  <span className="checkdate-  d-block">
                                    {this.state.currentData.checkin_date
                                      ? Moment(
                                        this.state.currentData.checkin_date
                                      ).format("MM/DD/YY")
                                      : "-"} | {this.state.currentData
                                        ? this.state.currentData.property?.check_in_time
                                        : ""}
                                  </span>

                                </li>
                                <li>
                                  <strong>Check-out</strong>
                                  <span className="checkdate-  d-block">
                                    {this.state.currentData.checkout_date
                                      ? Moment(
                                        this.state.currentData.checkout_date
                                      ).format("MM/DD/YY")
                                      : "-"} | {this.state.currentData
                                        ? this.state.currentData.property?.check_out_time
                                        : ""}
                                  </span>

                                </li>
                              </ul>
                            </div>
                            <div className="reservSection addressmobilview">
                            <div className="reserv_mid_heading">
                              <h5>Address</h5>
                            </div>
                            <div className="row m-0">

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <strong>Getting there</strong>
                                <span className="mg-b-10 pd-b-10 d-block gettingmobileview">
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_1) ? this.state.currentData.property.street_1 + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.street_2) ? this.state.currentData.property.street_2 + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.city) ? this.state.currentData.property.city.city_name + ", " : "-"}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.state) ? this.state.currentData.property.state.state_name + ", " : ""}
                                  {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.country) ? this.state.currentData.property.country.country_name + "." : ""}
                                </span>
                              <div className="unitmobileview2">
                                      <strong>Unit</strong>
                                      <span className="mg-b-10 d-block">
                                        {this.state.currentData.unit_number
                                          ? this.state.currentData.unit_number
                                          : this.state.currentData.unit_name}
                                      </span>
                              </div>
                                <a href=
                                    {this.state.currentData
                                      ? this.state.currentData.property
                                        ? this.state.currentData.property.google_map_link
                                        : "-"
                                      : "-"} className="map_text getdirection" target="_blank">
                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('pin.svg')} /> Get direction
                                  </a>
                              </div>
                              <div className="col-md-6 unitsectionmob">
                                    <strong>Unit</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.unit_number
                                        ? this.state.currentData.unit_number
                                        : this.state.currentData.unit_name}
                                    </span>
                              </div>
                            </div>
                        </div>
                       </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 reservdetail newsectionchangemobilearrival">
                    <div className="requestSection confirm_booking">
                      <div className="reservation-info">
                      {this.state.is_form_submited == 1 ?   (
                        <div>
                      <div className="requestSection reqestmobview arrivalinst">
                          <div className="row m-0 book_app_info cancellations-">
                            <div className="col-12">
                            <div className="cancellations">
                              <span className="pd-t-20 pd-b-20 labelTitle">Arrival instructions</span>
                                              <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.currentData
                                    ? this.state.currentData.property.arrival_instruction:"",
                                  }}
                                ></p>
                            </div>
                            </div>
                          </div>
                        </div>

                        <div className="requestSection reqestmobview arrivalinst">
                          <div className="row m-0 book_app_info cancellations-">
                            <div className="col-12">
                                    {this.state.parking_payment == 1 ? (
                            <div className="cancellations">
                              <span className="pd-t-20 pd-b-20 labelTitle">Parking instructions</span>
                                              <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.currentData
                                    ? this.state.currentData.property.parking_instructions:"",
                                  }}
                                ></p>
                                <div className="cancellations car_color_type border-0">
                              <span className="pd-t-20 pd-b-0 labelTitle position-relative">Car type and color <span class="edit_phone">
                                <img src="https://did1d8jg9ar6w.cloudfront.net/website/images/edit-icon.svg" alt=""  onClick={this.editableCar} />
                              </span>  
                              </span>
                                  <p>{this.state.carcolor ? this.state.carcolor:""}</p>
                                  <div className="col-xs-12 col-sm-12 col-md-12 pl-cust-two-0 mg-b-15 wzrd_phone">
                                  {this.state.editableCar === true ? (
                                    <>
                                  <div class="form-group">
                                    <input 
                                    type="text" 
                                    class="form-control" 
                                    value={this.state.carcolor ? this.state.carcolor : ""}
                                    onChange={this.changeHandlerCar}
                                    id="carcolor"
                                    name="carcolor"
                                    autoComplete="off"
                                    />
                                  </div>
                                  <a href="" className="map_text mg-t-15 mg-b-15 getdirection d-inline-block" onClick={(e) => this.onSubmitCar(e)}>Save</a>
                                  </>) : ""}
                                  </div>
                                  
                            </div>
                            </div>
                            ):
                                  <>
                                  {this.state.currentData.property_parking_fee ?(
                                    <div className="cancellations">
                                    <span className="pd-t-20 pd-b-10 labelTitle">Parking instructions</span>
                                                    <span className="pd-t-15 pd-b-20 labelTitle">Would you like to access parking instructions for  ${parkingFeedisplay}/night?</span>
                                                    <a href=
                                          {parking_url} className="map_text getdirection d-inline-block">
                                         Pay to park
                                        </a>
                                  </div>):""}
                                  </>
                            
                                }
                            </div>
                          </div>
                        </div>

                        <div className="requestSection reqestmobview arrivalinst">
                          <div className="row m-0 book_app_info cancellations-">
                            <div className="col-12">
                            <div className="cancellations-checkoutinstruction">
                              <span className="pd-t-20 pd-b-15 labelTitle">Check-out instructions</span>
                                              <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.currentData
                                    ? this.state.currentData.property.checkout_instructions:"",
                                  }}
                                ></p>
                            </div>
                            </div>
                          </div>
                        </div>













                        <div className="reservSection thingsknowmobview">
                            <div className="row m-0 book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                              <span className="pd-t-20 pd-b-15 labelTitle">House Manual</span>
                              <div className="d-flex flex-wrap">
                          {this.state.currentData.wi_fi_name ? (
                              <div className="col-6 col-md-6">
                                <strong>Wifi name</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.wi_fi_name
                                    ? this.state.currentData.wi_fi_name
                                    : "-"}
                                </span>
                              </div>) : ""}

                            {this.state.currentData.wi_fi_password ? (
                              <div className="col-6 col-md-6">
                                <strong>Wifi password</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.wi_fi_password
                                    ? this.state.currentData.wi_fi_password
                                    : "-"}
                                </span>
                              </div>) : ""} 
                              </div>
                            <div className="housemanulsection">
                              <div className="col-md-12 sectionone">
                                {this.state.currentData
                                  && this.state.currentData.property?.guest_manual ? (
                                  <strong>House manual</strong>) : ""}

                                  <span className="mg-b-10 d-block">
                                  <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.currentData
                                        ? this.state.currentData.property?.guest_manual
                                        : ""
                                    }
                                    maxLine="3"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className="span_text mg-b-20"
                                  />

                                  {this.state.currentData.property && this.state.currentData.property.guest_manual ? (
                                  <b
                                    className="getdirection showmore mg-t-30"
                                    onClick={() =>
                                      this.openGuestmanualModal(true)
                                    }
                                  >
                                    Show more
                                  </b>
                                ) : (
                                  ""
                                )}
                                </span>
                              

                              </div>
                              <div className="col-md-12 sectionone">
                                {this.state.currentData
                                  && this.state.currentData.property?.property_rules_new ? (
                                  <strong>House rules</strong>) : ""}

                                  <span className="mg-b-10 d-block">
                                  <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.currentData
                                        ? this.state.currentData.property?.property_rules_new
                                        : ""
                                    }
                                    maxLine="3"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className="span_text mg-b-20 formatter"
                                  />
                                  
                                  {this.state.currentData.property && this.state.currentData.property.property_rules_new ? (
                                  <b
                                    className="getdirection showmore mg-t-30"
                                    onClick={() =>
                                      this.openHouserulesModal(true)
                                    }
                                  >
                                    Show more
                                  </b>
                                ) : (
                                  ""
                                )}
                                </span>
                              

                              </div>
                            </div>  
                            </div>
                            </div>
                           
                          </div>
                        </div>


                            {/* faqs-section-mobile       */}
                            {this.state.totalRecordsfaq > 0 ? (
                            <div className="requestSection mg-t-30 pb-0 doitlocal">
                                <div className="row book_app_info cancellations-">
                                  <div className="col-12">
                                    <div className="cancellations reservation_faq">
                                      <span className="pd-t-20 pd-b-0 labelTitle">Frequently asked questions</span>
                                        {this.state.listfaq.map(
                            (value, index) => {
                              if (
                                countermobile <= 2
                              ) {
                              return(<>
                                     <div className="reser_faq_box"> <span className="d-none">{countermobile++}</span>
                                      {/* <h5>{value.title}</h5> */}
                                      {/* <p>{value.description}</p> */}
                                      <div className="reser_faq_edit"
                                  dangerouslySetInnerHTML={{
                                    __html: value.description,
                                  }}
                                ></div>
                                    </div></>)}})}
                                  {this.state.totalRecordsfaq > 2 ?(
                                    <div className="d-flex">
                                      <b
                                        className="getdirection showmore mg-t-30"
                                        onClick={() =>
                                          this.openResFaqModal(true)
                                        }
                                      >
                                        Show more
                                      </b>
                                    </div>):""}
                                    </div>
                                  </div>
                                </div>
                              </div>):""}     


                        <div className="requestSection mg-t-30 doitlocal">
                            <div className="row m-0 book_app_info cancellations-">
                              <div className="col-12">
                              <div className="cancellations">
                                <span className="pd-t-20 pd-b-30 labelTitle">Do it like a local</span>
                                  <Swiper
                                      slidesPerView={1}
                                      spaceBetween={15}
                                      slidesPerGroup={1}
                                      loop={true}
                                      loopFillGroupWithBlank={true}
                                      centeredSlides={false}
                                      pagination={{
                                        clickable: true,
                                      }}
                                      autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                      // navigation={true}
                                      modules={[Navigation, Pagination, Autoplay]}
                                      className="mySwiper m-0"

                                      breakpoints={{
                                        "@0.00": {
                                          slidesPerView: 1,
                                          spaceBetween: 10,
                                        },
                                        "@0.75": {
                                          slidesPerView: 1,
                                          spaceBetween: 10,
                                        },
                                        "@1.00": {
                                          slidesPerView: 2,
                                          spaceBetween: 10,
                                        },
                                        "@1.50": {
                                          slidesPerView: 2,
                                          spaceBetween: 15,
                                        },
                                      }}
                                    >
                            {this.state.neighborhoodlist?.type_list &&
                           this.state.neighborhoodlist.type_list.map(
                            (value, index) => {
                              return(
                                <>
                              <SwiperSlide>
                                <>
                            <div className="col-md-12 col-12">
                                  <div className="do_like_local_box">
                                    <a href={value.map ? value.map :""} className="local_box" target="_blank">
                                      <div className="local_box_new">
                                        <div className="local_box_img">
                                          <figure>
                                           <img src={value.image ? value.image:""} alt="" title="" />
                                          </figure>
                                        </div>
                                        <h5 className="loacl_one">{value.attraction_type_name ? value.attraction_type_name:""}</h5>
                                        <h4 className="loacl_two">{value.tag_line ? value.tag_line:""}</h4>
                                        <p>{value.description ? value.description:""}</p>
                                          <div className="do_live_footer">
                                      <a href ={value.map ? value.map:""}className="local_box" target="_blank">
                                      <p>Open collections on the map</p></a>
                                    </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                </>
                                </SwiperSlide></>)})}
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                         </div></div>):(
                          <>
                          <div className="requestSection reqestmobview arrivalinst pb-0">
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                            <div className="reserv_mid_heading confirm-symbl d-block pt-0 mg-b-cust-24 border-0 mt-0">
                            <h5 className="mg-b-20">Access your reservation</h5>
                            <a href=
                                    {checkin_url} className="map_text getdirection">
                                    Access arrival information
                                  </a>
                              </div></div></div></div>
                              </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-5 col-xl-5 position-relative priceInfomain">
                    <div className="requestaddedSection m-0 confirm_recpt">
                     

                      <div className="priceInfo">
                        
                        <div className="requestSection confirm_booking">
                          <div className="reservation-info">
                            <div className="reservSection">
                              <div className="reserv_mid_heading">
                                <h5>Reservation details</h5>
                              </div>
                              <div className="row m-0">
                                <div className="col-6 col-md-6">
                                  <strong>Confirmation code</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.confirmation_code
                                      ? this.state.currentData.confirmation_code
                                      : "-"}
                                  </span>
                                </div>
                                {this.state.currentData.booked_at ? (
                                  <div className="col-6">
                                    <strong>Booking date</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.booked_at
                                        ? Moment(this.state.currentData.booked_at).format(
                                          global.dateFormat
                                        )
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                <div className="col-6 col-md-6">
                                  <strong>Status</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.currentData.status_text
                                      ? this.state.currentData.status_text
                                      : "Pending"}
                                  </span>
                                </div>
                                {this.state.currentData.number_of_guest &&
                                  this.state.currentData.number_of_guest !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>No. of guests</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.number_of_guest &&
                                        this.state.currentData.number_of_guest !== null
                                        ? this.state.currentData.number_of_guest
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.adults &&
                                  this.state.currentData.adults !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Adults</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.adults &&
                                        this.state.currentData.adults !== null
                                        ? this.state.currentData.adults
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.children &&
                                  this.state.currentData.children !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Children</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.children &&
                                        this.state.currentData.children !== null
                                        ? this.state.currentData.children
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.infants &&
                                  this.state.currentData.infants !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Infants</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.infants &&
                                        this.state.currentData.infants !== null
                                        ? this.state.currentData.infants
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                {this.state.currentData.pets &&
                                  this.state.currentData.pets !== null ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Pets</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.currentData.pets &&
                                        this.state.currentData.pets !== null
                                        ? this.state.currentData.pets
                                        : "-"}
                                    </span>
                                  </div>) : ""}

                                <div className="col-6 col-md-6">
                                  <strong>Channel</strong>
                                  <span className="mg-b-10 d-block">
                                    {this.state.set_channel
                                      ? this.state.set_channel
                                      : "Website"}
                                  </span>
                                </div>

                              </div>
                            </div>
                            <div className="reservSection">
                          <div className="reserv_mid_heading">
                            <h5>Guest details</h5>
                          </div>
                          <div className="row m-0">
                            <div className="col-6 col-md-6">
                              <strong>First name</strong>
                              <span className="mg-b-10 d-block">
                                {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.first_name
                                  : "-"}
                              </span>
                            </div>
                            {this.state.currentData.guest_user?.last_name ? (
                              <div className="col-6">
                                <strong>Last name</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user?.last_name
                                    : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.phone_number !== "undefined" && this.state.currentData.guest_user.phone_number !== "" ? (
                              <div className="col-6 col-md-6">
                                <strong>Mobile</strong>
                                <span className="mg-b-10 d-block">
                                  <PhoneNumberFormat
                                    phone_number={
                                      this.state.currentData.guest_user.phone_number
                                    }
                                  />
                                </span>
                              </div>) : ""}
                              {this.state.currentData.guest_user && this.state.currentData.guest_user.email !== "undefined" && this.state.currentData.guest_user.email !== "" ? (
                            <div className="col-6 col-md-6">
                              <strong>Email</strong>
                              <span className="mg-b-10 d-block">
                                {this.state.currentData.guest_user ? this.state.currentData.guest_user.email : ""}
                              </span>
                            </div>) : <div className="col-6 col-md-6">
                              <strong>Email</strong>
                              <span className="mg-b-10 d-block">
                                {this.state.currentData.email ? this.state.currentData.email : ""}
                              </span>
                            </div>}
                            {/* <div className="col-6 col-md-6">
                              <strong>Address</strong>
                              <span className="mg-b-10 d-block">

                              </span>
                            </div> */}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.city && this.state.currentData.guest_user.city.city_name ? (
                              <div className="col-6 col-md-6">
                                <strong>City</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user ?
                                    this.state.currentData.guest_user.city ? this.state.currentData.guest_user.city.city_name
                                      : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.state && this.state.currentData.guest_user.state.state_name ? (
                              <div className="col-6 col-md-6">
                                <strong>State</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user ? this.state.currentData.guest_user.state
                                    ? this.state.currentData.guest_user.state.state_name
                                    : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user?.county ? (
                              <div className="col-6 col-md-6">
                                <strong>County</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.county ? this.state.currentData.guest_user.county.county_name
                                      : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user?.country ? (
                              <div className="col-6 col-md-6">
                                <strong>Country</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.country ? this.state.currentData.guest_user.country.country_name
                                      : "-" : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user && this.state.currentData.guest_user.zip_code ? (
                              <div className="col-6 col-md-6">
                                <strong>Zipcode</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.zip_code
                                    : "-"}
                                </span>
                              </div>) : ""}
                            {this.state.currentData.guest_user?.passport ? (
                              <div className="col-6">
                                <strong>Passport</strong>
                                <span className="mg-b-10 d-block">
                                  {this.state.currentData.guest_user
                                    ? this.state.currentData.guest_user.passport
                                      ? this.state.currentData.guest_user.passport
                                      : "-"
                                    : "-"}
                                </span>
                              </div>) : ""}

                          </div>
                        </div>
                          </div>
                         
                        </div>

                        <div className="priceMiddle">
                          <span className="mg-b-20 d-block">Price details</span>
                          <ul>
                            <li>
                              <span>{this.state.currentData.total_night_text}</span>
                              <span>{`${this.state.currentData.total_nightly_rate
                                ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_nightly_rate).toFixed(2)).replace(/\.00$/, ""))
                                :global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_nightly_rate_disp).toFixed(2)).replace(/\.00$/, ""))
                                }`}</span>
                            </li>

                            {this.state.currentData.cleaning_fee !== "0.00" &&
                              this.state.currentData.cleaning_fee > 0 ? (
                              <li>
                                <span>Cleaning fee</span>
                                <span>
                                  {this.state.currentData.cleaning_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.cleaning_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}
                            

                            {/* {this.state.currentData.convenience_fee !== "0.00" &&
                              this.state.currentData.convenience_fee > 0 ? (
                              <li>
                                <span>Service Fee</span>
                                <span>
                                  {this.state.currentData.convenience_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.convenience_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )} */}
                            {this.state.currentData.guest_service_fee !== "0.00" &&
                              this.state.currentData.guest_service_fee > 0 ? (
                              <li>
                                <span>Service fee</span>
                                <span>
                                  {this.state.currentData.guest_service_fee
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.guest_service_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}
                            {this.state.currentData.total_taxes !== "0.00" &&
                              this.state.currentData.total_taxes > 0 ? (
                              <li>
                                <span>Occupancy taxes</span>
                                <span>
                                  {this.state.currentData.total_taxes
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_taxes).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}

                          </ul>
                        </div>
                        <div className="priceBottom">
                          <ul>
                            <li>
                              <span>Total (USD)</span>
                              <b>
                                <span>
                                  {this.state.currentData.total_payment
                                    ? "$" + global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData.total_payment).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}
                                </span>
                              </b>
                            </li>
                          </ul>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 reservdetail newsectionchangemobilearrival">
                    <div className="requestSection confirm_booking">
                      <div className="reservation-info">
                      {this.state.getting_around ? (
                        <div className="requestSection gettingaroundmobview mg-t-5">
                          <div className="row m-0 book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                              <span className="pd-t-20 pd-b-5 labelTitle">Getting around</span>
                              <p className="mg-b-30">
                              <HTMLEllipsis
                            unsafeHTML={
                              this.state.getting_around
                                ? this.state.getting_around
                                : ""
                            }
                            maxLine="4"
                            ellipsis=""
                            trimright="true"
                            basedOn="letters"
                            className="span_text"
                          />
                          {this.state.getting_around ? (
                            <b
                              onClick={() => this.openGettingAroundModal(true)}
                              className="getdirection showmore "
                            >
                              Show more
                            </b>
                          ) : (
                            ""
                          )}
                              </p>
                                
                            </div>
                            </div>
                          </div>
                        </div>):""}
                       
                        <div className="requestSection host mg-t-25">
                          <div className="reserv_mid_heading mg-t-0 pd-t-25">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                              <div className="d-flex flex-wrap flex-column mg-lg-t-15 guestBlock">
                                <h5>Hosted by {this.state.currentData && this.state.currentData.host_name ? this.state.currentData.host_name:this.state.currentData?.host_name_property}</h5>
                              </div>
                              <figure><img src={this.state.currentData && this.state.currentData.host_photo_medium ? this.state.currentData.host_photo_medium:this.state.currentData?.host_photo_property_medium} alt="Host image" title="Host image" />
                              </figure>
                            </div>
                            <p></p>
                          </div>
                        </div>
                        <div className="requestSection getrecieptmobview mg-t-5">
                          <div className="row m-0 book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                                <ul className="receipt">
                                {this.state.ParkingReceipt  ? (
                                <li>
                                    <a href={this.state.ParkingReceipt} target="_blank"><div className="icon1"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Print details" /> Get parking receipt </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>):""}
                                  {this.state.WavioReceipt ? (
                                <li>
                                    <a href={this.state.WavioReceipt} target="_blank"><div className="icon1"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Print details" /> Get waiver receipt </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>):""}
                                {this.state.currentData.set_channel === 1 ?(
                                  <li>
                                    <a href={'/get-receipt'} target="_blank"><div className="icon1"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Print details" /> Get receipt </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>):""}
                                  <li>
                                    <a href={listing_url} target="_blank"><div className="icon2"><img src={global.OnKeyPressEvent.getS3ImageUrl('gotolisting.svg')}  className="viewlist" alt="Get receipt" /> Go to listing </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>
                                  {/* <li>
                                    <a href="/support-form" target="_blank"><div className="icon2"><img src={global.OnKeyPressEvent.getS3ImageUrl('need-help.svg')}  className="viewlist" alt="Get receipt" /> Need help</div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                       </div>
                     </div>
                  </div>
             </div>
          {/* end mobile view */}
                </div>
              </div>
            </div>
            <Modal
              show={this.state.openArrivalinstructionModal}
              centered
              className="unitmodal"
              onHide={() => this.openArrivalinstructionModal()}
            >
              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                  <span
                    className="mobilewelcomeClose float-end"
                    onClick={() => this.openArrivalinstructionModal(false)}
                  >
                    <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="House manual" title="House manual" />
                  </span>
                  <div className="title">Arrival instructions</div>
                  <span
                    className="welcomeClose float-end"
                    onClick={() => this.openArrivalinstructionModal(false)}
                  >
                    {global.closee}
                  </span>
                </div>
                <div className="pd-l-20 pd-r-20 mg-t-10">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.arrival_instruction,
                    }}
                  ></p>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.openGuestmanualModal}
              centered
              className="unitmodal"
              onHide={() => this.openGuestmanualModal()}
            >
              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                  <span
                    className="mobilewelcomeClose float-end"
                    onClick={() => this.openGuestmanualModal(false)}
                  >
                    <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="House manual" title="House manual" />
                  </span>
                  <div className="title">House manual</div>
                  <span
                    className="welcomeClose float-end"
                    onClick={() => this.openGuestmanualModal(false)}
                  >
                    {global.closee}
                  </span>
                </div>
                <div className="pd-l-20 pd-r-20 mg-t-10">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.currentData
                        ? this.state.currentData.property?.guest_manual
                        : "",
                    }}
                  ></p>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.openHouserulesModal}
              centered
              className="unitmodal"
              onHide={() => this.openHouserulesModal()}
            >
              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                  <span
                    className="mobilewelcomeClose float-end"
                    onClick={() => this.openHouserulesModal(false)}
                  >
                    <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="House rules" title="House rules" />
                  </span>
                  <div className="title">House rules</div>
                  <span
                    className="welcomeClose float-end"
                    onClick={() => this.openHouserulesModal(false)}
                  >
                    {global.closee}
                  </span>
                </div>
                <div className="pd-l-20 pd-r-20 mg-t-10">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.currentData
                        ? this.state.currentData.property?.property_rules
                        : "",
                    }}
                  ></p>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.cancelModal}
              centered
              onHide={() => this.setState({ cancelModal: false })}
              className="reservationDetailmodalbox"
            >
              <span
                className="welcomeClose"
                onClick={() => this.setState({ cancelModal: false })}
              >
                {global.closee}
              </span>

              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="reservationDetails">
                  <h5 className="mb-0">Are you sure want to cancel?</h5>
                  <h6>Cancelation policy for your listing</h6>
                  <ul>
                    <li>To receive a full refund, you must cancel within 48 hours of booking, and the cancelation must occur at least 14 days before check-in</li>
                    <li>If you cancel between 7 and 14 days before check-in, you’ll be paid 50% for all nights</li>
                    <li>If you cancel after that, you’ll be paid 100% for all nights</li>
                  </ul>
                  <h5 className="mb-4">Your total refund amount is ${this.state.cancel_amount}</h5>
                  <div className="d-flex d-flex  justify-content-center align-items-start">
                    <button
                      onClick={() =>
                        this.cancelReservation(true, this.state.currentData)
                      }
                      className="mg-r-15"
                    >
                      Yes, cancel
                    </button>
                    <button
                      onClick={() =>
                        this.cancelModal(false, this.state.currentData)
                      }
                    >
                      No
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
                  show={this.state.openGettingAroundModal}
                  centered
                  className="unitmodal"
                  onHide={() => this.openGettingAroundModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openGettingAroundModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Getting around" title="Getting around" />
                      </span>
                      <div className="title">Getting around</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openGettingAroundModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="pd-l-20 pd-r-20 mg-t-10">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.getting_around
                            ? this.state.getting_around
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>


                <Modal
              show={this.state.openResFaqModal}
              centered
              scrollable={true}
              className="unitmodal large_model p-0"
              onHide={() => this.openResFaqModal()}
            >
              <Modal.Body className="width_402 p-4 border_radius_14 pt-0">
                <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile model_top_fix">
                  <span
                    className="mobilewelcomeClose float-end"
                    onClick={() => this.openResFaqModal(false)}
                  >
                    <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="House rules" title="House rules" />
                  </span>
                  <div className="title">Frequently asked questions</div>
                  <span
                    className="welcomeClose float-end"
                    onClick={() => this.openResFaqModal(false)}
                  >
                    {global.closee}
                  </span>
                </div>
                <div className="">
                  <div className="cancellations reservation_faq border-0">
                    {this.state.totalRecordsfaq &&
                           this.state.listfaq.map(
                            (value, index) => {
                              return(<>
                    <div className="reser_faq_box">
                    {/* <h5>{value.title}</h5> */}
                                      {/* <p>{value.description}</p> */}
                                      <div className="reser_faq_edit"
                                  dangerouslySetInnerHTML={{
                                    __html: value.description,
                                  }}
                                ></div>
                                    </div></>)})}

                  </div>
                </div>
              </Modal.Body>
            </Modal>        


          </section>
        </div >
      </main >
    );
  }
}
export default ReservationDetails;
