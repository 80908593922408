import React, { useState } from "react";
import "./gallery.css";
// import GalleryView from "react-grid-gallery";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { Skeleton } from "@mui/material";

const Gallery = (props) => {
  let images = props.media_files;
  const [model, setModel] = useState(props.modelSet ? props.modelSet : false);
  const [loaded, setLoaded] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState(props.setTempImgSrc ? props.setTempImgSrc : "");
  const [currentImageNo, setCurrentImageNo] = useState((props.setCurrentImageNo || props.setCurrentImageNo === 0) ? props.setCurrentImageNo : null);
  const getImg = (imgSrc, no) => {
    setTempImgSrc(imgSrc);
    setModel(true);
    setCurrentImageNo(no);
  };

  const setModalClose = () => {
    setModel(false);
    // setTimeout(() => {
    //   document.getElementById('gallary-img-'+currentImageNo).focus()
    // }, 5000);
    const section = document.querySelector( '#gallary-img-'+currentImageNo);
    section.scrollIntoView( { behavior: 'auto', block: 'start' } );
  }
  // console.log(model,currentImageNo,'gallary-img-'+ currentImageNo);
  console.log("loaded",loaded,"tempImgSrc",tempImgSrc,"currentImageNo",currentImageNo,"model",model,"images",images[currentImageNo])
  return (
    <>
      <main className="oh-template w-80">
        <figure className="mg-auto m-auto text-center showmoreimgModalbox ">
          <div className="d-flex align-items-center justify-content-between p-t-4 pl-5 pr-5 p-b-4 galleryHead">
            <h3>Gallery</h3>
            <span
              className="welcomeClose float-end"
              onClick={() => props.closeFun()}
            >
              {global.closee}
            </span>
          </div>

          {model === true ? (
            <div className={model ? "model open" : "model"}>
              <Lightbox
                mainSrc={images[currentImageNo].large_img_w_1200}
                mainSrcThumbnail={images[currentImageNo].thumb_img}
                nextSrc={images[(currentImageNo + 1) % images.length].large_img_w_1200}
                nextSrcThumbnail={images[(currentImageNo + 1) % images.length].thumb_img}
                prevSrc={images[(currentImageNo + images.length - 1) % images.length].large_img_w_1200}
                prevSrcThumbnail={images[(currentImageNo + images.length - 1) % images.length].thumb_img}
                onCloseRequest={props.backToMain ? () => props.closeFun() : () => setModalClose(false)}
                onMovePrevRequest={() =>
                  setCurrentImageNo((currentImageNo + images.length - 1) % images.length)
                }
                onMoveNextRequest={() =>
                  setCurrentImageNo((currentImageNo + 1) % images.length)
                }
                animationDisabled={false}
                animationOnKeyInput={true}
                //imageCaption={images[currentImageNo].caption}
                // imageTitle={images[currentImageNo].display_file_name}
                clickOutsideToClose={false}
                wrapperClassName={""}
                nextLabel={images[(currentImageNo + 1) % images.length].caption}
                prevLabel={images[(currentImageNo + images.length - 1) % images.length].caption}
              />
              <span
                className="welcomeClose float-end svg"
                onClick={() => setModel(false)}
              >
                {" "}
              </span>
            </div>
          ) : (
            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8 m-auto">
              <div className="gallery">
                {images.map((image, index) => {
                  return (
                    <div
                      className="pics"
                      key={index}
                      onClick={() => getImg(image.src, index)}
                    >
                      {loaded ? null : (
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={250}
                        />
                      )}
                      <img
                        style={loaded ? { display:"flex", width: "100%",transition: "opacity .4s, visibility .4s ease,transform .5s ease-in-out" } : { display: "none" }}
                        src={image.thumbnail}
                        onLoadedData={() => setLoaded(true)}
                        id={"gallary-img-"+index}
                        // onBlur={() => setLoaded(false)}
                        onLoad={index === (images.length - 1) ? () => setLoaded(true) : ""}
                        alt=""
                        title=""
                      />
                      {/* <img src={image.thumbnail} style={{ width: "100%" }} /> */}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* <div className={model ? "model open" : "model"}> */}
          {/* <img src={tempImgSrc} />
            <span
              className="welcomeClose float-end svg"
              onClick={() => setModel(false)}
            >
              {global.closee}
            </span> */}

          {/* </div> */}
        </figure>
      </main>
    </>
  );
};

export default Gallery;
