//     							                          //
//  Program: help.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
        personName: [],
        date: new Date('2014-08-18T21:11:54'),
        selectedDate: new Date(),
        type: false,
        value: undefined,
        inputValue: undefined,
        input: {},
        errors: {},
        slug_help : props.match &&  props.match.params ? (props.match.params.slug ? props.match.params.slug : "") : "",
    }
  }

  componentDidMount() {
    this.getHelpData();
  }

  getHelpData = async () => {
    this.setState({ showSpinner: true, loading: true });
    const helpslug = this.state.slug_help;
    let resData=[];
    let res = await CmsDataService.gethelpData(helpslug);
    if (global.successStatus.includes(res.status)) {
      resData.title = res.data?.title;
      resData.description = res.data?.description;
    }
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true,
      title : resData.title ,
      description:resData.description ,
    });
  }


  render() {
    return (
      <main className="oh-template Brandmainpage-">
        {/* <CommonHeader propertySlug={this.state.property_slug} /> */}
        <section className="middle">
          <div className="container onboardingpagestyle">
            <section className="reservationBook ">
              <h1 className="mb-5">{this.state.title}</h1>
            </section>
            <section >
                                      
              <p
                                      className="cust_text_left"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                         this.state.description ? this.state.description : "",
                                      }}
              ></p>
            </section>
          </div>
        </section>
        {/* <CommonFooter propertySlug={this.state.property_slug} /> */}
      </main>
    );
  }
}
export default Help;