import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import PhoneInput from "react-phone-input-2";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import Moment from "moment";
export class FormWizards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: 'https://d1xsfycladxeij.cloudfront.net/user/profile/thumb/oh_636490df77cd2636490df77cd3.jpg',
      file_input: null,
      is_uploaded: false,
      input: {},
      email_error: "",
      exist_img: '',
      isLoaded: false,
      editablePhone:false,
      currentData: localStorage.getItem("trip_details")
      ? JSON.parse(localStorage.getItem("trip_details"))
      : [],
      email:props.email ? props.email:"",
    };
    this.ClickFileUpload = this.ClickFileUpload.bind(this)
    this.SingleFileUpload = this.SingleFileUpload.bind(this)
    this.RemoveProfileImage = this.RemoveProfileImage.bind(this)
    this.editablePhone = this.editablePhone.bind(this);
  }


  ClickFileUpload(el) {
    document.getElementById('img_eventTarget').click()
  }
  editablePhone =() => {
    this.setState({editablePhone:true})
  }

  SingleFileUpload = (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({
        file: URL.createObjectURL(event.target.files[0]),
        file_input: event.target.files[0],
        is_uploaded: true,
        isLoaded: true
      })
      setTimeout(() => {
        console.log(this.state.file)
      }, 200)
    } else {
      this.setState({
        file: this.state.exist_img,
        file_input: null
      })
    }

  };
  changeHandler = (event) => {
    let input = this.state.input;
    console.log(this.state.input);
    let value = event.target.value;

    if (event.target.name === "email") {
      if (event.target.value.length > 8) {
        this.setState({ email_error: "" });
      }
    }

    if (event.target.name === "email") {
      if (event.target.value.length > 7) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex.test(event.target.value) === false) {
          var email_error = "Please enter valid email.";
          this.setState({ email_error: email_error });
        } else {
          var email_error = "";
          this.setState({ email_error: email_error });
        }
      }
    }
    input[event.target.name] = value;
    this.setState({[event.target.name]: value, input: input,email:value});
  };
  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      this.setState({ email_error: "Please enter valid email." });
    } else {
      this.setState({ email_error: "" });
    }
    console.log("validate");
  }
  RemoveProfileImage = () => {
    this.setState({
      file: this.state.exist_img,
      file_input: null,
      is_uploaded: false
    })
  }
  render() {
    const step1Content = <>

<div className="container-fluid p-0">
    <section className="wzrd_main_section">
      <div className="container">
        <div className="content">
          <div className="row mobView wizard_new_heading">
            <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
              <div className="wzrd_heading_box col-12 ">
                <p className="welcom_text_wzrd"><strong>Hi {this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.first_name
                                  : "-"},</strong> please complete the following steps to check-in.</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
              <div className="wzrd_heading_box col-12 m-0">
                <div className="wizardInfo">
                  <h5>{this.state.currentData.listing_name
                              ? this.state.currentData.listing_name
                              : ""}</h5>
                  <p className="prop_wzd_loc"><img src="https://d1xsfycladxeij.cloudfront.net/website/images/pin.svg" /> {this.state.currentData && (this.state.currentData.property && this.state.currentData.property.state) ? this.state.currentData.property.state.state_name + ", " : ""}{this.state.currentData && (this.state.currentData.property && this.state.currentData.property.country) ? this.state.currentData.property.country.country_name + "." : ""}</p>
                </div>
                <div class="wizard_timing">
                  <ul>
                    <li><strong>Check-in</strong><span class="checkdate-  d-block">    {this.state.currentData.checkin_date
                                      ? Moment(
                                        this.state.currentData.checkin_date
                                      ).format("MM/DD/YY")
                                      : "-"} | {this.state.currentData
                                        ? this.state.currentData.property?.check_in_time
                                        : ""}</span></li>
                    <li><strong>Check-out</strong><span class="checkdate-  d-block"> {this.state.currentData.checkout_date
                                  ? Moment(
                                    this.state.currentData.checkout_date
                                  ).format("MM/DD/YY")
                                  : "-"} | {this.state.currentData.property
                                    ? this.state.currentData.property.check_out_time
                                    : ""}</span></li>
                  </ul>
                </div>
                {/* <div className="wzrd_mid_heading">
                  <h5>Reservation details</h5>
                  <div class="wizard_timing">
                    <ul className="resr_detl_list">
                      <li><strong>Confirmation code</strong><span class="checkdate-  d-block"> {this.state.currentData.confirmation_code
                                  ? this.state.currentData.confirmation_code
                                  : "-"}</span></li>
                      <li><strong>No. of guests</strong><span class="checkdate-  d-block">{this.state.currentData.number_of_guest &&
                                    this.state.currentData.number_of_guest !== null
                                    ? this.state.currentData.number_of_guest
                                    : "-"}</span></li>
                    </ul>
                  </div>
                </div> */}
                <div className="wzrd_mid_heading">
                    <h5>Guest details</h5>
                    <div class="wizard_timing">
                      <ul className="full_len">
                        <li className="w-100"><strong>Name</strong><span class="checkdate-  d-block">{this.state.currentData.guest_user
                                  ? this.state.currentData.guest_user.first_name
                                  : "-"}</span></li>
                        <li className="w-100"><strong>Mobile</strong><span class="checkdate-  d-block"> <PhoneNumberFormat
                                    phone_number={
                                      this.state.currentData.guest_user.phone_number
                                    }
                                  />
                          {/* <span class="edit_phone">
                            <img src="https://d1xsfycladxeij.cloudfront.net/website/images/edit-icon.svg" alt="" onClick={this.editablePhone}/>
                          </span> */}
                        </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="wzrd_mid_heading border-0 m-0">
                    {/* {console.log(this.state.editablePhone)}
                  {this.state.editablePhone === true ? (  
                    <div className="col-xs-6 col-sm-6 col-md-6 mg-b-10 p-0 wzrd_phone">
                      <PhoneInput
                        className=" textfield phoneInput"
                        variant="outlined"
                        autoFormat="true"
                        enableSearch="true"
                        countryCodeEditable="true"
                        id="phone_number"
                        name="phone_number"
                        // disabled
                        placeholder="Phone number"
                        fullWidth
                      />
                    </div>
                    ) : ""} */}
                    <div className="col-xs-6 col-sm-6 col-md-6 mg-b-10 p-0 wzrd_phone">
                      <div className="countryphone">
                        <TextField
                          margin="normal"
                          className=" textfield w-100 m-0"
                          required
                          fullWidth
                          value= {this.state.email? this.state.email:this.state.currentData.guest_user.email}
                          onBlur={() => this.validateEmail()}
                          onChange={this.changeHandler}
                          id="email"
                          label="Email"
                          name="email"
                          autoComplete="off"
                          error={
                            this.state.email_error
                              ? true
                              : false
                          }
                        // autoFocus
                        />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</>;
    return (
      

      <>
        <StepProgressBar
          startingStep={0}
          primaryBtnClass='btn_next_wzd'
          secondaryBtnClass='btn_pre_wzd'
          progressClass='waz_pipe'
          stepClass='waz_pipe_stp'
          wrapperClass='pipe_new'
          className='stepColor'
          onSubmit={this.onFormSubmit}
          buttonWrapperClass='step_btns'
          steps={[

            {
              content:step1Content
            },
            {
              content: step2Content
              // validator: step2Validator
            },
            {
              content: step3Content
              // validator: step3Validator
            },
            {
              content: step4Content
              // validator: step3Validator
            },


            {
              content: (<>

                <div className="container-fluid p-0">
                  <section className="wzrd_main_section">
                    <div className="container">
                      <div className="content">
                        <div className="row mobView wizard_new_heading">
                          <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                            <div className="wzrd_heading_box col-12 m-0">
                              <h4>Identity verification</h4>
                            </div>
                          </div>

                          <div className="wzrd_middle_desk">

                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                              <div className="wzrd_heading_box col-12 mb-0">
                                <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                                  <div className="wzrd_mid_heading border-0 m-0">
                                    <div className="col-xs-6 col-sm-6 col-md-6 ">
                                      <div className="countryphone">
                                        <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label">Issuing country</InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                          >
                                            <MenuItem value={10}>1</MenuItem>
                                            <MenuItem value={20}>2</MenuItem>
                                            <MenuItem value={30}>3</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                  <p> <b>Upload images of your identification</b> </p>
                                  <ul>
                                    <li>
                                      <p>Make sure your photos aren't blurry and the front of your driver's license clearly shows your face.</p>
                                    </li>
                                    <li>
                                      <p>Please provide a selfie in which you hold your government ID net to your face.</p>
                                    </li>
                                    <li>
                                      <p>The purpose of this requirement is so that we can confirm the person who booked our home is in face you!</p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                              <div className="row wzrd_heading_box m-0">

                                <div className="col-lg-4 col-sm-6  col-6">
                                  <div className="upd_frnt_img">
                                    <img src="https://d1xsfycladxeij.cloudfront.net/website/images/front-icon.svg" alt="" />
                                    <p>Upload front</p>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-6">
                                  <div className="upd_frnt_img">
                                    <img src="https://d1xsfycladxeij.cloudfront.net/website/images/back-icon.svg" alt="" />
                                    <p>Upload back</p>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-sm-6  col-xs-6  offset-xs-3">
                                  <div className="upd_frnt_img upd_frnt_img_sec">
                                    <img src="https://d1xsfycladxeij.cloudfront.net/website/images/selfie.svg" alt="" />
                                    <p>Upload selfie</p>
                                  </div>
                                </div>

                                {/* <div className="col-12 text-center">
                                <div className="avatar-upload">
                                  <div className="avatar-edit">                                    
                                    <div className="avatar-preview">                                    
                                    <div onClick={this.ClickFileUpload}>
                                    <img src={this.state.file} alt="" />
                                    {this.state.is_uploaded ? (<b className='btn_close_profile' onClick={this.RemoveProfileImage}>
                                      <i className="fa fa-close">x</i></b>) : ''}
                                    <input type='file' id="img_eventTarget" accept=".png, .jpg, .jpeg" onChange={this.SingleFileUpload} className="vs-hiddne"/>
                                    <label htmlFor="profile_image"></label>
                                    </div>
                                  </div>                                    
                                  </div>                                  
                                </div>
                              </div> */}

                              </div>
                            </div>
                          </div>



                        </div>
                      </div>
                    </div>
                  </section>
                </div>

              </>)
              // validator: step3Validator
            },

          ]}
        />
      </>


    )
  }
}

const step1Content = <>

  <div className="container-fluid p-0">
    <section className="wzrd_main_section">
      <div className="container">
        <div className="content">
          <div className="row mobView wizard_new_heading">
            <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
              <div className="wzrd_heading_box col-12 ">
                <p className="welcom_text_wzrd"><strong>Hi Peter,</strong> please complete the following steps to check-in.</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
              <div className="wzrd_heading_box col-12 m-0">
                <div className="wizardInfo">
                  <h5>Kini | List 01</h5>
                  <p className="prop_wzd_loc"><img src="https://d1xsfycladxeij.cloudfront.net/website/images/pin.svg" /> Temp, United States</p>
                </div>
                <div class="wizard_timing">
                  <ul>
                    <li><strong>Check-in</strong><span class="checkdate-  d-block">04/11/23 | 07:00 AM</span></li>
                    <li><strong>Check-out</strong><span class="checkdate-  d-block">04/19/23 | 07:00 PM</span></li>
                  </ul>
                </div>
                <div className="wzrd_mid_heading">
                  <h5>Reservation details</h5>
                  <div class="wizard_timing">
                    <ul className="resr_detl_list">
                      <li><strong>Confirmation code</strong><span class="checkdate-  d-block">6ND0AZSDDD</span></li>
                      <li><strong>No. of guests</strong><span class="checkdate-  d-block">04/19/23 | 07:00 PM</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</>;

const step2Content =
  <>
    <div className="container-fluid p-0">
      <section className="wzrd_main_section">
        <div className="container">
          <div className="content">
            <div className="row mobView wizard_new_heading">
              <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
                <div className="wzrd_heading_box col-12 mb-0">
                  <div className="wizardInfo">
                    <h5>Kini | List 01</h5>
                    <p className="prop_wzd_loc"><img src="https://d1xsfycladxeij.cloudfront.net/website/images/pin.svg" /> Temp, United States</p>
                  </div>
                  <div class="wizard_timing">
                    <ul>
                      <li><strong>Check-in</strong><span class="checkdate-  d-block">04/11/23 | 07:00 AM</span></li>
                      <li><strong>Check-out</strong><span class="checkdate-  d-block">04/19/23 | 07:00 PM</span></li>
                    </ul>
                  </div>
                  <div className="wzrd_mid_heading">
                    <h5>Guest details</h5>
                    <div class="wizard_timing">
                      <ul className="full_len">
                        <li className="w-100"><strong>Name</strong><span class="checkdate-  d-block">Peter</span></li>
                        <li className="w-100"><strong>Mobile</strong><span class="checkdate-  d-block">+1(777) 788-8887
                          <span class="edit_phone">
                            <img src="https://d1xsfycladxeij.cloudfront.net/website/images/edit-icon.svg" alt="" />
                          </span>
                        </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="wzrd_mid_heading border-0 m-0">
                    <div className="col-xs-6 col-sm-6 col-md-6 mg-b-10 p-0 wzrd_phone">
                      <PhoneInput
                        className=" textfield phoneInput"
                        variant="outlined"
                        autoFormat="true"
                        enableSearch="true"
                        countryCodeEditable="true"
                        id="phone_number"
                        name="phone_number"
                        placeholder="Phone number"
                        fullWidth
                      />
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 mg-b-10 p-0">
                      <div className="countryphone">
                        <TextField
                          margin="normal"
                          className=" textfield w-100 m-0"
                          required
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          autoComplete="off"
                        // autoFocus
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>;

const step3Content =
  <>
    <div className="container-fluid p-0">
      <section className="wzrd_main_section">
        <div className="container">
          <div className="content">
            <div className="row mobView wizard_new_heading">
              <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                <div className="wzrd_heading_box col-12 m-0">
                  <h4>House rules</h4>
                </div>
              </div>

              <div className="wzrd_middle_desk">
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0 checkbox_valid">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>When entering the property, you agree to abide by all building, platform, management, and local rules. You agree to be a great guest.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree to be a great guest"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree to NO SMOKING and to bring No Weapons of any kind.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree to NO PARTIES or events</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree to NO TAMPERING of smoke detector. I acknowledge that this is illegal and will result in a $1,500 fine.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree to NO OUTSIDE GUESTS. Only guests added to my reservation will be on the property.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree that the minimum guest age is 22, unless children are accompanied by parents.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I acknowledge that only TWO bath towels per bed, per stay is provided.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree to close all windows, AC, and doors (and make sure locked and returned) at the end of my stay</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree and acknowledge that the LOST KEY or misplacement of the apartment key, key fobs, and any other item on the key chain will result in a $500 fine.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree to tidy up after myself and leave the apartment in a clean condition, and that is why the cleaning fee is low.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-10 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I agree to be a clean guest, and I understand that I am responsible for damages to the property inside of the apartment. I understand that the cleaner before my arrival has checked all of the items in the room, and so damages inside of the room during my stay are my responsibility. I agree that there is a cost associated with damaging items, and that damaged items need to replaced.
                        The following price sheet includes some, but not all, prices for replacement items in the apartment:
                        Sheets: All sheets come in a pack with a fitted sheet, a flat sheet, and 4 pillow cases. If there are stains or damages to one part of a sheet set, the whole sheet set needs to be replaced at $110 for the items + 7.70 tax = 137.70
                        Quilts: 53.00 for the item + 3.71 tax =  56.71
                        Bath Towels: $5.00 for the item + .35 tax = 5.35
                        All replacement items will have a $20.00 service charge to order and replace the items.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>I acknowledge that if the apartment is left is a condition that will cause the cleaning team to spend extra time cleaning, that I am responsible for paying for the extra cleaning time.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-0">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>House Rules
                        I agree to NO PETS and that unapproved and/or unregistered guests will result in a $500 additional cleaning fee.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                  <div className="wzrd_heading_box col-12 mb-5">
                    <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard">
                      <span className="checkbox_valid_star">*</span>
                      <p>Additional House Rules
                        Smoking is not allowed in the unit and/or the building.
                        Smoking is not allowed in any balconies.
                        Agree to check-in and check-out times.
                        Agree to leave the apartment in a clean condition in order to not incur higher cleaning fees.
                        Agree to only two towels per bedroom, per stay.
                        Agree that there are are charges for replacement of any damages to the property, decor, or furnishes.</p>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox />
                          }
                          labelPlacement="end"
                          label="Yes, I agree"
                          required={true}
                          name="is_notification"
                          className="wizard_check"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    </div>
  </>;

const step4Content = <>
  <div className="container-fluid p-0">
    <section className="wzrd_main_section">
      <div className="container">
        <div className="content">
          <div className="row mobView wizard_new_heading ">
          <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                <div className="wzrd_heading_box col-12 m-0">
                  <h4>Email verification</h4>
                </div>
              </div>              


            <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
              <div className="wzrd_heading_box col-12 ">
                <p className="welcom_text_wzrd">Enter your verification code sent on your demo@gmail.com</p>
                <div className="col-xs-12 col-sm-12 col-md-6 ">
                      <div className="wzd_otp_sec">
                        <div className="otp_input">
                          <input type="text" />
                        </div>
                        <div className="otp_input">
                          <input type="text" />
                        </div>
                        <div className="otp_input">
                          <input type="text" />
                        </div>
                        <div className="otp_input">
                          <input type="text" />
                        </div>
                        <div className="otp_input">
                          <input type="text" />
                        </div>
                      </div>
                    </div>
              
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </section>
  </div>
</>;


export default FormWizards
