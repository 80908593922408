////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AdAgencyService.js                        //
//  Application: Third party                           //
//  Option: Used for manage add agency                 //
//  Developer: NP           						               //
//  Date: 2021-01-11                                   //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";



class FloorPlanApi {

  getFloorPlanlisting(data, queryString) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-availability-of-floorplan"+ (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getBedroomBathroomlisting(slug) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-bedroom-bath/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getFurnishedData() {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-master-data?is_dropdown=1&title=furnished";
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getUnitlisting(data) {
    let endPoint = "get-availability-of-units";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new FloorPlanApi();
