//     							                          //
//  Program: Footer.jsx                                   //
//  Application: KINI HTML                                //
//  Option: Footer                                        //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Form from "react-bootstrap/Form";
import CmsDataService from "../../services/CmsDataService";
import ApplicantsService from "../../services/ApplicantsService";
import LiveChat from "./LiveChat";

class CommonFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      brand_slug: global.brandSlug,
      property_slug: props.propertySlug ? props.propertySlug : "",
      input: {},
      first_name: "",
      last_name: "",
      email: "",
      loading: false,
      showSpinner: false,
      errorCount: 0,
      confirm_back: 0,
      status_id: true,
      succes_message: "",
      loader: false,
      errors: {},
      errors: {
        email: "",
        first_name: "",
        last_name: "",
      },
      isOneFooter: this.props.isOneFooter ? true : false,
	 	  webview: props.webview ? props.webview : 1,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.saveNewsletter = this.saveNewsletter.bind(this);
    this.getIp = this.getIp.bind(this);
  }

  componentDidMount() {
    this.getCorporateFooterCmsData();
    // this.getIp();
  }
  getIp = async (e) => {
    let response = await fetch('https://geolocation-db.com/json/');
    let data = await response.json();
    this.setState({ ip: data.IPv4 });
  }

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      let errors = this.state.errors;
      errors.email = "Please enter valid email."
      this.setState({ errors: errors });
    } else {
      let errors = this.state.errors;
      errors.email = ""
      this.setState({ errors: errors });
    }
  }

  changeHandler = (event) => {
    // let input = this.state.input;
    // let value = event.target.value;
    // input[event.target.name] = value;
    // this.setState({ [event.target.name]: value, input: input });
    let input = this.state.input;
    let value = event.target.value;
    if (event.target.name === "first_name") {
      const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      }
      else {
        errors.first_name = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "last_name") {
      const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.last_name = "Please enter valid last name.";
          this.setState({ errors: errors });
        } else {
          errors.last_name = "";
          this.setState({ errors: errors });
        }
      }
      else {
        errors.last_name = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "email") {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.email = "Please enter valid email.";
          this.setState({ errors: errors });
        } else {
          errors.email = "";
          this.setState({ errors: errors });
        }
      }
      else {
        errors.email = "";
        this.setState({ errors: errors });
      }
    }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  }

  scrollTop = () => {
    const section = document.querySelector('.Brandmainpage');
    section.scrollIntoView({ behavior: 'auto', block: 'start' });
  }

  /* CMS data getcode start */
  getCorporateFooterCmsData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    var cacheKeyName = global.level + "_FOOTER_DATA_" + levelSlug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }

    let res = await CmsDataService.getCmsData("FOOTER", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
      global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    }
    // console.log("footer",resData);
    await this.setState(resData);
  }
  saveNewsletter = async (e) => {
    e.preventDefault();
    if (
      this.state.errors.email === "" &&
      this.state.email !== "" &&
      this.state.errors.first_name === "" &&
      this.state.first_name !== "" &&
      this.state.errors.last_name === ""
    ) {
      this.setState({ loader: true });
      // this.setState({ showSpinner: true, isSubmit: true });
      // await this.getIp();
      var origin = window.location.origin ? window.location.origin : "";
      var pathname = window.location.pathname ? window.location.pathname : "";
      var finalPath = origin + pathname;
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        sort_order: this.state.sort_order,
        page_from: finalPath,
        ip: this.state.ip,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      let res = await ApplicantsService.createsubscriberFromWebsite(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          first_name: "",
          last_name: "",
          email: "",
          status_id: true,
          succes_message: "Thank you for your subscription"
        });
        // this.props.history.push(global.themeUrl + this.state.property_slug + "/virtual-tour-confirmation");
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
      }
      this.setState({ loader: false });

      // this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      //show required errors
      var errors = this.state.errors;
      var errorCount = this.state.errorCount;
      if (!this.state.email) {
        document.getElementById("email_sub").focus();
        errors.email = "Please enter email.";
      }
      if (!this.state.first_name) {
        document.getElementById("first_name_sub").focus();
        errors.first_name = "Please enter first name.";
      }
      this.setState({ errors: errors, errorCount: errorCount + 1 });
    }
  }
  /* CMS data getcode end */

  render() {
    // const section = document.querySelector( '#gallary-img-'+currentImageNo);
    // section.scrollIntoView( { behavior: 'auto', block: 'start' } );
    return (
      <>

        {
          !this.state.isOneFooter &&

          <section className="subscribeForm">
            <div className="container">
              <img alt="Sign up with your email to receive updates from OHAI" title="Send Icon" src={global.OnKeyPressEvent.getS3ImageUrl('send-icon.svg')} />
              <h2> {this.state.FOOTER ? ((this.state.FOOTER[0]) ? this.state.FOOTER[0].text : "Sign me up!") : "Sign me up!"} </h2>
              <p>Homesharing-friendly apartments. Rent a place to live, homeshare when you're away.
                {/* <p>{this.state.FOOTER ? ((this.state.FOOTER[1]) ? this.state.FOOTER[1].text : "Sign up with your email to receive updates from OHAI. We won’t spam you, we promise — just the good stuff.") : "Sign up with your email to receive updates from OHAI. We won’t spam you, we promise — just the good stuff."} */}
              </p>

              <div className="formSection">
                <Form>
                  <Form.Group className="mb-3 d-flex flex-wrap justify-content-center group">
                    {/* <Form.Control
                      type="text" placeholder="First name" id="first_name_sub"
                      name="first_name"
                      value={this.state.first_name}
                      onChange={this.changeHandler} error={
                        this.state.errors.first_name ? true : false
                      }
                      helperText={
                        this.state.errors.first_name
                          ? this.state.errors.first_name
                          : ""
                      } />

                    <Form.Control
                      type="text" placeholder="Last name" name="last_name"
                      value={this.state.last_name}
                      onChange={this.changeHandler} />
                    <Form.Control
                      type="email" placeholder="Email" name="email"
                      value={this.state.email}
                      onBlur={() => this.validateEmail()}
                      onChange={this.changeHandler}
                      id="email_sub" error={this.state.errors.email ? true : false}
                      helperText={
                        this.state.errors.email
                          ? this.state.errors.email
                          : ""
                      } /> */}
                    <div>
                      <TextField
                        type="text" placeholder="First name" id="first_name_sub"
                        name="first_name"
                        value={this.state.first_name}
                        onChange={(e) => this.changeHandler(e)} error={
                          this.state.errors.first_name ? true : false
                        }
                        helperText={
                          this.state.errors.first_name
                            ? this.state.errors.first_name
                            : ""
                        } />

                      {this.state.errors.first_name !== "" ? (
                        <p className="error-message">
                          {this.state.errors.first_name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <TextField
                        type="text" placeholder="Last name" name="last_name"
                        value={this.state.last_name}
                        onChange={(e) => this.changeHandler(e)} />
                      {this.state.errors.last_name !== "" ? (
                        <p className="error-message">
                          {this.state.errors.last_name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <TextField
                        type="email" placeholder="Email" name="email"
                        value={this.state.email}
                        //onBlur={() => this.validateEmail()}
                        onChange={(e) => this.changeHandler(e)}
                        id="email_sub" error={this.state.errors.email ? true : false}
                        helperText={
                          this.state.errors.email
                            ? this.state.errors.email
                            : ""
                        } />
                      {this.state.errors.email !== "" ? (
                        <p className="error-message">
                          {this.state.errors.email}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <button disabled={this.state.loader ? true : false} onClick={(e) => this.saveNewsletter(e)}>{this.state.FOOTER ? ((this.state.FOOTER[2]) ? this.state.FOOTER[2].text : "I'm in!") : "I'm in!"}</button>
                    </div>
                    {/* <button
                    
                                          hidden
                                          className="w-100"
                                          id="virtual-tour-button"
                                        >
                                          Confirm
                                        </button> */}
                    <span className="subscribemsg">
                      {this.state.succes_message ? (<img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} alt="Thank you for your subscription" title="Thank you for your subscription" />)
                        : ""}{this.state.succes_message}</span>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </section>
        }
        {/* Footer */}
        {this.state.webview == 1 ? (
        <footer>
          <div className="container-fluid">
            <div className="footer-bottom">
              <p>Ⓒ {this.state.FOOTER_COPY_RIGHT ? ((this.state.FOOTER_COPY_RIGHT[0]) ? this.state.FOOTER_COPY_RIGHT[0].text : "OHAI. All rights reserved.") : "OHAI. All rights reserved."} </p>
              <ul>
                <li><a href="/partnerships" >{this.state.section_header_data && this.state.section_header_data[1] && this.state.section_header_data[1].text ? this.state.section_header_data[1].text : 'Partnerships'}</a></li>
                {/* <li><a href="/contact" >{this.state.section_header_data && this.state.section_header_data[2] && this.state.section_header_data[2].text ? this.state.section_header_data[2].text : 'Contact'}</a></li> */}
                <li><a href="/contact">{this.state.FOOTER_COPY_RIGHT ? ((this.state.FOOTER_COPY_RIGHT[1]) ? this.state.FOOTER_COPY_RIGHT[1].text : "Contact") : "Contact"}</a></li>
                <li><a href="/disclaimers">{this.state.FOOTER_COPY_RIGHT ? ((this.state.FOOTER_COPY_RIGHT[2]) ? this.state.FOOTER_COPY_RIGHT[2].text : "Disclaimers") : "Disclaimers"}</a></li>
                <li><a href="/privacy">Privacy</a></li>
                <li><a href="/terms-condition">Terms</a></li>
              </ul>
            </div>
          </div>
          <img alt="Top Arrow" title="Top Arrow" onClick={() => this.scrollTop()} className="top-arrow" src={global.OnKeyPressEvent.getS3ImageUrl('top-arrow.svg')} />
        </footer>
         ) : (
          ""
        )}

        {/* <LiveChat liveChateEnable={true} /> */}
      </>
    );
  }
}
// Specifies the default values for props:
CommonFooter.defaultProps = {
  propertySlug: undefined
};
export default CommonFooter;
