//     							                          //
//  Program: strListing.jsx                               //
//  Application: OH HTML                                  //
//  Option: STR Listing                                   //
//  Developer: PS		                                  //
//  Date: 2022-04-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import Moment from "moment";
import StrlistingApi from "../../services/StrlistingApi";
import CmsDataService from "../../services/CmsDataService";
import queryString from "query-string";
const queryParams = queryString.parse(location.search);
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import GlobalFilter from "../common/GlobalFilter";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Skeleton, Typography } from "@mui/material";
import MetaSEO from "../../../MetaSEO";

class StrListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      type: false,
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      StrList: [],
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      Bedroom_list: [],
      Bathroom_list: [],
      bedroom: [],
      bathroom: [],
      UnitListing: [],
      UnitBedroom: [],
      UnitBathroom: [],
      UnitAmenity: {},
      location: queryParams.city ? queryParams.city : "",
      add: queryParams.furnished === "Unfurnished" ? "Unfurnished" : "Furnished",
      location_name: [],
      availablity_modal_box: false,
      listing_slug: this.props.match.params.slug,
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      slug: "",
      status_id: true,
      pathname: window.location.pathname,
      //   date:new Date('2014-08-18T21:11:54'),
      selectedDate: new Date(),
      // endDate: new Date(),
      // endDate: "",
      today: Moment(new Date()).format("YYYY-MM-DD"),
      selectedbedrooms: [],
      unit_size: {},
      price: {},
      min_unit: "",
      max_unit: "",
      min_price: "",
      max_price: "",
      startDate: queryParams.checkin ? queryParams.checkin : "",
      endDate: queryParams.checkout ? queryParams.checkout : "",
      itemsToShow: 6,
      item_slug: "",
      is_showmore: false,
      showmore_text: "Show More",
      showless_text: "Show Less",
      property_name: localStorage.getItem("property_name"),
      // meta tags start
      title: "",
      favicon: "",
      keywords: global.keywords,
      description: global.description,
      url: window.location.href,
      image: "",
      fb_app_id: "",
      // meta tags end
      adultCount:
        queryParams.numberOfAdults != "" && !isNaN(queryParams.numberOfAdults)
          ? parseInt(queryParams.numberOfAdults)
          : 1,
      childCount:
        queryParams.numberOfChildren != "" &&
          !isNaN(queryParams.numberOfChildren)
          ? parseInt(queryParams.numberOfChildren)
          : 0,
      infantCount:
        queryParams.numberOfInfants != "" && !isNaN(queryParams.numberOfInfants)
          ? parseInt(queryParams.numberOfInfants)
          : 0,
      isNeedtoStrDetails: true,
      headerToken: localStorage.getItem("headerToken") ? localStorage.getItem("headerToken") : "",
      userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : "",
      seoLoaded: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangelocation = this.handleChangelocation.bind(this);
    this.handleDateChangeout = this.handleDateChangeout.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleChangebedroom = this.handleChangebedroom.bind(this);
    this.handleChangebathroom = this.handleChangebathroom.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.SetUnitData = this.SetUnitData.bind(this);
    this.setPrice = this.setPrice.bind(this);
    this.changeDateHandlerNew = this.changeDateHandlerNew.bind(this);
    this.filterData = this.filterData.bind(this);
    // this.openDropdown = this.openDropdown.bind(this);
  }
  componentDidMount() {
    // this.getBedroombathroomList();
    this.getMetaData();
    this.getStrUnitlisting();
    this.getStrListingCmsData();
    document.getElementById("listing-focus").focus();
    // console.log("listing", this.state.property_slug);

  }

  makeQueryString = () => {
    // check_in=2022-11-24&check_out=2022-11-30
    var queryString = this.state.startDate ? "?check_in=" + Moment(this.state.startDate).format("YYYY-MM-DD") : "";
    queryString += this.state.endDate ? "&check_out=" + Moment(this.state.endDate).format("YYYY-MM-DD") : "";

    this.setState({ queryString: queryString });
  }

  /* CMS data getcode start */

  getMetaData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_STR_LISTING_META_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    let res = await CmsDataService.getCmsMetaData("STR_LISTING", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resData.title = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.title : "") : "";
      resData.favicon = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.keywords = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.keywords : "") : "";
      resData.description = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.description : "") : "";
      resData.url = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.image = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "") : "";
      resData.fb_app_id = res.data ? (res.data.meta_data && res.data.meta_data.META ? res.data.meta_data.META.fb_app_id : "") : "";
    }
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true
    });
  }

  getStrListingCmsData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    var cacheKeyName = global.level + "_STR_LISTING_DATA_" + levelSlug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let res = await CmsDataService.getCmsData("STR_LISTING", global.level, levelSlug);
    // console.log(res, "flist");
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false
    });
    // console.log(resData);
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
  }
  /* CMS data getcode end */

  changeDateHandlerNew = async (date) => {
    var startDate = date.value[0] ? date.value[0] : "";
    var endDate = date.value[1] ? date.value[1] : "";
    await this.setState({ startDate: startDate, endDate: endDate });
    await this.makeQueryString();
  }

  handleChangelocation = async (event) => {
    let input = this.state.input;
    input[event.label] = event.value;
    await this.setState({
      location: event.city_id,
      location_name: event.label,
      input: input,
    });
  }

  showmore(slug) {
    this.setState({ item_slug: slug, is_showmore: true });
  }

  showless(slug) {
    this.setState({ item_slug: "", is_showmore: false });
  }

  async getStrUnitlisting(inputData1 = "") {
    this.setState({ showSpinner: true, loading: true, Skeleton:true });
    var list = [];
    var totalRecords = 0;
    var city = [];
    let customInputs = {
      property_seo_slug: this.state.property_slug
        ? this.state.property_slug
        : "",
      city_id: this.state.location ? this.state.location : "",
      Furnished: this.state.add === "Unfurnished" ? ["Unfurnished"] : ["Fully Furnished"],
      Move_in_date: this.state.startDate
        ? Moment(this.state.startDate).format("YYYY-MM-DD")
        : this.state.today,
      Move_out_date: this.state.endDate
        ? Moment(this.state.endDate).format("YYYY-MM-DD")
        : "",
      guestValue:
        this.state.adultCount + this.state.childCount,
        // this.state.adultCount + this.state.childCount + this.state.infantCount,
    };

    let inputData = inputData1 ? inputData1 : customInputs;

    let res = await StrlistingApi.getStrUnitlisting(inputData);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      list.map((item, index) => {
        if (item.nightly_rate !== "") {
          totalRecords++;
        }
      })
      city = res.other_data ? res.other_data : [];
      if(list.length === 0){
        this.setState({ Skeleton: false })
      }
    } else {
      this.setState({ Skeleton: false })
    }
    city.map((item, index) => {
      item.label = city[index].city.city_name;
      item.value = city[index].city.city_id;
    });
    this.setState({ newcityList: city });

    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      StrList: list,
    });
    document.getElementById("listing-focus").focus();
  }

  // async getBedroombathroomList(queryString = "") {
  //   this.setState({ showSpinner: true, loading: true });
  //   var list = [];
  //   var totalRecords = 0;
  //   var cacheKeyName =
  //     global.theme + "_FLOORPLAN_DROPDOWN_DATA_" + this.state.property_slug;
  //   if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
  //     var data =
  //       global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
  //         ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
  //         : localStorage.removeItem(cacheKeyName);
  //     this.setState({
  //       total_record: data.length,
  //       Bedroom_list: data.bedroomArray,
  //       Bathroom_list: data.bathroomArray,
  //     });
  //   }
  //   let res = await StrlistingApi.getBedroomBathroomlisting(
  //     this.state.property_slug
  //   );
  //   if (global.successStatus.includes(res.status)) {
  //     list = res.data ? res.data : [];
  //     totalRecords = list ? list.length : 0;
  //   }
  //   global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(list));
  //   this.setState({
  //     showSpinner: false,
  //     loading: false,
  //     total_record: totalRecords,
  //     Bedroom_list: list.bedroomArray,
  //     Bathroom_list: list.bathroomArray,
  //   });
  // }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({
      [event.target.name.replace("-", "")]: value,
      input: input,
    });
    this.filterData();
  }

  filterData = async (inputData1 = "") => {
    await this.getStrUnitlisting(inputData1);
  }

  handleChange = (event) => {
    // console.log(event);
    this.setState({ personName: event.target.value });
  }

  handleDateChange(date, name) {
    let input = this.state.input;
    let a = new Date(date);
    input.move_in_date = Moment(new Date(a)).format("YYYY/MM/DD");
    // input[event.target.name] = event.target.value;
    // var a = Moment(new Date(event.target.value)).format("MM-DD-YYYY");
    //  //console.log(a);
    this.setState({ selectedDate: a, input: input });
    this.filterData();
  }

  handleDateChangeout(date, name) {
    let input = this.state.input;
    let a = new Date(date);
    input.move_out_date = Moment(new Date(a)).format("YYYY/MM/DD");
    this.setState({ endDate: a, input: input });
    this.filterData();
  }

  closeOtherTab = () => {
    this.SetUnitData();
    this.setPrice();
  }

  SetUnitData = (e) => {
    var unit = document.getElementById("unit-selection");
    unit !== null ? unit.classList.remove("show") : "";
    // unit.classList.remove("show");
  }

  setPrice = (e) => {
    var price = document.getElementById("price-selection");
    price !== null ? price.classList.remove("show") : "";
    // console.log(price);
    // unit.click();
    // price.classList.remove("show");
  }

  handleChangebedroom = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ bedroom: event.target.value, input: input });
    this.filterData();
  }

  handleChangebathroom = (event) => {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ bathroom: event.target.value, input: input });
    this.filterData();
  }

  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }

  unitSizeDDValue() {
    if (this.state.min_unit && !this.state.max_unit) {
      return this.state.min_unit + " Sq.Ft or more";
    }
    if (!this.state.min_unit && this.state.max_unit) {
      return this.state.max_unit + " Sq.Ft or less";
    }
    if (this.state.min_unit && this.state.max_unit) {
      return this.state.min_unit + " to " + this.state.max_unit + " Sq.Ft";
    }
    if (!this.state.min_unit && !this.state.max_unit) {
      return "";
    }
  }

  unitClear = async (e) => {
    await this.setState({
      min_unit: "",
      max_unit: "",
      input: { min_unit: "", max_unit: "" },
    });
    await this.getStrUnitlisting();
  }

  priceClear = async (e) => {
    await this.setState({
      min_price: "",
      max_price: "",
      input: { min_price: "", max_price: "" },
    });
    await this.getStrUnitlisting();
  }

  unitPriceDDValue() {
    if (this.state.min_price && !this.state.max_price) {
      return "$" + this.state.min_price + " or more";
    }
    if (!this.state.min_price && this.state.max_price) {
      return "$" + this.state.max_price + " or less";
    }
    if (this.state.min_price && this.state.max_price) {
      return "$" + this.state.min_price + " to $" + this.state.max_price;
    }
    if (!this.state.min_price && !this.state.max_price) {
      return "";
    }
  }

  locationFun = () => {
    return [
      { title: "The Shawshank Redemption", year: 1994 },
      { title: "The Godfather", year: 1972 },
      { title: "The Godfather: Part II", year: 1974 },
      { title: "The Dark Knight", year: 2008 },
      { title: "12 Angry Men", year: 1957 },
      { title: "Schindler's List", year: 1993 },
      { title: "Pulp Fiction", year: 1994 },
    ];
  }

  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const inputProps = {
      className: "md-mobile-picker-input",
      placeholder: "Add dates",
    };
    // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

    return (
      <React.Suspense fallback={<p>Loading list...</p>}>
        <React.Fragment>
          {/* meta data for index page for seo friendly url */}
          <main className="oh-template Brandmainpage">
            {/* Meta Tag Start */}
            {
              this.state.seoLoaded &&
              <MetaSEO
                title={this.state.title}
                favicon={this.state.favicon}
                keywords={this.state.keywords}
                description={this.state.description}
                noIndex={"true"}
                url={this.state.url}
                image={this.state.image}
                fb_app_id={this.state.fb_app_id}
                author={"OHAI"}
              />
            }
            {/* Meta Tag End */}
            {/* Header */}
            <CommonHeader propertySlug={this.state.property_slug} />
            {/* middle */}
            <section className="middle">
              <div className="container-fluid p-0">
                <section
                  className="liveHere-stay mg-b-10"
                  name="STR_LISTING"
                  style={{ backgroundImage: `url(${this.state.STR_LISTING_SECTION_1 ? ((this.state.STR_LISTING_SECTION_1[0]) ? this.state.STR_LISTING_SECTION_1[0].url : "") : ""})` }}
                >
                  <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-50 pd-r-50">
                    <h1>{this.state.seoLoaded ? this.state.title : "Stay at OHAI"}</h1>
                    <span className="m-0">
                      {/* {this.state.STR_LISTING &&
                        this.state.STR_LISTING.section_data &&
                        this.state.STR_LISTING.section_data[0] &&
                        this.state.STR_LISTING.section_data[0].text
                        ? this.state.STR_LISTING.section_data[0].text
                        : "Live here or stay awhile"} */}
                      {this.state.total_record
                        ? this.state.total_record > 1
                          ? this.state.total_record +
                          " units match your search "
                          : this.state.total_record + " unit match your search"
                        : ""}
                    </span>

                    {/* <h6>
                      {this.state.STR_LISTING &&
                        this.state.STR_LISTING.section_data &&
                        this.state.STR_LISTING.section_data[1] &&
                        this.state.STR_LISTING.section_data[1].text
                        ? this.state.STR_LISTING.section_data[1].text
                        : "Lease an apartment and earn money on your own terms — or stay with us while you’re in town (or however long you want…)."}
                    </h6> */}
                    {/* <div className="d-flex">
                      <button className="mg-r-15">
                        <a href="#strPlanList">
                          {" "}
                          {this.state.STR_LISTING &&
                            this.state.STR_LISTING.section_data &&
                            this.state.STR_LISTING.section_data[2] &&
                            this.state.STR_LISTING.section_data[2].text
                            ? this.state.STR_LISTING.section_data[2].text
                            : "Stay awhile"}
                        </a>
                      </button>{" "}
                      <button>
                        <a
                          href={
                            global.themeUrl +
                            this.state.property_slug +
                            "/floorplan-listing#floorPlanList"
                          }
                        >
                          {this.state.STR_LISTING &&
                            this.state.STR_LISTING.section_data &&
                            this.state.STR_LISTING.section_data[3] &&
                            this.state.STR_LISTING.section_data[3].text
                            ? this.state.STR_LISTING.section_data[3].text
                            : "Live here"}{" "}
                        </a>
                      </button>
                    </div> */}
                  </div>
                </section>
                {/* New filter section  */}
                <div className="globalFilter">
                  <section className="mainListing">
                    <GlobalFilter
                      locationArr={this.state.newcityList}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      changeDateHandlerNew={this.changeDateHandlerNew.bind(
                        this
                      )}
                      handleChangelocation={this.handleChangelocation.bind(
                        this
                      )}
                      changeHandler={this.changeHandler.bind(this)}
                      queryParams={queryParams}
                      page={"str"}
                      propertySlug={this.state.property_slug}
                      filterData={this.filterData.bind(this)}
                    />
                  </section>
                </div>
                {/* End New filter section  */}
                <section className="filter pd-b-20">
                  <div className="container-fluid">
                    <div className="property_detail" id="listing-focus">
                      <div className="container" >
                        {this.state.StrList.length > 0 ? (
                          this.state.StrList.map((item, i) => {
                            return (
                              item.nightly_rate && (
                                <div className="row" key={i}>
                                  <div className="col-md-12 pd-l-15 pd-r-30 mg-b-0">
                                    <div className="property_heading">

                                      <a
                                        href={this.state.isNeedtoStrDetails ? "/" + item.property_seo_slug + "/rooms/" + item.listing_seo_slug + this.state.queryString : item.book_redirect_to ? item.book_redirect_to + this.state.queryString : "#"}
                                        // href={item.book_redirect_to ? item.book_redirect_to : "#"}
                                        // href={`${ this.state.property_slug === "" ? item.property.property_seo_slug + "/" : this.state.property_slug + "/"}rooms/${item.listing_seo_slug}`
                                        // ( this.state.property_slug === ""
                                        //   ? item.property.property_seo_slug : "" ) +
                                        // "/rooms/" +
                                        // item.listing_seo_slug
                                        // }
                                        // target="_blank"
                                        className="text-decoration-none"
                                      >
                                        <h4 className="listing_title">

                                          {item.property_name
                                            ? item.property_name
                                            : "Las Olas"}
                                        </h4>
                                        {/* <h3>
                                          {item.brand
                                            ? item.brand.brand_name
                                            : "Kini Beach Haus"}{" "}
                                          /{" "}
                                          {item.property
                                            ? item.property.property_name
                                            : "Las Olas"}
                                        </h3> */}
                                        <h5>
                                          {item.city_name
                                            ? item.city_name
                                            : ""}
                                          ,{" "}
                                          {item.state_name
                                            ? item.state_name
                                            : ""}
                                        </h5>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-sm-12 pd-l-15 pd-r-30 mg-b-60">
                                    <a
                                      // href={`${ this.state.property_slug === "" ? item.property.property_seo_slug + "/" : this.state.property_slug + "/"}rooms/${item.listing_seo_slug}`
                                      // ( this.state.property_slug === ""
                                      //   ? item.property.property_seo_slug : "" ) +
                                      // "/rooms/" +
                                      // item.listing_seo_slug
                                      // }
                                      href={this.state.isNeedtoStrDetails ? "/" + item.property_seo_slug + "/rooms/" + item.slug + this.state.queryString : item.book_redirect_to ? item.book_redirect_to + this.state.queryString : "#"}
                                      // href={item.book_redirect_to ? item.book_redirect_to : "#"}
                                      // target="_blank"
                                      className="text-decoration-none"
                                    >
                                      <Swiper
                                         pagination={{
                                          clickable: true,
                                          dynamicBullets: true,
                                        }}
                                        lazy={true}
                                        zoom={true}
                                        autoplay={{
                                          delay: 2500,
                                          disableOnInteraction: false,
                                        }}
                                        modules={[Pagination, Autoplay]}
                                        className="mySwiper m-0"
                                      >
                                        {item.media_files &&
                                          item.media_files.length > 0 &&
                                          item.media_files.map(
                                            (value, index) => {
                                              return (
                                                <SwiperSlide key={index}>
                                                  <div className="property_img">
                                                    <figure key={index}>
                                                      <img
                                                        src={
                                                          value.large_img_w_720
                                                            ? value.large_img_w_720
                                                            : global.OnKeyPressEvent.getS3ImageUrl('oh_6313a700d82436313a700d8244.jpg')
                                                        }
                                                        alt="Property Image" title="Property Image"
                                                      />
                                                    </figure>
                                                  </div>
                                                </SwiperSlide>
                                              );
                                            }
                                          )}
                                          {item.propert_images &&
                                          item.propert_images.length > 0 &&
                                          item.propert_images.map(
                                            (value, index) => {
                                              return (
                                                <SwiperSlide key={index}>
                                                  <div className="property_img">
                                                    <figure key={index}>
                                                      <img
                                                        src={
                                                          value.large_img_w_720
                                                            ? value.large_img_w_720
                                                            : global.OnKeyPressEvent.getS3ImageUrl('oh_6313a700d82436313a700d8244.jpg')
                                                        }
                                                        alt="Property Image" title="Property Image"
                                                      />
                                                    </figure>
                                                  </div>
                                                </SwiperSlide>
                                              );
                                            }
                                          )}
                                      </Swiper>
                                    </a>
                                  </div>
                                  <div className="col-lg-7 col-sm-12 pd-l-30 pd-r-30 mg-b-60">
                                    <div className="Property_info">
                                      <a
                                        // href={`${ this.state.property_slug === "" ? item.property.property_seo_slug + "/" : this.state.property_slug + "/"}rooms/${item.listing_seo_slug}`
                                        // ( this.state.property_slug === ""
                                        //   ? item.property.property_seo_slug : "" ) +
                                        // "/rooms/" +
                                        // item.listing_seo_slug
                                        // }
                                        href={this.state.isNeedtoStrDetails ? "/" + item.property_seo_slug + "/rooms/" + item.slug + this.state.queryString : item.book_redirect_to ? item.book_redirect_to + this.state.queryString : "#"}
                                        // href={item.book_redirect_to ? item.book_redirect_to : "#"}
                                        // target="_blank"
                                        className="text-decoration-none"
                                      >
                                        <h4 className="listing_title p-0">
                                          {item.listing_name
                                            ? item.listing_name
                                            : ""}
                                        </h4>
                                      </a>
                                      <div className="property_size">
                                        <ul>
                                          <li>
                                            {" "}
                                            {`
                                      ${item.bedroom &&
                                              item.bedroom !== null &&
                                              (item.bedroom > 1
                                                ? item.bedroom + " Bedrooms"
                                                : item.bedroom + " Bedroom")
                                              } / ${item.bath !== null &&
                                              (item.bath > 1
                                                ? item.bath + " Bathrooms"
                                                : item.bath + " Bathroom")
                                              }
                                    `}
                                          </li>
                                          <li>{`Sleeps ${item.max_guest
                                            ? item.max_guest
                                            : 1
                                            } People`}
                                          </li>
                                          <li>{`${item.square_foot
                                            ? parseInt(item.square_foot)
                                            : ""
                                            } Sq ft`}
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="property_aminties">
                                        <ul>
                                          <li>
                                            {" "} Full kitchen
                                          </li>
                                          <li>
                                            {" "}Living room
                                          </li>
                                          <li>Pool</li>
                                          <li>Gym</li>
                                        </ul>
                                      </div>
                                      <div className="d-flex align-items-end mob-justify-content-between property_bottom">
                                        <div className="col-md-6">
                                          <h4 className="listing_title m-0">
                                            {`$${global.OnKeyPressEvent.numberWithCommasForDecimal(parseInt(
                                              item.nightly_rate
                                            ))}/night`}
                                          </h4>
                                        </div>
                                        <div className="col-md-6">
                                          <a
                                            // href={`${ this.state.property_slug === "" ? item.property.property_seo_slug + "/" : this.state.property_slug + "/"}rooms/${item.listing_seo_slug}`
                                            // ( this.state.property_slug === ""
                                            //   ? item.property.property_seo_slug : "" ) +
                                            // "/rooms/" +
                                            // item.listing_seo_slug
                                            // }
                                            href={this.state.isNeedtoStrDetails ? "/" + item.property_seo_slug + "/rooms/" + item.slug + this.state.queryString : item.book_redirect_to ? item.book_redirect_to + this.state.queryString : "#"}
                                            // href={item.book_redirect_to ? item.book_redirect_to : "#"}
                                            // target="_blank"
                                            className="text-decoration-none"
                                          >
                                            <button>Book</button>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })
                        ) : (
                          <div className="col-md-12 mg-b-60 text-center">
                            <>
                              {
                                this.state.Skeleton ?
                                  <div className="row">
                                    <div className="col-md-12"><Typography variant="h1" width={"50%"}><Skeleton /></Typography></div>
                                    <div className="col-md-12"><Typography variant="h3" width={"50%"}><Skeleton /></Typography></div>
                                    <div className="col-md-4">
                                      <Skeleton variant="rectangular" width="100%">
                                        <div style={{ paddingTop: '57%' }} />
                                      </Skeleton>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="col-md-12"><Typography variant="h1" width={"80%"}><Skeleton /></Typography></div>
                                      <div className="col-md-12"><Typography variant="h2" width={"80%"}><Skeleton /></Typography></div>
                                      <div className="col-md-12"><Typography variant="h3" width={"80%"}><Skeleton /></Typography></div>
                                      <div className="col-md-12"><Typography variant="h3" width={"80%"}><Skeleton /></Typography></div>
                                    </div>
                                  </div>
                                  :
                                  <p>No units found.</p>
                              }

                            </>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>

            {/* Footer */}
            <CommonFooter propertySlug={this.state.property_slug} />
          </main>
        </React.Fragment>
      </React.Suspense>
    );
  }
}
export default StrListing;
