////////////////////////////////////////////////////////////
//     							                                      //
//  Program: AdAgencyService.js                        //
//  Application: Third party                           //
//  Option: Used for manage add agency                 //
//  Developer: NP           						               //
//  Date: 2021-01-11                                   //
//                                                        //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";



class StrlistingApi {

  getStrUnitlisting(slug_type) {
    // let endPoint = "get-availability-of-units/" + slug;
    let endPoint = "get-str-list-data";
    let addditionalHeaderData = {};
    let postData = slug_type;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  // downloadPdf(url,file_name) {
  //   // let endPoint = "get-availability-of-units/" + slug;
  //   let endPoint = "download-files-from-s3";
  //   let addditionalHeaderData = {};
  //   let postData = {path:url,display_file_name:file_name};

  //   let inputData = {
  //     endPoint: endPoint,
  //     addditionalHeaderData: addditionalHeaderData,
  //     postData: postData,
  //   };
  //   let response = ApiService.postCall(inputData);
  //   return response;
  // }
  downloadPdf(data,queryString) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "download-files-from-s3"+ (queryString ? '?' + queryString : '');
    window.location.href = (process.env.REACT_APP_API_ENDPOINT+endPoint);
  }

  getUnitlisting(slug,property_slug="") {
    // let endPoint = "get-availability-of-units/" + slug;
    let endPoint = "get-str-list-detail?amenity_sort_type=sort_order";
    let addditionalHeaderData = {};
    let postData ={listing_seo_slug:slug,property_seo_slug:property_slug};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getNeighbourhoodlisting(property_slug="") {
    // let endPoint = "get-availability-of-units/" + slug;
    let endPoint = "get-neighbourhood-type";
    let addditionalHeaderData = {};
    let postData ={property_slug:property_slug};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getBedroomBathroomlisting(slug) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-bedroom-bath/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getBedlisting(slug) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-beds/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getNextdate(slug,listingslug,date) {
    //  let endPoint = "get-availability-of-floorplan/" + slug;
    let endPoint = "get-block-reservation-checkout-date/" + slug+"/"+listingslug+"/"+date;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getimagesData(data, queryString) {
    // let endPoint = "get-availability-of-units/" + slug;
    let endPoint = "get-multiple-files" + (queryString ? '?' + queryString : '');
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new StrlistingApi();
