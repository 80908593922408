//     							                                      //
//  Program: KINIProfile.jsx                              //
//  Application: KINI HTML                                //
//  Option: Profile page                                  //
//  Developer: PS		                                      //
//  Date: 2022-09-02                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AuthenticationService from "../../services/AuthenticationService";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import SpinnerLoader from "../common/SpinnerLoader";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_slug:  props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
      userData: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {},
      firstName: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).first_name
        : "",
      lastName: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).last_name
        : "",
      email: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).email
        : "",
      phoneNumber: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).phone_number
        : "",
      comments: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).comments
        : "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      updateDisableButton: true,
      updateChangePasswordButton: true,
      is_email: false,
      is_notification: false,
      is_sms: false,
      loader: false,
      updateNotificationSetting: false,
      imgError:""
    };
  }

  componentDidMount = () => {
    this.getUserSetting();
  };

  getUserSetting = async () => {
    let res = await AuthenticationService.getUserSetting();
    let is_email = false;
    let is_notification = false;
    let is_sms = false;

    if (global.successStatus.includes(res.status)) {
      is_email = res.data && res.data.is_email === 1 ? true : false;
      is_notification =
        res.data && res.data.is_notification === 1 ? true : false;
      is_sms = res.data && res.data.is_sms === 1 ? true : false;
      this.setState({
        is_email: is_email,
        is_notification: is_notification,
        is_sms: is_sms,
      });
    }
  };

  setUserSetting = async () => {
    this.setState({ loader: true });
    let inputData = {
      is_sms: this.state.is_sms,
      is_email: this.state.is_email,
      is_notification: this.state.is_notification,
      // "profile_thumb": this.state.profile_thumb
    };
    let res = await AuthenticationService.setUserSetting(inputData);
    if (global.successStatus.includes(res.status)) {
      this.getUserSetting();
      this.setState({ successUpdateProfile: "Profile has been successfully updated." });
    } else {
      this.setState({ successUpdateProfile: res.message });
    }
    this.setState({ loader: false });
  };

  onChangeNotification = (event) => {
    this.setState({ updateDisableButton: false,updateNotificationSetting:true });
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({ [event.target.name]: value });
  };

  updateProfile = async (e) => {
    this.setState({ loader: true });
    e.preventDefault();
    this.setState({ updateDisableButton: true });

    if (
      this.state.firstName !== "" &&
      this.state.errors.firstName === "" &&
      this.state.lastName !== "" &&
      this.state.errors.lastName === ""
      // this.state.email !== "" &&
      // this.state.errors.email !== ""
    ) {
      let inputData = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        comments: this.state.comments,
        // "profile_thumb": this.state.profile_thumb
      };
      this.state.profile_thumb
        ? (inputData.profile_thumb = this.state.profile_thumb)
        : "";

      let res = await AuthenticationService.updateProfile(inputData);
      if(this.state.updateNotificationSetting){
        this.setUserSetting();
      }
      if (global.successStatus.includes(res.status)) {
        let resdata = await AuthenticationService.getProfile();
        if (global.successStatus.includes(resdata.status)) {
          var resData = resdata.data ? resdata.data : {};
          await localStorage.setItem("userData", JSON.stringify(resData));
          this.setState({ successUpdateProfile: res.message });
          // this.props.imageUpload(resData.profile_url);
          // this.props.imageUpload(resData.profile_thumb_url);
        }
        // this.setState({ showSpinner: false, showAlertModal: true, alertModalType: 'success', alertModalTitle: 'Success', alertModalMessage: res.message ? res.message : 'Success' });
      } else {
        this.setState({ updateProfileError: res.message });
      }
    } else {
      var errors = this.state.errors;
      if (!this.state.firstName) {
        errors.firstName = "First name is required.";
      }
      if (!this.state.lastName) {
        errors.lastName = "Last name is required.";
      }
      this.setState({ errors: errors });
    }
    this.setState({ loader: false });
  };

  changeHandler = (event) => {
    this.setState({ updateDisableButton: false });
    if (event.target.type === "date" && event.target.value !== null) {
      event.target.value = Moment(event.target.value, "DD-MM-YYYY").toDate();
    }
    this.setState({ successUpdateProfile: "", updateProfileError: "" });
    if (event.target.name === "firstName") {
      if (event.target.value.length > 1) {
        const regex = /^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.firstName = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.firstName = "";
          this.setState({ errors: errors });
        }
      }
    }
    if (event.target.name === "lastName") {
      if (event.target.value.length > 1) {
        const regex = /^[a-zA-Z0-9]*[a-zA-Z]+[a-zA-Z0-9]*$/i;
        var errors = this.state.errors;
        if (regex.test(event.target.value) === false) {
          errors.lastName = "Please enter valid last name.";
          this.setState({ errors: errors });
        } else {
          errors.lastName = "";
          this.setState({ errors: errors });
        }
      }
    }
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({ [event.target.name]: value });
  };

  onImageChange = async (event) => {
    this.setState({ updateDisableButton: true });
    if (event.target.files[0] !== undefined) {
      this.setState({ loader: true });
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "profile_thumb",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");

      let res = await AuthenticationService.uploadProfileImage(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          profile_thumb: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ updateDisableButton: false });
        this.setState({ loader: false });
      } else if (res.status === 413) {
        this.setState({ updateDisableButton: false });
        this.setState({ imgError: "Content Too Large" });
        this.setState({ loader: false });
      } else if (res.status === 422) {
        let imgError = "";
        let ErrorDetails = (res.data && res.data.result) ?  (res.data.result.errorDetail ? res.data.result.errorDetail : "") : "";
        if(ErrorDetails !== ""){
          let br = ErrorDetails.length > 0 ? "<br/>" : "";
          ErrorDetails.map((item, index) => {
            imgError += item.errorMessage ?  item.errorMessage + br  : "";
          })
          this.setState({imgError:imgError});
          this.setState({ loader: false });
        }else{
          this.setState({imgError: "The profile thumb must be a file of type: jpeg, png, jpg1."});
          this.setState({ loader: false });
        }
        this.setState({ updateDisableButton: false });
        this.setState({ loader: false });
      } else {
        this.setState({ updateDisableButton: false });
        this.setState({ imgError: res.data.message });
        this.setState({ loader: false });
      }
    }
    this.setState({ loader: false });
  };

  passwordCheckFun = () => {
    let errors = this.state.errors;
    let passwordCheck = global.OnKeyPressEvent.validatePassword(this.state.newPassword);
      if(passwordCheck !== ""){
        errors.newPassword = passwordCheck
      } else {
        errors.newPassword = "";
      }
      this.setState({errors:errors});
  }

  verifyPassword = () => {
    let op = this.state.oldPassword;
    let np = this.state.newPassword;
    let cp = this.state.confirmPassword;
    let errors = this.state.errors;
    let success = true;
    if (op === "") {
      errors.oldPassword = "Please enter valid current password.";
      success = false;
    }else{
      errors.oldPassword = "";
    }
    if (np === "") {
      errors.newPassword = "Please enter valid new password.";
      success = false;
    }else{
      let passwordCheck = global.OnKeyPressEvent.validatePassword(np);
      if(passwordCheck !== ""){
        errors.newPassword = passwordCheck;
        success = false;
      } else {
        errors.newPassword = "";
      }
    }
    if (cp === "") {
      errors.confirmPassword = "Please enter valid confirm new password.";
      success = false;
    }else{
      errors.confirmPassword = "";
    }
    if (np !== cp && cp !== "") {
      errors.confirmPassword = "Confirm new password and new password must be same.";
      success = false;
    }
    if (op !== "" && np !== "" && op === np) {
      errors.newPassword =
        "New password and current password must be different.";
      success = false;
    }
    this.setState({ errors: errors });
    return success;
  };

  changePassword = async (e) => {
    this.setState({ loader1: true });
    this.setState({ updateChangePasswordButton: true });
    e.preventDefault();
    if (this.verifyPassword()) {
      // Validate all fields before data submission
      let input = {
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword,
      };
      let res = await AuthenticationService.changePassword(input);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          successPasswordChange: "Password changed successfully.",
        });
        setTimeout(
          () => (window.location.href = "/profile"),
          global.redirect_time
        );
      } else {
        this.setState({ errorPasswordChange: res.data.message });
      }
    }
    this.setState({ loader1: false });
  };

  changePasswordHandler = (event) => {
    this.setState({ updateChangePasswordButton: false });
    var errors = this.state.errors;
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ errorPasswordChange: "", successPasswordChange: "" });
    if (event.target.name === "oldPassword" && event.target.value.length >= 5) {
      errors.oldPassword = "";
    }
    if (event.target.name === "newPassword" && event.target.value.length >= 5) {
      errors.newPassword = "";
    }
    if (
      event.target.name === "confirmPassword" &&
      event.target.value.length >= 5
    ) {
      errors.confirmPassword = "";
    }
    this.setState({ errors: errors });

    if (
      event.target.name === "confirmPassword" &&
      event.target.value.length >= 6
    ) {
      var pw =
        event.target.name === "newPassword"
          ? event.target.value
          : this.state.newPassword;
      var cpw =
        event.target.name === "confirmPassword"
          ? event.target.value
          : this.state.confirmPassword;

      if (pw !== cpw) {
        errors.confirmPassword = "Confirm new password and new password must be same.";
      } else {
        errors.newPassword = "";
      }
      this.setState({ errors: errors });
    }
    // Validate
    // this.validator.showMessageFor(event.target.name);
  };

  render() {
    return (
      <main className="oh-template Brandmainpage">
         <Helmet>
        {/* <meta name="robots" content="noindex,nofollow" /> */}
        </Helmet>
        {/* Header */}
        <CommonHeader propertySlug={this.state.property_slug} profile_img={this.state.imageUrl} />
        {/* middle */}
        <section className="middle-section  pswd_user_chg">
          <div className="container">
            <section className="accountProfile pd-t-60 pd-b-60 d-flex flex-wrap">
              <div className="left">
                <div className="profile-img-upload">
                  <div className="fileUpload d-flex flex-wrap justify-content-between">
                    <span>
                      <label className="hand-cursor">
                        <input
                          onChange={this.onImageChange}
                          type="file"
                          name="profile_thumb"
                          accept="image/png, image/jpe, image/jpeg"
                        />
                        {this.state.imageUrl ? (
                          <img src={this.state.imageUrl} alt="Profile Image" title="Profile Image"/>
                        ) : (
                          <img
                            src={
                              this.state.userData &&
                                this.state.userData.profile_thumb_url
                                ? this.state.userData.profile_thumb_url
                                : global.OnKeyPressEvent.getS3ImageUrl('user-login.png')
                            }
                            alt="Profile Image" title="Profile Image"
                          />
                        )}
                      </label>
                    </span>
                  </div>
                  </div>
                  {this.state.imgError && (
                     <p className="text-danger text-left errorimg"
                        dangerouslySetInnerHTML={{__html: this.state.imgError}}
                      />
                        // <p className="text-danger text-left errorimg">
                        //   {this.state.imgError}
                        // </p>
                  )}

              </div>
              <div className="right">
                {/* <div className="editProfile d-none">
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <h3>Hi, I'm John</h3>
                    <button>Edit</button>
                  </div>
                  <h5>About </h5>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                </div> */}
                <div className="updateProfile">
                  <div className="d-flex flex-wrap justify-content-between align-items-center mobile">
                    <h5 className="mobile_w">Update account </h5>
                    <button
                      disabled={this.state.updateDisableButton ? true : false}
                      onClick={(e) => this.updateProfile(e)}
                    >
                      {" "}
                      Update
                      {this.state.loader ? (
                        <>
                          {/* <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          />
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          />
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          /> */}

                          <SpinnerLoader />

                        </>
                      ) : (
                        ""
                      )}
                    </button>
                    <button className="d-none">Edit</button>
                  </div>

                  <p className="mb-0">
                    You can update your contact information.{" "}
                  </p>
                  {this.state.updateProfileError && (
                    <p className="text-danger text-left">
                      {this.state.updateProfileError}
                    </p>
                  )}
                  {this.state.successUpdateProfile && (
                    <p className="text-success text-left">
                      {this.state.successUpdateProfile}
                    </p>
                  )}
                  <div className="row mg-lg-t-15 mg-xs-t-0">
                    <div className="col-lg-6 mg-t-15  mg-b-15">
                      <TextField
                        id="outlined-required"
                        label="First name"
                        variant="filled"
                        name="firstName"
                        onChange={this.changeHandler}
                        value={this.state.firstName}
                        className="borderInput textfield w-100"
                      // error={this.state.errors.firstName !== "" ? true : false}
                      // helperText={this.state.errors.firstName !== "" ? this.state.errors.firstName : ""}
                      required
                      />
                      {this.state.errors.firstName && (
                        <p className="text-danger text-left">
                          {this.state.errors.firstName}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6  mg-t-15 mg-b-15">
                      <TextField
                        id="outlined-required"
                        label="Last name"
                        variant="filled"
                        name="lastName"
                        onChange={this.changeHandler}
                        value={this.state.lastName}
                        className="borderInput textfield w-100"
                        required
                      />
                      {this.state.errors.lastName && (
                        <p className="text-danger">
                          {this.state.errors.lastName}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-12  mg-t-15">
                      <TextField
                        id="outlined-required"
                        label="Email"
                        variant="filled"
                        name="email"
                        value={this.state.email}
                        className="borderInput textfield w-100"
                        disabled={true}
                        required
                      />
                      {this.state.errors.email && (
                        <p className="text-danger">{this.state.errors.email}</p>
                      )}
                    </div>
                    <div className="col-lg-12 mg-t-30">
                      <TextField
                        id="outlined-required"
                        label="About"
                        variant="filled"
                        className="borderInput textfield w-100"
                        multiline
                        name="comments"
                        onChange={this.changeHandler}
                        value={this.state.comments}
                        rows={4}
                      />
                    </div>
                  </div>
                  <div className="row mg-t-20 notification notify_mg-tp">
                    <h5>Notification preferences </h5>
                    <span className="">My trips updates</span>
                    <p className="mg-b-0 update_text">
                      Receive updates regarding your booking and early
                      check-in/late check-out requests.{" "}
                    </p>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={this.state.is_email} />}
                        labelPlacement="end"
                        label="Email"
                        required={true}
                        name="is_email"
                        className="w-10"
                        onChange={(e) => this.onChangeNotification(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.is_sms}
                            onChange={(e) => this.onChangeNotification(e)}
                          />
                        }
                        labelPlacement="end"
                        label="SMS"
                        required={true}
                        name="is_sms"
                        className="w-10"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.is_notification}
                            onChange={(e) => this.onChangeNotification(e)}
                          />
                        }
                        labelPlacement="end"
                        label="Push notifications"
                        required={true}
                        name="is_notification"
                        className="w-10"
                      />
                    </FormGroup>
                  </div>
                  {/* <div className="row mg-t-10">
                    <span className="mg-t-10">Reminders & Recommendations</span>
                    <p className="mg-b-0">Receive booking reminders, travel tips, and recommendations relevant to your stay. </p>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={this.state.isAdult} />}
                        labelPlacement="end"
                        label="Email"
                        required={true}
                        name="isAdult"
                        className="w-10"

                      />
                    </FormGroup>

                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={this.state.isAdult} />}
                        labelPlacement="end"
                        label="SMS"
                        required={true}
                        name="isAdult"
                        className="w-10"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={this.state.isAdult} />}
                        labelPlacement="end"
                        label="Push notifications"
                        required={true}
                        name="isAdult"
                        className="w-10"
                      />
                    </FormGroup>

                  </div> */}

                  <hr className="mg-t-20 mg-b-20" />

                  <div className="d-flex flex-wrap justify-content-between align-items-center change-pass mg-b-30">
                    <h5 className="m-0 mobile_w deskview ">Change password </h5>
                    <button className="change-pass-text"
                      disabled={
                        this.state.updateChangePasswordButton ? true : false
                      }
                      onClick={(e) => this.changePassword(e)}
                    >Update
                      {this.state.loader1 ? (
                        <>
                          {/* <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          />
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          />
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="light"
                          /> */}

                          <SpinnerLoader />
                        </>
                      ) : (
                        ""
                      )}
                    </button>
                    <button className="d-none">Edit</button>
                    <h5 className="m-0 mobile_w mobview">Change password </h5>
                  </div>

                  <div className="row pswd_user_chg">
                    <div className="col-lg-12 cust-mg-t-30">
                      {this.state.successPasswordChange && (
                        <p className="text-success text-left">
                          {this.state.successPasswordChange}
                        </p>
                      )}
                      {/* {this.state.errorPasswordChange && (
                        <p className="text-danger text-left">
                          {this.state.errorPasswordChange}
                        </p>
                      )} */}
                      <TextField
                        id="outlined-required"
                        label="Current password"
                        variant="filled"
                        className="borderInput textfield w-100"
                        name="oldPassword"
                        type="password"
                        onChange={(e) => this.changePasswordHandler(e)}
                      />
                      {this.state.errors.oldPassword && (
                        <p className="text-danger text-left">
                          {this.state.errors.oldPassword}
                        </p>
                      )}
                      {this.state.errorPasswordChange && (
                        <p className="text-danger text-left">
                          {this.state.errorPasswordChange}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-12  mg-t-30">
                      <TextField
                        id="outlined-required"
                        label="New password"
                        variant="filled"
                        className="borderInput textfield w-100"
                        name="newPassword"
                        type="password"
                        onBlur={() => this.passwordCheckFun()}
                        onChange={(e) => this.changePasswordHandler(e)}
                      />
                      {this.state.errors.newPassword && (
                        <p className="text-danger text-left">
                          {this.state.errors.newPassword}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-12 mg-t-30">
                      <TextField
                        id="outlined-required"
                        label="Confirm new password"
                        variant="filled"
                        className="borderInput textfield w-100"
                        name="confirmPassword"
                        type="password"
                        onChange={(e) => this.changePasswordHandler(e)}
                      />
                      {this.state.errors.confirmPassword && (
                        <p className="text-danger text-left">
                          {this.state.errors.confirmPassword}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default Profile;
