//     							                          //
//  Program: help.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../services/CmsDataService";
import CommonHeader from "../template/common/CommonHeader";
import CommonFooter from "../template/common/CommonFooter";
import ReactPlayer from 'react-player'

class ResourceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      date: new Date('2014-08-18T21:11:54'),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      input: {},
      errors: {},
      slug: props.match && props.match.params ? (props.match.params.slug ? props.match.params.slug : "") : "",
    }
  }

  componentDidMount() {
    document.body.classList.add('resource_body');
    this.getResourceData();
  }

  getResourceData = async () => {
    this.setState({ showSpinner: true, loading: true });
    const slug = this.state.slug;
    let resData = [];
    let res = await CmsDataService.getResourceData(slug);
    if (global.successStatus.includes(res.status)) {
      resData.title = res.data?.title;
      resData.file_url = res.data?.file_url;
      resData.media_type = res.data?.media_type;
      resData.description = res.data?.description;
    }
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true,
      title: resData.title,
      file_url: resData.file_url,
      media_type: resData.media_type,
      description: resData.description,
    });
  }


  render() {
    return (
      <main className="oh-template Brandmainpage-">
        {/* <CommonHeader propertySlug={this.state.property_slug} /> */}
        <section className="middle">
          <div className="container onboardingpagestyle resource_detail_cnt">
            <section className="reservationBook ">
              <h1 className="mb-5">{this.state.title}</h1>
            </section>
            <section >
              {this.state.media_type === 1 ? (
                <>
            <img src={this.state.file_url} alt="" />
            </>
              ) : (
                <>
            <ReactPlayer controls url={this.state.file_url} className="resorce_video"/>
            </>
              )}
              <p
                className="cust_text_left"
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.description ? this.state.description : "",
                }}
              ></p>
            </section>
          </div>
        </section>
        {/* <CommonFooter propertySlug={this.state.property_slug} /> */}
      </main>
    );
  }
}
export default ResourceDetail;