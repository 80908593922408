//     							                          //
//  Program: Inventory.jsx                                //
//  Application: KINI HTML                                //
//  Option: Home Sharing Listing                          //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import ApplicantsService from "../../services/ApplicantsService";
import CommonHeader from "../common/CommonHeader";
import LiveChat from "../common/LiveChat";
import CookieConsent from "react-cookie-consent";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dropdown from "react-bootstrap/Dropdown";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, options, Page } from "@mobiscroll/react";
import Moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import Form from "react-bootstrap/Form";
import queryString from "query-string";
import { Skeleton } from "@mui/material";
import MetaSEO from "../../../MetaSEO";
import { Spinner } from "react-bootstrap";
const queryParams = queryString.parse(location.search);

class CorporateIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // topArrow: global.OnKeyPressEvent.getS3ImageUrl("top-arrow.svg'),

      add: "",
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      UnitListing: [],
      errors: {},
      first_name: "",
      last_name: "",
      email: "",
      errorCount: 0,
      succes_message: "",
      loader: false,
      errors: {
        email: "",
        first_name: "",
      },
      showAlertModal: false,
      selectedDate: "",
      // Moment(new Date(date.value[0])).format("MM-DD-YYYY")
      moveoutDate: "",
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      guest: "",
      total_record: 0,
      confirm_back: 0,
      adultCount: 1,
      childCount: 0,
      bedroomcount: 1,
      bathCount: 1,
      infantCount: 0,
      petsCount: 0,
      bedroom: "",
      location: [],
      location_name: [],
      guestValue: "1 Guest",
      bedbathValue: "1 Bedroom, 1 Bathroom",
      bathroom: "",
      queryString: "",
      queryStringltr: "",
      // property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
      slug: "",
      status_id: true,
      pathname: window.location.pathname,
      sec: "http://localhost:3000/kini/static/media/logo.8d8e6eeb6e92504d4242.png",
      property_name: localStorage.getItem("property_name"),
      brand_name: localStorage.getItem("brand_name"),
      // meta tags start
      title: props.title ? props.title : "OHAI",
      favicon: "",
      keywords: global.keywords,
      description: global.description,
      url: window.location.href,
      image: "",
      fb_app_id: "",
      viewallproperty: false,
      // meta tags end
      locationSelected: false,
      loaderSearch: false,
      seoLoaded: false,
      search: props.page ? props.page : "live-here",
      resDataLength: 0
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleChangelocation = this.handleChangelocation.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateChangeRange = this.handleDateChangeRange.bind(this);
    this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementbath = this.incrementbath.bind(this);
    this.decrementbath = this.decrementbath.bind(this);
    this.incrementBed = this.incrementBed.bind(this);
    this.decrementBed = this.decrementBed.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    this.saveNewsletter = this.saveNewsletter.bind(this);
    this.getIp = this.getIp.bind(this);
    // this.incrementpets = this.incrementpets.bind(this);
    // this.decrementpets = this.decrementpets.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.bedbathDetail = this.bedbathDetail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Setclosebath = this.Setclosebath.bind(this);
    this.Setclose = this.Setclose.bind(this);
  }

  componentDidMount() {
    if (this.props.redirect) {
      window.location.href = this.props.redirectTo;
    }
    // this.getIp();
    this.getMetaData();
    this.guestDetail();
    this.getCorporateHomeCmsData();
    this.getCorporateFooterCmsData();
    this.getBranddata();
    this.bedbathDetail();
    this.createQueryString();
    this.createQueryStringLtr();
  }
  getIp = async (e) => {
    let response = await fetch("https://geolocation-db.com/json/");
    let data = await response.json();
    this.setState({ ip: data.IPv4 });
  };

  async openpropertyrest() {
    await this.setState({ viewallproperty: true });
  }
  changeHandlerSelect = async (event) => {
    let input = this.state.input;
    input.add = event.target.value;
    await this.setState({ add: event.target.value, input: input });
    await this.createQueryString();
    await this.createQueryStringLtr();
    if (event.target.value == "Unfurnished") {
      var unit = document.getElementById(
        "uncontrolled-tab-example-tab-live-here"
      );
      if (unit) {
        unit.click();
      }
    } else {
      var unit = document.getElementById(
        "uncontrolled-tab-example-tab-stay-here"
      );
      if (unit) {
        unit.click();
      }
    }
  };

  saveNewsletter = async (e) => {
    e.preventDefault();
    if (
      this.state.errors.email === "" &&
      this.state.email !== "" &&
      this.state.errors.first_name === "" &&
      this.state.first_name !== "" &&
      this.state.errors.last_name === ""
    ) {
      // await this.getIp();
      this.setState({ loader: true });
      // this.setState({ showSpinner: true, isSubmit: true });
      var origin = window.location.origin ? window.location.origin : "";
      var pathname = window.location.pathname ? window.location.pathname : "";
      var finalPath = origin + pathname;
      let inputData = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        sort_order: this.state.sort_order,
        page_from: finalPath,
        ip: this.state.ip,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      let res = await ApplicantsService.createsubscriberFromWebsite(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
          first_name: "",
          last_name: "",
          email: "",
          status_id: true,
          // succes_message: "Thanks for your subscription"
          succes_message: "Thank you for your subscription.",
        });
        // this.props.history.push(global.themeUrl + this.state.property_slug + "/virtual-tour-confirmation");
      } else {
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          // this.props.history.push(global.themeUrl + this.state.property_slug + "/virtual-tour-confirmation");
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
        }
        this.setState({ loader: false });

        // this.setState({ showSpinner: false, isSubmit: false });
        setTimeout(
          () => this.setState({ showAlertModal: false }),
          global.alert_close_time
        );
      }
    } else {
      //show required errors
      var errors = this.state.errors;
      var errorCount = this.state.errorCount;
      if (!this.state.email) {
        document.getElementById("email_sub").focus();
        errors.email = "Please enter email.";
      }
      if (!this.state.first_name) {
        document.getElementById("first_name_sub").focus();
        errors.first_name = "Please enter first name.";
      }
      this.setState({ errors: errors, errorCount: errorCount + 1 });
    }
  };

  /* CMS data getcode start */

  getMetaData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_CORPORATE_HOME_META_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data =
        global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
          ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
          : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug =
      global.level === 2
        ? global.brandSlug
        : global.level === 3
        ? this.state.property_slug
        : 1;
    let res = await CmsDataService.getCmsMetaData(
      "HOME",
      global.level,
      levelSlug
    );
    if (global.successStatus.includes(res.status)) {
      resData.title = this.props.title
        ? this.props.title
        : res.data
        ? res.data.meta_data && res.data.meta_data.META
          ? res.data.meta_data.META.title
          : ""
        : "";
      resData.favicon = res.data
        ? res.data.meta_data && res.data.meta_data.META
          ? res.data.meta_data.META.favicon_url
          : ""
        : "";
      resData.keywords = res.data
        ? res.data.meta_data && res.data.meta_data.META
          ? res.data.meta_data.META.keywords
          : ""
        : "";
      resData.description = res.data
        ? res.data.meta_data && res.data.meta_data.META
          ? res.data.meta_data.META.description
          : ""
        : "";
      resData.url = res.data
        ? res.data.meta_data && res.data.meta_data.META
          ? res.data.meta_data.META.favicon_url
          : ""
        : "";
      resData.image = res.data
        ? res.data.meta_data && res.data.meta_data.META
          ? res.data.meta_data.META.favicon_url
          : ""
        : "";
      resData.fb_app_id = res.data
        ? res.data.meta_data && res.data.meta_data.META
          ? res.data.meta_data.META.fb_app_id
          : ""
        : "";
    }
    global.LocalStorageManage.setWithExpiry(
      cacheKeyName,
      JSON.stringify(resData)
    );
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true,
    });
  };

  getCorporateHomeCmsData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_CORPORATE_HOME_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data =
        global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
          ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
          : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug =
      global.level === 2
        ? global.brandSlug
        : global.level === 3
        ? this.state.property_slug
        : 1;
    let res = await CmsDataService.getCmsData("HOME", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false,
    });
    // console.log(resData);
    global.LocalStorageManage.setWithExpiry(
      cacheKeyName,
      JSON.stringify(resData)
    );
    this.setState(resData);
  };

  getCorporateFooterCmsData = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_CORPORATE_FOOTER_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data =
        global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null
          ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName))
          : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug =
      global.level === 2
        ? global.brandSlug
        : global.level === 3
        ? this.state.property_slug
        : 1;
    let res = await CmsDataService.getCmsData(
      "FOOTER",
      global.level,
      levelSlug
    );
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item.section_data;
      });
      global.LocalStorageManage.setWithExpiry(
        cacheKeyName,
        JSON.stringify(resData)
      );
    }
    // console.log("footer", resData);
    await this.setState(resData);
  };
  /* CMS data getcode end */

  handleChangelocation = async (event) => {
    let input = this.state.input;
    input[event.label] = event.value;
    await this.setState({
      location: event.value,
      location_name: event.label,
      input: input,
      locationSelected: true,
    });
    await this.createQueryString();
    await this.createQueryStringLtr();
  };

  handleDateChange = async (date, name) => {
    let input = this.state.input;
    let a = new Date(date.valueText);
    input.move_in_date = Moment(new Date(date.value)).format("MM/DD/YYYY");
    await this.setState({ selectedDate: input.move_in_date, input: input });
    await this.createQueryString();
  };

  handleDateChangeRange = async (date, name) => {
    let input = this.state.input;
    let a = new Date(date.valueText);
    input.move_in_date = Moment(new Date(date.value[0])).format("MM/DD/YYYY");
    input.move_out_date = Moment(new Date(date.value[1])).format("MM/DD/YYYY");
    await this.setState({
      selectedDate: input.move_in_date,
      moveoutDate: input.move_out_date,
      input: input,
    });
    await this.createQueryStringLtr();
  };

  guestDetail = () => {
    var message = "";
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Children"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    this.setState({ guestValue: message });
    // return message;
  };

  handleChange = (event, value) => {
    this.setState({ newcityList: value });
  };

  bedbathDetail = () => {
    var message = "";
    message +=
      this.state.bedroomcount > 1
        ? this.state.bedroomcount + " Bedrooms"
        : this.state.bedroomcount + " Bedroom";
    message += this.state.bathCount
      ? this.state.bathCount > 1
        ? ", " + this.state.bathCount + " Bathrooms"
        : ", " + this.state.bathCount + " Bathroom"
      : "";
    this.setState({ bedbathValue: message });
    // return message;
  };

  Setclosebath() {
    var unit = document.getElementById("dropdown-basic-bed");
    if (unit) {
      unit.click();
    }
  }

  createQueryString = () => {
    var queryString = "";
    queryString = `?city_name=${this.state.location_name}&city=${
      this.state.location
    }&checkin=${this.state.selectedDate}&furnished=${
      this.state.add === "" ? "Unfurnished" : this.state.add
    }&numberOfbed=${this.state.bedroomcount}&numberOfbathroom=${
      this.state.bathCount
    } `;
    this.setState({ queryString: queryString });
  };

  createQueryStringLtr = () => {
    var queryStringltr = "";
    queryStringltr = `?city_name=${this.state.location_name}&city=${
      this.state.location
    }&checkin=${this.state.selectedDate}&checkout=${
      this.state.moveoutDate
    }&furnished=${
      this.state.add === "" ? "Furnished" : this.state.add
    }&numberOfAdults=${this.state.adultCount}&numberOfChildren=${
      this.state.childCount
    }&numberOfInfants=${this.state.infantCount} `;
    this.setState({ queryStringltr: queryStringltr });
  };

  Setclose() {
    var unit = document.getElementById("dropdown-basic-bath");
    if (unit) {
      unit.click();
    }
  }

  async incrementbath() {
    await this.setState({ bathCount: this.state.bathCount + 1 });
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async decrementbath() {
    if (this.state.bathCount === 0 || this.state.bathCount === 1) {
      await this.setState({ bathCount: 1 });
    } else {
      await this.setState({ bathCount: this.state.bathCount - 1 });
    }
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async incrementBed() {
    await this.setState({ bedroomcount: this.state.bedroomcount + 1 });
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async decrementBed() {
    if (this.state.bedroomcount === 0) {
      await this.setState({ bedroomcount: 0 });
    } else {
      await this.setState({ bedroomcount: this.state.bedroomcount - 1 });
    }
    await this.createQueryString();
    await this.bedbathDetail();
  }

  async incrementAdult() {
    await this.setState({ adultCount: this.state.adultCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({ adultCount: this.state.adultCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({ childCount: this.state.childCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({ childCount: this.state.childCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    await this.createQueryStringLtr();
    await this.guestDetail();
  }

  searchRedirect = (path) => {
    this.setState({ loaderSearch: true });
    if (this.state.locationSelected) {
      this.setState({ loaderSearch: false });
      window.location.href =
        "/" +
        path +
        (path === "floorplan-listing"
          ? this.state.queryString
          : this.state.queryStringltr);
    } else {
      var unit = document.getElementById("autocomplete-" + path);
      this.setState({ loaderSearch: false });
      if (unit) {
        unit.click();
      }
    }
  };

  // async incrementpets() {
  //   await this.setState({ petsCount: this.state.petsCount + 1 });
  //   // await this.createQueryString();
  //   await this.guestDetail();
  // }

  // async decrementpets() {
  //   if (this.state.petsCount === 0) {
  //     await this.setState({ petsCount: 0 });
  //   } else {
  //     await this.setState({ petsCount: this.state.petsCount - 1 });
  //   }
  //   // await this.createQueryString();
  //   await this.guestDetail();
  // }

  getBranddata = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = [];
    var resDataLength = 0;
    var city = [];

    let inputData = {
      brand_seo_slug: "",
    };
    let res = await CmsDataService.getBrandData(inputData);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : [];
      resDataLength = resData ? resData.length : [];
      city = res.other_data ? res.other_data : [];
    }
    city.map((item, index) => {
      item.label = city[index].city.city_name;
      item.value = city[index].city.city_id;
    });
    var item1 = {};
    var item2 = {};
    var item3 = {};
    var item4 = {};
    var finaldata = resData;
    resData.map((item, index) => {
      if (index === 0) {
        item1.domain_city_name = resData[index].domain_city_name;
        item1.domain_name = resData[index].domain_name;
        item1.property_seo_slug = resData[index].property_seo_slug;
        item1.property_main_image_url = resData[index].property_main_image_url;
        item1.city_name = resData[index].city.city_name;
        item1.property_name = resData[index].property_name;
        finaldata = this.arrayRemove(finaldata, resData[index].slug);
      }
      if (index === 1) {
        item2.domain_city_name = resData[index].domain_city_name;
        item2.domain_name = resData[index].domain_name;
        item2.property_seo_slug = resData[index].property_seo_slug;
        item2.property_main_image_url = resData[index].property_main_image_url;
        item2.city_name = resData[index].city.city_name;
        item2.property_name = resData[index].property_name;
        finaldata = this.arrayRemove(finaldata, resData[index].slug);
      }
      if (index === 2) {
        item3.domain_city_name = resData[index].domain_city_name;
        item3.domain_name = resData[index].domain_name;
        item3.property_seo_slug = resData[index].property_seo_slug;
        item3.property_main_image_url = resData[index].property_main_image_url;
        item3.city_name = resData[index].city.city_name;
        item3.property_name = resData[index].property_name;
        finaldata = this.arrayRemove(finaldata, resData[index].slug);
      }
      if (index === 3) {
        item4.domain_city_name = resData[index].domain_city_name;
        item4.domain_name = resData[index].domain_name;
        item4.property_seo_slug = resData[index].property_seo_slug;
        item4.property_main_image_url = resData[index].property_main_image_url;
        item4.city_name = resData[index].city.city_name;
        item4.property_name = resData[index].property_name;
        finaldata = this.arrayRemove(finaldata, resData[index].slug);
      }
    });

    this.setState({ newcityList: city });
    this.setState({
      resData: resData,
      item1: item1,
      item2: item2,
      item3: item3,
      item4: item4,
      resDataLength: resDataLength,
      finaldata: finaldata,
    });
  };
  arrayRemove(arr, value) {
    return arr.filter(function (geeks) {
      return geeks.slug !== value;
    });
  }

  validateEmail = () => {
    var result = global.OnKeyPressEvent.validateEmail(this.state.email);
    if (!result) {
      let errors = this.state.errors;
      errors.email = "Please enter valid email.";
      this.setState({ errors: errors });
    } else {
      let errors = this.state.errors;
      errors.email = "";
      this.setState({ errors: errors });
    }
  };

  changeHandler = (event) => {
    // let input = this.state.input;
    // let value = event.target.value;
    // input[event.target.name] = value;
    // this.setState({ [event.target.name]: value, input: input });
    let input = this.state.input;
    let value = event.target.value;
    if (event.target.name === "first_name") {
      const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.first_name = "Please enter valid first name.";
          this.setState({ errors: errors });
        } else {
          errors.first_name = "";
          this.setState({ errors: errors });
        }
      } else {
        errors.first_name = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "last_name") {
      const regex = /^[a-zA-Z0-9 ]*[a-zA-Z ]+[a-zA-Z0-9 ]*$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.last_name = "Please enter valid last name.";
          this.setState({ errors: errors });
        } else {
          errors.last_name = "";
          this.setState({ errors: errors });
        }
      } else {
        errors.last_name = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "email") {
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      var errors = this.state.errors;
      if (event.target.value.length >= 1) {
        if (regex.test(event.target.value) === false) {
          errors.email = "Please enter valid email.";
          this.setState({ errors: errors });
        } else {
          errors.email = "";
          this.setState({ errors: errors });
        }
      } else {
        errors.email = "";
        this.setState({ errors: errors });
      }
    }

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };

  scrollTop = () => {
    const section = document.querySelector(".Brandmainpage");
    section.scrollIntoView({ behavior: "auto", block: "start" });
  };

  render() {
    const inputProps = {
      className: "md-mobile-picker-input",
      placeholder: "Add dates",
    };

    let counter = 0;
    return (
      <main className="Brandmainpage">
        {/* Header */}

        {/* Meta Tag Start */}

        {this.state.seoLoaded && (
          <MetaSEO
            title={this.state.title}
            favicon={this.state.favicon}
            keywords={this.state.keywords}
            description={this.state.description}
            url={this.state.url}
            image={
              this.state.CORPORATE_HOME_SECTION_1
                ? this.state.CORPORATE_HOME_SECTION_1[2]
                  ? this.state.CORPORATE_HOME_SECTION_1[2].url
                  : this.state.image
                : this.state.image
            }
            fb_app_id={this.state.fb_app_id}
            noIndex="false"
            author={"OHAI"}
          />
        )}
        {/* Meta Tag End */}
        <CommonHeader />

        {/* middle */}
        <section className="middle" name="SECTION_1">
          <div className="container-fluid p-0">
            {this.state.CORPORATE_HOME_SECTION_1 ? (
              <>
                <section
                  className="banner"
                  style={{
                    backgroundImage: `url(${
                      this.state.CORPORATE_HOME_SECTION_1
                        ? this.state.CORPORATE_HOME_SECTION_1[2]
                          ? this.state.CORPORATE_HOME_SECTION_1[2].url
                          : ""
                        : ""
                    })`,
                  }}
                >
                  <div className="container d-flex align-items-start flex-column justify-content-center h-100 w-100 pd-l-25 pd-r-25">
                    <h1>
                      {this.props.title
                        ? this.props.title
                        : this.state.CORPORATE_HOME_SECTION_1
                        ? this.state.CORPORATE_HOME_SECTION_1[0]
                          ? this.state.CORPORATE_HOME_SECTION_1[0].text
                          : "Live like a local"
                        : "Live like a local"}{" "}
                    </h1>
                    <span>
                      {this.state.CORPORATE_HOME_SECTION_1
                        ? this.state.CORPORATE_HOME_SECTION_1[1]
                          ? this.state.CORPORATE_HOME_SECTION_1[1].text
                          : "Unique places to live or stay"
                        : "Unique places to live or stay"}
                    </span>
                  </div>
                </section>
              </>
            ) : (
              <>
                <section className="banner">
                  <Skeleton
                    sx={{ height: "100%", width: "100%", bgcolor: "black.550" }}
                    animation="wave"
                    variant="rectangular"
                  />
                </section>
              </>
            )}

            <section className="mainListing">
              <div className="mainfilter">
                <div className="container">
                  <div className="filtersection">
                    <Tabs
                      defaultActiveKey={
                        this.props.page === "stay-here"
                          ? "stay-here"
                          : "live-here"
                      }
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="live-here" title="Live here">
                        <div className="tab-section">
                          <div className="mobileViewbannerContent">
                            <h1>
                              {this.props.title ? (
                                this.props.title
                              ) : this.state.CORPORATE_HOME_SECTION_1 ? (
                                !this.state.CORPORATE_HOME_SECTION_1[0] ? (
                                  this.state.CORPORATE_HOME_SECTION_1[0].text
                                ) : (
                                  <Skeleton />
                                )
                              ) : (
                                <Skeleton />
                              )}{" "}
                            </h1>
                            <h4>
                              {this.state.CORPORATE_HOME_SECTION_1 ? (
                                !this.state.CORPORATE_HOME_SECTION_1[1] ? (
                                  this.state.CORPORATE_HOME_SECTION_1[1].text
                                ) : (
                                  <Skeleton />
                                )
                              ) : (
                                <Skeleton />
                              )}
                            </h4>
                          </div>
                          <div className="search d-flex flex-column justify-content-start align-items-start">
                            <label>Where</label>
                            <Stack>
                              <Autocomplete
                                freeSolo
                                id="autocomplete-floorplan-listing"
                                name="location"
                                disableClearable
                                disablePortal
                                // value={this.state.location.value}
                                value={this.state.location_name}
                                //alue={option}
                                onChange={(e, v) => {
                                  this.handleChangelocation(v);
                                }}
                                options={
                                  this.state.newcityList
                                    ? this.state.newcityList
                                    : []
                                }
                                // onChange={this.handleChangelocation}
                                // getOptionValue={option => `${ option } `}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    hiddenLabel
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                    placeholder="Search locations"
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          <div className="add d-flex flex-column justify-content-start align-items-start">
                            <label>Add</label>
                            <FormControl>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={
                                  this.state.add
                                    ? this.state.add
                                    : "Unfurnished"
                                }
                                //disabled
                                name="add"
                                onChange={(event) =>
                                  this.changeHandlerSelect(event)
                                }
                              >
                                {/* <MenuItem value={10} selected>Furnished</MenuItem> */}
                                {/* <MenuItem value={10} selected >Unfurnished</MenuItem> */}
                                <MenuItem value="Furnished">Furnished</MenuItem>
                                <MenuItem value="Unfurnished">
                                  Unfurnished
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="when d-flex flex-column justify-content-start align-items-start">
                            <label>When</label>
                            <Datepicker
                              controls={["calendar"]}
                              buttons={[
                                {
                                  // text: "X",
                                  icon: "material-close",
                                  handler: "cancel",
                                },
                              ]}
                              pages={1}
                              calendarType="month"
                              invalid={[]}
                              inputComponent="input"
                              inputProps={{
                                placeholder: "Add date",
                              }}
                              selected={this.state.selectedDate}
                              onChange={(date) =>
                                this.handleDateChange(date, "dateFour")
                              }
                              rangeSelectMode="wizard"
                              showRangeLabels={true}
                              touchUi={false}
                              theme="ios"
                              themeVariant="light"
                              headerText=""
                              max=""
                              rangeStartLabel="Check-in"
                              closeOnEsc={true}
                              // rangeEndLabel="Check-out"
                              // buttons={['set', 'cancel']}
                              // /select=""
                              // closeOnEsc={true}
                              // cssClass=""
                              // defaultValue={undefined}
                              // // display="bottom"
                              responsive={{
                                xsmall: {
                                  pages: 1,
                                  touchUi: false,
                                  calendarSize: "2",
                                  // buttons: ['Cancel'],
                                  cssClass: "single_pick_date",
                                  calendarScroll: "horizontal",
                                  showOuterDays: true,
                                  refDate: Moment(new Date()).add(1, "year"),
                                },
                                medium: {
                                  // display: 'center',
                                  pages: 1,
                                  // touchUi: true
                                },
                                // custom: {
                                //   breakpoint: 850,
                                //   display: 'center',
                                // pages: 2,
                                //   touchUi: false
                                //}
                              }}
                            />
                          </div>
                          <div className="details d-flex flex-column justify-content-start align-items-start">
                            <label className="vs-hide-">Details</label>
                            <Dropdown autoClose={false}>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic-bed"
                              >
                                <TextField
                                  id="outlined-read-only-input"
                                  // label="GUESTS"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  className="inputBox"
                                  //placeholder="Add guests"
                                  value={this.state.bedbathValue}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <div className="d-flex justify-content-between pd-15 pd-b-5">
                                  <ul>
                                    {/* <li><b>Bedrooms</b></li> */}
                                    <li>
                                      <b>
                                        {this.state.bedroomcount > 1
                                          ? "Bedrooms"
                                          : "Bedroom"}
                                      </b>
                                    </li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button
                                        onClick={this.decrementBed}
                                        disabled={
                                          this.state.bedroomcount === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "minus-guest.svg"
                                          )}
                                          alt="Decrement bedroom counter"
                                          title="Decrement bedroom counter"
                                        />
                                      </button>
                                    </span>

                                    <span>{this.state.bedroomcount}</span>
                                    <span>
                                      <button onClick={this.incrementBed}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "plus-guest.svg"
                                          )}
                                          alt="Increment bedroom counter"
                                          title="Increment bedroom counter"
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between pd-15 pd-b-5">
                                  <ul>
                                    {/* <li><b>Bathrooms</b></li> */}
                                    <li>
                                      <b>
                                        {this.state.bathCount > 1
                                          ? "Bathrooms"
                                          : "Bathroom"}
                                      </b>
                                    </li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button onClick={this.decrementbath}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "minus-guest.svg"
                                          )}
                                          alt="Decrement bathroom counter"
                                          title="Decrement bathroom counter"
                                        />
                                      </button>
                                    </span>

                                    <span>{this.state.bathCount}</span>
                                    <span>
                                      <button onClick={this.incrementbath}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "plus-guest.svg"
                                          )}
                                          alt="Increment bathroom counter"
                                          title="Increment bathroom counter"
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="done"
                                    id="cnt-close"
                                    onClick={this.Setclosebath}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="search-btn">
                            {/* href={global.themeUrl + item.property_seo_slug} */}
                            <button
                              className={
                                this.state.loaderSearch ? "search_loader" : ""
                              }
                              onClick={() =>
                                this.searchRedirect("floorplan-listing")
                              }
                            >
                              {/* <a href={
                              "/floorplan-listing" +
                              this.state.queryString
                            }> */}
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "search-icon.svg"
                                )}
                                alt="Search  Button"
                                title="Floorplan Search"
                              />
                              {/* </a> */}
                            </button>
                          </div>
                          <div className="mobilesearch-btn">
                            <button
                              onClick={() =>
                                this.searchRedirect("floorplan-listing")
                              }
                            >
                              {this.state.loaderSearch ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                </>
                              ) : (
                                "Search"
                              )}
                            </button>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="stay-here" title="Stay here">
                        <div className="tab-section">
                          <div className="mobileViewbannerContent">
                            <h1>
                              1
                              {this.state.CORPORATE_HOME_SECTION_1
                                ? this.state.CORPORATE_HOME_SECTION_1[0]
                                  ? this.state.CORPORATE_HOME_SECTION_1[0].text
                                  : ""
                                : ""}{" "}
                            </h1>
                            <h4>
                              {this.state.CORPORATE_HOME_SECTION_1
                                ? this.state.CORPORATE_HOME_SECTION_1[1]
                                  ? this.state.CORPORATE_HOME_SECTION_1[1].text
                                  : ""
                                : ""}
                            </h4>
                          </div>

                          <div className="search d-flex flex-column justify-content-start align-items-start">
                            <label>Where</label>
                            <Stack>
                              <Autocomplete
                                freeSolo
                                id="autocomplete-str-listing"
                                disableClearable
                                disablePortal
                                // options={top100Films.map((option) => option.title)}
                                name="location"
                                // value={this.state.location.value}
                                value={this.state.location_name}
                                //alue={option}
                                onChange={(e, v) => {
                                  this.handleChangelocation(v);
                                }}
                                options={
                                  this.state.newcityList
                                    ? this.state.newcityList
                                    : []
                                }
                                // onChange={this.handleChangelocation}
                                // getOptionValue={option => `${ option } `}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    hiddenLabel
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                    }}
                                    placeholder="Search locations"
                                  />
                                )}
                              />
                            </Stack>
                          </div>
                          <div className="add d-flex flex-column justify-content-start align-items-start">
                            <label>Add</label>
                            <FormControl>
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={
                                  this.state.add ? this.state.add : "Furnished"
                                }
                                // disabled
                                name="add"
                                onChange={(event) =>
                                  this.changeHandlerSelect(event)
                                }
                              >
                                {/* <MenuItem value={10} selected>Furnished</MenuItem> */}
                                <MenuItem value="Furnished">Furnished</MenuItem>
                                <MenuItem value="Unfurnished">
                                  Unfurnished
                                </MenuItem>
                                {/* <MenuItem value={10} selected>Unfurnished</MenuItem> */}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="when d-flex flex-column justify-content-start align-items-start">
                            <label>When</label>
                            <Datepicker
                              controls={["calendar"]}
                              buttons={[
                                {
                                  // text: "X",
                                  icon: "material-close",
                                  handler: "cancel",
                                },
                              ]}
                              // buttons={['set', 'cancel']}
                              pages={2}
                              calendarType="month"
                              invalid={[]}
                              select="range"
                              inputComponent="input"
                              inputProps={inputProps}
                              selected={this.state.selectedDate}
                              onChange={(date) =>
                                this.handleDateChangeRange(date, "dateFour")
                              }
                              closeOnEsc={true}
                              cssClass="str_dual_calendar"
                              defaultValue={undefined}
                              // display="bottom"
                              rangeSelectMode="wizard"
                              showRangeLabels={true}
                              touchUi={false}
                              theme="ios"
                              themeVariant="light"
                              headerText=""
                              max=""
                              rangeStartLabel="Check-in"
                              rangeEndLabel="Check-out"
                              responsive={{
                                xsmall: {
                                  pages: 2,
                                  touchUi: false,
                                  calendarScroll: "horizontal",
                                  calendarSize: "2",
                                  // buttons: ['Cancel'],
                                  calendarScroll: "horizontal",

                                  refDate: Moment(new Date()).add(1, "year"),
                                },
                                medium: {
                                  // display: 'center',
                                  pages: 2,
                                  // touchUi: true
                                },
                                // custom: {
                                //   breakpoint: 850,
                                //   display: 'center',
                                // pages: 2,
                                //   touchUi: false
                                //}
                              }}
                            />
                          </div>
                          <div className="details d-flex flex-column justify-content-start align-items-start">
                            <label>Details</label>
                            <Dropdown autoClose={false}>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic-bath"
                              >
                                <TextField
                                  id="outlined-read-only-input"
                                  label=""
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  className="inputBox"
                                  placeholder="Add guests"
                                  value={this.state.guestValue}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                                  <ul>
                                    <li>
                                      <b>
                                        {this.state.adultCount > 1
                                          ? "Adults"
                                          : "Adult"}
                                      </b>
                                      <i>Age 13+</i>
                                    </li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button
                                        onClick={this.decrementAdult}
                                        disabled={
                                          this.state.adultCount === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "minus-guest.svg"
                                          )}
                                          alt="Decrement guest counter"
                                          title="Decrement guest counter"
                                        />
                                      </button>
                                    </span>
                                    <span>{this.state.adultCount}</span>
                                    <span>
                                      <button onClick={this.incrementAdult}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "plus-guest.svg"
                                          )}
                                          alt="Increment guest counter"
                                          title="Increment guest counter"
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                                  <ul>
                                    <li>
                                      <b>
                                        {this.state.childCount > 1
                                          ? "Children"
                                          : "Child"}
                                      </b>
                                      <i>Ages 2–12</i>
                                    </li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button onClick={this.decrementchild}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "minus-guest.svg"
                                          )}
                                          alt="Decrement guest counter"
                                          title="Decrement guest counter"
                                        />
                                      </button>
                                    </span>
                                    <span>{this.state.childCount}</span>
                                    <span>
                                      <button onClick={this.incrementchild}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "plus-guest.svg"
                                          )}
                                          alt="Increment guest counter"
                                          title="Increment guest counter"
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-start pd-15 pd-b-5">
                                  <ul>
                                    <li>
                                      <b>
                                        {this.state.infantCount > 1
                                          ? "Infants"
                                          : "Infant"}
                                      </b>
                                      <i>Under 2</i>
                                    </li>
                                  </ul>
                                  <div className="d-flex align-items-center countBtn">
                                    <span>
                                      <button onClick={this.decrementinfant}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "minus-guest.svg"
                                          )}
                                          alt="Decrement guest counter"
                                          title="Decrement guest counter"
                                        />
                                      </button>
                                    </span>
                                    <span>{this.state.infantCount}</span>
                                    <span>
                                      <button onClick={this.incrementinfant}>
                                        <img
                                          src={global.OnKeyPressEvent.getS3ImageUrl(
                                            "plus-guest.svg"
                                          )}
                                          alt="Increment guest counter"
                                          title="Increment guest counter"
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                {/*  <p>
                                                This place has a maximum of 6
                                                guests, not including infants. Pets
                                                aren't allowed.
                                              </p> */}
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="done"
                                    id="cnt-close"
                                    onClick={this.Setclose}
                                  >
                                    Close
                                  </button>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="search-btn">
                            <button
                              onClick={() => this.searchRedirect("str-listing")}
                            >
                              {/* <a href={
                              global.themeUrl +
                              "kini-beach-haus-las-olas" +
                              "/str-listing" +
                              this.state.queryStringltr
                            }> */}
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "search-icon.svg"
                                )}
                                alt="Search Button"
                                title="Unit Search"
                              />
                              {/* </a> */}
                            </button>
                          </div>

                          <div className="mobilesearch-btn">
                            <button
                              onClick={() => this.searchRedirect("str-listing")}
                            >
                              {this.state.loaderSearch ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner_loader"
                                  />
                                </>
                              ) : (
                                "Search"
                              )}
                            </button>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                  <h2>
                    {this.state.CORPORATE_HOME_SECTION_2
                      ? this.state.CORPORATE_HOME_SECTION_2[0]
                        ? this.state.CORPORATE_HOME_SECTION_2[0].text
                        : "Welcome home"
                      : "Welcome home"}{" "}
                  </h2>
                  <p>
                    {" "}
                    {this.state.CORPORATE_HOME_SECTION_2
                      ? this.state.CORPORATE_HOME_SECTION_2[1]
                        ? this.state.CORPORATE_HOME_SECTION_2[1].text
                        : "Book a night for a night or stay as long as you’d like."
                      : "Book a night for a night or stay as long as you’d like."}{" "}
                  </p>
                </div>
                <div className="container pd-t-50 pd-l-0 pd-r-0">
                  <div className="row">
                    {this.state.item1 &&
                    this.state.item1.hasOwnProperty("property_seo_slug") ? (
                      <>
                        <div className="col-lg-6 listItem property_first">
                          <div className="listImg">
                            <a
                              href={
                                this.state.item1.domain_city_name
                                  ? this.state.item1.domain_city_name
                                  : this.state.item1.domain_name
                                  ? this.state.item1.domain_name
                                  : global.themeUrl +
                                    this.state.item1.property_seo_slug
                              }
                            >
                              <figure>
                                <img
                                  src={
                                    this.state.item1.property_main_image_url
                                      ? this.state.item1.property_main_image_url
                                      : global.OnKeyPressEvent.getS3ImageUrl(
                                          "list-img-1.webp"
                                        )
                                  }
                                  alt="Orin Haus Image"
                                  title="Orin Haus Image"
                                />
                              </figure>
                              <span className="d-none">
                                {counter === 4 ? (counter = 0) : counter++}
                              </span>
                              <div className="content">
                                <b>
                                  {this.state.item1.property_name
                                    ? this.state.item1.property_name
                                    : ""}
                                </b>
                                <h1>
                                  {" "}
                                  {this.state.item1.city_name
                                    ? this.state.item1.city_name
                                    : ""}
                                </h1>
                                <div className="line"></div>
                                <div className="d-flex justify-content-end">
                                  <button>
                                    Details{" "}
                                    <img
                                      src={global.OnKeyPressEvent.getS3ImageUrl(
                                        "detail-arrow.svg"
                                      )}
                                      alt="Detail Arrow"
                                      title="Detail Arrow"
                                    />
                                  </button>
                                </div>
                              </div>
                              <div className="overlap"></div>
                            </a>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-6">
                      <div className="row">
                        {this.state.item2 &&
                        this.state.item2.hasOwnProperty("property_seo_slug") ? (
                          <div className="col-lg-12 listItem property_secd proerty_w_len">
                            <div className="listImg">
                              <a
                                href={
                                  this.state.item2.domain_city_name
                                    ? this.state.item2.domain_city_name
                                    : this.state.item2.domain_name
                                    ? this.state.item2.domain_name
                                    : global.themeUrl +
                                      this.state.item2.property_seo_slug
                                }
                              >
                                <figure>
                                  <img
                                    src={
                                      this.state.item2.property_main_image_url
                                        ? this.state.item2
                                            .property_main_image_url
                                        : global.OnKeyPressEvent.getS3ImageUrl(
                                            "list-img-1.webp"
                                          )
                                    }
                                    alt="Orin Haus Image"
                                    title="Orin Haus Image"
                                  />
                                </figure>
                                <span className="d-none">
                                  {counter === 4 ? (counter = 0) : counter++}
                                </span>
                                <div className="content">
                                  <b>
                                    {this.state.item2.property_name
                                      ? this.state.item2.property_name
                                      : ""}
                                  </b>
                                  <h1>
                                    {" "}
                                    {this.state.item2.city_name
                                      ? this.state.item2.city_name
                                      : ""}
                                  </h1>
                                  <div className="line"></div>
                                  <div className="d-flex justify-content-end">
                                    <button>
                                      Details{" "}
                                      <img
                                        src={global.OnKeyPressEvent.getS3ImageUrl(
                                          "detail-arrow.svg"
                                        )}
                                        alt="Detail Arrow"
                                        title="Detail Arrow"
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className="overlap"></div>
                              </a>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.resDataLength === 3 ? (
                          <React.Fragment>
                            {this.state.item3 &&
                            this.state.item3.hasOwnProperty(
                              "property_seo_slug"
                            ) ? (
                              <div className="col-lg-12 listItem property_secd proerty_w_len">
                                <div className="listImg">
                                  <a
                                    href={
                                      this.state.item3.domain_city_name
                                        ? this.state.item3.domain_city_name
                                        : this.state.item3.domain_name
                                        ? this.state.item3.domain_name
                                        : global.themeUrl +
                                          this.state.item3.property_seo_slug
                                    }
                                  >
                                    <figure>
                                      <img
                                        src={
                                          this.state.item3
                                            .property_main_image_url
                                            ? this.state.item3
                                                .property_main_image_url
                                            : global.OnKeyPressEvent.getS3ImageUrl(
                                                "list-img-1.webp"
                                              )
                                        }
                                        alt="Orin Haus Image"
                                        title="Orin Haus Image"
                                      />
                                    </figure>
                                    <span className="d-none">
                                      {counter === 4
                                        ? (counter = 0)
                                        : counter++}
                                    </span>
                                    <div className="content">
                                      <b>
                                        {this.state.item3.property_name
                                          ? this.state.item3.property_name
                                          : ""}
                                      </b>
                                      <h1>
                                        {" "}
                                        {this.state.item3.city_name
                                          ? this.state.item3.city_name
                                          : ""}
                                      </h1>
                                      <div className="line"></div>
                                      <div className="d-flex justify-content-end">
                                        <button>
                                          Details{" "}
                                          <img
                                            src={global.OnKeyPressEvent.getS3ImageUrl(
                                              "detail-arrow.svg"
                                            )}
                                            alt="Detail Arrow"
                                            title="Detail Arrow"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="overlap"></div>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {this.state.item3 &&
                            this.state.item3.hasOwnProperty(
                              "property_seo_slug"
                            ) ? (
                              <div className="col-lg-6 listItem property_secd">
                                <div className="listImg">
                                  <a
                                    href={
                                      this.state.item3.domain_city_name
                                        ? this.state.item3.domain_city_name
                                        : this.state.item3.domain_name
                                        ? this.state.item3.domain_name
                                        : global.themeUrl +
                                          this.state.item3.property_seo_slug
                                    }
                                  >
                                    <figure>
                                      <img
                                        src={
                                          this.state.item3
                                            .property_main_image_url
                                            ? this.state.item3
                                                .property_main_image_url
                                            : global.OnKeyPressEvent.getS3ImageUrl(
                                                "list-img-1.webp"
                                              )
                                        }
                                        alt="Orin Haus Image"
                                        title="Orin Haus Image"
                                      />
                                    </figure>
                                    <span className="d-none">
                                      {counter === 4
                                        ? (counter = 0)
                                        : counter++}
                                    </span>
                                    <div className="content">
                                      <b>
                                        {this.state.item3.property_name
                                          ? this.state.item3.property_name
                                          : ""}
                                      </b>
                                      <h1>
                                        {" "}
                                        {this.state.item3.city_name
                                          ? this.state.item3.city_name
                                          : ""}
                                      </h1>
                                      <div className="line"></div>
                                      <div className="d-flex justify-content-end">
                                        <button>
                                          Details{" "}
                                          <img
                                            src={global.OnKeyPressEvent.getS3ImageUrl(
                                              "detail-arrow.svg"
                                            )}
                                            alt="Detail Arrow"
                                            title="Detail Arrow"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="overlap"></div>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.item4 &&
                            this.state.item4.hasOwnProperty(
                              "property_seo_slug"
                            ) ? (
                              <div className="col-lg-6 listItem property_secd">
                                <div className="listImg">
                                  <a
                                    href={
                                      this.state.item4.domain_city_name
                                        ? this.state.item4.domain_city_name
                                        : this.state.item4.domain_name
                                        ? this.state.item4.domain_name
                                        : global.themeUrl +
                                          this.state.item4.property_seo_slug
                                    }
                                  >
                                    <figure>
                                      <img
                                        src={
                                          this.state.item4
                                            .property_main_image_url
                                            ? this.state.item4
                                                .property_main_image_url
                                            : global.OnKeyPressEvent.getS3ImageUrl(
                                                "list-img-1.webp"
                                              )
                                        }
                                        alt="Orin Haus Image"
                                        title="Orin Haus Image"
                                      />
                                    </figure>
                                    <span className="d-none">
                                      {counter === 4
                                        ? (counter = 0)
                                        : counter++}
                                    </span>
                                    <div className="content">
                                      <b>
                                        {this.state.item4.property_name
                                          ? this.state.item4.property_name
                                          : ""}
                                      </b>
                                      <h1>
                                        {" "}
                                        {this.state.item4.city_name
                                          ? this.state.item4.city_name
                                          : ""}
                                      </h1>
                                      <div className="line"></div>
                                      <div className="d-flex justify-content-end">
                                        <button>
                                          Details{" "}
                                          <img
                                            src={global.OnKeyPressEvent.getS3ImageUrl(
                                              "detail-arrow.svg"
                                            )}
                                            alt="Detail Arrow"
                                            title="Detail Arrow"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="overlap"></div>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    {this.state.resData &&
                    this.state.resData.length > 4 &&
                    !this.state.viewallproperty ? (
                      <div className="col-12">
                        <button
                          className="btn-property-view mx-auto"
                          onClick={(e) => this.openpropertyrest(e)}
                        >
                          View all
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    {this.state.finaldata && this.state.viewallproperty ? (
                      this.state.finaldata.map((item, index) => {
                        return (
                          <div className="col-lg-4 listItem">
                            <div className="listImg">
                              <a
                                href={
                                  item.domain_city_name
                                    ? item.domain_city_name
                                    : item.domain_name
                                    ? item.domain_name
                                    : global.themeUrl + item.property_seo_slug
                                }
                              >
                                <figure>
                                  <img
                                    src={
                                      item.property_main_image_url
                                        ? item.property_main_image_url
                                        : global.OnKeyPressEvent.getS3ImageUrl(
                                            "list-img-1.webp"
                                          )
                                    }
                                    alt="Orin Haus Image"
                                    title="Orin Haus Image"
                                  />
                                </figure>
                                <span className="d-none">
                                  {counter === 4 ? (counter = 0) : counter++}
                                </span>
                                <div className="content">
                                  <b>
                                    {item.property_name
                                      ? item.property_name
                                      : ""}
                                  </b>
                                  <h1>
                                    {" "}
                                    {item.city ? item.city.city_name : ""}
                                  </h1>
                                  <div className="line"></div>
                                  <div className="d-flex justify-content-end">
                                    <button>
                                      Details{" "}
                                      <img
                                        src={global.OnKeyPressEvent.getS3ImageUrl(
                                          "detail-arrow.svg"
                                        )}
                                        alt="Detail Arrow"
                                        title="Detail Arrow"
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className="overlap"></div>
                              </a>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-md-12 mg-b-60">
                        {/* <p>No unit match your filter criteria.</p> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="everthing">
              <div className="container">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="content">
                      <h2>
                        {" "}
                        {this.state.CORPORATE_HOME_SECTION_3
                          ? this.state.CORPORATE_HOME_SECTION_3[0]
                            ? this.state.CORPORATE_HOME_SECTION_3[0].text
                            : "Live or stay — it’s just a tap away"
                          : "Live or stay — it’s just a tap away"}{" "}
                      </h2>
                      <p>
                        {/* 24/7 in-app customer service
                        neighborhood guides
                        homesharing management
                        request late checkout */}
                        {this.state.CORPORATE_HOME_SECTION_3
                          ? this.state.CORPORATE_HOME_SECTION_3[1]
                            ? this.state.CORPORATE_HOME_SECTION_3[1].text
                            : ""
                          : ""}
                      </p>
                      <ul>
                        <li>
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "everything-1.svg"
                            )}
                            alt="24/7 in-app customer service"
                            title="24/7 in-app customer service"
                          />{" "}
                          <b>24/7 in-app customer service</b>
                        </li>
                        <li>
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "everything-2.svg"
                            )}
                            alt="Homesharing management"
                            title="Homesharing management"
                          />{" "}
                          <b>Homesharing management</b>
                        </li>
                        <li>
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "everything-3.svg"
                            )}
                            alt="Neighborhood guides"
                            title="Neighborhood guides"
                          />{" "}
                          <b>Neighborhood guides</b>
                        </li>
                        <li>
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "everything-4.svg"
                            )}
                            alt="Request late checkout"
                            title="Request late checkout"
                          />{" "}
                          <b>Request late checkout</b>
                        </li>
                      </ul>

                      <div className="downnloadApp d-flex mg-t-0 d-block">
                        <a
                          href="https://apps.apple.com/us/app/orion-haus/id1627695152"
                          target="blank"
                        >
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "download_apple.png"
                            )}
                            className="mg-r-30"
                            alt="Ios App Image"
                            title="Ios App Image"
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.orionhaus.haus"
                          target="blank"
                        >
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "download_android.png"
                            )}
                            alt="Android App Image"
                            title="Android App Image"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="simulatorscreen">
                      <img
                        src={
                          this.state.CORPORATE_HOME_SECTION_3
                            ? this.state.CORPORATE_HOME_SECTION_3[2]
                              ? this.state.CORPORATE_HOME_SECTION_3[2].url
                              : global.OnKeyPressEvent.getS3ImageUrl(
                                  "mobile-screen.webp"
                                )
                            : global.OnKeyPressEvent.getS3ImageUrl(
                                "mobile-screen.webp"
                              )
                        }
                        className="w-75 mobilescreen"
                        alt="Mobile App Image"
                        title="Mobile App Image"
                      />
                      <img
                        src={global.OnKeyPressEvent.getS3ImageUrl(
                          "simulator-bg-shape.svg"
                        )}
                        className="w-100 mobilescreenBg"
                        alt="Mobile App Image"
                        title="Mobile App Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="brandBlockone">
              <div className="container position-relative">
                <div className="row">
                  <div className="col-lg-8">
                    <figure>
                      <img
                        src={
                          this.state.CORPORATE_HOME_SECTION_4
                            ? this.state.CORPORATE_HOME_SECTION_4[2]
                              ? this.state.CORPORATE_HOME_SECTION_4[2].url
                              : global.OnKeyPressEvent.getS3ImageUrl(
                                  "brandblock-1.webp"
                                )
                            : global.OnKeyPressEvent.getS3ImageUrl(
                                "corporate_home_sec_4_left_side_img.webp"
                              )
                        }
                        alt="Flexible living at its best"
                        title="Flexible living at its best"
                      />
                    </figure>
                  </div>
                  <div className="col-lg-4">
                    <div className="content">
                      <h3>
                        {/* The first building that actually supports homesharing. */}
                        {this.state.CORPORATE_HOME_SECTION_4
                          ? this.state.CORPORATE_HOME_SECTION_4[0]
                            ? this.state.CORPORATE_HOME_SECTION_4[0].text
                            : "Flexible living at its best"
                          : "Flexible living at its best"}
                      </h3>
                      <p>
                        {/* Getting started is easy with our OHAI starter kit. Luxury linens like sheets and towels, high-end amenities like shampoo, conditioner, and body wash, and delicious candles help you take your listing to the next level right away. */}
                        {this.state.CORPORATE_HOME_SECTION_4
                          ? this.state.CORPORATE_HOME_SECTION_4[1]
                            ? this.state.CORPORATE_HOME_SECTION_4[1].text
                            : ""
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="brandBlocktwo">
              <div className="container position-relative">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="content">
                      <h3>
                        {this.state.CORPORATE_HOME_SECTION_5
                          ? this.state.CORPORATE_HOME_SECTION_5[0]
                            ? this.state.CORPORATE_HOME_SECTION_5[0].text
                            : "Professionally managed."
                          : "Professionally managed."}
                      </h3>
                      <p>
                        {this.state.CORPORATE_HOME_SECTION_5
                          ? this.state.CORPORATE_HOME_SECTION_5[1]
                            ? this.state.CORPORATE_HOME_SECTION_5[1].text
                            : "Our team does the heavy lifting when it’s most needed (like keeping you in the loop when your guest arrives and leaves; and automatically scheduling turnovers and sanitization)."
                          : "Our team does the heavy lifting when it’s most needed (like keeping you in the loop when your guest arrives and leaves; and automatically scheduling turnovers and sanitization)."}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <figure>
                      <img
                        src={
                          this.state.CORPORATE_HOME_SECTION_5
                            ? this.state.CORPORATE_HOME_SECTION_5[2]
                              ? this.state.CORPORATE_HOME_SECTION_5[2].url
                              : global.OnKeyPressEvent.getS3ImageUrl(
                                  "brandblock-2.webp"
                                )
                            : global.OnKeyPressEvent.getS3ImageUrl(
                                "brandblock-2.webp"
                              )
                        }
                        alt="Professionally managed"
                        title="Professionally managed"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </section>

            <section className="brandParent">
              <div className="container">
                <h2>
                  {this.state.CORPORATE_HOME_SECTION_6
                    ? this.state.CORPORATE_HOME_SECTION_6[0]
                      ? this.state.CORPORATE_HOME_SECTION_6[0].text
                      : "Live and travel on your own terms. We know flexibility is key, and we’ll help you monetize your apartment while traveling for work or heading out for the weekend."
                    : "Live and travel on your own terms. We know flexibility is key, and we’ll help you monetize your apartment while traveling for work or heading out for the weekend."}
                </h2>
              </div>

              <section className="brandBlockthree">
                <div className="container position-relative">
                  <div className="row">
                    <div className="col-lg-8">
                      <figure>
                        <img
                          src={
                            this.state.CORPORATE_HOME_SECTION_7
                              ? this.state.CORPORATE_HOME_SECTION_7[2]
                                ? this.state.CORPORATE_HOME_SECTION_7[2].url
                                : global.OnKeyPressEvent.getS3ImageUrl(
                                    "brandblock-3.webp"
                                  )
                              : global.OnKeyPressEvent.getS3ImageUrl(
                                  "brandblock-3.webp"
                                )
                          }
                          alt="Homesharing kits"
                          title="Homesharing kits"
                        />
                      </figure>
                    </div>
                    <div className="col-lg-4">
                      <div className="content">
                        <h3>
                          {this.state.CORPORATE_HOME_SECTION_7
                            ? this.state.CORPORATE_HOME_SECTION_7[0]
                              ? this.state.CORPORATE_HOME_SECTION_7[0].text
                              : "Homesharing-in-a-baux™."
                            : "Homesharing-in-a-baux™."}
                        </h3>
                        <p>
                          {this.state.CORPORATE_HOME_SECTION_7
                            ? this.state.CORPORATE_HOME_SECTION_7[1]
                              ? this.state.CORPORATE_HOME_SECTION_7[1].text
                              : "Getting started is easy with our OHAI starter kit. Luxury linens like sheets and towels, high-end amenities like shampoo, conditioner, and body wash, and delicious candles help you take your listing to the next level right away."
                            : "Getting started is easy with our OHAI starter kit. Luxury linens like sheets and towels, high-end amenities like shampoo, conditioner, and body wash, and delicious candles help you take your listing to the next level right away."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="brandBlockfour">
                <div className="container position-relative">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="content">
                        <h3>
                          {this.state.CORPORATE_HOME_SECTION_8
                            ? this.state.CORPORATE_HOME_SECTION_8[0]
                              ? this.state.CORPORATE_HOME_SECTION_8[0].text
                              : "Hosts with the mosts."
                            : "Hosts with the mosts."}
                        </h3>
                        <p>
                          {this.state.CORPORATE_HOME_SECTION_8
                            ? this.state.CORPORATE_HOME_SECTION_8[1]
                              ? this.state.CORPORATE_HOME_SECTION_8[1].text
                              : "Our hosts welcome you and your guests every time you visit, and can enhance your or your guests’ stay with insider tips about where to go and what to do."
                            : "Our hosts welcome you and your guests every time you visit, and can enhance your or your guests’ stay with insider tips about where to go and what to do."}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <figure>
                        <img
                          src={
                            this.state.CORPORATE_HOME_SECTION_8
                              ? this.state.CORPORATE_HOME_SECTION_8[2]
                                ? this.state.CORPORATE_HOME_SECTION_8[2].url
                                : global.OnKeyPressEvent.getS3ImageUrl(
                                    "brandblock-4.webp"
                                  )
                              : global.OnKeyPressEvent.getS3ImageUrl(
                                  "brandblock-4.webp"
                                )
                          }
                          alt="Hosts with the mosts"
                          title="Hosts with the mosts"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </section>
            </section>

            <section className="hostingFeatures">
              <div className="container">
                <h2>
                  {this.state.CORPORATE_HOME_SECTION_9
                    ? this.state.CORPORATE_HOME_SECTION_9[0]
                      ? this.state.CORPORATE_HOME_SECTION_9[0].text
                      : "Hosting features"
                    : "Hosting features"}
                </h2>
                <p>
                  {this.state.CORPORATE_HOME_SECTION_9
                    ? this.state.CORPORATE_HOME_SECTION_9[1]
                      ? this.state.CORPORATE_HOME_SECTION_9[1].text
                      : "OHAI amenities for you and your guests."
                    : "OHAI amenities for you and your guests."}{" "}
                </p>
              </div>
              <div className="container-fluid p-0">
                <Swiper
                  slidesPerView={1}
                  // spaceBetween={0}
                  slidesPerGroup={1}
                  loop={true}
                  loopFillGroupWithBlank={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    "@0.75": {
                      slidesPerView: 2,
                      spaceBetween: 0,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 0,
                    },
                    "@1.50": {
                      slidesPerView: 4,
                      spaceBetween: 0,
                    },
                  }}
                >
                  {this.state.CORPORATE_HOME_SECTION_10 ? (
                    <>
                      {this.state.CORPORATE_HOME_SECTION_10.map(
                        (item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div className="featuresitems">
                                <div className="listImg">
                                  <figure>
                                    <img
                                      src={
                                        item[2]
                                          ? item[2].url
                                          : global.OnKeyPressEvent.getS3ImageUrl(
                                              "feature-1.webp"
                                            )
                                      }
                                      alt="Full Time Hosts"
                                      title="Full Time Hosts"
                                    />
                                  </figure>
                                  <div className="content">
                                    <div className="content-center">
                                      <span>{item[0] ? item[0].text : ""}</span>
                                      <b>{item[1] ? item[1].text : ""}</b>
                                    </div>
                                  </div>
                                  <div className="overlap"></div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-1.webp"
                                )}
                                alt="Full Time Hosts"
                                title="Full Time Hosts"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>Full-time hosts.</span>
                                <b>
                                  Think of them as your OHAI concierge team.
                                  They’re here to make your listing sparkle, and
                                  make sure your guests have a seamless
                                  experience.{" "}
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-2.webp"
                                )}
                                alt="Digital Locks"
                                title="Digital Locks"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>Digital locks.</span>
                                <b>
                                  Contactless. Bluetooth. Digital. These locks
                                  work with your and your guests’ phones. Access
                                  and entry is super easy through your OHAI app.
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-3.webp"
                                )}
                                alt="App With Insights"
                                title="App With Insights"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>App with insights.</span>
                                <b>
                                  Earnings viewer. Integrated listing and
                                  calendar management. Automatically scheduled
                                  cleanings. Notifications when your guests
                                  arrive and leave. Business analytics dashboard
                                  to check your performance. On-site staff
                                  messaging. Events calendar. Tax payment
                                  reminders.
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-4.webp"
                                )}
                                alt="Ultimate Experiences"
                                title="Ultimate Experiences"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>Ultimate experiences.</span>
                                <b>
                                  Bike-share. Scooter-share. Moke rentals. Yacht
                                  charters. Coffee carts in the morning …and
                                  gelato carts at night (seriously).
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-5.webp"
                                )}
                                alt="Design Packages"
                                title="Design Packages"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>Design packages.</span>
                                <b>
                                  Hand-selected unique design packages for rent.
                                  Great style, and guess what? If anything gets
                                  damaged (by you or your guests) you can get a
                                  replacement to your unit in 24 hours.
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-6.webp"
                                )}
                                alt="On Site Host Supply Shop"
                                title="On Site Host Supply Shop"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>On-site host supply shop.</span>
                                <b>
                                  With anything you or your guests could need
                                  (or want…). Bed and bath linens. Scented
                                  candles and room sprays. Shampoo, conditioner,
                                  bodywash, lotion. Coffee mugs. Bottled water.
                                  Notebooks and stationery.
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-7.webp"
                                )}
                                alt="Resort Pool & Sun Deck"
                                title="Resort Pool & Sun Deck"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>Resort pool & sun deck.</span>
                                <b>
                                  The ultimate resort-style pool deck. Spa.
                                  Cabanas. Lounge chairs. Don’t forget the
                                  sunscreen.{" "}
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-8.webp"
                                )}
                                alt="Fitness Center"
                                title="Fitness Center"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>Fitness Center.</span>
                                <b>
                                  Two full sets of weights. Cables. Cardio room.
                                  Full schedule of fitness class — which can be
                                  taken in person or streamed online in your
                                  home. Free for residents and guests.
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="featuresitems">
                          <div className="listImg">
                            <figure>
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "feature-9.webp"
                                )}
                                alt="Lush Landscaping"
                                title="Lush Landscaping"
                              />
                            </figure>
                            <div className="content">
                              <div className="content-center">
                                <span>Lush landscaping.</span>
                                <b>
                                  And outdoor kitchens. Walk the grounds to the
                                  fire pits. We’ll be at the grill. You bring
                                  the fixin’s.
                                </b>
                              </div>
                            </div>
                            <div className="overlap"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  )}
                </Swiper>
              </div>
            </section>

            <section className="subscribeForm ">
              <div className="container">
                <img
                  src={global.OnKeyPressEvent.getS3ImageUrl("send-icon.svg")}
                  alt="Sign Up"
                  title="Sign Up"
                />
                <h2>
                  {this.state.CORPORATE_HOME_SECTION_11
                    ? this.state.CORPORATE_HOME_SECTION_11[0]
                      ? this.state.CORPORATE_HOME_SECTION_11[0].text
                      : "Sign me up!"
                    : "Sign me up!"}
                </h2>
                <p>
                  Homesharing-friendly apartments. Rent a place to live,
                  homeshare when you're away.
                  {/* <p>{this.state.CORPORATE_HOME_SECTION_11 ? ((this.state.CORPORATE_HOME_SECTION_11[1]) ? this.state.CORPORATE_HOME_SECTION_11[1].text : "Sign up with your email to receive updates from OHAI. We won’t spam you, we promise — just the good stuff.") : "Sign up with your email to receive updates from OHAI. We won’t spam you, we promise — just the good stuff."} */}
                </p>

                <div className="formSection">
                  <Form>
                    <Form.Group className="mb-3 d-flex flex-wrap justify-content-center group">
                      {/* <Form.Control
                        type="text" placeholder="First name" id="first_name_sub"
                        name="first_name"
                        value={this.state.first_name}
                        onChange={this.changeHandler} error={
                          this.state.errors.first_name ? true : false
                        }
                        helperText={
                          this.state.errors.first_name
                            ? this.state.errors.first_name
                            : ""
                        } />
                      <Form.Control
                        type="text" placeholder="Last name" name="last_name"
                        value={this.state.last_name}
                        onChange={this.changeHandler} />
                      <Form.Control
                        type="email" placeholder="Email" name="email"
                        value={this.state.email}
                        onBlur={this.validateEmail}
                        onChange={this.changeHandler}
                        id="email_sub" error={this.state.errors.email ? true : false}
                        helperText={
                          this.state.errors.email
                            ? this.state.errors.email
                            : ""
                        } /> */}
                      <div>
                        <TextField
                          type="text"
                          placeholder="First name"
                          id="first_name_sub"
                          name="first_name"
                          value={this.state.first_name}
                          onChange={(e) => this.changeHandler(e)}
                          error={
                            this.state.errors?.first_name
                              ? this.state.errors?.first_name
                              : ""
                          }
                          helperText={
                            this.state.errors?.first_name
                              ? this.state.errors?.first_name
                              : ""
                          }
                        />

                        {this.state.errors.first_name !== "" ? (
                          <p className="error-message">
                            {this.state.errors.first_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <TextField
                          type="text"
                          placeholder="Last name"
                          name="last_name"
                          value={this.state.last_name}
                          onChange={(e) => this.changeHandler(e)}
                        />
                        {this.state.errors.last_name !== "" ? (
                          <p className="error-message">
                            {this.state.errors.last_name}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <TextField
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={this.state.email}
                          // onBlur={() => this.validateEmail()}
                          onChange={(e) => this.changeHandler(e)}
                          id="email_sub"
                          error={this.state.errors.email ? true : false}
                          helperText={
                            this.state.errors.email
                              ? this.state.errors.email
                              : ""
                          }
                        />

                        {this.state.errors.email !== "" ? (
                          <p className="error-message">
                            {this.state.errors.email}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div>
                        <button
                          disabled={this.state.loader ? true : false}
                          onClick={(e) => this.saveNewsletter(e)}
                        >
                          {this.state.FOOTER
                            ? this.state.FOOTER[2]
                              ? this.state.FOOTER[2].text
                              : "I'm in!"
                            : "I'm in!"}
                        </button>
                      </div>
                      {/* <button
                                          hidden
                                          className="w-100"
                                          id="virtual-tour-button"
                                        >
                                          Confirm
                                        </button> */}
                      <span className="subscribemsg">
                        {this.state.succes_message ? (
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "animateCheck.svg"
                            )}
                            alt="Thank you for your subscription"
                            title="Thank you for your subscription"
                          />
                        ) : (
                          ""
                        )}
                        {this.state.succes_message}
                      </span>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </section>

            <section className="FooterAbout ">
              <div className="container">
                <div className="content">
                  <a href="#">
                    <figure>
                      <img
                        src={
                          this.state.CORPORATE_HOME_SECTION_12
                            ? this.state.CORPORATE_HOME_SECTION_12[0]
                              ? this.state.CORPORATE_HOME_SECTION_12[0].url
                              : global.OnKeyPressEvent.getS3ImageUrl(
                                  "footer-logo.png"
                                )
                            : global.OnKeyPressEvent.getS3ImageUrl(
                                "footer-logo.png"
                              )
                        }
                        alt="OHAI Logo"
                        title="OHAI Logo"
                      />
                    </figure>
                  </a>
                  <p>
                    {/* Travel, hospitality and lifestyle revolutionaries Cindy Diffenderfer and Kanan Whited proudly introduce OHAI — a revolutionary platform that turns everyday renters into real estate investors. */}
                    {this.state.CORPORATE_HOME_SECTION_12
                      ? this.state.CORPORATE_HOME_SECTION_12[1]
                        ? this.state.CORPORATE_HOME_SECTION_12[1].text
                        : this.state.description
                      : this.state.description}
                  </p>
                  <a href="/partnerships">
                    <button>Learn more</button>
                  </a>

                  {/* <ul>
                    <li><a href="https://www.instagram.com/orionhaus/" title="Instagram"><img src={instagram} target="blank" /></a></li>
                    <li><a href="https://www.facebook.com/liveorionhaus" title="Facebook" target="blank"><img src={facebook} /></a></li>
                    <li><a href="https://www.linkedin.com/company/orionhaus" title="Linkedin" target="blank"><img src={linkedin} /></a></li>
                    <li><a href="mailto:concierge@orionhaus.com" title="Mail"><img src={mail} /></a></li>
                  </ul> */}
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* Footer */}
        <footer>
          <div className="container-fluid">
            <div className="footer-bottom">
              <p>
                Ⓒ{" "}
                {this.state.FOOTER_COPY_RIGHT
                  ? this.state.FOOTER_COPY_RIGHT[0]
                    ? this.state.FOOTER_COPY_RIGHT[0].text
                    : "OHAI. All rights reserved."
                  : "OHAI. All rights reserved."}{" "}
              </p>
              <ul>
                <li>
                  <a href="/partnerships">
                    {this.state.section_header_data &&
                    this.state.section_header_data[1] &&
                    this.state.section_header_data[1].text
                      ? this.state.section_header_data[1].text
                      : "Partnerships"}
                  </a>
                </li>
                {/* <li><a href="/contact" >{this.state.section_header_data && this.state.section_header_data[2] && this.state.section_header_data[2].text ? this.state.section_header_data[2].text : 'Contact'}</a></li> */}
                <li>
                  <a href="/contact">
                    {this.state.FOOTER_COPY_RIGHT
                      ? this.state.FOOTER_COPY_RIGHT[1]
                        ? this.state.FOOTER_COPY_RIGHT[1].text
                        : "Contact"
                      : "Contact"}
                  </a>
                </li>
                <li>
                  <a href="/disclaimers">
                    {this.state.FOOTER_COPY_RIGHT
                      ? this.state.FOOTER_COPY_RIGHT[2]
                        ? this.state.FOOTER_COPY_RIGHT[2].text
                        : "Disclaimers"
                      : "Disclaimers"}
                  </a>
                </li>
                <li>
                  <a href="/privacy">Privacy</a>
                </li>
                <li>
                  <a href="/terms-condition">Terms</a>
                </li>
              </ul>
            </div>
          </div>
          <img
            onClick={() => this.scrollTop()}
            className="top-arrow"
            src={global.OnKeyPressEvent.getS3ImageUrl("top-arrow.svg")}
            title="top-arrow"
            alt="top-arrow"
          />
        </footer>

        {
          // this.state.FOOTER_COOKIES &&
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myAwesomeCookieName2"
            style={{
              background: "#EEEDEB",
              color: "#000",
              boxShadow: "0 0 4px 0px #000",
            }}
            expires={150}
          >
            {this.state.FOOTER_COOKIES
              ? this.state.FOOTER_COOKIES[0]
                ? this.state.FOOTER_COOKIES[0].text
                : ""
              : "This site uses cookies to provide you with a great user experience. By using OHAI, you accept our use of cookies."}
          </CookieConsent>
        }
        {/* <LiveChat liveChateEnable={true} /> */}
      </main>
    );
  }
}
export default CorporateIndex;
