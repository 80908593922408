
import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
class MultifamilyPartnershipsFirst extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };
    render() {
        return (
            <div>
                <main className="oh-template Brandmainpage ">
                    <CommonHeader />

                    {/* <section className="multifamily-banner">
                        <div className="container d-flex align-items-center flex-column justify-content-center h-100 w-100 pd-l-50 pd-r-50">
                            <h1>How to Stabilize Rent in a Down Market</h1>
                            <h4 className="m-0">Allowing residents to short-term rent their apartment, increases NOI and rent stabilization.</h4>
                        </div>
                    </section> */}

                    <section className="multifamily-sec">
                        <div className="container">
                            <div className="breadcumbs">
                                <ul>
                                    <li> <a href="/">Home</a> </li>
                                    <li> / <a href="/partnerships">Multifamily partnerships</a></li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-md-8 col-xs-12 pad-ct-r-0">
                                    <div className="multifamily-left">
                                        <div className="multi-head mg-b-30">
                                            <h2>How to minimize risk in your Airbnb-friendly apartment building?</h2>
                                            {/* <span>Allowing residents to short-term rent their apartment, increases NOI and rent stabilization.</span> */}
                                        </div>
                                        <div className="multi-cnt">
                                            <p>If you own an apartment building, you already know. Your tenants are listing their apartments on Airbnb. Luggage-toting transients are coming and going. The amenities are more crowded than they used to be. Your building has more parties, noise and cigarette smoke than it used to. And worst of all, your tenants are making a fortune, while transferring risk onto you.</p>
                                            <p>What can you do about this? There are two possible outcomes: crack down on Airbnb activity, or find a way to allow it in a way that benefits all.</p>
                                            <p>Cracking down is a difficult task. Monitoring online travel agencies for listings at your address requires a lot of work. Additionally, evicting good paying tenants is financially unwise in our current environment of economic uncertainty. The optimal course of action is to allow these activities to continue, but in a way that benefits the property. Here are the three primary ways to do this:</p>
                                            <ol>
                                                <li>Allow short term rental activities and look the other way. Don’t change the status quo. Allow transient behavior and assume the associated risks. Is this a good path forward?</li>
                                                <li>Partner with the Airbnb friendly buildings program. The Airbnb friendly buildings program is attractive in that it allows building owners to participate in the tenant-generated short term rental revenue. Perhaps this revenue opportunity offsets the risk and potential chaos of being a publicly announced short term rental-friendly building… but is it enough?</li>
                                                <li>Partner with OHAI. OHAI allows building owners to participate in short term rental revenue, but does so in a risk-averse environment. OHAI controls check-ins/check-outs, digital access points, key transfers, room cleanings/turnovers and guest customer service. OHAI also controls all tenant listings, through its proprietary app, allowing OHAI to monitor problematic guests and hosts. Other services such as identity verification, smoke monitoring and noise monitoring are available to further control the harmony between short term and long term renters.</li>
                                            </ol>
                                            <p>Financially speaking, short term rentals should be a financial win for tenants, building owners and guests alike. That said, building owners have obligations to their tenants to provide a clean, safe and friendly environment. OHAI provides the best-in-class solution to both maximize financial performance and mitigate risk.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-12 position-relative">
                                    <div className="multifamily-right">
                                        <div className="multi-need">
                                            <h4>Want to get in touch ?</h4>
                                            {/* <p>We'll start with some questions get you to the right place.</p> */}
                                            <a href="mailto:sales@orionhaus.com" className=""><button  >Contact sales</button></a>
                                            <div className="multi-address">
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('person.svg')} alt="" />  Rob Landers</p>
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('phone.svg')} alt="" /> <a href="tel:404-271-7680"> 404-271-7680</a></p>
                                                <p> <img src={global.OnKeyPressEvent.getS3ImageUrl('email.svg')} alt="" /> <a href="mailto:rob@orionhaus.com">rob@orionhaus.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="expl_artls_sec expl_inner_detail_sec">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="artls_head">
                                            <h2 className="art_heading">
                                                Explore resource center articles
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/how-to-stabilize-rent-in-a-down-market" className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('how-to-stabilize-rent.webp')} alt="" title="How to stabilize rent in a down market?" />
                                                    </figure>
                                                </div>
                                                <p>How to stabilize rent in a down market?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <a href="/resourcecenter/multifamily/Is-my-apartment-building-a-good-fit-for-airbnb-and-vrbo-activity" className="btn-artls">
                                            <div className="artls_box">
                                                <div className="artls_img">
                                                    <figure>
                                                        <img src={global.OnKeyPressEvent.getS3ImageUrl('is-my-apartment-building.webp')} alt="" title="Is my apartment building a good fit for Airbnb and Vrbo activity?" />
                                                    </figure>
                                                </div>
                                                <p>Is my apartment building a good fit for Airbnb and Vrbo activity?</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="artls_more">
                                            <button>More articles</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    <CommonFooter />
                </main>
            </div>
        )
    }
}

export default MultifamilyPartnershipsFirst;