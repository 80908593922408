////////////////////////////////////////////////////////////
//     							                          //
//  Program: Timer.jsx                                    //
//  Application: commpon                                  //
//  Option: For countdown timer into project              //
//  Developer: JJ  						                   //
//  Date: 2023-09-20                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from 'react';

class TimerSupport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            start: Date.now(),
            diff: 0,
            minutes: 0,
            seconds: 0,
            duration: 60 * this.props.minutes,
            resend: false,
            message:this.props.message,
            keycodemessage:this.props.keycodemessage,
            keycode:this.props.keycode,
            checkininstruction:this.props.checkininstruction,
            checkoutinstruction:this.props.checkoutinstruction,
            confirmationCode:this.props.confirmationCode,
            popup:this.props.popup,
            stillnotwork:this.props.stillnotwork,
            supporturl:this.props.supporturl,
        }
        this.timerState = '';
        this.timerCount = '';
        this.count = 0;
        this.resendOTP = this.resendOTP.bind(this);
    }

    componentDidMount() {
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(() => {
            this.timer();
        }, 1000)
    }

    starttimer() {
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(() => {
            this.timer();
        }, 1000)
    }

    componentWillUnmount() {
        window.clearInterval(this.timerCount);
    }

    timer() {
        let diff = this.state.diff;
        let minutes = this.state.minutes;
        let seconds = this.state.seconds;

        diff = this.state.duration - (((Date.now() - this.state.start) / 1000) | 0);
        minutes = (diff / 60) | 0;
        seconds = (diff % 60) | 0;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.setState({ minutes: minutes, seconds: seconds });

        if (diff <= 0) {
            this.setState({ resend: true });
            window.clearInterval(this.timerCount);
        }
    }

    resendOTP(evt) {
        this.setState({ start: Date.now(), minutes: 0, seconds: 0, duration: 60 * this.props.minutes });
        let isSuccess = this.props.resendEvent();
        if (isSuccess) {
            this.setState({ resend: false });
        }
        this.starttimer();
        evt.preventDefault();
    }

    render() {
        return (
            <div className="az-signin-footer reservationBook resend">
                {this.state.resend ? <h4 className="text-center support_cnt_font">{this.state.keycodemessage}: <span className='access_code_support'>{this.state.keycode}</span></h4>
                
                    : <h4 className="timerText text-center">{this.state.message} <span>{this.state.minutes}:{this.state.seconds}</span></h4>
                }
                {this.state.popup == 2 && this.state.resend ? (
                    <>
                <div className="cancellations">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.checkoutinstruction,
                      }}
                    ></p>
                  </div>
                  <div className="access_btn_box">
                  <a href={this.state.supporturl} className='text-decoration-none'>
                      <button id="book-tour-page" className="btn_pre_access">
                       {this.state.stillnotwork}
                      </button></a>
                      </div>
                      </>
                  ):(
                   ""
                  )}
                  {this.state.popup == 1 && this.state.resend ? (
               <>
               <div className="cancellations">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.checkininstruction,
                      }}
                    ></p>
                  </div>
                      <div className="access_btn_box">
                      <a href={this.state.supporturl} className='text-decoration-none'>
                          <button id="book-tour-page" className="btn_pre_access">
                           {this.state.stillnotwork}
                          </button></a>
                          </div>
                          </>
                          ):(
                    ""
                  )}
            </div>
            
        );
    }
}

export default TimerSupport;
