//     							                          //
//  Program: support.jsx                             //
//  Application: KINI HTML                                //
//  Option: Tech Support                                //
//  Developer: JJ		                                  //
//  Date: 2023-04-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReservationService from "../../services/ReservationService";
import CommonHeader from "../common/CommonHeader";
import SpinnerLoader from "../common/SpinnerLoader";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import Accordion from "react-bootstrap/Accordion";
const queryParams = queryString.parse(location.search);
class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      openFaqsModal: false,
      params:
        queryParams && queryParams.params
          ? queryParams.params.toLowerCase()
          : props.params,
      input: {},
      errors: {},
      loader: false,
      totalRecordsfaq: props.totalRecordsfaq ? props.totalRecordsfaq : "",
      listfaq: props.listfaq ? props.listfaq : "",
      confirmation_code: props.confirmationCode
        ? props.confirmationCode
        : queryParams.confirmation_code
        ? queryParams.confirmation_code
        : "",
      slug_help:
        props.match && props.match.params
          ? props.match.params.slug
            ? props.match.params.slug
            : ""
          : "",
      description: queryParams.description
        ? queryParams.description
        : props.latch_description,
      title: null,
      propertyname:
        queryParams && queryParams.propertyname
          ? queryParams.propertyname
          : props.propertyname,
      residentname:
        queryParams && queryParams.username
          ? queryParams.username
          : props.username,
      unitname:
        queryParams && queryParams.unitname
          ? queryParams.unitname
          : props.unitname,
      user_slug:
        queryParams && queryParams.userslug
          ? queryParams.userslug
          : props.userslug,
      cleaner: queryParams && queryParams.cleaner ? queryParams.cleaner : "",
    };
  }

  componentDidMount() {
    this.getFaqDetail();
  }

  openFaqsModal = (val = false, title, description) => {
    this.setState({
      openFaqsModal: val,
      title: title,
      description: description,
    });
  };

  getFaqDetail = async () => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecordsfaq = 0;
    var listfaq = [];
    var queryString = "";
    if (this.state.params === "latch") {
      queryString = "Access Support-Latch";
    } else if (this.state.params === "keycafe") {
      queryString = "Access Support-Keycafe";
    } else {
      queryString = "Access Support-Latch";
    }
    let res = await ReservationService.getFaqdataSupport(queryString);
    //let res = await ReservationService.getFaqdataSupport();
    if (global.successStatus.includes(res.status)) {
      listfaq = res.data ? res.data?.data : [];
      totalRecordsfaq = listfaq ? listfaq.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      listfaq: listfaq,
      totalRecordsfaq: totalRecordsfaq,
    });
  };

  render() {
    let counter = 0;
    let residenturl = "/support-form";
    let supporturl = "/support-form";
    if (this.state.confirmation_code) {
      supporturl =
        supporturl + "?confirmation_code=" + this.state.confirmation_code;
    }
    if (this.state.description) {
      supporturl = supporturl + "&description=" + this.state.description;
    }
    if (this.state.residentname) {
      residenturl = residenturl + "?username=" + this.state.residentname;
    }
    if (this.state.params) {
      residenturl = residenturl + "&locktype=" + this.state.params;
    }
    if (this.state.propertyname) {
      residenturl = residenturl + "&propertyname=" + this.state.propertyname;
    }
    if (this.state.user_slug) {
      residenturl = residenturl + "&userslug=" + this.state.user_slug;
    }
    return (
      <main className="oh-template Brandmainpage wizard_header wzard_main_bg supoort_faq_feed">
        <CommonHeader propertySlug={this.state.property_slug} />
        <section className="wizard_sec">
          <div className="row- wizard_row m-0 mobView">
            <div className="wizard_box tech_sec_box">
              <div className="wizard-data req_book_secw-zaed">
                <div className="container-fluid p-0">
                  <section className="wzrd_main_section">
                    <div className="container">
                      <div className="content ">
                        <div className="row mobView wizard_new_heading">
                          <div className="wzrd_middle_desk support_btm_btn">
                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                              <div className="wzrd_heading_box col-12 m-0">
                                <h4>Access Support</h4>
                              </div>
                            </div>
                            {this.state.totalRecordsfaq > 0 ? (
                              <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                                <div className="requestSection mg-t-20 doitlocal">
                                  <div className="row book_app_info m-0 scroll_phn_safari ">
                                    <div className="col-12 p-0">
                                      <div className="cancellations reservation_faq tech_fst_support border-0">
                                        <h3 className="tech-spt-title">
                                          Frequently asked questions
                                        </h3>
                                        <div className="tech_sup_faq">
                                          {/* <ul> */}
                                          {this.state.listfaq &&
                                            this.state.listfaq.length > 0 &&
                                            this.state.listfaq.map(
                                              (value, index) => {
                                                // if (counter <= 2) {
                                                return (
                                                  // <>
                                                  //     <li key={index} >
                                                  //         <a href={void (0)} onClick={() =>
                                                  //             this.openFaqsModal(true, value.title, value.description)
                                                  //         }>
                                                  //             <span className="d-none">
                                                  //                 {counter++}
                                                  //             </span>
                                                  //             <div className="faq_ques">{value.title ? value.title : ""}</div>
                                                  //             <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" />
                                                  //         </a>
                                                  //     </li>
                                                  // </>
                                                  <Accordion defaultActiveKey="0">
                                                    <>
                                                      <Accordion.Item
                                                        eventKey={
                                                          index == 0 ? "0" : ""
                                                        }
                                                      >
                                                        <Accordion.Header>
                                                          <h5>
                                                            {value.title
                                                              ? value.title
                                                              : ""}{" "}
                                                          </h5>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                          <p
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                value.description,
                                                            }}
                                                          ></p>
                                                        </Accordion.Body>
                                                      </Accordion.Item>
                                                    </>
                                                  </Accordion>
                                                );
                                                // }
                                              }
                                            )}
                                          {/* </ul> */}
                                        </div>

                                        {this.state.totalRecordsfaq > 2 ? (
                                          <div className="d-flex">
                                            <b
                                              className="getdirection showmore mg-t-30"
                                              onClick={() =>
                                                this.openResFaqModal(true)
                                              }
                                            >
                                              Show more
                                            </b>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {!this.state.cleaner ? (
                      <div className="step_btns suppot_btn_sec">
                        {this.state.residentname ? (
                          <a
                            href={residenturl}
                            className="text-decoration-none p-0 support_faq_btns"
                          >
                            <button className="btn_next_wzd tect_btn_sub">
                              Send us a message
                            </button>
                          </a>
                        ) : (
                          <a
                            href={supporturl}
                            className="text-decoration-none p-0 support_faq_btns"
                          >
                            <button className="btn_next_wzd tect_btn_sub">
                              Send us a message
                            </button>
                          </a>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={this.state.openFaqsModal}
          centered
          dialogClassName="modal-lg"
          className="ShareModal estimatePotential"
          onHide={() => this.openFaqsModal()}
        >
          <div className="d-flex justify-content-between pd-l-20 pd-r-20 pd-b-15 mobile">
            <h5 className="social-link-main-heding-div">
              <div className="title"></div>
            </h5>
            <span
              className="welcomeClose float-end mg-t-15"
              onClick={() => this.openFaqsModal(false)}
            >
              {global.closee}
            </span>
          </div>
          <Modal.Body className="width_402 p-4 border_radius_14 pt-0">
            <div className="">
              <div className="cancellations reservation_faq border-0">
                <div className="reser_faq_box">
                  <h5>{this.state.title}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.state.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}
export default Support;
