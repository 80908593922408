///////////////////////////////////////////////
//     							             //
//  Program: ApplicantsService.js                 //
//  Application: Services                    //
//  Option: Used for Applicants data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import CmsService from "./CmsService";
import ApiService from "./ApiService";

class CmsDataService {
  getCmsData(slug, level = 1, levelSlug = 1) {
    let endPoint =
      "get-cms-manage-page-section-details/" + slug + "/" + level + "/" + levelSlug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  getCmsMetaData(slug, level = 1, levelSlug = 1) {
    let endPoint =
      "get-cms-meta-data/" + slug + "/" + level + "/" + levelSlug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  getclientToken(data) {
    let endPoint = "client-token";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getParkingclientToken(data) {
    let endPoint = "parking-client-token";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  // saveTransaction(data) {
  //   // Delete data which we don't want to send
  //   delete data.errors;
  //   delete data.input;

  //   let endPoint = "braintree-transaction";
  //   let addditionalHeaderData = {};
  //   let postData = data;

  //   let inputData = {
  //     endPoint: endPoint,
  //     addditionalHeaderData: addditionalHeaderData,
  //     postData: postData,
  //   };
  //   let response = CmsService.postCMSCall(inputData);
  //   return response;
  // }
  saveTransaction(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "stripe-payment";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  updateIntent(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "stripe-payment-update";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }


  getTransactionData(data) {
    let endPoint = "get-reservation-information";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.postCMSCall(inputData);
    return response;
  }

  getAmenitylist(slug) {
    let endPoint =
      "get-property-amenities/" +
      slug + "/" + 1;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }
  getBrandData(data) {
    let endPoint =
      "get-property-with-brand";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.postCMSCall(inputData);
    return response;
  }

  getBrandCmsData(slug, queryString) {
    let endPoint = "get-cms-manage-page-section-details/" + slug + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = CmsService.getCMSCall(inputData);
    return response;
  }

  getNotificationData(queryString) {
    let endPoint =
      "notification" + "?request_type=3" + (queryString ? "&user_slug=" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getNotificationCount(queryString) {
    let endPoint =
      "notification-count" + "?request_type=3" + (queryString ? "&user_slug=" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getNotificationClearData(queryString) {
    let endPoint =
      "notification-clear-all" + (queryString ? queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getNotificationReadData(queryString) {
    let endPoint =
      "notification-read" + (queryString !== "" ? queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getcardData(customerId) {
    let endPoint =
      "get-saved-card/" + customerId;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  gethelpData(slug) {
    let endPoint = "help/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getResourceData(slug) {
    let endPoint =
      "v3/get-resource-details/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getInsightData(slug) {
    let endPoint =
      "v3/get-insight-details/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  saveSupportData(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "save-support";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getCardclientToken(data) {
    let endPoint = "card-client-token";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  
  updateIntentStripeCard(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "stripe-payment-update-card";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new CmsDataService();
