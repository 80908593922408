//     							                        /  //
//  Program: virtualTourConfirmation.jsx                   //
//  Application: KINI HTML                                 //
//  Option: Confirmation virtual tour                      //
//  Developer: P,NP	                                       //
//  Date: 2022-06-09                                       //
//                                                         //
/////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";

class VirtualTourConfirmation extends Component {
    constructor(props) {
        super(props);
        // for aleart
        this.state = {
            property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
        }
    }

  render() {

    return (
        <main className="oh-template Brandmainpage">
            {/* Header */}
            {/* <CommonHeader propertySlug={this.state.property_slug}  /> */}
            <CommonHeader propertySlug={this.state.property_slug} />
             {/* middle */}
            <section className="middle">
                    <div className="container-fluid p-0">
                         <section className="ConfirmationBooking pd-t-60 pd-b-20">
                                <div className="container pd-b-60">
                                    <div className="content">

                                    <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')}  alt="Virtual Tour Confirmed" title="Virtual Tour Confirmed" className="mg-b-20" />
                                    <h4 className="mg-b-15"> Virtual Tour Confirmed </h4>
                                    <p>We have also sent you the link on your email. </p>
                                     <div className="d-flex justify-content-center flex-column align-items-center mg-t-30">
                                        {/* need to add link for 360 virtual tour */}
                                        {/* <button>View Virtual Tour</button> */}
                                     </div>


                                    </div>
                                </div>
                         </section>
                    </div>
            </section>

            {/* Footer */}
            <CommonFooter propertySlug={this.state.property_slug} />
        </main>
    );
  }
}
export default VirtualTourConfirmation