///////////////////////////////////////////////
//     							             //
//  Program: ApplicantsService.js                 //
//  Application: Services                    //
//  Option: Used for Applicants data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-02-09                         //
//                                           //
///////////////////////////////////////////////

import CmsService from "./CmsService";

class ApplicantsService {

    createApplicantsFromWebsite(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "applicant";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
    }
    createsubscriberFromWebsite(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "subscriber";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
    }
    createContact(data) {
        // Delete data which we don't want to send
        delete data.errors;
        delete data.input;

        let endPoint = "contact-us";
        let addditionalHeaderData = {};
        let postData = data;

        let inputData = {
            endPoint: endPoint,
            addditionalHeaderData: addditionalHeaderData,
            postData: postData,
        };
        let response = CmsService.postCMSCall(inputData);
        return response;
    }
}

export default new ApplicantsService();
