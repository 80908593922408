//     							                          //
//  Program: bookingconfirmation.jsx                      //
//  Application: KINI HTML                                //
//  Option: Confirmation Booking                          //
//  Developer: PS		                                  //
//  Date: 2022-05-31                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import ReservationReceipt from "./ReservationReceipt";
import queryString from "query-string";
import { Helmet } from "react-helmet-async";
const queryParams = queryString.parse(location.search);

class BookingConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      startDate: localStorage.getItem("checkin") ? localStorage.getItem("checkin") : queryParams.checkin,
      endDate: localStorage.getItem("checkout") ? localStorage.getItem("checkout") : queryParams.checkout,
    };
    // require("../../../assets/scss/kini_html.scss")
    // this.createQueryString = this.createQueryString.bind(this);
  }

  render() {

    return (
      <main className="oh-template Brandmainpage">
        {/* Header */}
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <section className="middle">
          <div className="container-fluid p-0">

            <section className="">
              <div className="container-fluid p-0">
                <div className="content">

                  {/* <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} className="mg-b-20" />
                  <h4 className="mg-b-15"> Booking Confirmed </h4>
                  <p>Thank You! Your Booking has been successfully created. </p> */}
                  <ReservationReceipt></ReservationReceipt>
                  {/* <div className="d-flex justify-content-center flex-column align-items-center confirmbookingbtn">
                    <a href={global.themeUrl + localStorage.getItem("propertySlug") + "/str-listing"} className="text-decoration-none" rel="noopener noreferrer"><button>Make another booking</button></a>
                  </div> */}



                </div>
              </div>
            </section>



            {/* new_design */}
            {/* <section className="reservationBook req_book_sec">
              <div className="container ">
                <div className="content">
                  <div className="row mobView">
                    <div className="col-lg-1 "></div>
                    <div className=" col-sm-7 col-lg-6">

                      <div className="requestSection confirm_booking">
                        <div className="row book_app_info confirm-symbl">
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} />
                          <div className="confirm_text">
                            <h4> Reservation Confirmed </h4>
                            <p>Thank You! Your reservation has been successfully created. </p>
                          </div>
                        </div>
                        <div className="row book_app_info">
                          <div className="col-12 pd-l-0 "><span className="subhead">Reservation Details</span></div>
                          <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <label>Check-In</label>
                              <p>11/24/22 | 03:00 PM</p>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <label>Check-out</label>
                              <p>15/24/22 | 03:00 PM</p>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <label>Number of Guests</label>
                              <p>1</p>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <label>Number of Nights</label>
                              <p>1</p>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <label>Confirmation Code</label>
                              <p>HA-D35KS9</p>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <label>Unit Name</label>
                              <p>TMP-117</p>
                            </div>
                          </div>

                          <div className="col-xs-12 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <label>Get Direction</label>
                              <p><a href="#" className="btn_location">20815, Frederal Hway #22700 North Carolina New York New York United State</a></p>
                            </div>
                          </div>

                        </div>
                        <div className="row book_pay_info">
                          <div className="requestSection">
                            <div className="row">
                              <div className="col-12 pd-l-0"><span className="subhead m-0">Guest Details</span></div>
                              <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15  mg-t-20">
                                <div className="confirm-detail">
                                  <label>Name</label>
                                  <p>Admin E2logy</p>
                                </div>
                              </div>

                              <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-t-20">
                                <div className="confirm-detail">
                                  <label>Email</label>
                                  <p>admin@e2logy</p>
                                </div>
                              </div>

                              <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15  mg-t-20">
                                <div className="confirm-detail">
                                  <label>Address</label>
                                  <p></p>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-sm-5 col-lg-4 position-relative">
                      <div className="requestaddedSection m-0 confirm_recpt">
                        <figure><img src="/static/media/aminity-list-1.b90f6c6793e7173c1c10.jpg" className="img-fluid" alt="Property Logo" title="Property Logo" /></figure>
                        <div className="priceInfo">
                          <div className="priceTop">
                            <span className="subhead m-0">Payment Details</span>
                          </div>
                          <div className="priceMiddle">
                            <ul>
                              <li><span>$488 x 1 Night</span><span>$488</span></li>
                              <li><span>Service fee</span><span>$200</span></li>

                              <li><span>Cleaning fee</span><span>$100</span></li>
                              <li><span>Occupancy taxes</span><span>$102.44</span></li>
                              <li><span>Convenience fee</span><span>$100</span></li>
                            </ul>
                          </div>
                          <div className="priceBottom">
                            <ul>
                              <li><span>Total</span><b><span>$990.44</span></b></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-1 "></div>
                    <div className="col-lg-11">
                      <button className="mg-t-20 crmf_btn" >See your reservations</button>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}



          </div>
        </section>

        {/* Footer */}

        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default BookingConfirmation