import React, { Component } from "react";
import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { Spinner } from "react-bootstrap";
import CmsDataService from "../../services/CmsDataService";
import Moment from "moment";
import ReservationService from "../../services/ReservationService";
import SpinnerLoader from "../common/SpinnerLoader";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      errors: {},
    };
    this.removeMessage = this.removeMessage.bind(this);
  }
  clearLocalStorage = () => {
    localStorage.removeItem("pricing");
    localStorage.removeItem("checkin");
    localStorage.removeItem("checkout");
    localStorage.removeItem("guest_email");
    localStorage.removeItem("userToken");
    localStorage.removeItem("reservationDetails");
    localStorage.removeItem("listing_slug");
    localStorage.removeItem("listing_name");
    localStorage.removeItem("queryParams");
  };
  // async componentDidMount() {
  //  this.getReservationDetails();
  //   // if (this.state.reservation_confirm !== "") {
  //   //   // var unit = document.getElementById("go-to-the-home-page");
  //   //   var unit = document.getElementById("booking-success");
  //   //   unit.click();
  //   // }
  // }
 removeMessage = async() => {
  await this.setState({failedmessage:""})
 }
  SaveTransactiondata = async (event) => {
    var inputDatadate = {
      listing_slug: this.props.listing_slug,
       checkin_date: this.props.startDate,
       checkout_date: this.props.endDate,
       user_token: this.props.user_token,
       level: 5
  }
    let resdate = await ReservationService.getAvailibityDate(inputDatadate);
    if (resdate.status === 422) {
      this.setState({ loader: false,date_reserved:"dates are already reserved" });
    }
    var message = "";
    event.preventDefault();
    this.setState({ showSpinner: true, loading: true, loader: true});
     
      const stripe = this.props.stripe;
      const elements = this.props.elements;

      if (!stripe || !elements) {
        this.setState({loader: false});
        return;
      }
      const result = await stripe.confirmPayment({
        //   //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
        // metadata: {'order_id': this.props.reservation_slug
        // ? this.props.reservation_slug
        // : ""},
        //   confirmParams: {
        //     return_url: global.themeUrl + this.state.property_slug + "/booking-confirmation",
        //  },
      });
      if (result.error) {
        // var decline_code = result.error.decline_code;
        var message = result.error.message;
        var decline_message = message;
        this.setState({failedmessage: decline_message });
        this.setState({loader: false});
      }
      let inputData = {
        guest_slug: this.props.guest_slug ? this.props.guest_slug : "",
        // nonce: this.props.nonce ? this.props.nonce : "",
        reservation_slug: this.props.reservation_slug
          ? this.props.reservation_slug
          : "",
        street_address_1: this.props.street_address_1
          ? this.props.street_address_1
          : "",
        phone_number: this.props.phone_number,
        street_address_2: this.props.street_address_2
          ? this.props.street_address_2
          : "",
        city: this.props.city ? this.props.city : "",
        contry: this.props.country ? this.props.country : "",
        state: this.props.state ? this.props.state : "",
        zipcode: this.props.zipcode ? this.props.zipcode : "",
        checkin_date:this.props.startDate,
        checkout_date:this.props.endDate,
        address: this.props.address,
        stripe_id: this.props.stripe_id,
        elements: this.props.elements,
      };
      this.setState({ showSpinner: true, loading: true });
      var resData = {};
      var resDataArr = [];
      //  let res =  CmsDataService.saveTransaction(inputData);
      if (global.successStatus.includes(resdate.status) && result.paymentIntent.status === "succeeded") {
        var address = this.props.street_address_1
          ? this.props.street_address_1 + ","
          : "";
        address += this.props.street_address_2
          ? this.props.street_address_2 + ","
          : "";
        address += this.props.country ? this.props.country + "," : "";
        address += this.props.state ? this.props.state + "," : "";
        address += this.props.zipcode ? this.props.zipcode + "." : "";

        var reservationData = {
          reservation_slug: this.props.reservation_slug,
          guest_slug: this.props.guest_slug,
          payment_status: 2,
          user_token: this.props.guest_slug,
          phone_number: this.props.phone_number,
          street_address_1: this.props.street_address_1
            ? this.props.street_address_1
            : "",
          street_address_2: this.props.street_address_2
            ? this.props.street_address_2
            : "",
          city: this.props.cityId ? this.props.cityId : "",
          checkin_date:this.props.startDate,
          checkout_date:this.props.endDate,
          contry: this.props.countryId ? this.props.countryId : "",
          state: this.props.stateId ? this.props.stateId : "",
          zipcode: this.props.zipcode ? this.props.zipcode : "",
          address: address,
          adults: this.props.adults ? this.props.adults : 1,
          children: this.props.children ? this.props.children : 0,
          infants: this.props.infants ? this.props.infants : 0,
          total_selected_guest:this.props.total_selected_guest,
        };
        localStorage.setItem("addressDetails", JSON.stringify(reservationData));
        localStorage.setItem("reservation_confirm", this.props.guest_slug);
        let res =  await ReservationService.updateReservationData(reservationData);
        await this.clearLocalStorage();
        var stripeData = { result, reservationData };
        let resultTransaction = await CmsDataService.saveTransaction(stripeData);
        await this.getReservationDetails();
        resDataArr = res.data ? res.data : [];
        await this.setState({
          transactionData: resDataArr,
        });
        this.setState({loader: false});
        var unit = document.getElementById("booking-success");
        unit.click();
      } else {
        this.setState({ failedmessage: "Payment Failed."});
        this.setState({loader: false});
      }
    // } else {
    //   var errors = {};
    //   if (this.props.street_address_1 === "") {
    //     errors.street_address_1 = "Street name is required";
    //   }
    //   if (!this.props.city) {
    //     errors.city = "City name is required";
    //   }
    //   if (!this.props.state) {
    //     errors.state = "State name is required";
    //   }
    //   if (!this.props.country) {
    //     errors.country = "Country name is required";
    //   }
    //   if (!this.props.zipcode) {
    //     errors.zipcode = "Zipcode is required";
    //   }
    //   if (!this.props.phone_number) {
    //     errors.phone_number = "Phone number is required";
    //   }
    //   this.props.validationErrorsHandler(errors);
    //   this.setState({loader: false })
    //   // this.setState({ : errors });
    // }
  };

  getReservationDetails = async () => {
    var list = [];
    var record = [];
    var totalRecords = 0;
    this.setState({ loader: true });
    
      var data = {
        confirmation_code: this.props.confirmation_code,
        last_name: this.props.last_name,
        property_seo_slug: this.props.propertySlug,
        user_slug: "",
        filter_by: 4,
      };
      let res = await ReservationService.getMyBookReservation(data);
      // localStorage.setItem("trip_details", JSON.stringify(res));
      // let res = this.state.res;
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {

        list = res.data ? res.data : [];
        record = res.data ? res.data : [];
        this.setState({ loader: false });
        // totalRecords = list ? list.length : 0;
        totalRecords = res.data ? res.data.total : 0;
      }
      if (global.successStatus.includes(res.status) && res.data.length !== 0) {
      let statusText = "pending";
      
        const currentDate = new Date();
        var dateFrom = Moment(record.checkin_date).format("MM/DD/YYYY");
        var dateTo = Moment(record.checkout_date).format("MM/DD/YYYY");
        var dateCheck = Moment(currentDate).format("MM/DD/YYYY");
        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("/");
        if (record.status_id === 1 && c >= d1 && c <= d2) {
          statusText = "Confirmed";
        } else if (
          record.status_id !== 2 &&
          record.set_channel === 1 &&
          record.payment_status === 2 &&
          dateCheck > dateTo
        ) {
          statusText = "Confirmed";
        } else if (
          record.status_id !== 2 &&
          record.set_channel === 2 &&
          dateCheck > dateTo
        ) {
          statusText = "Completed";
        } else {
          if (record.set_channel === 1) {
            statusText =
              record.cancel_status === 0 && record.payment_status === 2
                ? "Confirmed"
                : record.cancel_status === 0 && record.status_id === 1
                ? "Payment Pending"
                : record.cancel_status === 1
                ? "Cancelled"
                : "Failed";
          }
          if (record.set_channel === 2) {
            statusText =
              record.status_id === 1 || record.cancel_status === 0
                ? "Confirmed"
                : "Failed";
          }
        }
        record.status_text = statusText;
        record.baged_class =
          statusText === "Confirmed"
            ? "baged-confirm"
            : statusText === "Payment Pending"
            ? "baged-pending"
            : statusText === "Cancelled"
            ? "baged-cancel"
            : statusText === "Completed"
            ? "baged-completed"
            : statusText === "Failed"
            ? "baged-cancel"
            : "baged-cancel";
        // ${this.state.currentData.nightly_rate ? this.state.currentData.nightly_rate : 0 } x ${this.state.currentData.total_stay_nights ? (this.state.currentData.total_stay_nights > 1 ? this.state.currentData.total_stay_nights + "nights" : "1 night") : 0 }
        record.total_night_text =
          "$" +
          parseFloat(record.nightly_rate) +
          " x " +
          (record.total_stay_nights
            ? record.total_stay_nights > 1
              ? record.total_stay_nights + "nights"
              : "1 night"
            : "1 night");
        record.total_nightly_rate_disp =
          "$" +
          parseFloat(record.nightly_rate) * parseInt(record.total_stay_nights);      
       await localStorage.setItem("trip_details", JSON.stringify(list));
      // window.location.href = "/reservation-details";
    }
    
  };

  // handleSubmit = async (event) => {
  //   // We don't want to let default form submission happen here,
  //   // which would refresh the page.

  //    this.SaveTransactiondata(inputData);
  //   // const result = await stripe.confirmPayment({
  //   //   //`Elements` instance that was used to create the Payment Element
  //   //   elements,
  //   //   confirmParams: {
  //   //     return_url: global.themeUrl + this.state.property_slug + "/booking-confirmation",
  //   //   },
  //   // });

  //   // if (result.error) {
  //   //   // Show error to your customer (for example, payment details incomplete)
  //   //   console.log(result.error.message);
  //   // } else {
  //   //   // Your customer will be redirected to your `return_url`. For some payment
  //   //   // methods like iDEAL, your customer will be redirected to an intermediate
  //   //   // site first to authorize the payment, then redirected to the `return_url`.
  //   // }
  // };

  render() {
    return (
      // <form onSubmit={this.handleSubmit}>

      <>
        <div>
          <span>
            <h5 className="mg-b-15 text-danger decline">{this.state.failedmessage}</h5>
          </span>
        </div>
        <div>
          <span>
            <h5 className="m-0 text-danger">{this.state.date_reserved}</h5>
          </span>
        </div>
        <div>
          <PaymentElement  onChange={this.removeMessage.bind(this)}/>
          {/* <button className="w-100" disabled={!this.props.stripe} onClick={this.buy.bind(this)}> Complete Booking</button> */}
          <a href={global.themeUrl +'reservation-details'}
            hidden
            className="w-100 text-decoration-none hidden"
            rel="noopener noreferrer"
          >
            <button className="mg-t-30" id="booking-success">
              Complete booking
            </button>
          </a>
          <button
            className={" mg-t-30"}
            onClick={this.SaveTransactiondata.bind(this)}
            disabled={this.state.loader === true ? true : false}
          >
            Complete booking
            {this.state.loader ? (
              <>
                {" "}
                <SpinnerLoader />{" "}
              </>
            ) : (
              ""
            )}
          </button>
        </div>
      </>
      // </form>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements, slug }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          guest_slug={props.guest_slug}
          reservation_slug={
            props.reservation_slug ? props.reservation_slug : ""
          }
          street_address_1={
            props.street_address_1 ? props.street_address_1 : ""
          }
          phone_number={props.phone_number}
          street_address_2={
            props.street_address_2 ? props.street_address_2 : ""
          }
          redirectToNextPage={props.redirectToNextPage}
          city={props.city ? props.city : ""}
          contry={props.country ? props.country : ""}
          state={props.state ? props.state : ""}
          zipcode={props.zipcode ? props.zipcode : ""}
          last_name={props.last_name}
          address={props.address}
          stripe_id={props.stripe_id}
          countryId={props.countryId}
          stateId={props.stateId}
          cityId={props.cityId}
          adults={props.adults}
          children= {props.children}
          infants={props.infants}
          startDate={props.startDate}
          endDate={props.endDate}
          propertySlug={props.propertySlug}
          listing_slug={props.listing_slug}
          user_token= {props.user_token}
          confirmation_code={props.confirmation_code}
          validationErrorsHandler={props.validationErrorsHandler}
          // getAvailibityDate={props.getAvailibityDate}
          total_selected_guest={props.total_selected_guest}
        />
      )}
    </ElementsConsumer>
  );
}
