//     							                          //
//  Program: bookingconfirmation.jsx                      //
//  Application: KINI HTML                                //
//  Option: Booking  Reciept                              //
//  Developer: PS		                                  //
//  Date: 2022-06-13                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import Moment from "moment";
import queryString from "query-string";
import { Button, Modal } from "react-bootstrap";
import StrlistingApi from "../../services/StrlistingApi";
import CmsDataService from "../../services/CmsDataService";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import PhoneNumberFormat from "../common/PhoneNumberFormat";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
const queryParams = queryString.parse(location.search);

class ReservationReceipt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openGettingAroundModal: false,
      cancelModal: false,
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      openGuestmanualModal: false,
      queryString: "",
      guest_slug: localStorage.getItem("guest_slug")
        ? localStorage.getItem("guest_slug")
        : "",
      reservation_slug: localStorage.getItem("reservation_slug")
        ? localStorage.getItem("reservation_slug")
        : "",
      adultCount:
        queryParams.numberOfAdults === undefined
          ? 1
          : parseInt(queryParams.numberOfAdults),
      childCount:
        queryParams.numberOfChildren === undefined
          ? 0
          : parseInt(queryParams.numberOfChildren),
      infantCount:
        queryParams.numberOfInfants === undefined
          ? 0
          : parseInt(queryParams.numberOfInfants),
      petsCount:
        queryParams.numberOfPets === undefined
          ? 0
          : parseInt(queryParams.numberOfPets),
      guestValue: "1 Guest",
      displayPhoneNo: "",
      pricing: localStorage.getItem("pricing")
        ? JSON.parse(localStorage.getItem("pricing"))
        : sessionStorage.getItem("pricing")
          ? JSON.parse(sessionStorage.getItem("pricing"))
          : {},
      unit_name: localStorage.getItem("pricing")
        ? JSON.parse(localStorage.getItem("pricing")).listing_name
        : "",
      nights: localStorage.getItem("pricing")
        ? JSON.parse(localStorage.getItem("pricing")).nights
        : "",
      checkin_date: localStorage.getItem("pricing")
        ? JSON.parse(localStorage.getItem("pricing")).checkin_date
        : "",
      checkout_date: localStorage.getItem("pricing")
        ? JSON.parse(localStorage.getItem("pricing")).checkout_date
        : "",
      host_photo_medium_url: localStorage.getItem("pricing")
        ? JSON.parse(localStorage.getItem("pricing")).host_photo_medium_url
        : "",
      guest_detail: "",
      reservation_detail: "",
    };
    this.neighborhoodDetail = this.neighborhoodDetail.bind(this);
    // this.createQueryString = this.createQueryString.bind(this);
  }
  async componentDidMount() {
    await this.GetTransactiondata();
    await this.neighborhoodDetail();
    var ele = document.getElementById("phoneNumberFormat");
    var eleValue = ele.value;
    this.setState({ displayPhoneNo: eleValue });
  }
  openGuestmanualModal = (val = false) => {
    this.setState({ openGuestmanualModal: val });
  };

  async neighborhoodDetail() {
    this.setState({ showSpinner: true, loading: true });
    var list = [];
    var slug = this.state.unit_slug;
    var totalRecordsneighborhood = 0;
    let res = await StrlistingApi.getNeighbourhoodlisting("stg-qa-apartments");

    if (global.successStatus.includes(res.status)) {
      var resData = {};
      list = res.data ? res.data : [];
      totalRecordsneighborhood = list ? list.length : 0;
    }
     await this.setState({
      neighborhoodlist: list,
      totalRecordsneighborhood:totalRecordsneighborhood,
     });
  }
  GetTransactiondata = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resDataguest = {};
    var resDatareservation = {};
    let inputData = {
      guest_slug: this.state.guest_slug ? this.state.guest_slug : "",
      reservation_slug: this.state.reservation_slug
        ? this.state.reservation_slug
        : "",
    };
    let res = await CmsDataService.getTransactionData(inputData);
    if (global.successStatus.includes(res.status)) {
      resDataguest = res.data ? res.data.resultGuest : [];
      resDatareservation = res.data ? res.data.resultReservation : [];
    }
    await this.setState({
      guest_detail: resDataguest,
      reservation_detail: resDatareservation,
    });
  };

  cancelModal = async (value, item = []) => {
    this.setState({ reservationModal: !value });
    await this.setState({
      currentData: item,
    });
    this.setState({ cancelModal: value });
  };
  cancelReservation = async (value, item = []) => {
    let input = {
      user_slug: item.confirmation_code ? item.confirmation_code : "",
      date: this.state.today,
      transaction_id: item.stripe_transaction_id
        ? item.stripe_transaction_id
        : "",
      // total_payment:this.state.cancel_amount,
      total_payment: this.state.cancel_amount
        ? (parseFloat(this.state.cancel_amount).toFixed(2)) : parseInt(0).toFixed(2)
    };

    let res = await ReservationService.cancelReservationData(input);
    if (global.successStatus.includes(res.status)) {
      this.setState({ cancelModal: false });
      window.location.href = "/reservation-list";
    } else {
      alert("something went wrong try after sometime");
      this.setState({ cancelModal: false });
    }
    await this.props.getReservationData("", 2);
    //this.setState({ cancelModal: value });
  };

  openGettingAroundModal = (val = false) => {
    this.setState({ openGettingAroundModal: val });
  };

  render() {
    return (
      <main className="oh-template Brandmainpage">
        {/* Header */}
        <meta name="robots" content="noindex,nofollow"></meta>
        {/* <CommonHeader propertySlug={this.state.property_slug} /> */}
        {/* middle */}
        <section className="middle">
          <div className="container-fluid p-0">

            <section className="reservationBook req_book_sec bgwhitecolor">
              <div className="container ">
                <div className="content">
                  <div className="row mobView">
                  {this.state.headerToken ? 
                  <div className="reservation-data-mob">
                    <div className="back_go">
                      <a href="/reservation-list">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                      </a>
                      <h4 className="back_arrow">
                        {/* My Trips */}
                        Trips
                      </h4>
                    </div>
                  </div>: 
                   <div className="reservation-data-mob">
                  <div className="back_go">
                      <a href="/reservation">
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                      </a>
                      <h4 className="back_arrow">
                        {/* My Trips */}
                        Trips
                      </h4>
                    </div></div>}
                    <div className="col-sm-12 col-lg-12 headingreserv">
                      <div className="requestSection confirm_booking">
                      {this.state.headerToken ? 
                      <div className="reservation-data-desk">
                        <div className="back_go">
                          <h4 className="back_arrow">
                            <a href="/reservation-list">
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                            </a>
                            {/* My Trips */}
                            Trips
                          </h4>
                        </div>
                      </div>:
                       <div className="reservation-data-desk">
                      <div className="back_go">
                          <h4 className="back_arrow">
                          <a href="/reservation">
                              <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt=" My Trips" title=" My Trips" />
                            </a>
                            {/* My Trips */}
                            Trips
                          </h4>
                        </div></div>}
                        <div className="row book_app_info confirm-symbl mg-l-0 mg-r-0 mg-b-15">
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} />
                          <div className="confirm_text">
                            <h4>Your reservation is confirmed! </h4>
                            <p>We emailed the details to {this.state.guest_detail ? this.state.guest_detail.email : ""} </p>
                            {/* <h4> Reservation Confirmed </h4>
                          <p>Thank You! Your reservation has been successfully created. </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 reservdetail">

                      <div className="requestSection confirm_booking">
                        {/* <div className="row book_app_info confirm-symbl mg-l-0 mg-r-0 mg-b-30">
                          <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} />
                          <div className="confirm_text">
                            <h4>Your reservation is confirmed! </h4>
                            <p>We emailed the details to test@gmail.com. </p>
                          
                          </div>
                        </div> */}


                        <div className="reservation-info">
                          <div className="reservSection d-none">
                            <ul>
                              <li>
                                <strong>Check-in</strong>
                                {/* <p>11/24/22 | 03:00 PM</p> */}
                                <span className="checkdate- d-block">{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkin_date).format("MM/DD/YY") : ""} |  {this.state.reservation_detail ? this.state.reservation_detail.checkin_time : ""}</span>
                              </li>
                              <li>
                                <strong>Check-out</strong>
                                {/* <p>15/24/22 | 03:00 PM</p> */}
                                <span className="checkdate-  d-block">{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkout_date).format("MM/DD/YY") : ""} | {this.state.reservation_detail ? this.state.reservation_detail.checkout_time : ""}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="reservSection addressdeskview">
                            <div className="reserv_mid_heading">
                              <h5>Address</h5>
                            </div>
                            <div className="row">

                              <div className="col-6 col-md-6">
                                <strong>Getting there</strong>
                                <span className="mg-b-10  pd-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</span>
                                <a href={this.state.reservation_detail ? this.state.reservation_detail.google_map_link : "-"} target="_blank" className="getdirection"><img src={global.OnKeyPressEvent.getS3ImageUrl('pin.svg')} /> Get direction</a>
                              </div>
                              <div className="col-6 col-md-6">
                                <strong>Unit</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.unit_name : ""}</span>
                              </div>
                              {/* <div className="col-6 col-md-6">
                                <strong>Parking space</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.parking_slot : "-"}</span>
                              </div> */}
                            </div>
                          </div>
                          <div className="requestSection reqestmobview arrivalinst">
                            <div className="row book_app_info">
                              <div className="col-12">
                                <div className="cancellations">
                                    <span className="pd-t-20 pd-b-20 labelTitle">Arrival instructions</span>
                                    <p>
                                    <HTMLEllipsis
                                  unsafeHTML={
                                    this.state.reservation_detail ? this.state.reservation_detail.arrival_instruction
                                      : ""
                                  }
                                  maxLine=""
                                  ellipsis=""
                                  trimright="true"
                                  basedOn="letters"
                                  className="span_text"
                                />
                                    </p>
                                </div> 
                              </div>
                            </div>
                         </div>
                         <div className="reservSection thingsknowmobview">
                           <div className="row book_app_info cancellations-">
                              <div className="col-12">
                              <div className="cancellations">
                              <span className="pd-t-20 pd-b-5 labelTitle">House Manual</span>
                              {/* <div className="col-md-12">
                                <strong>House rules</strong>
                                <span className="mg-b-10"></span>
                              </div>
                              <div className="col-md-12">
                                <strong>Health & safety</strong>
                                <span className="mg-b-10"></span>
                              </div> */}
                              <div className="d-flex flex-wrap">
                               <div className="col-6 col-md-6">
                                <strong>Wifi name</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.wifi_name : "-"}</span>
                              </div>
                              <div className="col-6 col-md-6">
                                <strong>Wifi password</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.wifi_password : "-"}</span>
                              </div>
                              </div>
                              <div className="col-md-12">
                                <strong>House manual</strong>
                                <span className="mg-b-10 d-block">
                                  {/* <span>Guest manual</span> */}
                                  {/* <p> */}
                                  <HTMLEllipsis
                                    unsafeHTML={
                                      this.state.reservation_detail
                                        ? this.state.reservation_detail.guest_manual
                                        : ""
                                    }
                                    maxLine="3"
                                    ellipsis=""
                                    trimright="true"
                                    basedOn="letters"
                                    className="span_text mg-b-30"
                                  />
                                  {/* </p> */}
                                  {this.state.reservation_detail.guest_manual ? (
                                    <b
                                      className="getdirection showmore mg-t-30"
                                      onClick={() =>
                                        this.openGuestmanualModal(true)
                                      }
                                    >
                                      Show more{" "}
                                    </b>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              </div>
                              </div>
                            </div>
                        </div>
                         <div className="reservSection d-none">
                            <div className="reserv_mid_heading">
                              <h5>Guest details</h5>
                            </div>
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <strong>First name</strong>
                                <span className="mg-b-10 d-block">{this.state.guest_detail ? this.state.guest_detail.first_name : ""}</span>
                              </div>
                              <div className="col-6 col-md-6">
                                <strong>Last name</strong>
                                <span className="mg-b-10 d-block">{this.state.guest_detail.last_name ? this.state.guest_detail.last_name : ""}</span>
                              </div>
                              {this.state.guest_detail && this.state.guest_detail.phone_number !== "undefined" && this.state.guest_detail.phone_number !== "" ? (
                                <div className="col-6 col-md-6">
                                  <strong>Mobile</strong>
                                  <span className="mg-b-10 d-block">
                                    <PhoneNumberFormat
                                      phone_number={
                                        this.state.guest_detail.phone_number
                                      }
                                    />
                                  </span>
                                </div>) : ""}
                              <div className="col-6 col-md-6">
                                <strong>Email</strong>
                                <span className="mg-b-10 d-block">{this.state.guest_detail ? this.state.guest_detail.email : ""}</span>
                              </div>
                              {/* <div className="col-6 col-md-6">
                                <strong>Address</strong>
                                <span className="mg-b-10">{this.state.guest_detail ? this.state.guest_detail.address : "-"}</span>
                              </div> */}

                            </div>
                          </div>
                          {/* <div className="requestSection mg-t-30">
                            <div className="row book_app_info cancellations">
                              <div className="col-12">
                                <span className="pd-t-20 pd-b-5 labelTitle">Do it like to a local</span>
                                {this.state.neighborhoodlist?.type_list &&
                                  this.state.neighborhoodlist.type_list.map(
                                    (value, index) => {
                                      return(
                                        <>
                                    <div className="commanBlockdetail pd-l-2 pd-r-2 mg-lg-t-30 mg-b-30 border-bottom">
                                      <p>{value.image}</p>
                                      <p>{value.tag_line}</p>
                                      <p>{value.description}</p>
                                      <p>{value.map}</p>
                                      <p>{value.attraction_type_name}</p>
                                     </div></>)})}
                              </div>
                            </div>
                          </div> */}
                          <div className="requestSection mg-t-30 doitlocal">
                            <div className="row book_app_info cancellations-">
                              <div className="col-12">
                              <div className="cancellations">
                                <span className="pd-t-20 pd-b-30 labelTitle">Do it like a local</span>
                                {/* {this.state.neighborhoodlist?.type_list &&
                                  this.state.neighborhoodlist.type_list.map(
                                    (value, index) => {
                                      return(
                                        <>
                                    <div className="commanBlockdetail pd-l-2 pd-r-2 mg-lg-t-30 mg-b-30 border-bottom">
                                      <p>{value.image}</p>
                                      <p>{value.tag_line}</p>
                                      <p>{value.description}</p>
                                      <p>{value.map}</p>
                                      <p>{value.attraction_type_name}</p>
                                     </div></>)})} */}

                           
                                  <Swiper
                                      slidesPerView={1}
                                      spaceBetween={15}
                                      slidesPerGroup={1}
                                      loop={true}
                                      loopFillGroupWithBlank={true}
                                      centeredSlides={false}
                                      pagination={{
                                        clickable: true,
                                      }}
                                      autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                      }}
                                      // navigation={true}
                                      modules={[Navigation, Pagination, Autoplay]}
                                      className="mySwiper m-0"

                                      breakpoints={{
                                        "@0.00": {
                                          slidesPerView: 1,
                                          spaceBetween: 10,
                                        },
                                        "@0.75": {
                                          slidesPerView: 1,
                                          spaceBetween: 10,
                                        },
                                        "@1.00": {
                                          slidesPerView: 2,
                                          spaceBetween: 10,
                                        },
                                        "@1.50": {
                                          slidesPerView: 2,
                                          spaceBetween: 15,
                                        },
                                      }}
                                    >
                          
                              <SwiperSlide>
                            <div className="col-md-12 col-12">
                                  <div className="do_like_local_box">
                                    <a href="#" className="local_box">
                                      <div className="local_box_new">
                                        <div className="local_box_img">
                                          <figure>
                                          <img src="https://did1d8jg9ar6w.cloudfront.net/website/images/how-to-minimize-risk.png" alt="" title="" />
                                          </figure>
                                        </div>
                                        <h5 className="loacl_one">Food</h5>
                                        <h4 className="loacl_two">Where to eat?</h4>
                                        <p>Everywhere we go to grab a bite.</p>
                                        <div className="do_live_footer">
                                          <p>Open collections on the map</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="col-md-12 col-12">
                                  <div className="do_like_local_box">
                                    <a href="#" className="local_box">
                                      <div className="local_box_new">
                                        <div className="local_box_img">
                                          <figure>
                                            <img src="https://did1d8jg9ar6w.cloudfront.net/website/images/how-to-minimize-risk.png" alt="" title="" />
                                          </figure>
                                        </div>
                                        <h5 className="loacl_one">Drink</h5>
                                        <h4 className="loacl_two">Where to drink?</h4>
                                        <p>Bars, clubs and lounges.</p>
                                        <div className="do_live_footer">
                                          <p>Open collections on the map</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                <div className="col-md-12 col-12">
                                  <div className="do_like_local_box">
                                    <a href="#" className="local_box">
                                      <div className="local_box_new">
                                        <div className="local_box_img">
                                          <figure>
                                            <img src="https://did1d8jg9ar6w.cloudfront.net/website/images/how-to-minimize-risk.png" alt="" title="" />
                                          </figure>
                                        </div>
                                        <h5 className="loacl_one">Fun</h5>
                                        <h4 className="loacl_two">Where to hang?</h4>
                                        <p>Out favourite thing to do around town.</p>
                                        <div className="do_live_footer">
                                          <p>Open collections on the map</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>

                              </SwiperSlide>
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                         </div>
                         <div className="requestSection gettingaroundmobview mg-t-5">
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                              <span className="pd-t-20 pd-b-5 labelTitle">Getting around</span>
                              <p className="mg-b-30">
                              <HTMLEllipsis
                            unsafeHTML={
                              this.state.getting_around
                                ? this.state.getting_around
                                : ""
                            }
                            maxLine="4"
                            ellipsis=""
                            trimright="true"
                            basedOn="letters"
                            className="span_text"
                          />
                          {this.state.getting_around ? (
                            <b
                              onClick={() => this.openGettingAroundModal(true)}
                              className="getdirection showmore "
                            >
                              Show more
                            </b>
                          ) : (
                            ""
                          )}
                              </p>
                            </div>
                            </div>
                          </div>
                        </div>
                       
                        <div className="requestSection host mg-t-25">
                          <div className="reserv_mid_heading mg-t-0 pd-t-25">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                              <div className="d-flex flex-wrap flex-column mg-lg-t-15 guestBlock">
                                <h5>Hosted by{this.state.reservation_detail ? this.state.reservation_detail.host_name:""}</h5>
                                {/* <div className="hostContent">
                                  <b>Joined in Nov 16th, 22</b>
                                </div> */}
                              </div>
                              <figure><img src={this.state.reservation_detail ? this.state.reservation_detail.host_photo_medium_url:""} alt="Host image" title="Host image" />
                              </figure>
                            </div>
                            <p></p>
                          </div>
                        </div>
                        <div className="requestSection getrecieptmobview mg-t-5">
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                                <ul className="receipt">
                                  <li>
                                    <a href={this.state.reservation_detail ? this.state.reservation_detail.pdf_path:""} target="_blank"><div className="icon1"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Print details" /> Get receipt </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>
                                  <li>
                                    <a href={'/str-listing'}><div className="icon2"><img src={global.OnKeyPressEvent.getS3ImageUrl('gotolisting.svg')} className="viewlist" alt="Get receipt" /> Go to listing </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="arrow" /></a>
                                  </li>
                                  {/* <li>
                                    <a href={'javaScript:void(0)'}><div className="icon2"><img src={global.OnKeyPressEvent.getS3ImageUrl('note.svg')} alt="Get receipt" /> Download agreement </div> <img src={global.OnKeyPressEvent.getS3ImageUrl('downloadgreement.svg')} className="download" alt="arrow" /></a>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                          <div className="reservSection d-none">
                            <div className="reserv_mid_heading">
                              <h5>Reservation details</h5>
                            </div>
                            <div className="row">

                              {/* <div className="col-6 col-md-6">
                                <strong>Unit</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.unit_name : this.state.reservation_detail.unit_number}</span>
                              </div> */}
                              {/* <div className="col-6 col-md-6">
                                <strong>Confirmation code</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.confirmation_code : ""}</span>
                              </div> */}
                               {/* <div className="col-6 col-md-6">
                                <strong>Booking date</strong>
                                <span className="mg-b-10">{this.state.reservation_detail ? this.state.reservation_detail.status_text : ""}</span>
                              </div> */}
                              {/* <div className="col-6 col-md-6">
                                <strong>Guest access code</strong>
                                <span>{this.state.reservation_detail ? this.state.reservation_detail.key_cafe_key : "-"}</span>
                              </div> */}
                              <div className="col-6 col-md-6">
                                <strong>No. of guests</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.number_of_guest : ""}</span>
                              </div>
                              {/* <div className="col-6 col-md-6">
                                <strong>Adults</strong>
                              
                                <span className="mg-b-10"></span>
                              </div> */}
                              {/* <div className="col-6 col-md-6">
                                <strong>Booked</strong>
                                <span className="mg-b-10"></span>
                              </div> */}
                              {/* <div className="col-6 col-md-6">
                                <strong>Status</strong>
                              
                                <span className="mg-b-10">{this.state.reservation_detail ? this.state.reservation_detail.total_stay_nights : 1}</span>
                              </div> */}
                            </div>
                          </div>
                          <div className="reservSection d-none">
                            <div className="reserv_mid_heading">
                              <h5>Guest details</h5>
                            </div>
                            <div className="row">
                              <div className="col-6 col-md-6">
                                <strong>First name</strong>
                                <span className="mg-b-10 d-block">{this.state.guest_detail ? this.state.guest_detail.first_name : ""}</span>
                              </div>
                              <div className="col-6 col-md-6">
                                <strong>Last name</strong>
                                <span className="mg-b-10 d-block">{this.state.guest_detail.last_name ? this.state.guest_detail.last_name : ""}</span>
                              </div>
                              {this.state.guest_detail && this.state.guest_detail.phone_number !== "undefined" && this.state.guest_detail.phone_number !== "" ? (
                                <div className="col-6 col-md-6">
                                  <strong>Mobile</strong>
                                  <span className="mg-b-10 d-block">
                                    <PhoneNumberFormat
                                      phone_number={
                                        this.state.guest_detail.phone_number
                                      }
                                    />
                                  </span>
                                </div>) : ""}
                              <div className="col-6 col-md-6">
                                <strong>Email</strong>
                                <span className="mg-b-10 d-block">{this.state.guest_detail ? this.state.guest_detail.email : ""}</span>
                              </div>
                              {/* <div className="col-6 col-md-6">
                                <strong>Address</strong>
                                <span className="mg-b-10">{this.state.guest_detail ? this.state.guest_detail.address : "-"}</span>
                              </div> */}

                            </div>
                          </div>
                          <div className="requestSection cancelpolicymobview mg-t-5">
                          <div className="row book_app_info cancellations-">
                            <div className="col-12">
                              <div className="cancellations">
                              <span className="pd-t-20 pd-b-5 labelTitle">Cancelation policy</span>
                              <p className="mg-b-30">To receive a full refund, you must cancel within 48 hours of booking, and the cancelation must occur at least 14 days before check-in.</p>
                                  <b
                                    onClick={() =>
                                      this.cancelModal(true, this.state.currentData)
                                    }
                                    className="getdirection showmore"
                                  > Show more
                                  </b>
                                 
                            </div>
                            </div>
                          </div>
                        </div>

                         
                          {/* <div className="col-sm-12 col-lg-11 resverBtn">
                            <a href="/reservation-list" className="text-decoration-none"><button className="mg-t-30 crmf_btn" >See your reservations</button></a>
                          </div> */}
                        </div>


















                        {/* <div className="row book_app_info">
                          <div className="col-12 pd-l-0 "><span className="subhead">Reservation Details</span></div>
                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Check-in</strong>
                              <p>11/24/22 | 03:00 PM</p>
                              <span>{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkin_date).format("MM/DD/YY") : ""} |  {this.state.reservation_detail ? this.state.reservation_detail.checkin_time : ""}</span>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Check-out</strong>
                              <p>15/24/22 | 03:00 PM</p>
                              <span>{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkout_date).format("MM/DD/YY") : ""} | {this.state.reservation_detail ? this.state.reservation_detail.checkout_time : ""}</span>
                            </div>
                          </div>
                        </div>
                        <div className="row book_app_info">
                         
                          <span className="pd-t-10 pd-b-20 pd-l-15 pd-r-15 labelTitle">Address</span>

                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Getting there</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</span>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Get direction</strong>
                              <span><a href={this.state.reservation_detail ? this.state.reservation_detail.google_map_link : "-"} target="_blank">{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</a></span>
                            </div>
                          </div>
                        </div>
                        <div className="row book_app_info">
                          <span className="pd-t-10 pd-b-20 pd-l-15 pd-r-15 labelTitle">Guests</span>
                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Number of guests</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.number_of_guest : ""}</span>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Number of nights</strong>
                            
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.total_stay_nights : 1}</span>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Confirmation code</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.confirmation_code : ""}</span>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Unit Name</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.unit_name : ""}</span>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Wifi name</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.wifi_name : "-"}</span>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Wifi password</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.wifi_password : "-"}</span>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Parking space</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.parking_slot : "-"}</span>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Guest access code</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.key_cafe_key : "-"}</span>
                            </div>
                          </div>

                          <div className="col-xs-12 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Getting there</strong>
                              <span>{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</span>
                            </div>
                          </div>
                          <div className="col-xs-12 pd-l-15 pd-r-15 mg-b-30">
                            <div className="confirm-detail">
                              <strong>Get direction</strong>
                              <span><a href={this.state.reservation_detail ? this.state.reservation_detail.google_map_link : "-"} target="_blank">{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</a></span>
                            </div>
                          </div>

                        </div>
                        <div className="row book_pay_info">
                          <div className="requestSection">
                            <div className="row">
                              <div className="col-12 pd-l-0"><span className="subhead m-0">Guest Details</span></div>
                              <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15  mg-t-20">
                                <div className="confirm-detail">
                                  <strong>Name</strong>
                                  <span>{this.state.guest_detail ? this.state.guest_detail.first_name + " " + this.state.guest_detail.last_name : ""}</span>
                                </div>
                              </div>

                              <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15 mg-t-20">
                                <div className="confirm-detail">
                                  <strong>Email</strong>
                                  <span>{this.state.guest_detail ? this.state.guest_detail.email : ""}</span>
                                </div>
                              </div>

                              <div className="col-xs-12 col-sm-4 col-md-4 pd-l-15 pd-r-15  mg-t-20">
                                <div className="confirm-detail">
                                  <strong>Address</strong>
                                  <span>{this.state.guest_detail ? this.state.guest_detail.address : "-"}</span>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-1 col-xl-1"></div>
                    <div className="col-sm-12 col-lg-5 col-xl-5 position-relative priceInfo">
                      <div className="requestaddedSection m-0 confirm_recpt">
                        <figure><img src={this.state.reservation_detail.listing_image ? this.state.reservation_detail.listing_image.large_img_w_720 : ""} className="img-fluid" alt="Property Logo" title="Property Logo" /></figure>
                        <div className="priceInfo">
                          <div className="priceTop">
                            <h5>
                              {this.state.reservation_detail ? this.state.reservation_detail.unit_name : ""}
                            </h5>
                          </div>
                          <div className="requestSection confirm_booking">
                          <div className="reservation-info">
                            <div className="reservSection checkdatemob">
                              <ul>
                                <li>
                                  <strong>Check-in</strong>
                                  {/* <p>11/24/22 | 03:00 PM</p> */}
                                  <span className="checkdate- d-block">{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkin_date).format("MM/DD/YY") : ""} |  {this.state.reservation_detail ? this.state.reservation_detail.checkin_time : ""}</span>
                                </li>
                                <li>
                                  <strong>Check-out</strong>
                                  {/* <p>15/24/22 | 03:00 PM</p> */}
                                  <span className="checkdate-  d-block">{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkout_date).format("MM/DD/YY") : ""} | {this.state.reservation_detail ? this.state.reservation_detail.checkout_time : ""}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="reservSection addressmobilview">
                            <div className="reserv_mid_heading">
                              <h5>Address</h5>
                            </div>
                            <div className="row">

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                <strong>Getting there</strong>
                                <span className="mg-b-20  pd-b-20 d-block gettingmobileview">{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</span>
                                <a href={this.state.reservation_detail ? this.state.reservation_detail.google_map_link : "-"} target="_blank" className="getdirection"><img src={global.OnKeyPressEvent.getS3ImageUrl('pin.svg')} /> Get direction</a>
                              </div>
                              <div className="col-md-6 unitmobileview">
                                <strong>Unit</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.unit_name : ""}</span>
                              </div>
                              {/* <div className="col-6 col-md-6">
                                <strong>Parking space</strong>
                                <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.parking_slot : "-"}</span>
                              </div> */}
                            </div>
                          </div>
                            <div className="reservSection">
                                <div className="reserv_mid_heading">
                                  <h5>Reservation details</h5>
                                </div>
                                <div className="row">
                                  <div className="col-6 col-md-6">
                                    <strong>Confirmation code</strong>
                                    <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.confirmation_code : ""}</span>
                                  </div>
                                  {this.state.reservation_detail.created_at ? (
                                  <div class="col-6 col-md-6">
                                    <strong>Booking date</strong>
                                    <span className="mg-b-10 d-block">
                                      {this.state.reservation_detail.created_at
                                        ? Moment(this.state.reservation_detail.created_at).format(
                                          global.dateFormat
                                        )
                                        : "-"}
                                    </span>
                                  </div>) : ""}
                                  {/* <div className="col-6 col-md-6">
                                    <strong>Booking date</strong>
                                    <span className="mg-b-10 d-block"></span>
                                  </div> */}
                                  <div className="col-6 col-md-6">
                                    <strong>Status</strong>
                                    <span className="mg-b-10 d-block"></span>
                                  </div>
                                  {/* <div className="col-6 col-md-6">
                                    <strong>Guest access code</strong>
                                    <span>{this.state.reservation_detail ? this.state.reservation_detail.key_cafe_key : "-"}</span>
                                  </div> */}
                                  <div className="col-6 col-md-6">
                                    <strong>No. of guests</strong>
                                    <span className="mg-b-10 d-block">{this.state.reservation_detail ? this.state.reservation_detail.number_of_guest : ""}</span>
                                  </div>
                                  <div className="col-6 col-md-6">
                                    <strong>Adults</strong>
                                  
                                    <span className="mg-b-10"></span>
                                  </div>
                                  <div className="col-6 col-md-6">
                                    <strong>Children</strong>
                                    <span className="mg-b-10 d-block">
                                    
                                    </span>
                                  </div>
                                  <div className="col-6 col-md-6">
                                    <strong>Infants</strong>
                                    <span className="mg-b-10 d-block">
                                    
                                    </span>
                                  </div>
                                  <div className="col-6 col-md-6">
                                    <strong>Pets</strong>
                                    <span className="mg-b-10 d-block">
                                    
                                    </span>
                                  </div>
                                  <div className="col-6 col-md-6">
                                    <strong>Channel</strong>
                                    <span className="mg-b-10 d-block">
                                    
                                    </span>
                                  </div>
                                </div>
                            </div>
                            <div className="reservSection">
                              <div className="reserv_mid_heading">
                                <h5>Guest details</h5>
                              </div>
                              <div className="row">
                                <div className="col-6 col-md-6">
                                  <strong>First name</strong>
                                  <span className="mg-b-10 d-block">{this.state.guest_detail ? this.state.guest_detail.first_name : ""}</span>
                                </div>
                                <div className="col-6 col-md-6">
                                  <strong>Last name</strong>
                                  <span className="mg-b-10 d-block">{this.state.guest_detail.last_name ? this.state.guest_detail.last_name : ""}</span>
                                </div>
                                {this.state.guest_detail && this.state.guest_detail.phone_number !== "undefined" && this.state.guest_detail.phone_number !== "" ? (
                                  <div className="col-6 col-md-6">
                                    <strong>Mobile</strong>
                                    <span className="mg-b-10 d-block">
                                      <PhoneNumberFormat
                                        phone_number={
                                          this.state.guest_detail.phone_number
                                        }
                                      />
                                    </span>
                                  </div>) : ""}
                                <div className="col-6 col-md-6">
                                  <strong>Email</strong>
                                  <span className="mg-b-10 d-block">{this.state.guest_detail ? this.state.guest_detail.email : ""}</span>
                                </div>
                                {/* <div className="col-6 col-md-6">
                                  <strong>Address</strong>
                                  <span className="mg-b-10">{this.state.guest_detail ? this.state.guest_detail.address : "-"}</span>
                                </div> */}
                              </div>
                             </div>
                          </div>
                        </div>
                          <div className="priceMiddle">
                            <span className="mg-b-20 d-block">Price details</span>
                            <ul>
                              {/* <li><span>{` $${this.state.reservation_detail ? (parseFloat(this.state.reservation_detail.nightly_rate).toFixed(2).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}  × ${this.state.reservation_detail ? this.state.reservation_detail.total_stay_nights : 1} Nights`}</span> */}
                              <li><span>{` $${this.state.reservation_detail ? (parseFloat(this.state.reservation_detail.total_nightly_rate / this.state.reservation_detail.total_stay_nights).toFixed(2).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}  × ${this.state.reservation_detail ? this.state.reservation_detail.total_stay_nights : 1}`}{this.state.reservation_detail.total_stay_nights > 1 ? "Nights" : "Night"}</span>
                                <span>{` $${this.state.reservation_detail.total_nightly_rate ? (parseFloat(this.state.reservation_detail.total_nightly_rate).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span></li>
                              {this.state.reservation_detail && this.state.reservation_detail.cleaning_fee ? (
                                <li>
                                  <span>Cleaning fee</span>
                                  <span>{` $${this.state.reservation_detail.cleaning_fee ? (parseFloat(this.state.reservation_detail.cleaning_fee).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span>
                                </li>) : ""}
                              <li><span>Service fee</span><span>{` $${this.state.reservation_detail.convenience_fee ? (parseFloat(this.state.reservation_detail.convenience_fee).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span></li>
                              <li><span>Occupancy taxes</span><span>{` $${this.state.reservation_detail.total_taxes ? (parseFloat(this.state.reservation_detail.total_taxes).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span></li>
                              {/* {this.state.reservation_detail && this.state.reservation_detail.service_fee ? (
                                <li>
                                  <span>Service fee</span><span>{` $${this.state.reservation_detail.service_fee ? (parseFloat(this.state.reservation_detail.service_fee).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span></li>) : ""} */}


                              {/* <li><span>Service fee</span><span>{` $${this.state.reservation_detail.convenience_fee ? (parseFloat(this.state.reservation_detail.convenience_fee).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span></li> */}
                            </ul>
                          </div>
                          <div className="priceBottom">
                            <ul>
                              <li><span>Total (USD)</span><b><span>{` $${this.state.reservation_detail ? (parseFloat(this.state.reservation_detail.total_amount).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</span></b></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                show={this.state.openGuestmanualModal}
                centered
                className="unitmodal"
                onHide={() => this.openGuestmanualModal()}
              >
                <Modal.Body className="width_402 p-4 border_radius_14">
                  <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                    <span
                      className="mobilewelcomeClose float-end"
                      onClick={() => this.openGuestmanualModal(false)}
                    >
                      <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="House manual" title="House manual" />
                    </span>
                    <div className="title">House manual</div>
                    <span
                      className="welcomeClose float-end"
                      onClick={() => this.openGuestmanualModal(false)}
                    >
                      {global.closee}
                    </span>
                  </div>
                  <div className="pd-l-20 pd-r-20 mg-t-10">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.reservation_detail
                          ? this.state.reservation_detail.guest_manual
                          : "",
                      }}
                    ></p>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
              show={this.state.cancelModal}
              centered
              onHide={() => this.setState({ cancelModal: false })}
              className="reservationDetailmodalbox"
            >
              <span
                className="welcomeClose"
                onClick={() => this.setState({ cancelModal: false })}
              >
                {global.closee}
              </span>

              <Modal.Body className="width_402 p-4 border_radius_14">
                <div className="reservationDetails">
                  {/* <h5 className="mb-0">Are you sure want to cancel?</h5> */}
                  <h6>Cancelation policy for your listing</h6>
                  <ul>
                    <li>To receive a full refund, you must cancel within 48 hours of booking, and the cancelation must occur at least 14 days before check-in</li>
                    <li>If you cancel between 7 and 14 days before check-in, you’ll be paid 50% for all nights</li>
                    <li>If you cancel after that, you’ll be paid 100% for all nights</li>
                  </ul>
                  {/* <h5 className="mb-4">Your total refund amount is ${this.state.cancel_amount}</h5> */}
                  {/* <div className="d-flex d-flex  justify-content-center align-items-start">
                    <button
                      onClick={() =>
                        this.cancelReservation(true, this.state.currentData)
                      }
                      className="mg-r-15"
                    >
                      Yes, cancel
                    </button>
                    <button
                      onClick={() =>
                        this.cancelModal(false, this.state.currentData)
                      }
                    >
                      No
                    </button>
                  </div> */}
                </div>
              </Modal.Body>
            </Modal>
            <Modal
                  show={this.state.openGettingAroundModal}
                  centered
                  className="unitmodal"
                  onHide={() => this.openGettingAroundModal()}
                >
                  <Modal.Body className="width_402 p-4 border_radius_14">
                    <div className="d-flex justify-content-between pd-l-20 pd-r-20 mobile">
                      <span
                        className="mobilewelcomeClose float-end"
                        onClick={() => this.openGettingAroundModal(false)}
                      >
                        <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} alt="Getting around" title="Getting around" />
                      </span>
                      <div className="title">Getting around</div>
                      <span
                        className="welcomeClose float-end"
                        onClick={() => this.openGettingAroundModal(false)}
                      >
                        {global.closee}
                      </span>
                    </div>
                    <div className="pd-l-20 pd-r-20 mg-t-10">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.getting_around
                            ? this.state.getting_around
                            : "",
                        }}
                      ></p>
                    </div>
                  </Modal.Body>
                </Modal>
            </section>



          </div>
        </section>

        {/* Footer */}

        {/* <CommonFooter propertySlug={this.state.property_slug} /> */}
      </main>

      //   <section className="bookingInformation">
      //   <div className="container ">
      //     <div className="row">
      //       <div className="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2 col-12">
      //       <div className="maiconfirmation">
      //       <img src={global.OnKeyPressEvent.getS3ImageUrl('animateCheck.svg')} alt="Booking Confirmed" title="Booking Confirmed"className="mg-b-20" />
      //       <h4 className="mg-b-15"> Reservation Confirmed </h4>
      //       <p>Thank You! Your Reservation has been successfully created. </p>
      //       </div>

      //           <div className="row bookingrecipt ">
      //           <div className="col-12">
      //                   <div className="reservationdetail">
      //                     <div className="bookingimagebox">
      //                     {/* <figure>
      //                     <img src={this.state.reservation_detail ? this.state.reservation_detail.host_photo_medium_url : ""} alt="hostphoto" />
      //                     </figure>                      */}

      //                   </div>
      //                     <h5>Reservation Details</h5>

      //                     <h6>Listing Name </h6>
      //                     <p>{this.state.reservation_detail ? this.state.reservation_detail.unit_name : ""}</p>
      //                     <h6>Getting there</h6>
      //                     <p>{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</p>
      //                     <h6>Get direction</h6>
      //                     <p><a  href= {this.state.reservation_detail ? this.state.reservation_detail.google_map_link : "-"} target="_blank">{this.state.reservation_detail ? this.state.reservation_detail.property_address : "-"}</a></p>
      //                     <div className="brd-1"></div>
      //                     <h6>Confirmation Code </h6>
      //                     <p>{this.state.reservation_detail ? this.state.reservation_detail.confirmation_code : ""}</p>
      //                     <div className="brd-1"></div>
      //                     <h6>Check-in </h6>
      //                     <p>{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkin_date).format("MM/DD/YY") : ""}  {this.state.reservation_detail ? this.state.reservation_detail.checkin_time : ""}</p>
      //                     <div className="brd-1"></div>
      //                     <h6>Check-out </h6>
      //                     <p>{this.state.reservation_detail ? Moment(this.state.reservation_detail.checkout_date).format("MM/DD/YY") : ""} {this.state.reservation_detail ? this.state.reservation_detail.checkout_time : ""}</p>
      //                     <div className="brd-1"></div>
      //                     <h6>Number of guest </h6>
      //                     <p>{this.state.reservation_detail ? this.state.reservation_detail.number_of_guest : ""}</p>

      //                   </div>

      //               </div>
      //           </div>

      //           <div className="row bookingrecipt brd-rd-0">
      //           <div className="col-12">
      //                   <div className="reservationdetail">

      //                     <h5>Guest Details</h5>

      //                     <h6>Name</h6>
      //                     <p>{this.state.guest_detail ? this.state.guest_detail.first_name + " " + this.state.guest_detail.last_name : ""}</p>
      //                     <div className="brd-1"></div>
      //                     <h6>Email</h6>
      //                     <p>{this.state.guest_detail ? this.state.guest_detail.email : ""}</p>
      //                     <div className="brd-1"></div>
      //                     <h6>Phone Number</h6>
      //                     <p>{this.state.guest_detail ? this.state.guest_detail.phone_number : ""}</p>
      //                     <div className="brd-1"></div>
      //                     <h6>Address</h6>
      //                     <p>{this.state.guest_detail ? this.state.guest_detail.address : "-"}</p>
      //                     <div className="brd-1"></div>
      //                     <h6>City</h6>
      //                     <p>{this.state.guest_detail ? this.state.guest_detail.city_name : "-"}</p>

      //                   </div>

      //               </div>
      //           </div>

      //           <div className="row bookingrecipt brd-rd-last">
      //           <div className="col-12">
      //                   <div className="Paymentrecipt">

      //                     <h5>Payment Details</h5>

      //                    <ul>
      //                      <li>Guest</li>
      //                      <li>{this.state.reservation_detail ? this.state.reservation_detail.number_of_guest : ""}</li>
      //                      <li>Unit Price</li>
      //                     <li>{` $${this.state.reservation_detail ? (parseFloat(this.state.reservation_detail.nightly_rate).toFixed(2).replace(/\.00$/, "")) : parseFloat(1).toFixed(2)}  × ${this.state.reservation_detail ? this.state.reservation_detail.total_stay_nights : 1} Nights`}</li>
      //                     {this.state.reservation_detail.service_fee ? (
      //                     <>
      //                     <li>Service Fee</li>
      //                     <li>{` $${this.state.reservation_detail.service_fee ? (parseFloat(this.state.reservation_detail.service_fee).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</li>
      //                     </>
      //                     ) : (
      //                       ""
      //                   )}
      //                   {this.state.reservation_detail.cleaning_fee ? (
      //                     <>
      //                     <li>Cleaning Fee</li>
      //                     <li>{` $${this.state.reservation_detail.cleaning_fee ? (parseFloat(this.state.reservation_detail.cleaning_fee).toFixed(2).replace(/\.00$/, "")) :parseInt(0).toFixed(2)}`}</li>
      //                     </>
      //                     ) : (
      //                       ""
      //                   )}
      //                    {this.state.reservation_detail.total_taxes ? (
      //                     <>
      //                     <li>Occupancy taxes</li>
      //                     <li>{` $${this.state.reservation_detail.total_taxes ? (parseFloat(this.state.reservation_detail.total_taxes).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</li>
      //                     </>
      //                     ) : (
      //                       ""
      //                   )}
      //                    </ul>
      //                    <div className="brd-1"></div>
      //                    <ul className="amountfinal">
      //                     <li>Total Amount</li>
      //                     <li>{` $${this.state.reservation_detail ? (parseFloat(this.state.reservation_detail.total_amount).toFixed(2).replace(/\.00$/, "")) : parseInt(0).toFixed(2)}`}</li>
      //                    </ul>

      //                   </div>

      //               </div>
      //           </div>

      //         </div>
      //     </div>
      //   </div>
      // </section>
    );
  }
}
export default ReservationReceipt;
