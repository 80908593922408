//     							                          //
//  Program: booktourpayment.jsx                                  //
//  Application: KINI HTML                                //
//  Option: Book Tour Payment                             //
//  Developer: PS		                                  //
//  Date: 2022-06-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import TextField from "@mui/material/TextField";
import CmsDataService from "../../services/CmsDataService";
import queryString from "query-string";
import Moment from "moment";
import ReservationService from "../../services/ReservationService";
import { Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import GooglePlaces from "../common/GooglePlaces";
import CountryService from "../../services/CountryService";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { Datepicker, options, Page } from "@mobiscroll/react";
// import {PaymentElement} from '@stripe/react-stripe-js';
// import {ElementsConsumer} from '@stripe/react-stripe-js';
// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import { Helmet } from "react-helmet-async";
// import handleSubmit from './CheckoutForm';
const queryParams = queryString.parse(location.search);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
class BookTourPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personName: [],
      cityList: [],
      newCityList: [],
      cancelModal: false,
      selectedCityList: [],
      stateList: [],
      // newStateList: [],
      selectedStateList: [],
      input: localStorage.getItem("reservationDetails")
        ? JSON.parse(localStorage.getItem("reservationDetails"))
        : {},
      input: {},
      errors: {
        street_address_1: "",
        city: "",
        state_name: "",
        country: "",
        zipcode: "",
      },
      street_address_1: "",
      city: "",
      state_name: "",
      availableDate: "",
      country: "",
      zipcode: "",
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      customer_id: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).stripe_customer_id
        : "",
      property_name: localStorage.getItem("property_name")
        ? localStorage.getItem("property_name")
        : "",
      listing_slug: localStorage.getItem("listing_slug")
        ? localStorage.getItem("listing_slug")
        : queryParams.listing,
      clientToken: "",
      oldStartDate: localStorage.getItem("checkin")
        ? localStorage.getItem("checkin")
        : queryParams.checkin,
      oldEndDate: localStorage.getItem("checkout")
        ? localStorage.getItem("checkout")
        : queryParams.checkout,
      startDate: localStorage.getItem("checkin")
        ? localStorage.getItem("checkin")
        : queryParams.checkin,
      endDate: localStorage.getItem("checkout")
        ? localStorage.getItem("checkout")
        : queryParams.checkout,
      userToken: localStorage.getItem("userToken")
        ? localStorage.getItem("userToken")
        : undefined,
      nonce: "",
      total_selected_guest: 1,
      guest: 1,
      transactionData: "",
      first_name: localStorage.getItem("reservationDetails")
        ? JSON.parse(localStorage.getItem("reservationDetails")).first_name
        : "",
      last_name: localStorage.getItem("reservationDetails")
        ? JSON.parse(localStorage.getItem("reservationDetails")).last_name
        : "",
      email: localStorage.getItem("reservationDetails")
        ? JSON.parse(localStorage.getItem("reservationDetails")).email
        : "",
      guest_slug: localStorage.getItem("guest_slug")
        ? localStorage.getItem("guest_slug")
        : "",
      reservation_slug: localStorage.getItem("reservation_slug")
        ? localStorage.getItem("reservation_slug")
        : "",
      redirectToNextPage: false,
      phone_number: localStorage.getItem("reservationDetails")
        ? JSON.parse(localStorage.getItem("reservationDetails")).phone_number
        : "",
      old_phone_number: localStorage.getItem("reservationDetails")
        ? JSON.parse(localStorage.getItem("reservationDetails")).phone_number
        : "",
      displayPhoneNo: "",
      contryCode: localStorage.getItem("country_code_value")
        ? JSON.parse(localStorage.getItem("country_code_value"))
        : "",
      property_slug:
        props.match && props.match.params
          ? props.match.params.propertySlug
            ? props.match.params.propertySlug
            : global.property_slug
          : global.property_slug,
      queryString: "",
      adultCount:
        queryParams.numberOfAdults === undefined
          ? 1
          : parseInt(queryParams.numberOfAdults),
      childCount:
        queryParams.numberOfChildren === undefined
          ? 0
          : parseInt(queryParams.numberOfChildren),
      infantCount:
        queryParams.numberOfInfants === undefined
          ? 0
          : parseInt(queryParams.numberOfInfants),
      petsCount:
        queryParams.numberOfPets === undefined
          ? 0
          : parseInt(queryParams.numberOfPets),
      guestValue: "1 Guest",
      pricing: localStorage.getItem("pricing")
        ? JSON.parse(localStorage.getItem("pricing"))
        : {},
      street_address_1: localStorage.getItem("addressDetails")
        ? JSON.parse(localStorage.getItem("addressDetails")).street_address_1
        : "",
      street_address_2: localStorage.getItem("addressDetails")
        ? JSON.parse(localStorage.getItem("addressDetails")).street_address_2
        : "",
      city: localStorage.getItem("addressDetails")
        ? JSON.parse(localStorage.getItem("addressDetails")).city
        : "",
      state_name: localStorage.getItem("addressDetails")
        ? JSON.parse(localStorage.getItem("addressDetails")).state
        : "",
      country: localStorage.getItem("addressDetails")
        ? JSON.parse(localStorage.getItem("addressDetails")).contry
        : "",
      zipcode: localStorage.getItem("addressDetails")
        ? JSON.parse(localStorage.getItem("addressDetails")).zipcode
        : "",
      addressDetails: localStorage.getItem("addressDetails")
        ? JSON.parse(localStorage.getItem("addressDetails"))
        : "",
      loader: false,
      coupon_code: "",
      reservation_confirm: localStorage.getItem("reservation_confirm")
        ? localStorage.getItem("reservation_confirm")
        : "",
      isNeedtoStrDetails: false,
      headerToken: localStorage.getItem("headerToken")
        ? localStorage.getItem("headerToken")
        : "",
      userData: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {},
      confirmation_code: localStorage.getItem("confirmation_code")
        ? localStorage.getItem("confirmation_code")
        : "",
      openPicker: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.incrementAdult = this.incrementAdult.bind(this);
    this.decrementAdult = this.decrementAdult.bind(this);
    this.incrementchild = this.incrementchild.bind(this);
    this.decrementchild = this.decrementchild.bind(this);
    this.incrementinfant = this.incrementinfant.bind(this);
    this.decrementinfant = this.decrementinfant.bind(this);
    this.incrementpets = this.incrementpets.bind(this);
    this.decrementpets = this.decrementpets.bind(this);
    this.guestDetail = this.guestDetail.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.getclientToken = this.getclientToken.bind(this);
    // this.UpdateStripeintent = this.UpdateStripeintent.bind(this);
    this.checkAvailabilityDate = this.checkAvailabilityDate.bind(this);
    this.changeDateHandlerNew = this.changeDateHandlerNew.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateChangeRange = this.handleDateChangeRange.bind(this);
    // this.getCardData = this.getCardData.bind(this);
    // this.getChildData = this.getChildData.bind(this);
  }

  async componentDidMount() {
    // Get a client token for authorization from your server
    this.getclientToken();
    this.checkAvailabilityDate();
    this.setState({
      guest: parseInt(localStorage.getItem("maximum_number_of_guests")),
    });
    this.guestDetail();
    this.setInputData();
    this.getGuestFormDetails();
    // this.getCardData();
    var ele = document.getElementById("phoneNumberFormat");
    var eleValue = this.state.phone_number;
    this.setState({ phone_number: eleValue });
    localStorage.setItem("queryParams", JSON.stringify(queryParams));
    if (this.state.reservation_confirm !== "") {
      this.props.history.push("/reservation-list");
      //    var unit = document.getElementById("go-to-the-home-page");
      //  // var unit = document.getElementById("booking-success");
      //   unit.click();
    }
  }
  getGuestFormDetails = async () => {
    this.setState({ showSpinner: true, loading: true });
    var totalRecords = 0;
    var list = [];
    var formSubmited = "";
    var cardTokenise = "";
    var is_card_enable = "";
    var confirmationcode = this.state.confirmation_code;
    let res = await ReservationService.getGuestformdata(confirmationcode);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      totalRecords = list ? list.length : 0;
      formSubmited = res.data.is_form_submited ? res.data.is_form_submited : 0;
      cardTokenise = res.data.card_tokenise ? res.data.card_tokenise : 0;
      is_card_enable = res.data.is_card_enable ? res.data.is_card_enable:0;
    }
    this.setState({
      is_form_submited: formSubmited,
      card_tokenise: cardTokenise,
      is_card_enable:is_card_enable,
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
    });
    localStorage.setItem("is_form_submited", this.state.is_form_submited);
    localStorage.setItem("card_tokenise", this.state.card_tokenise);
    localStorage.setItem("card_enable", this.state.is_card_enable);
  };
  setInputData = () => {
    var first_name = this.state.input.first_name;
    var last_name = this.state.input.last_name;
    var email = this.state.input.email;
    var phone_number = this.state.input.phone_number;
    this.setState({
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
    });
  };
  cancelModal = async (value) => {
    this.setState({ cancelModal: value });
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    if (event.target.name === "street_address_1") {
      if (event.target.value.length > 1) {
        var errors = this.state.errors;
        errors.street_address_1 = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "zipcode") {
      if (event.target.value.length > 4) {
        var errors = this.state.errors;
        errors.zipcode = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "city") {
      if (event.target.value.length > 1) {
        var errors = this.state.errors;
        errors.city = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "state_name") {
      if (event.target.value.length > 1) {
        var errors = this.state.errors;
        errors.state_name = "";
        this.setState({ errors: errors });
      }
    }
    if (event.target.name === "country") {
      if (event.target.value.length > 1) {
        var errors = this.state.errors;
        errors.country = "";
        this.setState({ errors: errors });
      }
    }
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
  };
  handleDateChangeRange = async (date, name) => {
    let input = this.state.input;
    input.move_in_date = Moment(new Date(date.value[0])).format("MM/DD/YYYY");
    input.move_out_date = Moment(new Date(date.value[1])).format("MM/DD/YYYY");
    await this.setState({
      startDate: input.move_in_date,
      endDate: input.move_out_date,
      input: input,
    });
  };

  setErrorFromCheckout(errors) {
    //this.setState({ loader: false });
    //  var errors = {};
    if (errors.street_address_1 === "") {
      errors.street_address_1 = "Street name is required.";
    }
    // if (!errors.city) {
    //   errors.city = "City name is required.";
    // }
    // if (!errors.state) {
    //   errors.state = "State name is required.";
    // }
    // if (!errors.country) {
    //   errors.country = "Country name is required.";
    // }
    // if (!errors.zipcode) {
    //   errors.zipcode = "Zipcode is required.";
    // }
    // if (!errors.phone_number) {
    //   errors.phone_number = "Phone number is required.";
    // }
    this.setState({ errors: errors });
  }

  clearLocalStorage = () => {
    localStorage.removeItem("pricing");
    localStorage.removeItem("checkin");
    localStorage.removeItem("checkout");
    localStorage.removeItem("guest_email");
    localStorage.removeItem("userToken");
    localStorage.removeItem("reservationDetails");
    localStorage.removeItem("listing_slug");
    localStorage.removeItem("listing_name");
    localStorage.removeItem("queryParams");
  };

  SaveTransactiondata = async (inputData) => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    let res = await CmsDataService.saveTransaction(inputData);

    if (global.successStatus.includes(res.status)) {
      var address = this.state.street_address_1
        ? this.state.street_address_1 + ","
        : "";
      address += this.state.street_address_2
        ? this.state.street_address_2 + ","
        : "";
      address += this.state.country ? this.state.country + "," : "";
      address += this.state.state ? this.state.state + "," : "";
      address += this.state.zipcode ? this.state.zipcode + "." : "";

      var reservationData = {
        reservation_slug: this.state.reservation_slug,
        guest_slug: this.state.guest_slug,
        payment_status: 2,
        user_token: this.state.guest_slug,
        phone_number: this.state.phone_number,
        street_address_1: this.state.street_address_1
          ? this.state.street_address_1
          : "",
        street_address_2: this.state.street_address_2
          ? this.state.street_address_2
          : "",
        city: this.state.cityId ? this.state.cityId : "",
        contry: this.state.countryId ? this.state.countryId : "",
        state: this.state.stateId ? this.state.stateId : "",
        zipcode: this.state.zipcode ? this.state.zipcode : "",
        address: address,
      };
      localStorage.setItem("addressDetails", JSON.stringify(reservationData));
      localStorage.setItem("reservation_confirm", this.state.guest_slug);
      let res = await ReservationService.updateReservationData(reservationData);

      await this.clearLocalStorage();
      resDataArr = res.data ? res.data : [];
      await this.setState({
        transactionData: resDataArr,
      });
      var unit = document.getElementById("booking-success");
      unit.click();
    } else {
    }
  };

  getclientToken = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var stripe_id = [];
    // let inputData = {
    //   customer_id: this.state.customer_id, payment: 1000, guest_slug: this.state.guest_slug, reservation_slug: this.state.reservation_slug ? this.state.reservation_slug : ""
    // }
    let inputData = {
      customer_id: this.state.customer_id,
      payment: this.state.pricing.total_listing_price_with_taxes_fees
        ? parseFloat(
            this.state.pricing.total_listing_price_with_taxes_fees
          ).toFixed(2)
        : parseInt(0).toFixed(2),
      guest_slug: this.state.guest_slug,
      reservation_slug: this.state.reservation_slug
        ? this.state.reservation_slug
        : "",
    };
    let res = await CmsDataService.getclientToken(inputData);
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data[0] : [];
      stripe_id = res.data ? res.data[1] : [];
    }
    await this.setState({
      clientToken: resDataArr,
      stripe_id: stripe_id,
    });
  };

  // getCardData = async (queryString = "") => {
  //   var resData = {};
  //   var resDataArr = [];
  //   var stripe_id = [];
  //   if (this.state.customer_id) {
  //     let res = await CmsDataService.getcardData(this.state.customer_id);
  //     if (global.successStatus.includes(res.status)) {
  //       resDataArr = res.data ? res.data[0] : [];
  //       stripe_id = res.data ? res.data[1] : [];
  //     }
  //     await this.setState({
  //       CardData: res
  //     });
  //   }
  // };

  handleChange = (event) => {
    this.setState({ personName: event.target.value });
  };

  handleDateChange(event) {
    this.setState({ selectedDate: event.target.value });
  }

  changeHandlerPhone = (phoneNumber) => {
    this.setState({ phone_number: phoneNumber });
    if (phoneNumber.length > 8) {
      var errors = this.state.errors;
      errors.phone_number = "";
      this.setState({ errors: errors });
    }

    if (phoneNumber.length > 1) {
      var errors = this.state.errors;
      errors.phone_number = "";
      this.setState({ errors: errors });
    }
    if (phoneNumber.length > 15 || phoneNumber.length < 8) {
      var errors = this.state.errors;
      errors.phone_number = "The phone number must be a valid phone number.";
      this.setState({ errors: errors });
    }
  };

  changeHandlerCoupon = (event) => {
    //  console.log(event);
    var coupon_code = event.target.value;
    this.setState({ coupon_code: coupon_code });
    if (this.state.coupon_code) {
      //apply check coupon logic and get response from api
    }
  };
  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }
  checkAvailabilityDate = () => {
    var inputData = {
      listing_slug: this.state.listing_slug,
      checkin_date: this.state.startDate,
      checkout_date: this.state.endDate,
      user_token: this.state.userToken,
      level: 5,
    };
    this.getAvailibityDate(inputData);
    // this.createQueryString();
  };
  validateDateChangeMobile = async () => {
    if (this.state.startDate === "") {
      this.setState({ openPicker: true });
    } else {
      await this.checkAvailabilityDate();
      //   this.setState({redirectToNextPage:true});
    }
    if (
      this.state.startDate !== "" &&
      (this.state.endDate === "" || this.state.endDate === null)
    ) {
      this.setState({ endDate: Moment(this.state.startDate).add(1, "days") });
    }
  };
  guestDetail = () => {
    var message = "";
    message +=
      this.state.adultCount > 1
        ? this.state.adultCount + " Guests"
        : this.state.adultCount + " Guest";
    message += this.state.childCount
      ? this.state.childCount > 1
        ? ", " + this.state.childCount + " Children"
        : ", " + this.state.childCount + " Child"
      : "";
    message += this.state.infantCount
      ? this.state.infantCount > 1
        ? ", " + this.state.infantCount + " Infants"
        : ", " + this.state.infantCount + " Infant"
      : "";
    message += this.state.petsCount
      ? this.state.petsCount > 1
        ? ", " + this.state.petsCount + " Pets"
        : ", " + this.state.petsCount + " Pet"
      : "";
    this.setState({ guestValue: message });
    // return message;
  };
  changeDateHandlerNewMobile = async (date) => {
    var startDate = date.value[0]
      ? Moment(date.value[0]).format("YYYY-MM-DD")
      : "";
    var endDate = date.value[1]
      ? Moment(date.value[1]).format("YYYY-MM-DD")
      : "";
    // if(startDate !== "" && (endDate === "" || endDate === null )){
    //   await this.setState({endDate:Moment(startDate).add(1, "days").format("YYYY-MM-DD")})
    //   endDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
    // }
    await this.setState({
      startDate: startDate,
      endDate: endDate,
      redirectToNextPage: true,
    });
    // await this.createQueryString();
    // await this.creatAirBnbQueryString();
    await this.checkAvailabilityDate();
  };

  changeDateHandlerNew = async (date) => {
    var startDate = date.value[0]
      ? Moment(date.value[0]).format("YYYY-MM-DD")
      : "";
    var endDate = date.value[1]
      ? Moment(date.value[1]).format("YYYY-MM-DD")
      : "";
    // if(startDate !== "" && (endDate === "" || endDate === null )){
    //   await this.setState({endDate:Moment(startDate).add(1, "days").format("YYYY-MM-DD")})
    //   endDate = Moment(startDate).add(1, "days").format("YYYY-MM-DD");
    // }
    await this.setState({ startDate: startDate, endDate: endDate });
    await this.checkAvailabilityDate();
    // await this.createQueryString();
    // await this.creatAirBnbQueryString();
  };
  async getAvailibityDate(dynamicData = "") {
    this.setState({ showSpinner: true, loading: true });
    var inputData =
      dynamicData !== ""
        ? dynamicData
        : {
            listing_slug: this.state.listing_slug,
            checkin_date: this.state.startDate,
            checkout_date: this.state.endDate,
            // user_token: this.state.userToken,
          };
    let res = await ReservationService.getAvailibityDate(inputData);
    var availableDate = [];
    var bookedDate = [];
    var pricing = [];
    var display_main_image_property = "";
    var userToken = "";
    var message = "";
    var redirectToNextPage = false;

    //let res = await UnitlistingApi.getUnitlisting();
    if (global.successStatus.includes(res.status)) {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      availableDate =
        res.data && res.data.available_dates ? res.data.available_dates : [];
      bookedDate =
        res.data && res.data.reserved_dates ? res.data.reserved_dates : [];
      pricing = res.data && res.data.pricing ? res.data.pricing : {};
      display_main_image_property =
        res.data && res.data.property
          ? res.data.property.property_main_image_url
          : "";
      redirectToNextPage = res.data && res.data.pricing ? true : false;
      if (pricing) {
        localStorage.setItem("pricing", JSON.stringify(pricing));
      }
      if (this.state.startDate && this.state.endDate) {
        localStorage.setItem("checkin", this.state.startDate);
        localStorage.setItem("checkout", this.state.endDate);
      }
    } else {
      userToken = res.data && res.data.user_token ? res.data.user_token : "";
      message = res.data && res.data.message ? res.data.message : "";
      availableDate =
        res.data && res.data.result ? res.data.result.available_dates : [];
      bookedDate =
        res.data && res.data.reserved_dates ? res.data.reserved_dates : [];
      redirectToNextPage = false;
    }
    this.setState({
      availableDate: availableDate,
      bookedDate: bookedDate,
      enableDatePicker: false,
      message: message,
      pricing: pricing,
      display_main_image_property: display_main_image_property,
      redirectToNextPage: redirectToNextPage,
    });

    this.UpdateStripeintent();
    this.setState({ showSpinner: false, loading: false });
  }
  UpdateStripeintent = async (queryString = "") => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var stripe_id = [];
    // let inputData = {
    //   customer_id: this.state.customer_id, payment: 1000, guest_slug: this.state.guest_slug, reservation_slug: this.state.reservation_slug ? this.state.reservation_slug : ""
    // }
    let inputData = {
      stripe_id: this.state.stripe_id,
      payment: this.state.pricing.total_listing_price_with_taxes_fees
        ? parseFloat(
            this.state.pricing.total_listing_price_with_taxes_fees
          ).toFixed(2)
        : parseInt(0).toFixed(2),
    };
    if(this.state.stripe_id !== ''){
    let res = await CmsDataService.updateIntent(inputData);
    }
  };
  async incrementAdult() {
    await this.setState({
      adultCount: this.state.adultCount + 1,
      total_selected_guest: this.state.total_selected_guest + 1,
    });
    // await this.createQueryString();
    await this.guestDetail();
  }

  async decrementAdult() {
    if (this.state.adultCount === 0 || this.state.adultCount === 1) {
      await this.setState({ adultCount: 1 });
    } else {
      await this.setState({
        adultCount: this.state.adultCount - 1,
        total_selected_guest: this.state.total_selected_guest - 1,
      });
    }
    // await this.createQueryString();
    await this.guestDetail();
  }

  async incrementchild() {
    await this.setState({
      childCount: this.state.childCount + 1,
      total_selected_guest: this.state.total_selected_guest + 1,
    });
    // await this.createQueryString();
    await this.guestDetail();
  }

  async decrementchild() {
    if (this.state.childCount === 0) {
      await this.setState({ childCount: 0 });
    } else {
      await this.setState({
        childCount: this.state.childCount - 1,
        total_selected_guest: this.state.total_selected_guest - 1,
      });
    }
    // await this.createQueryString();
    await this.guestDetail();
  }

  async incrementinfant() {
    await this.setState({ infantCount: this.state.infantCount + 1 });
    // await this.createQueryString();
    await this.guestDetail();
  }

  async decrementinfant() {
    if (this.state.infantCount === 0) {
      await this.setState({ infantCount: 0 });
    } else {
      await this.setState({ infantCount: this.state.infantCount - 1 });
    }
    // await this.createQueryString();
    await this.guestDetail();
  }

  async incrementpets() {
    await this.setState({ petsCount: this.state.petsCount + 1 });
    // await this.createQueryString();
    await this.guestDetail();
  }

  async decrementpets() {
    if (this.state.petsCount === 0) {
      await this.setState({ petsCount: 0 });
    } else {
      await this.setState({ petsCount: this.state.petsCount - 1 });
    }
    // await this.createQueryString();
    await this.guestDetail();
  }
  manageEditDateModal(modal = false) {
    this.setState({ editDateModal: modal, enableDatePicker: false });
  }
  manageGuestModal(modal = false) {
    this.setState({ editGuestModal: modal });
  }
  render() {
    const options = {
      // passing the client secret obtained in step 3
      clientSecret: this.state.clientToken,
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      },
    };
    const min = Moment(new Date()).format("YYYY-MM-DD");
    // const [value, setValue] = React.useState(options[0]);
    // const [inputValue, setInputValue] = React.useState('');

    return (
      <main className="oh-template Brandmainpage">
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        {/* Header */}
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <a
          hidden
          className="hidden"
          href={global.themeUrl + this.state.property_slug + ""}
        >
          <button id="go-to-the-home-page"></button>
        </a>
        <section className="middle">
          <div className="container-fluid p-0">
            <section className="reservationBook req_book_sec bg-white bgwhitecolor">
              <div className="container ">
                <div className="content">
                  <div className="row mobView m-0">
                    <div className="col-sm-12 col-lg-12 headingpage">
                      <div className="d-flex ResivationTitle">
                        <a href="#" className="m-0">
                          {" "}
                          <img
                            src={global.OnKeyPressEvent.getS3ImageUrl(
                              "back-arrow-icon.svg"
                            )}
                          />
                        </a>
                        <h4> Confirm and pay </h4>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 yourtrip loggedinmob">
                      <div className="requestSection">
                        <div className="row book_app_info">
                          <div className="col-12">
                            <div className="yourloggedin">
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "loggedin.svg"
                                )}
                              />
                              <div className="mg-l-3">
                                <span className="pd-t-0 pd-b-0 labelTitle">
                                  Hi, you're logged in
                                </span>
                                <p>Review your booking details to continue.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 yourtrip">
                      <div className="requestSection loggedindesk">
                        <div className="row book_app_info">
                          <div className="col-12">
                            <div className="yourloggedin">
                              <img
                                src={global.OnKeyPressEvent.getS3ImageUrl(
                                  "loggedin.svg"
                                )}
                              />
                              <div className="mg-l-3">
                                <span className="pd-t-0 pd-b-0 labelTitle">
                                  Hi, you're logged in
                                </span>
                                <p>Review your booking details to continue.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="requestSection">
                        <div className="row book_app_info m-0">
                          <div className="col-md-12 pd-l-0 pd-r-0 mg-b-0 d-flex- flex-column align-items-start justify-content-start first">
                            <span className="pd-t-10 pd-l-0 pd-r-0 pd-b-20 labelTitle">
                              Your trip{" "}
                            </span>

                            {this.state.message && (
                              <>
                                <p className="text-danger">
                                  {" "}
                                  {this.state.message}
                                </p>
                              </>
                            )}
                            <div className="col-md-12 pd-l-0 pd-r-0 mg-b-30 d-flex flex-row align-items-start justify-content-between">
                              <div className="w-75">
                                <strong className="d-block">Dates</strong>
                                <span className="d-block dateinputmobile">
                                  <Datepicker
                                    controls={["calendar"]}
                                    buttons={[
                                      {
                                        // text: "X",
                                        icon: "material-close",
                                        handler: "cancel",
                                      },
                                    ]}
                                    pages={2}
                                    calendarType="month"
                                    // invalid={[]}
                                    select="range"
                                    inputComponent="input"
                                    // inputProps={inputProps}
                                    // selected={this.state.selectedDate}
                                    value={[
                                      this.state.startDate,
                                      this.state.endDate,
                                    ]}
                                    // onChange={(date) =>
                                    //   this.handleDateChangeRange(date, "dateFour")
                                    // }
                                    onChange={(date) =>
                                      this.changeDateHandlerNew(
                                        date,
                                        "dateFour"
                                      )
                                    }
                                    // invalid={this.state.bookedDate}
                                    valid={this.state.availableDate}
                                    // invalid={this.state.bookedDate}
                                    // valid={this.state.availableDate}
                                    cssClass="dual_calendar"
                                    inputProps={{
                                      placeholder: "Add date",
                                    }}
                                    isOpen={this.state.openPicker}
                                    openPicker={() =>
                                      this.setState({ openPicker: true })
                                    }
                                    onLabelClick={() =>
                                      this.setState({ openPicker: true })
                                    }
                                    onInputClick={() =>
                                      this.setState({ openPicker: true })
                                    }
                                    onFocus={() =>
                                      this.setState({ openPicker: true })
                                    }
                                    onClick={() =>
                                      this.setState({ openPicker: true })
                                    }
                                    onClose={() =>
                                      this.setState({ openPicker: false })
                                    }
                                    closeOnEsc={true}
                                    defaultValue={undefined}
                                    display="center"
                                    rangeSelectMode="wizard"
                                    showRangeLabels={true}
                                    touchUi={false}
                                    theme="ios"
                                    themeVariant="light"
                                    headerText=""
                                    minRange={2}
                                    max=""
                                    min={min}
                                    inRangeInvalid={false}
                                    rangeEndInvalid={true}
                                    rangeStartLabel="Check-in"
                                    rangeEndLabel="Check-out"
                                    responsive={{
                                      xsmall: {
                                        pages: 2,
                                        touchUi: false,
                                        calendarSize: "2",
                                        calendarScroll: "horizontal",
                                        display: "anchored",
                                        refDate: Moment(new Date()).add(
                                          1,
                                          "year"
                                        ),
                                      },
                                      medium: {
                                        // display: 'center',
                                        // display: 'anchored',
                                        pages: 2,
                                        touchUi: false,
                                      },
                                      // custom: {
                                      //   breakpoint: 850,
                                      //   display: 'center',
                                      // pages: 2,
                                      //   touchUi: false
                                      //}
                                    }}
                                  />
                                </span>
                              </div>
                              <p
                                className="Link"
                                onClick={() =>
                                  this.setState({ openPicker: true })
                                }
                              >
                                Edit
                              </p>
                            </div>

                            <div className="col-md-12 pd-l-0 pd-r-0 d-flex flex-row align-items-start justify-content-between guestedit">
                              <div>
                                <strong className="d-block">Guests</strong>
                                <span>{this.state.guestValue}</span>
                              </div>
                              <p
                                className="Link"
                                onClick={() => this.manageGuestModal(true)}
                              >
                                Edit
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-md-6 pd-l-10 pd-r-10 mg-b-30 d-flex flex-column align-items-end justify-content-start last">
                            <p
                                className="Link"
                                onClick={() => this.manageGuestModal(true)}
                              >
                                Edit
                              </p>


                          </div> */}
                        </div>
                      </div>
                      <div className="requestSection">
                        <div className="row book_app_info guestDetails">
                          <div className="col-12">
                            <span className="pd-t-10 pd-b-20 labelTitle">
                              Guest details
                            </span>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mg-b-30">
                            <TextField
                              id="first_name"
                              label="First name"
                              variant="outlined"
                              fullWidth
                              type="text"
                              disabled={true}
                              className="borderInput textfield"
                              name="first_name"
                              value={this.state.first_name}
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mg-b-30">
                            <TextField
                              id="last_name"
                              label="Last name"
                              variant="outlined"
                              type={"text"}
                              fullWidth
                              disabled={true}
                              className="borderInput textfield"
                              name="last_name"
                              value={this.state.last_name}
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mg-b-30">
                            <TextField
                              type="email"
                              id="email"
                              label="Email"
                              variant="outlined"
                              fullWidth
                              className="borderInput textfield"
                              name="email"
                              disabled={true}
                              value={this.state.email}
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 mg-b-30">
                            {/* <p>{this.state.displayPhoneNo}</p> */}
                            <PhoneInput
                              country={global.country}
                              // disableSearchIcon
                              // autoFormat="true"
                              // enableSearch="true"
                              // countryCodeEditable="true"
                              className="borderInput textfield phoneInput"
                              variant="outlined"
                              id="phone_number"
                              name="phone_number"
                              value={this.state.phone_number}
                              disabled={
                                this.state.old_phone_number !== ""
                                  ? true
                                  : false
                              }
                              inputProps={{
                                id: "phoneNumberFormat",
                              }}
                              onKeyPress={
                                global.OnKeyPressEvent.phoneValidation
                              }
                              containerClass=""
                              error={
                                this.state.errors.phone_number ? true : false
                              }
                              helperText={
                                this.state.errors.phone_number
                                  ? this.state.errors.phone_number
                                  : ""
                              }
                              onChange={(e) => this.changeHandlerPhone(e)}
                            />
                            {this.state.errors.phone_number ? (
                              <p className="error-message">
                                {this.state.errors.phone_number}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="requestSection">
                        <div className="row book_pay_info paywith">
                          {/* <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">

      </div> */}
                          <div className="col-12 pd-l-15 pd-r-15">
                            <span className="pd-t-10 pd-b-20 labelTitle ">
                              Pay with
                            </span>
                            {/* <span className="subhead ">Payment info</span> */}
                          </div>

                          {/* <div className="googleplaceInput">
      <GooglePlaces getChildData={this.getChildData.bind(this)}  street_address_1={this.state.street_address_1} street_address_2={this.state.street_address_2} city={this.state.city} state={this.state.state} country={this.state.country} zipcode={this.state.zipcode}  variant="outlined" errors={this.state.errors}/>
      {/* {this.state.errors.street_address_1 ? (
      <p className="error-message">
        {this.state.errors.street_address_1}
      </p>
    ) : (
      ""
    )} */}
                          {/* </div> */}

                          {!this.state.clientToken ? (
                            <div>
                              <h3>Loading...</h3>
                            </div>
                          ) : (
                            <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-">
                              {/* <DropIn
        options={{
          authorization: this.state.clientToken,
        }}
        onInstance={(instance) =>
          (this.instance = instance)
        }
      /> */}
                              {/* <CheckoutForm> */}
                              {/* <Elements stripe={stripePromise} options={options}>
         <PaymentElement />
        </Elements>
         */}
                              <Elements
                                stripe={stripePromise}
                                options={options}
                              >
                                <CheckoutForm
                                  guest_slug={this.state.guest_slug}
                                  reservation_slug={
                                    this.state.reservation_slug
                                      ? this.state.reservation_slug
                                      : ""
                                  }
                                  street_address_1={
                                    this.state.street_address_1
                                      ? this.state.street_address_1
                                      : ""
                                  }
                                  phone_number={this.state.phone_number}
                                  street_address_2={
                                    this.state.street_address_2
                                      ? this.state.street_address_2
                                      : ""
                                  }
                                  city={this.state.city ? this.state.city : ""}
                                  contry={
                                    this.state.country ? this.state.country : ""
                                  }
                                  state={
                                    this.state.state ? this.state.state : ""
                                  }
                                  zipcode={
                                    this.state.zipcode ? this.state.zipcode : ""
                                  }
                                  address={this.state.address}
                                  stripe_id={this.state.stripe_id}
                                  countryId={this.state.countryId}
                                  stateId={this.state.stateId}
                                  cityId={this.state.cityId}
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  propertySlug={this.state.property_slug}
                                  confirmation_code={
                                    this.state.confirmation_code
                                  }
                                  adults={this.state.adultCount}
                                  children={this.state.childCount}
                                  infants={this.state.infantCount}
                                  last_name={
                                    this.state.last_name
                                      ? this.state.last_name
                                      : this.state.userData.last_name
                                  }
                                  listing_slug={this.state.listing_slug}
                                  total_selected_guest={
                                    this.state.total_selected_guest
                                  }
                                  redirectToNextPage={
                                    this.state.redirectToNextPage
                                  }
                                  user_token={this.state.userToken}
                                  payment={
                                    this.state.pricing
                                      .total_listing_price_with_taxes_fees
                                      ? parseFloat(
                                          this.state.pricing
                                            .total_listing_price_with_taxes_fees
                                        ).toFixed(2)
                                      : parseInt(0).toFixed(2)
                                  }
                                  // getAvailibityDate={this.getAvailibityDate.bind(this)}
                                  // validationerrors={this.state.bind(setErrorFromCheckout)}
                                  validationErrorsHandler={this.setErrorFromCheckout.bind(
                                    this
                                  )}
                                />
                              </Elements>
                              {/* <button onClick={this.buy.bind(this)}>Buy</button> */}
                            </div>
                          )}

                          {/* <div className="col-md-12 pd-l-15 pd-r-15 mg-b-0 d-flex justify-content-center">
                            <a href={global.themeUrl + this.state.property_slug + "/booking-confirmation"} hidden className="w-100 text-decoration-none hidden" rel="noopener noreferrer">
                              <button className="w-100" id="booking-success">Complete Booking</button></a>

                            <button
                              className={"w-100 d-none"}
                              // onClick={this.buy.bind(this)}
                              disabled={this.state.loader ? true : false}
                            >
                              Complete Booking
                              {this.state.loader ?
                                <>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                                : ""
                              }
                            </button>
                          </div> */}
                        </div>
                        {/* <div className="row">
                                  <span className="subhead">Payment Info</span>
                                     {!this.state.clientToken ? (
                                    <div>
                                      <h1>Loading...</h1>
                                    </div>
                                  ) : (
                                    <div>
                                      <DropIn
                                        options={{
                                          authorization: this.state.clientToken,
                                        }}
                                        onInstance={(instance) =>
                                          (this.instance = instance)
                                        }
                                      />

                                    </div>
                                  )}

                                  <div className="col-md-12 pd-l-15 pd-r-15 mg-b-0 d-flex justify-content-center">
                                    <a
                                      href={
                                        global.themeUrl +
                                        this.state.property_slug +
                                        "/booking-confirmation"
                                      }
                                      hidden
                                      className="w-100 text-decoration-none hidden"
                                      rel="noopener noreferrer"
                                    >
                                      <button className="w-100" id="booking-success">
                                        Complete Booking
                                      </button>
                                    </a>

                                    <button
                                      className={"w-100"}
                                      onClick={this.buy.bind(this)}
                                      disabled={this.state.loader ? true : false}
                                    >
                                      Complete Booking
                                      {this.state.loader ? (
                                        <>
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </button>
                                  </div>
                                </div> */}
                      </div>
                      {/* <div className="requestSection mg-t-25">
                        <div className="row book_app_info cancellations">
                          <div className="col-12">
                            <span className="pd-t-10 pd-b-5 labelTitle">Cancellation policy</span>
                            <p>To receive a full refund, you must cancel within 48 hours of booking,   <b
                                    onClick={() =>
                                      this.cancelModal(true, this.state.currentData)
                                    }
                                  > Show more
                                  </b>.</p>
                          </div>

                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-1 col-xl-1"></div>
                    {this.state.redirectToNextPage === true ? (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 position-relative priceDetail">
                        <div className="requestaddedSection m-0">
                          <figure>
                            <img
                              src={
                                this.state.pricing
                                  ? this.state.pricing.listing_image
                                    ? this.state.pricing.listing_image
                                        .large_img_w_720
                                    : this.state.display_main_image_property
                                  : this.state.display_main_image_property
                              }
                              className="img-fluid"
                              alt="Property Logo"
                              title="Property Logo"
                            />
                          </figure>
                          <div className="priceInfo">
                            <div className="priceTop">
                              <h5>
                                {this.state.pricing.listing_name
                                  ? this.state.pricing.listing_name
                                  : this.state.property_name
                                  ? this.state.property_name
                                  : ""}
                              </h5>
                              {/* <h6>
                              {`${this.state.startDate
                                ? Moment(
                                  this.state.startDate
                                ).format("ddd, MMM DD")
                                : Moment(this.state.startDate).format(
                                  "ddd, MMM DD"
                                )
                                } - ${this.state.endDate
                                  ? Moment(
                                    this.state.endDate
                                  ).format("ddd, MMM DD")
                                  : Moment(this.state.endDate).format(
                                    "ddd, MMM DD"
                                  )
                                }`}{" "}
                              <b>
                                {" "}
                                {`${this.state.pricing.nights
                                  ? this.state.pricing.nights
                                  : 1
                                  }`}{this.state.pricing.nights > 1 ? " Nights" : " Night"}
                              </b>
                            </h6> */}
                            </div>
                            <div className="priceMiddle">
                              <span className="mg-b-20 d-block">
                                Price details
                              </span>
                              <ul>
                                <li>
                                  {/* <span>{` US$${this.state.pricing.nightly_rate ? parseFloat(this.state.pricing.nightly_rate).toFixed(2) : parseFloat(1).toFixed(2)}  × ${this.state.pricing.nights ? this.state.pricing.nights : 1} Nights`}</span>
                                <span>{`US$${this.state.pricing.listing_price ? parseFloat(this.state.pricing.listing_price).toFixed(2) : parseFloat(1).toFixed(2) }`}</span> */}
                                  {/* <span>{`$${this.state.pricing.nightly_rate
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.nightly_rate
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseFloat(1).toFixed(2)
                                  }  × ${this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                  } Nights`}</span> */}
                                  <span>
                                    {`$${
                                      this.state.pricing.listing_price
                                        ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                            parseFloat(
                                              this.state.pricing.listing_price /
                                                this.state.pricing.nights
                                            )
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                          )
                                        : parseFloat(1).toFixed(2)
                                    }  × ${
                                      this.state.pricing.nights
                                        ? this.state.pricing.nights
                                        : 1
                                    }`}
                                    {this.state.pricing.nights > 1
                                      ? " nights"
                                      : " night"}
                                  </span>
                                  <span>{`$${
                                    this.state.pricing.listing_price
                                      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                          parseFloat(
                                            this.state.pricing.listing_price
                                          )
                                            .toFixed(2)
                                            .replace(/\.00$/, "")
                                        )
                                      : parseFloat(1).toFixed(2)
                                  }`}</span>
                                </li>
                                {/* {this.state.pricing ? (
                                this.state.pricing.fees ? (
                                  <li>
                                    <span>Service fee</span>
                                    <span>{`$${this.state.pricing.fees
                                      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                        (parseFloat(
                                          this.state.pricing.fees
                                        ).toFixed(2).replace(/\.00$/, ""))
                                      )
                                      : parseInt(0).toFixed(2)
                                      }`}</span>
                                  </li>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )} */}
                                {this.state.pricing ? (
                                  this.state.pricing.cleaning_fee &&
                                  this.state.pricing.cleaning_fee !== "0.00" ? (
                                    <li>
                                      <span>Cleaning fee</span>
                                      {/* <span>{` US$${this.state.pricing.fees ? parseFloat(this.state.pricing.fees).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                      <span>{`$${
                                        this.state.pricing.cleaning_fee
                                          ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                              parseFloat(
                                                this.state.pricing.cleaning_fee
                                              )
                                                .toFixed(2)
                                                .replace(/\.00$/, "")
                                            )
                                          : parseInt(0).toFixed(2)
                                      }`}</span>
                                    </li>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}

                                {/* {this.state.pricing ? (
                                this.state.pricing.convenience_fee && this.state.pricing.convenience_fee !== 0 ? ( */}
                                <li>
                                  <span>Service fees</span>

                                  {/* <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                  {/* <span>${this.state.pricing.nightly_rate * 8 /100}</span> */}
                                  <span>{`$${
                                    this.state.pricing.convenienceFee
                                      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                          parseFloat(
                                            this.state.pricing.convenienceFee
                                          )
                                            .toFixed(2)
                                            .replace(/\.00$/, "")
                                        )
                                      : parseInt(0).toFixed(2)
                                  }`}</span>
                                </li>

                                {this.state.pricing ? (
                                  this.state.pricing.total_of_dollar_taxes ? (
                                    <li>
                                      <span>Occupancy taxes</span>
                                      {/* <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                      {/* <b> */}
                                      <span>{`$${
                                        this.state.pricing.total_of_dollar_taxes
                                          ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                              parseFloat(
                                                this.state.pricing
                                                  .total_of_dollar_taxes
                                              )
                                                .toFixed(2)
                                                .replace(/\.00$/, "")
                                            )
                                          : parseInt(0).toFixed(2)
                                      }`}</span>
                                    </li>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}

                                {/* ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )} */}
                              </ul>
                            </div>
                            <div className="priceBottom">
                              <ul>
                                <li>
                                  <span>Total (USD)</span>
                                  {/* <span>{` US$${this.state.pricing.total_listing_price_with_taxes_fees ? parseFloat(this.state.pricing.total_listing_price_with_taxes_fees).toFixed(2) : parseInt(0).toFixed(2)}`}</span> */}
                                  <b>
                                    <span>{`$${
                                      this.state.pricing
                                        .total_listing_price_with_taxes_fees
                                        ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                            parseFloat(
                                              this.state.pricing
                                                .total_listing_price_with_taxes_fees
                                            )
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                          )
                                        : parseInt(0).toFixed(2)
                                    }`}</span>
                                  </b>
                                </li>
                              </ul>
                            </div>
                            {/* <div className="d-flex align-items-center promoCode">
                            <div className="col-xs-6 col-sm-6 col-md-12 pd-l-0 pd-r-0 mg-b-30 mg-t-30">
                            <TextField
                              id="coupon_code"
                              label="Enter promo code"
                              variant="outlined"
                              type={"text"}
                              fullWidth
                              onChange={this.changeHandlerCoupon}
                              //disabled={true}
                              className="borderInput textfield"
                              name="coupon_code"
                              value={this.state.coupon_code}
                            />
                          </div>
                          <button
                            className={"w-100"}
                            disabled={!this.state.coupon_code}
                            >Apply</button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <div className=" col-sm-7 col-lg-6">
                      <div className="d-flex ResivationTitle">
                        <a href="#" className="m-0"> <img src={global.OnKeyPressEvent.getS3ImageUrl('back-arrow-icon.svg')} /></a>
                        <h4> Confirm and pay </h4>
                      </div>

                      <div className="requestSection">

                        <div className="row book_app_info">
                          <div className="col-12 pd-l-0 ">
                            <span className="subhead">Guest details</span>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <TextField
                              id="first_name"
                              label="First name"
                              variant="outlined"
                              fullWidth
                              type="text"
                              disabled={true}
                              className="borderInput textfield"
                              name="first_name"
                              value={this.state.first_name}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <TextField
                              id="last_name"
                              label="Last name"
                              variant="outlined"
                              type={"text"}
                              fullWidth
                              disabled={true}
                              className="borderInput textfield"
                              name="last_name"
                              value={this.state.last_name}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <TextField
                              type="email"
                              id="email"
                              label="Email"
                              variant="outlined"
                              fullWidth
                              className="borderInput textfield"
                              name="email"
                              disabled={true}
                              value={this.state.email}
                            />
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 pd-l-15 pd-r-15 mg-b-30">
                            <p>{this.state.displayPhoneNo}</p>
                            <PhoneInput
                              country={global.country}
                              disableSearchIcon
                              autoFormat="true"
                              enableSearch="true"
                              countryCodeEditable="true"
                              className="borderInput textfield phoneInput"
                              variant="outlined"
                              id="phone_number"
                              name="phone_number"
                              value={this.state.phone_number}
                              disabled={this.state.old_phone_number !== "" ? true : false}
                              inputProps={{
                                id: "phoneNumberFormat"
                              }}
                              onKeyPress={global.OnKeyPressEvent.phoneValidation}
                              containerClass=""
                              error={this.state.errors.phone_number ? true : false}
                              helperText={this.state.errors.phone_number ? this.state.errors.phone_number : ""}
                              onChange={(e) => this.changeHandlerPhone(e)}
                            />
                            {this.state.errors.phone_number ? (
                              <p className="error-message">
                                {this.state.errors.phone_number}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                        </div>


                        <div className="row book_pay_info">
                          <div className="requestSection">
                            <div className="row">
                             <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-15 mg-b-30">

                              </div>
                              <div className="col-12 pd-l-0">
                                <span className="subhead ">Payment info</span>
                              </div>


                              <div className="googleplaceInput">
                              <GooglePlaces getChildData={this.getChildData.bind(this)}  street_address_1={this.state.street_address_1} street_address_2={this.state.street_address_2} city={this.state.city} state={this.state.state} country={this.state.country} zipcode={this.state.zipcode}  variant="outlined" errors={this.state.errors}/>
                             {this.state.errors.street_address_1 ? (
                              <p className="error-message">
                                {this.state.errors.street_address_1}
                              </p>
                            ) : (
                              ""
                            )}
                              </div> 


                              {!this.state.clientToken ? (
                                <div>
                                  <h1>Loading...</h1>
                                </div>
                              ) : (
                                <div className="col-xs-12 col-sm-12 col-md-12 pd-l-15 pd-r-">
                                  <DropIn
                                options={{
                                  authorization: this.state.clientToken,
                                }}
                                onInstance={(instance) =>
                                  (this.instance = instance)
                                }
                              />
                                  <CheckoutForm>
                                  <Elements stripe={stripePromise} options={options}>
                                 <PaymentElement />
                                </Elements>
                                
                                  <Elements stripe={stripePromise} options={options}>
                                    <CheckoutForm guest_slug={this.state.guest_slug} reservation_slug={this.state.reservation_slug
                                      ? this.state.reservation_slug
                                      : ""}
                                      street_address_1={this.state.street_address_1
                                        ? this.state.street_address_1
                                        : ""}
                                      phone_number={this.state.phone_number}
                                      street_address_2={this.state.street_address_2
                                        ? this.state.street_address_2
                                        : ""}
                                      city={this.state.city ? this.state.city : ""}
                                      contry={this.state.country ? this.state.country : ""}
                                      state={this.state.state ? this.state.state : ""}
                                      zipcode={this.state.zipcode ? this.state.zipcode : ""}
                                      address={this.state.address}
                                      stripe_id={this.state.stripe_id}
                                      countryId={this.state.countryId}
                                      stateId={this.state.stateId}
                                      cityId={this.state.cityId}
                                      propertySlug={this.state.property_slug}
                                      validationerrors={this.state.bind(setErrorFromCheckout)}
                                      validationErrorsHandler={this.setErrorFromCheckout.bind(this)} />
                                  </Elements>
                                  <button onClick={this.buy.bind(this)}>Buy</button>
                                </div>
                              )}

                              <div className="col-md-12 pd-l-15 pd-r-15 mg-b-0 d-flex justify-content-center">
                                <a href={global.themeUrl + this.state.property_slug + "/booking-confirmation"} hidden className="w-100 text-decoration-none hidden" rel="noopener noreferrer">
                                  <button className="w-100" id="booking-success">Complete Booking</button></a>

                                <button
                                  className={"w-100 d-none"}
                                  onClick={this.buy.bind(this)}
                                  disabled={this.state.loader ? true : false}
                                >
                                  Complete Booking
                                  {this.state.loader ?
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    </>
                                    : ""
                                  }
                                </button>
                              </div>
                            </div>
                            <div className="row">
                          <span className="subhead">Payment Info</span>



                          {!this.state.clientToken ? (
                            <div>
                              <h1>Loading...</h1>
                            </div>
                          ) : (
                            <div>
                              <DropIn
                                options={{
                                  authorization: this.state.clientToken,
                                }}
                                onInstance={(instance) =>
                                  (this.instance = instance)
                                }
                              />

                            </div>
                          )}

                          <div className="col-md-12 pd-l-15 pd-r-15 mg-b-0 d-flex justify-content-center">
                            <a
                              href={
                                global.themeUrl +
                                this.state.property_slug +
                                "/booking-confirmation"
                              }
                              hidden
                              className="w-100 text-decoration-none hidden"
                              rel="noopener noreferrer"
                            >
                              <button className="w-100" id="booking-success">
                                Complete Booking
                              </button>
                            </a>

                            <button
                              className={"w-100"}
                              onClick={this.buy.bind(this)}
                              disabled={this.state.loader ? true : false}
                            >
                              Complete Booking
                              {this.state.loader ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </button>
                          </div>
                        </div> 
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-sm-5 col-lg-4 position-relative">
                      <div className="requestaddedSection m-0">
                        <figure>
                          <img
                            src={
                              this.state.pricing
                                ? this.state.pricing.listing_image
                                  ? this.state.pricing.listing_image
                                    .large_img_w_720
                                  : global.OnKeyPressEvent.getS3ImageUrl('aminity-list-1.jpg')
                                : global.OnKeyPressEvent.getS3ImageUrl('aminity-list-1.jpg')
                            }
                            className="img-fluid"
                            alt="Property Logo" title="Property Logo"
                          />
                        </figure>
                        <div className="priceInfo">
                          <div className="priceTop">
                            <h5>
                              {this.state.pricing.listing_name
                                ? this.state.pricing.listing_name
                                : this.state.property_name
                                  ? this.state.property_name
                                  : ""}
                            </h5>
                            <h6>
                              {`${this.state.startDate
                                ? Moment(
                                  this.state.startDate
                                ).format("ddd, MMM DD")
                                : Moment(this.state.startDate).format(
                                  "ddd, MMM DD"
                                )
                                } - ${this.state.endDate
                                  ? Moment(
                                    this.state.endDate
                                  ).format("ddd, MMM DD")
                                  : Moment(this.state.endDate).format(
                                    "ddd, MMM DD"
                                  )
                                }`}{" "}
                              <b>
                                {" "}
                                {`${this.state.pricing.nights
                                  ? this.state.pricing.nights
                                  : 1
                                  }`}{this.state.pricing.nights > 1 ? " Nights" : " Night"}
                              </b>
                            </h6>
                          </div>
                          <div className="priceMiddle">
                            <span className="mg-b-20 d-block">Price details</span>
                            <ul>
                              <li>
                                <span>{` US$${this.state.pricing.nightly_rate ? parseFloat(this.state.pricing.nightly_rate).toFixed(2) : parseFloat(1).toFixed(2)}  × ${this.state.pricing.nights ? this.state.pricing.nights : 1} Nights`}</span>
                                <span>{`US$${this.state.pricing.listing_price ? parseFloat(this.state.pricing.listing_price).toFixed(2) : parseFloat(1).toFixed(2)}`}</span>
                                <span>{`$${this.state.pricing.nightly_rate
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.nightly_rate
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseFloat(1).toFixed(2)
                                  }  × ${this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                  } Nights`}</span>
                                <span>{`$${this.state.pricing.listing_price
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.listing_price / this.state.pricing.nights
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseFloat(1).toFixed(2)
                                  }  × ${this.state.pricing.nights
                                    ? this.state.pricing.nights
                                    : 1
                                  }`}{this.state.pricing.nights > 1 ? " Nights" : " Night"}</span>
                                <span>{`$${this.state.pricing.listing_price
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing.listing_price
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseFloat(1).toFixed(2)
                                  }`}</span>
                              </li>
                              {this.state.pricing ? (
                                this.state.pricing.fees ? (
                                  <li>
                                    <span>Service fee</span>
                                    <span>{`$${this.state.pricing.fees
                                      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                        (parseFloat(
                                          this.state.pricing.fees
                                        ).toFixed(2).replace(/\.00$/, ""))
                                      )
                                      : parseInt(0).toFixed(2)
                                      }`}</span>
                                  </li>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {this.state.pricing ? (
                                this.state.pricing.cleaning_fee && this.state.pricing.cleaning_fee !== "0.00" ? (
                                  <li>
                                    <span>Cleaning fee</span>
                                    <span>{` US$${this.state.pricing.fees ? parseFloat(this.state.pricing.fees).toFixed(2) : parseInt(0).toFixed(2)}`}</span>
                                    <span>{`$${this.state.pricing.cleaning_fee
                                      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                        (parseFloat(
                                          this.state.pricing.cleaning_fee
                                        ).toFixed(2).replace(/\.00$/, ""))
                                      )
                                      : parseInt(0).toFixed(2)
                                      }`}</span>
                                  </li>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {this.state.pricing ? (
                                this.state.pricing.total_of_dollar_taxes ? (
                                  <li>
                                    <span>Occupancy taxes</span>
                                    <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span> 
                                    <b>
                                      <span>{`$${this.state.pricing.total_of_dollar_taxes
                                        ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                          (parseFloat(
                                            this.state.pricing
                                              .total_of_dollar_taxes
                                          ).toFixed(2).replace(/\.00$/, ""))
                                        )
                                        : parseInt(0).toFixed(2)
                                        }`}</span>

                                  </li>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {this.state.pricing ? (
                                this.state.pricing.convenience_fee && this.state.pricing.convenience_fee !== 0 ? (
                                  <li>
                                    <span>Service fees</span>

                                    <span>{` US$${this.state.pricing.total_of_dollar_taxes ? parseFloat(this.state.pricing.total_of_dollar_taxes).toFixed(2) : parseInt(0).toFixed(2)}`}</span>
                                    <span>${this.state.pricing.nightly_rate * 8 / 100}</span>
                                    <span>{`$${this.state.pricing.convenienceFee
                                      ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                        (parseFloat(
                                          this.state.pricing.convenienceFee
                                        ).toFixed(2).replace(/\.00$/, ""))
                                      )
                                      : parseInt(0).toFixed(2)
                                      }`}</span>
                                  </li>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                          <div className="priceBottom">
                            <ul>
                              <li>
                                <span>Total</span>
                                <span>{` US$${this.state.pricing.total_listing_price_with_taxes_fees ? parseFloat(this.state.pricing.total_listing_price_with_taxes_fees).toFixed(2) : parseInt(0).toFixed(2)}`}</span>
                                <b><span>{`$${this.state.pricing
                                  .total_listing_price_with_taxes_fees
                                  ? global.OnKeyPressEvent.numberWithCommasForDecimal(
                                    (parseFloat(
                                      this.state.pricing
                                        .total_listing_price_with_taxes_fees
                                    ).toFixed(2).replace(/\.00$/, ""))
                                  )
                                  : parseInt(0).toFixed(2)
                                  }`}</span></b>
                              </li>
                            </ul>
                          </div>
                          <div className="d-flex align-items-center promoCode">
                            <div className="col-xs-6 col-sm-6 col-md-12 pd-l-0 pd-r-0 mg-b-30 mg-t-30">
                              <TextField
                                id="coupon_code"
                                label="Enter promo code"
                                variant="outlined"
                                type={"text"}
                                fullWidth
                                onChange={this.changeHandlerCoupon}
                                //disabled={true}
                                className="borderInput textfield"
                                name="coupon_code"
                                value={this.state.coupon_code}
                              />
                            </div>
                            <button
                              className={"w-100"}
                              disabled={!this.state.coupon_code}
                            >Apply</button>
                          </div>

                        </div>
                      </div>

                    </div> */}
                    {/* <div className="col-lg-1"></div> */}
                  </div>
                </div>
              </div>
              {/* <div className="container pd-l-35 pd-r-35 mg-t-35 mg-b-35 mobAminity"> */}
              {/* <figure>
                                            <img src={aminityListimg1} className="img-fluid" />
                                        </figure> */}
              {/* </div> */}
            </section>
          </div>
        </section>

        <Modal
          show={this.state.editGuestModal}
          centered
          onHide={() => this.manageGuestModal(false)}
          className="guestModalbox"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span
              className="welcomeClose float-end"
              onClick={() => this.manageGuestModal(false)}
            >
              {global.closee}
            </span>
            <div className="dropmenuInputbox">
              <h4>Guests</h4>
              {/* <p>
                  This place has a maximum of 6 guests, not including infants.
                  Pets aren't allowed.
                </p> */}

              <div className="dropdown-item">
                <div className="d-flex justify-content-between align-items-start mg-t-15 mg-t-15">
                  <ul>
                    <li>
                      <strong>Adults</strong>
                      <span>Age 13+</span>
                    </li>
                  </ul>
                  <div className="d-flex align-items-center countBtn">
                    <span>
                      <button
                        onClick={this.decrementAdult}
                        disabled={this.state.adultCount === 1 ? true : false}
                      >
                        <img
                          src={global.OnKeyPressEvent.getS3ImageUrl(
                            "minus-icon.svg"
                          )}
                          alt="Guest Count"
                          title="Guest Count"
                        />
                      </button>
                    </span>
                    <span> {this.state.adultCount}</span>
                    <span>
                      <button
                        onClick={this.incrementAdult}
                        disabled={
                          parseInt(this.state.guest) ===
                          this.state.total_selected_guest
                            ? true
                            : false
                        }
                      >
                        <img
                          src={global.OnKeyPressEvent.getS3ImageUrl(
                            "add-icon.svg"
                          )}
                          alt="Guest Count"
                          title="Guest Count"
                        />
                      </button>
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-start mg-t-15 mg-t-15">
                  <ul>
                    <li>
                      <strong>Children</strong>
                      <span>Ages 2–12</span>
                    </li>
                  </ul>
                  <div className="d-flex align-items-center countBtn">
                    <span>
                      <button
                        onClick={this.decrementchild}
                        disabled={this.state.childCount === 0 ? true : false}
                      >
                        <img
                          src={global.OnKeyPressEvent.getS3ImageUrl(
                            "minus-icon.svg"
                          )}
                          alt="Guest Count"
                          title="Guest Count"
                        />
                      </button>
                    </span>
                    <span>{this.state.childCount}</span>
                    <span>
                      <button
                        onClick={this.incrementchild}
                        disabled={
                          parseInt(this.state.guest) ===
                          this.state.total_selected_guest
                            ? true
                            : false
                        }
                      >
                        <img
                          src={global.OnKeyPressEvent.getS3ImageUrl(
                            "add-icon.svg"
                          )}
                          alt="Guest Count"
                          title="Guest Count"
                        />
                      </button>
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-start mg-t-15 mg-t-15">
                  <ul>
                    <li>
                      <strong>Infants</strong>
                      <span>Under 2</span>
                    </li>
                  </ul>
                  <div className="d-flex align-items-center countBtn">
                    <span>
                      <button
                        onClick={this.decrementinfant}
                        disabled={this.state.infantCount === 0 ? true : false}
                      >
                        <img
                          src={global.OnKeyPressEvent.getS3ImageUrl(
                            "minus-icon.svg"
                          )}
                          alt="Guest Count"
                          title="Guest Count"
                        />
                      </button>
                    </span>
                    <span> {this.state.infantCount}</span>
                    <span>
                      <button
                        onClick={this.incrementinfant}
                        disabled={this.state.infantCount === 5 ? true : false}
                      >
                        <img
                          src={global.OnKeyPressEvent.getS3ImageUrl(
                            "add-icon.svg"
                          )}
                          alt="Guest Count"
                          title="Guest Count"
                        />
                      </button>
                    </span>
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between align-items-start mg-t-20 mg-t-20">
                      <ul>
                        <li>
                          <strong>Pets</strong>
                        </li>
                      </ul>
                      <div className="d-flex align-items-center countBtn">
                        <span>
                          <button onClick={this.decrementpets} disabled={this.state.decrementpets === 0 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('minus-icon.svg')} />
                          </button>
                        </span>
                      <span> {this.state.petsCount}</span>
                        <span>
                          <button onClick={this.incrementpets} disabled={this.state.incrementpets === 5 ? true : false}>
                            <img src={global.OnKeyPressEvent.getS3ImageUrl('add-icon.svg')} />
                          </button>
                        </span>
                      </div>
                    </div> */}

                <div className="d-flex justify-content-end">
                  <button
                    className="done"
                    id="cnt-close"
                    onClick={() => this.manageGuestModal(false)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.cancelModal}
          centered
          onHide={() => this.setState({ cancelModal: false })}
          className="reservationDetailmodalbox"
        >
          <span
            className="welcomeClose"
            onClick={() => this.setState({ cancelModal: false })}
          >
            {global.closee}
          </span>

          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="reservationDetails">
              {/* <h5 className="mb-0">Are you sure want to cancel?</h5> */}
              <h6>Cancellation policy for your listing</h6>
              <ul>
                <li>
                  To receive a full refund, you must cancel within 48 hours of
                  booking, and the cancellation must occur at least 14 days
                  before check-in
                </li>
                <li>
                  If you cancel between 7 and 14 days before check-in, you’ll be
                  paid 50% for all nights
                </li>
                <li>
                  If you cancel after that, you’ll be paid 100% for all nights
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
        {/* Footer */}

        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default BookTourPayment;
