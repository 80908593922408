//     							                          //
//  Program: Inventory.jsx                                //
//  Application: KINI HTML                                //
//  Option: Home Sharing Listing                          //
//  Developer: PS		                                  //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CommonHeader from "../common/CommonHeader";
import Moment from "moment";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutFormParking from './CheckoutFormParking';
import CmsDataService from "../../services/CmsDataService";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class Parking extends Component {
  constructor() {
    super();
    this.state = {
      currentData: localStorage.getItem("trip_details")
        ? JSON.parse(localStorage.getItem("trip_details"))
        : [],
      headerToken: localStorage.getItem("headerToken")
        ? localStorage.getItem("headerToken")
        : "",
      is_form_submited: localStorage.getItem("is_form_submited")
        ? localStorage.getItem("is_form_submited")
        : "",
      parking_payment: localStorage.getItem("parking_payment")
        ? localStorage.getItem("parking_payment")
        : "",
      today: Moment(new Date()).format("YYYY-MM-DD H:m:s"),
    };
    this.getclientToken = this.getclientToken.bind(this);
  }
  
  async componentDidMount() {
    this.getclientToken();
  }
  onBackButtonEvent = () => {
    this.props.history.push("/reservation-list");
  };
  getclientToken = async (queryString = "") => {
    // console.log("kagk");
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var stripe_id = [];
    var parkingAmount = this.state.currentData ? global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData?.parking_fee_stripe).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2);
    let inputData = {
      customer_id: this.state.currentData?.guest_user?.stripe_customer_id, payment:parkingAmount, guest_slug: this.state.currentData?.guest_user?.slug, reservation_slug:this.state.currentData?.slug
    }
    let res = await CmsDataService.getParkingclientToken(inputData); 
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data[0] : [];
      stripe_id = res.data ? res.data[1] : [];
    }
    await this.setState({
      clientToken: resDataArr,
      stripe_id: stripe_id,
      parkingAmount:parkingAmount,
    });
  };
  render() {
    const options = {
      // passing the client secret obtained in step 3
      clientSecret: this.state.clientToken,
      // Fully customizable with appearance API.
      appearance: {/*...*/ },
    }
    const parkingFeedisplay =  this.state.currentData?global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData?.property_parking_fee).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2);
    const numberOfdays = this.state.currentData?.total_stay_nights;
    const parkingAmount =  this.state.currentData?global.OnKeyPressEvent.numberWithCommasForDecimal((parseFloat(this.state.currentData?.parking_fee_stripe).toFixed(2)).replace(/\.00$/, "")) : parseInt(0).toFixed(2);
    return (
      <main className="oh-template Brandmainpage wizard_header wzard_main_bg parking_via_web">
        <CommonHeader/>

        <section className="wizard_sec">
          <div className="row- wizard_row m-0 mobView">
            <div className="wizard_box ">
              <div className="wizard-data req_book_secw-zaed">
                <div className="container-fluid p-0 parking_page">
                  <section className="wzrd_main_section">
                    <div className="container">
                      <div className="content">
                        <div className="row mobView wizard_new_heading">
                          <div className="wzrd_middle_desk">
                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading mob_sticky_top">
                              <div className="wzrd_heading_box col-12 m-0 ">
                                <h4>{this.state.currentData?.parking_screen_stripe_titles ? this.state.currentData?.parking_screen_stripe_titles : "Parking Payment"}</h4>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                              <div className="wzrd_heading_box col-12 mb-0">
                                <div className="col-xs-12 col-sm-12 col-md-12 p-0 mg-b-0 check_wizard upload_info_detail ">
                                  <p>
                                    {" "}
                                    <b className="doc_upload_heading pt-0 parking-head-text">
                                      Would you like to access parking
                                      instructions for $
                                      {parkingFeedisplay
                                        ? parkingFeedisplay
                                        : ""}
                                      /night?
                                    </b>{" "}
                                  </p>
                                  <ul className="parking_list_sty">
                                   
                                      <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.currentData
                                    ? this.state.currentData.property.parking_payment_instructions:"",
                                  }}
                                ></p>
                                
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading">
                              <div className="parking_price_box">
                                <div className="wzrd_heading_box col-12 ">
                                  <div className="wzrd_mid_heading pt-0 border-0">
                                    <h5 className="pt-0">Parking price details</h5>
                                    <div className="mt-3">
                                      <ul className="parking_pay_detail">
                                        <li><span>${parkingFeedisplay} x {numberOfdays > 1 ? numberOfdays+ " nights":numberOfdays+" night"}</span><span>${parkingAmount}</span></li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="wzrd_mid_heading pt-0 ">
                                    <div className="parking_total">
                                      <ul className="parking_pay_detail">
                                        <li className="parking_total_price">
                                          <span>Total (USD)</span><span>${parkingAmount}</span></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>




                            <div className="col-sm-12 col-lg-12 bg_wzrd_heading ">
                              <div className="wzrd_heading_box col-12 mb-0">
                                <div className="col-xs-12 col-sm-12 col-md-12 mg-b-0 strip_parking">

                                  {!this.state.clientToken ? (
                                    <div>
                                      <h3>Loading...</h3>
                                    </div>
                                  ) : (
                                    <div className="col-xs-12 col-sm-12 col-md-12 pd-l- pd-r-">
                                      <Elements stripe={stripePromise} options={options}>
                                        <CheckoutFormParking guest_slug={this.state.guest_slug} reservation_slug={this.state.reservation_slug
                                          ? this.state.reservation_slug
                                          : ""}
                                          amount={this.state.parkingAmount ? this.state.parkingAmount : ""}
                                          stripe_id={this.state.stripe_id}
                                          confirmation_code={this.state.currentData?.confirmation_code} />
                                      </Elements>
                                    </div>
                                  )}
                                  <p className="error-message"></p>

                                </div>
                              </div>
                            </div>




                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
export default Parking;
