//     							                          //
//  Program: booktourform.jsx                             //
//  Application: KINI HTML                                //
//  Option: Book Tour Form                                //
//  Developer: PS		                                  //
//  Date: 2022-05-26                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import CmsDataService from "../../services/CmsDataService";
import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import queryString from "query-string";
import MetaSEO from "../../../MetaSEO";
import { Helmet } from "react-helmet-async";
const queryParams = queryString.parse(location.search);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class NewResident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personName: [],
      date: new Date("2014-08-18T21:11:54"),
      selectedDate: new Date(),
      type: false,
      value: undefined,
      inputValue: undefined,
      input: {},
      errors: {},
      first_name: "",
      // property_slug: props.match &&  props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug ) : global.property_slug,
      last_name: "",
      email: "",
      phone_number: "",
      applicant_photo: "",
      price_range: "",
      desired_move_in_date: "",
      comment: "",
      status_id: true,
      helperText: "",
      bedroom: [],
      bedroomArray: [],
      priceRangeArray: [],
      // meta tags start
      title:global.level ===  2 ? localStorage.getItem("brand_name")  :  ( global.level ===  3 ?  localStorage.getItem("property_name") : ""),
      favicon: global.level ===  2 ? localStorage.getItem("brand_logo_url")  :  ( global.level ===  3 ?  localStorage.getItem("property_logo_url") : ""),
      keywords:global.keywords,
      description:global.description,
      url:window.location.origin,
      image: global.level ===  2 ? localStorage.getItem("brand_logo_url")  :  ( global.level ===  3 ?  localStorage.getItem("property_logo_url") : ""),
      fb_app_id:"",
      // meta tags end
      view: queryParams.view === "mobile" ? false : true,
      seoLoaded: false,
    };
    // console.log("props", queryParams);
  }
  componentDidMount() {
    this.getMetaData();
  }

  getMetaData = async () => {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.level + "_HOSTING_META_DATA_";
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let levelSlug = global.level === 2 ? global.brandSlug : (global.level === 3 ? this.state.property_slug : 1);
    let res = await CmsDataService.getCmsMetaData("HOSTING", global.level, levelSlug);
    if (global.successStatus.includes(res.status)) {
      resData.title = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.title : "" ) : "";
      resData.favicon = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "" ) : "";
      resData.keywords = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.keywords : "" ) : "";
      resData.description = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.description : "" ) : "";
      resData.url = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "" ) : "";
      resData.image = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.favicon_url : "" ) : "";
      resData.fb_app_id = res.data ? (res.data.meta_data &&  res.data.meta_data.META ? res.data.meta_data.META.fb_app_id : "" ) : "";
    }
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
    await this.setState({
      showSpinner: false,
      loading: false,
      seoLoaded: true
    });
  }

  render() {
    // const [value, setValue] = React.useState(options[0]);
    // const [inputValue, setInputValue] = React.useState('');
    const config = {
      cors: "https://cors-anywhere.herokuapp.com/", // <optional> doesn't display the cors error
      formUrl:
        "https://docs.google.com/forms/d/e/<GOOGLE_FORM_ID>/formResponse",
    };
    return (
      <main className="oh-template Brandmainpage">
        <Helmet>
        <script src="//embed.typeform.com/next/embed.js"></script>
        <style type="text/css">{`
        html,
        body,
        #wrapper {
          width: 100%;
          height: 100vh;
        }
        iframe {
          border-radius: 0 !important;
        }
    `}</style>
        </Helmet>
        {/* Meta Tag Start */}
        {
          this.state.seoLoaded &&
            <MetaSEO
                title = {this.state.title}
                favicon = {this.state.favicon}
                keywords = {this.state.keywords}
                description = {this.state.description}
                url = {this.state.url}
                image = {this.state.image}
                fb_app_id = {this.state.fb_app_id}
                noIndex="true"
            />
         }
        {/* Meta Tag End */}
        {/* Header */}
        {this.state.view && (
          <CommonHeader propertySlug={this.state.property_slug} />
        )}
        {/* middle */}
        <section className="middle hosting_form">
          {/* <iframe src="https://accounts.google.com/v3/signin/identifier?dsh=S-1073632834%3A1667478130405124&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&rip=1&sacu=1&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=ARgdvAuj5WS7JqmUqmeZcekF6E4DUG1oFqdpcUO2omGyP-xl1iMAzr7082qmrY0DZWw2JXGX91yYZQ/viewform?embedded=true"
        width="80%" height="1000"
        frameborder="0" marginheight="0"
        marginwidth="0">
        Loading…
    </iframe> */}
          {/* <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeo42Dp6BimgXiG_9Fef8KOyQgpEi_6AvTYTEZYEcut1N-WYQ/viewform?embedded=true"
            width="640"
            height="1512"
            frameborder="0"
            marginheight="0"
            marginwidth="0"

          >
            Loading…
          </iframe> */}
          <div
      data-tf-widget="cajGROiC"
      id="wrapper"
      data-tf-opacity="100"
      data-tf-inline-on-mobile
      data-tf-iframe-props="title=OH Resident onboarding"
      data-tf-transitive-search-params
      data-tf-auto-focus
    ></div>

        </section>
        {/* Footer */}
        {this.state.view && (
          <CommonFooter
            propertySlug={this.state.property_slug}
            isOneFooter={true}
          />
        )}
      </main>
    );
  }
}
export default NewResident;
