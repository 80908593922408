//     							                                      //
//  Program: neighborhoods.jsx                            //
//  Application: KINI HTML                                //
//  Option: Neighborhoods                                 //
//  Developer: PS		                                      //
//  Date: 2022-04-21                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";


import CommonHeader from "../common/CommonHeader";
import CommonFooter from "../common/CommonFooter";
import CmsDataService from "../../services/CmsDataService";
import { Helmet } from "react-helmet-async";


class Neighborhoods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personName: [],
      date: new Date('2014-08-18T21:11:54'),
      selectedDate: new Date(),
      type: false,
      property_slug: props.match && props.match.params ? (props.match.params.propertySlug ? props.match.params.propertySlug : global.property_slug) : global.property_slug,
      slug: "",
      status_id: true,
      pathname: window.location.pathname,
      property_name: localStorage.getItem("property_name"),
      metaKeywords: "home,realtor,houses for rent,homes for sale,property,property for sale,homes for rent,apartments for rent near me",
      metaDescription: " Traditional real estate advertising focuses on outbound leads, which involves targeted prospecting. This method allows you to hone in probable leads, which increases the likelihood of success."

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  componentDidMount() {
    this.getcmsCommonData();
  }

  getcmsCommonData = async (queryString = "") => {

    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    var resDataArr = [];
    var cacheKeyName = global.theme + "_NEIGHBORHOODS_DATA_" + this.state.property_slug;
    if (global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null) {
      var data = global.LocalStorageManage.getWithExpiry(cacheKeyName) !== null ? JSON.parse(global.LocalStorageManage.getWithExpiry(cacheKeyName)) : localStorage.removeItem(cacheKeyName);
      this.setState(data);
    }
    let res = await CmsDataService.getCmsData(
      "NEIGHBORHOODS",
      "property_seo_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resDataArr = res.data ? res.data : [];
      resDataArr.map((item, key) => {
        resData[item.section_name_slug] = item;
      });
    }
    await this.setState({
      showSpinner: false,
      loading: false,
      neighborhoodSet: true
    });
    global.LocalStorageManage.setWithExpiry(cacheKeyName, JSON.stringify(resData));
    this.setState(resData);
  };

  handleChange = (event) => {
    this.setState({ personName: event.target.value });
  };

  handleDateChange(event) {
    this.setState({ selectedDate: event.target.value });
  }

  onFocus() {
    // alert("kldg");
    this.setState({ type: true });
  }

  onBlur() {
    this.setState({ type: false });
  }

  render() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const names = [
      '1 Bedrooms',
      '2 Bedrooms',
      '3 Bedrooms',
    ];


    return (
      <main className="oh-template">
        {/* Header */}
        <CommonHeader propertySlug={this.state.property_slug} />
        {/* middle */}
        <Helmet>
          <title>{this.state.property_name ? this.state.property_name : global.propertyName} - A CITY REIMAGINED</title>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="description" content={this.state.property_name ? this.state.property_name + " - A CITY REIMAGINED  " + this.state.metaDescription : this.state.metaDescription}></meta>
          <meta name="keywords" content={this.state.metaKeywords}></meta>
          <meta name="author" content={this.state.property_name ? this.state.property_name : global.propertyName} />
        </Helmet>
        <section className="middle">
          <div className="container-fluid p-0">
            <section className="neighborhoods pd-t-60 pd-b-20" name="SECTION_1_LEFT">
              <div className="container pd-b-30">
                <div className="row align-items-end">
                  <div className="col-lg-4">
                    <h3>
                      {this.state.SECTION_1_LEFT && this.state.SECTION_1_LEFT.section_data && this.state.SECTION_1_LEFT.section_data[0] && this.state.SECTION_1_LEFT.section_data[0].text ? this.state.SECTION_1_LEFT.section_data[0].text : "A city reimagined."}
                    </h3>
                    <p>
                      {this.state.SECTION_1_LEFT && this.state.SECTION_1_LEFT.section_data && this.state.SECTION_1_LEFT.section_data[1] && this.state.SECTION_1_LEFT.section_data[1].text ? this.state.SECTION_1_LEFT.section_data[1].text : "Nestled in one of AirDNA’s top neighborhoods, Kini Beach Haus puts you exactly where your guests want to be. Visit charming coffee shops, eclectic boutiques, mouthwatering cuisine, exciting nightlife, and inspiring art — all steps from your front door."}
                    </p>
                    <figure className="pd-t-70 desk">
                      <img src={this.state.SECTION_1_LEFT && this.state.SECTION_1_LEFT.section_data && this.state.SECTION_1_LEFT.section_data[2] && this.state.SECTION_1_LEFT.section_data[2].url ? this.state.SECTION_1_LEFT.section_data[2].url : global.OnKeyPressEvent.getS3ImageUrl("neighborhoods-img-1.png")} alt="neighborhood" className="img-fluid w-100" />
                    </figure>
                    <figure className="pd-t-70 mob">
                      <img src={this.state.SECTION_1_LEFT && this.state.SECTION_1_LEFT.section_data && this.state.SECTION_1_LEFT.section_data[2] && this.state.SECTION_1_LEFT.section_data[2].url ? this.state.SECTION_1_LEFT.section_data[2].url : global.OnKeyPressEvent.getS3ImageUrl("a-city-mobile.jpg")} alt="neighborhood" className="img-fluid w-100" />
                    </figure>
                  </div>
                  <div className="col-lg-8" name="SECTION_1_RIGHT">
                    <figure className="pd-l-70 desk-2">
                      <img src={this.state.SECTION_1_RIGHT && this.state.SECTION_1_RIGHT.section_data && this.state.SECTION_1_RIGHT.section_data[0] && this.state.SECTION_1_RIGHT.section_data[0].url ? this.state.SECTION_1_RIGHT.section_data[0].url : global.OnKeyPressEvent.getS3ImageUrl("neighborhoods-img-2.png")} alt="neighborhood" className="img-fluid w-100" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="container-fluid pd-t-10 pd-b-15">
                <figure>
                  <img src={this.state.SECTION_2 && this.state.SECTION_2.section_data && this.state.SECTION_2.section_data[0] && this.state.SECTION_2.section_data[0].url ? this.state.SECTION_2.section_data[0].url : global.OnKeyPressEvent.getS3ImageUrl("neighborhoods-map.png")} alt="neighborhood" className="img-fluid w-100" />
                </figure>
              </div>
              <div className="container pd-t-40 pd-b-30 pd-l-0 pd-r-0">
                <div className="content">
                  <div className="row m-0 align-items-end">
                    <ul>
                      <li><button className="one">{this.state.SECTION_3_DINE && this.state.SECTION_3_DINE.section_data && this.state.SECTION_3_DINE.section_data[0] && this.state.SECTION_3_DINE.section_data[0].text ? this.state.SECTION_3_DINE.section_data[0].text : "Dine"}</button></li>
                      <li><button className="two">{this.state.SECTION_3_PLAY && this.state.SECTION_3_PLAY.section_data && this.state.SECTION_3_PLAY.section_data[0] && this.state.SECTION_3_PLAY.section_data[0].text ? this.state.SECTION_3_PLAY.section_data[0].text : "Play"}</button></li>
                      <li><button className="three">{this.state.SECTION_3_SHOP && this.state.SECTION_3_SHOP.section_data && this.state.SECTION_3_SHOP.section_data[0] && this.state.SECTION_3_SHOP.section_data[0].text ? this.state.SECTION_3_SHOP.section_data[0].text : "Shop"}</button></li>
                      <li><button className="four">{this.state.SECTION_3_COFFEE && this.state.SECTION_3_COFFEE.section_data && this.state.SECTION_3_COFFEE.section_data[0] && this.state.SECTION_3_COFFEE.section_data[0].text ? this.state.SECTION_3_COFFEE.section_data[0].text : "coffee"}</button></li>
                    </ul>
                  </div>
                  <div className="row align-items-end mg-t-45 m-0">
                    <div className="col-lg-12 pd-l-7 pd-r-3">
                      <table className="shop mg-t-70">
                        <tr>
                          <td><h6>{this.state.SECTION_3_DINE && this.state.SECTION_3_DINE.section_data && this.state.SECTION_3_DINE.section_data[1] && this.state.SECTION_3_DINE.section_data[1].text ? this.state.SECTION_3_DINE.section_data[1].text : "The Chef and I on Ninth"}</h6></td>

                          <td><h6>{this.state.SECTION_3_PLAY && this.state.SECTION_3_PLAY.section_data && this.state.SECTION_3_PLAY.section_data[1] && this.state.SECTION_3_PLAY.section_data[1].text ? this.state.SECTION_3_PLAY.section_data[1].text : "Whiskey Kitchen"}</h6></td>

                          <td><h6>{this.state.SECTION_3_SHOP && this.state.SECTION_3_SHOP.section_data && this.state.SECTION_3_SHOP.section_data[1] && this.state.SECTION_3_SHOP.section_data[1].text ? this.state.SECTION_3_SHOP.section_data[1].text : "Otaku Ramen"}</h6></td>

                          <td><h6>{this.state.SECTION_3_COFFEE && this.state.SECTION_3_COFFEE.section_data && this.state.SECTION_3_COFFEE.section_data[1] && this.state.SECTION_3_COFFEE.section_data[1].text ? this.state.SECTION_3_COFFEE.section_data[1].text : "The Southern Steak & Oyster"}</h6></td>
                        </tr>
                        <tr>
                          <td><h6>{this.state.SECTION_3_DINE && this.state.SECTION_3_DINE.section_data && this.state.SECTION_3_DINE.section_data[2] && this.state.SECTION_3_DINE.section_data[2].text ? this.state.SECTION_3_DINE.section_data[2].text : "Sunda Nashvile"}</h6></td>

                          <td><h6>{this.state.SECTION_3_PLAY && this.state.SECTION_3_PLAY.section_data && this.state.SECTION_3_PLAY.section_data[2] && this.state.SECTION_3_PLAY.section_data[2].text ? this.state.SECTION_3_PLAY.section_data[2].text : "Barista Parlor Golden Sound"}</h6></td>

                          <td><h6>{this.state.SECTION_3_SHOP && this.state.SECTION_3_SHOP.section_data && this.state.SECTION_3_SHOP.section_data[2] && this.state.SECTION_3_SHOP.section_data[2].text ? this.state.SECTION_3_SHOP.section_data[2].text : "Superica"}</h6></td>

                          <td><h6>{this.state.SECTION_3_COFFEE && this.state.SECTION_3_COFFEE.section_data && this.state.SECTION_3_COFFEE.section_data[2] && this.state.SECTION_3_COFFEE.section_data[2].text ? this.state.SECTION_3_COFFEE.section_data[2].text : "404 Kitchen/Gertie's Whiskey Bar"}</h6></td>
                        </tr>
                        <tr>
                          <td><h6>{this.state.SECTION_3_DINE && this.state.SECTION_3_DINE.section_data && this.state.SECTION_3_DINE.section_data[3] && this.state.SECTION_3_DINE.section_data[3].text ? this.state.SECTION_3_DINE.section_data[3].text : "Biscuit Love"}</h6></td>

                          <td><h6>{this.state.SECTION_3_PLAY && this.state.SECTION_3_PLAY.section_data && this.state.SECTION_3_PLAY.section_data[3] && this.state.SECTION_3_PLAY.section_data[3].text ? this.state.SECTION_3_PLAY.section_data[3].text : "Saint Anejo"}</h6></td>

                          <td><h6>{this.state.SECTION_3_SHOP && this.state.SECTION_3_SHOP.section_data && this.state.SECTION_3_SHOP.section_data[3] && this.state.SECTION_3_SHOP.section_data[3].text ? this.state.SECTION_3_SHOP.section_data[3].text : "Emmy Squared"}</h6></td>

                          <td><h6>{this.state.SECTION_3_COFFEE && this.state.SECTION_3_COFFEE.section_data && this.state.SECTION_3_COFFEE.section_data[3] && this.state.SECTION_3_COFFEE.section_data[3].text ? this.state.SECTION_3_COFFEE.section_data[3].text : "Blue Stripes Cacao Shop"}</h6></td>
                        </tr>


                      </table>
                      <table className="shop mg-t-70">
                        <tr>
                          <td><h6>{this.state.SECTION_3_DINE && this.state.SECTION_3_DINE.section_data && this.state.SECTION_3_DINE.section_data[4] && this.state.SECTION_3_DINE.section_data[4].text ? this.state.SECTION_3_DINE.section_data[4].text : "Mercy Lounge"}</h6></td>

                          <td><h6>{this.state.SECTION_3_PLAY && this.state.SECTION_3_PLAY.section_data && this.state.SECTION_3_PLAY.section_data[4] && this.state.SECTION_3_PLAY.section_data[4].text ? this.state.SECTION_3_PLAY.section_data[4].text : "Rocketown"}</h6></td>

                          <td><h6>{this.state.SECTION_3_SHOP && this.state.SECTION_3_SHOP.section_data && this.state.SECTION_3_SHOP.section_data[4] && this.state.SECTION_3_SHOP.section_data[4].text ? this.state.SECTION_3_SHOP.section_data[4].text : "Country Music Hall of Fame and Museum"}</h6></td>

                          <td><h6>{this.state.SECTION_3_COFFEE && this.state.SECTION_3_COFFEE.section_data && this.state.SECTION_3_COFFEE.section_data[4] && this.state.SECTION_3_COFFEE.section_data[4].text ? this.state.SECTION_3_COFFEE.section_data[4].text : "Ascend Amphitheater"}</h6></td>
                        </tr>
                        <tr>
                          <td><h6>{this.state.SECTION_3_DINE && this.state.SECTION_3_DINE.section_data && this.state.SECTION_3_DINE.section_data[5] && this.state.SECTION_3_DINE.section_data[5].text ? this.state.SECTION_3_DINE.section_data[5].text : "Rudy's Jazz Room"}</h6></td>

                          <td><h6>{this.state.SECTION_3_PLAY && this.state.SECTION_3_PLAY.section_data && this.state.SECTION_3_PLAY.section_data[5] && this.state.SECTION_3_PLAY.section_data[5].text ? this.state.SECTION_3_PLAY.section_data[5].text : "The Station Inn"}</h6></td>

                          <td><h6>{this.state.SECTION_3_SHOP && this.state.SECTION_3_SHOP.section_data && this.state.SECTION_3_SHOP.section_data[5] && this.state.SECTION_3_SHOP.section_data[5].text ? this.state.SECTION_3_SHOP.section_data[5].text : "Pins Mechanical"}</h6></td>

                          <td><h6>{this.state.SECTION_3_COFFEE && this.state.SECTION_3_COFFEE.section_data && this.state.SECTION_3_COFFEE.section_data[5] && this.state.SECTION_3_COFFEE.section_data[5].text ? this.state.SECTION_3_COFFEE.section_data[5].text : "CycleBar"}</h6></td>
                        </tr>
                        <tr>

                          <td><h6>{this.state.SECTION_3_DINE && this.state.SECTION_3_DINE.section_data && this.state.SECTION_3_DINE.section_data[6] && this.state.SECTION_3_DINE.section_data[6].text ? this.state.SECTION_3_DINE.section_data[6].text : "L.A. Jackson"}</h6></td>

                          <td><h6>{this.state.SECTION_3_PLAY && this.state.SECTION_3_PLAY.section_data && this.state.SECTION_3_PLAY.section_data[6] && this.state.SECTION_3_PLAY.section_data[6].text ? this.state.SECTION_3_PLAY.section_data[6].text : "Gulch Dog Park"}</h6></td>
                          <td></td>
                          <td></td>
                        </tr>



                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* Footer */}
        <CommonFooter propertySlug={this.state.property_slug} />
      </main>
    );
  }
}
export default Neighborhoods